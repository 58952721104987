export const ItemTypes = {
    ALL: 'all',

    // MINISITE

    PLACEHOLDER: 'placeholder',
    DROPPABLE: 'droppable',
    SECTION_TITLE: 'section-title',
    FOOTER: 'footer',
    HEADER: 'header',
    IMAGE_ROW: 'image-row',
    TAB_PRODUCT: 'tab-products',
    SLIDER: 'slider',
    SLIDER_WITH_RIGHT_IMAGES: 'slider-right-images',
    SLIDER_MULTIPLE: 'slider-multiple',
    THREE_IMAGES_ROW: 'three-images-row',
    THREE_IMAGES_GRID: 'three-images-grid',
    BLOC_TEXT: 'bloc-text',
    PAGE_LANDING: 'page-landing',
    PAGE_PRODUCT: 'page-product',
    PAGE_CONTACT: 'page-contact',
    PAGE_WISHLIST: 'page-wishlist',
    TEXT: 'text',
    TEXT_HTML: 'text-html',
    IMAGE: 'image',
    GIFT_FINDER: 'gift-finder',
    EMAIL_CONTACT: 'email-contact',
    EMAIL_WISHLIST: 'email-wishlist',
    INFORMATION_BANNER: 'information-banner',
    LINK_BANNER: 'link-banner',
    CATEGORY: 'category',
    TEXT_IMAGE: 'text-image',
    SLIDER_PRODUCT: 'slider-product',
    TEXT_VIDEO: 'text-vidéo',

    // JEUX

    PRE_HEADER_GAME: 'pre-header-game',
    HEADER_GAME: 'header-game',
    FOOTER_GAME: 'footer-game',
    HOME_GAME: 'home-game',
    HOME_COMPLETE_FORM_GAME: 'home-complete-form-game',
    LOGIN_GAME: 'login-game',
    INDEX_GAME: 'index-game',
    RESULTS_WIN_GAME: 'results-win-game',
    RESULTS_LOOSE_GAME: 'results-loose-game',
    ALREADYPLAYED_GAME: 'alreadyplayed-game',
    ENDED_GAME: 'ended-game',
    NOT_STARTED_GAME: 'not-started-game',
    EXPLANATIONS_GAME: 'explanations-game',
    EXPLANATIONS_MORE_DETAILS_GAME: 'explanations-more-details-game',
    EXPLANATIONS_ACV_GAME: 'explanations-acv-game',
    EXPLANATIONS_BF_GAME: 'explanations-bf-game',
    DOTATIONS_MORE_DETAILS_GAME: 'dotations-more-details-game',
    RULES_GAME: 'rules_game',
    RULE: 'rule',
    RULE_BF: 'rule-bf',
    DOTATION: 'dotation',
    DOTATION_MORE_DETAILS: 'dotation-more-details',
    SOCIAL: 'social',
    REDIRECT_ON_GAME: 'redirect-on-game',
    REDIRECT_ON_GAME2: 'redirect-on-game2',
    REDIRECT_ON_GAME3: 'redirect-on-game3',
    REDIRECT_ON_GAME4: 'redirect-on-game4',
    AMAZING_GAME: 'amazing-game',
    TITLE_GAME: 'title-game',
    PARAGRAPH_GAME: 'paragraph-game',
    RULES_AMAZING_GAME: 'rules-amazing-game',
    RULES_INLINE_FLEX_AMAZING_GAME: 'rules-inline-flex-amazing-game',
    DOTATIONS_ONE_IMAGE: 'dotations-one-image-amazing-game',
    BUTTON_GAME: 'button-amazing-game',
    BASIC_BLOC: 'basic-bloc-game',
    BASIC_BLOC2: 'basic-bloc-game2',

    // FLIPBOOK

    HEADER_BOOK: 'header-book',
    HEADER_CART_BOOK: 'header-cart-book',
    FOOTER_BOOK: 'footer-book',
    TOP_HOMEPAGE_BOOK: 'top-homepage-book',
    POSTCARD_BOOK: 'postcard-book',
    RECAP_BLOCK_BOOK: 'recap-block-book',
    RESULTS_BOOK: 'result-book',
    BANNER_LINK_BOOK: 'banner-link-book',
    EMAIL_SUCCESS_BOOK: 'email_register',
    FLIPBOOK: 'flipbook',
    FLIPBOOK_WITH_CART: 'flipbook-with-cart',
    FLIPBOOK_WITH_WISHLIST: 'flipbook-with-wishlist',

    // OFFERS 

    OFFER_FRONT_PAGE: 'offer-front-page',
    OFFER_SECTION_TITLE: 'offer-section-title',
    OFFER_ABOUT_SLIDE: 'offer-about-slide',
    OFFER_TRIPLE_BLOCK_LIST: 'offer-triple-block-list',
    OFFER_TRIPLE_BLOCK: 'offer-triple-block',
    OFFER_TRIPLE_BLOCK_TEXT: 'offer-triple-text',
    OFFER_TEXT_WITH_IMAGE: 'offer-text-with-image',
    OFFER_TEXT_WITH_IMAGE_SIMPLE: 'offer-text-with-image-simple',
    OFFER_STATS: 'offer-stats',
    OFFER_TRIPLE_BLOCK_LIST_POLE: 'offer-triple-block-list-pole',
    OFFER_TRIPLE_BLOCK_LIST_LEADER: 'offer-triple-block-list-leader',
    OFFER_TRIPLE_BLOCK_INFORMATIONS: 'offer-triple-block-informations',
    OFFER_TRIPLE_BLOCK_LIST_TEXT: 'offer-triple-block-list-text',
    OFFER_DEVIS: 'offer-devis',

    // CMS 

    CMS_SLIDER: 'cms-slider',
    CMS_SLIDER_ABOUT: 'cms-slider-about',
    CMS_LIST_CATEGORIES: 'cms-list-categories',
    CMS_LIST_CATEGORIES_SUBITEM: 'cms-list-categories-subitem',
    CMS_SLIDER_BY_TAB: 'cms-slider-by-tab',
    CMS_SLIDER_BY_TAB_SUBITEM: 'cms-slider-by-tab-subitem',
    CMS_SLIDER_INSTAGRAM: 'cms-slider-instagram',
    CMS_SLIDER_PRESENTATION_MEDIA: 'cms-slider-presentation_media',
    CMS_SLIDER_MOMENTS: 'cms-slider-moments',
    CMS_SLIDER_IMAGE: 'cms-slider-image',
    CMS_SECTION_MANIFEST_BLOCK: 'cms-description-manifest',
    CMS_GRID_IMAGE: 'cms-grid-image',
    CMS_TEXT_MEDIA: 'cms-text-media',
    CMS_BANNER_MEDIA: 'cms-banner-media',
    CMS_SECTION_DESCRIPTION_PAGE: 'cms-section-description-page',
    CMS_CATEGORIES_HOME: 'cms-categories-home',
    CMS_COLLECTIONS_HOME: 'cms-collections-home',
    CMS_SLIDER_PRODUCTS_HOME: 'cms-new-products-home',
    CMS_BLOC_STORELOCATOR_HOME: 'cms-bloc-storelocator-home',
    CMS_ENTETE: 'cms-entete',
    CMS_TOP_IMAGE_FULLSCREEN: 'cms-top-image-fullscreen',
    CMS_SLOGAN: 'cms-slogan',
    CMS_TITLE_TEXT: 'cms-title-text',
    CMS_SEPARATOR: 'cms-separator',
};

export const EditableTypes = {
    SHORT_TEXT: 'short-text',
    LONG_TEXT: 'long-text',
    IMAGE: 'image',
    MULTIPLE_BLOCKS: 'multiple-block',
    MINI_BLOCKS: 'mini-block',
    FINDER: 'finder',
    IMAGE_LINK: 'image-link'
};

export const FormInputTypes = {
    TEXT: 'text',
    LINK: 'link',
    TEXTAREA: 'textarea',
    WYSIWYG: 'wysiwyg',
    NUMBER: 'number',
    DATE: 'date',
    COLOR: 'color',
    SLIDER: 'slider',
    IMAGE: 'image',
    SELECT: 'select',
    GROUP: 'group',
    SWITCH: 'switch',
    BUTTON_GROUP: 'button-group',
    // Specifics
    MENU: 'menu',
    SOCIAL: 'social',
    PRODUCTS: 'products',
    PRODUCT: 'product',
    CATEGORY: 'category'
};

export const MenuTypes = {
    LINK: 'link',
    CATEGORY: 'category'
};

export const FinderFilterTypes = {
    RANGE: 'range',
    SELECT: 'select'
};

export const Pages = {
    HOME: 'home',
    LANDING: 'landing',
    PRODUCT_DETAILS: 'product_details',
    CONTACT: 'contact',
    WISHLIST: 'wishlist',
    EMAIL_CONTACT: 'email_contact',
    EMAIL_WISHLIST: 'email_wishlist',
    WORDPRESS: 'wordpress',
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    HOMEPAGE_GAME: 'home_game',
    LOGIN_GAME: 'login_game',
    INDEX_GAME: 'index_game',
    RESULTS_GAME: 'results_game',
    RESULTS_WIN_GAME: 'results_win_game',
    RESULTS_LOOSE_GAME: 'results_loose_game',
    ALREADYPLAYED_GAME: 'alreadyplayed_game',
    ENDED_GAME: 'ended_game',
    NOT_STARTED_GAME: 'not_started_game',
    HOMEPAGE_BOOK: 'home',
    FLIPBOOK: 'flipbook',
    REGISTER_BOOK: 'register',
    REGISTER_SUCCESS_BOOK: 'register_success',
    GIFT_FINDER: 'config',
    MAGENTO: 'config_magento',
    PRESENTATION: 'presentation',
    CMS_HOME: 'cms_home'
};

export const SocialNetworks = {
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    INSTAGRAM: 'instagram',
    YOUTUBE: 'youtube',
    PINTEREST: 'pinterest',
    LINKEDIN: 'linkedin',
    SNAPCHAT: 'snapchat'
};

export const AssetTypes = {
    MINISITE: 'minisite',
    FLIPBOOK: 'flipbook',
    PRESENTATION: 'presentation',
    NEWSLETTER: 'newsletter',
    GIFT_FINDER: 'gift_finder',
    GAME: 'jeu',
    FACEBOOK: 'facebook',
    WORDPRESS: 'wordpress',
    MAGENTO: 'magento',
    CMS: 'cms'
};