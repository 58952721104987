import React from 'react';
import { withRouter } from 'react-router';
import {Grid,LinearProgress,Dialog,DialogTitle,DialogContent,DialogActions} from '@material-ui/core'
import { connect } from "react-redux";
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import TopPanel from '../../../layouts/TopPanel/TopPanel'
import Typography from '../../../ui/typography/Typography';
import ChipMedias from './components/ChipMedias'
import MediaModal from './components/MediaModal'
import { eventService } from '../../../../js/services/event.service';
import request from '../../../../js/utils/fetch';

import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import SearchTags from '../../../ui/inputs/SearchTags';
import EmptyCard from "../../../ui/empty-card/EmptyCard";   
import EmptyMedias from '../../../../assets/pictos/empty-picto/empty_medias.png';

import CardCustom from '../../../layouts/Card/CardCustom';
import CardMedia from '../../../layouts/Card/cardContent/CardMedia';

import { withApollo } from 'react-apollo';
import { GET_MEDIA_CATEGORIES } from '../../../../queries/mediaCategories';
import { GET_MEDIAS_IMAGES, GET_MEDIAS_CURSOR,UPDATE_TAG,ADD_TAG} from '../../../../queries/medias';
import { GET_ALL_CHANNELS } from '../../../../queries/channels'

import LayoutFormBuilder from '../../../ui/form/LayoutFormBuilder';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import axios from '../../../../js/utils/axios';

import mediasConfig from './config/medias.config'
import mediasEditConfig from './config/mediasEdit.config'

import {ALLOWED,VIDEOS,IMAGES,DOCUMENTS, CSV} from '../../../../js/constants/medias-types';
import {ROUTE_CHANNELS_MEDIAS, ROUTE_HOME} from '../../../../js/constants/route-names';
import Pagination from '@material-ui/lab/Pagination';
import TabCategoriesMedia from '../products/components/TabCategories';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import mediasImportConfig from './config/mediasImport.config';
import mediasImportStepperConfig from './config/mediasImportStepper.config';

import { hasRights } from '../../../../js/utils/rights';
import { MEDIAS, MEDIAS_MEDIAS, VIEW, CREATE, IMPORT, DELETE, UPDATE } from '../../../../js/constants/constant-rights';
import { getFileConfig } from '../../../../js/helpers/files';

const MediaContainer=styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top:20px;
    /*&>.MuiGrid-item{
        height: 65vh;
        overflow: auto;
    }*/
`;

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;

class MediasCategories extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            openForm        : this.props.history.location?.state?.formOpen 
                                ? props.history.location?.state?.formOpen 
                                : props.history.location.state?.openForm 
                                    ? props.history.location.state.openForm 
                                    : false,
            openMediaForm   : props.history.location.state?.openMediaForm ? props.history.location.state.openMediaForm : false,
            openFormImport  : false,
            dataLayout      : null,
            identifier      : '',
            parent          : [],
            parentCat       : '',
            formImage       : null,  
            fileName        : null,
            fileAlt         : 'Image',
            treeCats        : [],
            medias          : null,
            /*allMedias       : null,*/
            filterByType    : props.history.location.state?.types ? props.history.location.state.types : ALLOWED,
            openModal       : false,
            buttonAvailable : props.history.location.state?.buttonAvailable ? false : true,
            toDelete        : null,
            openDeleteModal : false,
            selectedNodes   : [],
            formAction      : 'add',
            currentId       : '',
            openDeleteCatModal : false,
            loading         : false,
            page            : 1,
            nbperpage       : 8,
            countPage       : 0,
            cursor          : null,
            errors          : []
            /*dataAddLayout   : mediasImport,*/
        };
        this.cats=[
            {
                libelle:'All',
                type:'all',
            },
            {
                libelle:'Images',
                type:IMAGES,
            },
            {
                libelle:'Vidéos',
                type:VIDEOS,
            },
            {
                libelle:'Documents',
                type:DOCUMENTS,
            },
            /*{
                libelle:'360°',
                type:[],
            },
            {
                libelle:'Audio',
                type:[],
            }*/
        ]

    }
    

    //#region UTILS
    copyArrayOfObjects = array => array.map(a => ({...a}));

    resetState() {
        this.setState({
            cat_id: '',
            identifier: '',
            parentCat: '',
            parent:[],
            action:'',
            formAction:'',
            toDelete:null,
            fileName:'',
            fileAlt:'',
            formImage: null,
            /*dataAddLayout: mediasImport,*/
        });
    }

    getFileFromURL = (url,name)=>{
        return new Promise((resolve,reject)=>{
            fetch(url)
            .then(res => res.blob()) // Gets the response and returns it as a blob
            .then(blob => {
                let file=new File([blob],name);
                resolve(file)
            });
        })
    }

    handleNextStep=()=>{
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ 
                seeErrors: true,
            });
            eventService.fire();
            return false;
        }
        
    }

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleInputChange = (stateName, evt) => {
        const value = evt?.target?.value ?? evt;
        
        this.setState({
            ...this.state,
            [stateName]: value
        });
    };

    checkChannel(channels,identifier){
        let channel= channels.filter(channel=>channel.node.attributeGroup.identifier === identifier && !channel.node.isSystem);
        if(channel && channel.length>0){
            return {isSetUp:true,channels:channel};
        }
        else{
            return {isSetUp:false};
        }
    };

    changePage = (event, page) => {
        this.props.startLoading();
        let index = 0;
        if(page > 1){
            index = ( page * this.state.nbperpage ) - this.state.nbperpage - 1
        }
        this.setState({
            cursor: this.state.listCursors[index].cursor,
            page: page
        },()=>{
            this.filterMedias(true);
        });
    };

    //#endregion


    //#region GET MEDIAS
    handleGetCursorsMedias = (variables) => {
        return new Promise((resolve,reject)=>{
            const getCursorsMedias = GET_MEDIAS_CURSOR;
            this.props.client.query({
                query: getCursorsMedias,
                fetchPolicy: 'no-cache',
                variables:variables,
            }).then(result => {
                this.setState({
                    listCursors: result.data.mediaObjects.edges
                });
            });
            
            resolve();
        });
    }

    handleGetCategories() {
        return new Promise((resolve, reject) => {
            // this.props.client.query({
            //     query: GET_MEDIA_CATEGORIES,
            //     fetchPolicy: 'no-cache'
            // }).then(result =>{
            //     let categoriesRemoveNode = []
            //     result.data.mediaObjectCategories.edges.map(
            //         e => categoriesRemoveNode.push(e.node)
            //     )
            //     this.setState({
            //         categories: categoriesRemoveNode,
            //         rootid: result.data.mediaObjectCategories.edges[0].node.id,
            //         activeCategorie: ['Tout voir']
            //     });
            //     resolve();
            // });
            request(`${process.env.REACT_APP_API}/categories-list/media`, 'get').then(
                (data) => {
                    if(data.success){
                        this.setState({
                            categories: data.datas,
                            activeCategorie: ['Tout voir']
                        });
                    }
                    resolve();
                }
            );
        });
    }
    //#endregion


    //#region FILTER
    filterByType=(cat)=>{
        let filter;
        if(cat==='all'){
            filter = ALLOWED;
        }
        else{
            filter = cat;     
        }
         this.setState({
            filterByType  : filter
        },()=>{
            this.filterMedias();
        }) 
    }

    filterMedias = async(pagination)=>{  
        this.setState({
            medias:null
        })

        let variables   = {
            types: this.state.filterByType ? this.state.filterByType : ALLOWED,
            categories: this.state.hasMultiCat ? this.state.filterByCategory : [this.state.filterByCategory],
            nbperpage : this.state.nbperpage,
            id_list : this.state.filterByTags ?? []
        }
        if(!pagination){
            await this.handleGetCursorsMedias(variables);
            this.setState({
                page :1
            })

        }
        else{
            if(this.state.cursor && this.state.listCursors && this.state.cursor !== this.state.listCursors[0].cursor){
                variables.cursor = this.state.cursor;
            }            
        }

        let getMedias    = GET_MEDIAS_IMAGES;
        this.props.client.query({
            query:getMedias ,
            fetchPolicy:'no-cache',
            variables:variables,

        }).then( result =>{
            this.setState({
                medias  : result.data.mediaObjects.edges,
                countPage: Math.ceil(result.data.mediaObjects.totalCount / this.state.nbperpage)
            }) 
        })
        this.props.stopLoading();


    }

    handleChangeCategorie=(categorie , childrens,id,currentNode, breadcrumb)=>{
        if(id) {
            // let id2 = id.replace('/api/categories', '');
            //     id2 = id2.replace('/', '');
            //     id2 = parseInt(id2);

            this.setState({
                idActiveCat: id,
                arrayCatFilter: [id],

                filterByCategory : id,
                selectedNodeId : id,
                hasMultiCat : false,

            },()=>{
                this.filterMedias();
            });
        } else {
            this.setState({
                filterByCategory: ['Tout voir'],
                selectedNodeId: ['Tout voir'],
                hasMultiCat : true,
            }, () => {
                this.filterMedias();
            });
        }


    }

    filterByTags=(tags)=>{
        let listID=[]
        if(tags.length>0){
            for(let tag of tags){
                for(let media of tag.mediaObjects){
                    media = parseInt(media.replace('/api/media-objects/',''),10)
                    if(!listID.includes(media)){
                        listID.push(media);
                    }
                }
                
            }            
        }

        this.setState({
            filterByTags:listID,
        },()=>{
            this.filterMedias();
        })
    }
    //#endregion


    //#region LAYOUT FORMS
    handleToggleDrawer(form){
        if(form==='addMediaForm'){
            this.setState({ 
                openMediaForm   : !this.state.openMediaForm,
                buttonAvailable : !this.state.buttonAvailable
            });  
        }

        this.setState({ 
            [form] : !this.state[form]
        });    
    }

    addMedia = ()=>{
        this.resetState();
        let defaultCat = this.state.selectedNodeId ? this.state.selectedNodeId : this.state.categoriesData?.find(e => e.node.parent === null);
        this.setState({ 
            parent: [defaultCat],
        },()=>{
            this.handleToggleDrawer('addMediaForm');
        });

    }

    editMedia = (media)=>{
        this.resetState();
        if(media.node){
            media = media.node;
        }
        let mediaCat = media.category.edges.length>0 ? media.category.edges : [this.state.categoriesData.find(e => e.node.parent === null)];
        let currentTags = media.mediaObjectTags.edges;
        let fileTags = media.mediaObjectTags.edges.map(tag=>{
            let mediaObjects = tag.node.mediaObjects.edges.map(media=>media.id);
            return{
                id : tag.node.id,
                tag : tag.node.tag,
                mediaObjects : mediaObjects
            }
        });

        this.setState({
            formAction:'edit',
            currentId: media.id,
            fileName: media.name,
            fileAlt: media.alt,
            filePath: getFileConfig(media.type).image ? getFileConfig(media.type).image : media.filePath,
            fileTags: [...fileTags],
            currentTags: [...currentTags],
            mediaType: media.type,
            parent: mediaCat
        },()=>{
            this.handleToggleDrawer('addMediaForm');

        })
    }

    handleFormImport = (type, title) => {
        /*this.handleToggleDrawer('openFormImport');*/
        this.props.startLoading();
        this.props.client.query({
            query:GET_ALL_CHANNELS,
            fetchPolicy : 'no-cache'
        }).then(result=>{
            let allChannels = result.data.channels.edges;
            let importCards = [
                {
                    logo : 'medias/gdrive-logo.png',
                    libelle : 'Google Drive',
                    isLocaleImage:true,
                    identifier :'googledrive',

                },
                {
                    logo : 'medias/dropbox-logo.png',
                    libelle : 'Dropbox',
                    isLocaleImage:true,
                    identifier:'dropbox',

                },
                {
                    logo : 'medias/onedrive-logo.png',
                    libelle : 'OneDrive',
                    isLocaleImage:true,
                    identifier:'onedrive'

                },
                {
                    logo : 'medias/winrar.png',
                    libelle : 'Fichier zip',
                    textButton : 'Bientot disponible',
                    secondaryStyle : true,
                    isLocaleImage:true,
                    disabled:true,
                    identifier:'zip'

                }
            ]
            importCards = importCards.map(card=>{
                if(card.identifier === 'zip'){
                    return card;
                }
                else{
                    let checkChannel= this.checkChannel(allChannels,card.identifier);
                    let isSetUp = checkChannel.isSetUp;
                    return{
                        ...card,
                        textButton : isSetUp ? 'Importer': 'Créer un channel',
                        secondaryStyle : !isSetUp,
                        description : isSetUp ? 'Importer des medias depuis ' + card.libelle : null,
                        secondaryText: isSetUp ? null : 'Vous n\'avez pas encore configuré de channel',
                        onClick : isSetUp ? ()=>this.setUpImport(checkChannel.channels) : ()=>this.goTo(ROUTE_CHANNELS_MEDIAS),

                    }
                }
                
            })
            let defaultCat = this.state.selectedNodeId ? this.state.selectedNodeId : this.state.categoriesData.find(e => e.node.parent === null);

            this.setState({
                importMediasCard : importCards,
                parent: [defaultCat],
            },()=>{
                this.props.stopLoading()
                this.handleToggleDrawer('openFormImport')
            })
            
        })

    };

    setUpImport = (channels) =>{
        this.setState({
            availableChannels : channels
        },()=>{
            this.handleToggleDrawer('openFormImportStepper')
        })
    }
    //#endregion

    //#region MODALS
    handleCallbackModal = (newList) => {
        this.setState({
            medias          : newList,
            currentMedias   : newList,
        },()=>{
            this.filterMedias();
        })
    }
    //#endregion


    //INIT
    async preparePage() {
        this.prepareTree();
        await this.handleGetCategories();
        await this.handleGetCursorsMedias();
        this.filterMedias()
        if(this.props.history.location.state?.types){
            this.filterMedias();
        }
        else{
            // this.handleGetAllMedias();            
        }
        this.setState({ ready: true });
    }  

    prepareTree(){
        this.props.client.query({
            query: GET_MEDIA_CATEGORIES,
            fetchPolicy: 'no-cache'
        }).then(result=>{

            let cats  = result.data.mediaObjectCategories.edges;
            let data  = cats.filter(e => e.node.parent === null);

            
            
            this.setState({ categoriesData: cats,defaultRoot:data });
            if (this.props.history.location?.state?.formOpen){
                this.addMedia()
            }
            /*root.children=data;
            tree=tree.concat(root);*/
            
        })
    }


    //#region MUTATION
    handleAddMedia= (action='add')=>{

        return new Promise(async(resolve,reject)=>{
            if(action === 'edit' || this.state.formImage?.data !== null ){

                this.props.startLoading();
                await this.saveMedia(action);    
                this.handleToggleDrawer('addMediaForm');
                // this.handleGetAllMedias(true);
                this.filterMedias();
                this.props.stopLoading();
                if(action === 'edit'){
                    this.props.snack(ALERT_SUCCESS,'Média modifié !');
                }else{
                    this.props.snack(ALERT_SUCCESS,'Média ajouté !');
                }
                this.setState({
                    formImage:null      
                })


            }
            
            resolve();
        });
    }

    handleImportMedia = async()=>{
        let cats = this.state.parent;
        let tags = this.state.fileTags;

        this.setState({
            mediasPending : this.state.mediasToImport.length,
            mediasUploaded : 0,
            openImportModal:true,
        })
        for(let media of this.state.mediasToImport){
            let formData =new FormData();
            let mediaFile;
            if(media.fromLink){
                mediaFile = await this.getFileFromURL(media.image,media.name);
            }
            let  file = mediaFile;
            formData.append('file', file);
            formData.append('type', media.type);
            formData.append('name',media.name.replace(/ /g, "_").toLowerCase());
            formData.append('alt',media.name);
            if(cats.length>0){
                let mediaCats = cats.map(cat=>parseInt(cat.node.id.replace('/api/media-object-categories/', ''),10));
                for (let mediaCat of mediaCats){
                    formData.append('categories[]',mediaCat);
                }     
            }
            let resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData); 
            if(tags?.length>0){
                let tagsToUpdate    = tags.filter(tag=>tag.id !== null);
                let tagsToCreate    = tags.filter(tag=>tag.id === null);
                await this.handleMediaTags(resultMedia.id,tagsToCreate,tagsToUpdate,null);

            }
            this.setState({
                mediasUploaded:this.state.mediasUploaded + 1,
            })     
        }
        this.setState({
            openImportModal:false,
            mediasToImport:[],
        },()=>{
            this.handleToggleDrawer('openFormImportStepper');
            this.handleToggleDrawer('openFormImport');
            this.filterMedias();
            this.props.snack(ALERT_SUCCESS,'Vos médias ont été importés avec succès, leur intégration dans SpreadSuite sera exécutée lors du passage du CRON (Tâche automatique).');

        })
        
    }

    saveMedia = async(action='add')=>{
        
        let cats        = this.state.parent;
        let tags        = this.state.fileTags;
        /*Retirer les parents des catégories enfantes
        let parentList=cats.map(category=>category.node.parent?.id);
        cats=cats.filter(cat=>!parentList.includes(cat.value))  */
        let resultMedia;
        let formData    = new FormData();   
        switch (action){

            case 'add':
                let  media      = this.state.formImage;
                formData.append('file', media.file);
                formData.append('type', media.file.type);
                formData.append('name',this.state.fileName.replaceAll(/ /g, "_").toLowerCase());
                formData.append('alt',this.state.fileAlt);
                formData.append('description',this.state.fileDescription);
               
                if(cats.length>0){
                    
                    let mediaCats = cats.map(cat=>cat.node ? parseInt(cat.node.id.replace('/api/media-object-categories/', ''),10) : cat);
                    for (let mediaCat of mediaCats){
                        formData.append('categories[]',mediaCat);
                    }           
                }
                
                resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                if(tags?.length>0){
                    let tagsToUpdate    = tags.filter(tag=>tag.id != null);
                    let tagsToCreate    = tags.filter(tag=>tag.id == null);
                    await this.handleMediaTags(resultMedia.id,tagsToCreate,tagsToUpdate,null);

                }
                this.filterMedias()
                break;

            case 'edit':
                let mediaCats = [];
                if(cats.length>0){
                    mediaCats = cats.map(cat=> cat.node.id);         
                }
                let editData = {
                    'alt' : this.state.fileAlt,
                    'name': this.state.fileName.replace(/ /g, "_").toLowerCase(),
                    'category': mediaCats,
                };
                resultMedia = await axios(`${process.env.REACT_APP_API_ROOT+this.state.currentId}`, 'PUT', editData,true,'application/json');
                if(tags?.length>0){
                    let tagsToAdd       = tags.filter(tag=>!this.state.currentTags.map(tag=>tag.node.id).includes(tag.id));
                    let tagsToUpdate    = tagsToAdd.filter(tag=>tag.id != null);
                    let tagsToCreate    = tagsToAdd.filter(tag=>tag.id == null);
                    let tagsToDelete    = this.state.currentTags.filter(currentTag=>typeof(tags.find(tag=>tag.id === currentTag.node.id)) === 'undefined')
                    await this.handleMediaTags(resultMedia.id,tagsToCreate,tagsToUpdate,tagsToDelete);

                }
                this.filterMedias()
                break;
            default:
                return null;
        }

    }


    handleMediaTags = (id,toCreate,toUpdate,toDelete)=>{
        return new Promise(async(resolve,reject)=>{
            if(toCreate){
                for(let tag of toCreate){
                    await this.props.client.mutate({
                            
                        mutation : ADD_TAG,
                        variables:{
                            tag : tag.tag,
                            mediaObjects : ['/api/media-objects/'+id],

                        }
                    })                    
                }

            }
            
            if(toUpdate){
                for(let tag of toUpdate){
                    tag.mediaObjects.push('/api/media-objects/'+id)
                    await this.props.client.mutate({
                        
                        mutation : UPDATE_TAG,
                        variables:{
                            id : tag.id,
                            mediaObjects : tag.mediaObjects,
                        }
                    })
                }  
            }

            if(toDelete){
                for(let tag of toDelete){
                    let target = '/api/media-objects/'+id;
                    let mediaObjects=tag.node.mediaObjects.edges.filter(media=>media.node.id !== target).map(media=>media.node.id);
                    await this.props.client.mutate({
                        mutation : UPDATE_TAG,
                        variables:{
                            id : tag.node.id,
                            mediaObjects : mediaObjects,
                        }
                    })
                }  
            }
            resolve();
        })
    }
    //#endregion


    /*COMPONENTS*/
    componentDidMount(){
        const getRights = hasRights(MEDIAS, MEDIAS_MEDIAS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.preparePage();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.formImage !== this.state.formImage) {
            let name=this.state.formImage?.file?.name.replace(/ /g, "_").toLowerCase();
            this.setState({
                fileName : name
            })
        }
    }

    render() {
        console.log('THIS.STATE',this.state)
        const normalise = value => (value - 0) * 100 / (this.state.mediasPending - 0);
        return (
            <div>
                <TopPanel 
                    icomoon         = "picto-media" 
                    colorIcomoon    = {colors.blue.lighter.hue300} 
                    title           = "Gérer les médias" 
                    subtitle        = "Gestion de vos médias (création / modification / suppression)" 
                    gradientColor1  = {colors.menu.regular} 
                    gradientColor2  = {colors.menu.darker}  
                    handlerAdd      = {() => this.addMedia()} 
                    textAdd         = {hasRights(MEDIAS, MEDIAS_MEDIAS, CREATE) ? "Ajouter un Media" : null}
                    buttonAvailable = {this.state.medias ? this.state.buttonAvailable : false}
                    handlerImport   = {() => this.handleFormImport()} 
                    textImport      = {hasRights(MEDIAS, MEDIAS_MEDIAS, IMPORT) ? "Importer des médias" : null}
                />
                <Grid container alignItems="center" style={{background: 'white', borderBottom: `1px solid ${colors.grey.lighter.hue800}`}}>
                    <Grid item xs={7}>
                        <TabCategoriesMedia categories={this.state.categories} rootid={this.state.rootid} handleChangeTab={this.handleChangeCategorie} currentLang={this.state.currentLang} isMedia={true}/>
                    </Grid>
                    <Grid item xs={5}>
                        <SearchTags 
                            allState={this.state} 
                            searchOptions={{
                                placeholder : 'Rechercher un ou plusieurs tag',
                                isFilter : true,
                            }}
                            stateCallback={this.filterByTags}
                        />
                    </Grid>
                </Grid>
                
                {this.state.ready ? 
                    <div>
                        <MediaContainer container>
                            <ChipMedias categories={this.cats} handleChangeTab={this.filterByType} defaultTab={this.props.history.location.state?.index?this.props.history.location.state.index:null}/>
                            <Grid item xs={12} style={{padding:30}}>
                                <Grid container spacing={2}>
                                    {
                                        this.state.medias ?
                                            this.state.medias.length > 0 ?
                                                (
                                                    this.state.medias.map((media,index) => 
                                                        <Grid item xs={12} sm={6} md={6} lg={3} key={`media-item-${index}`}>
                                                            <CardCustom contentpadding={0} paddingbottom={'0px'}>
                                                                <CardMedia 
                                                                    callback={this.handleCallbackModal} 
                                                                    allMedias={this.state.medias} 
                                                                    media={media} 
                                                                    // modal={this.handleMediaModal} 
                                                                    currentIndex={index} 
                                                                    windowWidth={this.props.windowWidth}
                                                                    handleEdit={hasRights(MEDIAS, MEDIAS_MEDIAS, UPDATE) ? this.editMedia : null}>
                                                                </CardMedia>
                                                            </CardCustom>
                                                        </Grid>
                                                    )
                                                )
                                            :
                                            <EmptyCard title={"Désolé nous n'avons pas trouvé de résultats à votre recherche"} subtitle={"Essayez avec d'autres critères"} picto={EmptyMedias} textButton={"Ajouter un media"} onClick={() => {this.handleToggleDrawer('addMediaForm')}}  xsImg={this.state.openForm ? 4 : 2} />

                                        :
                                        <PageLoader/>

                                    }                          
                                </Grid>
                                {
                                    this.state.ready && this.state.countPage > 1 && this.state.medias?.length > 0 ?
                                        <PaginationCustom onChange={(event, page) => {this.changePage(event, page)}} page={this.state.page} count={this.state.countPage} color="primary" />
                                        : null
                                } 

                            </Grid>

                        </MediaContainer> 

                        {this.state.openMediaForm ? 

                            <LayoutFormBuilder
                                icomoon         = "ico-importer-media"
                                opened          = {this.state.openMediaForm} 
                                forClose        = {() => {this.handleToggleDrawer('addMediaForm')}}
                                dataLayout      = {this.state.formAction === 'edit'?mediasEditConfig(this.state.categoriesData,this.state.parent,this.state.filePath,this.state.mediaType) :mediasConfig(this.state.categoriesData,this.state.parent)}
                                allState        = {this.state} 
                                stateCallback   = {this.handleInputChange}
                                handlerMutation = {this.state.formAction === 'edit'?()=>{this.handleAddMedia('edit')} :()=>{this.handleAddMedia('add')}}
                                validateButton  = {true}
                                stepperButtonAction={[
                                    this.handleNextStep,
                                    this.handleNextStep,
                                    
                                ]}
                                errorCallback={this.handleFormError}


                            />

                        : null}
                        {this.state.modalData 
                        
                        ? 
                            <MediaModal
                                open                = {this.state.openModal}
                                onClose             = {this.handleCloseModal}
                                modalData           = {this.state.modalData}
                                handleNav           = {this.handleNextMedia}
                                lastMedia           = {this.state.medias?.length-1}
                                handleDeleteModal   = {hasRights(MEDIAS, MEDIAS_MEDIAS, DELETE) ? this.handleDeleteModal : null}
                            />

                        : null}

                        {this.state.importMediasCard ? 
                        <LayoutBuilder
                            opened={this.state.openFormImport}
                            forClose = {()=>{this.handleToggleDrawer('openFormImport')}}
                            dataLayout = {mediasImportConfig}
                            dataCard = {this.state.importMediasCard}
                        />
                        :null}
                        {this.state.availableChannels ? 
                            <LayoutBuilder
                                icomoon         = "ico-importer-media"

                                opened={this.state.openFormImportStepper}
                                forClose={()=>this.handleToggleDrawer('openFormImportStepper')}
                                dataLayout={mediasImportStepperConfig(this.state.availableChannels,this.state.categoriesData,this.state.parent)}
                                allState={this.state}
                                stateCallback = {this.handleInputChange}
                                stepperButtonAction={[
                                    this.handleNextStep,
                                    this.handleNextStep,
                                    this.handleNextStep,
                                    this.handleNextStep,
                                ]}
                                backStepperButtonAction={[
                                    ()=>this.setState({mediasToImport:[]}),
                                    ()=>this.setState({mediasToImport:[]}),
                                    null,
                                    null,
                                    null
                                ]}
                                errorCallback={this.handleFormError}
                                handlerMutation={this.handleImportMedia}
                            />
                        :null}
                    </div>
                    
                    
                : <PageLoader/>}

                {/* <DialogModal
                    open            = {this.state.openDeleteModal}
                    secondaryAction = {()=>{this.deleteMedia('cancel')}}
                    primaryAction   = {()=>{this.deleteMedia('delete')}}
                    title           = 'Supprimer ce média' 
                >
                    <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce média ? <strong>Cette action est irréversible</strong></Typography>
                </DialogModal> */}

                {this.state.openImportModal ? 
                    <Dialog
                    onClose={()=>{}}
                    open = {this.state.openImportModal}
                    fullWidth={true}
                    maxWidth='sm'
                    >
                        <DialogTitle id="max-width-dialog-title">Import en cours</DialogTitle>
                        <DialogContent>
                            <Grid container justify="center">
                                
                                <Typography variant="h4" style={{width:'100%',textAlign:'center'}}>{this.state.mediasUploaded} / {this.state.mediasPending} medias uploadés</Typography>
                                <LinearProgress variant="determinate" value={normalise(this.state.mediasUploaded)} style={{width:'80%',height:6,backgroundColor:colors.blue.lighter.hue300,marginTop:20}}/>
                            </Grid>                            
                        </DialogContent>
                        <DialogActions/>

                    </Dialog>
                :null}

                    
            </div>
        );
    }

    copyArrayOfObjects = array => array.map(a => ({...a})); // be careful, only breaks references at objects level

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(MediasCategories)));
