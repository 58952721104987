import React, { Fragment } from "react";
import { 
    HeaderGame,
    PreHeaderGame,
    FooterGame,
    HomeGame,
    HomeCompleteFormGame,
    LoginGame,
    IndexGame,
    ResultsWinGame,
    ResultsLooseGame,
    AlreadyPlayedGame,
    EndedGame,
    NotStartedGame,
    ExplanationsGame,
    ExplanationsACVGame,
    ExplanationsBFGame,
    ExplanationsMoreDetailsGame,
    RedirectOn,
    RedirectOn2,
    RedirectOn3,
    RedirectOn4,
    BlocBasicGame,
    BlocBasic2Game,
    AmazingBlocGame
} from "../../../../shareable/components/assets/game/components";

import { Toolbar } from "../../../../shareable/tools";
import { ItemTypes } from "../../../../shareable/types";

// DND
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const getComponent = (element, index, preview, Droppable, updateConfig, deleteComponent, inputCallback) => {
    switch (element.type) {
        case ItemTypes.HEADER_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <Droppable type={ItemTypes.HEADER_GAME} id={element.key - 1} index={index - 1} />
                        <HeaderGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </HeaderGame>
                        <Droppable type={ItemTypes.HEADER_GAME} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.PRE_HEADER_GAME:
                return (<Fragment key={element.key}>
                    <PreHeaderGame
                        id={element.key}
                        noSticky={preview}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </PreHeaderGame>
                </Fragment>)
            case ItemTypes.FOOTER_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <Droppable type={ItemTypes.FOOTER_GAME} id={element.key - 1} index={index - 1} />
                        <FooterGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                        </FooterGame>
                    </DndProvider>
                </Fragment>);
            case ItemTypes.HOME_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <HomeGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </HomeGame>
                        <Droppable type={ItemTypes.HOME_GAME} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.HOME_COMPLETE_FORM_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <Droppable type={ItemTypes.HOME_COMPLETE_FORM_GAME} id={element.key - 1} index={index - 1} />
                        <HomeCompleteFormGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </HomeCompleteFormGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.LOGIN_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <LoginGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                        />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.INDEX_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <IndexGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                        >
                        </IndexGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.RESULTS_WIN_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <ResultsWinGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                            toolbarBlockSocial={<Toolbar name={element.data.blocks.socialBlock.name} onEdit={() => inputCallback(element.key, 'socialBlock')} />}
                        />
                        <Droppable type={ItemTypes.RESULTS_WIN_GAME} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.RESULTS_LOOSE_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <ResultsLooseGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                            toolbarBlockSocial={<Toolbar name={element.data.blocks.socialBlock.name} onEdit={() => inputCallback(element.key, 'socialBlock')} />}
                        />
                        <Droppable type={ItemTypes.RESULTS_LOOSE_GAME} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.ALREADYPLAYED_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <AlreadyPlayedGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                        />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.ENDED_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <EndedGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                        />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.NOT_STARTED_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <NotStartedGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                        />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.REDIRECT_ON_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <Droppable type={ItemTypes.REDIRECT_ON_GAME} id={element.key - 1} index={index - 1} />
                        <RedirectOn
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </RedirectOn>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.REDIRECT_ON_GAME2:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <Droppable type={ItemTypes.REDIRECT_ON_GAME2} id={element.key - 1} index={index - 1} />
                        <RedirectOn2
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </RedirectOn2>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.REDIRECT_ON_GAME3:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <Droppable type={ItemTypes.REDIRECT_ON_GAME3} id={element.key - 1} index={index - 1} />
                        <RedirectOn3
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </RedirectOn3>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.REDIRECT_ON_GAME4:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <Droppable type={ItemTypes.REDIRECT_ON_GAME4} id={element.key - 1} index={index - 1} />
                        <RedirectOn4
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </RedirectOn4>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.EXPLANATIONS_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <ExplanationsGame
                            id={element.key}
                            noSticky={preview}
                            update={updateConfig}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                        >
                        </ExplanationsGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.EXPLANATIONS_ACV_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <ExplanationsACVGame
                            id={element.key}
                            noSticky={preview}
                            update={updateConfig}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                        >
                        </ExplanationsACVGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.EXPLANATIONS_BF_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <ExplanationsBFGame
                            id={element.key}
                            noSticky={preview}
                            update={updateConfig}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                        >
                        </ExplanationsBFGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <ExplanationsMoreDetailsGame
                            id={element.key}
                            noSticky={preview}
                            update={updateConfig}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                        >
                        </ExplanationsMoreDetailsGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.AMAZING_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <AmazingBlocGame
                            id={element.key}
                            noSticky={preview}
                            update={updateConfig}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.insideBlock.name} onEdit={() => inputCallback(element.key, 'insideBlock')} />}
                        >
                            <Droppable type={ItemTypes.AMAZING_GAME} id={element.data.blocks.insideBlock.id} index={index} inside={true} />
                        </AmazingBlocGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.BASIC_BLOC:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <BlocBasicGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </ BlocBasicGame>
                        <Droppable type={ItemTypes.BASIC_BLOC} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.BASIC_BLOC2:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <BlocBasic2Game
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </ BlocBasic2Game>
                        <Droppable type={ItemTypes.BASIC_BLOC} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
    }
}