import React from 'react';
import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/not-found.png';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import StoreIcon from '@material-ui/icons/Store';
import _ from 'lodash';
import UpdateIcon from '@material-ui/icons/Update';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import * as moment from 'moment';

const BoxCustom = styled(Box)`
    width: auto;
`;
const BoxCustomAttributs = styled(Box)`
    background: ${colors.grey.lighter.hue980};
`;
const TitleAttributs = styled(Typography)`
    font-weight: bold;
    color: ${colors.blue.regular};
`;
const TypographyAttributs = styled(Typography)`
    color: ${colors.grey.regular};
    p{
        margin: 0;
    }
`;
const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
    font-weight: bold;
`;
const Subtitle = styled(Typography)`
    color: ${colors.blue.regular};
    font-weight: bold;
`;
const AttributContainer = styled(Grid)`
    margin-bottom: 16px;
`;
const ContainerCard = styled(Box)`
    display: block;
    height: 100%;
    padding-top: 8px;
`
const ButtonCustom = styled(Button)`
    margin: 0;
    text-transform: unset;
    font-weight: bold;
`
const Title = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.nbLine ? props.nbLine : '2'};
    -webkit-box-orient: vertical;
`
const Sku = styled(Typography)``;
const ProductAttr = styled(Typography)``;
const ContainerButton = styled(Box)`
    display: flex;
    justify-content: flex-end;

`
const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`

export default function CardShell(props){

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.blue.regular,
        },
        tooltip: {
            backgroundColor: colors.blue.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }

    const shell = props.shell;
    const attributes = shell.shellDatas.edges.filter(e => e.node.attribute.useInCard);
    let name = '';
    let listImages = attributes.filter(e => e.node.media && e.node.locale.code === props.currentLang)

    const goTo = props.goTo;
    return(
        <ContainerCard>
            <Grid container style={{position: 'relative', height: '100%'}}>
                <Grid item xs={5}>
                    <Box pr={4}>
                        <Grid container alignItems="center" justify="center" style={{minHeight: 180}}>
                            <img src={listImages.length > 0 ? `${process.env.REACT_APP_MEDIAS}/${listImages[0].node.media.filePath}` : NoImage} style={{maxHeight: 180, maxWidth: '100%'}} alt={name} />
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={7} style={{display: 'flex', flexDirection: 'column'}}>
                    <Box>
                        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Box style={{width: 'auto'}}>
                                <StatusInfo status={shell.status}></StatusInfo>
                            </Box>
                        </Box>
                        <Box pt={1}>
                            <Title variant="h4" nbLine={props.type === 'dashboard' ? '1' : '2' }>{shell.sku}</Title>
                        </Box>
                    </Box>
                    {
                        <ContainerButton pt={1} style={{justifyContent: "flex-end"}}>
                            <ButtonCustom disableElevation={true} text="Modifier" bgColor={colors.green.regular} onClick={goTo} fontsize={ 12 } style={props.type === "dashboard" ? {height: '100%', padding: 6} : null} />
                        </ContainerButton>
                    }
                </Grid>
                <Grid container>
                    {
                        attributes && attributes?.length
                            ? (
                                <Box pt={4} style={{width:"100%"}}>
                                    <Subtitle variant="body2">Attributs</Subtitle>
                                    <BoxCustomAttributs mt={2} py={2} px={1}>
                                        <Grid container>
                                            {
                                                attributes
                                                    ? attributes.filter(e => e.node.attribute.attributeType.input !== 'image' && e.node.locale.code === props.currentLang).map((e, index) => {
                                                        let getValue = getTraductionAttributs(e.node.attribute.identifier, attributes, props.currentLang, e.node.attribute.attributeType.input);
                                                        const defaultLabel = e.node.attribute.translation.translationDatas.edges[0];
                                                        const label = e.node.attribute.translation.translationDatas.edges.find(
                                                            lang  => lang.node.locale.code === props.currentLang
                                                        );
                                                        if (getValue){
                                                            return(
                                                                <Grid container alignItems={"center"} style={{marginTop: index === 0 ? 0 : 8}}>
                                                                    <Grid item xs={8} >
                                                                        <TitleAttributs variant="body2">
                                                                            { 
                                                                                label?.node.value 
                                                                                    ?? defaultLabel?.node.value 
                                                                                    ?? e.node.attribute.identifier 
                                                                            }
                                                                        </TitleAttributs>
                                                                    </Grid>
                                                                    <Grid item xs={4} >
                                                                        {
                                                                            e.node.attribute.attributeType.input === "textarea" ?
                                                                            <Typography dangerouslySetInnerHTML={{ __html: getValue }} />
                                                                            : e.node.attribute.attributeType.input === "date" ? 
                                                                                <Typography>{moment(getValue).format('DD/MM/YYYY')}</Typography>
                                                                                : e.node.attribute.attributeType.input === "mail" ?
                                                                                    <a href={`mailto:${getValue}`}><Typography>{getValue}</Typography></a>
                                                                                    : e.node.attribute.attributeType.input === "file" ?
                                                                                        <a href={`${process.env.REACT_APP_MEDIAS}/${getValue.filePath}`} target={'__blank'}>
                                                                                            <Typography style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{getValue.filePath}</Typography>
                                                                                        </a>
                                                                                        : e.node.attribute.attributeType.input === "link" ? 
                                                                                            <a href={getValue} target={'__blank'}>
                                                                                                <Typography style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{getValue}</Typography>
                                                                                            </a>
                                                                                            : <Typography>{getValue}</Typography>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        }
                                                    }) 
                                                    : null
                                            }
                                        </Grid>
                                    </BoxCustomAttributs>
                                </Box>
                            ) : null
                    }
                </Grid>
            </Grid>
        </ContainerCard>
    );
}