import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Context } from "../shareable/store";
import { BuilderContext } from "../builder";
import logo from "../../assets/images/logo-spread.svg";
import styled from "styled-components";
import { Fab } from "@material-ui/core";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LinkIcon from '@material-ui/icons/Link';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import Select from "react-select";
import makeAnimated from "react-select/animated";
import Button from '../../components/ui/button/Button';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getTemplate } from "../templates";

const SelectCustom = styled(Select)`
    &>div{
        border-radius: 0;
        border: 0;
        div{
            &[class*=singleValue]{
                color: white;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &[class*=IndicatorsContainer]{
                position: relative;
                &>div{
                    color: white;
                    padding-right: 16px;
                }
                &>span{
                    margin-bottom: 5px;
                    margin-top: 5px;
                    width: 1px;
                    height: calc(100% - 10px);
                    right: 0;
                    position: absolute;
                }
            }
        }
    }
`;

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.common.white,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.main,
            },
        },
    },
}))(MenuItem);

export const BuilderLeft = () => {
    const { 
        asset, 
        ready,
        catalog,
        products, updateProducts,
        data,
        save, reinit,
        page,
        onlyLeft,
        noRight, setNoRight,
        preview, setPreview,
        pages, 
        pagesImg,
        getPageName,
        changePage,
        height,
        presentation, presentationModel, offer,
        exportLink,
        setSizes,
        dev
    } = React.useContext(BuilderContext);

    const [openChangerPage, setOpenChangerPage] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const animatedComponents = makeAnimated();

    const handleClick = (event) => {
        console.log(event)
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const PageChanger = () => {
        return (
            <div className="changer-page">
                <div className="selecter" onClick={() => setOpenChangerPage(!openChangerPage)}>
                    <p>{ getPageName(page) }</p>

                    {
                        openChangerPage 
                            ? <KeyboardArrowUpIcon />
                            : <KeyboardArrowDownIcon />
                    }
                </div>

                {
                    openChangerPage ? (
                        <div className="dynamicChangerPage">
                            <CloseIcon style={{ fill: 'white' }} onClick={() => setOpenChangerPage(!openChangerPage)} />

                            {
                                pages && asset && pages.map((page, index) => {
                                    return (
                                        <div key={index} onClick={() => changePage(page.value)}>
                                            <img
                                                style={{ width: (pages.length % 3 === 0 || (pages.length < 6 && pages.length !== 4)) ? '26vw' : '25vw', height: pages.length < 9 ? '40vh' : '30vh' }}
                                                src={typeof page.image === "string" ? page.image : page.image?.default}
                                            />
                                            <p>{ page.label }</p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    ) : null
                }
            </div>
        )
    };

    const PageSelect = () => {
        return (
            <SelectCustom
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                defaultValue={pages.find(e => e.value === page)}
                options={pages}
                isMulti={false}
                isSearchable={false}
                placeholder="Changer de page..."
                noOptionsMessage={() => "Aucun résultat"}
                components={{ ...animatedComponents }}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        width: 220,
                        background: 'transparent',
                        color: 'white',
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        zIndex: 150,
                        padding: 0
                    }),
                    input: (provided, state) => ({
                        ...provided,
                        color: 'white',
                    })
                }}
                onChange={(item) => changePage(item.value)}
            />
        );
    };

    return (
        <div className="left" style={{ height, width: onlyLeft || noRight ? "100%" : "70%"}}>
            <div className="top-bar" style={{display: onlyLeft ? "none" : "flex", justifyContent: 'space-between', width: "calc(100% - 15px)", boxShadow: '3px 3px 15px rgb(0 0 0 / 28%)' }}>
                <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                    <img src={logo} alt="logo-spread" />
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', height: '100%'}}>
                    {
                        pages.length
                            ? pagesImg === pages.length
                                ? <PageChanger /> 
                                : <PageSelect />
                            : null
                    }

                    { (presentation || presentationModel) && (
                        <CopyToClipboard text={`https://presentation.sinfin.fr/${offer?.alias}`} onCopy={exportLink}>
                            <Button className="builder-link-button" colortext="white" bgcolor="transparent">
                                <LinkIcon />
                            </Button> 
                        </CopyToClipboard>
                    )}

                    <div style={{height: 40, borderRight: '2px solid #2B4553', marginRight: 10, paddingRight: 10}}>
                        <Fab
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="extended"
                            style={{background:'white', boxShadow: 'inherit', height: '100%'}}
                            onClick={(e) => handleClick(e)}
                        >
                            <MoreHorizIcon/>
                        </Fab>
                        <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <StyledMenuItem onClick={save}>
                                <ListItemIcon>
                                    <SaveIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sauvegarder" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={reinit}>
                                <ListItemIcon>
                                    <RefreshIcon />
                                </ListItemIcon>
                                <ListItemText primary="Réinitialiser" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => setPreview(!preview)}>
                                <ListItemIcon>
                                    {preview ? <Visibility /> : <VisibilityOff />}
                                </ListItemIcon>
                                <ListItemText primary="Prévisualiser" />
                            </StyledMenuItem>
                        </StyledMenu>
                    </div>

                    <Fab color="primary" style={{boxShadow: 'inherit'}} size="small" className="builder-expand-button" onClick={() => {setSizes(noRight ? [70, 30] : [100, 0]); setNoRight(!noRight)}}>
                        <ArrowForwardIosIcon style={{ transform: noRight ? 'rotate(180deg)' : '' }} />
                    </Fab>
                </div>
            </div>

            <div 
                className="content" 
                style={{ 
                    top: onlyLeft ? 0 : 60, 
                    height: onlyLeft 
                        ? "100%" 
                        : "calc(100% - 60px)" 
                }}>
                <Context.Provider value={{ products, update: (products) => updateProducts(products) }}>
                    { 
                        ready && (presentation ? offer : dev ? true : catalog) 
                            ? getTemplate(...data) 
                            : <p className="builder-loading">Chargement...</p> 
                    }
                </Context.Provider>
            </div>
        </div>
    );
}