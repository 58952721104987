import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function collectionEdit(action='add') {
    let generalInputs   = [{
        required : true,
        type : 'text',
        label : 'Identifier',
        stateName : 'collectionIdentifier',
        helper: {
            text: 'Helper non disponible pour le moment',
            link: false,
        },
    }]
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add'? 'Créer une collection':'Modifier une collection',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer':'modifier'} votre collection`,
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Valider la création de cette collection' : 'Valider la modification de cette collection',
                textButton: action === 'add' ? 'Créer' : 'Modifier'
            },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}