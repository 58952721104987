import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import ContactCategoriesTabs from './ContactCategoriesTabs';
import SearchBar from '../../../../ui/search/SearchBar';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import CardContact from '../../../../layouts/Card/cardContent/CardContact';
import CardCustom from '../../../../layouts/Card/CardCustom';
import Pagination from '@material-ui/lab/Pagination';
import Listing from '../../../../layouts/Listing/Listing'
const defaultCategories = [
    {
        libelle : 'Tout voir',
        value : null
    },
    {
        libelle : 'Clients',
        value : 'client'
    },
    {
        libelle : 'Leads',
        value : 'lead'
    },
    {
        libelle : 'Contacts',
        value : 'contact'
    },
]
const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;
const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

const styles = makeStyles((theme)=>({
    root : {
        padding : '0 20px 30px'
    },
    customersContainer:{
        paddingTop : 16
    },
}))

const listSettings = {
    grid : {
        components : CardContact, //Composant de carte qui sera utilisé
        columns : {
            xs : 1,
            sm : 2,
            laptop : 2,
        } //Nombre de colonnes de la grille par breakpoints
    },
}

const listMappers = [
    {
        view : 'card',
        mapper : null
    }
]

const perPageOptions = {
    'card':[6],
}
export default function ContactList(props) {
    if(props.isFormComponent){
        props = props.customProps;
    }
    
    const debug = true;
    const classes       = styles();
    const categories    = props.categories ? props.categories : defaultCategories;
    const smallScreen   = props.smallScreen ? props.smallScreen : false;
    const pagination    = props.pagination ? props.pagination : false;
    const isForm        = props.isForm ? props.isForm : false;

    const search        = props.search === false ? props.search : true;

    return(
        <Grid container className={classes.root}>
            {smallScreen ? 
                <>
                {search ?
                <Grid item xs={12}>
                    <Grid container justify="flex-end" style={{paddingBottom : 30}}>
                        <Grid item xs={6} >
                            <SearchBar onChange={props.handleSearch} placeholder="Rechercher un contact" type={isForm}></SearchBar>
                        </Grid>
                        
                    </Grid>
                </Grid>                
                :null}

                <Grid item xs={12}>
                    <ContactCategoriesTabs 
                        categories={categories} 
                        handleCategory={props.handleCategory}
                        isForm= {isForm}
                    />
                </Grid>
                </>
            :
                <>
                <Grid item xs={6}>
                    {props.showTab ? <ContactCategoriesTabs 
                        categories={categories}
                        // currentCategory={props.currentCategory} 
                        handleCategory={props.handleCategory}
                        currentStatus = {props.currentStatus}
                    />
            : null}
                </Grid>
                {search ? 
                
                <Grid item xs={6}>
                    <SearchBar 
                        onChange={props.handleSearch} 
                        placeholder="Rechercher un contact ou une société"
                        type={isForm}
                    />
                </Grid>                  
                :null}

                </>
            }

            {debug ? 
                <Grid
                    container
                    className={classes.customersContainer}
                >
                    <Listing
                        identifier = 'customers'
                        label = 'contacts'
                        settings = {listSettings}
                        viewsOptions = {{
                            current : 'card',
                        }}
                        cardProps = {{
                            select:props.select,
                            currentCompany : props.currentCompany,
                            handleSelect : props.handleSelect,
                            style : {height:'100%'},
                            isForm : true,
                            isCard: props.isCard           

                        }}

                        cardContainerProps = {{
                            hovercard:props.isCard ? true : false ,
                            style:{height:'100%'},
                            contentpadding : props.isCard ? '0 8px 8px 8px' : '',
                        }}


                        perPageOptions = {perPageOptions}
                        mappers = {listMappers}
                        currentLang = {props.currentLang}
                        queryVariables={props.queryVariables}
                        
                        

                
                    />
                </Grid>
            :
            <>
                <Grid container>
                    
                    <Grid
                        container
                        spacing={2}
                        className={classes.customersContainer}
                    >
                        {props.isReady ? props.items.map((item)=>{
                            return(
                                <GridCustom item xs={ !props.isCard ? 12 : smallScreen || props.windowWidth < 1500  ? 6 : 4} >
                                    
                                        <CardContact 
                                            key={`customer-${item.node.id}`}
                                            data={item} 
                                            select={props.select}
                                            currentCompany={props.currentCompany}
                                            currentLang={props.currentLang}
                                            handleSelect={props.handleSelect}
                                            style={{height:'100%'}}
                                            isForm={true}
                                            isCard={props.isCard}
                                        />                                  

                                    
                                </GridCustom>
                            )
                        })
                        :
                        <PageLoader/>
                    }
                    
                    </Grid>
                </Grid>
                {pagination ?
                    props.paginationSettings.count>1 ?
                        <Grid item xs={12}>
                            <Grid container justify="center">
                                <PaginationCustom page={props.paginationSettings.page} count={props.paginationSettings.count} onChange={(event, page) => {props.paginationSettings.changePage(event, page,isForm)}}></PaginationCustom> 
                            </Grid>
                        </Grid>
                    :null
                : null}          
            </>  
            }

            
        </Grid>

    )
    
}