import React, { useEffect, useRef, useState } from "react";
import H3 from '../shared/h3';
import Div from '../shared/div';
import Button from '../shared/a';
import styled from 'styled-components';


const Slogan = styled(Div)`
    max-width: 1500px;
    margin: auto;
    padding: 0 1rem;
    padding-top: 15px;
    h3{
        font-size: 23px;
        line-height: 28px;
        font-family: 'DinRegular', sans-serif;
        font-weight: normal;
    }
    @media screen and (min-width: 768px){
        padding-top: 40px;
        padding-bottom: 30px;
        text-align: center;
        h3{
            font-size: 30px;
            line-height: 49px;
        }
    }
    @media screen and (min-width: 1024px){
        padding-top: 80px;
        padding-bottom: 58px;
        h3{
            font-size: 49px;
            line-height: 59px;
        }
    }
`
const SloganBlock = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;
    console.log('data', data)

    return (
        <Div style={{position: 'relative'}} className="slogan" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Slogan>
                <H3>{data.blocks.title.inputs.value.value}</H3>
            </Slogan>
        </Div>
    );
};

export default SloganBlock;