import { FormInputTypes } from '../../../shareable/types';
import { getDefaultTextCms } from '../../helpersCms';

export default {
    name: 'Bloc Titre et Texte',
    inputs: {
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
        centerText: {
            label: 'Centrer les textes',
            type: FormInputTypes.SWITCH,
            value: false,
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "LE MANIFESTE EMPREINTE DU COQ SPORTIF ET DE SES ATHLÈTES", '#000'),
        text: getDefaultTextCms("Titre", "Ce Manifeste est écrit selon le principe de l’acrostiche : chaque lettre du mot EMPREINTE exprime une valeur forte, partagée par notre collectif d’athlètes. Il a été rédigé à partir des mots réels qu’emploient ces grands sportifs pour parler de leur relation au sport.", '#000'),
    }
};