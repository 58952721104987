import { EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultSlideImage, getDefaultBackgroundCms } from '../../helpersCms';

export default {
    name: "Grille d'image",
    blocks:{
        background: getDefaultBackgroundCms('rgba(255,255,255,0)'),
        slides:{
            title: "Image",
            name: "Image",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_GRID_IMAGE,
            buttonAdd: 'une image',
            limit: 10,
            config: [ 
                getDefaultSlideImage(),
                getDefaultSlideImage(),
                getDefaultSlideImage(),
            ]
        }
    }
};