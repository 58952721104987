import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultCollectionHome } from '../../helpersCms';

export default {
    name: 'Collections mises en avant',
    inputs: {
        value: {
            type: FormInputTypes.TEXT,
            label: "Titre de la section",
            value: "Collections LCS"
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: 'FAFAFB',
        },
        typeSlider: {
            label: 'Afficher en tant que slider',
            type: FormInputTypes.SWITCH,
            value: false,
        },
    },
    blocks:{
        collections:{
            title: "Collections",
            name: "Collections",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_COLLECTIONS_HOME,
            buttonAdd: 'une collection',
            limit: 6,
            config: [ 
                getDefaultCollectionHome()
            ]
        }
    }
};