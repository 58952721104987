import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import FlipBook from '../FlipBook/FlipBook';
import H1 from '../../../../shared/components/CustomH1';
import H2 from '../../../../shared/components/CustomH2';
import Div from '../../../../shared/components/CustomDiv';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const GiftsList = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [position, setPosition] = useState(null);
    const [cart, setCart] = useState([]);
    const [qtyPanier, setQtyPanier] = useState([]);
    const [modal, setModal] = useState(false);
    const newCategories = props.categories.slice(1);
    const [forcePage, setForcePage] = useState(null);

    let {
        preview = false,
        id = null, 
        data = null,
        pagesFlipbook = null,
        noSticky,
        categories= null,
        inputCallback = null,
        children = null,
        products,
        spread,
        params = null,
        readonly = false
    } = props;

    console.log(props);

    const resetCat = (pageNumber) => {
        setPosition(pageNumber);
    }

    function handleClick(filter) {
        setPosition(filter.attributes.category_flipbook_position?.fr_FR)
        document.getElementById('flipBook').scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const getMediaOrigin = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // DEV
            return process.env.REACT_APP_MEDIAS;
        } else {
            // PROD
            return process.env.REACT_APP_SPREAD_CDN ? `${process.env.REACT_APP_SPREAD_CDN}/medias` : process.env.REACT_APP_MEDIAS; 
        }
    };

    function openModal(status){
        setModal(status)
    }

    function getCart(array){
        setCart(array)
        renderQty(array)
    }

    function renderQty(array){
        let counterQty = 0
        if (array.length > 0){
            for (let element of array){
                if (element.product){
                    counterQty = counterQty + element.product.qty
                }
            }
        }
        setQtyPanier(counterQty)
    }

    let header;
    let flipbook;
    let subBlock;
    let giftBlock;
    let image;
    let switchValue = props.params.switch.value;

    if(params?.variante?.value === 'cart'){
        header = data.blocks.header;
        flipbook = data.blocks.flipbook;
        image = flipbook?.inputs?.backgroundImage?.value;
    }
    else{
        flipbook = data.blocks.flipbook;
        subBlock = data.blocks.subBlock;
        giftBlock = data.blocks.giftBlock;
    }
    return (
        <div 
            className={readonly ? "gift_list_container readOnly" : params?.variante?.value === "cart" ? "gift_list_container cart" : "gift_list_container"} 
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            style={{
                backgroundColor:  params?.variante?.value !== "cart" ? data.inputs?.['backgroundColor']?.value : null,
                backgroundImage: params?.variante?.value !== "cart" ? typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none' : null,
                backgroundSize: "100%",
                backgroundPosition: "bottom center",
                backgroundRepeat: "no-repeat",
                position: 'relative'
            }}
        >
            {
                params?.variante?.value === "cart" && header ?
                    <div className={`header_cart ${spread ? '' : 'sticky'}`} style={{position: "relative", backgroundColor: header.inputs.backgroundColor.value}}>
                        { !preview && props.toolbarHeader}
                        <div className="container">
                            <div className="wrapper_header_left">
                                <a href={process.env.REACT_APP_LINKED_MAGENTO}>
                                    <div className="logo">
                                        <img 
                                            src={
                                                typeof header.blocks.logo.inputs.value.value === 'string' ?
                                                    header.blocks.logo.inputs.value.value : 
                                                    header.blocks.logo.inputs.value?.value?.filePath ? 
                                                    ` ${process.env.REACT_APP_MEDIAS}/${header.blocks.logo.inputs.value.value.filePath}` 
                                                    : "http://via.placeholder.com/200x100/FFFFFF/53839D?text=Logo"
                                            } 
                                            alt="Logo" 
                                            style={{ maxHeight: 90, maxWidth: 170 }}
                                        />
                                    </div>
                                </a>
                                <span className="homeIcon" onClick={(e) => {
                                    e.preventDefault();
                                    setForcePage(new Date().getTime());
                                }}>
                                    <HomeIcon style={{fill: header.inputs.primaryColor.value}} />
                                </span>
                            </div>
                            {!switchValue ?
                                <div className="cart_wrapper" onClick={() => openModal(!modal)}>
                                    <div id="dummy_debris" className="debris" />
                                    <div className="cart-icon">
                                        <ShoppingCartIcon style={{fill: header.inputs.primaryColor.value}} />
                                        <div className="product_qty" style={{background: header.inputs.secondaryColor.value}}>{qtyPanier}</div>
                                    </div>
                                    <span style={{color: header.inputs.primaryColor.value}} >VOIR MA LISTE</span>
                                </div>
                            :
                                null
                            }
                        </div>
                    </div>
                : null
            }

            <div className="wrapper_container"
                style={{
                    backgroundColor: data.inputs?.['backgroundColor']?.value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: "100%",
                    backgroundPosition: "bottom center",
                    backgroundRepeat: "no-repeat",
                    position: 'relative'
                }}
            >
                { !preview && props.toolbarSection }
                <section className="title">
                    <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true} />
                    <H2 data={data} preview={preview} component={id} block={'subtitle'} inputCallback={inputCallback} nopadding={true} />
                </section>
                <div className="wrapper_card">
                    <div className="card_container" style={{ position: 'relative', backgroundColor: subBlock?.inputs?.['backgroundColor']?.value}} onMouseEnter={() => setHoverBlock(true)} onMouseLeave={() => setHoverBlock(false)}>
                        { !preview && hoverBlock && props.toolbarSubSection}
                        <div className="filters_wrapper">
                            <Div data={subBlock} className={params?.variante?.value === "wishlist" ? "title_filter" : "not-displayed"} preview={preview} component={id} block={'title'} inputCallback={() => inputCallback(id, 'title')} nopadding={true} />
                            <div className={params?.variante?.value === "wishlist" ? "list_filters" : "not-displayed"}>
                                {
                                    newCategories.length > 0 ?
                                    newCategories.map((filter,index) => {
                                        return(
                                            <div className="filter_block" 
                                            // style={{background: `url(${filter.image})`}} 
                                            key={`filterBlock${index}`}
                                            onClick={() => handleClick(filter)}
                                            >
                                                <img src={filter.attributes.category_image?.fr_FR ? `${getMediaOrigin()}/${filter.attributes.category_image.fr_FR}` : null} alt={`Filtre numéro ${index}`}/>
                                            </div>
                                        )
                                    })
                                    : null
                                }
                            </div>
                            
                            <FlipBook 
                                data={props.pagesFlipbook} 
                                pagePosition={position} 
                                resetCat={resetCat} 
                                products={products} 
                                spread={spread} 
                                modal={modal} 
                                openModal={openModal} 
                                readOnly={readonly} 
                                getCart={getCart} 
                                isCartMode={params?.variante?.value === "cart" ? true : false}
                                params={params}
                                forcePage={forcePage}
                                primaryColor={params?.variante?.value === "cart" && header ? header.inputs.primaryColor.value : null}
                                secondaryColor={params?.variante?.value === "cart" && header ? header.inputs.secondaryColor.value : subBlock?.inputs?.['backgroundColor2']?.value}
                                switchValue={params?.variante?.value === "cart" && header ? header.inputs.switch.value : null}
                                paramFlipbook={subBlock}
                                paramGifts={giftBlock}
                                inputCallback={inputCallback}
                                id={id}
                                toolbarGiftBlock={props.toolbarGiftBlock}
                            />
                        </div>
                    </div>

                    { params?.variante?.value === "cart" && <div className="space-div" style={{ width: '100%', height: 50 }} /> }
                </div>
            </div>
        </div>
    );
};

GiftsList.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default GiftsList;
