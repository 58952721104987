import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardContactDetails from '../../../layouts/Card/cardContent/CardContactDetails';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import * as moment from "moment";
import { Grid } from '@material-ui/core';
import InputBuilder from '../../../ui/form/InputBuilder';
import { ROUTE_CRM_CONTACTS_LIST, ROUTE_CRM_CONTACTS_CLIENTS, ROUTE_CRM_CONTACTS_LEADS, ROUTE_HOME} from '../../../../js/constants/route-names';
import { eventService } from '../../../../js/services/event.service';
import { GET_ATTR_GROUPE_WITH_ATTRIBUTES } from '../../../../queries/attributes';
import { SEARCH_COMPANIES, GET_COMPANIES_CURSOR, GET_COMPANIES_PAGINATION} from '../../../../queries/companies';
import {GET_CUSTOMERS_COMPANIES,ADD_CUSTOMER,UPDATE_CUSTOMER,ADD_CUSTOMER_DATA,UPDATE_CUSTOMER_DATA,GET_CUSTOMERS, DELETE_CUSTOMER} from '../../../../queries/customers';
import contactAdd from './config/contactAdd.config';
import DialogModal from '../../../ui/dialog/DialogModal';
import Typography from '../../../ui/typography/Typography';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_CONTACT, CRM_CONTACT_CLIENTS, CRM_CONTACT_CONTACTS, CRM_CONTACT_LEADS, CREATE, UPDATE, DELETE, VIEW} from '../../../../js/constants/constant-rights';

import {prepareAttributeValues,getElements,saveElement,updateElement} from '../../../../js/utils/functions';
import {searchItem} from '../../../../js/helpers/search';
import * as formHelper from '../../../../js/helpers/form'
class ContactAdd extends React.Component{
    constructor(props){
        super(props)
        this.goBack.bind(this);
        this.state={
            currentLang: props.locales[0].node.code,
            customerAddForm : false,
            errors:{},
            companies:{
                current : [],
                toAdd:[],
                toRemove : [],
            },
            nbperPageCompanies: 6,
            customerStatus : props.location.state.typeContact ? props.location.state.typeContact:'contact', 
            contactId : props.location.state.contactId ? props.location.state.contactId : null,
            pictoContact: "icon-contacts",
            edit : props.edit ? props.edit:false,
            formSettings:{
                page:1,
                count:1,
            },
            customer : {
                customAttributes:{} //etc...
            },
            

        }
    }

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    handleNextStep = () =>{
        let result = formHelper.handleStep('next',this.state.errors);
        this.setState({
            seeErrors : !result
        })
        return result;
    }

    handleBackStep = () => {
        this.setState({ errors: {} });
    }

    handleToggleDrawer = (stateDrawer,reset=false) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer]
        });

        if(reset){
            this.resetState()
        }
    };

    handleToggleModal = (stateModal)=>{
            this.setState({ 
                [stateModal]: !this.state[stateModal]
            });
        }

    handleDeleteCustomer = ()=>{
        let customer = this.state.currentCustomer.node.id;
        let status = this.state.customerStatus;
        this.props.startLoading();
        this.props.client.mutate({
            mutation : DELETE_CUSTOMER,
            variables : {id : customer}
        }).then(result=>{
            this.props.stopLoading();
            let route;
            switch(status){
                case 'client': 
                    route = ROUTE_CRM_CONTACTS_CLIENTS;
                
                break;
                case 'lead': 
                    route = ROUTE_CRM_CONTACTS_LEADS;
                
                break;
                case 'contact': 
                    route =ROUTE_CRM_CONTACTS_LIST;
                
                break;
                default:
                    return null;
            }
            this.props.history.push({
                pathname : route,
                state: {hasMessage:true,message : 'Contact supprimé'}
            });
        })
    }

    handleMediaPicker=(selected,stateName,translated)=>{
        this.handleInputChange(stateName,selected,null,translated);  
    }

    handleInputChange = (stateName, evt, custom, translated ) => {
        let value = formHelper.setValue(this.state,stateName, evt, custom, translated);
        this.setState({...value})
    };
 
    handleSearch = async(e) =>{
        let idList = await searchItem(e,'company','/api/companies/',{},{delay:100})
        let formSettings = this.state.formSettings;
        formSettings.queryVariables = {id_list:idList}
        this.setState({
            formSettings
        })
    }

    setUpAttributes = async() => {
        let attributes = await prepareAttributeValues('customer',this.state.edit,this.state.currentCustomer?.node);
        this.setState({
            ...attributes,
            isReady : true,
        })
    }

    handleSelectCompany = (checked,company,currentAction='add') =>{
        let companies = this.state.companies
        if(checked){
            companies.toAdd.push(company)
        }
        else{
            let index = companies.toAdd.indexOf(company);
            companies.toAdd.splice(index,1)
        }
        this.setState({
            companies : companies
        })
    }

    handleGetCurrentCustomer = ()=>{
        return new Promise(async(resolve,reject)=>{
            let contactId = parseInt(this.state.contactId.replace('/api/customers/',''),10);
            let result = await getElements('customer',{id:contactId})
            let currentCustomer = result.data.customers.edges[0]
            let currentCompanies = currentCustomer.node.companies.edges.map(company => company.node.id);
            let companies = this.state.companies;
            companies.toAdd = companies.current = currentCompanies;
            this.setState({
                currentCustomer : currentCustomer,
                customerStatus : currentCustomer.node.status,
                companies : companies,
                customerEmail : currentCustomer.node.sku
            })
            resolve()
            
        })
    }

    initForm = async()=>{
        if(this.state.edit){
            await this.handleGetCurrentCustomer()
        }else{
            const getRights = hasRights(CRM_CONTACT, this.state.accessType, CREATE)
            if (!getRights){
                this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
                this.goTo(ROUTE_HOME);
            }
        }
        this.setUpAttributes();

        if(!this.state.edit){
            this.handleToggleDrawer('contactAddForm');
        }

        let formSettings = this.state.formSettings;

        formSettings.handleSearch = this.handleSearch;

        this.props.stopLoading();
    }

    getTraductionAttributs(attributeIdentifier, data, lang, type) {
        if (!data.length) return null;
    
        let nameAttributes  = data.filter(e => e.node.attribute.identifier === attributeIdentifier);
        let getTraduction   = nameAttributes.find(e => e.node.locale.code === lang);

        return type !== 'image'
            ? getTraduction?.node.value?
                {
                    value :getTraduction?.node.value,
                    id:getTraduction?.node.id
                } 
            : null
            : getTraduction?.node.media?
                {
                    value :getTraduction?.node.media,
                    id:getTraduction?.node.id
                } 
            : null;
    };
    
    getAttributesGroup = (type) =>{
        return new Promise((resolve,reject)=>{
            this.props.client.query({
                query: GET_ATTR_GROUPE_WITH_ATTRIBUTES,
                fetchPolicy: "no-cache",
            }).then(result=>{     
                let attrGroup = result.data.attributeGroups.edges.find(group => group.node.identifier === type)?.node.id;
                resolve(attrGroup);
            })
        })
    }

    handleMutation= async() =>{
        /*this.props.startLoading();*/
        let attributesGroup = await this.getAttributesGroup('customer');
        let variables = {
            attributeGroup : attributesGroup,
            status : this.state.customerStatus,
            sku : this.state.customerEmail,
            companies : this.state.companies.toAdd

        }
        if(this.state.edit){
            variables.id = this.state.contactId;
            await updateElement('customer',variables,{setAttributes:true,states:this.state,success:'Contact mis à jour !'})   
        }
        else{
            await saveElement('customer',variables,{setAttributes:true,states:this.state,success:'Contact ajouté !'})       
        }

        let content;
        switch(variables.status){
            case 'client': content = {
                route: this.goTo(ROUTE_CRM_CONTACTS_CLIENTS)
            }
            break;
            case 'lead': content = {
                route: this.goTo(ROUTE_CRM_CONTACTS_LEADS)
            }
            break;
            case 'contact': content = {
                route: this.goTo(ROUTE_CRM_CONTACTS_LIST)
            }
            break;
            default:
                return null;
        }
        this.setState({
            content: content,
        });
    }

    goBack(){
        this.props.history.goBack();
    }

    checkAccess() {
        let type = null;
        switch(this.state.customerStatus){
            case 'contact' :
                type = CRM_CONTACT_CONTACTS;
            break;
            case 'client' :
                type = CRM_CONTACT_CLIENTS;
            break;
            case 'lead' :
                type = CRM_CONTACT_LEADS;
            break;
            default:
                return null;
        }
        const getRights = hasRights(CRM_CONTACT, type, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.setState({
                accessType: type,
            })
        }
    }

    async componentDidMount(){
        await this.checkAccess();
        this.initForm();
    }
    
    render() {
        const selectLang = {
            type: 'select',
            label: 'Langue',
            helper: 'Langue',
            required: false,
            stateName: 'currentLang',
            value: this.props.locales.map((locale) => {
                return(
                    {
                        value: locale.node.code,
                        label: locale.node.libelle
                    }
                )
            })
        };
        return (
            <div style={{width: this.state.contactAddForm || this.state.customerAddForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                <Grid container justify={'flex-end'}>
                    <Grid item xs={5}>
                        <Grid container justify={'flex-end'}>
                            <InputBuilder value={this.state.currentLang} input={selectLang} stateCallback={this.handleLang} /> 
                        </Grid>
                    </Grid>
                </Grid>

                <CardCustom style={{margin: '0 32px', paddingLeft: 16}}>
                    <CardContactDetails 
                        allStates={this.state} 
                        currentLang = {this.state.currentLang} 
                        langs={this.props.locales} 
                        handleToggleDrawer={this.handleToggleDrawer} 
                        isEdit={hasRights(CRM_CONTACT, this.state.accessType, UPDATE) ? this.state.edit : null}
                    />
                </CardCustom>
                
                {
                    this.state.isReady ? 
                    (
                        <LayoutBuilder
                            icomoon="icon-contacts"
                            opened={this.state.contactAddForm} 
                            forClose={this.state.edit ? () => this.handleToggleDrawer('contactAddForm') : () => this.props.history.goBack()} 
                            handlerSetup={()=>{}} 
                            dataLayout={contactAdd(this.state.currentLang,this.state.customAttributes,this.handleMediaPicker,this.handleSelectCompany,this.state.companies.current,this.state.formSettings, this.state.edit, this.goTo)} 
                            drawerWidth={this.props.drawerWidth}     
                            allState={this.state}    
                            stateCallback={this.handleInputChange} 
                            errorCallback={this.handleFormError}    
                            stepperButtonAction={[
                                this.handleNextStep,
                                this.handleNextStep,
                            ]}
                            handlerMutation = {this.handleMutation}
                            currentLang={this.state.currentLang}
                            handleLang={this.handleLang}
                            deleteMutation = {this.state.edit && hasRights(CRM_CONTACT, this.state.accessType, DELETE) ? () => this.handleToggleModal('openDeleteModal') : null}
                            deleteText = {'Supprimer ce contact'}
                            validateButton = {true}
                        />            
                    )
                    : null
                }

                <DialogModal
                    open = {this.state.openDeleteModal}
                    secondaryAction = {()=>this.handleToggleModal('openDeleteModal')}
                    primaryAction = {()=>this.handleDeleteCustomer()}
                    title = 'Supprimer ce contact'
                >
                    <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce contact ? <strong>Cette action est irréversible.</strong></Typography>
                </DialogModal>

            </div>
        );
    }

    goTo = (route, status) => {
        this.props.history.push({
            pathname : route,
            state: {openForm : status}
        });
    };
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ContactAdd)));
