import {Grid,List, Switch, Typography, TextField, MenuItem} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import {connect} from 'react-redux';
import ButtonCustom from '../../../../ui/button/Button';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import _ from 'lodash';
import CardCustom from '../../../../layouts/Card/CardCustom';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import * as listingHelper from '../../../../../js/utils/listing';
import {getElements} from '../../../../../js/utils/functions';
import {CursorPagination} from '../../../../../js/utils/pagination';
import { getTraductionAttributs } from '../../../../../js/utils/functions';
import NoImage from '../../../../../assets/images/not-found.png';
import { GET_SELECTIONS } from '../../../../../queries/selections';
import { UPDATE_CATALOG_PRODUCT } from '../../../../../queries/catalogs';
import * as moment from 'moment';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../../js/constants/alert-types';
import { ROUTE_PRODUCTS_LIST_DETAIL } from '../../../../../js/constants/route-names';

const TableCellCustom = styled(TableCell)`
    border: none;
    // display: inline-block;
    // width: calc(100% / 9);
    padding: 3px 16px;
`;

const SwitchCustom = styled(Switch)`
    .Mui-checked{
        color: ${colors.blue.lighter.hue150};
        & + .MuiSwitch-track{
            background-color: ${colors.blue.lighter.hue300};
        }
    }
`;

const Title = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 13px;
        padding: 10px;
    }
    & > p{
        margin: 0;
    }
`;

class ProductSelectorCatalog extends React.Component { 
    constructor(props){
        super(props)
        this.state = {
            ready: false,
            switchValue: false,
            pagination :  {
                page : 0,
                perPage : 30
            },
            perPageOptions:{
                'table' : [30,50,100],
            },
            listCollections: [],
        }
    }

    changeViews = async(mode) =>{
        let views = await listingHelper.changeViews(this.state,'list');
        this.setState({
            views
        })
        if(this.state.perPageOptions['list'][0]!=this.state.pagination.perPage){
            this.changePerPage(this.state.perPageOptions['list'][0])
        }
    }

    changePage = async(newPage) =>{
        let pagination = await listingHelper.pagination.changePage(this.state.pagination,newPage);
        this.setState({
            pagination
        },()=>this.handleGetItems())
    }

    changePerPage = async(perPage) => {
        let reload = this.state.pagination.perPage !== perPage;
        if(reload){
            let pagination = await listingHelper.pagination.updatePerPage(this.state.pagination,perPage);
            this.setState({
                pagination
            },()=>{
                this.handleGetItems()
            })    
        }
    }

    initCollections() {
        this.props.client.query({
            query: GET_SELECTIONS,
            fetchPolicy: "no-cache",
        }).then(result => {
            this.setState({
                listCollections: result.data.selections.edges
            })
        })
    }

    async componentDidMount(){
        this.initCollections();
        await this.handleGetItems();
    }

    handleGetItems() {
        return new Promise(async(resolve, reject) => {
            this.setState({ready:false})

            let variables = {
                "catalog": this.props.allState.catalogId
            }
            variables = await listingHelper.initQuery(this.state,variables,'queryData');
            let queryStates = await listingHelper.initQuery(this.state,variables,'states');

            this.setState({...queryStates});
            let result = await getElements('catalogProducts',variables);
            let handleResults = await listingHelper.handleResult(result.data['catalogProducts'],'listItems',this.state);
            
            this.setState({
                ...handleResults,
            })
            // if(this.props.listingCallback)
            //     this.props.listingCallback(handleResults.listItems)
            resolve();
        
        });
    }

    handleSwitchChange  = (id, status) => {
        let getData = this.state.listItems
        let statusValue = getData.find(e => e.node.id === id)
        statusValue.node.status = !status
        this.setState({
            listItems : getData
        },() => {
            this.props.client.mutate({
                mutation: UPDATE_CATALOG_PRODUCT,
                variables:{
                    "id": id,
                    "status": !status,
                    "updatedAt": moment().format('YYYY-MM-DD')
                }
            }).then(result =>{
                if (result){
                    this.props.snack(ALERT_SUCCESS, 'Modification du status effectuée');
                }else{
                    this.props.snack(ALERT_ERROR, 'Une erreur est survenue');
                }
            })
        })
    };

    handleSelectionChange  = (id, selection) => {
        let getData = this.state.listItems
        let selectionValue = getData.find(e => e.node.id === id)
        if (selectionValue.node.selection && selectionValue.node.selection?.id !== selection.target.value){
            selectionValue.node.selection.id = selection.target.value
        }else if (!selectionValue.node.selection){
            selectionValue.node.selection = {id: selection.target.value}
        }
        this.setState({
            listItems : getData
        },() => {
            this.props.client.mutate({
                mutation: UPDATE_CATALOG_PRODUCT,
                variables:{
                    "id": id,
                    "selection": selection.target.value === "none" ? null : selection.target.value,
                    "updatedAt": moment().format('YYYY-MM-DD')
                }
            }).then(result =>{
                if (result){
                    this.props.snack(ALERT_SUCCESS, 'Modification de la collection effectuée');
                }else{
                    this.props.snack(ALERT_ERROR, 'Une erreur est survenue');
                }
            })
        })
    };

    handleBlankGoTo = (productId) => {
        window.open(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', productId.replace('/api/products/', '')))
    }

    render(){
        let headCells = [
            { id: 'image', align: "center", style: null, disablePadding: true, label: 'Image' },
            { id: 'sku', style: null, disablePadding: false, label: 'Code article' },
            { id: 'nom', style: null, disablePadding: false, label: 'Nom' },
            { id: 'collection', style: null, disablePadding: false, label: 'Collection' },
            { id: 'status', align: "center", style: null, disablePadding: false, label: 'Actif' },
            { id: 'button', style: null, disablePadding: false, label: '' }
        ]
        return(
            <Grid container spacing={4} style={{background: colors.grey.lighter.hue980, marginTop: 10}}>
                {
                    this.state.ready ?
                        this.state.listItems.length > 0 ?
                            <CardCustom style={{width: "100%", height: "auto",  padding: "0"}} cardContentStyle={{height: "auto"}} contentpadding={'0'} hovercard={false} collapse={true}>
                                <TableContainer component={Paper} style={{maxHeight: 500}}>
                                    <Table aria-label="headerList" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {headCells.filter(e => e!== null).map((headCell) => (
                                                    <TableCellCustom
                                                        key={headCell.id}
                                                        align={headCell.align ? headCell.align : 'left'}
                                                        style={{width: `${headCell.style?.width}`, background: "white"}}
                                                    >
                                                        {headCell.label}
                                                    </TableCellCustom>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.listItems.map((product, index) => {
                                                    let getName = getTraductionAttributs('product_name', product.node.product.productDatas.edges, this.props.allState.currentLang);
                                                    let getImage = getTraductionAttributs('product_image', product.node.product.productDatas.edges, this.props.allState.currentLang, 'image');
                                                    return(
                                                        <TableRow key={`row-product-${index}`}>
                                                            <TableCellCustom component="th" scope="row" align="center">
                                                                {
                                                                    getImage && getImage !== '' ? 
                                                                        (<img src={`${process.env.REACT_APP_MEDIAS}/${getImage.filePath}`} alt={`image-${getName}-${index}`} style={{maxWidth: '100%', maxHeight: 40}}/>) 
                                                                    : 
                                                                        (<img src={NoImage} style={{maxWidth: '100%', maxHeight: 40}} alt={`image-${getName}-${index}`}/>)
                                                                }
                                                            </TableCellCustom>
                                                            <TableCellCustom >
                                                                <Typography variant="body2">{product.node.product.sku}</Typography>
                                                            </TableCellCustom>
                                                            <TableCellCustom style={{minWidth: 150}}>
                                                                <Title variant="body1">{getName || 'N/A'}</Title>
                                                            </TableCellCustom>
                                                            <TableCellCustom>
                                                                <TextFieldCustom
                                                                    id={`Selection-${index}`}
                                                                    name={`Selection-${index}`}
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    select
                                                                    fullWidth
                                                                    displayEmpty
                                                                    value={product.node.selection !== null ? product.node.selection.id : 'none'}
                                                                    onChange={evt => {
                                                                        this.handleSelectionChange(product.node.id, evt);
                                                                    }}
                                                                    style={{minWidth: 150}}
                                                                    >
                                                                        <MenuItem
                                                                            value={'none'}
                                                                            disabled={product.node.selection === '' ? true : false}
                                                                            >
                                                                            <em>Aucune collection</em>
                                                                        </MenuItem>
                                                                    {this.state.listCollections.map((option,index) => {
                                                                        return(
                                                                            <MenuItem key={`OptionSelect${index}`} value={option.node.id} disabled={product.node.selection?.id === option.node.id ? true : false}>
                                                                                {option.node.identifier}
                                                                            </MenuItem>
                                                                        )
                                                                    })}
                                                                </TextFieldCustom>
                                                            </TableCellCustom>
                                                            <TableCellCustom align={"center"}>
                                                                <SwitchCustom
                                                                    checked={product.node.status}
                                                                    className={product.node.status ? 'checked' : ''}
                                                                    onChange={() => this.handleSwitchChange(product.node.id, product.node.status)}
                                                                    color="primary"
                                                                    name="checkedB"
                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                />
                                                            </TableCellCustom>
                                                            <TableCellCustom>
                                                                <ButtonCustom disableElevation={true} text={"Voir"} arrow="right" bgColor={colors.green.regular} onClick={() => this.handleBlankGoTo(product.node.product.id)} />
                                                            </TableCellCustom>
                                                        </TableRow>
                                                    )
                                                })   
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardCustom>
                        : <Typography style={{padding: 20}}>Aucun produit</Typography>
                    : <PageLoader />
                }
                {
                    this.state.ready && this.state.listItems.length > 0 ?
                        <Grid container style={{padding: "8px 20px"}}>
                            <CursorPagination
                                rowLabel = {`Produits par page`}
                                pagination = {this.state.pagination}
                                type = "table"
                                changePageCallback = {this.changePage}
                                changePerPageCallback = {this.changePerPage}
                                showPerPage = {this.state.perPageOptions['table']?.length > 0}
                                perPageOptions = {this.state.perPageOptions['table']}
                            />
                        </Grid>
                    : null
                }
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo((connect(mapStateToProps,mapDispatchToProps)(ProductSelectorCatalog)));