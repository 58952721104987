import React from 'react';

import { Box, Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import styled from 'styled-components';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import Button from '../../../ui/button/Button';
import { ROUTE_ENGINE_ASSETS_DETAIL, ROUTE_DIFFUSION_PERMANENTS_POSTS } from '../../../../js/constants/route-names';
import { EPHEMERAL_ASSET } from '../../../../js/constants/assets-types';
import { withRouter } from 'react-router';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import iconSet from "../../../../assets/selection.json";
import IcomoonReact from "icomoon-react";

const AssetDescr=styled(Typography)`
    font-size:16px;
    line-height:1.6em;
    margin:0;  
    height:3.2em;
    width:100%;
    p{
        margin: 0;
    }
`

const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    width: 100%;
`
const ContainerBackground = styled(Box)`
    overflow: hidden;
    background: ${props => props.backgroundcolorcard};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`
const LineColor = styled(Box)`
    background: ${props => props.typeasset === 'permanent' ? colors.blue.darker.hue300 : props.typeasset === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    width: 100%;
`

const TypeAsset = styled(Typography)`
    font-weight: bold;
`
const AssetType = styled(Typography)`
    color: ${props => props.typeasset === 'permanent' ? colors.blue.darker.hue300 : props.typeasset === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    font-weight: bold;
    text-transform: uppercase;
    margin-right: ${props => props.marginright};
`

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.regular,
    },
    tooltip: {
        backgroundColor: colors.blue.regular,
        fontSize: 14,
    },
}));


function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
}

function CardAssets(props){
    const asset=props.asset;
    let image= '';
    let name= '';
    let description= '';
    if (!props.isDashboard){
        const attributes    = asset.node.assetDatas.edges;
        let allNames    = attributes.filter(e => e.node.attribute.identifier === 'asset_store_name');
        name        = allNames.find(e => e.node.locale.code === props.currentLang);
        let nameEN        = allNames.find(e => e.node.locale.code === 'en_US');
        let nameFR        = allNames.find(e => e.node.locale.code === 'fr_FR');

        let allDescription   = attributes.filter(e => e.node.attribute.identifier === 'asset_store_description');
        description       = allDescription.find(e => e.node.locale.code === props.currentLang);
        let descriptionEN        = allDescription.find(e => e.node.locale.code === 'en_US');
        let descriptionFR        = allDescription.find(e => e.node.locale.code === 'fr_FR');
        
        // let allImages   = attributes.filter(e => e.node.attribute.identifier === 'product_image');
        // let imageDB       = allImages.find(e => e.node.locale.code === props.currentLang);
        let allImages   = asset.node.assetDatas.edges.filter(e => e.node.attribute.identifier === 'asset_store_image');
        let imageByLang = allImages.find(e => e.node.locale.code === props.currentLang);
        let defaultImage = allImages[0];

        image   =  imageByLang 
                    ? `${process.env.REACT_APP_MEDIAS}/${imageByLang.node.media?.filePath}` ?? '' 
                    : defaultImage 
                        ?`${process.env.REACT_APP_MEDIAS}/${defaultImage.node.media?.filePath}` ?? '' 
                        : null;
        
        name = name ? name.node.value : nameEN ? nameEN.node.value : nameFR ? nameFR.node.value : (asset.node.identifier);
        // description = description ? description.node.value : 'Pas encore de description';
        description = description ? description.node.value : descriptionEN ? descriptionEN.node.value : descriptionFR ? descriptionFR.node.value : 'Pas encore de description';
    }else{
        name = asset.node.asset[props.currentLang]?.assetName || 'Pas encore de nom dans cette langue';
        description = asset.node.asset[props.currentLang]?.assetDescription || 'Pas encore de description dans cette langue';
        let imageByLang = asset.node.asset[props.currentLang]?.assetImage?.filePath;

        image   =  imageByLang 
                    ? `${process.env.REACT_APP_MEDIAS}/${imageByLang}`
                    : null

    }
    

    const goTo = (route, id) => {
        props.history.push({
            pathname : route,
            state: {assetId : id}
        });
    };

    function renderCardImage(image){
        let content = '';
        let colorBackground = '';
        if (asset.node.assetGameType){
            content =  <TypeAsset variant="h1" colortext={colors.white}>{asset.node.assetType.libelle} - {asset.node.assetGameType.libelle}</TypeAsset>
        }else {
            content =  <TypeAsset variant="h1" colortext={colors.white}>{asset.node.assetType.libelle}</TypeAsset>
        }
        switch(asset.node.assetType.assetCategory.identifier){
            case 'instore':
                colorBackground = "#FF4F98"
            break;
            case 'online':
                colorBackground = "#52D3F1"
            break;
            case 'social':
                colorBackground = "#FEF481"
            break;
            case 'webshop':
                colorBackground = "#FFC4E9"
            break;
            case 'cms':
                colorBackground = "#B04DE6"
            break;
            case 'connector':
                colorBackground = "#000000"
            break;
            default:
                colorBackground = "#000000"
        }
        if(image){
            return(
                <ContainerImage height={160} width={'100%'} backgroundimage={image ? image : null}>
                </ContainerImage>
            )
        }else{
            return(
                <ContainerBackground height={160} width={'100%'} backgroundcolorcard={colorBackground}>
                    {content}
                </ContainerBackground>
            )
        }
    }

    

    const classes = useRowStyles();

    return(
        <Box width={'100%'} 
            style={{cursor: props.inputCard ? 'inherit' : 'pointer'}} 
            onClick={props.inputCard ? null 
            : props.isDiffusion ? 
                () => goTo(ROUTE_DIFFUSION_PERMANENTS_POSTS.replace(':id', asset.node.id.replace('/api/assets/', '')))
                :() => goTo(ROUTE_ENGINE_ASSETS_DETAIL.replace(':id', asset.node.id.replace('/api/assets/', '')))
            }>
            {renderCardImage(image)}
            <LineColor height={8} width={'100%'} typeasset={EPHEMERAL_ASSET.indexOf(asset.node.assetType.identifier) > -1 ? 'ephemeral' : 'permanent'}/>
            <Box padding={'1rem 1.5rem 0.5rem 1.5rem'}>
                <Grid container justify="space-between" style={{paddingBottom: 16}} alignItems="center">
                    <AssetType variant="h5" component="div" typeasset={EPHEMERAL_ASSET.indexOf(asset.node.assetType.identifier) > -1 ? 'ephemeral' : 'permanent'} marginright={"1rem"}>{EPHEMERAL_ASSET.indexOf(asset.node.assetType.identifier) > -1 ? 'ASSET ÉPHÉMÈRE' : 'ASSET PERMANENT'}</AssetType>
                    <Box>
                        <StatusInfo status={props.isDashboard ? asset.node.asset.status : asset.node.status}/>
                    </Box>
                </Grid>
                <Box mb={1}>
                    <Typography variant="h3">{name}</Typography>
                    <Box mt={1}> 
                        <TypeAsset style={{fontSize: 14, lineHeight: '19px'}} variant="h4" colortext={colors.blue.lighter.hue300}>
                            {asset.node.assetGameType ?
                                `${asset.node.assetType.libelle} - ${asset.node.assetGameType.libelle}` : asset.node.assetType.libelle
                            }
                        </TypeAsset> 
                    </Box>
                </Box>
                <Grid container alignItems="center" justify={'center'}>
                    <AssetDescr variant="body2" component="div">
                        <HTMLEllipsis
                            unsafeHTML={description}
                            maxLine={2}
                            ellipsis='...'
                            basedOn='letters'
                        />
                    </AssetDescr>
                </Grid> 
                {
                    props.isDashboard && asset.node.stats.success ?
                        <Grid container>
                            {
                                asset.node.stats.nbWishlist || asset.node.stats.nbWishlist >= 0  ?
                                    <Grid>
                                        <BootstrapTooltip title={'Total Wishlist'} className={clsx(classes.tooltip)} arrow>
                                                <Box style={{display: 'flex'}}>
                                                    <IcomoonReact iconSet={iconSet} color={colors.blue.regular} size={props.windowWidth < 1400 ? 20 : 24} icon={"picto-profil"} />
                                                    <Typography variant="h4" style={{marginLeft: 8}}>{asset.node.stats.nbWishlist}</Typography>
                                                </Box>
                                            </BootstrapTooltip>
                                    </Grid>
                                : null
                            }
                            {
                                asset.node.assetGameType ? 
                                    <>
                                        <Grid container direction="row" style={{marginTop: 8}}>
                                            <BootstrapTooltip title={'Total Participations'} className={clsx(classes.tooltip)} arrow>
                                                <Box style={{display: 'flex'}}>
                                                    <IcomoonReact iconSet={iconSet} color={colors.blue.regular} size={props.windowWidth < 1400 ? 20 : 24} icon={"picto-profil"} />
                                                    <Typography variant="h4" style={{marginLeft: 8}}>{asset.node.stats.datas.participations}</Typography>
                                                </Box>
                                            </BootstrapTooltip>
                                            <BootstrapTooltip title={'Total Participations uniques'} className={clsx(classes.tooltip)} arrow>
                                                <Box style={{display: 'flex'}} ml={2}>
                                                    <IcomoonReact iconSet={iconSet} color={colors.blue.regular} size={props.windowWidth < 1400 ? 20 : 24} icon={"account"} />
                                                    <Typography variant="h4" style={{marginLeft: 8}}>{asset.node.stats.datas.singleParticipations}</Typography>
                                                </Box>
                                            </BootstrapTooltip>
                                            <BootstrapTooltip title={'Total Prix remportés'} className={clsx(classes.tooltip)} arrow>
                                                <Box style={{display: 'flex'}} ml={2}>
                                                    <IcomoonReact iconSet={iconSet} color={colors.blue.regular} size={props.windowWidth < 1400 ? 20 : 24} icon={"icon-win"} />
                                                    <Typography variant="h4" style={{marginLeft: 8}}>{asset.node.stats.datas.giftsWon}</Typography>
                                                </Box>
                                            </BootstrapTooltip>
                                        </Grid>
                                    </>
                                : null
                            }
                        </Grid> 
                    : null
                }
                {!props.configCard ?
                    <Grid container direction="row" justify="flex-end" style={{alignSelf:'flex-end'}}>
                        <Box mt={2}>
                            {
                                props.canBroadcast ?
                                    !props.isDashboard ?
                                        !props.isDiffusion ?
                                            asset.node.assetType.hasFeed || asset.node.assetType.hasPage || asset.node.assetType.identifier === 'jeu' ?
                                                <Button 
                                                    text={"Diffuser"}
                                                    bgcolor={colors.blue.lighter.hue300} 
                                                    color={colors.white} 
                                                    bgcolorhover={colors.blue.darker.hue300}  
                                                    colorhover={colors.white}  
                                                    border={`1px solid ${colors.blue.lighter.hue300}`}
                                                    onClick={() => goTo(ROUTE_DIFFUSION_PERMANENTS_POSTS.replace(':id', asset.node.id.replace('/api/assets/', '')))} 
                                                    arrow="right"
                                                    style={{marginRight: 8}}
                                                /> 
                                            : null
                                        : null
                                    : null
                                : null
                            }
                            
                            <Button 
                            text={props.isDiffusion ? "Diffuser" : "Voir les détails"}
                            bgcolor={colors.white} 
                            color={colors.blue.lighter.hue300} 
                            bgcolorhover={colors.blue.lighter.hue300}  
                            colorhover={colors.white}  
                            border={`1px solid ${colors.blue.lighter.hue300}`}
                            onClick={
                                props.isDiffusion ? 
                                    () => goTo(ROUTE_DIFFUSION_PERMANENTS_POSTS.replace(':id', asset.node.id.replace('/api/assets/', '')))
                                :() => goTo(ROUTE_ENGINE_ASSETS_DETAIL.replace(':id', props.isDashboard ? `/api/assets/${asset.node.asset.id}` : asset.node.id.replace('/api/assets/', '')))} 
                            arrow="right"
                            />
                        </Box>
                    </Grid>  
                : null}   
                
            </Box>
        </Box>
    )
}
export default withRouter(CardAssets);