import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import { withApollo } from 'react-apollo';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import { eventService } from '../../../../js/services/event.service';
import { v4 as uuidv4 } from 'uuid';

import { GET_ASSET_BY_ID, DELETE_ASSET, UPDATE_ASSET, DELETE_ASSET_DATA, UPDATE_ASSET_DATA, ADD_ASSET_DATA,GET_ASSETS_PAGINATION, GET_ASSETS_CURSOR,GET_ASSET_TYPE_WIDGET, GET_ASSET_EXPORT, ADD_ASSET_EXPORT, UPDATE_ASSET_EXPORT, DELETE_ASSET_EXPORT } from '../../../../queries/assets';
import { GET_CATALOGS_ATTR_BY_ID } from '../../../../queries/catalogs';
import { GET_EAV_TYPES,GET_ATTRIBUTES_BY_TYPE } from '../../../../queries/attributes'
import { ADD_GAME_GIFT, ADD_GAME_INSTANT, GET_GAME_GIFTS, GET_GAME_INSTANTS, UPDATE_GAME_INSTANT, UPDATE_GAME_GIFT, DELETE_GAME_GIFT, DELETE_GAME_INSTANT } from '../../../../queries/asset_games';
import { GET_CURRENCIES } from '../../../../queries/currencies';
import { GET_COMPLETENESSGROUPS } from '../../../../queries/completeness_group';
import { GET_BY_ASSET_FORMAT_URL, CREATE_FORMAT_URL, ADD_FORMAT_URL_ATTRIBUTE, UPDATE_FORMAT_URL, DELETE_FORMAT_URL, UPDATE_FORMAT_URL_ATTRIBUTE, DELETE_FORMAT_URL_ATTRIBUTE } from '../../../../queries/formal_url';

import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import Button from '../../../ui/button/Button';
import { ROUTE_ENGINE_ASSETS, ROUTE_ENGINE_ASSETS_DETAIL_STATS, ROUTE_DIFFUSION_PERMANENTS_POSTS, ROUTE_BUILDER, ROUTE_HOME } from '../../../../js/constants/route-names';
import * as moment from 'moment';
import 'moment-timezone';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CardAssetDetails from '../../../layouts/Card/cardContent/CardAssetDetails';  
import assetsEditConfig from './config/assetsEdit.config';
import assetsGift from './config/assetsGift.config';
import importInstantsTypesConfig from './config/instants/importInstantsTypes.config';
import importInstantsConfig from './config/instants/importInstants.config';
import importFichier from '../../../../assets/pictos/icon-import-fichier.svg';
import importAPI from '../../../../assets/pictos/icon-import-api.svg';
import importFlux from '../../../../assets/pictos/icon-import-flux.svg';
import _ from 'lodash';
import request from '../../../../js/utils/fetch';

import { hasRights } from '../../../../js/utils/rights';
import { BUILDER, BUILDER_ASSETS, VIEW, DELETE, UPDATE } from '../../../../js/constants/constant-rights';
import { GET_RATE_GROUPS_LIGHT } from '../../../../queries/rate_group';

class EngineAssetsDetails extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            styleInnerContainer : {
                boxShadow: 'none',
                backgroundInner: colors.white,
                backgroundActions: colors.grey.lighter.hue980,
                padding: '0px',
                height: '630px'
            },
            openFormEdit : false,
            openFormEditGifts : false,
            openFormFeedAdd:false,
            openFormCardImport: false,
            openFormImport:false,
            openDialog : false,
            idAsset: `/api/assets/${this.props.match.params.id}`,
            assetData : null,
            startDate : '',
            imageAsset : null,
            errors: {},
            seeErrors: false,
            ready: false,
            isReady: false,
            drawerWidthModified : props.drawerWidth,
            currentLang: props.locales[0].node.code,
            catalogAsset: null,
            paramIdentifier : null,
            paramStartAt    : null,
            paramEndAt    : null,
            paramStatus     : true,
            // paramUrl        : null,
            paramImage      : null,
            disabledImage   : false,
            paramLink       : null,
            disabledLink    : false,
            paramText       : null,
            paramTwitterFirstMedia : null,
            paramTwitterSecondMedia : null,
            paramTwitterThirdMedia : null,
            paramTwitterFourthMedia : null,
            toDelete        : null,
            openMediaPicker: false,
            postType: true,
            giftInitial  : [],
            giftSelected  : [],
            instantWinList  : [],
            instantWinListInitial: [],
            assetLangSelected   : [],
            nbperpageAssets     : 4,
            separatorFormattingUrl: '-',
        };
    }

    

    componentDidMount() {
        const getRights = hasRights(BUILDER, BUILDER_ASSETS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.handleGetEavTypes();
            this.handleGetAssetDatas();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){

        if (prevState.startDateNotConverted !== this.state.startDateNotConverted) {
            let local = moment.tz.guess();
            let toDateStart = moment.tz(this.state.startDateNotConverted, local).utc().format().slice(0,19);
            this.setState({
                startDate : toDateStart,
            });
        }

        if(prevState.paramImage !== this.state.paramImage){
            if(this.state.paramImage != null){
                this.setState({
                    disabledLink : true,
                })
            }
            else{
                this.setState({
                    disabledLink : false,
                })
            }
        }
        if(prevState.paramLink !== this.state.paramLink){
            if(this.state.paramLink.length>0){
                this.setState({
                    disabledImage : true,
                })
            }
            else{
                this.setState({
                    disabledImage : false,
                })
            }
        }
    }

    handleGetCompletnessGroup = () => {
        this.props.client.query({
            query: GET_COMPLETENESSGROUPS,
            fetchPolicy: "no-cache",
        }).then(result => {
            let selectGroup = {
                type: 'select',
                label: 'Groupe de complétude',
                translated: false,
                helper: {
                    text: 'Sélectionnez un groupe de complétude',
                    link: false,
                },
                required: false,
                stateName: 'selectCompletenessGroup',
            };
            let selectGroupValues = [{value : 'none', label : 'Aucun groupe'}];

            for(let group of result.data.completenessGroups.edges){
                selectGroupValues.push({value: group.node.id, label: group.node.libelle});
            }

            selectGroup.value = selectGroupValues;
            this.setState({listCompletenessGroup: result.data.completenessGroups, selectGroup});
        });
    }

    handleGetFormatUrl = () => {
        this.props.client.query({
            query: GET_BY_ASSET_FORMAT_URL,
            fetchPolicy: "no-cache",
            variables: {asset: this.state.assetData.id}
        }).then(result => {
            if(result.data.formatUrls.edges.length > 0) {
                let formatUrl = result.data.formatUrls.edges[0].node;
                let formattingValueUrl = [];
                for(let item of formatUrl.formatUrlAttributes.edges){
                    formattingValueUrl.push({id: uuidv4(), attr: item.node.attribute.id, value: item.node.position, idFormat: item.node.id})
                }
                this.setState({separatorFormattingUrl: formatUrl.delimiter, formattingValueUrl, idFormatUrl: formatUrl.id, formatUrlExist: true})
            }
            else{
                this.setState({formatUrlExist: false})
            }
        });
    }

    handleGetEavTypes(){
        this.props.client.query({
            query: GET_EAV_TYPES,
        }).then(result =>{
            let getEavAsset = result.data.eavTypes.edges.find(e => e.node.code === 'asset')
            let getAssetEavId = getEavAsset.node.id;
            this.setState({
                eavTypeId: getAssetEavId,     
            },()=>{

                this.handleGetAttributesAsset(); 
            });
        });
    }

    handleGetAttributesAsset(){
        this.props.client.query({
            query: GET_ATTRIBUTES_BY_TYPE,
            variables: {id: this.state.eavTypeId},
        }).then(result =>{
            let getAllAttributes = result.data.eavType.attributes.edges
            this.setState({
                customAttributes: getAllAttributes
            })
        });
    }
    
    handleGetCurrencies = () => {
        this.props.client.query({
            query: GET_CURRENCIES,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCurrencies: result.data.currencies.edges,
            })
        });
    }

    handleGetGroupRates = () => {
        this.props.client.query({
            query: GET_RATE_GROUPS_LIGHT,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listGroupRates: result.data.rateGroups.edges,
                isReady: true
            })
        });
    }

    handleGetCatalogSelected = () => {
        let variables = {"id": this.state.catalogSelected}
        this.props.client.query({
            query: GET_CATALOGS_ATTR_BY_ID,
            variables,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.setState({
                catalogSelectedDatas: result.data,
            });
        });
    }

    handleGetAssetExports = () => {
        this.props.client.query({
            query: GET_ASSET_EXPORT,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                formattingValue: []
            }, () => {
                for (let item of result.data.assetExports.edges){
                    if(this.state.assetData.id === item.node.asset.id){
                        let formattingValue = this.state.formattingValue;
                        let object = {                
                            attr: item.node.attribute?.id ? item.node.attribute.id : item.node.keyValue ? item.node.keyValue: null,
                            id: item.node.id,
                            new: false,
                            value: item.node.keyName,
                        }
                        formattingValue.splice(item.node.position, 0, object);
                        this.setState({formattingValue: formattingValue});
                    }
                }
            })
        });
    }

    handleGetAssetDatas = () =>{
        this.setState({
            filteredAssets: null,
        })
        const getAssetsByType  = GET_ASSET_BY_ID;

        this.props.client.query({
            query:getAssetsByType,
            variables:{id: this.state.idAsset},
            fetchPolicy:'no-cache'
        }).then(GET_ASSET_BY_ID_RESULT =>{
            let currentAsset = GET_ASSET_BY_ID_RESULT.data.asset
            let toDateStart = null;
            let toDateStartNotConverted = null;
            let toDateEnd = null;
            let toDateEndNotConverted = null;
            let local                   = moment.tz.guess();

            if (currentAsset.startAt) {
                toDateStart             = moment.tz(currentAsset.startAt, local).format('L');
                toDateStartNotConverted = moment(currentAsset.startAt).utc().format().slice(0,19);
            }

            if (currentAsset.endAt) {
                toDateEnd             = moment.tz(currentAsset.endAt, local).format('L');
                toDateEndNotConverted = moment(currentAsset.endAt).utc().format().slice(0,19);
            }
            
            this.setState({
                imageAsset : currentAsset.assetType.logo,
                assetData: currentAsset,
                startDateNotConverted: toDateStartNotConverted,
                endDateNotConverted: toDateEndNotConverted,
                startDate: toDateStart,
                endDate: toDateEnd,
                stageUrl: currentAsset.stageUrl,
                stageHostUrl: currentAsset.stageHostUrl,
                prodUrl: currentAsset.prodUrl,
                prodHostUrl: currentAsset.prodHostUrl,
                paramIdentifier: currentAsset.identifier,
                paramStatus: currentAsset.status,
                paramStartAt: toDateStartNotConverted,
                paramEndAt: toDateEndNotConverted,
                currency: currentAsset.currencies.edges[0]?.node.id,
                // paramUrl: currentAsset.url,
                channelSelected: currentAsset.channel ? currentAsset.channel.id : null,
                catalogSelected: currentAsset.catalog?.id,
                assetsSelected: currentAsset.assetWidgets?.edges,
                retailerSelected: currentAsset.retailer ? currentAsset.retailer.id : null,
                retailerAsset:{
                    node: currentAsset.retailer
                },
                catalogAsset: {
                    node: currentAsset.catalog
                },
                assetLangSelected: currentAsset.locales.edges.map(e => e.node.id),
                selectCompletenessGroup: currentAsset.completenessGroup ? currentAsset.completenessGroup.id : 'none',
                ready : true,
            }, async () => {
                await this.prepareAttributesLocales(currentAsset);
                await this.handleGetGiftDatas();
                await this.handleGetInstantDatas();
                await this.handleGetCurrencies();
                await this.handleGetGroupRates();
                await this.handleGetCursorsAssets();
                await this.handleGetCatalogSelected();
                await this.handleGetAssetExports();
                await this.handleGetCompletnessGroup();
                await this.handleGetFormatUrl();
                this.prepareAttributeValues();
            }); 
        })
    }

    handleGetGiftDatas = () =>{
        const getGameGiftsByAsset = GET_GAME_GIFTS;
        let newList         = [];

        this.props.client.query({
            query:getGameGiftsByAsset,
            variables:{asset: this.state.idAsset},
            fetchPolicy:'no-cache'
        }).then(GET_GAME_GIFTS_RESULT =>{
            for(let gameGift of GET_GAME_GIFTS_RESULT.data.assetGameGifts.edges){
                newList.push({
                    product : gameGift.node.product,
                    giftImage : gameGift.node.image,
                    idGift : gameGift.node.id,
                    nbLink : 0,
                })
            }
            this.setState({
                giftInitial: newList,
                giftSelected: newList
            })
        })
    }

    handleGetInstantDatas = () =>{
        const getGameInstantByAsset = GET_GAME_INSTANTS;
        let newList         = [];

        this.props.client.query({
            query:getGameInstantByAsset,
            variables:{asset: this.state.idAsset},
            fetchPolicy:'no-cache'
        }).then(GET_GAME_INSTANTS_RESULT =>{
            for(let gameInstant of GET_GAME_INSTANTS_RESULT.data.assetGameInstants.edges){
                let formatDate = moment(gameInstant.node.winningAt).utc().format().slice(0,19);
                newList.push({
                    dateSelected : formatDate,
                    productLink : gameInstant.node.assetGameGift.id,
                    idInstant : gameInstant.node.id,
                    isWin : gameInstant.node.isWin === null ? false : gameInstant.node.isWin
                })
            }
            this.setState({
                instantWinListInitial: newList,
                instantWinList: newList
            })
        })
    }
    
    prepareAttributesLocales(result) {
        return new Promise((resolve, reject) => {
            let attributes = [];

            for (let { node } of result.assetDatas.edges) {
                let found = false;
                
                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;
    
                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            productDataId: node.id,
                            type: node.attribute.attributeType.input
                        });
                    }
                }
    
                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        isSystem: node.attribute.isSystem,
                        attributeOptions: node.attributeOptions,
                        translationLabel: node.attribute.translation.translationDatas.edges,
                        locales: [{
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            productDataId: node.id,
                            type: node.attribute.attributeType.input
                        }]
                    });
                }
            }
    
            this.setState({ attributes }, resolve);
        });
    }

    prepareAttributeValues() {
        for (let locale of this.props.locales) {
            let values = {};

            for (let attribute of this.state.attributes) {
                let attributeLocale = attribute.locales.find(e => e.code === locale.node.code);
                let defaultLocale   = attribute.locales.find(e => e.code === this.state.currentLang);
                

                if (!attributeLocale) {
                    if (attribute.attributeType.input === 'select') {
                        values[attribute.identifier] = defaultLocale?.value ?? attribute.locales[0] ;  
                    }
                } else {
                    switch(attribute.attributeType.input) {
                        case 'file':
                        case 'image':
                            
                            values[attribute.identifier] = {
                                data: attributeLocale.media ? attributeLocale.media.filePath : null,
                                file: null,
                                changed: false
                            };
                            break;
                        default:
                            values[attribute.identifier] = attributeLocale.value;  
                    }
                }
            }
            this.setState({
                [locale.node.code]: values
            });
        }
    }

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();
        });
        this.forceUpdate();
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];
            if (!values) {
                values = {};
            }
            values[stateName] = value;
            this.setState({
                [this.state.currentLang]: values,
            });
        } else{
            if (stateName === 'assetLangSelected' && !this.state.assetData.assetType.multiLocale){
                let newArray = []
                newArray.push(value)
                this.setState({
                    [stateName]: newArray,
                });
            }else{
                this.setState({
                    [stateName]: value,
                }, () => {
                    if(stateName === 'currency' && value){
                        let getAssetData = this.state.assetsEditData
                        let findIndex = getAssetData.formConfig.children.findIndex(e => e.labelName === "Paramétrage")
                        let findIndexChild = getAssetData.formConfig.children[findIndex].optionsInputs.findIndex(e => e.stateName === "groupRate")
                        getAssetData.formConfig.children[findIndex].optionsInputs[findIndexChild].disabled = false
                        getAssetData.formConfig.children[findIndex].optionsInputs[findIndexChild].value = this.state.listGroupRates.filter(e => e.node.currency.id === this.state.currency).map((groupRate, index) => {
                            return ({
                                value: groupRate.node.id,
                                label: `${groupRate.node.code}`,
                            });
                        })
                    }
                });
            }
        }
    };

    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);  
    }

    resetState = () => {
        this.setState({
            paramIdentifier : null,
            paramStartAt    : null,
            paramEndAt      : null,
            paramStatus     : true,
            stageUrl        : null,
            stageHostUrl    : null,
            prodUrl         : null,
            prodHostUrl     : null,
        });
        this.handleGetAssetDatas();
        this.prepareAttributeValues();
    }

    resetGiftAndInstant = () => {
        this.setState({
            instantWinList : [],
            giftSelected   : [],
        });
        this.handleGetInstantDatas();
        this.handleGetGiftDatas();
    }

    handleToggleDrawer = (stateDrawer, changeWidth) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer],
            errors: {},
        });
        if (stateDrawer === 'openFormCardImport'){
            this.setState({
                openFormEdit : false,
                openFormEditGifts : false,
                openFormFeedAdd:false,
            })
        }
        if (changeWidth) {
            this.handleDrawerWidthChange(350)
        } 
    };

    handleDrawerWidthChange = (width) => {
        this.setState({ 
            drawerWidthModified: width
        });
    };

    handleToggleDialog = () => {
        this.setState({ 
            openDialog : !this.state.openDialog
        });
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        await this.handleGetAssetDatas();
        await this.handleGetGiftDatas();
        await this.handleGetInstantDatas();

        this.props.snack(ALERT_SUCCESS, 'Asset modifié !');

        // this.handleToggleDrawer('openFormEdit');
        if(this.state.openFormEdit){
            this.handleToggleDrawer('openFormEdit');
        }
        if(this.state.openFormEditGifts){
            this.handleToggleDrawer('openFormEditGifts');
        }
        if(this.state.openFormCardImport){
            this.handleToggleDrawer('openFormCardImport');
        }
        if(this.state.openFormImport){
            this.handleToggleDrawer('openFormImport');
        }
        this.resetState();
        
        this.props.stopLoading();
    };


    deleteMutation = () => {
        this.props.startLoading();
        
        if(this.state.assetData.assetType.identifier === 'customflux' && this.state.formattingValue.length > 0){
            for( let item of this.state.formattingValue){
                this.props.client.mutate({
                    mutation: DELETE_ASSET_EXPORT,
                    variables: { id: item.id },
                });
            }
            this.props.client.mutate({
                mutation: DELETE_ASSET,
                variables: { id: this.state.idAsset },
            }).then(result => {
                this.props.stopLoading();
                this.props.snack(ALERT_SUCCESS, 'Asset supprimé avec succès');
                this.goTo(ROUTE_ENGINE_ASSETS);
            });
        }
        else{
            this.props.client.mutate({
                mutation: DELETE_ASSET,
                variables: { id: this.state.idAsset },
            }).then(result => {
                this.props.stopLoading();
                this.props.snack(ALERT_SUCCESS, 'Asset supprimé avec succès');
                this.goTo(ROUTE_ENGINE_ASSETS);
            });

        }
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    // SAVE TREE

    getAttributeTranslatedValue = (id, lang) => {
        if (!this.state.attributes)
            return null;

        let attribute = this.state.attributes.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };
    
    
    getImageFound = () => {
        let image = '';
        let getImage = '';

        for (let attribute of this.state.attributes){
            getImage = attribute.locales.filter(e => e.media) 
        }

        image = getImage[0].media.filePath;
        return image;
    }

    getTranslatedValueFromData = (datas, id, lang) => {
        let data = datas.find(e => e.node.locale.id === lang && e.node.attribute.id === id);

        if (!data)
            return null;

        return data.node;
    };
    
    saveAttributes = (category, uptodate) => {
        return new Promise(async (resolve, reject) => {
            let attributes = this.state.customAttributes;

            for (let attribute of attributes) {
                for (let locale of this.props.locales) { 
                    let formValue           = this.state[locale.node.code][attribute.node.identifier];
                    let currentTranslation  = this.getAttributeTranslatedValue(attribute.node.id, locale.node.id);
                    let isMedia             = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';
    
                    if (formValue && isMedia) {
                        /*if (!formValue.changed)
                            continue;*/ 
    
                        isMedia     = true;
                        /*formValue   = formValue.file;*/
                    }
    
                    if (formValue) { 
                        // let resultMedia = null;
                        /*if (isMedia) { 
                            let formData = new FormData();
    
                            formData.append('file', formValue);
                            formData.append('type', formValue.type);
        
                            resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                        }*/
    
                        if (currentTranslation) {
                            // UPDATE STEP
                            
                            let variables = {
                                "id": currentTranslation.productDataId, 
                                // "value": 
                                "asset": this.state.idAsset,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia)
                                variables.media = formValue.id;
                            
                            if (!isMedia)
                                variables.value = formValue;
    
                            await this.props.client.mutate({
                                mutation: UPDATE_ASSET_DATA,
                                variables
                            });
                        } else {
                            // CREATE STEP

                            let variables = {
                                // "id": currentTranslation.productDataId, 
                                // "value": 
                                "asset": this.state.idAsset,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia)
                                variables.media = formValue.id;
                            
                            if (!isMedia)
                                variables.value = formValue;
    
                            await this.props.client.mutate({
                                mutation: ADD_ASSET_DATA,
                                variables
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP
    
                        await this.props.client.mutate({
                            mutation: DELETE_ASSET_DATA,
                            variables: { "id": currentTranslation.productDataId }
                        });
                    }
                }
            }

            resolve();
        });
    };

    saveAssetExport = asset => {
        return new Promise(async (resolve, reject) => {
            let position = 0;
            for (let value of this.state.formattingValue) {
                if(value.new === true){
                    let variables = {
                        "position": position,
                        "keyName": value.value,
                        "asset": asset,
                    };
                    if (value.attr === 'SKU') {
                        variables.keyValue = value.attr;
                    } else {
                        variables.attribute = value.attr;
                    }
                    await this.props.client.mutate({
                        mutation: ADD_ASSET_EXPORT,
                        variables
                    });
                }
                else{
                    if(value.updated){
                        let variables = {
                            "id": value.id,
                            "position": position,
                            "keyName": value.value,
                            "asset": asset
                        };
                        
                        if (value.attr === 'SKU') {
                            variables.keyValue = value.attr;
                            variables.attribute = null;
                        } else {
                            variables.keyValue = null;
                            variables.attribute = value.attr;
                        }

                        await this.props.client.mutate({
                            mutation: UPDATE_ASSET_EXPORT,
                            variables
                        });
                    }
                }
                position++;
            }

            resolve();
        });
    };

    saveFormatUrl = async () => {
        if(this.state.formatUrl && this.state.formatUrl.length > 0 && this.state.formatUrlExist){
            await this.props.client.mutate({
                mutation: UPDATE_FORMAT_URL,
                variables: {id: this.state.idFormatUrl, delimiter: this.state.separatorFormattingUrl}
            }).then(result => {
                let idFormatUrl = result.data.updateFormatUrl.formatUrl.id;
                
                for(let item of this.state.formattingValueUrl){
                    if(item.updated && !item.new){
                        console.log(item, 'update')
                        this.props.client.mutate({
                            mutation: UPDATE_FORMAT_URL_ATTRIBUTE,
                            variables: {id: item.idFormat, position: parseInt(item.value)}
                        })
                    }
                    if(item.deleted && !item.new){
                        console.log(item, 'delete')
                        this.props.client.mutate({
                            mutation: DELETE_FORMAT_URL_ATTRIBUTE,
                            variables: {id: item.idFormat}
                        })
                    }
                    if(item.new && !item.deleted){
                        console.log(item, 'create')
                        this.props.client.mutate({
                            mutation: ADD_FORMAT_URL_ATTRIBUTE,
                            variables: {formatUrl: idFormatUrl, attribute: item.attr, position: parseInt(item.value)}
                        })
                    }
                }
            });
        }
        else if(this.state.formatUrl && this.state.formatUrl.length > 0 && !this.state.formatUrlExist){
            const RESULT_CREATE_FORMAT_URL = await this.props.client.mutate({
                mutation: CREATE_FORMAT_URL,
                variables: {delimiter: this.state.separatorFormattingUrl, asset: this.state.idAsset},
            });

            let idFormatUrl = RESULT_CREATE_FORMAT_URL.data.createFormatUrl.formatUrl.id;

            for(let item of this.state.formatUrl){
                const RESULT_ADD_FORMAT_URL_ATTRIBUTE = await this.props.client.mutate({
                    mutation: ADD_FORMAT_URL_ATTRIBUTE,
                    variables: {formatUrl: idFormatUrl, attribute: item.attr, position: parseInt(item.value),}
                })
            }
            this.setState({formatUrlExist: true})
        }
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }

            this.props.startLoading();

            let variables = {
                'id': this.state.idAsset,
                'startAt': this.state.paramStartAt,
                'endAt': this.state.paramEndAt,
                'status': this.state.paramStatus,
            }
            if(this.state.currency && this.state.currency !== null){
                variables.currencies = [this.state.currency];
            }
            if(this.state.groupRate && this.state.groupRate !== null){
                variables.rateGroups = [this.state.groupRate];
            }
            if(this.state.stageUrl !== null){
                variables.stageUrl = this.state.stageUrl;
            }
            if(this.state.stageHostUrl !== null){
                variables.stageHostUrl = this.state.stageHostUrl;
            }
            if(this.state.prodUrl !== null){
                variables.prodUrl = this.state.prodUrl;
            }
            if(this.state.prodHostUrl !== null){
                variables.prodHostUrl = this.state.prodHostUrl;
            }
            // if(this.state.assetGameType){
            //     variables.assetGameType = this.state.assetGameType;
            // }
            if (this.state.assetLangSelected){
                variables.locales = this.state.assetLangSelected;
            }
            if (this.state.assetsSelected.length > 0){
                variables.assetWidgets = this.state.assetsSelected.map(e => e.node.id);
            }
            console.log(variables)
            await this.props.client.mutate({
                mutation: UPDATE_ASSET,
                variables: variables
            });

            await this.saveAttributes(this.state.idAsset);
            await this.saveAssetExport(this.state.idAsset);
            await this.saveFormatUrl();
            this.handleSuccess();
        } catch(e) {
            console.log('here')
            this.handleError(e);
        }
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    handleFormImport = (type, title) => {
        this.setState({
            catalogFormData: {
                type,
                title
            },
            mapper: [],
            media: null,
            headers: null,
            importFile: null,
            importSep: ';',
            importValues: {},
            importLang: this.props.locales[0].node.id
        });

        this.handleToggleDrawer('openFormImport');
    };

    handlerMutationImport = async () => {
        this.props.startLoading();

        let importConfig = {
            "url": `${process.env.REACT_APP_API_ROOT}${this.state.media?.contentUrl ?? null}`,
            "mapper": this.state.mapper,
            "asset": this.state.idAsset,
            "delimiter": this.state.importSep
        };
        try {
            // todo thomas
            await request(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
            this.props.snack(ALERT_SUCCESS, `Votre fichier a été importé avec succès, son intégration dans SpreadSuite sera exécutée lors du passage du CRON (Tâche automatique).`,6000);
            await this.handleGetInstantDatas();
            this.handleToggleDrawer('openFormCardImport');
            this.handleToggleDrawer('openFormImport');
            this.props.stopLoading();
        } catch(e) {
            this.props.snack(ALERT_ERROR, `L'import a échoué !`);
            this.props.stopLoading();
        }
    };

    getGiftsToDelete = (result) => {
        let deleteGifts = []
        for (let i = 0; i < this.state.giftInitial.length; ++i ){
            let checkDelete = result.find(e => e.idGift === this.state.giftInitial[i].idGift)
            if (!checkDelete){
                deleteGifts.push(this.state.giftInitial[i].idGift)
            }
        }
        this.setState({
            giftToDelete: deleteGifts,
        });
    }

    getInstantToDelete = (result) => {
        let deleteInstants = []
        for (let i = 0; i < this.state.instantWinListInitial.length; ++i ){
            let checkDelete = result.find(e => e.idInstant === this.state.instantWinListInitial[i].idInstant)
            if (!checkDelete){
                deleteInstants.push(this.state.instantWinListInitial[i].idInstant)
            }
        }
        this.setState({
            instantsToDelete: deleteInstants,
        });
    }

    handlerMutationGame = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }
            let copyGifts = _.cloneDeep(this.state.giftSelected);
            let copyInstants = _.cloneDeep(this.state.instantWinList);
            await this.getGiftsToDelete(copyGifts);
            await this.getInstantToDelete(copyInstants);

            this.props.startLoading();

            for (let gift of this.state.giftSelected){
                //MUTATION ADD GIFT
                if (gift.new){
                    let variables = {
                        'product': gift.product.id,
                        'image': gift.giftImage.id,
                        'asset': this.state.idAsset,
                    }
                    await this.props.client.mutate({
                        mutation: ADD_GAME_GIFT,
                        variables: variables
                    }).then((result) => {
                        for(let instant of this.state.instantWinList){
                            //For each instant in array MUTATION GAME INSTANT
                            if(instant.new){
                                //CREATE INSTANT if new gift
                                if(gift.idGift === instant.productLink){
                                    let variablesInstant ={
                                        "assetGameGift": result.data.createAssetGameGift.assetGameGift.id,
                                        "asset": this.state.idAsset,
                                        "winningAt": instant.dateSelected,
                                        "isWin": instant.isWin
                                    }
                                    this.props.client.mutate({
                                        mutation: ADD_GAME_INSTANT,
                                        variables: variablesInstant
                                    })
                                }
                            }
                            //UPDATE INSTANT if new gift
                            if(instant.updated && !instant.new){
                                if(gift.idGift === instant.productLink){
                                    let variablesInstant ={
                                        "id": instant.idInstant,
                                        "assetGameGift": result.data.createAssetGameGift.assetGameGift.id,
                                        "asset": this.state.idAsset,
                                        "winningAt": instant.dateSelected,
                                        "isWin": instant.isWin
                                    }
                                    this.props.client.mutate({
                                        mutation: UPDATE_GAME_INSTANT,
                                        variables: variablesInstant
                                    }) 
                                }
                            }
                        }
                    })
                } else{
                    //UPDATE GIFT
                    if(gift.updated){
                        let variables = {
                            "id": gift.idGift,
                            "product": gift.product.id,
                            'image': gift.giftImage.id,
                            'asset': this.state.idAsset,
                        }
                        await this.props.client.mutate({
                            mutation: UPDATE_GAME_GIFT,
                            variables: variables
                        })
                    }

                    //For each instant in array MUTATION GAME INSTANT
                    for(let instant of this.state.instantWinList){
                        //CREATE INSTANT
                        if(instant.new){
                            if(gift.idGift === instant.productLink){
                                let variablesInstant ={
                                    "assetGameGift": instant.productLink,
                                    "asset": this.state.idAsset,
                                    "winningAt": instant.dateSelected,
                                    "isWin": instant.isWin
                                }
                                this.props.client.mutate({
                                    mutation: ADD_GAME_INSTANT,
                                    variables: variablesInstant
                                })
                            }
                        }else if(instant.updated && !instant.new){
                            //UPDATE INSTANT
                            if(gift.idGift === instant.productLink){
                                let variablesInstant ={
                                    "id" : instant.idInstant,
                                    "assetGameGift": instant.productLink,
                                    "asset": this.state.idAsset,
                                    "winningAt": instant.dateSelected,
                                    "isWin": instant.isWin
                                }
                                this.props.client.mutate({
                                    mutation: UPDATE_GAME_INSTANT,
                                    variables: variablesInstant
                                })
                            }
                        }
                    }
                }
            }

            //DELETE GIFT
            if (this.state.giftToDelete.length > 0 ){
                for (let giftId of this.state.giftToDelete){
                    await this.props.client.mutate({
                        mutation: DELETE_GAME_GIFT,
                        variables: {
                            'id': giftId
                        },
                    })
                }
            }

            //DELETE INSTANT
            if (this.state.instantsToDelete.length > 0 ){
                for (let instantId of this.state.instantsToDelete){
                    await this.props.client.mutate({
                        mutation: DELETE_GAME_INSTANT,
                        variables: {
                            'id': instantId
                        },
                    })
                }
            }

            this.handleSuccess();
        } catch(e) {
            this.handleError(e);
        }
    };


    resetStateAsset = () => {
        this.setState({
            productByCategorie: {},
            selectedProductByCategorie: {}
        });
    };

    handleGetCursorsAssets = () => {
        this.props.client.query({
            query: GET_ASSETS_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursorsWidgets: result.data.assets.edges,
            }, () => this.handleGetAssets());
        });
    }

    handleGetAssets = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_ASSET_TYPE_WIDGET,
            }).then(result => {
                let allTypeWidget= result.data.assetTypes.edges.map(e => e.node.assets.edges);
                let arrayIdWidget = [];

                for (let type of allTypeWidget){
                    for(let element of type){
                        let stripType = element.node.id.replace('/api/assets/', '')
                        arrayIdWidget.push(stripType)
                    }
                }
                if (arrayIdWidget.length > 0 ){
                    let variables = {
                        "nbperpage": this.state.nbperpageAssets, 
                        "id_list": arrayIdWidget,
                    };

                    if(this.state.cursorAssets && this.state.listCursorsWidgets && this.state.cursorAssets !== this.state.listCursorsWidgets[0].cursor){
                        variables.cursor = this.state.cursorAssets;
                    }
                    if (this.state.searchValue !== ''){
                        variables.libelle = this.state.searchValue;
                        variables.description = this.state.searchValue;
                    }

                    this.props.client.query({
                        query: GET_ASSETS_PAGINATION,
                        variables,
                        fetchPolicy:'no-cache'
                    }).then(result =>{
                        this.setState({
                            countPageAssets: Math.ceil(result.data.assets.totalCount / this.state.nbperpageAssets),
                            getAllAssets: result.data.assets.edges,
                            readyAssets: true,
                        });
                        this.props.stopLoading();
                        resolve();
                    })
                }else{
                    this.setState({
                        getAllAssets: [],
                        readyAssets: true,
                    });
                }
            })
        });
    }

    changePage = (event, page, type) => {
        this.props.startLoading();
        let index = 0;
        if(page > 1){
            switch (type){
                case 'asset':
                    index = ( page * this.state.nbperpageAssets ) - this.state.nbperpageAssets - 1
                break;
                default:
                    return null
            }
        }
        switch (type){
            case 'asset':
                this.setState({
                    cursorAssets: this.state.listCursorsWidgets[index].cursor,
                    pageAsset: page
                },()=>{
                    this.handleGetAssets(true);
                });
            default:
                return null
            // default:
            //     this.setState({
            //         cursor: this.state.listCursors[index].cursor,
            //         page: page
            //     },()=>{
            //         this.handleGetAssets();
            //     });
            // break;
        }
    };

    handleAssetEdit = (categorie) =>{
        let getAssetData = this.state.assetData.channel
        let getCatalog = this.state.assetData.catalog
        let getRetailer = this.state.assetData.retailer
        this.handleToggleDrawer('openFormEdit');

        this.setState({
            assetsEditData   : assetsEditConfig(categorie, this.state.currentLang,this.props.locales, this.state.listCurrencies, getAssetData, this.state.customAttributes, getCatalog, getRetailer, this.handleMediaPicker, this.state.getAllAssets, this.state, this.state.selectGroup),
            layoutImage     : categorie.logo
        })
    }

    handleAssetEditGifts = (game) =>{
        this.handleToggleDrawer('openFormEditGifts');
    }

    render() {
        const { paramStartAt, paramEndAt, paramStatus, prodUrl, assetsSelected } = this.state;
        const selectedLocales = this.state.assetLangSelected;
        const assetData = this.state.assetData;

        let colorImport = {
            shadow : "#346D8D",
            background : colors.blue.lighter.hue300,
            arrow: 'right'
        }

        return (
            assetData ? (
                <div>
                    <TopPanel
                        image           = {assetData.assetType.logo} 
                        colorIcomoon    = {colors.blue.lighter.hue300}
                        title           = {`Gérer mon Asset ${assetData.assetType.libelle}`}
                        subtitle        = "Apportez des modifications à votre asset" 
                        handlerAdd      = {() => {this.handleAssetEdit(this.state.assetData.assetType)}} 
                        textAdd         = {hasRights(BUILDER, BUILDER_ASSETS, UPDATE) ? "Modifier" : null}
                        handlerImport   = {() => {
                            if (assetData.assetType.identifier === 'gift_finder') {
                                window.open(`${window.location.origin}${ROUTE_BUILDER.replace(':assetId', assetData.identifier).replace(':pageId', 'config')}`, '_blank');
                            }else {
                                this.goTo(ROUTE_DIFFUSION_PERMANENTS_POSTS.replace(':id', assetData.id.replace('/api/assets/', '')));
                            }
                        }} 
                        textImport = {
                            (assetData.assetType.hasFeed || assetData.assetType.identifier === 'jeu' || assetData.assetType.identifier === 'magento') 
                                ? "Diffuser sur cet asset" 
                                : (assetData.assetType.identifier === 'gift_finder')
                                    ? "Configurer" 
                                    : null
                        }
                        colorImport     = {colorImport}
                        buttonAvailable = {this.state.isReady ? !this.state.openFormEdit : false}
                        ready           = {this.state.ready}
                        gradientColor1  = {colors.menu.regular} 
                        gradientColor2  = {colors.menu.darker} 
                        openForm        = {this.state.openFormEdit}
                        currentLang     = {this.state.currentLang} 
                        handleLang      = {this.handleLang} 
                        locales         = {this.props.locales}
                        hasBorder={true}
                    />
                    <Grid container spacing={2} style={{width: `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))`, marginTop: 16, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                        <Grid item xs={12}>
                            <CardAssetDetails 
                                asset                   = {{ paramStartAt, paramEndAt, paramStatus, prodUrl, selectedLocales, assetsSelected }} 
                                routeAsset              = {() => this.goTo(ROUTE_ENGINE_ASSETS)} 
                                // toggleDrawer         = {this.handleAssetEdit} 
                                toggleDrawerGifts       = {hasRights(BUILDER, BUILDER_ASSETS, UPDATE) ? this.handleAssetEditGifts : null} 
                                toggleImportInstants    = {hasRights(BUILDER, BUILDER_ASSETS, UPDATE) ? () => this.handleToggleDrawer('openFormCardImport') : null} 
                                routeStats              = {() => this.goTo(ROUTE_ENGINE_ASSETS_DETAIL_STATS.replace(':id', assetData.id.replace('/api/assets/', '')))} 
                                openForm                = {this.state.openForm}
                                imageAttributes         = {this.state.imageAttributes}
                                customAttributes        = {this.state.customAttributes}
                                currentLang             = {this.state.currentLang}
                                allState                = {this.state}
                                locales                 = {this.props.locales}
                                ready                   = {this.state.ready}
                                windowWidth             = {this.props.windowWidth}
                                drawerWidth             = {this.props.drawerWidth}
                            />                            
                        </Grid>
                    </Grid>

                    {
                        this.state.assetsEditData ?
                        (
                            <LayoutBuilder 
                                opened              = {this.state.openFormEdit} 
                                forClose            = {() => {this.handleToggleDrawer('openFormEdit')}}  
                                handleCancel        = {this.resetState}
                                dataLayout          = {this.state.assetsEditData} 
                                image               = {this.state.layoutImage} 
                                paginationInfo={{
                                    changePage : this.changePage,
                                    asset : {
                                        count : this.state.countPageAssets,
                                        page : this.state.pageAsset
                                    },
                                }}
                                stepperButtonAction = {[
                                    
                                    () =>{
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                            this.setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
        
                                        this.handleDrawerWidthChange(-350)
        
                                        return true;
                                    },
                                    () =>{
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                            this.setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
                                        
                                        return true;
                                    },
                                    () =>{
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                            this.setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
                                        
                                        return true;
                                    },
                                    () =>{
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                            this.setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
                                        
                                        return true;
                                    },
                                    () =>{
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                            this.setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
                                        
                                        return true;
                                    },
                                    () =>{
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                            this.setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
                                        
                                        return true;
                                    }
                                ]} 
                                backStepperButtonAction={[
                                    () => {
                                        this.setState({ errors: {} });
                                    }, 
                                    () => {
                                        this.setState({ errors: {} });
                                    }, 
                                    () => {
                                        this.setState({ errors: {} });
                                    }, 
                                    () => {
                                        this.setState({ errors: {} });
                                    },
                                    () => {
                                        this.setState({ errors: {} });
                                    },
                                    () => {
                                        this.setState({ errors: {} });
                                    },
                                    () => {
                                        this.setState({ errors: {} });
                                    },
                                ]} 
                                allState={this.state} 
                                currentLang={this.state.currentLang}
                                handleLang={this.handleLang}
                                handlerMutation={this.handlerMutation} 
                                stateCallback={this.handleInputChange} 
                                errorCallback={this.handleFormError}
                                handleButtonGroupChange={this.handleButtonGroupChange}
                                deleteMutation={hasRights(BUILDER, BUILDER_ASSETS, DELETE) ? this.handleToggleDialog : null}
                                deleteText={'Supprimer l\'asset'}
                                validateButton={true}
                            />
                        ) : ''
                    }
                    {
                        this.state.assetData.assetGameType ?
                        (
                            <>
                                <LayoutBuilder 
                                    opened              = {this.state.openFormEditGifts} 
                                    forClose            = {() => {this.handleToggleDrawer('openFormEditGifts', true)}}  
                                    handleCancel        = {this.resetGiftAndInstant}
                                    dataLayout          = {assetsGift()} 
                                    image               = {this.state.layoutImage} 
                                    drawerWidth         = {this.state.drawerWidthModified}
                                    stepperButtonAction = {[
                                        
                                        () =>{
                                            if (this.hasErrors()) {
                                                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                                this.setState({ seeErrors: true });
                                                eventService.fire();
                                                return false;
                                            }
            
                                            this.handleDrawerWidthChange(-1000)
            
                                            return true;
                                        },
                                        () =>{
                                            if (this.hasErrors()) {
                                                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                                this.setState({ seeErrors: true });
                                                eventService.fire();
                                                return false;
                                            }

                                            this.handleDrawerWidthChange(350)
                                            
                                            return true;
                                        },
                                        () =>{
                                            if (this.hasErrors()) {
                                                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                                this.setState({ seeErrors: true });
                                                eventService.fire();
                                                return false;
                                            }
                                            
                                            return true;
                                        },
                                        () =>{
                                            if (this.hasErrors()) {
                                                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                                this.setState({ seeErrors: true });
                                                eventService.fire();
                                                return false;
                                            }
                                            
                                            return true;
                                        }
                                    ]} 
                                    backStepperButtonAction={[
                                        () => {
                                            this.setState({ errors: {} });
                                        }, 
                                        () => {
                                            this.setState({ errors: {} });
                                            this.handleDrawerWidthChange(350)
                                        }, 
                                        () => {
                                            this.setState({ errors: {} });
                                            this.handleDrawerWidthChange(-1000)
                                        }, 
                                        () => {
                                            this.setState({ errors: {} });
                                        },
                                        () => {
                                            this.setState({ errors: {} });
                                        }
                                    ]} 
                                    allState={this.state} 
                                    currentLang={this.state.currentLang}
                                    handlerMutation={this.handlerMutationGame} 
                                    stateCallback={this.handleInputChange} 
                                    errorCallback={this.handleFormError}
                                    handleButtonGroupChange={this.handleButtonGroupChange}
                                    validateButton={true}
                                />

                                <LayoutBuilder 
                                    opened={this.state.openFormCardImport} 
                                    forClose={() => this.handleToggleDrawer('openFormCardImport')} 
                                    dataLayout={importInstantsTypesConfig} 
                                    drawerWidth={this.props.drawerWidth} 
                                    dataCard={[
                                        {
                                            'libelle': 'Importer via un',
                                            'bicoloreText': 'flux',
                                            'colorhover': '#6EAED1',
                                            'picto': importFlux,
                                            'disabled': true,
                                            'textButton': 'Importer',
                                            'description': 'Votre import contenus facilité en renseignant simplement votre flux', 
                                            'catalogDescription' : 'Veuillez compléter les champs ci-dessous',
                                            'onClick': () => this.handleFormImport(null, 'flux')
                                        },
                                        {
                                            'libelle': 'Importer via une',
                                            'bicoloreText': 'API',
                                            'colorhover': '#6EAED1',
                                            'picto': importAPI,
                                            'disabled': true,
                                            'textButton': 'Importer',
                                            'description': 'Votre import contenus facilité en renseignant simplement votre API', 
                                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                                            'onClick': () => this.handleFormImport(null, 'api')
                                        },
                                        {
                                            'libelle': 'Importer un',
                                            'bicoloreText': 'fichier',
                                            'colorhover': '#6EAED1',
                                            'picto': importFichier,
                                            'disabled': false,
                                            'textButton': 'Importer',
                                            'description': 'Votre import contenus facilité en important simplement votre fichier', 
                                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                                            'onClick': () => this.handleFormImport(null, 'fichier')
                                        }
                                    ]}
                                />

                                <LayoutBuilder 
                                    opened={this.state.openFormImport} 
                                    icomoon={'ico-import-fichier'}
                                    forClose={() => this.handleToggleDrawer('openFormImport')}  
                                    dataLayout={importInstantsConfig} 
                                    drawerWidth={this.state.drawerWidth}
                                    handlerMutation={this.handlerMutationImport} 
                                    allState={this.state} 
                                    stateCallback={this.stateCallback}
                                    stepperButtonDisabled={[() => this.state.headers === null, null]}
                                    stepperButtonAction={[null, null]}
                                    backStepperButtonAction={[null, null, null]}
                                />  
                            </>
                        ) : ''
                    }
                    <Dialog
                        open={this.state.openDialog}
                        onClose={this.handleToggleDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Êtes-vous sûr de vouloir supprimer cet asset ?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Si vous supprimez cet asset celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                                Annuler
                            </Button>
                            <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            ) : <PageLoader />
        );
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => ({...a})); 

    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: {assetId : id}
        });
    };
};


const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
}

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetails)));

