import styled from 'styled-components';

export default styled.h5`
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    font-family: 'DinRegular', sans-serif;
    color: ${props => props.color || '#000'};
    @media screen and (min-width: 1024px){
        font-size: 25px;
        line-height: 30px;
    }
    &.center{
        text-align: center;
    }
    &.uppercase{
        text-transform: uppercase;
    }
    &.white{
        color: white;
    }
    &.din-offc{
        font-size: 18px;
        @media screen and (min-width: 1024px){
            font-size: 23px;
            line-height: 28px;
        }
    }
`; 