import React from 'react';
import {Grid} from '@material-ui/core';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardContent from '../../../layouts/Card/cardContent/CardContent';
import TabCategories from '../products/components/TabCategories';
import Typography from '../../../ui/typography/Typography';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import styled from 'styled-components';
import { ROUTE_CONTENU_LIST_DETAIL, ROUTE_CONTENU_ADD, ROUTE_HOME } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { GET_CONTENTS_PAGINATION, GET_CONTENTS_CURSOR, SEARCH_CONTENTS_SKU, SEARCH_CONTENTS_SKU_BY_CAT } from '../../../../queries/contents';
import { GET_CONTENT_CATEGORIES_ONLY } from '../../../../queries/content_categories';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import importProductsTypesConfig from './config/importProductsTypes.config';
import importProductsConfig from './config/importProducts.config';
import importFichier from '../../../../assets/pictos/icon-import-fichier.svg';
import importAPI from '../../../../assets/pictos/icon-import-api.svg';
import importFlux from '../../../../assets/pictos/icon-import-flux.svg';
import emptyProduct from '../../../../assets/pictos/empty-picto/product.svg';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import request from '../../../../js/utils/fetch';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';
import Pagination from '@material-ui/lab/Pagination';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import { hasRights } from '../../../../js/utils/rights';
import { CONTENTS, CONTENTS_CONTENTS, VIEW, CREATE, IMPORT } from '../../../../js/constants/constant-rights';
import TreeCategories from '../../../ui/tree-view/TreeCategories';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1400px){
        padding: 16px 8px 8px!important;
    }
`;

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;

class ListContents extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentLang: props.locales[0].node.code,
            openForm: false,
            openFormImport: false,
            catalogFormData : {},
            ready: false,
            page: 1,
            nbperpage: 6,
            countPage: 0,
            cursor: null,
            skulist: [],
            searchValue: '',
            arrayCatFilter: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.typingSearchTimer = null;
    }

    handleRecursiveCat = (breadcrumb) => {
        this.state.activeCategorie.push('Home');
        if (breadcrumb){
            for (let categorie of breadcrumb){
                let translatedLibelle = categorie.categoryDatas.find(e => e.locale.code === this.state.currentLang)
                if (translatedLibelle){
                    this.state.activeCategorie.push(` > ${translatedLibelle.value}`);
                }else{
                    this.state.activeCategorie.push(` > ${categorie.libelle}`);
                }
            }
        }
    };

    handleSearch = () => {
        this.props.startLoading();
        let value = this.state.searchValue;
        if (value !== "" && this.state.listCursors.length > 0) {
            this.props.client.query({
                query: SEARCH_CONTENTS_SKU,
                variables: {"needle": value},
                fetchPolicy: 'no-cache'
            }).then(result =>{
                let stockSku = this.state.skulist;
                this.setState({
                    skulist: [],
                    page: 1,
                    cursor: this.state.listCursors[0].cursor
                }, () => {
                    if(this.state.activeCategorie.length <= 1){
                        for(let item of result.data.contentDatas.edges){
                            if(this.state.skulist.indexOf(item.node.content.sku) === -1){
                                this.state.skulist.push(item.node.content.sku);
                            }
                        }
                    }
                    else{
                        for(let sku of stockSku){
                            let check = false;
                            for(let item of result.data.contentDatas.edges){
                                if(sku === item.node.content.sku && !check){
                                    check = true;
                                }
                            }
                            if(check){
                                this.state.skulist.push(sku);
                            }
                        }
                    }
                });
                if(this.state.skulist.length === 0){
                    this.setState({noResult: true});
                    this.props.stopLoading();
                }
                else{
                    this.setState({noResult: false},() => {
                        this.handleGetContents();
                    });
                }
            });
        } else {
            this.setState({
                skulist: [],
                noResult: false
            },() => {
                if(this.state.activeCategorie.length <= 1){
                    this.handleGetContents();
                }
                else{
                    this.handleChangeTab(null,null,this.state.idActiveCat);
                }
            });
        }
    }

    handleChange(e, value) {
        clearTimeout(this.typingSearchTimer);
        if(e?.target?.value){
            this.setState({searchValue: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch();
            }, 500);
        } 
        else if(value){
            this.setState({
                searchValue: e.target.value
            }, this.handleSearch);
        }
        else {
            this.setState({
                skulist: [],
                noResult: false,
                searchValue: ''
            }, () => {
                if(this.state.activeCategorie.length <= 1){
                    this.handleGetContents();
                }
                else{
                    this.handleChangeTab(null,null,this.state.idActiveCat);
                }
            });
        }
    }

    getChildrenCat = (id) => {
        for(let categorie of this.state.categories) {
            if(categorie.id === id) {
                for(let child of categorie?.childrens){
                    this.state.arrayCatFilter.push(child.id);
                }
            }
        }
    }

    handleChangeTab = (event, id, breadcrumb) => {
        this.props.startLoading();
        if(id) {
            this.setState({
                idActiveCat: id,
                arrayCatFilter: [id],
            }, () => {
                this.getChildrenCat(id);
                this.props.client.query({
                    query: SEARCH_CONTENTS_SKU_BY_CAT,
                    variables: {"idlist": this.state.arrayCatFilter},
                    fetchPolicy: 'no-cache'
                }).then(result =>{
                    this.setState({
                        skulist: [],
                        page: 1,
                        cursor: this.state.listCursors[0].cursor
                    }, () => {
                        for(let item of result.data.contentCategories){
                            for(let content of item.contents.edges){
                                if(this.state.skulist.indexOf(content.node.sku) === -1){
                                    this.state.skulist.push(content.node.sku);
                                }
                            }
                        }
                        if(this.state.searchValue && this.state.searchValue !== ''){
                            this.handleChange(null, true);
                        }
                        else{
                            if(this.state.skulist.length === 0){
                                this.setState({noResult: true});
                                this.props.stopLoading();
                            }
                            else{
                                this.setState({noResult: false},() => {
                                    this.handleGetContents();
                                })
                            }
                        }
                    });
                    this.setState({activeCategorie: []}, () => {this.handleRecursiveCat(breadcrumb)});
                });
            });

            
        } else {
            this.setState({
                activeCategorie: ['Tout voir'],
                noResult: false
            }, () => {
                this.handleChange(null, true);
            });
        }
    };

    handleGetCursorsContents = () => {
        const getCursorsContents = GET_CONTENTS_CURSOR;
        this.props.client.query({
            query: getCursorsContents,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursors: result.data.contents.edges
            });
        });
    }

    handleGetContents() {
        return new Promise((resolve, reject) => {
            const getContents = GET_CONTENTS_PAGINATION;
            let variables = 
            {
                "nbperpage": this.state.nbperpage, 
            };
            if(this.state.cursor && this.state.listCursors && this.state.cursor !== this.state.listCursors[0].cursor){
                variables.cursor = this.state.cursor;
            }
            if(this.state.skulist.length > 0){
                variables.skulist = this.state.skulist;
            }
            this.props.client.query({
                query: getContents,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                this.listContents = result.data.contents.edges;
                this.setState({
                    countPage: Math.ceil(result.data.contents.totalCount / this.state.nbperpage),
                    listContents: this.listContents,
                });
                this.props.stopLoading();
                resolve();
            });
        });
    }

    handleGetCategories() {
        return new Promise((resolve, reject) => {
            request(`${process.env.REACT_APP_API}/categories-list/content`, 'get').then(
                (data) => {
                    if(data.success){
                        this.setState({
                            categories: data.datas,
                            activeCategorie: ['Tout voir']
                        });
                    }
                    resolve();
                }
            );
        });
    }

    componentDidMount() {
        const getRights = hasRights(CONTENTS, CONTENTS_CONTENTS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.getAll();
    }

    async getAll() {
        await this.handleGetContents();
        await this.handleGetCategories();
        await this.handleGetCursorsContents();
        this.setState({ ready: true });
    }

    handleToggleDrawer = (stateDrawer) => {
        this.setState({ 
            [stateDrawer] : !this.state[stateDrawer]
        });
    };

    handleFormImport = (type, title) => {
        this.setState({
            catalogFormData: {
                type,
                title
            },
            mapper: [],
            media: null,
            headers: null,
            importFile: null,
            importSep: ';',
            importValues: {},
            importLang: this.props.locales[0].node.id
        });

        this.handleToggleDrawer('openFormImport');
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();

            this.forceUpdate();
        });
    };

    handlerMutation = async () => {
        this.props.startLoading();

        let importConfig = {
            "url": `${process.env.REACT_APP_API_ROOT}${this.state.media?.contentUrl ?? null}`,
            "mapper": this.state.mapper,
            "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'content').node.id,
            "locale": this.state.importLang,
            "delimiter": this.state.importSep
        };

        try {
            // todo thomas
            await request(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
            this.props.snack(ALERT_SUCCESS, `Votre fichier a été importé avec succès, son intégration dans SpreadSuite sera exécutée lors du passage du CRON (Tâche automatique).`,6000);
            this.getAll();
            this.handleToggleDrawer('openForm');
            this.handleToggleDrawer('openFormImport');
            this.props.stopLoading();
        } catch(e) {
            this.props.snack(ALERT_ERROR, `L'import a échoué !`);
            this.props.stopLoading();
        }
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    changePage = (event, page) => {
        this.props.startLoading();
        let index = 0;
        if(page > 1){
            index = ( page * this.state.nbperpage ) - this.state.nbperpage - 1
        }
        this.setState({
            cursor: this.state.listCursors[index].cursor,
            page: page
        },()=>{
            this.handleGetContents();
        });
    };

    render() {
        return (
            <div>
                <TopPanel 
                    icomoon="picto-content"
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Gérer les Contenus" 
                    subtitle="Gestion de vos contenus (création / modification / suppression)" 
                    handlerAdd={() => this.goTo(ROUTE_CONTENU_ADD)} 
                    textAdd={hasRights(CONTENTS, CONTENTS_CONTENTS, CREATE) ? "Ajouter un contenu" : null}
                    handlerImport={() => this.handleToggleDrawer('openForm')} 
                    textImport={hasRights(CONTENTS, CONTENTS_CONTENTS, IMPORT) ? "Importer des contenus" : null}
                    searchHandler={this.handleChange} 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                />
                <Grid container alignItems="center" style={{background: 'white', borderBottom: `1px solid ${colors.grey.lighter.hue800}`}}>
                    {/* <Grid item xs={9}>
                        {
                            (this.state.listContents && this.state.listContents.length > 0) ? 
                            (
                            <>
                                <TabCategories categories={this.state.categories} rootid={this.state.rootid} handleChangeTab={this.handleChangeTab} currentLang={this.state.currentLang} typeContent={true}/>
                            </>
                            ) : null
                        }
                    </Grid> */}
                    <Grid item xs={12}>
                        <TraductionSelect 
                            currentLang={this.state.currentLang} 
                            handleLang={this.handleLang} 
                            locales={this.props.locales}
                        />
                    </Grid>
                </Grid>
                <div>
                    {/* <Typography variant="body2" style={{marginTop: 24, color: colors.blue.lighter.hue300}}>{this.state.activeCategorie ? this.state.activeCategorie : null}</Typography> */}
                    <Grid container direction="row" justify="center" style={{paddingTop: 16}} spacing={0}>
                        <Grid item md={2} sm={3} xs={3} style={{paddingRight: 16}}>
                            <TreeCategories query={GET_CONTENT_CATEGORIES_ONLY} resultVar={"contentCategories"} typeCategory={"content"} callbackCategorie={this.handleChangeTab} currentLang={this.state.currentLang}/>
                        </Grid>
                        <Grid item md={10} sm={9} xs={9}>
                            <Grid container direction="row" spacing={2}>
                                {
                                    !this.state.ready ? (<PageLoader />) : (this.state.listContents && this.state.listContents.length > 0 && !this.state.noResult) ? (
                                        this.state.listContents.map((content, index) => {
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListContenu${index}`}>
                                                    <CardCustom style={{width: "100%", height: "100%",  padding: "0px 16px"}} cardContentStyle={{height: "100%"}} hovercard={true}>
                                                        <CardContent 
                                                            currentLang={this.state.currentLang} 
                                                            content={content.node} 
                                                            index={index} 
                                                            routeContent={() => this.goTo(ROUTE_CONTENU_LIST_DETAIL.replace(':id', content.node.id.replace('/api/contents/', '')))} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    )
                                    : (this.state.noResult) ?
                                    (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={"Ajouter un contenu"} onClick={() => this.goTo(ROUTE_CONTENU_ADD)} picto={emptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} mtImg={2} />)
                                    : 
                                    (<EmptyCard title={"Vous n'avez pas encore configuré de contenu"} subtitle={"Ajouter un contenu en cliquant ci-dessous"} textButton={"Ajouter un contenu"} onClick={() => this.goTo(ROUTE_CONTENU_ADD)} picto={emptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                                }
                            </Grid>
                        </Grid>
                        {
                            this.state.ready && this.state.countPage > 1 && !this.state.noResult && this.state.listCursors?.length > 0 ?
                                <PaginationCustom onChange={(event, page) => {this.changePage(event, page)}} page={this.state.page} count={this.state.countPage} color="primary" />
                                : null
                        }
                    </Grid>
                </div>
                <LayoutBuilder 
                    opened={this.state.openForm} 
                    forClose={() => this.handleToggleDrawer('openForm')} 
                    dataLayout={importProductsTypesConfig} 
                    drawerWidth={this.props.drawerWidth} 
                    dataCard={[
                        {
                            'libelle': 'Importer via un',
                            'bicoloreText': 'flux',
                            'colorhover': '#6EAED1',
                            'picto': importFlux,
                            'disabled': true,
                            'textButton': 'Importer',
                            'description': 'Votre import contenus facilité en renseignant simplement votre flux', 
                            'catalogDescription' : 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'flux')
                        },
                        {
                            'libelle': 'Importer via une',
                            'bicoloreText': 'API',
                            'colorhover': '#6EAED1',
                            'picto': importAPI,
                            'disabled': true,
                            'textButton': 'Importer',
                            'description': 'Votre import contenus facilité en renseignant simplement votre API', 
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'api')
                        },
                        {
                            'libelle': 'Importer un',
                            'bicoloreText': 'fichier',
                            'colorhover': '#6EAED1',
                            'picto': importFichier,
                            'disabled': false,
                            'textButton': 'Importer',
                            'description': 'Votre import contenus facilité en important simplement votre fichier', 
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'fichier')
                        }
                    ]}
                />

                <LayoutBuilder 
                    opened={this.state.openFormImport} 
                    icomoon={'ico-import-fichier'}
                    forClose={() => this.handleToggleDrawer('openFormImport')}  
                    dataLayout={importProductsConfig} 
                    drawerWidth={this.state.drawerWidth}
                    // handleCancel={this.handleCancel}
                    handlerMutation={this.handlerMutation} 
                    allState={this.state} 
                    stateCallback={this.stateCallback}
                    stepperButtonDisabled={[() => this.state.headers === null, null]}
                    stepperButtonAction={[null, null]}
                    backStepperButtonAction={[null, null, null]}
                />      

            </div>
        );
    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: { contentId : id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListContents)));
