import React, { useEffect, useRef, useState } from "react";
import Swiper from 'swiper';
import H2 from '../shared/h2';
import H3 from '../shared/h3';
import H4 from '../shared/h4';
import Button from '../shared/a';
import Div from '../shared/div';
import P from '../shared/p';
import Img from '../shared/img';

const SliderTop = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    const getContentSlide = (slide) => {
        return(
            <Div className="absolute" style={{bottom: 50, left: '50%', transform: 'translateX(-50%)'}}>
                {
                    slide.blocks.title.inputs.value.value ? (
                        <H2 className="like-h1 center" color={slide.blocks.title.inputs.color.value}>{slide.blocks.title.inputs.value.value}</H2>
                    ) : null
                }
                {
                    slide.blocks.subtitle.inputs.value.value ? (
                        <H4 className="center" color={slide.blocks.subtitle.inputs.color.value}>{slide.blocks.subtitle.inputs.value.value}</H4> 
                    ) : null
                }
                <Button href={slide.blocks.link.inputs.link.value} color={slide.blocks.link.inputs.color.value} backgroundColor={slide.blocks.link.inputs.backgroundColor.value}>{slide.blocks.link.inputs.value.value}</Button>
            </Div>
        )
    }
    
    useEffect(() => {
        const swiper = new Swiper('.swiper-container-top', {
            direction: 'horizontal',
            pagination: {
                el: '.swiper-pagination-top',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next-top',
                prevEl: '.swiper-button-prev-top',
            },
            slidesPerView: 1,
            loop: true
        });
    }, []);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-top', {
            direction: 'horizontal',
            pagination: {
                el: '.swiper-pagination-top',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next-top',
                prevEl: '.swiper-button-prev-top',
            },
            slidesPerView: 1,
            loop: true
        });
    }, [props]);

    return (
        <Div style={{position: 'relative'}} className="slider-top" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className="swiper-container swiper-container-top hide-tablet hide-desktop" id={`swiper-${id}`}>
                <div className="swiper-wrapper">
                    {
                        data.blocks.slides.config.map((slide, index) => (
                            <Div className="swiper-slide w100" key={index}>
                                <Img 
                                    className="w100" 
                                    src={
                                        typeof slide.blocks.imageMobile.inputs.value.value === 'string' ? 
                                            slide.blocks.imageMobile.inputs.value.value 
                                        : slide.blocks.imageMobile.inputs.value.value.filePath ? 
                                            `${process.env.REACT_APP_MEDIAS}/${slide.blocks.imageMobile.inputs.value.value.filePath}` 
                                        : null
                                    }
                                    alt={slide.blocks.title.inputs.color.value}
                                />
                                {getContentSlide(slide)}
                            </Div>
                        ))
                    }
                </div>
                <div className="swiper-pagination swiper-pagination-top"></div>
                <div className="swiper-button-prev swiper-button-prev-top"></div>
                <div className="swiper-button-next swiper-button-next-top"></div>
            </Div>
            <Div className="swiper-container swiper-container-top hide-mobile">
                <div className="swiper-wrapper">
                {
                        data.blocks.slides.config.map((slide, index) => (
                            <Div className="swiper-slide w100 ">
                                <Img 
                                    className="w100" 
                                    src={
                                        typeof slide.blocks.imageDesktop.inputs.value.value === 'string' ? 
                                            slide.blocks.imageDesktop.inputs.value.value 
                                        : slide.blocks.imageDesktop.inputs.value.value.filePath ? 
                                            `${process.env.REACT_APP_MEDIAS}/${slide.blocks.imageDesktop.inputs.value.value.filePath}` 
                                        : null
                                    }
                                    alt={slide.blocks.title.inputs.color.value}
                                />
                                {getContentSlide(slide)}
                            </Div>
                        ))
                    }
                </div>
                <div className="swiper-pagination swiper-pagination-top"></div>
                <div className="swiper-button-prev swiper-button-prev-top"></div>
                <div className="swiper-button-next swiper-button-next-top"></div>
            </Div>
        </Div>
    );
};

export default SliderTop;