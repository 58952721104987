import { v4 as uuidv4 } from "uuid";
import { AssetTypes, FormInputTypes, ItemTypes, MenuTypes, Pages, SocialNetworks } from "../../shareable/types";
import { getDefaultConfig, getDefaultData } from "../../utils/data";
import FlipbookSuccessEmail from '../../../email/flipbook/emailSuccess';
import { Draggable } from "../components/builder";
import { Alert, AlertTitle } from "@material-ui/lab";

export const getDefaultPageConfig = (page, type, config, custom) => {

    switch (type) {
        case AssetTypes.FLIPBOOK:
        case AssetTypes.NEWSLETTER:
            switch(page) {
                case Pages.HOME:
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_BOOK,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_BOOK)
                            },
                            {
                                type: ItemTypes.TOP_HOMEPAGE_BOOK,
                                key: "top-homepage-book",
                                data: getDefaultData(ItemTypes.TOP_HOMEPAGE_BOOK)
                            },
                            {
                                type: ItemTypes.POSTCARD_BOOK,
                                key: "postcard-book",
                                data: getDefaultData(ItemTypes.POSTCARD_BOOK)
                            },
                            {
                                type: ItemTypes.FOOTER_BOOK,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_BOOK)
                            },
                        ]
                    };
                case Pages.FLIPBOOK:
                    let variante = custom || (config?.params?.menu?.value ?? "wishlist");

                    if (variante === "cart") {
                        return {
                            elements: [
                                {
                                    type: ItemTypes.FLIPBOOK_WITH_CART,
                                    key: "flipbook",
                                    data: getDefaultData(ItemTypes.FLIPBOOK_WITH_CART)
                                },
                                {
                                    type: ItemTypes.POSTCARD_BOOK,
                                    key: "postcard-book",
                                    data: getDefaultData(ItemTypes.POSTCARD_BOOK)
                                },
                                {
                                    type: ItemTypes.FOOTER_BOOK,
                                    key: "page-footer",
                                    data: getDefaultData(ItemTypes.FOOTER_BOOK)
                                },
                            ]
                        };
                    } else {
                        return {
                            elements: [
                                {
                                    type: ItemTypes.HEADER_BOOK,
                                    key: "page-header",
                                    data: getDefaultData(ItemTypes.HEADER_BOOK)
                                },
                                {
                                    type: ItemTypes.FLIPBOOK,
                                    key: "flipbook",
                                    data: getDefaultData(ItemTypes.FLIPBOOK)
                                },
                                {
                                    type: ItemTypes.POSTCARD_BOOK,
                                    key: "postcard-book",
                                    data: getDefaultData(ItemTypes.POSTCARD_BOOK)
                                },
                                {
                                    type: ItemTypes.FOOTER_BOOK,
                                    key: "page-footer",
                                    data: getDefaultData(ItemTypes.FOOTER_BOOK)
                                },
                            ]
                        };
                    };
                case Pages.REGISTER_BOOK:
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_BOOK,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_BOOK)
                            },
                            {
                                type: ItemTypes.RECAP_BLOCK_BOOK,
                                key: "recap-book",
                                data: getDefaultData(ItemTypes.RECAP_BLOCK_BOOK)
                            },
                            {
                                type: ItemTypes.POSTCARD_BOOK,
                                key: "postcard-book",
                                data: getDefaultData(ItemTypes.POSTCARD_BOOK)
                            },
                            {
                                type: ItemTypes.FOOTER_BOOK,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_BOOK)
                            },
                        ]
                    };
                case Pages.REGISTER_SUCCESS_BOOK:
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_BOOK,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_BOOK)
                            },
                            {
                                type: ItemTypes.RESULTS_BOOK,
                                key: "results-book",
                                data: getDefaultData(ItemTypes.RESULTS_BOOK)
                            },
                            {
                                type: ItemTypes.BANNER_LINK_BOOK,
                                key: "banner-link-book",
                                data: getDefaultData(ItemTypes.BANNER_LINK_BOOK)
                            },
                            {
                                type: ItemTypes.FOOTER_BOOK,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_BOOK)
                            },
                        ]
                    };
                case Pages.EMAIL_SUCCESS_BOOK:
                    return {
                        html: <FlipbookSuccessEmail data={getDefaultData(ItemTypes.EMAIL_SUCCESS_BOOK)} id={uuidv4()} />,
                        elements: [
                            {
                                type: "email_register",
                                data: getDefaultData(ItemTypes.EMAIL_SUCCESS_BOOK),
                                key: "email_register",
                            }
                        ]
                    };
                default: return null;
            };
        case AssetTypes.MINISITE:
            switch(page) {
                case Pages.HOME:
                    return {
                        elements: [
                            {
                                type: ItemTypes.SLIDER,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.SLIDER)
                            },
                        ]
                    };
                case Pages.LANDING:
                    return {
                        elements: [
                            {
                                type: ItemTypes.PAGE_LANDING,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.PAGE_LANDING)
                            }
                        ]
                    };
                case Pages.PRODUCT_DETAILS:
                    return {
                        elements: [
                            {
                                type: ItemTypes.PAGE_PRODUCT,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.PAGE_PRODUCT)
                            }
                        ]
                    };
                case Pages.CONTACT:
                    return {
                        elements: [
                            {
                                type: ItemTypes.PAGE_CONTACT,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.PAGE_CONTACT)
                            }
                        ]
                    };
                case Pages.WISHLIST:
                    return {
                        elements: [
                            {
                                type: ItemTypes.PAGE_WISHLIST,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.PAGE_WISHLIST)
                            }
                        ]
                    };
                case Pages.EMAIL_CONTACT:
                    return {
                        data: getDefaultData(ItemTypes.EMAIL_CONTACT),
                        pageType: Pages.EMAIL_CONTACT,
                        isEmail: true,
                        html: null
                    };
                default: return null;
            };
        case AssetTypes.GAME:
            switch(page) {
                case 'home_game':
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.HOME_GAME,
                                key: "page-home",
                                data: getDefaultData(ItemTypes.HOME_GAME)
                            },
                            {
                                type: ItemTypes.EXPLANATIONS_GAME,
                                key: "page-explanations",
                                data: getDefaultData(ItemTypes.EXPLANATIONS_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'login_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.LOGIN_GAME,
                                key: "page-login",
                                data: getDefaultData(ItemTypes.LOGIN_GAME)
                            },
                            {
                                type: ItemTypes.EXPLANATIONS_GAME,
                                key: "page-explanations",
                                data: getDefaultData(ItemTypes.EXPLANATIONS_GAME)
                            },
                            {
                                type: ItemTypes.DOTATIONS,
                                key: "page-dotations",
                                data: getDefaultData(ItemTypes.DOTATIONS)
                            },
                            {
                                type: ItemTypes.RULES,
                                key: "page-rules",
                                data: getDefaultData(ItemTypes.RULES)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'index_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.INDEX_GAME,
                                key: "page-index",
                                data: getDefaultData(ItemTypes.INDEX_GAME)
                            },
                            {
                                type: ItemTypes.EXPLANATIONS_GAME,
                                key: "page-explanations",
                                data: getDefaultData(ItemTypes.EXPLANATIONS_GAME)
                            },
                            {
                                type: ItemTypes.DOTATIONS,
                                key: "page-dotations",
                                data: getDefaultData(ItemTypes.DOTATIONS)
                            },
                            {
                                type: ItemTypes.RULES,
                                key: "page-rules",
                                data: getDefaultData(ItemTypes.RULES)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'results_win_game':
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.RESULTS_WIN_GAME,
                                key: "page-results",
                                data: getDefaultData(ItemTypes.RESULTS_WIN_GAME)
                            },
                            {
                                type: ItemTypes.REDIRECT_ON_GAME,
                                key: "page-redirect-on",
                                data: getDefaultData(ItemTypes.REDIRECT_ON_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'results_loose_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.RESULTS_LOOSE_GAME,
                                key: "page-results",
                                data: getDefaultData(ItemTypes.RESULTS_LOOSE_GAME)
                            },
                            {
                                type: ItemTypes.REDIRECT_ON_GAME,
                                key: "page-redirect-on",
                                data: getDefaultData(ItemTypes.REDIRECT_ON_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'alreadyplayed_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.ALREADYPLAYED_GAME,
                                key: "page-alreadyplayed",
                                data: getDefaultData(ItemTypes.ALREADYPLAYED_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'ended_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.ENDED_GAME,
                                key: "page-ended",
                                data: getDefaultData(ItemTypes.ENDED_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'not_started_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.NOT_STARTED_GAME,
                                key: "page-not-started",
                                data: getDefaultData(ItemTypes.NOT_STARTED_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                default: return null;
            };
        case AssetTypes.MAGENTO:
            switch(page) {
                case Pages.CMS_HOME:
                    return {
                        elements: [
                            {
                                type: ItemTypes.CMS_SLIDER,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER)
                            },
                            {
                                type: ItemTypes.CMS_CATEGORIES_HOME,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_CATEGORIES_HOME)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_PRODUCTS_HOME,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_PRODUCTS_HOME)
                            },
                            {
                                type: ItemTypes.CMS_COLLECTIONS_HOME,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_COLLECTIONS_HOME)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_ABOUT,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_ABOUT)
                            },
                            {
                                type: ItemTypes.CMS_LIST_CATEGORIES,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_LIST_CATEGORIES)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_BY_TAB,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_BY_TAB)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_PRODUCTS_HOME,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_PRODUCTS_HOME)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_INSTAGRAM,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_INSTAGRAM)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_MOMENTS,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_MOMENTS)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_IMAGE,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_IMAGE)
                            },
                            {
                                type: ItemTypes.CMS_GRID_IMAGE,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_GRID_IMAGE)
                            },
                            {
                                type: ItemTypes.CMS_TEXT_MEDIA,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_TEXT_MEDIA)
                            },
                            {
                                type: ItemTypes.CMS_BANNER_MEDIA,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_BANNER_MEDIA)
                            },
                            {
                                type: ItemTypes.CMS_SECTION_DESCRIPTION_PAGE,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SECTION_DESCRIPTION_PAGE)
                            },
                            {
                                type: ItemTypes.CMS_SECTION_MANIFEST_BLOCK,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SECTION_MANIFEST_BLOCK)
                            },
                            {
                                type: ItemTypes.CMS_BLOC_STORELOCATOR_HOME,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_BLOC_STORELOCATOR_HOME)
                            },
                        ]
                    };
            };
        default:
            switch (page) {
                case Pages.GIFT_FINDER: 
                    return {
                        elements: [
                            {
                                type: ItemTypes.GIFT_FINDER,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.GIFT_FINDER)
                            }
                        ]
                    };
                case Pages.MAGENTO: 
                    return {
                        elements: [
                            {
                                type: ItemTypes.PLACEHOLDER,
                                key: uuidv4(),
                                placeholderConfig: {
                                    text: "Entête de page",
                                    height: 250,
                                    bgColor: '#ccc',
                                    color: '#9a9a9a',
                                    fullWidth: true
                                }
                            }, {
                                type: ItemTypes.SLIDER_WITH_RIGHT_IMAGES,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.SLIDER_WITH_RIGHT_IMAGES)
                            }, {
                                type: ItemTypes.SLIDER_MULTIPLE,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.SLIDER_MULTIPLE)
                            }, {
                                type: ItemTypes.PLACEHOLDER,
                                key: uuidv4(),
                                placeholderConfig: {
                                    text: "Bandeau produits",
                                    height: 200,
                                    bgColor: '#ccc',
                                    color: '#9a9a9a',
                                    fullWidth: false
                                }
                            }, {
                                type: ItemTypes.THREE_IMAGES_ROW,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.THREE_IMAGES_ROW)
                            }, {
                                type: ItemTypes.THREE_IMAGES_GRID,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.THREE_IMAGES_GRID)
                            }, {
                                type: ItemTypes.BLOC_TEXT,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.BLOC_TEXT)
                            }, {
                                type: ItemTypes.PLACEHOLDER,
                                key: uuidv4(),
                                placeholderConfig: {
                                    text: "Pied de page",
                                    height: 200,
                                    bgColor: '#ccc',
                                    color: '#9a9a9a',
                                    fullWidth: true,
                                    noBottomMargin: true
                                }
                            }
                        ]
                    };
                case Pages.PRESENTATION: 
                    return {
                        elements: [
                            {
                                type: ItemTypes.OFFER_FRONT_PAGE,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.OFFER_FRONT_PAGE)
                            },
                        ]
                    };
                case 'wordpress': return getDefaultConfig(Pages.WORDPRESS);
                case 'facebook': return getDefaultConfig(Pages.FACEBOOK);
                default: return null;
            }; 
    }
};

export const getDefaultParams = (type) => {
    switch (type) {
        case AssetTypes.MINISITE:
        case AssetTypes.NEWSLETTER:
            return {
                logo: {
                    id: "main-logo",
                    name: "Logo",
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/200x100"
                },
                menu: {
                    id: "header-menu",
                    name: "Menu",
                    type: FormInputTypes.MENU,
                    value: [
                        {
                            type: MenuTypes.LINK,
                            readonly: true,
                            id: "home",
                            name: "Accueil",
                            value: "/"
                        },
                        {
                            type: MenuTypes.LINK,
                            readonly: true,
                            id: "contact",
                            name: "Contact",
                            value: "/contact"
                        }
                    ]
                },
                emailContact: {
                    id: "emailContact",
                    name: "Email de contact",
                    type: FormInputTypes.TEXT,
                    value: ""
                },
                hasHeaderFooter: {
                    id: "has-header-footer",
                    name: "Activer header / footer",
                    type: FormInputTypes.SWITCH,
                    value: true
                },
                hasContact: {
                    id: "has-contact",
                    name: "Formulaire de contact",
                    type: FormInputTypes.SWITCH,
                    value: true
                },
                hasSearch: {
                    id: "has-search",
                    name: "Recherche",
                    type: FormInputTypes.SWITCH,
                    value: true
                },
                hasWishlist: {
                    id: "has-wishlist",
                    name: "Wishlist",
                    type: FormInputTypes.SWITCH,
                    value: true
                },
                isExternal: {
                    id: "is-external",
                    name: "Externe",
                    type: FormInputTypes.SWITCH,
                    value: false
                },
                moto: {
                    id: "moto",
                    name: "Slogan",
                    type: FormInputTypes.TEXTAREA,
                    value: "Lorem ipsum"
                },
                copyright: {
                    id: "copyright",
                    name: "Copyright",
                    type: FormInputTypes.TEXT,
                    value: "© 2020 [Votre entreprise]. All Rights Reserved."
                },
                social: {
                    id: "social-networks",
                    name: "Réseaux sociaux",
                    type: FormInputTypes.SOCIAL,
                    value: [
                        { icon: SocialNetworks.FACEBOOK, value: "//facebook.com" },
                        { icon: SocialNetworks.TWITTER, value: "//twitter.com" },
                        { icon: SocialNetworks.INSTAGRAM, value: "//instagram.com" },
                        { icon: SocialNetworks.PINTEREST, value: "" },
                        { icon: SocialNetworks.YOUTUBE, value: "" },
                        { icon: SocialNetworks.LINKEDIN, value: "" },
                    ]
                },
                numberOne: {
                    name: 'Numéro (1)',
                    type: FormInputTypes.TEXT,
                    value: "+33 0 00 00 00 00"
                },
                numberTwo: {
                    name: 'Numéro (2)',
                    type: FormInputTypes.TEXT,
                    value: "+33 0 00 00 00 00"
                },
                webOne: {
                    name: 'Website (1)',
                    type: FormInputTypes.TEXT,
                    value: "google.com",
                    placeholder: 'Texte'
                },
                webOneLink: {
                    type: FormInputTypes.TEXT,
                    value: "//google.com",
                    placeholder: 'Lien'
                },
                webTwo: {
                    name: 'Website (2)',
                    type: FormInputTypes.TEXT,
                    value: "google.com",
                    placeholder: 'Texte'
                },
                webTwoLink: {
                    type: FormInputTypes.TEXT,
                    value: "//google.com",
                    placeholder: 'Lien'
                },
                addressOne: {
                    name: 'Adresse (1)',
                    type: FormInputTypes.TEXT,
                    value: "1400 Park Avenue Emeryville"
                },
                addressTwo: {
                    name: 'Adresse (2)',
                    type: FormInputTypes.TEXT,
                    value: "CA 94608 United States"
                },
                footerColor: {
                    id: "footer-color",
                    name: "Couleur du pied de page",
                    type: FormInputTypes.COLOR,
                    value: '#000'
                },
            };
        case AssetTypes.FLIPBOOK:
            return {
                variante: {
                    type: FormInputTypes.SELECT,
                    label: "Variante de catalogue",
                    value: 'wishlist',
                    params: [{ label: 'Wishlist', value: 'wishlist' }, { label: 'Ajout au panier', value: 'cart' }]
                },
                switch: {
                    label: "Désactiver le panier / wishlist",
                    type: FormInputTypes.SWITCH,
                    value: false,
                }
            };
        case AssetTypes.GAME:
            return {
                bg: {
                    id: "global-background",
                    name: "Fond entier",
                    type: FormInputTypes.IMAGE,
                    value: null,
                },
            };
        default: return null;
    }
};

export const getDraggables = (type, presentation = false) => {
    if (presentation) {
        return (
            <>
                <Draggable type={ItemTypes.OFFER_FRONT_PAGE} />
                <Draggable type={ItemTypes.OFFER_SECTION_TITLE} />
                <Draggable type={ItemTypes.OFFER_ABOUT_SLIDE} />
                <Draggable type={ItemTypes.OFFER_TRIPLE_BLOCK_LIST} />
                <Draggable type={ItemTypes.OFFER_TRIPLE_BLOCK} />
                <Draggable type={ItemTypes.OFFER_TRIPLE_BLOCK_TEXT} />
                <Draggable type={ItemTypes.OFFER_TEXT_WITH_IMAGE} />
                <Draggable type={ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE} />
                <Draggable type={ItemTypes.OFFER_DEVIS} />
            </>
        );
    }

    switch (type) {
        case AssetTypes.MINISITE:
            return (
                <>
                    <Draggable type={ItemTypes.SLIDER} />
                    <Draggable type={ItemTypes.SLIDER_MULTIPLE} />
                    <Draggable type={ItemTypes.SLIDER_WITH_RIGHT_IMAGES} />
                    <Draggable type={ItemTypes.TAB_PRODUCT} />
                    <Draggable type={ItemTypes.IMAGE_ROW} />
                    <Draggable type={ItemTypes.SECTION_TITLE} />
                    <Draggable type={ItemTypes.THREE_IMAGES_GRID} />
                    <Draggable type={ItemTypes.THREE_IMAGES_ROW} />
                    <Draggable type={ItemTypes.BLOC_TEXT} />
                    <Draggable type={ItemTypes.GIFT_FINDER} />
                    <Draggable type={ItemTypes.INFORMATION_BANNER} />
                    <Draggable type={ItemTypes.LINK_BANNER} />
                    <Draggable type={ItemTypes.CATEGORY} />
                    <Draggable type={ItemTypes.TEXT_IMAGE} />
                    <Draggable type={ItemTypes.SLIDER_PRODUCT} />
                    <Draggable type={ItemTypes.TEXT_VIDEO} /> 
                    {/* A SUPPRIMER CAR CMS */}
                    <Draggable type={ItemTypes.CMS_ENTETE} />
                    <Draggable type={ItemTypes.CMS_TOP_IMAGE_FULLSCREEN} />
                    <Draggable type={ItemTypes.CMS_SLOGAN} />
                    <Draggable type={ItemTypes.CMS_TITLE_TEXT} />
                    <Draggable type={ItemTypes.CMS_SEPARATOR} />
                    <Draggable type={ItemTypes.CMS_SLIDER} />
                    <Draggable type={ItemTypes.CMS_SLIDER_ABOUT} />
                    <Draggable type={ItemTypes.CMS_LIST_CATEGORIES} />
                    <Draggable type={ItemTypes.CMS_SLIDER_BY_TAB} />
                    <Draggable type={ItemTypes.CMS_SLIDER_INSTAGRAM} />
                    <Draggable type={ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA} />
                    <Draggable type={ItemTypes.CMS_SLIDER_MOMENTS} />
                    <Draggable type={ItemTypes.CMS_SLIDER_IMAGE} />
                    <Draggable type={ItemTypes.CMS_GRID_IMAGE} />
                    <Draggable type={ItemTypes.CMS_TEXT_MEDIA} />
                    <Draggable type={ItemTypes.CMS_BANNER_MEDIA} />
                    <Draggable type={ItemTypes.CMS_SECTION_DESCRIPTION_PAGE} />
                    <Draggable type={ItemTypes.CMS_SECTION_MANIFEST_BLOCK} />
                    <Draggable type={ItemTypes.CMS_CATEGORIES_HOME} />
                    <Draggable type={ItemTypes.CMS_COLLECTIONS_HOME} />
                    <Draggable type={ItemTypes.CMS_SLIDER_PRODUCTS_HOME} />
                    <Draggable type={ItemTypes.CMS_BLOC_STORELOCATOR_HOME} />
                    {/* FIN A SUPPRIMER CAR CMS */}
                </>
            );
        case AssetTypes.MAGENTO:
            return (
                <>
                    <Draggable type={ItemTypes.CMS_ENTETE} />
                    <Draggable type={ItemTypes.CMS_TOP_IMAGE_FULLSCREEN} />
                    <Draggable type={ItemTypes.CMS_SLOGAN} />
                    <Draggable type={ItemTypes.CMS_TITLE_TEXT} />
                    <Draggable type={ItemTypes.CMS_SEPARATOR} />
                    <Draggable type={ItemTypes.CMS_SLIDER} />
                    <Draggable type={ItemTypes.CMS_SLIDER_ABOUT} />
                    <Draggable type={ItemTypes.CMS_LIST_CATEGORIES} />
                    <Draggable type={ItemTypes.CMS_SLIDER_BY_TAB} />
                    <Draggable type={ItemTypes.CMS_SLIDER_INSTAGRAM} />
                    <Draggable type={ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA} />
                    <Draggable type={ItemTypes.CMS_SLIDER_MOMENTS} />
                    <Draggable type={ItemTypes.CMS_SLIDER_IMAGE} />
                    <Draggable type={ItemTypes.CMS_GRID_IMAGE} />
                    <Draggable type={ItemTypes.CMS_TEXT_MEDIA} />
                    <Draggable type={ItemTypes.CMS_BANNER_MEDIA} />
                    <Draggable type={ItemTypes.CMS_SECTION_DESCRIPTION_PAGE} />
                    <Draggable type={ItemTypes.CMS_SECTION_MANIFEST_BLOCK} />
                    <Draggable type={ItemTypes.CMS_CATEGORIES_HOME} />
                    <Draggable type={ItemTypes.CMS_COLLECTIONS_HOME} />
                    <Draggable type={ItemTypes.CMS_SLIDER_PRODUCTS_HOME} />
                    <Draggable type={ItemTypes.CMS_BLOC_STORELOCATOR_HOME} />
                </>
            );
        case AssetTypes.GAME:
            return (
                <>
                    <Draggable type={ItemTypes.PRE_HEADER_GAME} />
                    <Draggable type={ItemTypes.HOME_GAME}>
                        <Draggable type={ItemTypes.HOME_COMPLETE_FORM_GAME} />
                    </Draggable>
                    <Draggable type={ItemTypes.EXPLANATIONS_GAME}>
                        <Draggable type={ItemTypes.EXPLANATIONS_ACV_GAME} />
                        <Draggable type={ItemTypes.EXPLANATIONS_BF_GAME} />
                        <Draggable type={ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME} />
                    </Draggable>
                    <Draggable type={ItemTypes.AMAZING_GAME}>
                        <Draggable type={ItemTypes.TITLE_GAME} />
                        <Draggable type={ItemTypes.PARAGRAPH_GAME} />
                        <Draggable type={ItemTypes.RULES_AMAZING_GAME} />
                        <Draggable type={ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME} />
                        <Draggable type={ItemTypes.DOTATIONS_ONE_IMAGE} />
                        <Draggable type={ItemTypes.BUTTON_GAME} />
                    </Draggable>
                    <Draggable type={ItemTypes.BASIC_BLOC}>
                        <Draggable type={ItemTypes.BASIC_BLOC2} />
                    </Draggable>
                    <Draggable type={ItemTypes.REDIRECT_ON_GAME}>
                        <Draggable type={ItemTypes.REDIRECT_ON_GAME3} />
                        <Draggable type={ItemTypes.REDIRECT_ON_GAME4} />
                    </Draggable>
                </>
            );
        default: 
            return (
                <Alert severity="warning" style={{ width: "calc(100% - 40px)", marginTop: 10, marginBottom: 0 }}>
                    <AlertTitle>Attention</AlertTitle>
                    Aucun <strong>composant</strong> disponible pour le gift finder
                </Alert>
            );
    }
};