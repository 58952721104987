import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlideAbout } from '../../helpersCms';

export default {
    name: 'Slider à propos',
    inputs: {
        value: {
            type: FormInputTypes.TEXT,
            label: "Changer le texte",
            value: "À propos de nous"
        },
    },
    blocks:{
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_ABOUT,
            buttonAdd: 'une slide',
            limit: 10,
            config: [ 
                getDefaultSlideAbout(),
                getDefaultSlideAbout(),
                getDefaultSlideAbout(),
            ]
        }
    }
};