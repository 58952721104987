import React, { useEffect, useRef, useState } from "react";
import Swiper from 'swiper';
import H2 from '../shared/h2';
import H3 from '../shared/h3';
import H4 from '../shared/h4';
import Button from '../shared/a';
import Div from '../shared/div';
import P from '../shared/p';
import Img from '../shared/img';
import styled from 'styled-components';

const DivColumnsTitleParagraph = styled(Div)`
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    padding-top: 14px;
    padding-bottom: 50px;
    max-width: calc(100vw - 32px);
    overflow: hidden;
    .columnTitle {
        width: 100%;
        padding-right: 20px;
    }
    .columnTitle h2 {
        margin-bottom: 18px;
        font-size: 23px;
        line-height: 29px;
        font-family: 'DINOffc-Bold',sans-serif;
    }
    .columnTitle p {
        font-size: 18px;
        line-height: 23px;
        font-family: 'DinRegular', sans-serif;
        width: 100%;
        padding-bottom: 23px;
        margin-bottom: 23px;
        border-bottom: 2px solid rgb(230 231 234);
        max-width: 480px;
    }
    .columnParagraph{
        width: 100%;
        max-width: 900px;
    }
    .columnParagraph p{
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 23px;
        font-family: 'DinRegular', sans-serif;
        margin-top: 0;
    }

    .sliderMoments{
        padding: 0;
        position: relative;
        width: calc(100% + 16px);
    }

    .sliderMoments > div:first-of-type{
        position: relative;
        margin: 0;
        width: 100%;
    }

    .columnParagraph{
        width: 100%;
        max-width: 900px;
        padding-right: 0;
    }
    .columnParagraphText{
        width: calc(100% - 1rem);
        max-width: calc(900px - 1rem);
        padding-right: 1rem;
    }

    @media screen and (min-width: 768px){
        flex-direction: row;
        .columnParagraph{
            width: 60%;
            max-width: calc(100% - (40%));
        }
        .columnParagraphText{
            max-width: 900px;
        }
        .columnTitle{
            width: 40%;
        }
    }
    @media screen and (min-width: 900px){
        flex-direction: row;
        padding-top: 30px;
        padding-bottom: 50px;
        .columnTitle p {
            max-width: 550px;
            border-bottom: 0;
        }
    }
    @media screen and (min-width: 1024px){
        padding-right: 22px;
        padding-left: 22px;
        max-width: calc(100% - 44px);
        .columnTitle {
            width: 40%;
            min-width: 390px;
            padding-right: 60px;
        }
        .columnTitle h2 {
            margin-bottom: 18px;
            font-size: 28px;
            line-height: 41px;
        }
        .columnTitle p {
            font-size: 22px;
            line-height: 28px;
        }
        .columnParagraph{
            width: 60%;
            max-width: calc(100% - (390px + 60px));
        }
        .columnParagraph p{
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 28px;
        }
        .sliderMoments{
            width: calc(100% + 22px);
        }
    }
    @media screen and (min-width:1450px){
        padding-right: 60px;
        padding-left: 60px;
        max-width: calc(100% - 120px);
        .sliderMoments{
            width: calc(100% + 60px);
        }
    }
`;

const SectionDescriptionPage = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;
    
    useEffect(() => {
        const swiper = new Swiper('.swiper-container-image', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-image',
            },
            navigation: {
                nextEl: ".swiper-button-next-image",
                prevEl: ".swiper-button-prev-image",
            },
            slidesPerView: 1.8,
            breakpoints: {
                300:{
                    slidesPerView: 1.2,
                    spaceBetween: 8
                },
                500:{
                    spaceBetween: 16,
                    slidesPerView: 1.2,
                },
                768:{
                    spaceBetween: 16,
                    slidesPerView: 1.2,
                },
                1000:{
                    slidesPerView: 1.2,
                    spaceBetween: 16,
                }
            }
        });
    }, []);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-image', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-image',
            },
            navigation: {
                nextEl: ".swiper-button-next-image",
                prevEl: ".swiper-button-prev-image",
            },
            slidesPerView: 1.8,
            breakpoints: {
                300:{
                    slidesPerView: 1.2,
                    spaceBetween: 8
                },
                500:{
                    spaceBetween: 16,
                    slidesPerView: 1.2,
                },
                768:{
                    spaceBetween: 16,
                    slidesPerView: 1.2,
                },
                1000:{
                    slidesPerView: 1.2,
                    spaceBetween: 16,
                }
            }
        });
    }, [props]);

    return (
        <Div  style={{ position: 'relative', boxSizing: 'inherit', /*background: data.blocks.background.inputs.backgroundColor.value*/ }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <DivColumnsTitleParagraph className="columnsTitleParagraph">
                <Div className="columnTitle">
                    <H2 className="w100 uppercase">{data.blocks.title.inputs.value.value}</H2>
                    <P><strong>{data.blocks.subtitle.inputs.value.value}</strong></P> 
                </Div>
                <Div className="columnParagraph">
                    <Div className="columnParagraphText" dangerouslySetInnerHTML={{__html: data.blocks.text.inputs.value.value}} />
                    {
                        data.inputs['showSlider'].value ? (
                            <Div className="sliderMoments slider-image">
                                <Div className="swiper-container swiper-container-image">
                                    <Div className="swiper-wrapper">
                                        {
                                            data.blocks.slides.config.map((slide, index) => (
                                                <Div className="swiper-slide w100">
                                                    <Img 
                                                        src={
                                                            typeof slide.blocks.imagePrincipale?.inputs.value.value === 'string' ? 
                                                                slide.blocks.imagePrincipale?.inputs.value.value 
                                                            : slide.blocks.imagePrincipale?.inputs.value.value.filePath ? 
                                                                `${process.env.REACT_APP_MEDIAS}/${slide.blocks.imagePrincipale?.inputs.value.value.filePath}` 
                                                            : null
                                                        }
                                                        alt={data.blocks.title.inputs.value.value}
                                                    />
                                                </Div>
                                            ))
                                        }
                                    </Div>
                                    <Div className="swiper-scrollbar swiper-scrollbar-image"></Div>
                                    <Div className="swiper-button-prev swiper-button-prev-image"></Div>
                                    <Div className="swiper-button-next swiper-button-next-image"></Div>
                                </Div>
                            </Div>
                        ) : null
                    }
                </Div>
            </DivColumnsTitleParagraph>
        </Div>
    );
};

export default SectionDescriptionPage;