import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultTabForSlider, getDefaultTextareaCms, getDefaultLinkCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Slider par catégorie',
    inputs: {
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
        colorButtonTab: {
            label: 'Couleur des boutons',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
        colorButtonTabActive: {
            label: 'Couleur du bouton actif',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
        backgroundButtonTab: {
            label: 'Couleur de fond du bouton',
            type: FormInputTypes.COLOR,
            value: '#53839D'
        },
        backgroundButtonTabActive: {
            label: 'Couleur de fond du bouton actif',
            type: FormInputTypes.COLOR,
            value: '#004369'
        },
    },
    blocks:{
        logo: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: "http://via.placeholder.com/400x100/53839D/FFFFFF?text=Logo"
                }
            }
        },
        text: getDefaultTextareaCms("Texte", "Avec l’arrivée de Ventile au sein de l’équipe, le footwear du Coq Sportif continue de faire vivre sa créativité à travers cette nouvelle matière. Des sneakers prêtes à être portées cet été et à embarquer avec elles les valeurs de la marque française : des matières 100% naturelles et une sensation douce et confortable.", true, '#53839D'),
        categories:{
            title: "Catégories",
            name: "Catégorie",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_BY_TAB,
            buttonAdd: 'une catégorie',
            limit: 3,
            config: [
                getDefaultTabForSlider(),
                getDefaultTabForSlider(),
            ]
        },
        link: getDefaultLinkCms("Lien", "Découvrir", "#", '#FFFFFF', '#000000'),
    }
};