import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CatalogFinal from '../../../../../assets/pictos/empty-picto/catalogue.svg';

export default function catalogEdit(catalogData, treeData, secondTreeData, allProducts,handleMediaPicker, allTypes, allGroups){
    var obj = {
        titleForm: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Modifier le catalogue' : "Modifier l'entrepôt",
        subTitleForm: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Veuillez compléter les champs ci-dessous pour mettre à jour votre catalogue' : "Veuillez compléter les champs ci-dessous pour mettre à jour votre entrepôt",
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                picto: CatalogFinal,
                title: 'Et voilà !',
                subtitle: 'Vous pouvez confirmer votre modification',
                textButton: 'Modifier'
            },
            children:[
                {
                    labelName: 'Catégories',
                    isOptionnal: false,
                    optionsInputs:[
                        {
                            type: 'treeView',
                            helper: {
                                link: false,
                            },
                            required: true,
                            stateName: 'treeData',
                            stateNameQuery: 'searchQuery',
                            placeholderSearch: 'Rechercher une catégorie existante',
                            treeData : treeData,
                            typeOfTree: 'classic',
                            shouldCopy : true,
                            multipleTree: true,
                            canDrop: false,
                            canDrag: false,
                            canModify: true,
                            name: 'Catégories existantes :'
                        },
                        {
                            type: 'treeView',
                            helper: {
                                link: false,
                            },
                            required: true,
                            stateName: 'secondTreeData',
                            stateNameQuery: 'searchQuery',
                            placeholderSearch: 'Rechercher une catégorie catalogue',
                            treeData: secondTreeData,
                            typeOfTree: 'categorie',
                            shouldCopy: true,
                            multipleTree: true,
                            canDrag: false,
                            canDrop: false,
                            name: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Catégories du catalogue :' : "Catégories de l'entrepôt",
                        }
                    ]
                },
                {
                    labelName: 'Produits',
                    isOptionnal: false,
                    optionsInputs:[
                        {
                            type: 'treeView',
                            helper: {
                                link: false,
                            },
                            required: true,
                            stateName: 'secondTreeData',
                            treeData : secondTreeData,
                            typeOfTree : 'categorieFilter',
                            shouldCopy : false,
                            multipleTree: true,
                            canDrag: false,
                            canDrop: false
                        },
                        {
                            type: 'checkboxItem',
                            helper: {
                                link: false,
                            },
                            required: true,
                            stateName: 'allProducts',
                            allProducts: allProducts,
                            multipleTree: true,
                            explorerManager : true,
                        },
                    ]
                },
            ]
        }
    } 
    return obj;
}