import React from 'react';
import { withApollo } from 'react-apollo';
import { connect } from "react-redux";
import { Typography, Grid, Drawer, Box, InputLabel } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import FormBuilder from './FormBuilder';
import TopPanel from '../../layouts/TopPanel/TopPanel';

import { TABS, STEPPER, FORM, CARD, COMPONENT,MEDIAPICKER} from '../../../js/constants/form-types';
import StepperFormBuilder from './typeForm/StepperFormBuilder';
import TabsFormBuilder from './typeForm/TabsFormBuilder';
import CardDrawerBuilder from './typeForm/CardDrawerBuilder';
import Button from '../../ui/button/Button';
import InputBuilder from './InputBuilder';
import Picto from '../../../assets/pictos/bg-ampoule.svg';
import imgNotFound from '../../../assets/images/not-found.png';

const SwippeableCustom = styled(Drawer)`
    width: 50%;
    left: 50%!important;
    z-index: 100 !important;
    &> .MuiBackdrop-root{
        display: none;
    }
    &> .MuiDrawer-paper{
        width: 50%;
        padding: 32px;
        box-shadow: 0px 0px 10px #1622421A;
        padding-top: 112px;
        margin-top: 0;
        @media screen and (max-width: 1280px){
            padding-left: 18px;
            padding-right: 18px;
        }
        @media screen and (max-width: 1000px){
            padding-top: 80px;
            width: 80%;
        }
    }
`;
const DrawerCustom = styled(Drawer)`
    ${props => {
        let width = props.drawerwidth ? props.drawerwidth / 2 : 300 / 2;
        return `
            width: calc(50% - ${width}px);
            left: unset !important;
            right: 0 !important;
        `;
    }}
    z-index: 100 !important;
    &> .MuiBackdrop-root{
        display: none;
    }
    &> .MuiDrawer-paper{
        width: ${props => props.drawerwidth ? `calc(50% - ${props.drawerwidth}px / 2)` : 'calc(50% - 300px / 2)'};
        @media screen and (max-width: 1500px){
            width: ${props => props.drawerwidth ? `calc(60% - ${props.drawerwidth}px / 2)` : 'calc(60% - 300px / 2)'};
            max-width: calc(100% - 300px);
        }
        @media screen and (max-width: 1400px){
            max-width: calc(100% - 200px);
        }
        @media screen and (max-width: 1000px){
            width: ${props => props.drawerwidth ? `calc(100% - ${props.drawerwidth}px)` : 'calc(100% - 200px )'};
            max-width: 100%;
        }
        @media screen and (max-width: 1280px){
            padding-left: 18px;
            padding-right: 18px;
        }
        @media screen and (max-width: 1000px){
            // padding-left: 18px;
            // padding-right: 18px;
            padding-top: 80px;
        }
        padding: 50px;
        padding: 32px;
        box-shadow: 0px 0px 10px #1622421A;
        padding-top: ${props => props.paddingtop ? '20px' : '112px'};
        margin-top: 0; // 80px to get as before
    }
`;
const BoxClose = styled(Box)`
    width: 80px;
    padding-bottom: 0;
    &:hover{
        text-decoration: underline;
    }
`;
const InputLabelCustom = styled(InputLabel)`
    color: ${colors.blue.regular};
    margin-top: ${props => props.margintop || 0};
    margin-bottom: 16px;
    @media screen and (max-width: 1450px){
        font-size: 14px;
        line-height: 18px;
    }
    @media screen and (max-width: 1280px){
        font-size: 13px;
        line-height: 17px;
    }
    @media screen and (max-width: 960px){
        font-size: 12px;
        line-height: 16px;
    }
`;
const RequiredMessage = styled(Typography)`
    color: ${colors.grey.regular};
    font-weight: lighter;
    font-style: italic;
`;


class LayoutBuilder extends React.Component{  
    constructor(props){
        super(props)
        this.state = {
            tabState: '',
            langState: '',
            image: '',
        }
    }
    
    handleChange = (event, value) => {
        this.setState({ tabState: value });
    };
    
    componentDidMount(){
        this.setState({
            tabState: 0,
            langState: this.props.currentLang,
        });

        if(this.props.image){
            const imagesFolder = require.context('../../../assets/images', true);

            try {
                let img = imagesFolder('./' + this.props.image);
                this.setState({ image: typeof img === "string" ? img : img?.default });
            } catch(e) {
                this.setState({ image: imgNotFound });
            }                        
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.image !== prevProps.image){
            if(this.props.image){
                const imagesFolder = require.context('../../../assets/images', true);
                try {
                    let img = imagesFolder('./' + this.props.image);
                    this.setState({ image: typeof img === "string" ? img : img?.default });
                }catch(e){
                    this.setState({ image: imgNotFound });   
                }                        
            }   
        }
    }

    typeNavForm(
        navFormConfig, 
        stateCallback, 
        errorCallback,
        handleButtonGroupChange, 
        handlerSetup, 
        hideInput, 
        allState, 
        dataCard, 
        currentLang, 
        stepperButtonAction, 
        handlerMutation, 
        backStepperButtonAction, 
        stepperButtonDisabled,
        handleTreeChange, 
        expandNodes, 
        component, 
        treeEditCat,
        copyCategories,
        treeAddCat,
        handleChangeCategorie,
        resetReloadListing,
        selectedCategorie,
        productByCategorie,
        selectedProductByCategorie,
        setSelectedProducts,
        allCategories,
        catalogDatas,
        paginationInfo,
        searchHandler,
        isBuilder,
    ) {
        switch(navFormConfig.type) {
            case TABS:
                return (
                    <TabsFormBuilder 
                        navFormConfig={navFormConfig} 
                        stateCallback={stateCallback} 
                        errorCallback={errorCallback}
                        allState={allState} 
                        handleButtonGroupChange={handleButtonGroupChange ? handleButtonGroupChange : null}
                        catalogDatas={catalogDatas ? catalogDatas : null}
                        paginationInfo={paginationInfo}
                        searchHandler={searchHandler}
                    />
                );
            case STEPPER:
                return (
                    <StepperFormBuilder 
                        setSelectedProducts={setSelectedProducts}
                        allCategories={allCategories}
                        styleInnerContainer={this.props.styleInnerContainer}
                        backgroundColor={this.props.backgroundColor}
                        deleteMutation={this.props.deleteMutation} 
                        forClose={this.props.forClose} 
                        deleteText={this.props.deleteText} 
                        navFormConfig={navFormConfig} 
                        stateCallback={stateCallback} 
                        errorCallback={errorCallback}
                        allState={allState} 
                        stepperButtonAction={stepperButtonAction} 
                        backStepperButtonAction={backStepperButtonAction} 
                        handlerMutation={handlerMutation} 
                        handleButtonGroupChange={handleButtonGroupChange ? handleButtonGroupChange : null} 
                        handleTreeChange={handleTreeChange}
                        expandNodes={expandNodes}
                        treeEditCat = {treeEditCat}
                        copyCategories= {copyCategories}
                        treeAddCat = {treeAddCat}
                        component={component}
                        handleChangeCategorie={handleChangeCategorie}
                        resetReloadListing={resetReloadListing}
                        selectedCategorie={selectedCategorie}
                        productByCategorie={productByCategorie}
                        selectedProductByCategorie={selectedProductByCategorie}
                        stepperButtonDisabled={stepperButtonDisabled}
                        stepperSkip={this.props.stepperSkip}
                        gridItems={this.props.gridItems}
                        loadGrid={this.props.loadGrid}
                        addProducts={this.props.addProducts}
                        hideInput={hideInput !== null ? hideInput : null}
                        catalogDatas= {catalogDatas ? catalogDatas : null}
                        paginationInfo={paginationInfo}
                        searchHandler={searchHandler}
                        windowWidth={this.props.windowWidth}
                    />
                ); 
            case FORM:
                return (
                    <div>
                        {navFormConfig.children.map((child, index) => (
                            <div value={this.state.tabState} hidden={this.state.tabState !== index} key={`FormBuilder${index}`}>
                                <FormBuilder 
                                    setSelectedProducts={setSelectedProducts}
                                    optionsInputs={child.optionsInputs} 
                                    stateCallback={stateCallback}
                                    errorCallback={errorCallback} 
                                    handleButtonGroupChange={handleButtonGroupChange ? handleButtonGroupChange : null} 
                                    hideInput={hideInput !== null ? hideInput : null}
                                    allState={allState}
                                    catalogDatas={catalogDatas ? catalogDatas : null}
                                    paginationInfo={paginationInfo}
                                    searchHandler={searchHandler}
                                    gridItems={this.props.gridItems}
                                    loadGrid={this.props.loadGrid}
                                    selectedCategorie={selectedCategorie}
                                    productByCategorie={productByCategorie}
                                    selectedProductByCategorie={selectedProductByCategorie}
                                    resetReloadListing={resetReloadListing}
                                />
                            </div>
                        ))}
                    </div>
                );
            case CARD:
                return (
                    <CardDrawerBuilder  
                        navFormConfig={navFormConfig} 
                        stateCallback={stateCallback} 
                        errorCallback={errorCallback} 
                        handlerSetup={handlerSetup} 
                        dataCard={dataCard} 
                        handleButtonGroupChange={handleButtonGroupChange ? handleButtonGroupChange : null} 
                        catalogDatas={catalogDatas ? catalogDatas : null}
                        paginationInfo={paginationInfo}
                        searchHandler={searchHandler}
                    />
                );
            case MEDIAPICKER :
                return(
                    <navFormConfig.component onSelect={navFormConfig.onSelect} xs={navFormConfig.xs} stateName={navFormConfig.stateName} handleOpenMediaPicker={navFormConfig.handleOpenMediaPicker} translated={navFormConfig.translated} isBuilder={isBuilder} allowedTypes={navFormConfig.allowedTypes} />
                );

            case COMPONENT :
                return(
                    <navFormConfig.component allState={allState} allProps={this.props} stateCallback={stateCallback}/>
                );
            default:
                return null;
        }
    }

    topLayoutForm(props){
        const selectLang = {
            type: 'select',
            label: 'Langue',
            helper: 'Langue',
            required: false,
            stateName: 'langState',
            value: 
                props.locales.map((locale) => {
                    return({ value: locale.node.code, label: locale.node.libelle})
                })
        };
        let selectCollection = {}
        if (props.listCollections && props.listCollections.length > 0){
            selectCollection = {
                type: 'select',
                label: 'Collection',
                helper: 'Collection',
                required: false,
                stateName: 'currentCollection',
                value: 
                    [].concat({
                        node: {
                            id: '-1',
                            identifier: 'Aucune collection'
                        }
                    }).concat(props.listCollections).map((collection) => {
                        return({ value: collection.node.id, label: collection.node.identifier})
                    })
            };
        }
        return(
            <Grid container direction="column" justify="center" spacing={0}>
                { 
                    props.dataLayout.titleForm ? (
                        props.icomoon ? 
                            (<TopPanel windowWidth={props.windowWidth} icomoon={props.icomoon} colorIcomoon={props.keepColorIcomoon ? 'none' : colors.blue.lighter.hue300} title={props.dataLayout.titleForm} subtitle={props.dataLayout.subTitleForm} gradientColor1={props.gradientColor1} gradientColor2={props.gradientColor2} />) 
                        :   (<TopPanel windowWidth={props.windowWidth} picto={props.picto ? props.picto : props.image ? (this.state.image ? this.state.image : imgNotFound) : Picto} title={props.dataLayout.titleForm} subtitle={props.dataLayout.subTitleForm} />) 
                    ) : null
                }
                
                {
                    props.dataLayout.langSelect ? (
                        <Grid container direction="column" justify="center" spacing={0}>
                            <Grid container direction="row" spacing={1} alignItems="center">
                                <Grid item xs={2}>
                                    <InputLabelCustom>Langue</InputLabelCustom>
                                </Grid>
                                <Grid item xs={4}>
                                    <InputBuilder value={this.props.currentLang} input={selectLang} stateCallback={this.props.handleLang} /> 
                                </Grid>
                                {
                                    props.dataLayout.collectionSelect ? 
                                        <>
                                            <Grid item xs={2}>
                                                <InputLabelCustom>Collection</InputLabelCustom>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <InputBuilder value={props.currentCollection} input={selectCollection} stateCallback={this.props.handleChangeCollection} /> 
                                            </Grid>
                                        </>
                                    : null
                                }
                            </Grid>
                        </Grid>
                    ) : null 
                }
                
                {
                    this.typeNavForm(
                        props.dataLayout.formConfig,
                        props.stateCallback, 
                        props.errorCallback,
                        props.handleButtonGroupChange, 
                        props.handlerSetup, 
                        props.hideInput, 
                        props.allState, 
                        props.dataCard, 
                        props.currentLang, 
                        props.stepperButtonAction, 
                        props.handlerMutation, 
                        props.backStepperButtonAction, 
                        props.stepperButtonDisabled, 
                        props.handleTreeChange, 
                        props.expandNodes,
                        props.component,
                        props.treeEditCat,
                        props.copyCategories,
                        props.treeAddCat,
                        props.handleChangeCategorie,
                        props.resetReloadListing,
                        props.selectedCategorie,
                        props.productByCategorie,
                        props.selectedProductByCategorie,
                        props.setSelectedProducts,
                        props.allCategories,
                        props.catalogDatas,
                        props.paginationInfo,
                        props.searchHandler,
                        props.isBuilder,
                        props.allowedTypes,
                    )
                }
            </Grid>
        )
    }

    renderSwitch(props) {
        switch(props.dataLayout.drawerType) {
            case 'swipeable':
                return (
                    <SwippeableCustom anchor="right" open={props.opened}>
                        { props.noCancel ? null : 
                            <Typography variant="h5" onClick={() =>{ 
                                props.forClose();
                                if (props.handleCancel)
                                    props.handleCancel();
                            }} component="div">
                                <BoxClose pb={2} style={{cursor: 'pointer'}}>
                                    X Annuler
                                </BoxClose>
                            </Typography>
                        }
                        {this.topLayoutForm(props)}
                        {props.validateButton ? 
                            <Grid container direction="row" justify="flex-end">
                                {props.deleteMutation ? 
                                    <Box pt={3} mr={2}>
                                        <Button 
                                            onClick={props.deleteMutation} 
                                            forClose={props.forClose} 
                                            allState={props.allState} 
                                            text={props.deleteText ? props.deleteText : null} 
                                            bgcolor={'transparent'} 
                                            bgcolorhover={'transparent'}  
                                            color={colors.red.regular} 
                                            deleteIcon={true} 
                                            textTransform={'underline'} 
                                            fontsize={14}
                                        />
                                    </Box> 
                                    : 
                                    null
                                }
                                {
                                    props.dataLayout.formConfig.type !== STEPPER ? 
                                    (
                                        props.handlerMutation ?
                                        (
                                            <Box pt={3}>
                                                <Button onClick={props.handlerMutation} forClose={props.forClose} allState={props.allState} text={props.dataLayout.textButtonValidate ? props.dataLayout.textButtonValidate : 'Valider la modification'} bgcolor={colors.green.regular} shadowcolor={colors.green.darker}>
                                                </Button>
                                            </Box>
                                        )
                                        :
                                        (
                                            <Box pt={3}>
                                                <Button onClick={props.forClose} forClose={props.forClose} allState={props.allState} text={props.dataLayout.textButtonValidate ? props.dataLayout.textButtonValidate : 'Valider la modification'} bgcolor={colors.green.regular} shadowcolor={colors.green.darker}>
                                                </Button>
                                            </Box>
                                        )   
                                    ) : ''
                                }
                                <Grid container direction="row" justify="flex-start">
                                    <Box pt={2} pb={3}>
                                        <RequiredMessage variant="h4">*Champs obligatoires</RequiredMessage>
                                    </Box> 
                                </Grid>
                            </Grid>
                        : null }
                    </ SwippeableCustom>
                );
            case 'drawer':
                return (
                    <DrawerCustom anchor="right" open={props.opened} drawerwidth={props.drawerWidth ? props.drawerWidth : null} paddingtop={props.isBuilder}>
                        { props.noCancel ? null :
                            <Typography variant="h5" onClick={() =>{ 
                                props.forClose();
                                if (props.handleCancel)
                                    props.handleCancel();
                            }} component="div">
                                <BoxClose pb={2} style={{cursor: 'pointer'}}>
                                    X Annuler
                                </BoxClose>
                            </Typography>
                        }
                        {this.topLayoutForm(props)}
                        { props.validateButton ? 
                            <Grid container direction="row" justify="flex-end">
                                { props.deleteMutation ? 
                                    <Box pt={3} mr={2}>
                                        <Button 
                                            onClick={props.deleteMutation} 
                                            forClose={props.forClose} 
                                            allState={props.allState} 
                                            text={props.deleteText ? props.deleteText : null} 
                                            bgcolor={'transparent'} 
                                            bgcolorhover={'transparent'}  
                                            color={colors.red.regular} 
                                            deleteIcon={true} 
                                            textTransform={'underline'} 
                                            fontsize={14}
                                        />
                                    </Box> 
                                    : 
                                    null
                                }
                                {
                                    props.dataLayout.formConfig.type !== STEPPER ? 
                                        (
                                            props.handlerMutation ?
                                                (<Box pt={3}>
                                                    <Button onClick={props.handlerMutation} forClose={props.forClose} allState={props.allState} text={props.dataLayout.textButtonValidate ? props.dataLayout.textButtonValidate : 'Valider la modification'} bgcolor={colors.green.regular} shadowcolor={colors.green.darker} />
                                                </Box>)
                                            :
                                                (<Box pt={3}>
                                                    <Button onClick={props.forClose} forClose={props.forClose} allState={props.allState} text={props.dataLayout.textButtonValidate ? props.dataLayout.textButtonValidate : 'Valider la modification'} bgcolor={colors.green.regular} shadowcolor={colors.green.darker} />
                                                </Box>)
                                        ) 
                                    :   ''
                                }
                                <Grid container direction="row" justify="flex-start">
                                    <Box pt={2} pb={3}>
                                        <RequiredMessage variant="h4">*Champs obligatoires</RequiredMessage>
                                    </Box> 
                                </Grid>
                            </Grid>
                        : null }
                    </DrawerCustom>
                ); 
            case 'none': 
                return (
                    <div>
                        <Typography variant="h5" onClick={() =>{ 
                        props.forClose();
                        if (props.handleCancel)
                            props.handleCancel();
                        }} component="div">
                        </Typography>
                        {this.topLayoutForm(props)}
                        <Grid container direction="row" justify="flex-end">
                            {
                                props.dataLayout.formConfig.type !== STEPPER ? 
                                (
                                    props.handlerMutation ?
                                    (
                                        <Box pt={3}>
                                            <Button onClick={props.handlerMutation} forClose={props.forClose} allState={props.allState} text={props.dataLayout.textButtonValidate ? props.dataLayout.textButtonValidate : 'Valider la modification'} bgcolor={colors.green.regular} shadowcolor={colors.green.darker}>
                                            </Button>
                                        </Box>
                                    )
                                    :
                                    (
                                        <Box pt={3}>
                                            <Button onClick={props.forClose} forClose={props.forClose} allState={props.allState} text={props.dataLayout.textButtonValidate ? props.dataLayout.textButtonValidate : 'Valider la modification'} bgcolor={colors.green.regular} shadowcolor={colors.green.darker}>
                                            </Button>
                                        </Box>
                                    )
                                ) : ''
                            }
                        </Grid>
                    </div>
                );
            default:
                return null;
        }
    }
    
    render() {
        return (
            <div>
                {this.renderSwitch(this.props)}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};
export default withApollo(connect(mapStateToProps, null)(LayoutBuilder));