import {getTraductionAttributs} from '../utils/functions';
import { ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS } from '../constants/route-names';
import moment from 'moment';

export const flowListMapper = (flows,lang) =>{
    console.log(flows)
    return new Promise((resolve,reject)=>{
        let results = flows.map((flow,index)=>{
            return {
                sku: flow.node.product.sku,
                price: flow.node.price,
                specialPrice: flow.node.specialPrice ? flow.node.specialPrice : '-',
                specialPriceFrom: flow.node.specialPriceFrom ? moment(flow.node.specialPriceFrom).format('DD/MM/YYYY') : '-',
                specialPriceTo: flow.node.specialPriceTo ? moment(flow.node.specialPriceTo).format('DD/MM/YYYY') : '-',
                codeTaxe: flow.node.rateGroup.code,
                codeDevise: flow.node.currency.code,
                selectionIdentifier: flow.node.selection.identifier,
                updatedAt: moment(flow.node.updatedAt).format('DD/MM/YYYY à HH:MM'),
            }
        })
        resolve(results);
    })
}