import TableCell from '@material-ui/core/TableCell';

function CellText(props){
    return(
        <TableCell 
            style={{
                width : props.width ? props.width :'auto',
                fontWeight : props.fontWeight ? props.fontWeight : 'normal',
                padding : '4px 16px'
            }}
        >
            {props.value}
        </TableCell>
    )
}
export default CellText;