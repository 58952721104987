export const handleSort = (sortBy,newSort) =>{
    let newSortBy = sortBy;
    return new Promise((resolve,reject)=>{
        let foundFilter = sortBy.find(e=>e.filter == newSort.filter);
        if(foundFilter){
            let index = sortBy.findIndex(e=>e.filter == newSort.filter);
            if(newSort.orderBy != null){
                newSortBy[index] = newSort;
            } else {
                newSortBy.splice(index,1)
            }

        } else {
            newSortBy.push(newSort);
        }
        resolve(newSortBy);
    })
}