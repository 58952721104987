import store from '../redux/store';
import {client} from '../../index'
import * as moment from 'moment';
import { START_LOADING, STOP_LOADING, SNACK, SET_LOCALES, SET_ATTRIBUTES, SET_GUIDELINE } from '../constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../constants/alert-types';
import {addAttributesQueries,addQueries,updateQueries,updateAttributesQueries,getQueries} from '../constants/queries-type';
import {GET_EAV_TYPES, GET_ATTRIBUTES_BY_TYPE} from '../../queries/attributes';
import {GET_LOCALES} from '../../queries/locales';
import {GET_BRAND_GUIDELINE} from '../../queries/brand_guideline';



const props = store.getState();
const startLoading = () => store.dispatch({ type: START_LOADING });
const stopLoading = () => store.dispatch({ type: STOP_LOADING });
const snack = () => store.dispatch({ type: SNACK }) ;
const setLocales = (locales) => store.dispatch({ type: SET_LOCALES, payload: { locales } });
const setGuideline = (guideline) => store.dispatch({ type: SET_GUIDELINE, payload: { guideline } });
const setAttributes = (attributes) => store.dispatch({ type: SET_ATTRIBUTES, payload: { attributes } });

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function isset(el){
    let isSet= typeof el !== 'undefined';
    return isSet;
}
  
export function getTraductionAttributs(attributeIdentifier, data, lang, type) {
    if (!data?.length) return null;

    let nameAttributes  = data.filter(e => e.node.attribute.identifier === attributeIdentifier);
    let getTraduction   = nameAttributes.find(e => e.node.locale.code === lang);
    let getOptionTrad   = null
    if(type === 'select'){
        getOptionTrad = getTraduction?.node.attributeOption.translation.translationDatas.edges.find(e => e.node.locale.code === lang)
    }
    return( 
        type !== 'image'
            ? type !== 'select'
                ? type != 'file' 
                    ? getTraduction?.node.value ?? nameAttributes[0]?.node.value
                    : getTraduction?.node.media ?? nameAttributes[0]?.node.media
            : getOptionTrad?.node.value ?? getTraduction?.node.attributeOption.translation.translationDatas.edges[0]?.node.value
        : getTraduction?.node.media ?? nameAttributes[0]?.node.media
    )
};

export function prepareAttributes(type){
    return new Promise(async (resolve, reject) => {
        let attributes = props.attributes[type].attributes.edges;
        let isSystemAttributes  = attributes.filter(e => e.node.isSystem);
        let customAttributes = attributes;
        resolve({
            'isSystemAttributes' : isSystemAttributes,
            'customAttributes' : customAttributes
        })

    })
}

export const prepareAttributeValues = (type,isEdit = false,current=null) => {
    return new Promise(async (resolve, reject) => {
        let {customAttributes,isSystemAttributes} = await prepareAttributes(type);
        let dataArray = type+'Datas';
        let langArray = {};
        for (let locale of props.locales) {
            let values = {};

            for (let attribute of customAttributes) {
                if (attribute.node.attributeType.input === 'select') {
                    if (attribute.node.attributeOptions.edges.length){
                        values[attribute.node.identifier] = attribute.node.attributeOptions.edges[0].node.id
                    }
                }
                if(isEdit && current){
                    let translated = getTraductionAttributs(attribute.node.identifier,current[dataArray].edges,locale.node.code,attribute.node.attributeType.input)
                    if(translated){
                        values[attribute.node.identifier]=translated;
                    }
                }
            }
            langArray[locale.node.code]={...values};
        }
        let result = {
            'customAttributes' : customAttributes,
            'isSystemAttributes':isSystemAttributes,
            ...langArray,
        }
        resolve(result)
    })
}

export const saveAttributes = (states,identifier,id,options,action='create',currentData=null) =>{
    let query = action == 'create' ? addAttributesQueries[identifier] : updateAttributesQueries[identifier];
    return new Promise(async (resolve, reject) => {
        let attributes = states.isSystemAttributes;
        let itemDatas = [];
        for (let attribute of attributes) {
            for (let locale of props.locales) { 
                let formValue   = states[locale.node.code][attribute.node.identifier];
                let isMedia     = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';

                if (formValue) {

                    let variables = {
                        "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                        "attribute": attribute.node.id,
                        "locale": locale.node.id
                    };
                    variables[identifier] = id;


                    if (isMedia){
                        variables.media = formValue.id;
                    }
                        
                    
                    if (!isMedia)
                        if (attribute.node.attributeType.input !== 'select')
                                variables.value = formValue;
                        
                    if(action=='edit' && currentData){
                        let translation = currentData.find((e)=>{
                            if(e.node.attribute.id === attribute.node.id && e.node.locale.id === locale.node.id){
                                return true;
                            }else{
                                return false
                            }
                        });
                        if(translation){
                            variables.id = translation.node.id;
                        }
                    }

                    let resultMutation = await client.mutate({
                        mutation: query,
                        variables
                    })

                }
            }
        }
        resolve();
    });
}

/**
 * Enregistre un élèment en gql
 * @param {Object} states - States du composant
 * @param {string} identifier - Type d'élèment à sauvegarder
 * @param {Object} variables - Variables pour la sauvegarde
 * @param {Object} options - Options supplémentaires : {enableLoad : bool , setAttributes : bool , setDates : bool ,states : {Object}} (optionnel)
 * @returns {Object} - Element ajouté
 */
export function saveElement(identifier,variables,options){
    return new Promise(async(resolve,reject)=>{

        let setDates = isset(options.setDates) ? options.setDates : true ;
        let setAttributes = isset(options.setAttributes) ? options.setAttributes : false ;
        let enableLoad = isset(options.enableLoad) ? options.enableLoad : true;


        if(enableLoad)
            startLoading();

        let mutation = addQueries[identifier];

        if(setDates){
            variables['createdAt'] = moment().format('YYYY-MM-DD');
            variables['updatedAt'] = moment().format('YYYY-MM-DD');        
        }

        const ADD_ITEM = await client.mutate({
            mutation : mutation,
            variables : variables,
            fetchPolicy: 'no-cache'

        })

        let newItem = ADD_ITEM.data['create'+capitalize(identifier)][identifier];
        
        if(setAttributes){
            await saveAttributes(options.states,identifier,newItem.id);

        }

        if(enableLoad)
            stopLoading();
        
        if(options.success)
            snack(ALERT_SUCCESS, options.success);

        resolve(newItem);
    })
}

/**
 * 
 * @param {Object} states - States du composant
 * @param {string} identifier - Type d'élèment à sauvegarder
 * @param {Object} variables - Variables pour la sauvegarde
 * @param {Object} currentDatas - Datas de l'élèment à mettre à jour (optionnel)
 * @param {Object} options - Options supplémentaires : {enableLoad : bool,setAttributes : bool, setDates : bool} (optionnel)
 * @returns {Object} - Element mis à jour
 */
export function updateElement(states,identifier,variables,currentDatas,options){
    return new Promise(async(resolve,reject)=>{

        let setDates = isset(options.setDates) ? options.setDates : true ;
        let setAttributes = isset(options.setAttributes) ? options.setAttributes : false ;
        let enableLoad = isset(options.enableLoad) ? options.enableLoad : true;

        if(enableLoad)
            startLoading();
        let mutation = updateQueries[identifier];

        if(setDates){
            variables['updatedAt'] = moment().format('YYYY-MM-DD');        
        }
        const UPDATE_ITEM = await client.mutate({
            mutation : mutation,
            variables : variables,
            fetchPolicy: 'no-cache'

        })
        let updatedItem = UPDATE_ITEM.data['update'+capitalize(identifier)][identifier];
        if(setAttributes){
            await saveAttributes(states,identifier,updatedItem.id,options,'edit',currentDatas);
        }

        if(enableLoad)
            stopLoading();
        
        if(options.success)
            snack(ALERT_SUCCESS, options.success);

        resolve(updatedItem);
    })
}


/**
 * Récupère des élèments
 * @param {string} identifier - Type d'élèments à récupérer
 * @param {Object} variables - Variables pour la récupération (optionnel)
 * @param {Object} options - Options supplémentaires : {enableLoad : bool} (optionnel)
 * @returns 
 */
export function getElements(identifier,variables,options){
    let query = getQueries[identifier];

    return new Promise(async (resolve,reject)=>{
        let enableLoad = isset(options?.enableLoad) ? options?.enableLoad : false;

        if(enableLoad)
            startLoading();

        let GET_ELEMENTS = await client.query({
            query: query,
            variables: variables,
            fetchPolicy: 'no-cache'
        })

        if(enableLoad)
            stopLoading();

        resolve(GET_ELEMENTS)
    })
}

export function setRedux(isCRM, eavTypes, locales, guidelines){
    return new Promise(async (resolve,reject)=>{
        let categoryTypes = [];
        if (eavTypes){
            let dataTypes = await client.query({ query: GET_EAV_TYPES, fetchPolicy: 'no-cache' });
            let typesToFetch = ['product', 'category', 'content'];
            if (isCRM)
                typesToFetch.push('company', 'customer')

            for (let type of dataTypes.data.eavTypes.edges) {
                if (~typesToFetch.indexOf(type.node.code)) {
                    let typeData = await client.query({ query: GET_ATTRIBUTES_BY_TYPE, variables: { id: type.node.id }, fetchPolicy: 'no-cache' });
                    categoryTypes.push(typeData.data.eavType);
                }
            }

            let attributes = {
                eavTypes: dataTypes.data.eavTypes.edges,
                category: categoryTypes.find(e => e.code === 'category'),
                product: categoryTypes.find(e => e.code === 'product'),
                content: categoryTypes.find(e => e.code === 'content'),
                company: categoryTypes.find(e => e.code === 'company'),
                customer: categoryTypes.find(e => e.code === 'customer'),
            };
            setAttributes(attributes);  
            localStorage.setItem('ATTRIBUTES', JSON.stringify(attributes)); 
        }

        if (locales){
            let dataLocales = await client.query({ query: GET_LOCALES, fetchPolicy: 'no-cache' });
            setLocales(dataLocales.data.locales.edges);
            localStorage.setItem('LOCALES', JSON.stringify(dataLocales.data.locales.edges));
        }

        if (guidelines){
            let dataGuideline = await client.query({ query: GET_BRAND_GUIDELINE, fetchPolicy: 'no-cache' });
            if (dataGuideline.data.guidelines.edges.length) {
                setGuideline(dataGuideline.data.guidelines.edges[0].node);
                localStorage.setItem('GUIDELINE', JSON.stringify(dataGuideline.data.guidelines.edges[0].node));
            }
        }

        // let dataUsers = await client.query({ query: GET_USERS, fetchPolicy: 'no-cache' });

        // for (let user of dataUsers.data.users.edges) {
        //   if (user.node.email === this.state.email) {
        //     let getUser = user.node;
        //     let idUserRole = user.node.userRole.id.replace('/api/user-roles/', '')
        //     request(`${process.env.REACT_APP_API}/users/acl/${idUserRole}`, 'get', false).then(
        //       (data) => {
        //         if (data.success) {
        //           getUser.rights = data.acl
        //         }
        //         localStorage.setItem('AUTH_USER', JSON.stringify(getUser));
        //         this.props.setUser(getUser);
        //       }
        //     )
        //   }
        // }
        
        resolve()
    })
}