import { EditableTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultTextCms, getDefaultLinkCms, getDefaultTextareaCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bloc de texte et media',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
        positionMedia: {
            label: 'Media avant le bloc texte',
            type: FormInputTypes.BUTTON_GROUP,
            params:[
                {value: 'right', label: 'À droite'},
                {value: 'left', label: 'À gauche'},
                {value: 'bottom', label: 'En bas'},
                {value: 'top', label: 'En haut'},
            ],
            value: 'right'
        },
        showMedia: {
            label: 'Afficher le media',
            type: FormInputTypes.SWITCH,
            value: true,
        },
        centerText: {
            label: 'Centrer les textes',
            type: FormInputTypes.SWITCH,
            value: false,
        },
        centerButton: {
            label: 'Centrer les boutons',
            type: FormInputTypes.SWITCH,
            value: true,
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Le Coq Sportif", '#000'),
        subtitle: getDefaultTextCms("Sous-titre", "La marque de sport référence des français depuis 1882 …", '#000'),
        text: getDefaultTextareaCms("Texte", "Une proximité émotionnelle avec les sportifs, champions ou amateurs passionnés, et avec leurs supporters. L’amour du sport, le challenge de ses épopées et surtout le partage collectif des émotions qu’il génère, guident la marque depuis son origine.", true, '#000'),
        link: getDefaultLinkCms("Lien", "Information", "https://www.lecoqsportif.com/fr-fr/", '#000', '#FFF', true),
        link2: getDefaultLinkCms("Lien 2", "Information", "https://www.lecoqsportif.com/fr-fr/", '#000', '#FFF', true),
        media: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/1040x640"
                }
            }
        },
    }
};