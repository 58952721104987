import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Tooltip, TextField, Typography} from '@material-ui/core';
import request from '../../../js/utils/fetch';
import CardCustom from '../../layouts/Card/CardCustom';
import { GET_CATEGORIES_LIGHT } from '../../../queries/categories';
import { withApollo } from 'react-apollo';
import colors from '../../../config/theme/colors';
import PageLoader from '../loadings/page-loader/PageLoader';
import { CONTENTS_MODELS } from '../../../js/constants/constant-rights';

const CategorieName = styled(Typography)`
    cursor: pointer;
    &:hover{
        color: ${colors.blue.lighter.hue300};
    }
`
const BackCategorie = styled(Typography)`
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

class TreeCategories extends Component {

    constructor(props){
        super(props);
        this.state = {
            categories: null,
            ready: false,
            reload: props.reload || null,
        }
    }

    processChildren = async (categories, parent, allIdentifiers) => {
        parent.children = categories.filter(e => e.parent?.id === parent?.id);
        for (let child of parent.children) {
            await this.processChildren(categories, child);
        }
    };

    handleGetCategories() {
        this.props.client.query({
            query: this.props.query,
            variables: this.props.variablesQuery,
            fetchPolicy: "no-cache"
        }).then((result) => {
            let allCategories = result.data[this.props.resultVar];
            let root = this.props.isCatalog ? allCategories[0] : Array.isArray(allCategories) ? allCategories?.find(e => e.parent === null) : null;
            if (allCategories.length > 0 ){
                this.processChildren(allCategories, root);
            }
            this.setState({
                categories : allCategories,
                activeCategorie : root,
                ready : true,
                root,
            })
        })
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.reload !== prevProps.reload){
            this.handleGetCategories();
        }
    }

    async componentDidMount(){
        await this.handleGetCategories()
    }

    handleChangeCat(e, newCat, parentCat){
        let newCategorie = null
        if (parentCat){
            newCategorie = this.state.categories?.find(cat => cat.id === parentCat?.parent?.id)
        }else {
            newCategorie = newCat
        }
        this.setState({
            lastCategorie : this.state.activeCategorie,
            activeCategorie : newCategorie
        });
        if(this.props.resultVar === 'assetCategories'){
            if(e){
                this.props.callbackCategorie(e, newCategorie.id !== this.state.root?.id ? newCategorie.id.replace('/api/asset-categories/', '') : !this.state.root ? newCategorie.id : null, newCategorie.id, newCategorie)
            }else{
                this.setState({activeCategorie: null})
                this.props.callbackCategorie(null, null, null)
            }
        }else{
            this.props.callbackCategorie(e, newCategorie.id !== this.state.root.id ? newCategorie.id.replace('/api/categories/', '').replace('/api/content-categories/', '') : null)
        } 
    }

    render() {
        let categoryType = [];
        switch(this.props.typeCategory){
            case "product":
                categoryType = "categoryDatas";
                break;
            case "content":
                categoryType = "contentCategoryDatas";
                break;
        }
        
        if(this.state.categories){
            let getCategorie;
            let lastCatName;
            let activeCatName;
            if(this.state.activeCategorie && this.props.resultVar !== 'assetCategories'){
                let lastCat = this.state.categories?.find(cat => cat.id === this.state.activeCategorie?.parent?.id);
                getCategorie = this.state.categories?.find(cat => cat.id === this.state.activeCategorie.id);
                lastCatName = null;
                if (lastCat){
                    let filterLast = lastCat[categoryType]?.edges.filter(e => e.node.attribute.identifier === "category_name");
                    if (filterLast.length > 0){
                        if (filterLast?.find(e => e.node.locale.code === this.props.currentLang)){
                            lastCatName = filterLast?.find(e => e.node.locale.code === this.props.currentLang)?.node.value
                        }else{
                            lastCatName = lastCat.libelle
                        }
                    }else{
                        lastCatName = lastCat.libelle
                    }
                }
                else if(getCategorie){
                    lastCat = this.state.categories?.find(cat => cat.id === getCategorie?.parent?.id);
                    if(lastCat){
                        let activeCategorie = this.state.activeCategorie;
                        activeCategorie.parent = lastCat;
                        this.setState({activeCategorie});
                        let filterLast = lastCat[categoryType]?.edges.filter(e => e.node.attribute.identifier === "category_name");
                        if (filterLast.length > 0){
                            if (filterLast?.find(e => e.node.locale.code === this.props.currentLang)){
                                lastCatName = filterLast?.find(e => e.node.locale.code === this.props.currentLang)?.node.value
                            }else{
                                lastCatName = lastCat.libelle
                            }
                        }else{
                            lastCatName = lastCat.libelle
                        }
                    }
                }
                activeCatName = this.state.activeCategorie?.[categoryType]?.edges.filter(e => e.node.attribute.identifier === "category_name");
                if (activeCatName?.find(e => e.node.locale.code === this.props.currentLang)){
                    activeCatName = activeCatName?.find(e => e.node.locale.code === this.props.currentLang)?.node.value;
                }else{
                    activeCatName = this.state.activeCategorie?.libelle;
                }
            }
            else if(this.props.resultVar === 'assetCategories' && this.state.categories.edges.length > 0){
                if(!this.state.activeCategorie){
                    let temporaryCategories = this.state.categories.edges;
                    let children = [];
                    getCategorie = {};
                    for(let categorie of temporaryCategories){
                        if(categorie.node.libelle){
                            children.push(categorie.node);
                        }
                    }
                    getCategorie.children = children;
                }
                else{
                    let temporaryCategories = this.state.categories.edges;
                    let children = [];
                    getCategorie = {};
                    if(this.state.activeCategorie.assetTypes){
                        for(let categorie of temporaryCategories){
                            if(categorie.node.id === this.state.activeCategorie.id){
                                activeCatName = categorie.node.libelle;
                                for(let assetType of categorie.node.assetTypes.edges){
                                    if(assetType.node.libelle){
                                        children.push(assetType.node);
                                    }
                                }
                            }
                        }
                        getCategorie.children = children;
                    }
                    else{
                        activeCatName = this.state.activeCategorie.libelle;
                        getCategorie = null;
                    }
                }
            }

            return (
                <CardCustom style={{position: 'sticky',top: 100, maxHeight: "45vh", overflow: "auto"}} collapse={this.props.collapse}>
                    <Grid container>
                        {
                            this.state.root?.id === this.state.activeCategorie?.id ?
                                !this.props.collapse ? <Typography variant="h3" style={{marginBottom: 8}}>Catégories</Typography> : null
                            : (
                                <Grid container direction="column">
                                    <BackCategorie variant="h5" onClick={() => this.handleChangeCat(null, this.state.activeCategorie?.parent)} > 
                                        &lt; 
                                        {
                                            this.state.activeCategorie?.parent?.id === this.state.root?.id ?
                                                "Catégories" 
                                            : !this.state.root ? 
                                                this.state.activeCategorie.id
                                            : lastCatName
                                        }
                                    </BackCategorie>
                                    <Typography variant="h3" style={{marginTop: 8}}>{activeCatName}</Typography>
                                </Grid>
                            )
                        }
                        <Grid container direction="column" >
                            {
                                this.state.ready ?
                                    getCategorie?.children.length > 0 ?
                                        getCategorie?.children.map((cat, index) => {
                                            let catName = cat[categoryType]?.edges.filter(e => e.node.attribute.identifier === "category_name");
                                            catName = catName?.find(e => e.node.locale.code === this.props.currentLang)?.node.value
                                            return(
                                                <CategorieName key={`categorie-name${index}`} variant="h5" style={{width: "100%", marginTop:8}} onClick={(e) => this.handleChangeCat(e, cat)}>
                                                    {catName || cat.libelle} {cat.products ? (<span style={{color: colors.blue.lighter.hue300}}>({cat.products?.totalCount ?? cat.contents?.totalCount})</span>) : null}
                                                </CategorieName>
                                            )
                                        })
                                    : <Typography variant="h5" style={{marginTop: 8, color: colors.blue.regular}}>Pas de catégorie</Typography>
                                : <PageLoader />
                            }
                        </Grid>
                    </Grid>
                </CardCustom>
            )
        }
        else{
            return(<CardCustom><PageLoader /></CardCustom>)
        }
    }
}

export default withApollo((TreeCategories));