import React from 'react';
import {Grid, Box} from '@material-ui/core';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';
import { connect } from "react-redux";

import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';

import { GET_CATALOGS } from '../../../../queries/catalogs';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import CardByAssetType from '../../../layouts/Card/cardContent/CardByAssetType';  
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';

import request from '../../../../js/utils/fetch';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { ROUTE_DIFFUSION_PERMANENTS_ADD_POST, ROUTE_DIFFUSION_PERMANENTS_HEADERS_ASSET, ROUTE_DIFFUSION_PERMANENTS_PAGES_ASSET, ROUTE_DIFFUSION_PERMANENTS_INFOS_MANAGEMENTS_ASSET, ROUTE_DIFFUSION_PERMANENTS_SHOP_THE_LOOK } from '../../../../js/constants/route-names';
import { GET_ASSET_BY_ID } from '../../../../queries/assets';
import styled from 'styled-components';
import 'moment-timezone';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import Button from '../../../ui/button/Button';

const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

class DiffusionPermanentPosts extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            filtered: [],
            ready: true,
            postType: true,
            idAsset: `/api/assets/${this.props.match.params.id}`
        };
    }

    componentDidMount() {
        this.handleGetAssetDatas();

        this.setState({
            filtered: this.props.products
        });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    handleToggleDrawer = (stateDrawer, changeWidth) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer],
            errors: {},
        });

        if (changeWidth) {
            this.handleDrawerWidthChange(300);
        } 
        
    };

    handleGetAssetDatas = () =>{
        this.setState({
            filteredAssets: null
        });
        
        const getAssetsByType = GET_ASSET_BY_ID;

        this.props.client.query({
            query:getAssetsByType,
            variables:{id: this.state.idAsset},
            fetchPolicy:'no-cache'
        }).then(GET_ASSET_BY_ID_RESULT =>{
            let currentAsset = GET_ASSET_BY_ID_RESULT.data.asset;

            this.setState({
                assetData: currentAsset,
            }, async () => {
                if (this.state.assetData.channel && !this.state.assetData.assetType.hasPage) {
                    this.getFeed();
                }
            });
        })
    }

    render() {
        const assetData = this.state.assetData;
        let isBuilder = assetData?.assetType.identifier === 'minisite' || assetData?.assetType.identifier === 'jeu' || assetData?.assetType.identifier === 'flipbook' || assetData?.assetType.identifier === "flipbook_with_cart" || assetData?.assetType.identifier === 'newsletter' || assetData?.assetType.identifier === 'magento';
        let isFlipbook = assetData?.assetType.identifier === 'flipbook' || assetData?.assetType.identifier === "flipbook_with_cart";
        let hasPage = assetData?.assetType.hasPage;
        return (
            <div>
                {assetData ? 
                    <>
                        <TopPanel 
                            // icomoon="picto-rs"
                            image={assetData?.assetType.logo}
                            colorIcomoon={colors.blue.lighter.hue300}
                            title={isBuilder ? `Gérer les pages de votre ${isFlipbook ? 'flipbook' : assetData?.assetType.identifier}` :`Gérer la diffusion de votre feed ${assetData?.assetType.libelle}`}
                            subtitle={isBuilder ? 'Gestion des pages (création / modification / suppression)' : 'Gestion des posts (création / modification / suppression)'} 
                            handlerAdd={isBuilder ? hasPage ? () => {this.goTo(ROUTE_DIFFUSION_PERMANENTS_HEADERS_ASSET.replace(':id', assetData.id.replace('/api/assets/', '')))} : null : () => {this.goTo(ROUTE_DIFFUSION_PERMANENTS_ADD_POST.replace(':id', assetData.id.replace('/api/assets/', '')).replace(':identifier', assetData.identifier))}} 
                            textAdd={isBuilder ? hasPage ? 'Modifier les headers' : null : 'Ajouter un post'}
                            handlerImport={hasPage ? () => {this.goTo(ROUTE_DIFFUSION_PERMANENTS_PAGES_ASSET.replace(':id', assetData.id.replace('/api/assets/', '')))} : null} 
                            textImport={hasPage ? 'Modifier les pages' : null}
                            handlerThirdButton={hasPage ? () => {this.goTo(ROUTE_DIFFUSION_PERMANENTS_INFOS_MANAGEMENTS_ASSET.replace(':id', assetData.id.replace('/api/assets/', '')))} : null} 
                            textThirdButton={hasPage ? "Régie d'informations" : null}
                            // searchHandler={this.handleChange} 
                            gradientColor1={colors.menu.regular} 
                            gradientColor2={colors.menu.darker}
                            windowWidth={this.props.windowWidth}
                            // openForm={this.state.openForm}
                            buttonAvailable={this.state.ready}
                            // currentLang={this.state.currentLang} 
                            // handleLang={this.handleLang} 
                            locales={this.props.locales}
                            hasBorder={true}
                        >
                            {
                                hasPage ?
                                    <Button style={{marginLeft: '16px'}} text={"Shop the look"} bgcolor={colors.green.regular} onClick={() => {this.goTo(ROUTE_DIFFUSION_PERMANENTS_SHOP_THE_LOOK.replace(':id', assetData.id.replace('/api/assets/', '')))}} shadowcolor={colors.green.regular} disabled={false}/>
                                : null
                            }
                        </TopPanel>
                        <Grid container justify={'space-between'} alignItems='flex-start' style={{marginTop: 16}}>
                            <Box onClick={this.props.history.goBack} style={{marginBottom: 25}}>
                                <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                            </Box>
                        </Grid>
                        <CardByAssetType
                            asset       ={assetData}
                            // handleAdd   = {() => {this.handleToggleDrawer('openFormFeedAdd')}}
                            posts       ={this.state.posts ? this.state.posts : null}
                            handleDelete={this.deleteFeed}
                            isFlipbook={isFlipbook}
                        />  
                    </>
                : <PageLoader />}
            </div>
        );
    }

    getFeed(){
        return new Promise((resolve) => {
            //---- get feed
            let channelIdentifier   = this.state.assetData.channel.identifier;
            let channelType         = this.state.assetData.channel.attributeGroup.identifier;

            let pathChannelType;
            let listAction;

            if (channelType === 'facebook') {
                pathChannelType     = 'facebook-page';
                listAction          = 'posts';
            } else if (channelType === 'twitter') {
                pathChannelType     = 'twitter';
                listAction          = 'tweets';
            } else if (channelType === 'wordpress') {
                pathChannelType     = 'wordpress';
                listAction          = 'posts';
            }

            this.setState({
                requesting: true
                }, () => {
                request(`${process.env.REACT_APP_API}/assets/${pathChannelType}/${channelIdentifier}/${listAction}`, 'get', null, 'application/json', true)
                .then(async (data) => {
                    //--- parse response to get posts
                    if (channelType === 'facebook') {
                        this.setState({posts:data.posts});
                    } else if (channelType === 'twitter') {
                        this.setState({posts:data[0]});
                    } else if (channelType === 'wordpress') {
                        this.setState({posts:data.posts});
                    }
                });
                resolve();
            });
        })
    }

    deleteFeed=(id)=>{
        //---- delete feed
        let channelIdentifier = this.state.assetData.channel.identifier;
        let channelType = this.state.assetData.channel.attributeGroup.identifier;

        let pathChannelType;
        let deleteAction;
        let postId = id;

        if (channelType === 'facebook') {
            pathChannelType = 'facebook-page';
            deleteAction = 'posts'
        } else if (channelType === 'twitter') {
            pathChannelType = 'twitter';
            deleteAction = 'tweets';
        } else if (channelType === 'wordpress') {
            pathChannelType = 'wordpress';
            deleteAction = 'posts';
        }
        
        this.setState({
            requesting: true
            }, () => {
            this.props.startLoading();
            request(`${process.env.REACT_APP_API}/assets/${pathChannelType}/${channelIdentifier}/${deleteAction}/${postId}`, 'delete', {}, 'application/json', true
            ).then(async (data) => {
                if(data){
                    
                    await this.getFeed();
                    this.props.stopLoading();
                    this.props.snack(ALERT_SUCCESS, `${this.state.assetData.assetType.identifier === 'twitter' ? 'Tweet' : 'Post'} supprimé ! `);
                }
                else{
                    await this.getFeed();
                    this.props.stopLoading();
                    this.props.snack(ALERT_ERROR, 'Il y a eu une erreur, veuillez réessayer plus tard.');

                }
            })
        });

    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: {assetId : id}
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
      startLoading: () => dispatch({ type: START_LOADING }),
      stopLoading: () => dispatch({ type: STOP_LOADING }),
      snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DiffusionPermanentPosts)));
