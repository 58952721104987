import {useState} from 'react';

import styled from 'styled-components';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';

import HeadingCell from './cells/HeadingCell'
import CellText from './cells/CellText';
import CellImage from './cells/CellImage';
import CellLink from './cells/CellLink';
import CellAvatars from './cells/CellAvatars';
import CellProfilePicture from './cells/CellProfilePicture';
    
const CustomRow = styled(TableRow)`

    background : ${props => props.style.rowBackground ? props.style.rowBackground : 'white'};
    ${props => props.style.type == 'cards' ? 
        `
        border-radius : 10px;
        &:hover{
            box-shadow :0px 1px 9px -1px rgba(0,0,0,0.15);
        }
        `
    : null}
    ${props => props.style.type == 'striped' ? 
        `   
            background : #f2f2f2;
            &:nth-of-type(odd){
                background :  white;
            }
        `
    : null}
`
const CustomTable = styled(Table)`
    ${props => props.style.type == 'cards' ? `
        border-spacing: 0px 10px;
        border-collapse:separate;
    `:null}
`

const HeadRow = styled(TableRow)`
    background : ${props=> props.style?.headingBackground ? props.style.headingBackground: '#003e4d' };

    th{
        color : ${props=> props.style?.headingColor ? props.style.headingColor : 'white' };
    }
`

const cells = {
    'text' : CellText,
    'image' : CellImage,
    'link'  : CellLink,
    'avatars' : CellAvatars,
    'profil_picture' : CellProfilePicture,
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

const Rows = (props) => {
    return (
        <CustomRow 
            key={`list${capitalize(props.label)}-${props.index}`}
            style = {props.style}
        >
            {props.columns.map((column)=>{
                let Cell = cells[column.cellType];
                let value = props.item[column.field];
                return(
                    <Cell value={value} width={column.width} {...column.cellProps} />
                )
            })}
        </CustomRow>
    )
}

export default function TableView(props){
    let {settings,items,label} = props;
    const columns = settings.columns;

    return(
        <CustomTable style={props.settings.style}>
            <TableHead>
                <HeadRow style={props.settings.style}>
                    {columns.map((column)=>(
                        <HeadingCell sortCallback = {props.sortCallback} width={column.cellProps?.width} label={column.label} filter={column.filter} sortBy={props.sortBy}/>
                    ))}
                </HeadRow>
            </TableHead>
            <TableBody>
                {
                    props.ready && items ? 
                        items.map((item,index)=>(<Rows item={item} index={index} columns={columns} style={props.settings.style} label={label}/>))
                    : 
                    <tr>
                        <td colspan={columns?.length ? columns.length : 1}><PageLoader/></td>                 
                    </tr>
                }
            </TableBody>
        </CustomTable>
    )
}

