import React from 'react';
import {Grid,List,InputLabel} from '@material-ui/core';
import request from '../../../../../js/utils/fetch';

import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import {connect} from 'react-redux';
import {GET_PRODUCTS_PAGINATION, SEARCH_PRODUCTS_SKU_BY_CAT, SEARCH_PRODUCTS_SKU} from '../../../../../queries/products';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';

import * as Pagination from '../../../../../js/utils/pagination';
import _ from 'lodash';
import ListingProducts from './ListingProducts';



const ListCustom = styled(List)`
    padding: 0;
    &>div{
        padding: 8px;
        background: ${colors.white};
    }
`;
const InputLabelCustom = styled(InputLabel)`
    color: ${colors.blue.regular};
    line-height: 20px;
    @media screen and (min-width: 1280px){
        height: 51px;
    }
    @media screen and (max-width: 1450px){
        font-size: 14px;
        line-height: 18px;
    }
    @media screen and (max-width: 1280px){
        font-size: 13px;
        line-height: 17px;
    }
    @media screen and (max-width: 960px){
        font-size: 12px;
        line-height: 16px;
    }
    display: flex;
    align-items: center;
    word-break: break-word;
`;

class ProductSelector extends React.Component { 
    constructor(props){
        super(props)
        this.state = {
            currentLang : this.props.allState.currentLang,
            selectedProducts: this.props.allState.selectedProducts || [],
            selectAll: [],
            nbProductsTotal: this.props.allState.selectedProducts?.length || 0,
            pagination: {
                page: 0,
                perPage: 8,
                count: 0,
            },
            productByCategorie: {},
            selectedProductByCategorie: {},
            formatedDatas: {},
            load: true,
            skulist: [],
            searchStatus: 'all',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSearchStatus = this.handleSearchStatus.bind(this);
        this.handleSearchSku = this.handleSearchSku.bind(this);
        this.typingSearchTimer = null;
    }

    
    handleChangeAccordion = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    getChildrenCat = (id) => {
        for(let categorie of this.state.categories) {
            if(categorie.id === id) {
                for(let child of categorie?.childrens){
                    this.state.arrayCatFilter.push(child.id);
                }
            }
        }
    }

    handleChangeTab = async (e, id, breadcrumb) => {
        let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
        this.setState({load: true, pagination});
        if(id) {
            this.setState({
                idActiveCat: id,
                arrayCatFilter: [id],
                breadcrumb
            }, () => {
                this.getChildrenCat(id);

                this.props.client.query({
                    query: SEARCH_PRODUCTS_SKU_BY_CAT,
                    variables: {"idlist": this.state.arrayCatFilter},
                    fetchPolicy: 'no-cache'
                }).then(result =>{
                    this.setState({
                        skulist: [],
                    }, () => {
                        for(let item of result.data.categories){
                            for(let product of item.products.edges){
                                if(this.state.skulist.indexOf(product.node.id) === -1){
                                    this.state.skulist.push(product.node.id);
                                }
                            }
                        }
                        if(this.state.searchValue && this.state.searchValue !== ''){
                            this.handleChange(null, true);
                        }
                        else{
                            if(this.state.skulist.length === 0){
                                this.setState({noResult: true, load: false});
                            }
                            else{
                                this.setState({noResult: false},() => {
                                    this.handleGetProducts();
                                })
                            }
                        }
                    });
                });
            });
            this.setState({activeCategorie: []});
        } else {
            this.setState({
                activeCategorie: ['Tout voir'],
                noResult: false,
                load: false,
            }, () => {
                this.handleChange(null, true);
            });
        }
    };
    
    handleChange = (e, value) => {
        clearTimeout(this.typingSearchTimer);
        if(e?.target?.value){
            this.setState({searchValue: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch();
            }, 500);
        } 
        else {
            this.setState({
                skulist: [],
                noResult: false,
                searchValue: '',
            }, () => {
                this.handleGetProducts();
            });
        }
    }

    handleSearchStatus = async (e, value) => {
        let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
        this.setState({
            pagination,
            searchStatus: e.target.value
        }, () => this.handleGetProducts(true));
    }

    handleSearchSku = async (e, value) => {
        let searchValueSku = e.target.value;
        let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
        this.setState({
            pagination,
            searchValueSku,
        }, () => this.handleGetProducts());
    }

    handleSearch = async () => {
        let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
        this.setState({pagination, load: true})
        let value = this.state.searchValue;
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_PRODUCTS_SKU,
                variables: {"needle": value},
                fetchPolicy: 'no-cache'
            }).then(result =>{
                let stockSku = this.state.skulist;
                this.setState({
                    skulist: [],
                }, () => {
                    if(this.state.activeCategorie.length <= 1){
                        for(let item of result.data.productDatas.edges){
                            if(this.state.skulist.indexOf(item.node.product.id) === -1){
                                this.state.skulist.push(item.node.product.id);
                            }
                        }
                    }
                    else{
                        for(let id of stockSku){
                            let check = false;
                            for(let item of result.data.productDatas.edges){
                                if(id === item.node.product.id && !check){
                                    check = true;
                                }
                            }
                            if(check){
                                this.state.skulist.push(id);
                            }
                        }
                    }
                });
                if(this.state.skulist.length === 0){
                    this.setState({noResult: true});
                    this.props.stopLoading();
                }
                else{
                    this.setState({noResult: false},() => {
                        this.handleGetProducts();
                    });
                }
            });
        } else {
            this.setState({
                skulist: [],
                noResult: false
            },() => {
                this.handleGetProducts();
            });
        }
    }

    handleGetCategories() {
        return new Promise((resolve, reject) => {
            request(`${process.env.REACT_APP_API}/categories-list/product`, 'get').then(
                (data) => {
                    if(data.success){
                        this.setState({
                            categories: data.datas,
                            activeCategorie: ['Tout voir']
                        });
                    }
                    resolve();
                }
            );
        });
    }

    changePage = async(newPage)=>{
        let pagination = await Pagination.changePage(this.state.pagination,newPage);
        this.setState({pagination, load: true}, () => this.handleGetProducts());
    };

    changePerPage = async (perPage) => {
        let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
        pagination = await Pagination.updatePerPage(pagination,perPage);
        this.setState({pagination, load: true}, () => this.handleGetProducts());
    };

    handleGetProducts = async () => {
        this.setState({load: true})
        let paginationVars = await Pagination.paginationQueryData(this.state.pagination);
        let variables = {...paginationVars};
        variables.id = this.state.idCatalog;
        if (this.state.searchValueSku){
            variables.sku = this.state.searchValueSku
        }
        if(this.state.skulist.length > 0){
            variables.idList = this.state.skulist.map(e => parseInt(e.replace('/api/products/', '')));
        }
        if (this.state.searchStatus && this.state.searchStatus !== 'all'){
            if (this.state.searchStatus === 'active'){
                variables.status = true
            }else{
                variables.status = false
            }
        }
        this.props.client.query({
            query: GET_PRODUCTS_PAGINATION,
            fetchPolicy: 'no-cache',
            variables
        }).then(async result =>{
            let pagination = await Pagination.updatePaginationData(this.state.pagination, result.data.products);
            this.setState({pagination, load: false, products: result.data.products.edges});
        });
    };

    handleToggle = (value) => {
        let currentIndex  = this.state.selectedProducts.indexOf(value.node.id);
        let nbProductsTotal = this.state.nbProductsTotal;
        let newChecked = this.state.selectedProducts;

        if (currentIndex === -1) {
            newChecked.push(value.node.id);
            nbProductsTotal++;
        } else {
            newChecked.splice(currentIndex, 1);
            nbProductsTotal--;
        }

        let selectedProducts = newChecked;
        this.setState({selectedProducts, nbProductsTotal}, () => {
            this.props.stateCallback('selectedProducts', this.state.selectedProducts)
        });
    };

    componentDidMount = () => {
        this.handleGetProducts();
        this.handleGetCategories();
    };

    render(){
        return(
            <Grid container spacing={4} style={{background: colors.grey.lighter.hue980, marginTop: 0}}>
                <Grid item xs={12}>
                    <ListCustom dense>
                        <Grid container direction="row" justify="space-around">
                            <Grid container>
                                <InputLabelCustom style={{maxWidth: 490, margin: 'auto'}}>Sélectionner des produits ({this.state.nbProductsTotal}) </InputLabelCustom>
                                <Grid container direction="row" style={{margin: 'auto'}}>
                                    <ListingProducts 
                                        productBypage = {{
                                            card: [8,16,32],
                                            list: [10,20,30]
                                        }}
                                        catalogId = {this.props.optionsInputs?.catalogId.replace('/api/catalogs/', '')}
                                        nbperpage = {8}
                                        currentLang={this.props.allState.currentLang} 
                                        windowWidth={this.props.windowWidth}
                                        openForm={this.state.openForm}
                                        handleToggle={this.handleToggle}
                                        selectedProducts={this.state.selectedProducts}
                                        cardSize = {{
                                            xs: 12,
                                            sm: 6,
                                            md: 6,
                                            lg: 6
                                        }}
                                        noCategories={this.props.optionsInputs?.noCategories}
                                        inForm={true}
                                        hoverCard = {false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListCustom>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo((connect(mapStateToProps,mapDispatchToProps)(ProductSelector)));