import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import styled from 'styled-components';


const CardStyled = styled(Card)`
    box-shadow: ${props => props.collapse ? 'none' : '0px 0px 5px #1622421A'};
    border-radius: 5px;
    width : ${props => props.width ? props.width : 'unset'};
    margin-top:${props => props.margintop ? props.margintop : ''};
    margin-bottom:${props => props.marginbottom ? props.marginbottom : ''};
    &:hover{
        box-shadow: ${props => props.hovercard ? '0px 0px 7px 4px #1622421A' : '0px 0px 7px 4px #1622421A' && props.collapse ? 'none' : '0px 0px 7px 4px #1622421A'};
    }
`;

const CardContentStyled = styled(CardContent)`
    padding: ${props => props.contentpadding ? `${props.contentpadding} !important` : ''};
    height: 100%;
    padding-bottom: ${props => props.paddingbottom ? `${props.paddingbottom} !important` : ''};
    padding-top: ${props => props.paddingtop ? props.paddingtop : ''};
    margin-left: ${props => props.marginleft ? props.marginleft : ''};
`;

export default function CardCustom(props){
    const { paddingbottom, paddingtop, contentpadding, hovercard,width,margintop,marginbottom,collapse, marginleft } = props;
    
    return(
        <CardStyled style={props.style} paddingbottom={paddingbottom} paddingtop={paddingtop} hovercard={hovercard ? hovercard.toString() : null} width={width} margintop={margintop} marginbottom={marginbottom} collapse={collapse}>
            <CardContentStyled contentpadding={contentpadding} style={props.cardContentStyle ? props.cardContentStyle : null} paddingbottom={paddingbottom} marginleft={marginleft}>
                { props.children }
            </CardContentStyled>
        </CardStyled>
    );
}
