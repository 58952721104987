import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultTextCms, getDefaultLinkCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bannière media',
    blocks:{
        media: {
            type: EditableTypes.IMAGE,
            name: "Media",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/1920x980"
                }
            }
        },
    }
};