import React from 'react';
import { Grid, Tooltip, Checkbox, TextField, MenuItem, Select} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardCustom from '../../../../layouts/Card/CardCustom';
import CardProduct from '../../../../layouts/Card/cardContent/CardProduct';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import styled from 'styled-components';
import { ROUTE_PRODUCTS_LIST_DETAIL, ROUTE_PRODUCTS_ADD } from '../../../../../js/constants/route-names';
import colors from '../../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { GET_PRODUCTS_SEARCH_PAGINATION } from '../../../../../queries/products';
import LayoutBuilder from '../../../../ui/form/LayoutFormBuilder';
import exportProductsConfig from '../config/exportProducts.config';
import EmptyProduct from '../../../../../assets/pictos/empty-picto/empty_products.png';
import EmptyCard from "../../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../../ui/loadings/page-loader/PageLoader";
import request from '../../../../../js/utils/fetch';
import axios from '../../../../../js/utils/axios';
import { hasRights } from '../../../../../js/utils/rights';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../../js/constants/alert-types';
import { eventService } from '../../../../../js/services/event.service';
import TablePagination from '@material-ui/core/TablePagination';

import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CardProductList from '../../../../layouts/Card/cardContent/CardProductList';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { PRODUCTS, PRODUCTS_PRODUCTS, CREATE, EXPORT } from '../../../../../js/constants/constant-rights';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '../../../../ui/typography/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../../../../navigation/DrawerLeft.scss';
import { GET_CATEGORIES_LIGHT } from '../../../../../queries/categories';
import MasterSearch from '../../../../ui/search/MasterSearch';
import Button from '../../../../ui/button/Button';
import { GET_SELECTIONS } from '../../../../../queries/selections';
import DoneIcon from '@material-ui/icons/Done';
import { UPDATE_PRODUCT_CATEGORY } from '../../../../../queries/product_position';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;
const ViewModuleIconCustom = styled(ViewModuleIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    &.active{
        fill: ${colors.blue.darker.hue300};
        cursor: inherit;
    }
`;
const ViewListIconCustom = styled(ViewListIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    &.active{
        fill: ${colors.blue.darker.hue300};
        cursor: inherit;
    }
`;

const TableCellCustom = styled(TableCell)`
    border: none;
    // display: inline-block;
    // width: calc(100% / 9);
    padding: 3px 16px;
`;
const TablePaginationStyle = styled(TablePagination)`
    .MuiToolbar-root{
        padding: 0;
    }
    .MuiTablePagination-spacer{
        display: none;
    }
`;

const CheckboxCustom = styled(Checkbox)`
    justify-content: flex-start;
    position: absolute;
    background: white;
    border-radius: 5px;
    width: 35px;
    height: 27px;
    margin-top: 10px;
    z-index: 10;
    svg{
        fill: ${colors.blue.lighter.hue300}
    }
    &:hover{
        background: white!important;
        svg{
            fill: ${colors.blue.lighter.hue150}
        }
    }
    input{
        width: 40px;
    }
`;

const CheckboxAll = styled(Checkbox)`
    background: transparent;
    border-radius: 5px;
    z-index: 10;
    padding: 0;
    margin: 0;
`

const AccordionCustom = styled(Accordion)`
    box-shadow: none;
    width: 100%;
`;
const AccordionSummaryCustom = styled(AccordionSummary)`
    padding: 0 20px;
    border-bottom: 1px solid ${colors.grey.lighter.hue900};
    min-height: 40px !important;
    .MuiAccordionSummary-content{
        margin: 8px 0 !important;
        align-items: center;
    }
`;
const AccordionDetailsCustom = styled(AccordionDetails)`
    padding: 0;
    margin-top: 8px;
`;

const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 13px;
        padding: 10px;
    }
    & > p{
        margin: 0;
    }
`;

const styles = theme => ({
});

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.darker.hue300,
    },
    tooltip: {
        backgroundColor: colors.blue.darker.hue300,
        fontSize: 14,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class ListingProducts extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            openFormExport: false,
            catalogFormData : {},
            ready: false,
            page: 0,
            nbperpage: props.nbperpage,
            countPage: 0,
            categories: [],
            skulist: props.idlist ? props.idlist : [],
            searchValue: '',
            searchValueSku: '',
            arrayCatFilter: [],
            viewMode: 'card',
            exportName: '',
            exportLang: props.locales[0].node.code,
            exportType: 'csv',
            exportStatus: 'all',
            exportImage: 'all',
            errors: {},
            searchStatus: 'all',
            attributesInternalFilter: [],
            customSearchAttributes: {
                "product_image": 'all'
            },
            categoriesSelected: props.activeCategorie ? [props.activeCategorie] : [],
            catalogId: props.catalogId || null,
            readyFilters: false,
            listCollections: []
        }
        this.errors = {}
        this.handleChange = this.handleChange.bind(this);
        this.handleSearchStatus = this.handleSearchStatus.bind(this);
        this.handleSearchSku = this.handleSearchSku.bind(this);
        // this.typingSearchTimer = null;
    }

    componentDidMount() {
        if (this.props.listCategories?.length > 0){
            this.setState({
                categories: this.props.listCategories
            })
        }
        this.getAll();


        request(`${process.env.REACT_APP_API}/products-count/media`, 'get', false).then(
            (data) => {
                if(data.success){
                    this.setState({
                        nbImageTotal: data.details
                    });
                }
            }
        );
    }

    async componentDidUpdate(prevProps){
        if (prevProps.reload !== this.props.reload && this.props.reload){
            this.props.onReload();
            this.resetFilters();
        }
    }

    async getAll() {
        await this.handleGetProducts();
        if (!this.props.listCategories){
            await this.handleGetCategories();
        }
        this.setState({
            ready: true,
        })
    }
    
    handleSearchStatus(e, value) {
        this.setState({
            searchStatus: e.target.value
        });
    }

    handleSearchSku(e, value) {
        this.setState({
            searchValueSku: e.target.value
        });
    }

    handleChange(e, value) {
        this.setState({searchValue: e.target.value});
    }

    resetFilters = () => {
        this.setState({
            ready: false,
            page: 0,
            searchValue: '',
            searchValueSku: '',
            customSearchAttributes: {
                "product_image": 'all'
            },
            categoriesSelected: this.props.activeCategorie ? [this.props.activeCategorie] : [],
        }, () => this.handleGetProducts())
    }

    validateFilters = () => {
        this.setState({
            ready: false,
            page: 0,
        }, () => this.handleGetProducts())
    }

    handleToggleDrawer = (stateDrawer) => {
        if (stateDrawer === "openFormExport"){
            this.setState({
                [stateDrawer] : !this.state[stateDrawer],
                exportName: '',
                exportLang: this.props.currentLang,
                exportType: 'csv',
                exportStatus: 'all',
                exportImage: 'all',
            })
        }
        this.setState({ 
            [stateDrawer] : !this.state[stateDrawer]
        });
    };

    handlerMutationExport = async () => {
        this.props.startLoading();
        if (this.hasErrors()) {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            return eventService.fire();
        }
        try {
            let data = new FormData();
            data.append('name', this.state.exportName);
            data.append('local', this.state.exportLang);
            data.append('format', this.state.exportType);
            data.append('status', this.state.exportStatus);
            data.append('image', this.state.exportImage);

            let urlencoded = new URLSearchParams(data).toString();

            request(`${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`, 'get', null, 'application/x-www-form-urlencoded')
                .then(async (data) => {
                    if (data.success){
                        window.open(`${process.env.REACT_APP_API_ROOT}/medias/export/${data.mediaObject.filePath + '.' + (this.state.exportType === 'xls' ? this.state.exportType + 'x' : this.state.exportType)}`, '_blank');
                    }
                    this.props.stopLoading();
                    this.props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
                });
        } catch(e) {
            this.props.snack(ALERT_ERROR, `L'exportation a échoué !`);
            this.props.stopLoading();
        }
        this.handleToggleDrawer('openFormExport')
    };

    handleGetProducts() {
        return new Promise((resolve, reject) => {
            const getProducts = GET_PRODUCTS_SEARCH_PAGINATION;
            let productsDataFilters = [];
            let variables = 
            {
                "itemsPerPage": this.state.nbperpage, 
                "page": this.state.page + 1,
            };

            if (this.state.searchValueSku){
                variables.sku = this.state.searchValueSku
            }
            if (this.state.catalogId){
                variables.catalog = this.state.catalogId
            }

            if (this.state.searchValue !== "" && this.state.searchValue){
                productsDataFilters.push(JSON.stringify({
                    'identifier': 'product_name',
                    'value': this.state.searchValue,
                    'locale': this.props.currentLang,
                    'option': null
                }))
            }

            if (this.state.searchStatus && this.state.searchStatus !== 'all'){
                if (this.state.searchStatus === 'active'){
                    variables.status = true
                }else{
                    variables.status = false
                }
            }

            if (this.state.customSearchAttributes){
                for (const [key, value] of Object.entries(this.state.customSearchAttributes)) {
                    if (value !== ''){
                        let option = null
                        let newValue = null
                        if (key !== 'product_image'){
                            option = value?.includes('/api/attribute-options')
                        }else{
                            if (value === "active"){
                                newValue = true
                            }else if (value === "inactive"){
                                newValue = false
                            }
                        }
                        if (value !== 'all'){
                            productsDataFilters.push(JSON.stringify({
                                'identifier': key,
                                'value': option ? null : newValue !== null ? newValue : value,
                                'locale': option ? null : this.props.currentLang,
                                'option': option ? value.replace('/api/attribute-options/', '') : null
                            }))
                        }
                    }
                }
            }

            if (productsDataFilters.length > 0){
                variables.productDatas = productsDataFilters;
            }

            if (this.state.categoriesSelected.length > 0){
                variables.categories = this.state.categoriesSelected.map(e => parseInt(e.id.replace('/api/categories/', '')))
            }

            this.props.client.query({
                query: getProducts,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                this.listProducts = result.data.researchProducts.edges;
                this.setState({
                    countPage: result.data.researchProducts.totalCount
                })
                let dataSku = new FormData();
                let list_sku = this.listProducts.map(e => e.node.sku)
                list_sku.forEach(element => {
                    dataSku.append('sku_list[]', element)
                });

                

                axios(`${process.env.REACT_APP_API}/completude`, 'post',dataSku).then(
                    (data) => {
                        if(data.success){
                            for( var i = 0; i < this.listProducts.length; i++){
                                let productSku = this.listProducts[i].node.sku;
                                let infoStats = data.products[productSku];
                                this.listProducts[i].node.stats = {
                                    infoStats
                                };
                            }
                            this.forceUpdate();
                        }
                    }

                );
                //----disable it - now available with productCatalogs > total Count :D
                    // request(`${process.env.REACT_APP_API}/products-count/catalog`, 'get', false).then(
                    //     (data) => {
                    //         if(data.success){
                    //             this.setState({
                    //                 nbCatalog: data
                    //             });
                    //         }
                    //     }
                    // );    
                
                if ( process.env.REACT_APP_MODE_SPREAD === "hub"){
                    request(`${process.env.REACT_APP_API}/stockCompletude`, 'get', false).then(
                        (data) => {
                            if(data.success){
                                for( var i = 0; i < this.listProducts.length; i++){
                                    let productSku = this.listProducts[i].node.sku;
                                    let infoStocks = data.products[productSku].stock;
                                    let allInfoStocks = data.products[productSku];
                                    this.listProducts[i].node.stocks = {
                                        infoStocks,
                                        allInfoStocks,
                                    };
                                }
                                this.forceUpdate();
                            }
                        }

                    );
                }
                if ( process.env.REACT_APP_MODE_SPREAD === "hub"){
                    request(`${process.env.REACT_APP_API}/stockCompletudeByWarehouse/${this.state.catalogId.replace('/api/catalogs/', '')} `, 'post', false).then(
                        (data) => {
                            if(data.success){
                                for( var i = 0; i < this.listProducts.length; i++){
                                    let productSku = this.listProducts[i].node.sku;
                                    let infoStocks = data.products[productSku].stock;
                                    this.listProducts[i].node.stocks = {
                                        infoStocks
                                    };
                                }
                                this.forceUpdate();
                            }
                        }
    
                    );
                }
                if(this.listProducts.length === 0){
                    this.setState({noResult: true});
                }
                else{
                    this.setState({noResult: false});
                }
                this.setState({
                    ready : true,
                    readyFilters: true,
                    listProducts: this.listProducts
                });
                this.props.stopLoading();
                resolve();
            });
        });
    }

    processChildren = async (categories, parent, allIdentifiers) => {
        parent.children = categories.filter(e => e.parent?.id === parent?.id);
        for (let child of parent.children) {
            await this.processChildren(categories, child);
        }
    };

    handleGetCategories() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_CATEGORIES_LIGHT,
                variables: {
                    "exists": [{"catalog": false}],
                    "status": this.state.searchStatus && this.state.searchStatus !== 'all' ? this.state.searchStatus === 'active' ? true : false : null
                },
                fetchPolicy: "no-cache"
            }).then((result) => {
                let allCategories = result.data.categories;
                let root = this.props.isCatalog ? allCategories[0] : Array.isArray(allCategories) ? allCategories?.find(e => e.parent === null) : null;
                if (!this.props.isCatalog){
                    allCategories = allCategories.filter(e => e.catalog === null)
                }
                if (allCategories.length > 0 ){
                    this.processChildren(allCategories, root);
                }
                this.setState({
                    categories : allCategories,
                    activeCategorie : root,
                    ready : true,
                    root,
                })
            })
            resolve()
        });
    }


    handleChangePositionMutation = (data, idProduct) => {
        this.props.startLoading()
        let variables = {
            "id": data.id,
            "category": data.category.id,
            "product": idProduct,
            "position": data.position ? data.position !== '' ? data.position : null : null,
        };
        this.props.client.mutate({
            mutation: UPDATE_PRODUCT_CATEGORY,
            variables,
        }).then(result => {
            this.props.snack(ALERT_SUCCESS, data.selection && data.position ? 'Changement de la position et de la collection bien effectué' : data.selection && !data.position ? 'Changement de la collection bien effectué' : 'Changement de la position bien effectué');
            this.props.stopLoading();
        });
    }

    stateCallback = (stateName, value, custom, translated, callback) => {
        if (custom === true){
            let customSearch = this.state.customSearchAttributes;
            if (!customSearch) {
                customSearch = {};
            }
            customSearch[stateName] = value?.target?.value ?? value;
            this.setState({
                customSearchAttributes: customSearch 
            }, callback);
        }else{
            this.setState({
                [stateName]: value?.target?.value ?? value
            }, callback);
        }
    };

    changePage = (event, page) => {
        this.props.startLoading();
        this.setState({
            page: page
        },()=>{
            this.handleGetProducts();
        });
    };

    handleChangePerPage = (event) => {
        this.setState({
            nbperpage: event.target.value
        }, this.changeViewMode(this.state.viewMode))
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    changeViewMode = (mode) => {
        this.props.startLoading();
        if (mode === "list"){
            this.setState({
                nbperpage : !this.props.productBypage['list'].includes(this.state.nbperpage) ? this.props.productBypage['list'][0] : this.state.nbperpage,
                viewMode : 'list',
                page: 0,
            }, () => this.handleGetProducts())
        }else{
            this.setState({
                nbperpage : this.props.productBypage['card'][0],
                viewMode : 'card',
                page: 0,
            }, () => this.handleGetProducts())
        }
    }

    handleBlankGoTo = (productId) => {
        window.open(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', productId.replace('/api/products/', '')))
    }

    changePosition = (stateName, value, idProduct) => {
        //todo
        let getProducts = this.state.listProducts
        let productData = getProducts.find(e => e.node.id === idProduct)
        let getData = productData.node.productCategories.edges.find(e => e.node.category.id === this.props.activeCategorie.id)
        if (stateName === "position"){
            getData.node.position = value?.target?.value ?? value
        }else{
            if (getData.node.selection){
                getData.node.selection.id = value?.target?.value ?? value
            }else{
                getData.node.selection = {id : value?.target?.value ?? value}
            }
        }
        this.setState({
            listProducts: getProducts
        });
    };

    render(){
        let productBypage = [];
        productBypage = this.props.productBypage[this.state.viewMode]
        const { classes, cardSize } = this.props;
        let checkSelectAll = false;
        let checker = (arr, target) => target.every(v => arr.includes(v));
        if (this.state.listProducts && this.state.listProducts?.length > 0 && this.props.selectedProducts?.length > 0){
            let productsIdArray = this.state.listProducts.map(e => e.node.id)
            
            if(checker(this.props.selectedProducts, productsIdArray)){
                checkSelectAll = true
            }
        }
        let headCells = [
            (this.props.handleSelectAll ?
                { id: 'check', align: "center", style: null, disablePadding: true, label: <CheckboxAll
                    edge="start"
                    checked={checkSelectAll ? true : false}
                    indeterminate={checkSelectAll ? false : this.props.selectedProducts?.length > 0 ? true : false}
                    onClick={() => {this.props.handleSelectAll(this.state.listProducts, checkSelectAll)}}
                    /> 
                }
            : null),
            { id: 'image', align: "center", style: null, disablePadding: true, label: 'Image' },
            { id: 'sku', style: null, disablePadding: false, label: 'Code article' },
            { id: 'nom', style: process.env.REACT_APP_MODE_SPREAD !== "hub" ? null : {width: 'calc(100%/3.5)'}, disablePadding: false, label: 'Nom' },
            { id: 'categories', style: process.env.REACT_APP_MODE_SPREAD !== "hub" ? null : {width: 'calc(100%/4)'}, disablePadding: false, label: 'Catégorie(s)' },
            { id: 'nbCatalogs', align: "center", style: null, disablePadding: false, label: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Catalogue(s)' : 'Présence dans les entrepôts'},
            { id: 'status', align: "center", style: {width: 'calc(100%/9)'}, disablePadding: false, label: 'Status' },
            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
            { id: 'completion', style: {width: 'calc(100%/9) / 1.25'}, disablePadding: false, label: 'Complétude' } : null,
            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
            { id: 'nbImages',align: "center", style: {width: 'calc(100%/9)'}, disablePadding: false, label: 'Nombre d\'images' } : null,
            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
            { id: 'button', style: {width: 'calc(100%/9)'}, disablePadding: false, label: '' } : null,
        ];
        if (this.props.minimalForm){
            headCells = [
                { id: 'checkboxes', align: "center", style: null, disablePadding: true, label: '' },
                { id: 'image', align: "center", style: null, disablePadding: true, label: 'Image' },
                { id: 'sku', style: null, disablePadding: false, label: 'Code article' },
                { id: 'nom', style: null, disablePadding: false, label: 'Nom' },
                { id: 'status', align: "center", style: null, disablePadding: false, label: 'Status' },
                { id: 'position', style: null, disablePadding: false, label: 'Position' },
                { id: 'validate', style: null, disablePadding: false, label: 'Valider' },
                { id: 'button', style: null, disablePadding: false, label: '' },
            ]
        }
        // console.log('Listing state', this.state)

        return(
            <Grid container>
                <Grid container direction="row" alignItems="center" style={{marginTop: 12}}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container justify="flex-end" alignItems="center">
                            <BootstrapTooltip title="Vue carte" className={clsx(classes.tooltip)}>
                                <ViewModuleIconCustom 
                                style={{ fontSize: 30 }} 
                                className={this.state.viewMode === "card" ? 'active': null}
                                onClick={this.state.viewMode === "card" ? null : () => this.changeViewMode('card')}
                                />
                            </BootstrapTooltip>
                            <BootstrapTooltip title="Vue liste" className={clsx(classes.tooltip)}>
                                <ViewListIconCustom 
                                style={{ fontSize: 30, marginLeft: 8 }} 
                                className={this.state.viewMode === "list" ? 'active': null}
                                onClick={this.state.viewMode === "list" ? null : () => this.changeViewMode('list')}
                                />
                            </BootstrapTooltip>

                            {
                                hasRights(PRODUCTS, PRODUCTS_PRODUCTS, EXPORT) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                    <BootstrapTooltip title="Cliquez pour exporter les produits" className={clsx(classes.tooltip)}>
                                        <GetAppOutlinedIcon 
                                        style={{ fontSize: 30, marginLeft: 8, cursor:'pointer', fill: colors.blue.darker.hue300 }} 
                                        onClick={() => this.handleToggleDrawer('openFormExport')}
                                        />
                                    </BootstrapTooltip>
                                : null
                            }
                            
                        </Grid>
                    </Grid>
                    <Grid container>
                        {
                            this.state.readyFilters ?
                            <AccordionCustom defaultExpanded={false}>
                                    <AccordionSummaryCustom
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography variant={"h4"}>Filtres/Recherches</Typography>
                                    </AccordionSummaryCustom>
                                    <AccordionDetailsCustom>
                                        <Grid container justify="center" alignItems="center">
                                            <MasterSearch 
                                                currentStatus={this.state.searchStatus} 
                                                searchStatusHandler={this.handleSearchStatus} 
                                                handleNameDesc={this.handleChange} 
                                                handleSearchSku={this.handleSearchSku} 
                                                validateSearch={this.validateFilters} 
                                                resetFilters={this.resetFilters}
                                                categories={this.state.categories}
                                                attributesInternalFilter={this.props.attributes.product.attributes.edges.filter(e => e.node.internalFilter)}
                                                stateCallback={this.stateCallback}
                                                currentLang={this.props.currentLang} 
                                                allState={this.state}
                                                categorieActivated={this.props.activeCategorie ? false : true}
                                                noCategories={this.props.noCategories}
                                            />
                                        </Grid>
                                    </AccordionDetailsCustom>
                                </AccordionCustom>
                            : null
                        }
                    </Grid>
                </Grid>
                {
                    this.props.handleSelectAll && this.state.viewMode === "card" ? 
                        <Grid container justify="space-between" alignItems="center">
                            <Button onClick={() => {this.props.handleSelectAll(this.state.listProducts, checkSelectAll)}}>{checkSelectAll ? `Désélectionner tout` :  `Sélectionner tout`}</Button>
                        </Grid>
                    : null
                }
                <Grid container direction="row" spacing={2} style={{marginTop: this.state.viewMode === "list" ? 8 : 0, marginBottom: this.state.viewMode === "list" ? 16 : 0}}>
                    {
                        this.state.viewMode === "list" ? 
                            <GridCustom item xs={12} key={`HeaderList`} style={{
                                padding: this.props.handleToggle ? '20px 0px 3px' : '3px 16px',
                                zIndex: 12,
                            }}>
                                <CardCustom style={{width: "100%", height: "100%",  padding: "0"}} cardContentStyle={{height: "100%"}} contentpadding={'0'} hovercard={false} collapse={true}>
                                    <TableContainer component={Paper} style={{maxHeight: this.props.inForm ? 600 : 700}}>
                                        <Table aria-label="headerList" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    {headCells.filter(e => e!== null).map((headCell) => (
                                                        <TableCellCustom
                                                            key={headCell.id}
                                                            align={headCell.align ? headCell.align : 'left'}
                                                            style={{width: `${headCell.style?.width}`, background: "white"}}
                                                        >
                                                            {headCell.label}
                                                        </TableCellCustom>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.listProducts.map((product, index) => {
                                                    let truncAttrGroupId = product.node.attributeGroup.id.replace('/api/attribute-groups/', '');
                                                    let productCategory = null;
                                                    if (this.props.minimalForm){
                                                        productCategory = product.node.productCategories?.edges?.find(e => e.node.category.id === this.props.activeCategorie.id)
                                                    }
                                                    return(
                                                        <CardProductList
                                                            currentLang={this.props.currentLang} 
                                                            nbCatalog={this.state.nbCatalog?.products[product.node.sku] ?? 0} 
                                                            nbImageTotal={this.state.nbImageTotal[truncAttrGroupId]} 
                                                            product={product} 
                                                            index={index} 
                                                            routeProduct={() => this.props.inForm ? this.handleBlankGoTo(product.node.id) : this.goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', product.node.id.replace('/api/products/', '')))} 
                                                            windowWidth={this.props.windowWidth}
                                                            inForm={this.props.inForm}
                                                            minimalForm={this.props.minimalForm}
                                                            changePosition={this.changePosition}
                                                            productCategory={productCategory}
                                                            reverseCheck={this.props.reverseCheck}
                                                            selectedProducts={this.props.selectedProducts}
                                                            handleChangePositionMutation={this.handleChangePositionMutation}
                                                            handleToggle={this.props.handleToggle}
                                                            listCollections={this.state.listCollections}
                                                            index={index}
                                                        />
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardCustom>
                            </GridCustom>
                        : null
                    }
                    {
                        !this.state.ready ? (<PageLoader />) : (this.state.listProducts && this.state.listProducts.length > 0 && !this.state.noResult) ? (
                            this.state.listProducts.map((product, index) => {
                                let truncAttrGroupId = product.node.attributeGroup.id.replace('/api/attribute-groups/', '');
                                const labelId   = `checkbox-list-secondary-label-${product}`;
                                let productCategory = null;
                                if (this.props.minimalForm){
                                    productCategory = product.node.productCategories?.edges?.find(e => e.node.category.id === this.props.activeCategorie.id)
                                }
                                if(this.state.viewMode === "card"){
                                    return(
                                        <GridCustom item key={`GridItem${index}`} lg={cardSize?.['lg'] ? cardSize?.['lg'] : 4} md={ cardSize?.['md'] ? cardSize?.['md'] : 6} sm={cardSize?.['sm'] ? cardSize?.['sm'] : 6} xs={cardSize?.['xs'] ? cardSize?.['xs'] : 12} key={`ListProduct${index}`} onClick={this.props.handleToggle ? () => this.props.handleToggle(product) : null}>
                                            <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%", width: "100%"}} hovercard={this.props.hoverCard ? this.props.hoverCard : true} style={{width: "100%",cursor: this.props.inForm ? 'pointer' : 'initial'}}>
                                                {
                                                    this.props.inForm ?
                                                        <CheckboxCustom
                                                            edge="start"
                                                            checked={this.props.reverseCheck ? this.props.selectedProducts?.indexOf(product.node.id) !== -1 ? false : true : this.props.selectedProducts?.indexOf(product.node.id) === -1 ? false : true}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    : null
                                                }
                                                <CardProduct 
                                                    currentLang={this.props.currentLang} 
                                                    nbCatalog={this.state.nbCatalog?.products[product.node.sku] ?? 0} 
                                                    nbImageTotal={this.state.nbImageTotal?.[truncAttrGroupId]} 
                                                    product={product.node} 
                                                    index={index} 
                                                    routeProduct={() => this.props.inForm ? this.handleBlankGoTo(product.node.id) : this.goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', product.node.id.replace('/api/products/', '')))} 
                                                    windowWidth={this.props.windowWidth}
                                                    inForm={this.props.inForm}
                                                />
                                            </CardCustom>
                                            {
                                                this.props.minimalForm ?
                                                    <Grid spacing={1} container alignItems="center" style={{marginTop: 8}}>
                                                        <Grid item xs={3}>
                                                            <TextFieldCustom
                                                                id={`Position-${index}`}
                                                                variant="outlined"
                                                                value={productCategory.node.position || ''}
                                                                fullWidth= {true}
                                                                onChange={evt => {
                                                                    this.changePosition('position', evt, product.node.id);
                                                                }}
                                                                name={`Position-${index}`}
                                                                type={'text'}
                                                                placeholder={'Pos'}
                                                                style={{width: '100%'}}
                                                                onClick={(e) => e.stopPropagation()}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2} onClick={(e) => {e.stopPropagation()}}>
                                                            <Grid container justify="center">
                                                                <Button style={{minWidth: "100%"}} onClick={() => this.handleChangePositionMutation(productCategory.node, product.node.id)}>
                                                                    <DoneIcon style={{fill: colors.white}}/>
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                : null
                                            }
                                        </GridCustom>
                                    )
                                }
                            })
                        )
                        : (this.state.noResult) ?
                            (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un produit" : null} onClick={() => this.goTo(ROUTE_PRODUCTS_ADD)} picto={EmptyProduct} openForm={this.props.openForm} xsImg={this.props.openForm ? 4 : 2} mtImg={2} />)
                        : 
                            (<EmptyCard title={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Vous n'avez pas encore configuré de produit" : "Vous n'avez pas les droits pour ajouter un produit"} subtitle={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un produit en cliquant ci-dessous" : "Faite une demande auprès d'un administrateur"} textButton={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un produit" : null} onClick={() => this.goTo(ROUTE_PRODUCTS_ADD)} picto={EmptyProduct} openForm={this.props.openForm} xsImg={this.props.openForm ? 4 : 2} />)
                    }
                </Grid>
                {
                    this.state.ready && !this.state.noResult ?
                        (
                            <TablePaginationStyle
                                count={this.state.countPage}
                                page={this.state.page}
                                onChangePage={(event, page) => {this.changePage(event, page)}}
                                color="primary"
                                component="div"
                                rowsPerPage={this.state.nbperpage}
                                rowsPerPageOptions={productBypage}
                                labelDisplayedRows={(from=this.state.pagePresentations) => (`${from.from}-${from.to === -1 ? from.count : from.to} sur ${from.count}`)}
                                onChangeRowsPerPage={this.handleChangePerPage}
                                labelRowsPerPage="Produits par page :"
                            />
                        ) : null
                }
                <LayoutBuilder 
                    opened={this.state.openFormExport} 
                    icomoon={'ico-import-fichier'}
                    forClose={() => this.handleToggleDrawer('openFormExport')}  
                    dataLayout={exportProductsConfig(this.props.locales, 'products')} 
                    drawerWidth={this.state.drawerWidth}
                    handleCancel={this.handleCancel}
                    handlerMutation={this.handlerMutationExport} 
                    allState={this.state} 
                    stateCallback={this.stateCallback}
                    validateButton={true}
                    errorCallback={this.handleFormError}
                    checkError={() => {}}
                />  
            </Grid>
        )
    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: { productId : id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ListingProducts))));