import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  Switch,
  Route
} from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

import Appbar from '../../navigation/Appbar';
import DrawerLeft from '../../navigation/DrawerLeft';

import './Dashboard.scss';
import { START_LOADING, STOP_LOADING, SET_USER, SET_GUIDELINE } from '../../../js/constants/action-types';
import { GET_SYSTEM_TYPOS } from "../../../queries/brand_guideline";
import GoogleFontLoader from 'react-google-font-loader';
import { withApollo } from 'react-apollo';

import { 
  ROUTE_HOME,
  ROUTE_STATS,
  ROUTE_STATS_RETAILERS,
  ROUTE_STATS_ASSETS,
  ROUTE_STATS_CAMPAIGNS,
  ROUTE_DIFFUSION_CALENDAR,
  ROUTE_DIFFUSION_PERMANENT,
  ROUTE_DIFFUSION_PERMANENTS_POSTS,
  ROUTE_DIFFUSION_PERMANENTS_ADD_POST,
  ROUTE_DIFFUSION_CAMPAIGNS,
  ROUTE_DIFFUSION_CAMPAIGNS_ADD,
  ROUTE_DIFFUSION_CAMPAIGNS_DETAIL,
  ROUTE_DIFFUSION_RETAILERS,
  ROUTE_DIFFUSION_SOCIALS,
  ROUTE_ENGINE,
  ROUTE_ENGINE_ASSETS,
  ROUTE_ENGINE_ASSETS_DETAIL,
  ROUTE_DIFFUSION_PERMANENTS_PAGE,
  ROUTE_DIFFUSION_PERMANENTS_HEADERS_ASSET,
  ROUTE_ENGINE_ASSETS_DETAIL_STATS,
  ROUTE_CATALOGS,
  ROUTE_CATALOGS_LIST,
  ROUTE_CATALOGS_DETAIL,
  ROUTE_MEDIAS,
  ROUTE_MEDIAS_CATEGORIES,
  ROUTE_MEDIAS_LIST,
  ROUTE_MEDIAS_TAGS,
  ROUTE_CHANNELS_MEDIAS,
  ROUTE_ORDERS_LIST,
  ROUTE_ORDERS_LIST_PREORDER,
  ROUTE_ORDERS_LIST_RETURN,
  ROUTE_ORDERS_LIST_DELAY,
  ROUTE_ORDERS_DETAIL,
  ROUTE_ATTRIBUTS,
  ROUTE_ATTRIBUTS_ATTRIBUTS,
  ROUTE_ATTRIBUTS_CATEGORIES,
  ROUTE_ATTRIBUTS_POINT,
  ROUTE_PRODUCTS,
  ROUTE_PRODUCTS_CATEGORIES,
  ROUTE_PRODUCTS_LIST,
  ROUTE_PRODUCTS_ADD,
  ROUTE_PRODUCTS_LIST_DETAIL,
  ROUTE_PRODUCTS_CONNECTORS,
  ROUTE_PRODUCTS_STORELOCATOR,
  ROUTE_DIFFUSION_PERMANENTS_SHOP_THE_LOOK,
  ROUTE_CHANNELS,
  ROUTE_CHANNELS_RETAILERS,
  ROUTE_CHANNELS_BRANDS,
  ROUTE_CHANNELS_MARKETPLACES,
  ROUTE_CHANNELS_SOCIALS,
  ROUTE_CHANNELS_CMS,
  ROUTE_CHANNELS_MAILER,
  ROUTE_CHANNELS_CONTENTS,
  ROUTE_CHANNELS_CONNECTORS,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_LANGUAGES,
  ROUTE_SETTINGS_CURRENCIES,
  ROUTE_SETTINGS_USERS,
  ROUTE_SETTINGS_DETAIL_USER,
  ROUTE_SETTINGS_SUBSCRIPTIONS,
  ROUTE_PRODUCTS_COMPLETUDE,
  ROUTE_SETTINGS_ACCESS,
  ROUTE_PREFERENCES,
  ROUTE_HELPS,
  ROUTE_BRAND_GUIDELINE,
  ROUTE_BRAND_GUIDELINE_ADD,
  ROUTE_BRAND_GUIDELINE_UPDATE,
  ROUTE_BRAND_FLOW,
  ROUTE_CONTENU,
  ROUTE_CONTENU_CATEGORIES,
  ROUTE_CONTENU_LIST,
  ROUTE_CONTENU_ADD,
  ROUTE_CONTENU_LIST_DETAIL,
  ROUTE_CONTENU_ATTRIBUTES,
  ROUTE_IMPORT_HISTORY,
  ROUTE_ATTRIBUTS_CATEGORIESS,
  ROUTE_SHELLS,
  ROUTE_SHELLS_LIST,
  ROUTE_SHELLS_CUSTOM_ATTRIBUTES,
  ROUTE_SHELLS_CUSTOM_LIST,
  ROUTE_SHELLS_CUSTOM_DETAIL,
  ROUTE_RESTAURANTS,
  ROUTE_RESTAURANTS_DETAILS,
  ROUTE_RESTAURANTS_DEMANDS,
  ROUTE_PRODUCTS_STORELOCATOR_DETAILS,
  ROUTE_PRODUCTS_COLLECTIONS,
  ROUTE_SETTINGS_TAXRATES,
  ROUTE_DIFFUSION_PERMANENTS_PAGES_ASSET,
  ROUTE_DIFFUSION_PERMANENTS_INFOS_MANAGEMENTS_ASSET,
  ROUTE_DIFFUSION_PERMANENTS_PAGES_CHILDS_ASSET,
  ROUTE_SETTINGS_GROUPRATES,
  ROUTE_BRAND_FLOW_DETAILS,
} from '../../../js/constants/route-names';

import Homepage from './homepage/Homepage';
import Calendar from './homepage/Calendar';

import DashboardStats from './stats/DashboardStats';
import StatsAssets from './stats/StatsAssets';
import StatsRetailers from './stats/StatsRetailers';
import StatsCampaigns from './stats/StatsCampaigns';

import DiffusionPermanent from './diffusion/DiffusionPermanent';
import DiffusionPermanentPosts from './diffusion/DiffusionPermanentPosts';
import DiffusionPermanentAddPost from './diffusion/DiffusionPermanentAddPost';
import DiffusionCampaigns from './diffusion/DiffusionCampaigns';
import DiffusionCampaignAdd from './diffusion/DiffusionCampaignAdd';
import DiffusionCampaignDetail from './diffusion/DiffusionCampaignDetail';
import DiffusionRetailers from './diffusion/DiffusionRetailers';
import DiffusionSocials from './diffusion/DiffusionSocials';

import DashboardEngine from './engine/DashboardEngine';
import EngineAssets from './engine/EngineAssets';
import EngineAssetsDetails from './engine/EngineAssetsDetails';
import EngineAssetsDetailsPageStats from './engine/EngineAssetsDetailsPageStats';
import EngineAssetsDetailsPage from './engine/EngineAssetsDetailsPage';
import EngineAssetsDetailsHeaders from './engine/EngineAssetsDetailsHeaders';

import DashboardCatalogs from './catalogs/DashboardCatalogs';
import ListCatalogs from './catalogs/ListCatalogs';
import CatalogsDetails from './catalogs/CatalogsDetails';

import DashboardMedias from './medias/DashboardMedias';
import MediasCategories from './medias/MediasCategories';
import ListMedias from './medias/ListMedias';
import MediasTags from './medias/MediasTags';

import DashboardContents from './contents/DashboardContents';
import ListContents from './contents/ListContents';
import ContentDetail from './contents/ContentDetail';
import ContentAdd from './contents/ContentAdd';
import ContentsCategories from './contents/ContentsCategories';
import ContentsAttributes from './contents/ContentsAttributes';

import AttributesProducts from './attributs/AttributesProducts';

import DashboardProducts from './products/DashboardProducts';
import ProductsCategories from './products/ProductsCategories';
import ProductAdd from './products/ProductAdd';
import ListProducts from './products/ListProducts';
import ProductDetail from './products/ProductDetail';
import ProductsConnectors from './products/ProductsConnectors';
import ShopTheLook from './products/ShopTheLook';

import ListStorelocator from './products/ListStorelocator';
import StorelocatorDetail from './products/StorelocatorDetail';

import DashboardChannels from './channels/DashboardChannels';
import ListChannels from './channels/ListChannels';
import ListRetailers from './channels/ListRetailers';
import RetailersDetails from './channels/RetailersDetails';

import GuidelineBrand from './brand/GuidelineBrand';
import GuidelineAdd from './brand/GuidelineAdd';
import GuidelineUpdate from './brand/GuidelineUpdate';

import SettingsLanguages from './settings/SettingsLanguages';
import SettingsCurrencies from './settings/SettingsCurrencies';
import SettingsUsers from './settings/SettingsUsers';
import UserDetail from './settings/UserDetail';
import SettingsSubscriptions from './settings/SettingsSubscriptions';
import SettingsPreferences from './settings/SettingsPreferences';
import SettingsAccess from './settings/SettingsAccess';
import ProductsCompletude from './products/ProductsCompletude';
import ImportHistory from './settings/ImportHistory';
import SettingsHelps from './settings/SettingsHelps';
import ListOrders from './orders/ListOrders';
import OrderDetails from './orders/OrderDetails';
import ListShells from './shells/ListShells';
import ListShellsCustom from './shells/ListShellsCustom';
import ShellCustomDetail from './shells/ShellCustomDetail';
import ListCollections from './products/ListCollections';
import SettingsTaxRates from './settings/SettingsTaxRates';
import DiffusionListPages from './diffusion/DiffusionListPages';
import SettingsGroupRates from './settings/SettingsGroupRates';
import EngineAssetsInfoManagements from './engine/EngineAssetsInfoManagements';

import ListRestaurants from './restaurant/restaurants/ListRestaurants';
import RestaurantDetails from './restaurant/restaurants/RestaurantDetails';
import ListDemands from './restaurant/demands/ListDemands';

function Dashboard(props) {
    const [open, setOpen] = React.useState(window.innerWidth <= 1000 ? false : true);
    const [typoSystem, setTypoSystem] = React.useState([]);

    const handleDrawerToggle = () => setOpen(!open);

    var drawerWidth = 300;

    if(window.innerWidth > 1600){
      open ? drawerWidth = 300 : drawerWidth = 96;
    }
    if(window.innerWidth <= 1600 && window.innerWidth > 1400){
      open ? drawerWidth = 240 : drawerWidth = 96;
    }
    else if(window.innerWidth <= 1400 && window.innerWidth > 1000){
      open ? drawerWidth = 180 : drawerWidth = 96;
    }
    else if(window.innerWidth <= 1000){
      open ? drawerWidth = 240 : drawerWidth = 0;
    }

    const useStyles = makeStyles(theme => ({
      root: {
        display: 'flex',
      },
      hide: {
        display: 'none',
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        '@media (max-width:1000px)': {
          minHeight: '40px',
        }
      },
      content: {
        width: `calc(100% - ${drawerWidth}px)`,
        flexGrow: 1,
        paddingTop: '20px',
        paddingBottom: theme.spacing(4),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        "& > div > div": {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(2),
        },
        "& > div > h1": {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(2),
          paddingTop: theme.spacing(2)
        },
        "& > div > div.MuiBox-root": {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(2),
        },
        "& > div > div.MuiGrid-root": {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(2),
        }
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
    }));
    const classes = useStyles();

    const makeArrayFonts = () => {
      props.client.query({
        query: GET_SYSTEM_TYPOS,
        fetchPolicy: 'no-cache'
      }).then(result =>{
        let array = [];
        for(let font of result.data.typographySystems.edges){
            array.push({font: font.node.value});
        }
        setTypoSystem(array)
        return(array);
      });
    }
    let pathname = props.location.pathname
    let customState = props.location?.state?.customState
    return (
      <div className={classes.root}>
        <CssBaseline />
        <Appbar openCallback={handleDrawerToggle} opened={open} user={props.user} windowWidth={props.windowWidth} drawerWidth={drawerWidth} />
      
        <DrawerLeft openCallback={handleDrawerToggle} pathname={pathname} opened={open} windowWidth={props.windowWidth} drawerWidth={drawerWidth} />
      
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Switch>
            <Route path={`${ROUTE_HOME}`} exact>
              <Homepage user={props.user} windowWidth={props.windowWidth}/>
            </Route>
            
            <Route path={`${ROUTE_STATS}`} exact>
              <DashboardStats windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_STATS_RETAILERS}`} exact>
              <StatsRetailers />
            </Route>
            <Route path={`${ROUTE_STATS_ASSETS}`} exact>
              <StatsAssets />
            </Route>
            <Route path={`${ROUTE_STATS_CAMPAIGNS}`} exact>
              <StatsCampaigns />
            </Route>
            
            <Route path={`${ROUTE_DIFFUSION_CALENDAR}`} exact>
              <Calendar/>
            </Route>
            <Route path={`${ROUTE_DIFFUSION_PERMANENT}`} exact>
              <DiffusionPermanent windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_DIFFUSION_PERMANENTS_POSTS}`} exact>
              <DiffusionPermanentPosts />
            </Route>
            <Route path={`${ROUTE_DIFFUSION_PERMANENTS_ADD_POST}`} exact>
              <DiffusionPermanentAddPost drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_DIFFUSION_CAMPAIGNS}`} exact>
              <DiffusionCampaigns />
            </Route>
            <Route path={`${ROUTE_DIFFUSION_CAMPAIGNS_ADD}`} exact>
              <DiffusionCampaignAdd drawerWidth={drawerWidth}/>
            </Route>
            <Route path={`${ROUTE_DIFFUSION_CAMPAIGNS_DETAIL}`} exact>
              <DiffusionCampaignDetail drawerWidth={drawerWidth}/>
            </Route>
            <Route path={`${ROUTE_DIFFUSION_RETAILERS}`} exact>
              <DiffusionRetailers />
            </Route>
            <Route path={`${ROUTE_DIFFUSION_SOCIALS}`} exact>
              <DiffusionSocials />
            </Route>

            <Route path={`${ROUTE_ENGINE}`} exact>
              <DashboardEngine drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_ENGINE_ASSETS}`} exact>
              <EngineAssets drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_ENGINE_ASSETS_DETAIL}`} exact>
              <EngineAssetsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_ENGINE_ASSETS_DETAIL_STATS}`} exact>
              <EngineAssetsDetailsPageStats windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_DIFFUSION_PERMANENTS_HEADERS_ASSET}`} exact>
              <EngineAssetsDetailsHeaders drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_DIFFUSION_PERMANENTS_PAGES_ASSET}`} exact>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={false}/>
            </Route>
            <Route path={`${ROUTE_DIFFUSION_PERMANENTS_PAGES_CHILDS_ASSET}`} exact>
              <DiffusionListPages drawerWidth={drawerWidth} windowWidth={props.windowWidth} isContent={true}/>
            </Route>
            <Route path={`${ROUTE_DIFFUSION_PERMANENTS_INFOS_MANAGEMENTS_ASSET}`} exact>
              <EngineAssetsInfoManagements drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_DIFFUSION_PERMANENTS_SHOP_THE_LOOK}`} exact>
              <ShopTheLook drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_DIFFUSION_PERMANENTS_PAGE}`} exact>
              <EngineAssetsDetailsPage windowWidth={props.windowWidth}/>
            </Route>


            <Route path={`${ROUTE_CATALOGS}`} exact>
              <DashboardCatalogs drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_CATALOGS_LIST}`} exact>
              <ListCatalogs drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_CATALOGS_DETAIL}`} exact>
              <CatalogsDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>


            <Route path={`${ROUTE_MEDIAS}`} exact>
              <DashboardMedias windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_MEDIAS_CATEGORIES}`} exact>
              <MediasCategories />
            </Route>
            <Route path={`${ROUTE_MEDIAS_LIST}`} exact>
              <ListMedias windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_MEDIAS_TAGS}`} exact >
              <MediasTags windowWidth={props.windowWidth}/>
            </Route>

            <Route path={`${ROUTE_CONTENU}`} exact>
              <DashboardContents windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_CONTENU_CATEGORIES}`} exact>
              <ContentsCategories drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_CONTENU_LIST}`} exact>
              <ListContents windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_CONTENU_LIST_DETAIL}`} exact>
              <ContentDetail drawerWidth={drawerWidth} />
            </Route>
            <Route path={`${ROUTE_CONTENU_ADD}`} exact>
              <ContentAdd drawerWidth={drawerWidth} />
            </Route>
            <Route path={`${ROUTE_CONTENU_ATTRIBUTES}`} exact>
              <ContentsAttributes drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            
            <Route path={`${ROUTE_ORDERS_LIST}`} exact>
              <ListOrders windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_ORDERS_LIST_PREORDER}`} exact>
              <ListOrders windowWidth={props.windowWidth} noStatus={'preorder'}/>
            </Route>
            <Route path={`${ROUTE_ORDERS_LIST_DELAY}`} exact>
              <ListOrders windowWidth={props.windowWidth} noStatus={'delayed'}/>
            </Route>
            <Route path={`${ROUTE_ORDERS_LIST_RETURN}`} exact>
              <ListOrders windowWidth={props.windowWidth} noStatus={'returned'}/>
            </Route>
            <Route path={`${ROUTE_ORDERS_DETAIL}`} exact>
              <OrderDetails drawerWidth={drawerWidth} />
            </Route>

            <Route path={`${ROUTE_ATTRIBUTS}`} exact>
            
            </Route>
            <Route path={`${ROUTE_ATTRIBUTS_ATTRIBUTS}`} exact>
              <AttributesProducts drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeAttributs={'product'}/>
            </Route>
            <Route path={`${ROUTE_ATTRIBUTS_CATEGORIES}`} exact>
              <AttributesProducts drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeAttributs={'category'} noGroup={true}/>
            </Route>
            
            <Route path={`${ROUTE_SHELLS}`} exact>
            </Route>
            <Route path={`${ROUTE_SHELLS_LIST}`} exact>
              <ListShells drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_SHELLS_CUSTOM_ATTRIBUTES}`} exact>
              <AttributesProducts drawerWidth={drawerWidth} windowWidth={props.windowWidth} customAttributes={true} typeAttributs={customState?.alias} noGroup={true}/>
            </Route>
            <Route path={`${ROUTE_SHELLS_CUSTOM_LIST}`} exact>
              <ListShellsCustom drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </Route>
            <Route path={`${ROUTE_SHELLS_CUSTOM_DETAIL}`} exact>
              <ShellCustomDetail drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </Route>

            <Route path={`${ROUTE_ATTRIBUTS_POINT}`} exact>
              <AttributesProducts drawerWidth={drawerWidth} windowWidth={props.windowWidth} typeAttributs={'point'} noGroup={true} butInGroup={true}/>
            </Route>

            <Route path={`${ROUTE_PRODUCTS}`} exact>
              <DashboardProducts windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_PRODUCTS_CATEGORIES}`} exact>
              <ProductsCategories drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_PRODUCTS_ADD}`} exact>
              <ProductAdd drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_PRODUCTS_LIST}`} exact>
              <ListProducts windowWidth={props.windowWidth}/>
            </Route>
            <Route path={`${ROUTE_PRODUCTS_LIST_DETAIL}`} exact>
              <ProductDetail drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route>
            {/* <Route path={`${ROUTE_ATTRIBUTS_ATTRIBUTS}`} exact>
              <AttributesProducts drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route> */}
            <Route path={`${ROUTE_PRODUCTS_CONNECTORS}`} exact>
              <ProductsConnectors windowWidth={props.windowWidth}/>
            </Route>

            <Route path={`${ROUTE_PRODUCTS_STORELOCATOR}`} exact>
              <ListStorelocator windowWidth={props.windowWidth} drawerWidth={drawerWidth}/>
            </Route>
            <Route path={`${ROUTE_PRODUCTS_STORELOCATOR_DETAILS}`} exact>
              <StorelocatorDetail windowWidth={props.windowWidth} drawerWidth={drawerWidth}/>
            </Route>
            <Route path={`${ROUTE_PRODUCTS_COLLECTIONS}`} exact>
              <ListCollections windowWidth={props.windowWidth} drawerWidth={drawerWidth}/>
            </Route>
            <Route path={`${ROUTE_PRODUCTS_COMPLETUDE}`} exact>
              <ProductsCompletude windowWidth={props.windowWidth} drawerWidth={drawerWidth} />
            </Route>

            <Route path={`${ROUTE_CHANNELS}`} exact>
              <DashboardChannels drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </Route>
            <Route path={`${ROUTE_CHANNELS_RETAILERS}`}  exact>
              <ListRetailers windowWidth={props.windowWidth} drawerWidth={drawerWidth} isInternal={false} type="retailer" />
            </Route>
            <Route path={`${ROUTE_CHANNELS_BRANDS}`}  exact>
              <ListRetailers windowWidth={props.windowWidth} drawerWidth={drawerWidth} isInternal={false} type="brand" />
            </Route>
            <Route path={`${ROUTE_CHANNELS_MARKETPLACES}`} exact>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="marketplace" />
            </Route>
            <Route path={`${ROUTE_CHANNELS_SOCIALS}`} exact>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="social" />
            </Route>
            <Route path={`${ROUTE_CHANNELS_CMS}`} exact>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="cms" />
            </Route>
            <Route path={`${ROUTE_CHANNELS_MAILER}`} exact>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="newsletter" />
            </Route>
            <Route path={`${ROUTE_CHANNELS_CONTENTS}`} exact>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="content"  />
            </Route>
            <Route path={`${ROUTE_CHANNELS_MEDIAS}`} exact>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="media" />
            </Route>
            <Route path={`${ROUTE_CHANNELS_CONNECTORS}`} exact>
              <ListChannels windowWidth={props.windowWidth} drawerWidth={drawerWidth} typeChannel="connector" />
            </Route>


            <Route path={`${ROUTE_BRAND_GUIDELINE}`} exact>
              <GuidelineBrand drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
              {
                typoSystem.length > 0 ? (
                  <GoogleFontLoader fonts={makeArrayFonts()} /> 
                ) : null
              }
            </Route>
            <Route path={`${ROUTE_BRAND_GUIDELINE_ADD}`} exact>
              <GuidelineAdd drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
              {
                typoSystem.length > 0 ? (
                  <GoogleFontLoader fonts={makeArrayFonts()} /> 
                ) : null
              }
            </Route>
            <Route path={`${ROUTE_BRAND_GUIDELINE_UPDATE}`} exact>
              <GuidelineUpdate drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
              {
                typoSystem.length > 0 ? (
                  <GoogleFontLoader fonts={makeArrayFonts()} /> 
                ) : null
              }
            </Route>
            <Route path={`${ROUTE_BRAND_FLOW}`} exact>
              <ListRetailers windowWidth={props.windowWidth} drawerWidth={drawerWidth} isInternal={true} type="flow" />
            </Route>
            <Route path={`${ROUTE_BRAND_FLOW_DETAILS}`} exact>
              <RetailersDetails windowWidth={props.windowWidth} drawerWidth={drawerWidth} isInternal={true} type="flow" />
            </Route>

            <Route path={`${ROUTE_SETTINGS_LANGUAGES}`} exact>
              <SettingsLanguages windowWidth={props.windowWidth} />
            </Route>
            <Route path={`${ROUTE_SETTINGS_CURRENCIES}`} exact>
              <SettingsCurrencies windowWidth={props.windowWidth} />
            </Route>
            <Route path={`${ROUTE_SETTINGS_GROUPRATES}`} exact>
              <SettingsGroupRates windowWidth={props.windowWidth} />
            </Route>
            <Route path={`${ROUTE_SETTINGS_TAXRATES}`} exact>
              <SettingsTaxRates windowWidth={props.windowWidth} />
            </Route>
            <Route path={`${ROUTE_SETTINGS_USERS}`} exact>
              <SettingsUsers drawerWidth={drawerWidth} windowWidth={props.windowWidth} user={props.user} />
            </Route>
            <Route path={`${ROUTE_SETTINGS_DETAIL_USER}`} exact>
              <UserDetail drawerWidth={drawerWidth} user={props.user} />
            </Route>
            <Route path={`${ROUTE_SETTINGS_ACCESS}`} exact>
              <SettingsAccess />
            </Route>
            <Route path={`${ROUTE_SETTINGS_SUBSCRIPTIONS}`} exact>
              <SettingsSubscriptions />
            </Route>

            <Route path={`${ROUTE_PREFERENCES}`} exact>
              <SettingsPreferences />
            </Route>
            <Route path={`${ROUTE_IMPORT_HISTORY}`} exact>
              <ImportHistory used="page" />
            </Route>
            <Route path={`${ROUTE_HELPS}`} exact>
              <SettingsHelps />
            </Route>

            <Route path={`${ROUTE_SETTINGS}`}>
              <Typography variant="body1">
                Paramètres
              </Typography>
            </Route>


            <Route path={`${ROUTE_RESTAURANTS}`} exact>
              <ListRestaurants drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </Route>
            <Route path={`${ROUTE_RESTAURANTS_DETAILS}`} exact>
              <RestaurantDetails drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </Route>
            <Route path={`${ROUTE_RESTAURANTS_DEMANDS}`} exact>
              <ListDemands drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
            </Route>
          </Switch>
        </main>

      </div>
    );
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    setUser: user => dispatch({ type: SET_USER, payload: { user }}),
    setGuideline: guideline => dispatch({ type: SET_GUIDELINE, payload: { guideline }})
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading,
    user: state.user,
    guideline: state.guideline,
  };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));