import React from 'react';
import { connect } from "react-redux";
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';   
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../../js/constants/alert-types';
import SearchBarProductDynamic from '../../../../../components/ui/inputs/SearchBarProductDynamic';
import CardCustom from '../../../../layouts/Card/CardCustom';
import TypographyCustom from '../../../../ui/typography/Typography';
import Button from '../../../../ui/button/Button';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import videoLogo from '../../../../../assets/pictos/video.png';
import mediaPickerConfig from '../../../../../components/ui/form/config/mediaPicker.config'
import LayoutBuilder from '../../../../../components/ui/form/LayoutFormBuilder';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';



import { Grid, TextField } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';


const GridCustom = styled(Grid) `
    margin-bottom: 16px;
`;

const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
    font-weight: bold;
`

const ContainerFileUpload = styled.div`
    background-size: ${props => props.fullwidth ? 'cover' : 'contain' } !important;
    width: 100%;
    height: ${props => props.fullwidth ? '110px' : '192px' };
    position: relative;
    background-position: ${props => 'center'} !important;
`;
const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 16px;
        padding: 16px;
    }
    & > p{
        margin: 0;

    }
`;
const HiddenUploadFile = styled(TextFieldCustom)`
    display: none;
`;
const ClickToUpload = styled.label`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    &:after{
        content: '${props => props.textcontent}';
        position: absolute;
        margin: auto;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        font-size: 80px;
        color: white;
    }
`;

const LabelFab= styled.label`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const StyledFab = styled(Fab)`
    background-color: ${props => props.checkerror ? 'red' : colors.blue.regular};
`;

class GiftProductSelector extends React.Component {  
    state = {
        hover: false,
        file: null,
        uploading: false,
        errorMessage : null,
        seeError : false,
        openMediaPicker: false,
        rowVariantValue: null,
        openDialog : false,
    };

    componentDidMount() {
        // let productOfCat = []
        // for (let cat of this.props.allState.catalogAsset.node.categories.edges){
        //     if (cat.node.products?.edges.length > 0 ){
        //         productOfCat = productOfCat.concat(cat.node.products.edges)
        //     }
        // }
        let selectedProducts = _.cloneDeep(this.props.allState.giftInitial).concat(this.props.allState.giftSelected)
        // for (let product of this.props.allState.giftSelected){
        //     let newArray = {
        //         product: _.cloneDeep(product),
        //         giftImage: null
        //     }
        //     selectedProducts.push(newArray)
        // }
        this.setState({
            productForGift : selectedProducts
        });
        this.checkAlreadyWin(selectedProducts)
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.allState.giftSelected !== this.props.allState.giftSelected){
            this.setState({
                productForGift : _.cloneDeep(this.props.allState.giftSelected)
            });
        }
    }

    checkSelectedValues = (value) => {
        let newArray = []
        newArray = _.cloneDeep(this.props.allState.giftSelected)
        for (let i = 0; i < value.length; ++i ){
            let getImageDefault = value[i].productDatas.edges.find(e => e.node.attribute.identifier === "product_image")
            if (this.props.allState.giftSelected.length > 0){
                let checkExist = this.props.allState.giftSelected.find(e => e.product.id === value[i].id)
                if (!checkExist){
                    newArray.push(
                        {
                            product : value[i],
                            giftImage : getImageDefault.node.media,
                            idGift: uuidv4(),
                            new: true,
                            nbLink: 0,
                        })
                }
            }else{
                newArray.push({
                    product : value[i],
                    giftImage : getImageDefault.node.media,
                    idGift: uuidv4(),
                    new: true,
                    nbLink: 0,
                })
            }
        }
        this.props.stateCallback('giftSelected', newArray)
    };

    checkAlreadyWin = (rows) => {
        // let deleteInstants = []
        for (let row of rows){
            let getLinkedGift = this.props.allState.instantWinListInitial.filter(e => e.productLink === row.idGift)
            if (getLinkedGift){
                
                for (let link of getLinkedGift){
                    row.linked = true;
                    row.nbLink++;
                    if(link.isWin === true){
                        row.isWin = true;
                    }
                }
            }
        }
    }
    
    handleOpenMediaPicker = () => {
        this.setState({
            openMediaPicker : !this.state.openMediaPicker,
        });
    };

    handleToggleDialog = (row) => {
        this.setState({ 
            openDialog : !this.state.openDialog,
            selectedRow : row
        });
    };

    setRowVariantValue = (row) => {
        this.setState({
            rowVariantValue : row,
        });
    }

    error = (error) => {
        this.props.snack(ALERT_ERROR, error);
        this.setState({ hover: false });
    };

    render() {
        const { seeError, errorMessage } = this.state;
        const { stateCallback } = this.props;
        return (
            <div style={{ padding: 32, backgroundColor: 'rgb(250, 251, 251)' }}>
                <Grid container alignItems={"center"}>
                    <Grid item xs={4}>
                        Sélection des produits*
                    </Grid>
                    <Grid item xs={8}>
                        <SearchBarProductDynamic 
                            catalog={this.props.allState.catalogAsset.node}
                            isMulti={true}
                            currentLang={'fr_FR'}
                            style={{
                                position: 'relative',
                                top: 5,
                                marginBottom: 5
                            }}
                            onSelect={(value) => {
                                // setSeeError(true);
                                // checkError(value);
                                this.checkSelectedValues(value)
                            }}
                        />

                        {/* {
                            seeError && errorMessage ? null :
                            props.input.helper.link 
                                ? <LinkText variant="body2" onClick={() => goTo(props.input.helper.route)}>{props.input.helper.text}</LinkText> 
                                : <HelperText variant="body2">{props.input.helper.text}</HelperText>
                        } */}
                    </Grid>
                </Grid>
                <Grid container style={{marginTop: 12}}>
                {
                    this.state.productForGift?.map((row, i) => {
                        let getTitle = row.product.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name')
                        if (row.deleted)
                            return null;

                        return (
                            <Grid key={i} item xs={12} style={{ marginBottom: 15 }}>
                                <CardCustom paddingbottom="16px" paddingtop="16px">
                                    <Grid container direction={"column"}>
                                        <TypographyCustom variant="body2" colortext="#333333" style={{marginBottom: "18px"}}>Cadeau - #{row.deleted ? i-1 : i+1}</TypographyCustom>
                                        {row.isWin ? 
                                        <TypographyCustom variant="body2" colortext={colors.green.regular} style={{marginTop: "-10px", marginBottom: "8px"}}>Ce lot est déjà remporté, impossible de le supprimer ou de le modifier.</TypographyCustom>
                                        : null}
                                    </Grid>
                                    <Grid container direction="row" spacing={0} style={{position: 'relative'}}>
                                        <Grid item xs={5} style={{ paddingRight: 20, marginTop: 8 }}>
                                            <TypographyCustom variant="body2" colortext={colors.blue.lighter.hue300} style={{marginBottom: 8}}>Produit :</TypographyCustom>
                                            <TypographyCustom variant="h5" colortext="#333333">{getTitle.node.value}</TypographyCustom>
                                        </Grid>
                                        <Grid item xs={7} style={{ paddingRight: 20, marginTop: 8 }}>
                                            <TypographyCustom variant="body2" colortext={colors.blue.lighter.hue300} style={{marginBottom: 8}}>Image pour le cadeau :</TypographyCustom>
                                            <GridCustom item xs={12}>
                                                <HiddenUploadFile
                                                    id={'test' + i}
                                                    type="file"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}

                                                    // helperText={props.input.helper?.link ? <Box><LinkText variant="h5">{props.input.helper?.text}</LinkText></Box> : props.input.helper?.text}
                                                    hidden={true}
                                                />
                                                <ContainerFileUpload style={{
                                                    background: `url(${row.giftImage?.data ?
                                                                        ['video/mpeg','video/webm','video/mp4'].includes(row.giftImage.types) ? 
                                                                            videoLogo
                                                                        : process.env.REACT_APP_API_ROOT+'/medias/'+row.giftImage.filePath

                                                                : row.giftImage?.filePath 
                                                                    ? process.env.REACT_APP_API_ROOT+'/medias/'+row.giftImage.filePath : `${colors.grey.lighter.hue900}`
                                                    }) no-repeat center`,
                                                }}>
                                                    {!row.isWin ?
                                                        <ClickToUpload>
                                                            {
                                                                row.giftImage && row.giftImage?.filePath
                                                                    ?   <>
                                                                            <Fab color="primary" size="small"  onClick={()=>{this.handleOpenMediaPicker(); this.setRowVariantValue(row)}}>
                                                                                <LabelFab > 
                                                                                    <EditIcon  />
                                                                                </LabelFab>
                                                                            </Fab>
                                                                            <Fab color="primary" size="small" style={{ marginLeft: 5 }} onClick={()=>{
                                                                                    // row.image.value.id = props.input.defaultImage.id
                                                                                    // row.image.value.data = props.input.defaultImage
                                                                                    // row.image.value.filePath = props.input.defaultImage.filePath
                                                                                    // stateCallback(props.value)
                                                                                }}>
                                                                                <DeleteIcon style={{ color: 'red' }} />
                                                                            </Fab>
                                                                        </>
                                                                    :   <StyledFab color="primary" checkerror={seeError && errorMessage ? seeError : null} size="small">
                                                                            <LabelFab onClick={()=>{this.handleOpenMediaPicker(); this.setRowVariantValue(row)}}> 
                                                                                <AddIcon  />
                                                                            </LabelFab>
                                                                        </StyledFab>
                                                            }
                                                        </ClickToUpload>
                                                    : null
                                                    }
                                                </ContainerFileUpload>
                            
                                                {
                                                    !seeError ? null : 
                                                    <span className="error-label">{ errorMessage }</span>
                                                }

                                                <LayoutBuilder
                                                    opened              = {this.state.openMediaPicker}
                                                    dataLayout          = {mediaPickerConfig(
                                                        (value) => {
                                                            let getRow = this.state.productForGift.find(
                                                                e =>  e.product.id === this.state.rowVariantValue.product.id
                                                            )
                                                            getRow.giftImage = value;
                                                            getRow.updated = true;
                                                            stateCallback('giftSelected',this.state.productForGift);
                                                        },
                                                        'giftSelected',
                                                        null,
                                                        this.handleOpenMediaPicker,
                                                    )} 
                                                    forClose            = {() => {this.handleOpenMediaPicker()}}  
                                                />
                                            
                                            </GridCustom>
                                        </Grid>
                                        <Grid item xs={2} style={{
                                            position: 'absolute',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            right: '-20px',
                                            top: '50%',
                                            transform: 'translate(-50%)'
                                        }}> 
                                            <Tooltip 
                                                title={row.isWin ? "Lot déjà remporté" : "Supprimer" }
                                                style={{marginTop: "8px"}}
                                            >
                                                {row.linked ?
                                                    row.isWin ? 
                                                    <CheckCircleIcon style={{ color: colors.green.regular}} /> 
                                                    : <DeleteIcon 
                                                        style={{ color: '#FF6565', cursor: 'pointer' }}
                                                        onClick={() => {this.handleToggleDialog(row)}} 
                                                    />
                                                : <DeleteIcon 
                                                    style={{ color: '#FF6565', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        row.deleted = true;
                                                        stateCallback('giftSelected', this.state.productForGift.filter(
                                                            e =>  e.product.id !== row.product.id
                                                        ));
                                                    }} 
                                                />
                                                }
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </CardCustom>
                            </Grid>
                        );
                    })
                }
                <Dialog
                        open={this.state.openDialog}
                        onClose={this.handleToggleDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Êtes-vous sûr de vouloir supprimer ce cadeau ?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Si vous supprimez ce cadeau celui-ci ne sera plus accessible, ce cadeau est utilisé dans <SpanColor>{this.state.selectedRow ? this.state.selectedRow.nbLink : null} instant{this.state.selectedRow ? this.state.selectedRow.nbLink > 1 ? 's' : null : null} gagnant{this.state.selectedRow ? this.state.selectedRow.nbLink > 1 ? 's' : null : null}</SpanColor>. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                                Annuler
                            </Button>
                            <Button onClick={() => {
                                    this.handleToggleDialog();
                                    stateCallback('giftSelected', this.state.productForGift.filter(
                                        e =>  e.product.id !== this.state.selectedRow.product.id
                                    ));
                                }} 
                                bgcolor={colors.red.regular} 
                                bgcolorhover={colors.red.darker} 
                                autoFocus
                            >
                            Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default connect(null, mapDispatchToProps)(GiftProductSelector);