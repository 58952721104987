import React, { useEffect, useRef, useState } from "react";
import Swiper from 'swiper';
import H2 from '../shared/h2';
import Div from '../shared/div';
import Img from '../shared/img';

const SliderImage = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;
    
    useEffect(() => {
        const swiper = new Swiper('.swiper-container-image-carre', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-image',
            },
            navigation: {
                nextEl: '.swiper-button-next-image',
                prevEl: '.swiper-button-prev-image',
            },
            spaceBetween: 10,
            breakpoints: {
                300:{
                    slidesPerView: 1,
                },
                700:{
                    slidesPerView: 2,
                },
                1100:{
                    slidesPerView: 3.2,
                },
            }
        });
        const swiper2 = new Swiper('.swiper-container-image-horizontal', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-image',
            },
            navigation: {
                nextEl: '.swiper-button-next-image',
                prevEl: '.swiper-button-prev-image',
            },
            spaceBetween: 10,
            slidesPerView: 1.3,
        });           
        const swiper3 = new Swiper('.swiper-container-image-fullscreen', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-image',
            },
            navigation: {
                nextEl: '.swiper-button-next-image',
                prevEl: '.swiper-button-prev-image',
            },
            spaceBetween: 10,
            slidesPerView: 1,
        });
    }, []);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-image-carre', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-image',
            },
            navigation: {
                nextEl: '.swiper-button-next-image',
                prevEl: '.swiper-button-prev-image',
            },
            spaceBetween: 10,
            breakpoints: {
                300:{
                    slidesPerView: 1,
                },
                700:{
                    slidesPerView: 2,
                },
                1100:{
                    slidesPerView: 3.2,
                },
            }
        });
        const swiper2 = new Swiper('.swiper-container-image-horizontal', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-image',
            },
            navigation: {
                nextEl: '.swiper-button-next-image',
                prevEl: '.swiper-button-prev-image',
            },
            spaceBetween: 10,
            slidesPerView: 1.3,
        });           
        const swiper3 = new Swiper('.swiper-container-image-fullscreen', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-image',
            },
            navigation: {
                nextEl: '.swiper-button-next-image',
                prevEl: '.swiper-button-prev-image',
            },
            spaceBetween: 10,
            slidesPerView: 1,
        });
    }, [props]);

    return (
        <Div  style={{ position: 'relative', background: data.blocks.background.inputs.backgroundColor.value }} className="slider-image" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className="title-box">
                <H2 className="w100 uppercase">{data.blocks.title.inputs.value.value}</H2>
                <a href={data.blocks.link.inputs.link.value}>{data.blocks.link.inputs.value.value}</a>
            </Div>
            <Div className={`swiper-container swiper-container-image-${data.inputs.formatSlider.value}`} style={{width: data.inputs.formatSlider.value === 'fullscreen' ? '100%' : null, marginLeft: data.inputs.formatSlider.value === 'fullscreen' ? 0 : null}}>
                <div className="swiper-wrapper">
                    {
                        data.blocks.slides.config.map((slide, index) => (
                            <Div className="swiper-slide w100">
                                <Img 
                                    src={
                                        typeof slide.blocks.imagePrincipale?.inputs.value.value === 'string' ? 
                                            slide.blocks.imagePrincipale?.inputs.value.value 
                                        : slide.blocks.imagePrincipale?.inputs.value.value.filePath ? 
                                            `${process.env.REACT_APP_MEDIAS}/${slide.blocks.imagePrincipale?.inputs.value.value.filePath}` 
                                        : null
                                    }
                                    alt={data.blocks.title.inputs.value.value}
                                />
                            </Div>
                        ))
                    }
                </div>
                <div className="swiper-scrollbar swiper-scrollbar-image"></div>
                <div className="swiper-button-prev swiper-button-prev-image"></div>
                <div className="swiper-button-next swiper-button-next-image"></div>
            </Div>
        </Div>
    );
};

export default SliderImage;