import React from 'react';
import { withRouter } from "react-router";
import { ROUTE_FORGET_PASSWORD } from '../../../js/constants/route-names';
import { connect } from "react-redux";
import styled from 'styled-components';
import * as moment from 'moment';
import {
  // Card, 
  // CardContent, 
  Grid,
  Container,
  Box,
  // Checkbox, 
  // FormControlLabel, 
} from '@material-ui/core';
import Typography from '../../ui/typography/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { withApollo } from 'react-apollo';

import { GET_LOCALES } from '../../../queries/locales';
import { GET_EAV_TYPES, GET_ATTRIBUTES_BY_TYPE } from '../../../queries/attributes';
import { GET_USERS } from '../../../queries/users';
import { GET_BRAND_GUIDELINE } from '../../../queries/brand_guideline';
import { START_LOADING, STOP_LOADING, SET_USER, SNACK, SET_LOCALES, SET_ATTRIBUTES, SET_GUIDELINE } from '../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../js/constants/alert-types';

import request from '../../../js/utils/fetch';
import ButtonStyled from "../../ui/button/Button";
import logo from '../../../assets/images/logo.png';
import FormBuilder from '../../ui/form/FormBuilder';
import colors from '../../../config/theme/colors';

// import LegoLogo from '../../../assets/images/logo_lego.svg';
// import SinfinLogo from '../../../assets/images/logo_sinfin.png';

import './Login.scss';

const inputsLogin = [
  {
    type: 'text',
    email: true,
    noSpecificValidation: true,
    label: 'Adresse e-mail',
    helper: {
      text: '',
      link: false
    },
    required: true,
    stateName: 'email',
    disabled: false,
  },
  {
    type: 'password',
    noSpecificValidation: true,
    label: 'Mot de passe',
    helper: {
      text: 'Mot de passe oublié',
      link: true,
      route: ROUTE_FORGET_PASSWORD,
    },
    helperLink: true,
    required: true,
    stateName: 'password',
    disabled: false,
  },
]

const ContainerLogin = styled(Grid)`
  // top: 48%;
  // position: relative;
  // transform: translateY(-50%);
`
const ContainerCustom = styled(Container)`
  background: #fff;
  padding: 4rem 2rem 2rem 2rem !important;
  position: relative;
  left: auto;
  margin: auto;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
`

const ButtonCustom = styled(ButtonStyled)`
  margin:auto;
`
// const CheckboxCustom = styled(Grid)`
//   margin-top: -40px;
// `

// const CheckboxStyled = styled(Checkbox)`
//   border-radius: 5px;
// `
// const SpanColor = styled.span`
//     color: ${colors.blue.lighter.hue300};
//     font-weight: bold;
// `
// const GridCustom = styled(Grid)`
//     margin-top: 0.5rem;
// `
// const LinkText = styled.a`
//     color: ${colors.blue.lighter.hue300} !important;
//     cursor: pointer;
//     text-decoration: underline;
//     font-size: 14px;
// `;
// const CardCustom = styled(Card)`
//   box-shadow: 0px 0px 5px #1622421A;
//   border-radius: 5px;
//   cursor:pointer;
// `;
// const CardContentStyled = styled(CardContent)`
//   padding: 1rem !important;
// `;
// const TypographyCustom = styled(Typography)`
//     color: ${colors.blue.lighter.hue300} !important;
//     font-weight: normal;
// `;
// const WorkspaceName = styled(Typography)`
//     font-weight: bold;
// `;

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      checked: false,
      open: false,
      requesting: false,
      firstname: '',
      lastname: '',
      id: ''
    }
  }

  handleCheck = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  handleInputChange = (stateName, evt) => {
    const value = evt?.target?.value ?? evt;

    this.setState({
      ...this.state,
      [stateName]: value
    });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onSubmit()
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  onSubmit = () => {
    // debugger
    const isCRM = process.env.REACT_APP_MODE_CRM === 'true'
    if (this.state.requesting)
      return false;

    if (!this.state.email || !this.state.password)
      return this.props.snack(ALERT_ERROR, 'Veuillez remplir tous les champs');

    this.props.startLoading();

    this.setState({
      requesting: true
    }, () => {
      request(`${process.env.REACT_APP_API}/authentication-token`, 'post', {
        email: this.state.email,
        password: this.state.password
      }).then(async (data) => {
        localStorage.setItem('AUTH_TOKEN', data.token);
        localStorage.setItem('AUTH_TOKEN_EXPIRY', moment().add(5, 'hours').toISOString());

        let dataTypes = await this.props.client.query({ query: GET_EAV_TYPES, fetchPolicy: 'no-cache' });
        let dataLocales = await this.props.client.query({ query: GET_LOCALES, fetchPolicy: 'no-cache' });
        let dataGuideline = await this.props.client.query({ query: GET_BRAND_GUIDELINE, fetchPolicy: 'no-cache' });

        let categoryTypes = [];
        let typesToFetch = ['product', 'category', 'content'];
        
        if (isCRM)
          typesToFetch.push('company', 'customer')

        for (let type of dataTypes.data.eavTypes.edges) {
          if (~typesToFetch.indexOf(type.node.code)) {
            let typeData = await this.props.client.query({ query: GET_ATTRIBUTES_BY_TYPE, variables: { id: type.node.id }, fetchPolicy: 'no-cache' });
            categoryTypes.push(typeData.data.eavType);
          }
        }

        let attributes = {
          eavTypes: dataTypes.data.eavTypes.edges,
          category: categoryTypes.find(e => e.code === 'category'),
          product: categoryTypes.find(e => e.code === 'product'),
          content: categoryTypes.find(e => e.code === 'content'),
          company: categoryTypes.find(e => e.code === 'company'),
          customer: categoryTypes.find(e => e.code === 'customer'),
        };

        this.props.setAttributes(attributes);
        this.props.setLocales(dataLocales.data.locales.edges);

        localStorage.setItem('LOCALES', JSON.stringify(dataLocales.data.locales.edges));
        localStorage.setItem('ATTRIBUTES', JSON.stringify(attributes));

        if (dataGuideline.data.guidelines.edges.length) {
          this.props.setGuideline(dataGuideline.data.guidelines.edges[0].node);
          localStorage.setItem('GUIDELINE', JSON.stringify(dataGuideline.data.guidelines.edges[0].node));
        }

        let dataUsers = await this.props.client.query({ query: GET_USERS, fetchPolicy: 'no-cache' });

        for (let user of dataUsers.data.users.edges) {
          if (user.node.email === this.state.email) {
            let getUser = user.node;
            let idUserRole = user.node.userRole.id.replace('/api/user-roles/', '')
            request(`${process.env.REACT_APP_API}/users/acl/${idUserRole}`, 'get', false).then(
              (data) => {
                if (data.success) {
                  getUser.rights = data.acl
                }
                localStorage.setItem('AUTH_USER', JSON.stringify(getUser));
                this.props.setUser(getUser);
              }
            )
          }
        }

        this.props.stopLoading();
        this.props.snack(ALERT_SUCCESS, 'Bienvenue dans votre espace !');
      }).catch(error => {
        console.error(error);

        if (error?.status && error?.status === 401) {
          // API ERROR
          this.setState({ requesting: false });
          this.props.stopLoading();
          this.props.snack(ALERT_ERROR, `Veuillez vérifier vos identifiants de connexion`);
        } else if (error?.status && error?.status === 429) {
          // TOO MUCH FAILS
          this.setState({ requesting: false });
          this.props.stopLoading();
          this.props.snack(ALERT_ERROR, `Trop de tentatives infructueuses`);
        } else {
          // GQL ERROR
          this.setState({ requesting: false });
          this.props.stopLoading();
          this.props.snack(ALERT_ERROR, `Une erreur est survenue...`);
        }
      });
    });
  };

  render() {
    return (
      <Grid container justify={"center"} className={"login"}>
        <AppBar
          position="fixed"
          color={"primary"}
          className={"appBar"}
        >
          <Toolbar className={"toolbar"}>
            <img className={"logoSpread"} src={logo} height="25px" width="225px" alt="logo-spread" />
          </Toolbar>
        </AppBar>
        <ContainerLogin item xs={8}>
          <ContainerCustom maxWidth={"sm"}>
            <Grid container justify="center">
              <img src={logo} alt="logo-spread" style={{maxWidth: '100%', maxHeight: 48}}/>
              <Box mt={6} textAlign={'center'} style={{ maxWidth: '100%' }}>
                <Typography variant={'h3'}>Connectez-vous à votre espace de travail</Typography>
                {/* <Typography variant={'body1'} style={{marginTop: '0.5rem'}}>Saisissez l'URL <SpanColor>Spreadsuite</SpanColor> de votre espace de travail.</Typography> */}
              </Box>
              <FormBuilder optionsInputs={inputsLogin} stateCallback={this.handleInputChange} allState={this.state} backgroundColor={'white'} />
              {/* <CheckboxCustom container>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={9}>
                <FormControlLabel
                  control={
                    <CheckboxStyled
                      checked={this.state.checked}
                      onChange={this.handleCheck}
                      value="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}

                    />
                  }
                  label="Se souvenir de moi"
                />
              </Grid>
            </CheckboxCustom> */}
              <ButtonCustom bgColor={colors.green.regular} text="Se connecter" className="button" onClick={this.onSubmit} style={{ marginTop: 0, top: -30 }} />
            </Grid>
          </ContainerCustom>
          {/* <Box mt={2} mb={4}>
          <Typography variant="body2">Vous ne connaissez pas l’URL de votre espace de travail ? <LinkText variant="body2">Trouver votre espace de travail</LinkText></Typography>
        </Box>
        <Box mb={4} textAlign={"center"}>
          <TypographyCustom variant="h3">Vous êtes déjà connecté(e) à cet espace de travail :</TypographyCustom>
        </Box>
        <GridCustom container justify={"space-between"}>
            <Grid item xs={6}>
              <Box mr={2}>
                <CardCustom>
                  <CardContentStyled>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={4}>
                        <Box mr={2}>
                          <img src={LegoLogo} width={"100%"}/>
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container justify={"space-between"} alignItems={"center"}>
                          <WorkspaceName variant={"h4"}>LEGO®</WorkspaceName>
                          <ArrowForwardIosRoundedIcon fontSize="small"  />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContentStyled>
                </CardCustom>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box ml={2}>
                <CardCustom>
                  <CardContentStyled>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={4}>
                        <Box mr={2}>
                          <img src={SinfinLogo} width={"100%"}/>
                        </Box>  
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container justify={"space-between"} alignItems={"center"}>
                          <WorkspaceName variant={"h4"}>Sinfin</WorkspaceName>
                          <ArrowForwardIosRoundedIcon fontSize="small"  />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContentStyled>
                </CardCustom>
              </Box>
            </Grid>
        </GridCustom> */}
        </ContainerLogin>
      </Grid>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    setUser: (user) => dispatch({ type: SET_USER, payload: { user } }),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
    setLocales: (locales) => dispatch({ type: SET_LOCALES, payload: { locales } }),
    setGuideline: (guideline) => dispatch({ type: SET_GUIDELINE, payload: { guideline } }),
    setAttributes: (attributes) => dispatch({ type: SET_ATTRIBUTES, payload: { attributes } }),
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading
  };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Login)));
