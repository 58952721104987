import styled from 'styled-components';

export default styled.h1`
    margin: 0;
    color: ${props => props.color || '#000'};
    font-size: 32px;
    line-height: 41px;
    font-family: 'DINOffc-Bold',sans-serif;
    text-transform: uppercase;
    &.landing-title{
        padding-bottom: 20px;
    }
    @media screen and (min-width: 1024px){
        font-size: 41px;
        line-height: 73px;
        &.landing-title{
            padding-bottom: 30px;
        }
    }
`; 