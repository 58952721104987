import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlideMoments, getDefaultTextCms } from '../../helpersCms';

export default {
    name: 'Slider image',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Histoire", null),
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_MOMENTS,
            buttonAdd: 'une slide',
            limit: 10,
            config: [ 
                getDefaultSlideMoments(),
                getDefaultSlideMoments(),
                getDefaultSlideMoments(),
            ]
        }
    }
};