import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { ItemTypes } from '../../../../shareable/types';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import AppsIcon from '@material-ui/icons/Apps';
import ImageIcon from '@material-ui/icons/Image';
import SearchIcon from '@material-ui/icons/Search';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import LayersIcon from '@material-ui/icons/Layers';
import LinkIcon from '@material-ui/icons/Link';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CategoryIcon from '@material-ui/icons/Category';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import colors from '../../../../../config/theme/colors'; 

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 10,
        width: '100%',
        height: 'auto',
        cursor: 'move',
        flexDirection: 'row',
        paddingTop: 10,
        borderRadius: 4,
        boxShadow: '0px 0px 14px #00000012'
    },
}));

export default function Draggable(props) {
    let {type} = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [{isDragging}, drag] = useDrag({
        item: { type },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });


    const getTypeName = type => {
        switch(type) {
            // MINISITE
            case ItemTypes.SLIDER: return "Slider";
            case ItemTypes.SLIDER_MULTIPLE: return "Slider multiple";
            case ItemTypes.SLIDER_WITH_RIGHT_IMAGES: return "Slider avec images";
            case ItemTypes.TAB_PRODUCT: return "Produits";
            case ItemTypes.IMAGE_ROW: return "Images Instagram";
            case ItemTypes.SECTION_TITLE: return "Section de titre";
            case ItemTypes.THREE_IMAGES_GRID: return "Grille d'images";
            case ItemTypes.THREE_IMAGES_ROW: return "Bandeau d'images";
            case ItemTypes.BLOC_TEXT: return "Bloc de texte";
            case ItemTypes.GIFT_FINDER: return "Gift finder";
            case ItemTypes.INFORMATION_BANNER: return "Bandeau d'information";
            case ItemTypes.LINK_BANNER: return "Bandeau lien";
            case ItemTypes.CATEGORY: return "Catégorie";
            case ItemTypes.TEXT_IMAGE: return "Texte image";
            case ItemTypes.SLIDER_PRODUCT: return "Slider produit";
            case ItemTypes.TEXT_VIDEO: return "Texte vidéo";
            // OFFER
            case ItemTypes.OFFER_FRONT_PAGE: return "Page de garde";
            case ItemTypes.OFFER_SECTION_TITLE: return "Section de titre";
            case ItemTypes.OFFER_ABOUT_SLIDE: return "À propos";
            case ItemTypes.OFFER_TRIPLE_BLOCK_LIST: return "Équipe";
            case ItemTypes.OFFER_TRIPLE_BLOCK: return "Division de 3 blocs";
            case ItemTypes.OFFER_TRIPLE_BLOCK_TEXT: return "Division de 3 textes";
            case ItemTypes.OFFER_TEXT_WITH_IMAGE: return "Référence client";
            case ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE: return "Texte et image";
            case ItemTypes.OFFER_DEVIS: return "Devis";
            // GAME
            case ItemTypes.PRE_HEADER_GAME: return "Pre-Header";
            case ItemTypes.HOME_GAME: return "Homepage";
            case ItemTypes.HOME_COMPLETE_FORM_GAME: return "Homepage avec formulaire";
            case ItemTypes.EXPLANATIONS_GAME: return "Explications";
            case ItemTypes.EXPLANATIONS_ACV_GAME: return "Section d'explications ACV";
            case ItemTypes.EXPLANATIONS_BF_GAME: return "Section d'explications BF";
            case ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME: return "Section d'explications complète";
            case ItemTypes.BASIC_BLOC: return "Bloc de présentation";
            case ItemTypes.BASIC_BLOC2: return "Bloc de présentation 2";
            case ItemTypes.REDIRECT_ON_GAME: return "Bloc de Redirection";
            case ItemTypes.REDIRECT_ON_GAME2: return "Bloc de Redirection 2";
            case ItemTypes.REDIRECT_ON_GAME3: return "Bloc de Redirection 2";
            case ItemTypes.REDIRECT_ON_GAME4: return "Bloc de Redirection 3";
            // AMAZING GAME BLOC
            case ItemTypes.AMAZING_GAME: return "Bloc à compléter";
            case ItemTypes.TITLE_GAME: return "Titre";
            case ItemTypes.PARAGRAPH_GAME: return "Paragraphe";
            case ItemTypes.RULES_AMAZING_GAME: return "Règles";
            case ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME: return "Règles en ligne";
            case ItemTypes.DOTATIONS_ONE_IMAGE: return "Dotations en une image";
            case ItemTypes.BUTTON_GAME: return "Boutton";
            //CMS BLOC
            case ItemTypes.CMS_SLIDER: return "Slider Top";
            case ItemTypes.CMS_SLIDER_ABOUT: return "Slider à propos";
            case ItemTypes.CMS_LIST_CATEGORIES: return "Liste catégories";
            case ItemTypes.CMS_SLIDER_BY_TAB: return "Slider par catégorie";
            case ItemTypes.CMS_SLIDER_INSTAGRAM: return "Slider Instagram";
            case ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA: return "Slider Présentation médias";
            case ItemTypes.CMS_SLIDER_MOMENTS: return "Slider Moments";
            case ItemTypes.CMS_SLIDER_IMAGE: return "Slider Image";
            case ItemTypes.CMS_GRID_IMAGE: return "Grille d'image";
            case ItemTypes.CMS_TEXT_MEDIA: return "Bloc de texte et son media";
            case ItemTypes.CMS_BANNER_MEDIA: return "Bannière media";
            case ItemTypes.CMS_SECTION_DESCRIPTION_PAGE: return "Section de description de la page";
            case ItemTypes.CMS_SECTION_MANIFEST_BLOCK: return "Section du bloc manifeste";
            case ItemTypes.CMS_CATEGORIES_HOME: return "Catégories Multiple";
            case ItemTypes.CMS_COLLECTIONS_HOME: return "Collections Multiple";
            case ItemTypes.CMS_SLIDER_PRODUCTS_HOME: return "Slider de produits";
            case ItemTypes.CMS_BLOC_STORELOCATOR_HOME: return "Bloc storelocator";
            case ItemTypes.CMS_ENTETE: return "Bloc entete";
            case ItemTypes.CMS_TOP_IMAGE_FULLSCREEN: return "Bloc Top image";
            case ItemTypes.CMS_SLOGAN: return "Bloc Slogan";
            case ItemTypes.CMS_TITLE_TEXT: return "Bloc Titre et Texte";
            case ItemTypes.CMS_SEPARATOR: return "Séparateur";
            default: return "n/a";
        }
    }

    const getTypeIcon = (type, fontSize) => {
        switch(type) {
            case ItemTypes.SLIDER: return <ViewCarouselIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.SLIDER_MULTIPLE: return <ViewCarouselIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.SLIDER_WITH_RIGHT_IMAGES: return <ViewCarouselIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.TAB_PRODUCT: return <AppsIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.IMAGE_ROW: return <ViewColumnIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.SECTION_TITLE: return <SubtitlesIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.THREE_IMAGES_GRID: return <ViewCompactIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.THREE_IMAGES_ROW: return <ViewColumnIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.BLOC_TEXT: return <SubtitlesIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.IMAGE: return <ImageIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.TEXT: return <TextFieldsIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.GIFT_FINDER: return <SearchIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.INFORMATION_BANNER: return <SubtitlesIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.LINK_BANNER: return <LinkIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.CATEGORY: return <CategoryIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.TEXT_IMAGE: return <FormatIndentIncreaseIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.TEXT_VIDEO: return <FormatIndentIncreaseIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            case ItemTypes.SLIDER_PRODUCT: return <ViewCarouselIcon style={{ fontSize, color: colors.white, background: colors.blue.lighter.hue600, borderRadius: '50%', padding: 6, marginLeft: 10 }} />;
            default: return null;
        }
    }

    return (
        <Paper elevation={3} className={classes.root} ref={drag} style={{opacity: 1, border: isDragging ? `1px dashed ${colors.blue.regular}` : '1px solid white',position: 'relative',paddingBottom: props.children && open ? 0 : 10}}>
            {
                props.children && !isDragging ?
                (
                    <div style={{position: 'absolute', top: 10, right: 10, display: 'flex', cursor: 'pointer'}} onClick={() => setOpen(!open)}>
                        <LayersIcon style={{fill: colors.blue.lighter.hue150}} /> <span style={{color: colors.blue.lighter.hue150, fontWeight: 'bold'}}>{props.children.length ? props.children.length : 1}</span>
                    </div>
                ) : (
                    <div style={{position: 'absolute', top: 10, right: 10, display: 'flex'}}>
                        <MoreVertIcon style={{fill: colors.blue.lighter.hue600}} />
                    </div>
                )
            }

            { 
                isDragging ? 
                    null 
                :   getTypeIcon(type, 24) 
            }

            <span style={{ fontSize: 14, fontWeight: 'bold', opacity: isDragging ? 0 : 1, padding: '0 10px', maxWidth: '80%' }}>{ getTypeName(type) }</span>

            {
                props.children && open ? (
                    <div style={{padding: open ? '10px 0 0' : '0 20px', width: 'calc(100% + 80px)', display: 'block', overflow: 'hidden', height: open ? 'auto' : 0}}>
                        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', overflow: 'auto', padding: 10, width: 'calc(100% - 20px)'}}>
                            {props.children}
                        </div>
                    </div>
                ) : null
            }
        </Paper>
    );
}