import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../shareable/types';
import * as helpers from './helpers';



export const getDefaultLinkCms = (name, value, link, color = null, backgroundColor = null, border = false) => {
    return{
        type: EditableTypes.SHORT_TEXT,
        name,
        id: uuidv4(),
        key: 'button',
        inputs: {
            value: {
                type: FormInputTypes.TEXT,
                value
            },
            link: {
                label: 'Lien',
                type: FormInputTypes.LINK,
                value: link
            },
            color: {
                type: FormInputTypes.COLOR,
                value: color,
                label: "Couleur du texte"
            },
            backgroundColor: {
                type: FormInputTypes.COLOR,
                value: backgroundColor,
                label: "Couleur de fond"
            },
            border: border ? {
                type: FormInputTypes.GROUP,
                label: "Border",
                inputs: {
                    borderColor: {
                        type: FormInputTypes.COLOR,
                        value: '#000',
                        label: "Couleur de la bordure"
                    },
                    borderWidth: {
                        type: FormInputTypes.SLIDER,
                        value: 0,
                        label: "Largeur de la bordure :",
                        params: {max: 5, min: 0, step: 1}
                    },
                }
            } : null,
        }
    }
};

export const getDefaultBackgroundCms = ( backgroundColor = null) => {
    return{
        type: EditableTypes.SHORT_TEXT,
        name: 'Couleur de fond',
        id: uuidv4(),
        key: 'color',
        inputs: {
            backgroundColor: {
                type: FormInputTypes.COLOR,
                value: backgroundColor,
                label: "Couleur de fond"
            },
        }
    }
};

export const getDefaultTextCms = (name, value, color = null) => {
    let inputs = {
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
    };
    
    let optional = {};

    if (value !== null) {
        optional.value = {
            type: FormInputTypes.TEXT,
            label: "Changer le texte",
            value
        };
    }

    inputs = { ...optional, ...inputs };
    
    return {
        type: EditableTypes.SHORT_TEXT,
        name,
        id: uuidv4(),
        inputs,
    };
};

export const getDefaultTextareaCms = (name, value, html, color = null, backgroundColor = null, textTransform = null, textAlign = null, size = 14) => {
    let inputs = {
        value: {
            type: html ? FormInputTypes.WYSIWYG : FormInputTypes.TEXTAREA,
            value
        }
    };

    if (!html) {
        inputs.size = {
            type: FormInputTypes.SLIDER,
            value: size,
            label: "Taille du texte",
            params: {max: 100, min: 8, step: 2},
        };
    }
    
    inputs = {
        ...inputs,
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
        backgroundColor: {
            type: FormInputTypes.COLOR,
            value: backgroundColor,
            label: "Couleur de fond"
        },
        textTransform: {
            type: FormInputTypes.SELECT,
            label: "Transformation du texte",
            value: textTransform,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Tout Majuscule', value: 'uppercase'}, {label: 'Tout Minuscule', value: 'lowercase'}]
        },
        textAlign: {
            type: FormInputTypes.SELECT,
            label: "Alignement du texte",
            value: textAlign,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Centré', value: 'center'}, {label: 'Droite', value: 'end'}]
        }
    }

    return {
        type: EditableTypes.LONG_TEXT,
        name,
        id: uuidv4(),
        inputs
    }
};

export const getDefaultButtonCms = (name, value, color, backgroundColor = null, textTransform = null) => ({
    type: EditableTypes.SHORT_TEXT,
    name,
    id: uuidv4(),
    key: 'button',
    inputs: {
        value: {
            type: FormInputTypes.TEXT,
            value
        },
        textTransform: {
            type: FormInputTypes.SELECT,
            label: "Transformation du texte",
            value: textTransform,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Tout Majuscule', value: 'uppercase'}, {label: 'Tout Minuscule', value: 'lowercase'}]
        },
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
        backgroundColor: {
            type: FormInputTypes.COLOR,
            value: backgroundColor,
            label: "Couleur de fond"
        },
    },
});

export const getDefaultSlideTop = (img = "https://via.placeholder.com/1920x840", imgMobile = "https://via.placeholder.com/780x1088", subtitle = "Sous-titre", title = "Titre",textLink = "Découvrir",link = "https://www.lecoqsportif.com/fr-fr/") => ({
    id: uuidv4(),
    blocks: {
        imageDesktop: {
            type: EditableTypes.IMAGE,
            name: "Image Desktop",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        imageMobile: {
            type: EditableTypes.IMAGE,
            name: "Image Mobile",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imgMobile
                }
            }
        },
        title: getDefaultTextCms("Titre", title, '#FFFFFF'),
        subtitle: getDefaultTextCms("Sous-titre", subtitle, '#FFFFFF'),
        link: getDefaultLinkCms("Lien", textLink, link, '#FFFFFF', '#000000')
    }
});

export const getDefaultSlideAbout = ( img = "https://via.placeholder.com/590x700", title = "Titre", text = 'Texte', textLink = "En savoir plus", link = "https://www.lecoqsportif.com/fr-fr/") => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        title: getDefaultTextCms("Titre", title, null),
        text: getDefaultTextCms("Texte", text, null),
        link: getDefaultLinkCms("Lien", textLink, link, null, null)
    }
});

export const getDefaultListCategories = (title = "Titre") => ({
    id: uuidv4(),
    blocks: {
        title: getDefaultTextCms("Titre", title, null),
        
        subItem: {
            title: "Sous-items",
            name: "Sous-items",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_LIST_CATEGORIES_SUBITEM,
            buttonAdd: 'un sous item',
            limit: 10,
            config: [ 
                getDefaultListCategoriesSubitem(),
            ]
        }
    }
});

export const getDefaultListCategoriesSubitem = ( img = "https://via.placeholder.com/400x600", title = "Prénom", title2 = "Nom", text = 'Texte', textLink = "En savoir plus", link = "https://www.lecoqsportif.com/fr-fr/") => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        title2: getDefaultTextCms("Titre", title2, null),
        title: getDefaultTextCms("Titre", title, null),
        text: getDefaultTextCms("Description", text, null),
        hashtag: getDefaultTextCms("#MotClé", text, null),
    }
});



export const getDefaultTabForSlider = ( img = "https://via.placeholder.com/400x600", title = "Titre", text = 'Texte', textLink = "En savoir plus", link = "https://www.lecoqsportif.com/fr-fr/") => ({
    id: uuidv4(),
    blocks: {
        title: getDefaultTextCms("Titre", title, null),
        description: getDefaultTextareaCms("Texte", "Description de la catégorie", true, '#53839D'),
        subItem: {
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_BY_TAB_SUBITEM,
            buttonAdd: 'une slide',
            limit: 10,
            config: [ 
                getDefaultSlideByTab(),
            ]
        }
    }
});

export const getDefaultSlideByTab = ( img = "https://via.placeholder.com/700x400") => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        }
    }
});

export const getDefaultSlideInstagram = (imagePrincipale = "https://via.placeholder.com/430x430", imageThumbnail1 = "https://via.placeholder.com/136x136",  imageThumbnail2 = "https://via.placeholder.com/136x136",  imageThumbnail3 = "https://via.placeholder.com/136x136") => ({
    id: uuidv4(),
    blocks: {
        imagePrincipale: {
            type: EditableTypes.IMAGE,
            name: "Image Principale",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imagePrincipale
                }
            }
        },
        imageThumbnail1: {
            type: EditableTypes.IMAGE,
            name: "Vignette 1",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imageThumbnail1
                }
            }
        },
        imageThumbnail2: {
            type: EditableTypes.IMAGE,
            name: "Vignette 2",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imageThumbnail2
                }
            }
        },
        imageThumbnail3: {
            type: EditableTypes.IMAGE,
            name: "Vignette 3",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imageThumbnail3
                }
            }
        },
    }
});

export const getDefaultSlideImage = (imagePrincipale = "https://via.placeholder.com/580x580") => ({
    id: uuidv4(),
    blocks: {
        imagePrincipale: {
            type: EditableTypes.IMAGE,
            name: "Image Principale",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imagePrincipale
                }
            }
        },
    }
});

export const getDefaultSlidePresentationMedia = (title = "Titre", text="Description", imagePrincipale = "https://via.placeholder.com/200x250") => ({
    id: uuidv4(),
    blocks: {
        title: getDefaultTextCms("Titre", title, null),
        description: getDefaultTextCms("Description", text, null),
        imagePrincipale: {
            type: EditableTypes.IMAGE,
            name: "Image Principale",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imagePrincipale
                }
            }
        },
    }
});

export const getDefaultSlideMoments = (title = "Titre", subtitle = "Sous-titre", text="Description", imagePrincipale = "https://via.placeholder.com/1400x1000", tag = "#tag") => ({
    id: uuidv4(),
    blocks: {
        title: getDefaultTextCms("Titre", title, null),
        subtitle: getDefaultTextCms("Sous-Titre", subtitle, null),
        description: getDefaultTextCms("Description", text, null),
        imagePrincipale: {
            type: EditableTypes.IMAGE,
            name: "Image Principale",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imagePrincipale
                }
            }
        },
        tag: getDefaultTextCms("Tag", tag, null),
        link: getDefaultLinkCms("Lien", "Voir les produits", "#"),
    }
});

export const getDefaultDescriptionManifest = (title = "Titre", description = "Description", img = null) => ({
    id: uuidv4(),
    blocks: {
        title: getDefaultTextCms("Titre du bloc", title,  null),
        description: getDefaultTextareaCms("Description du bloc", description, true, null),
        imagePrincipale: {
            type: EditableTypes.IMAGE,
            name: "Image Principale",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
    }
});

export const getDefaultCategorieHome = (img = "https://via.placeholder.com/600x600", title = "Catégorie") => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image d'illustration de la catégorie",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        title: getDefaultTextCms("Titre de la catégorie", title,  null),
        categorie: {
            name: 'Choix de la catégorie',
            inputs:{
                category: {
                    name: 'Catégorie',
                    type: FormInputTypes.CATEGORY,
                    value: null
                },
            }
        },
        link: {
            name: 'Lien',
            inputs:{
                value: {
                    label: 'Lien',
                    type: FormInputTypes.TEXT,
                    value: ''
                },
            }
        }
    }
});

export const getDefaultProductHome = (img = "https://via.placeholder.com/430x430", title = "Produit") => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image d'illustration du produit",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        product: {
            name: 'Produit',
            inputs:{
                product: {
                    type: FormInputTypes.PRODUCT,
                    value: null
                },
            }
        }
    }
});

export const getDefaultCollectionHome = (img = "https://via.placeholder.com/600x600", title = "Collection", subtitle = null) => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image d'illustration de la collection",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        title: getDefaultTextCms("Titre de la collection", title, null),
        subtitle: getDefaultTextCms("Sous-titre de la collection", title, null),
        categorie: {
            name: 'Choix de la catégorie',
            inputs:{
                category: {
                    name: 'Collection',
                    type: FormInputTypes.CATEGORY,
                    value: null
                },
            }
        },
        link: {
            name: 'Lien',
            inputs:{
                value: {
                    label: 'Lien',
                    type: FormInputTypes.TEXT,
                    value: ''
                },
            }
        },
        button: getDefaultButtonCms('Bouton', 'Découvrir', '#000', '#fff', 'initial'),
    }
});