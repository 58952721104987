import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../../ui/button/Button';
import {Grid, Typography, Box} from '@material-ui/core';
import FormBuilder from '../FormBuilder';
import StepperCustom from '../../stepper/StepperCustom';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

const Title = styled(Typography)`
    color: ${colors.green.regular};
    font-size: 34px;
`;

const Subtitle = styled(Typography)`
    color: ${colors.grey.regular};
    max-width: 380px;
    margin: auto;
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actions_section: {
    paddingBottom: 40, 
    display: 'flex',
    justifyContent: 'center'
  },
}));

function getSteps(getData) {
    return getData;
}

export default function HorizontalLinearStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps(props.navFormConfig.children);
  const isStepOptional = step => {
    return (
      step.isOptionnal === true
    )
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let goNext = () => {
      let newSkipped = skipped;

      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setSkipped(newSkipped);
    }

    if (props.stepperButtonAction[activeStep] !== null) {
      let canGoNext = props.stepperButtonAction[activeStep]();
      if (typeof canGoNext === "boolean") {
        if (canGoNext) {
          goNext();
        }
      } else {
        goNext();
      }
    } else {
      goNext();
    }
  };

  const handleBack = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setSkipped(newSkipped);

    if(props.backStepperButtonAction){
      if(props.backStepperButtonAction[activeStep] !== null){
        props.backStepperButtonAction[activeStep]();
      }
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(steps[activeStep])) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    if(props.stepperSkip){
      setActiveStep(prevActiveStep => props.stepperSkip);
      setSkipped(prevSkipped => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    }else{
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setSkipped(prevSkipped => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    }
  };

  const CustomComponent = activeStep !== steps.length && steps[activeStep].component ? steps[activeStep].component : null;
  let CustomComponentProps;
  if (CustomComponent){
    CustomComponentProps = steps[activeStep].componentProps;
  }
  
  return (
    <div className={classes.root}>
        <StepperCustom className="test" activeStep={activeStep}>
            {steps.map((step, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(step)) {
                  if((props.windowWidth > 1000 && steps.length <= 3) || (props.windowWidth > 1400 && steps.length > 3) || activeStep === index){
                    labelProps.optional = <Typography variant="caption">&nbsp;(Optionnel)</Typography>;
                  }
                }
                if (isStepSkipped(index)) {
                  activeStep === index ? stepProps.completed = false : stepProps.completed = true;
                }
                return (
                    <Step key={step.labelName} {...stepProps}>
                      <StepLabel {...labelProps}>- {step.labelName}</StepLabel>
                    </Step>
                );
            })}
      </StepperCustom>
      <div>
        {activeStep === steps.length ? (
          <div>
            {/* FINAL STEP */}
            <GridCustom item xs={12}>
              <Grid container alignItems={"center"} justify={"center"}>
                  <Grid item xs={3}>
                      <Box textAlign={"center"} mt={8}>
                          <img src={props.navFormConfig.finalStep.picto} width={"100%"}/>
                      </Box>
                  </Grid>
                  <Grid item xs={12}>
                      <Box textAlign={"center"} mt={4}>
                          <Title variant={"h2"}>{props.navFormConfig.finalStep.title}</Title>
                      </Box>
                  </Grid>
                  <Grid item xs={12}>
                      <Box textAlign={"center"} mt={2}>
                          <Subtitle variant={"h4"}>{props.navFormConfig.finalStep.subtitle}</Subtitle>
                      </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box textAlign={"center"} mt={4}>
                        <Button bgcolor={colors.green.regular} shadowcolor={colors.green.darker} text={props.navFormConfig.finalStep.textButton} onClick={props.handlerMutation} className="button" />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box textAlign={"center"} mt={1}>
                        <Button 
                          onClick={handleBack} 
                          className={classes.button} 
                          text="Revenir"
                          bgcolor="transparent"
                          bgcolorhover="transparent"
                          color={colors.grey.regular}
                          disableElevation={true}
                          arrow="left"
                        />
                    </Box>
                  </Grid>
              </Grid>
          </GridCustom>
          </div>
        ) : (
          <div>
            <div>
              { 
                CustomComponent
                  ?
                    <CustomComponent 
                      stateCallback={props.stateCallback} 
                      errorCallback={props.errorCallback}
                      allState={props.allState}
                      optionsInputs={steps[activeStep].optionsInputs}
                      paginationInfo={props.paginationInfo}
                      searchHandler={props.searchHandler}
                      isFormComponent={true}
                      customProps = {CustomComponentProps}
                      stepsInfo={steps[activeStep]}
                    /> 
                  : <FormBuilder 
                      styleInnerContainer={props.styleInnerContainer}
                      backgroundColor={props.backgroundColor}
                      optionsInputs={steps[activeStep].optionsInputs} 
                      stateCallback={props.stateCallback} 
                      errorCallback={props.errorCallback}
                      allState={props.allState} 
                      handleButtonGroupChange={props.handleButtonGroupChange ? props.handleButtonGroupChange : null}
                      handleTreeChange={props.handleTreeChange}
                      expandNodes={props.expandNodes}
                      treeEditCat={props.treeEditCat}
                      treeAddCat={props.treeAddCat}
                      handleChangeCategorie={props.handleChangeCategorie}
                      selectedCategorie={props.selectedCategorie}
                      productByCategorie={props.productByCategorie}
                      selectedProductByCategorie={props.selectedProductByCategorie}
                      setSelectedProducts={props.setSelectedProducts}
                      copyCategories={props.copyCategories}
                      allCategories={props.allCategories}
                      gridItems={props.gridItems}
                      loadGrid={props.loadGrid}
                      addProducts={props.addProducts}
                      resetReloadListing={props.resetReloadListing}
                      hideInput={props.hideInput !== null ? props.hideInput : null}
                      paginationInfo={props.paginationInfo}
                      searchHandler={props.searchHandler}
                    />
              }
            </div>
            <div className={classes.actions_section} style={{backgroundColor: props.styleInnerContainer ? props.styleInnerContainer.backgroundActions : colors.grey.lighter.hue980}}>
              {activeStep !== 0 ? 
                <Button 
                  disabled={activeStep === 0}
                  bgcolor="transparent"
                  bgcolorhover="transparent"
                  color={colors.grey.regular}
                  disableElevation={true}
                  onClick={handleBack}
                  className={classes.button}
                  text="Revenir"
                  arrow="left"
                />
              : ''}
                
              {isStepOptional(steps[activeStep]) && (
                <Button
                  variant="contained"
                  color="white"
                  bgcolor={colors.blue.lighter.hue300}
                  bgcolorhover={colors.blue.lighter.hue150}
                  onClick={handleSkip}
                  className={classes.button}
                  text="Passer"
                />
              )}

              <Button
                variant="contained"
                color="white"
                bgcolor={colors.blue.lighter.hue300}
                bgcolorhover={colors.blue.lighter.hue150}
                onClick={handleNext}
                className={classes.button}
                disabled={props.stepperButtonDisabled ? (props.stepperButtonDisabled[activeStep] !== null && props.stepperButtonDisabled[activeStep]()) : null}
                text={activeStep === steps.length - 1 ? 'Valider' : 'Suivant'}
                arrow="right"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}