import React from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { withApollo } from 'react-apollo';
import { connect } from "react-redux";
import request from '../../../../js/utils/fetch';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import _ from 'lodash';
import mapDispatchToProps from '../../../../js/helpers/mapDispatchToProps';
import mapStateToProps from '../../../../js/helpers/mapStateToProps';

import * as Pagination from '../../../../js/utils/pagination';

import * as moment from 'moment';
import 'moment-timezone';

import { eventService } from '../../../../js/services/event.service';
import { v4 as uuidv4 } from 'uuid';
import axios from '../../../../js/utils/axios';
import { CopyWithJSON } from "../../../../js/utils/copy";
import { hasRights } from '../../../../js/utils/rights';
import { toggleExpandedForAll } from 'react-sortable-tree';

import { CATALOGS, CATALOGS_CATALOGS, VIEW, UPDATE, DELETE} from '../../../../js/constants/constant-rights';

import { Box, Grid } from '@material-ui/core';

import { GET_ATTR_GROUPE_WITH_ATTRIBUTES } from '../../../../queries/attributes';
import { GET_PRODUCTS_PAGINATION, SEARCH_PRODUCTS_SKU_BY_CAT, PRODUCTS_BY_CATALOGS_CAT_PAGINATION } from '../../../../queries/products';
import { DELETE_CATALOG, UPDATE_CATALOG, GET_CATALOG_TYPES, GET_CATALOGS_BY_ID, GET_CATALOGS_CATEGORIES_BY_ID, GET_CATALOGS_BY_ID_ATTRS, GET_CATALOGS_BY_ID_CAT_FILTERS } from '../../../../queries/catalogs';
import { GET_ASSET_BY_CATALOG_ID } from '../../../../queries/assets';
import { GET_CATEGORIES_LIGHT, GET_CATEGORIES_BY_ID_LIST_LIGHT, GET_CATEGORIES_ONLY, DELETE_CATEGORY, UPDATE_CATEGORY_DATA, ADD_CATEGORY_DATA, DELETE_CATEGORY_DATA, UPDATE_CATEGORY, ADD_CATEGORY } from '../../../../queries/categories';

import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { ROUTE_CATALOGS_LIST, ROUTE_PRODUCTS_LIST_DETAIL, ROUTE_HOME } from '../../../../js/constants/route-names';

import catalogEditForm from './config/catalogEditForm.config';
import formCategoriesAdd from '../products/config/formCategoriesAdd.config';
import formCategoriesEdit from '../products/config/formCategoriesEdit.config';
import productSelectorConfig from './config/productSelector.config';
import catalogEditProductsCategories from './config/catalogEditProductsCategories.config';
import catalogEditSelectionsProducts from './config/catalogEditSelectionsProducts.config';
import catalogEditAttributs from './config/catalogEditAttributs.config';

import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import InputBuilder from '../../../ui/form/InputBuilder';
import MaterialButton from '@material-ui/core/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import DialogModal from '../../../ui/dialog/DialogModal';
import SyncIcon from '@material-ui/icons/Sync';
import emptyCatalog from '../../../../assets/images/emptyCatalog.jpg';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TreeView from '../../../ui/tree-view/TreeView';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import SearchBar from '../../../ui/search/SearchBar';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../assets/selection.json";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'; 
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import WarningIcon from '@material-ui/icons/Warning';

import ModalSynchro from "./components/ModalSynchro";
import ListingProducts from '../products/components/ListingProducts';
import {getTraductionAttributs} from '../../../../js/utils/functions';
import { CREATE_CATEGORY_FILTER, DELETE_CATEGORY_FILTER, UPDATE_CATEGORY_FILTER } from '../../../../queries/category_filter';
import { CREATE_CATALOG_FILTER, DELETE_CATALOG_FILTER, UPDATE_CATALOG_FILTER, GET_CATALOG_FILTERS } from '../../../../queries/catalog_filter';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: 280,
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&> .MuiListItemIcon-root': {
            minWidth: 40,
        },
        '&> .logout > p': {
            color: colors.red.regular,
        },
        '&:focus': {
            backgroundColor: colors.grey.lighter.hue900,
            '& .MuiListItemIcon-root, & .MuiListItemTextCustom-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const BoxCustom = styled(Box)`
    width: auto;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;
const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.imagechanged ? props => props.backgroundimage : props.backgroundimage});
    background-size: cover;
    background-position: center;
`;
const ContainerStyled = styled(Grid)`
    padding-bottom: 0;
`;
const LineColor = styled(Box)`
    background: ${props => props.typecatalog === 'permanent' ? colors.blue.darker.hue300 : props.typecatalog === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    height: 20px;
    padding-left: 16px;
    display: flex;
    align-items: center;
`;
const CatalogType = styled(Typography)`
    color: white;
    font-weight: bold;
    text-transform: uppercase;
`;
const CatalogDate = styled(Typography)`
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: ${props => props.marginleft};
`;
const CatalogNumbers = styled(Typography)`
    color: ${props => props.typecatalog === 'permanent' ? colors.blue.darker.hue300 : props.typecatalog === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    font-weight: bold;
`;
const CatalogNbType = styled(Typography)`
    margin-top: -0.5rem;
    font-weight: bold;
    color: ${colors.blue.regular};
`;
const GridCustom = styled(Grid)`
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
`;
const ListItemTextCustom = styled(ListItemText)`
    margin: 0;
    p{
        color: ${props => props.color ? props.color : colors.blue.regular};
    }
`;
const StyledMenuItemDelete = styled(StyledMenuItem)`
    background: ${colors.red.regular};
    &:hover{
        background: ${colors.red.darker};
    }
`;

class EngineCatalogsDetails extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            activeCategorie: ["Home"],
            styleInnerContainer : {
                boxShadow: 'none',
                backgroundInner: colors.white,
                backgroundActions: colors.grey.lighter.hue980,
                padding: '0px',
                height: '630px'
            },
            openForm: false,
            openFormCategories: false,
            openFormAttributs: false,
            openSelectionProduct: false,
            openDialog: false,
            openDialogCategory: false,
            openDialogTreeView: false,
            idCatalog : `/api/catalogs/${this.props.match.params.id}`,
            catalogData : null,
            startDate : '',
            endDate : '',
            nbProducts : 0,
            imageCatalog : null,
            categories: [],
            filteredCat: [],
            errors: {},
            seeErrors: false,
            ready: false,
            expanded: {},
            productByCategorie: [],
            selectedProductByCategorie: [],
            selectedCategorie: '',
            drawerWidthModified : props.drawerWidth,
            currentLang: props.locales[0].node.code,
            allCategories: [],
            pagination: {
                page: 0,
                perPage: 6,
                count: 0,
            },
            paginationCheckboxItem:{
                page: 0,
                perPage: 8,
                count: 0,
            },
            idlist: [],
            arrayCatFilter: [],
            noResult: false,
            anchorEl: null,
            searchModal: '',
            listAsset: [],
            modalSynchro: false,
            reloadTreeCategories: uuidv4(),
            reloadProductList: false,
            reloadProductFormList: false,
            catFilters: [],
            catalogFilters: [],
        };
    }

    handleClick = (event) => {
        this.setState({ anchorEl : event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl : null });
    };

    handleToggleDrawer = (stateDrawer, changeWidth) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer]
        }, () => {
            if(stateDrawer === 'openFormCategories' && !this.state[stateDrawer]){
                // this.setState({ catFilters: [] });
            }
        });

        if (changeWidth) {
            this.handleDrawerWidthChange(300);
        } 
        else{
            this.handleDrawerWidthChange(-800);
        }
    };

    handleDrawerWidthChange = (width) => {
        this.setState({ 
            drawerWidthModified: width
        });
    };

    handleToggleDialog = (stateName) => {
        this.setState({[stateName]: !this.state[stateName]});
    };


    componentDidMount() {
        const getRights = hasRights(CATALOGS, CATALOGS_CATALOGS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.getCatalog();
            this.getAssetByCatalogId();
            this.getFiltersCatalogs();
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot){
        let calcProduct = 0;

        if (prevState.catalogData !== this.state.catalogData) {
            this.setState({
                nbProducts: this.state.catalogData.catalogProducts?.totalCount
            });
        }

        if (prevState.startDateNotConverted !== this.state.startDateNotConverted) {
            let local = moment.tz.guess();
            let toDateStart = moment.tz(this.state.startDateNotConverted, local).format('L');
            this.setState({
                startDate : toDateStart,
            });
        }
        if (prevState.endDateNotConverted !== this.state.endDateNotConverted) {
            let local = moment.tz.guess();
            let toDateEnd = moment.tz(this.state.endDateNotConverted, local).format('L');
            this.setState({
                endDate : toDateEnd,
            });
        }
        if (prevState.ready !== this.state.ready) {
            this.handleGetCatalogsTypes();
            this.prepareFirstTree(true);
        }
        if(prevState.categories !== this.state.categories){
            this.setState({reloadTreeCategories: uuidv4()});
        }
    };

    getCatalog = async () => {
        let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
        this.setState({pagination});

        // GET CATALOGS
        this.props.client.query({
            query: GET_CATALOGS_BY_ID,
            variables: { id: this.state.idCatalog },
            fetchPolicy: 'no-cache',
        }).then(result => {
            let findCatalog = result.data.catalog;

            let local                   = moment.tz.guess();
            let toDateStart             = moment.tz(findCatalog.startAt, local).format('L');
            let toDateStartNotConverted = moment(findCatalog.startAt).format('YYYY-MM-DD');
            let toDateEnd               = moment.tz(findCatalog.endAt, local).format('L');
            let toDateEndNotConverted   = moment(findCatalog.endAt).format('YYYY-MM-DD');

            for (let category of findCatalog.categories) {
                category.productCategories = {
                    totalCount: 0,
                    edges: []
                };
            }

            this.setState({
                autosync: findCatalog.autosync,
                libelle: findCatalog.libelle,
                categories: findCatalog.categories,
                description: findCatalog.description,
                catalogId: findCatalog.id,
                catalogIdentifier: findCatalog.identifier,
                status: findCatalog.status,
                type: findCatalog.type,
                catalogType: findCatalog.type.id,
                imageCatalog: findCatalog.media,
                catalogData: findCatalog,
                startDateNotConverted: toDateStartNotConverted,
                startDate: toDateStart,
                endDateNotConverted: toDateEndNotConverted,
                endDate: toDateEnd,
                ready: true,
            }, () => {
                this.prepareSecondTree(this.state.categories, true);
            }); 
            
            let dataRequest = findCatalog.id.replace('/api/catalogs/', '')
            
            request(`${process.env.REACT_APP_API}/categories-list/product/${dataRequest}`, 'get').then((data) => {
                if(data.success){
                    this.setState({categoriesShowed: data.datas, activeCategorie: ['Tout voir']});
                }
            });
            // GET PRODUCTS
            let filteredProducts = [];
            let idlist = [];

            for (let category of this.state.categories){
                for (let productCat of category.productCategories.edges) {
                    if (filteredProducts.find(value => value.node.id === productCat.node.id)){
                    }else{
                        idlist.push(productCat.node.product.id.replace('/api/products/', ''));
                        filteredProducts.push(productCat);
                    }
                }
            }

            this.setState({
                idlist,
                filteredCat: filteredProducts,
            }, () => {
                // this.handleGetProducts('no-cache');
            });

            let catalogFilters = [];
            let values = null;
            if (result.data.catalog.catalogFilters.edges.length > 0){
                for (let filter of result.data.catalog.catalogFilters.edges){
                    if (filter.node.attribute.attributeType.input === "select"){
                        if (filter.node.operator === "IN" || filter.node.operator === "NOT IN"){
                            let parsing = JSON.parse(filter.node.value)
                            values = parsing.map(e => `/api/attribute-options/${e}`)
                        }else{
                            values = filter.node.attributeOption.id
                        }
                    }else{
                        if (filter.node.operator === "IN" || filter.node.operator === "NOT IN"){
                            let parsing = JSON.parse(filter.node.value)
                            values = parsing
                        }else{
                            values = filter.node.value
                        }
                    }
                    catalogFilters.push({
                        new: false,
                        changed: false,
                        code: filter.node.attribute.id,
                        values,
                        operator: filter.node.operator,
                        attributeType: {node: filter.node.attribute},
                        id: filter.node.id,
                        checkbox: false,
                        typingChips: null,
                        showButton: false,
                    })
                }
            }

            this.asyncGetCatalogAttributes();

            this.setState({catalogFilters})

            this.initAttributs();
        });
    };

    asyncGetCatalogAttributes = () => {
        this.props.client.query({
            query: GET_CATALOGS_BY_ID_ATTRS,
            variables: { id: this.state.idCatalog },
            fetchPolicy: 'no-cache',
        }).then(result => { 
            let findCatalog = result.data.catalog;

            this.setState({
                attributesSelected: findCatalog.attributes?.edges.map(e => e.node.id) ?? [],
                ready: true,
            }); 
        });
    };

    getAssetByCatalogId = () => {
        this.props.client.query({
            query: GET_ASSET_BY_CATALOG_ID,
            variables: { catalog: this.state.idCatalog},
            fetchPolicy: 'no-cache',
        }).then(result => {
            let listAsset = [];
            if(result.data.assets.edges.length>0){
                for(let asset of result.data.assets.edges){
                    listAsset.push(asset)
                }
            }
            this.setState({listAsset});
        });
    };

    getFiltersCatalogs = () => {
        this.props.client.query({
            query: GET_CATALOG_FILTERS,
            fetchPolicy: 'no-cache',
        }).then(result => {
            let filtersList = result.data.catalogFilters;
        });
    };

    handleGetCatalogsTypes = () => {
        this.props.client.query({
            query: GET_CATALOG_TYPES,
            fetchPolicy: 'no-cache'
        }).then(result => {
            let allCatalogsTypes = result.data.catalogTypes.edges;
            this.setState({
                allCatalogsTypes
            })
        })
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();
        });
        this.prepareSecondTree(this.state.categories)
        this.forceUpdate();
    };

    setValue = (stateName, value, translated) => {
        if (stateName === "catalogType"){
            let getNewType = this.state.allCatalogsTypes.find(e => e.node.id === value)
            this.setState({
                [stateName]: value,
                type: getNewType.node
            })
        }
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else{
            this.setState({
                [stateName]: value,
            });
        }
    };

    handleInputChange = (stateName, evt, custom, translated ) => {
        if(custom){
            this.setState({...custom})
        }
        else{
            const value = evt?.target?.value ?? evt;
            this.setValue(stateName, value, translated);
        }
    };

    handleMediaPicker=(selected,stateName)=>{
        if(stateName === 'imageCatalog'){
            this.handleInputChange(stateName,selected,null,false);  
        }
        else{
            this.handleInputChange(stateName,selected,null,true);  
        }
    };

    setExpanded = (all, node) => {
        if(node){
            all[node.id] = node.expanded;
    
            for (let child of node.children) {
                this.setExpanded(all, child);
            }
        }
    };

    storeDraggedCats = (categoriesToAdd, category, parent) => {
        let foundCat = this.state.categories.find(e => e.id === category.id);

        if (!foundCat) {
            // set data format
            category                = CopyWithJSON(category);
            category.fromLeft       = true;
            category.libelle   = `${category.libelle}_${this.state.catalogIdentifier}`;

            let attributes = [];

            for (let { node } of category.categoryDatas.edges) {
                let found = false;
                
                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;
    
                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.value,
                            media: {
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id // category data id
                        });
                    }
                }
    
                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        locales: [{
                            value: node.value,
                            media: {
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id
                        }]
                    });
                }
            }

            category.attributes = attributes;
            
            categoriesToAdd.push(category);
        }

        // set correct parent
        category.parent = parent ? {
            id: parent
        } : null;

        for (let child of category.children) {
            this.storeDraggedCats(categoriesToAdd, child, category.id);
        }
    };

    modifyCategoryAttributes = category => {
        for (let attribute of this.props.attributes.category.attributes.edges) {
            let isMedia = attribute.node.attributeType.input === 'image';

            for (let locale of this.props.locales) { 
                let value           = this.state[locale.node.code][attribute.node.identifier];
                let catAttribute    = category.attributes?.find(e => e.id === attribute.node.id);

                if (catAttribute) {
                    let catAttributeLocale = catAttribute.locales.find(e => e.code === locale.node.code);

                    if (catAttributeLocale) {
                        catAttributeLocale.value = isMedia ? null : value;
                        catAttributeLocale.media = isMedia ? { 
                            ...catAttributeLocale.media,
                            ...value
                        } : null;
                    } else if (value) {
                        catAttribute.locales.push({
                            value: isMedia ? null : value,
                            media: isMedia ? value : null,
                            id: locale.node.id,
                            code: locale.node.code
                        });
                    }
                } else if (value) {
                    category.attributes.push({
                        id: attribute.node.id,
                        identifier: attribute.node.identifier,
                        attributeType: attribute.node.attributeType,
                        locales: [{
                            value: isMedia ? null : value,
                            media: isMedia ? value : null,
                            id: locale.node.id,
                            code: locale.node.code
                        }]
                    });
                }
            }
        }
        return category;
    };

    modifyCategoryFilters = category => {
        for (let filter of this.state.catFilters){
            if (filter.new){
                category.filters.push(filter)
            }else if (!filter.new && (filter.changed || filter.deleted)){
                let getCatFilters = category.filters.find(e => e.id === filter.id)
                getCatFilters = filter
            }
        }
        return category
    };

    handleMutationCategories = async () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            eventService.fire();
            return false;
        }
        this.props.startLoading();
        this.setState({idCatSave: this.state.cat_id});

        switch(this.state.editForm) {
            case 'edit':
                for (let editCat of this.state.categories) {
                    if (editCat.id === this.state.cat_id) {

                        editCat.status = this.state.catActivated
                        editCat.edited = true;
                        editCat.id = this.state.currentNode.id;
                        editCat.libelle = this.state.identifier;
                        editCat.parent = {id: this.state.parent};

                        await this.modifyCategoryFilters(editCat);
                        this.modifyCategoryAttributes(editCat);

                        this.setState({
                            categories: this.state.categories
                        }, () => {this.prepareSecondTree(this.state.categories, false, true);this.props.stopLoading();});
                    }
                }

                break;
            case 'add' :
                let newCat = {
                    new: true,
                    attributes: [],
                    categoryDatas: { edges: [] },
                    libelle: this.state.identifier,
                    parent: { id: this.state.parent },
                    products: { edges: [] },
                    status: this.state.catActivated,
                    id: uuidv4()
                };

                let variables = { 
                    libelle: this.state.identifier, 
                    parent: this.state.parent,
                    catalog: this.state.idCatalog,
                    master: null,
                    status: this.state.catActivated,
                };

                let MUTATION_RESULT = await this.props.client.mutate({
                    mutation: ADD_CATEGORY,
                    variables
                });

                newCat.id = MUTATION_RESULT.data.createCategory.category.id;
                newCat = await this.modifyCategoryAttributes(newCat);
                await this.saveAttributes(newCat, newCat);

                let temporaryCategories = this.state.categories;
                temporaryCategories.push(newCat);

                this.setState({
                    categories: temporaryCategories,
                }, () => {this.prepareSecondTree(this.state.categories, false, true);this.props.stopLoading();});
            break;
            default: return;
        }

        this.handleToggleDrawer('openFormCategories', false);
    };
    
    processCategory = (category, parent = null, allCategories) => {
        return new Promise(async (resolve, reject) => {
            if(category.id === this.state.idCatSave){
                let query       = null;
                let variables   = null;
                
                if (category.new || category.fromLeft) {
                    query = ADD_CATEGORY;
                    variables = { 
                        libelle: category.libelle, 
                        parent,
                        catalog: this.state.idCatalog,
                        master: category.fromLeft ? category.id : null,
                        status: category.status
                    };

                    let products = this.state.selectedProductByCategorie[category.id];

                    if (products && products.length)
                        variables.products = products;
                } else {
                    query = UPDATE_CATEGORY;
                    variables = { 
                        id: category.id, 
                        libelle: category.libelle, 
                        parent: this.state.parent,
                        status: category.status
                    };

                    let products = this.state.selectedProductByCategorie[category.id];

                    if (products && products.length)
                        variables.products = products;
                }
    
                const MUTATION_RESULT = await this.props.client.mutate({
                    mutation: query,
                    variables
                });

                let queryFilter = null;
                let variablesFilter = null;
                for (let filter of this.state.catFilters){
                    let value = null;
                    let attributeOption = null;
                    if (filter.attributeType.node.attributeType.input === "select"){
                        if (filter.operator === "IN" || filter.operator === "NOT IN"){
                            let arrayRegex = filter.values.map(e => e.replace('/api/attribute-options/', ''))
                            value = JSON.stringify(arrayRegex)
                        }else{
                            attributeOption = filter.values
                        }
                    }else{
                        if (filter.operator === "IN" || filter.operator === "NOT IN"){
                            value = JSON.stringify(filter.values)
                        }else{
                            value = filter.values
                        }
                    }
                    if (filter.new){
                        queryFilter = CREATE_CATEGORY_FILTER;
                        variablesFilter = {
                            operator: filter.operator,
                            createdAt: moment(),
                            category: category.id,
                            attribute: filter.code,
                            attributeOption,
                            value
                        }
                    }else if (filter.changed && !filter.deleted){
                        queryFilter = UPDATE_CATEGORY_FILTER;
                        variablesFilter = {
                            id: filter.id,
                            operator: filter.operator,
                            category: category.id,
                            attribute: filter.code,
                            attributeOption,
                            value
                        }
                    }else if (filter.deleted){
                        queryFilter = DELETE_CATEGORY_FILTER;
                        variablesFilter = {
                            id: filter.id,
                        }
                    }
                    if (queryFilter && variablesFilter){
                        await this.props.client.mutate({
                            mutation: queryFilter,
                            variables: variablesFilter
                        });
                    }
                }
    
                let uptodate = !category.new && !category.fromLeft 
                    ? MUTATION_RESULT.data.updateCategory.category
                    : MUTATION_RESULT.data.createCategory.category;
    
                await this.saveAttributes(category, uptodate);
    
                allCategories.push(uptodate.id);

                for (let child of category.children) {
                    await this.processCategory(child, uptodate.id, allCategories);
                }
            }else{
                if(category.new){}
                for (let child of category.children) {
                    await this.processCategory(child, category.id, allCategories);
                }
            }
            resolve();
        });
    };

    resetState() { 
        this.setState({
            identifier: '',
            parent: '',
            errors: {},
            seeErrors: false
        });
    };

    handleCancel = () => {
        this.getCatalog();
    };
    
    handleRecursiveCat = (breadcrumb) => {
        this.state.activeCategorie.push('Home');
        for (let categorie of breadcrumb){
            let translatedLibelle = categorie.categoryDatas.find(e => e.locale.code === this.state.currentLang)
            if (translatedLibelle){
                this.state.activeCategorie.push(` > ${translatedLibelle.value}`);
            }else{
                this.state.activeCategorie.push(` > ${categorie.libelle}`);
            }
        }
    };

    getChildrenCat = (id) => {
        for(let categorie of this.state.categoriesShowed) {
            if(categorie.id === id) {
                for(let child of categorie?.childrens){
                    this.state.arrayCatFilter.push(child.id);
                }
            }
        }
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({[stateName]: value});
    };

    deleteMutation = () => {
        this.props.startLoading();
        this.props.client.mutate({
            mutation: DELETE_CATALOG,
            variables: { id: this.state.catalogId }
        }).then(result => {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Catalogue supprimé avec succès');
            this.goTo(ROUTE_CATALOGS_LIST);
        }).catch(error => {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, `Impossible de supprimer le catalogue`);
        });
    };

    deleteMutationCategory = () => {
        this.props.startLoading();
        if (!this.state.currentNode.new && !this.state.currentNode.fromLeft) {
            this.props.client.mutate({
                mutation: DELETE_CATEGORY,
                variables: { id: this.state.cat_id }
            }).then(() => {
                this.props.stopLoading();
                this.props.snack(ALERT_SUCCESS, 'Catégorie supprimée');
    
                this.removeNode(this.state.cat_id);
                this.handleToggleDrawer('openFormCategories', false);
                this.handleToggleDialog('openDialogCategory');
                this.resetState();
            }).catch(error => {
                this.props.stopLoading();
                this.props.snack(ALERT_ERROR, `Impossible de supprimer la catégorie, veuillez vérifier qu'elle n'est pas utilisée`);
                this.handleToggleDialog('openDialogCategory');
            });
        } else {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Catégorie supprimée');

            this.removeNode(this.state.cat_id);
            this.handleToggleDrawer('openFormCategories', false);
            this.handleToggleDialog('openDialogCategory');
            this.resetState();
        }
    };

    removeNode = (id) => {
        let allCategories = this.state.categories;
        allCategories = this.state.categories.filter(e => e.id !== id);

        this.setState({
            categories: allCategories
        }, () => this.prepareSecondTree(this.state.categories));
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    // SAVE TREE
    copyCategories = (nodeInfo) => {
        this.setState({openDialogTreeView: true, copyCategories: nodeInfo});
    };

    selectCategorie = (nodeInfo) => {
        this.setState({selectCategorie: nodeInfo});
    };

    validateNewCategorie = () => {
        
        this.props.startLoading();
        let data = {};
        data.idCategory = parseInt(this.state.copyCategories.node.id.replace('/api/categories/', ''));
        data.idParent = parseInt(this.state.selectCategorie.node.id.replace('/api/categories/', ''));

        request(`${process.env.REACT_APP_API}/categories/copy`, 'post', data).then(response => {
            if(response.success){
                this.props.client.query({
                    query: GET_CATALOGS_CATEGORIES_BY_ID,
                    variables: { id: this.state.idCatalog },
                    fetchPolicy: 'no-cache',
                }).then(result => {
                    let findCatalog = result.data.catalog;
                    
                    this.setState({
                        categories: findCatalog.categories,
                        openDialogTreeView: false,
                        copyCategories: null,
                        selectCategorie: null,
                    }, () => {
                        this.prepareSecondTree(this.state.categories, true);
                        this.props.stopLoading();
                    }); 
                });
            }
            else{
                this.props.stopLoading();
            }
        });
    };
    
    addSubcategory = (nodeInfo) => {
        this.resetState();

        for (let locale of this.props.locales) {
            this.setState({
                [locale.node.code]: {}
            });
        }

        this.setState({ 
            openFormCategories: true,
            editForm: 'add',
            parent: nodeInfo.id,
            catActivated: true,
        });
    };

    editCat = async (nodeInfo) => {
        this.resetState();

        this.props.startLoading();

        let result = await this.props.client.query({
            query: GET_CATALOGS_BY_ID_CAT_FILTERS,
            variables: { id: this.state.idCatalog, categoryId: nodeInfo.id.replace('/api/categories/', '') },
            fetchPolicy: 'no-cache',
        });

        this.props.stopLoading();

        let findCategory = result.data.catalog?.categories?.[0];
        let categoryFilters = findCategory?.categoryFilters?.edges;

        let filters = [];

        if (categoryFilters && categoryFilters.length > 0){
            for (let filter of categoryFilters){
                if (filter){
                    let getFilterData = {
                        ...filter.node,
                        attributeType: {node: filter.node.attribute}
                    }

                    filters.push(getFilterData);
                }
            }
        }

        nodeInfo.filters = filters;
        
        for (let locale of this.props.locales) {
            let values = {};

            for (let attribute of nodeInfo.attributes) {
                for (let attributeLocale of attribute.locales) {
                    if (attributeLocale.code === locale.node.code) {
                        switch(attribute.attributeType.input) {
                            case 'image': 
                                values[attribute.identifier] = {
                                    data: attributeLocale.media ? attributeLocale.media.data : null,
                                    file: attributeLocale.media ? attributeLocale.media.file : null,
                                    changed: attributeLocale.media ? attributeLocale.media.changed : null
                                };
                                break;
                            default:
                                values[attribute.identifier] = attributeLocale.value;  
                        }
                    }
                }
            }

            this.setState({
                [locale.node.code]: values
            });
        }
        let catFilters = [];
        let values = null;
        if (nodeInfo.filters.length > 0){
            for (let filter of nodeInfo.filters){
                if (filter.attribute.attributeType.input === "select"){
                    if (filter.operator === "IN" || filter.operator === "NOT IN"){
                        let parsing = JSON.parse(filter.value)
                        values = parsing.map(e => `/api/attribute-options/${e}`)
                    }else{
                        values = filter.attributeOption.id
                    }
                }else{
                    if (filter.operator === "IN" || filter.operator === "NOT IN"){
                        let parsing = JSON.parse(filter.value)
                        values = parsing
                    }else{
                        values = filter.value
                    }
                }
                catFilters.push({
                    new: false,
                    changed: false,
                    code: filter.attribute.id,
                    values,
                    operator: filter.operator,
                    attributeType: {node: filter.attribute},
                    id: filter.id,
                    checkbox: false,
                    typingChips: null,
                    showButton: false,
                })
            }
        }

        this.setState({ 
            currentNode: nodeInfo,
            catFilters,
            openFormCategories : true,
            editForm: 'edit',
            cat_id: nodeInfo.id,
            identifier: nodeInfo.libelle,
            catActivated: nodeInfo.status,
            parent: nodeInfo.parent === null ? nodeInfo.id : nodeInfo.parent.id,
        });
    };

    handleTreeChange = (stateName, value) => {
        this.setValue(stateName, value);
        let all = this.state.expanded;

        this.setExpanded(all, value[0]);

        this.setState({ expanded: all });
        this.forceUpdate()
    };

    getAttributeTranslatedValue = (attributes, id, lang) => {
        let attribute = attributes?.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };

    getTranslatedValueFromData = (datas, id, lang) => {
        let data = datas?.find(e => e.node.locale.id === lang && e.node.attribute.id === id);

        if (!data)
            return null;

        return data.node;
    };
    
    saveAttributes = (category, uptodate) => {
        return new Promise(async (resolve, reject) => {
            for (let attribute of this.props.attributes.category.attributes.edges) {
                for (let locale of this.props.locales) { 
                    let formValue           = this.getAttributeTranslatedValue(category.attributes, attribute.node.id, locale.node.id);
                    let currentTranslation  = this.getTranslatedValueFromData(category.categoryDatas?.edges, attribute.node.id, locale.node.id);

                    if (formValue) {
                        let resultMedia = null;
                        let isMedia     = attribute.node.attributeType.input === 'image';
    
                        if (isMedia) { 
                            if (formValue.media && formValue.media.changed) {
                                // means we have a new media
                                let formData = new FormData();
                                    
                                formData.append('file', formValue.media.file);
                                formData.append('type', formValue.media.type);

                                resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                            } else if (category.fromLeft && formValue.media.id) {
                                // means we need to duplicate media
                                let id          = formValue.media.id.replace('/api/media-objects/', '');
                                let duplicate   = await axios(`${process.env.REACT_APP_API}/media-objects/${id}/duplicate`, 'put');
                                resultMedia     = { id: duplicate.response };
                            }
                        }
    
                        if (currentTranslation && !category.fromLeft && !category.new) {
                            // UPDATE STEP 

                            try {
                                if (isMedia && resultMedia) // remove the old media
                                    await axios(`${process.env.REACT_APP_API}/media-objects/${currentTranslation.media.id.replace('/api/media-objects/', '')}`, 'delete');
                            } catch(e) {
                                console.log(e);
                            }

                            if (!isMedia && currentTranslation.value === formValue.value)
                                continue; // no change

                            if (isMedia && currentTranslation.media.id === formValue.media.id)
                                continue; // no change

                            let variables = {
                                "id": currentTranslation.id, 
                                "category": uptodate.id, 
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };
    
                            if (isMedia){
                                variables.media = formValue.media.id;
                            }
                            if (!isMedia)
                                variables.value = formValue.value;
    
                            await this.props.client.mutate({
                                mutation: UPDATE_CATEGORY_DATA,
                                variables
                            });
                        } else {
                            // CREATE STEP
                            let variables = {
                                "category": uptodate.id, 
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };
    
                            if (isMedia){
                                variables.media = formValue.media.id;
                            }
                            if (!isMedia)
                                variables.value = formValue.value;
    
                            await this.props.client.mutate({
                                mutation: ADD_CATEGORY_DATA,
                                variables
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP
                        await this.props.client.mutate({
                            mutation: DELETE_CATEGORY_DATA,
                            variables: { "id": currentTranslation.id },
                            refetchQueries: [{ query: GET_CATEGORIES_ONLY }]
                        });
                    }
                }
            }
            resolve();
        });
    };

    handlerMutation = async (attr = false) => {
        
        this.props.startLoading();

        let variables = {
            "id": this.state.idCatalog,
            "identifier": this.state.catalogIdentifier,
            "libelle": this.state.libelle,
            "description": this.state.description ? this.state.description : null,
            "type": this.state.type.id,
            "status": this.state.status, 
            "autosync": this.state.autosync,
            "startAt": this.state.startDateNotConverted,
            "endAt": this.state.endDateNotConverted,
            "attributes": this.state.attributesSelected,
        };

        if (this.state.imageCatalog?.id)
            variables.media = this.state.imageCatalog.id;

        this.props.client.mutate({
            mutation: UPDATE_CATALOG,
            variables
        }).then(async (result) => {
            if(attr){
                this.props.snack(ALERT_SUCCESS, 'Les attributs du catalogue ont été modifiés');
                this.handleToggleDrawer('openFormAttributs');
            }
            else{
                this.props.snack(ALERT_SUCCESS, 'Le catalogue a été modifié');
                this.handleToggleDrawer('openEditForm')
            }
            for (let filter of this.state.catalogFilters){
                let value = null;
                let attributeOption = null;
                if (filter.attributeType.node.attributeType.input === "select"){
                    if (filter.operator === "IN" || filter.operator === "NOT IN"){
                        let arrayRegex = filter.values.map(e => e.replace('/api/attribute-options/', ''))
                        value = JSON.stringify(arrayRegex)
                    }else{
                        attributeOption = filter.values
                    }
                }else{
                    if (filter.operator === "IN" || filter.operator === "NOT IN"){
                        value = JSON.stringify(filter.values)
                    }else{
                        value = filter.values
                    }
                }

                let queryFilter = null;
                let variablesFilter = null;
                
                if (filter.new){
                    queryFilter = CREATE_CATALOG_FILTER;
                    variablesFilter = {
                        operator: filter.operator,
                        createdAt: moment(),
                        catalog: this.state.catalogId,
                        attribute: filter.code,
                        attributeOption,
                        value
                    }
                }else if (filter.changed && !filter.deleted){
                    queryFilter = UPDATE_CATALOG_FILTER;
                    variablesFilter = {
                        id: filter.id,
                        operator: filter.operator,
                        catalog: this.state.catalogId,
                        attribute: filter.code,
                        attributeOption,
                        value
                    }
                }else if (filter.deleted){
                    queryFilter = DELETE_CATALOG_FILTER;
                    variablesFilter = {
                        id: filter.id,
                    }
                }
                if(queryFilter && variablesFilter){
                    await this.props.client.mutate({
                        mutation: queryFilter,
                        variables: variablesFilter
                    });
                }
                this.props.stopLoading();
            }
            this.resetStateCatalog();
            this.getCatalog();
        });
    };

    handlerMutationProducts = () => {
        
        this.props.startLoading();
        let idListState = this.state.idlist;
        let formatedDatas = {};
        formatedDatas.categories = [];
        
        for (const [key, value] of Object.entries(this.state.selectedProductByCategorie)) {
            let element = {};
            element.idCategory = parseInt(key.replace('/api/categories/', ''));
            let idList = _.clone(this.state.selectedProductByCategorie[key]);
            let i = 0;
            if(idList){
                for(let id of idList){
                    idList[i] = parseInt(idList[i].replace('/api/products/', ''));
                    for (let j=0; j < idListState.length; j++) {
                        if (parseInt(idListState[j]) === idList[i]) {
                            idListState.splice(j, 1);
                        }
                    }
                    i++;
                }
                element.products = idList;
            }
            if(idList.length > 0){
                formatedDatas.categories.push(element);
            }
        }

        request(`${process.env.REACT_APP_API}/categories/remove-product`, 'post', formatedDatas).then(response => {
            this.setState({openForm: false}, async () => {
                let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
                this.setState({
                    pagination,
                    selectedCategorie: '',
                    selectedProductByCategorie: [],
                    reloadProductList: true,
                }, async () => {
                    this.getCatalog();
                    // this.handleGetProducts('no-cache');
                    this.props.stopLoading();
                });
            });
        });
    };
    // -- END SAVE TREE

    resetStateCatalog = () => {
        this.setState({
            productByCategorie: {},
            selectedProductByCategorie: {},
            selectedCategorie: '',
        });
    };

    setSelectedProducts = values => {
        let productOfCategorie = this.state.selectedProductByCategorie;
        productOfCategorie[this.state.selectedCategorie] = values;

        this.setState({
            selectedProductByCategorie: productOfCategorie
        }, () =>  {this.forceUpdate()});
    };

    expandNodes = (expanded, stateName) => {
        this.setState({
            [stateName]: toggleExpandedForAll({
                treeData: this.state[stateName],
                expanded
            })
        });
    };

    handleChangeCategorie = async (id) => {
        let pagination = await Pagination.resetPagination(this.state.paginationCheckboxItem.perPage);
        this.setState({
            paginationCheckboxItem: pagination,
            selectedCategorie: id,
            load: true,
        }, async () => {
            this.handleGetProductsPagination('no-cache');
        }); 
    };

    handleGetProductsPagination = async (policy = "cache-first") => {
        let paginationVars = await Pagination.paginationQueryData(this.state.paginationCheckboxItem);
        let variables = {...paginationVars};
        variables.id = this.state.idCatalog;
        if(this.state.selectedCategorie){
            variables.idlist = this.state.selectedCategorie.replace('/api/categories/', '');
        }
        this.props.client.query({
            query: PRODUCTS_BY_CATALOGS_CAT_PAGINATION,
            fetchPolicy: policy,
            variables
        }).then(async result =>{
            if(result.data.catalog.categories.length > 0){
                let pagination = await Pagination.updatePaginationData(this.state.paginationCheckboxItem, result.data.catalog.categories[0].productCategories);
                let products = this.state.productByCategorie;
                products[this.state.selectedCategorie] = result.data.catalog.categories[0].productCategories.edges;
                this.setState({productByCategorie: products, paginationCheckboxItem: pagination, load: false});
            }
        });
    };

    addProducts = (items) => {
        this.props.startLoading();
        request(`${process.env.REACT_APP_API}/categories/add-product`, 'post', items).then(response => {
            this.setState({openProductSelector: false}, async () => {
                let pagination = await Pagination.resetPagination(this.state.paginationCheckboxItem.perPage);
                this.setState({
                    paginationCheckboxItem: pagination,
                    reloadProductFormList: true,
                }, async () => {
                    this.handleGetProductsPagination('no-cache');
                    this.props.stopLoading();
                });
            });
        });
    };

    getCatalogTypeName = (identifier) => {
        switch(identifier) {
            case 'permanent':
                return 'Permanent';
            case 'ephemeral':
                return 'Éphémère';
            case 'thematic':
                return 'Thématique';
            default: return 'n/a';
        }
    };

    initAttributs() {
        this.props.client.query({
            query: GET_ATTR_GROUPE_WITH_ATTRIBUTES,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({ allGroups: result.data.attributeGroups.edges.filter(e => (!e.node.isSystem && !e.node.isForContent) || e.node.identifier === 'default') });
        });
    };

    render() {
        const { anchorEl } = this.state;
        const catalogData = this.state.catalogData;
        const selectLang = {
            type: 'select',
            label: 'Langue',
            helper: 'Langue',
            required: false,
            stateName: 'currentLang',
            value: 
                this.props.locales.map((locale) => {
                    return({
                        value: locale.node.code,
                        label: locale.node.libelle
                    })
                })
        };
        let infoCatSelected = null
        if (this.state.selectedCategorie){
            infoCatSelected = this.state.allCategories.find(e => e.id === this.state.selectedCategorie)
            infoCatSelected = getTraductionAttributs('category_name', infoCatSelected.categoryDatas.edges, this.state.currentLang)
        }
        return (
            catalogData ? (
                <div style={{width: (this.state.openForm || this.state.openEditForm || this.state.openFormAttributs) && this.props.windowWidth > 1500 ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                    <TopPanel 
                        icomoon="icon-catalogue" 
                        colorIcomoon={colors.blue.lighter.hue300}
                        title={process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gérer mon Catalogue" : "Gérer mon Entrepôt"}
                        subtitle={process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Apportez des modifications à votre catalogue" : "Apportez des modifications à votre entrepôt"}
                        gradientColor1={colors.menu.regular} 
                        gradientColor2={colors.menu.darker} 
                        openForm={this.state.openForm}
                        hasBorder={true}
                    />

                    {
                        process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid container justify={'flex-end'}>
                                <Grid item xs={6}>
                                    <Grid container justify={'flex-end'}>
                                        <InputBuilder value={this.state.currentLang} input={selectLang} stateCallback={this.handleLang} /> 
                                    </Grid>
                                </Grid>
                            </Grid>
                        : null
                    }

                    <Grid container direction="column" justify="center" style={{marginTop: -32, marginBottom: 32}}>
                        <GridCustom item lg={12} md={12} xs={12} >
                            <BoxCustom onClick={this.props.history.goBack} style={{marginBottom: 16}}>
                                <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                            </BoxCustom>

                            <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%", position: 'relative'}} contentpadding={"0 0 0 0"}>
                                <Box>
                                    <ContainerImage 
                                        height={245}
                                        backgroundimage={this.state.imageCatalog ? `${process.env.REACT_APP_MEDIAS}/${this.state.imageCatalog.filePath}` : emptyCatalog} 
                                        style={{backgroundColor: this.state.imageCatalog ? null : colors.blue.lighter.hue300, backgroundRepeat: this.state.imageCatalog ? null : 'no-repeat', backgroundSize: this.state.imageCatalog ? null : 'auto 100%' }}
                                        imagechanged={this.state.imageCatalog ? this.state.imageCatalog.changed : null}
                                    />
                                    <LineColor height={16} typecatalog={this.state.type.identifier}>
                                        {
                                            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                            (
                                                <>
                                                    <CatalogType variant="h5" component="div" typecatalog={this.state.type.identifier}>
                                                        {this.getCatalogTypeName(this.state.type.identifier)}       
                                                    </CatalogType>
                                                    {
                                                        this.state.type.identifier !== 'permanent' ? (
                                                            <CatalogDate variant="h5" color={colors.grey.regular} component="div" marginleft={"1rem"}>
                                                                {this.state.startDate} - {this.state.endDate}
                                                            </CatalogDate> 
                                                        ) : null
                                                    }
                                                </>
                                            ) : null
                                        }
                                    </LineColor>

                                    <Box padding={'1rem 1.5rem 1rem 1.5rem'} style={{borderBottom: '1px solid rgb(212, 217, 220)'}}>
                                        <ContainerStyled container direction="row" justify="space-between" alignItems={this.state.openForm || this.state.openEditForm || this.state.openFormAttributs ? "flex-start" : "center"}>
                                            <div style={{padding: '5px 0'}}>
                                                <StatusInfo status={this.state.status} width={'auto'}/>
                                            </div>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{width: 'auto', background: this.state.autosync ? colors.green.regular : colors.orange.regular, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4px 12px', borderRadius: 50, marginRight: 16}}>
                                                    <SyncIcon style={{fill: colors.white,  borderRadius: '50%', fontSize: '1.2rem'}} />
                                                    <Typography variant="body1" component="div" style={{color: colors.white, paddingLeft: 8, fontSize: 12, lineHeight: '15px'}}>
                                                        {this.state.autosync ? `Synchronisation automatique des produits` : `Aucune synchronisation automatique des produits`}
                                                    </Typography>
                                                </div>
                                                {
                                                    hasRights(CATALOGS, CATALOGS_CATALOGS, UPDATE) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                                    <>
                                                        <MaterialButton style={{color: this.state.type.identifier === 'permanent' ? colors.blue.darker.hue300 : this.state.type.identifier === 'ephemeral' ? colors.purple.regular : colors.pink.regular, textTransform: 'inherit'}} onClick={this.handleClick}>
                                                            Gérer le catalogue
                                                            <ExpandMoreIcon /> 
                                                        </MaterialButton>
                                                        <StyledMenu
                                                            id="customized-menu"
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={Boolean(anchorEl)}
                                                            onClose={this.handleClose}
                                                            autoFocus={false}
                                                        >
                                                            <StyledMenuItem onClick={() => { this.handleClose(); this.handleToggleDrawer('openEditForm', true)}}>
                                                                <IcomoonReact iconSet={iconSet} color={colors.blue.regular} size={20} icon={'icon-catalogue'}  />
                                                                <ListItemTextCustom style={{paddingLeft: 12}} secondary={process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Modifier le catalogue' : "Modifier l'entrepôt"} />
                                                            </StyledMenuItem>
                                                            <StyledMenuItem  onClick={() => { 
                                                                if (!this.state.allCategoriesReady) 
                                                                    return;

                                                                this.handleClose(); 
                                                                this.handleToggleDrawer('openForm');
                                                            }}>
                                                                <IcomoonReact iconSet={iconSet} color={colors.blue.regular} size={20} icon={'picto-produit'}  />
                                                                <ListItemTextCustom style={{paddingLeft: 12}} secondary={process.env.REACT_APP_MODE_SPREAD !== "hub" 
                                                                    ? this.state.allCategoriesReady ? 'Catégoriser les produits' : 'Chargement...' 
                                                                    : "Modifier l'entrepôt" } />
                                                            </StyledMenuItem>
                                                            <StyledMenuItem  onClick={() => { this.handleClose(); this.handleToggleDrawer('openSelectionProduct') }}>
                                                                <IcomoonReact iconSet={iconSet} color={colors.blue.regular} size={20} icon={'picto-toggle'}  />
                                                                <ListItemTextCustom style={{paddingLeft: 12}} secondary={"Sélectionner les produits"} />
                                                            </StyledMenuItem>
                                                            <StyledMenuItem onClick={() => { 
                                                                if (!this.state.attributesSelected) 
                                                                    return;

                                                                this.handleClose(); 
                                                                this.handleToggleDrawer('openFormAttributs');
                                                            }}>
                                                                <IcomoonReact iconSet={iconSet} color={!this.state.attributesSelected ? colors.grey.regular : colors.blue.regular} size={20} icon={'picto-attribut'}  />
                                                                <ListItemTextCustom style={{paddingLeft: 12}} secondary={this.state.attributesSelected 
                                                                    ? 'Gérer les attributs' 
                                                                    : 'Chargement...'} />
                                                            </StyledMenuItem>
                                                            <StyledMenuItem onClick={() => { this.handleClose(); this.handleToggleDialog('modalSynchro')}}>
                                                                <SyncIcon style={{fill: colors.blue.regular,  borderRadius: '50%', fontSize: '20px'}} />
                                                                <ListItemTextCustom style={{paddingLeft: 12}} secondary="Accéder aux synchronisations" />
                                                            </StyledMenuItem>
                                                            {process.env.REACT_APP_MODE_SPREAD !== "hub" && hasRights(CATALOGS, CATALOGS_CATALOGS, DELETE) ? 
                                                                (
                                                                    <StyledMenuItemDelete onClick={() => { this.handleClose(); this.handleToggleDialog('openDialog')}}>
                                                                        <DeleteOutlineIcon style={{fill: colors.white, marginLeft: -3}} />
                                                                        <ListItemTextCustom style={{paddingLeft: 12}} color={colors.white} secondary="Supprimer le catalogue" />
                                                                    </StyledMenuItemDelete>
                                                                ) : null
                                                            }
                                                        </StyledMenu>
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </ContainerStyled>

                                        <Typography variant="h4" component="div">
                                            <Box fontWeight="bold">
                                                {this.state.libelle}
                                            </Box>
                                        </Typography>

                                        <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: this.state.description }}/>
                                        
                                        <Grid
                                            container 
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                            style={{paddingBottom: this.state.nbProducts > 0 ? 0 : 24}}
                                        >
                                            <Grid item xs={6}>
                                                <Grid container>
                                                    <Grid item>
                                                        <CatalogNumbers variant="h1" typecatalog={this.state.type.identifier}>
                                                            {/* {this.state.nbProducts} */}
                                                            n/a
                                                        </CatalogNumbers>
                                                        <CatalogNbType variant="h4">
                                                            {this.state.nbProducts > 1 ? 'produits' : 'produit'}
                                                        </CatalogNbType>
                                                    </Grid>
                                                    <Grid item style={{marginLeft: 64}}>
                                                        <CatalogNumbers variant="h1" typecatalog={this.state.type.identifier}>
                                                            {this.state.categories.length}
                                                        </CatalogNumbers>
                                                        <CatalogNbType variant="h4">
                                                            {this.state.categories.length > 1 ? 'catégories' : 'catégorie'}
                                                        </CatalogNbType>
                                                    </Grid>
                                                    <Grid item style={{marginLeft: 64}}>
                                                        <CatalogNumbers variant="h1" typecatalog={this.state.type.identifier}>
                                                            {this.state.listAsset.length}
                                                        </CatalogNumbers>
                                                        <CatalogNbType variant="h4">
                                                            {this.state.listAsset.length > 1 ? 'assets' : 'asset'}
                                                        </CatalogNbType>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <ModalSynchro listAsset={this.state.listAsset} modalSynchro={this.state.modalSynchro} typeCron={this.state.typeCron} onClose={() => this.handleToggleDialog("modalSynchro")}/>
                                    </Box>
                                    {
                                        this.state.nbProducts > 0 && this.state.idlist.length > 0 ? (
                                            <Box>
                                                <Grid container  direction="column" style={{paddingTop: 12, paddingBottom: 8, background: colors.grey.lighter.hue980}} >
                                                    <Grid container direction="row" style={{paddingLeft: 16, paddingRight: 16, maxWidth: '100%'}}>
                                                        <Grid item md={12} sm={12} xs={12}>
                                                            <ListingProducts 
                                                                productBypage = {{
                                                                    card: [6,18,30],
                                                                    list: [10,20,30]
                                                                }}
                                                                nbperpage = {6}
                                                                currentLang={this.state.currentLang} 
                                                                windowWidth={this.props.windowWidth}
                                                                openForm={this.state.openForm}
                                                                listCategories={this.copyArrayOfObjects(this.state.categories)}
                                                                selectedCategories={this.state.selectedCategorie}
                                                                catalogId={parseInt(this.state.catalogId.replace('/api/catalogs/', ''))}
                                                                reload={this.state.reloadProductList}
                                                                onReload={() => {this.setState({reloadProductList : false})}}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ) : null
                                    }
                                </Box>
                            </CardCustom>
                        </GridCustom>   
                    </Grid>


                    {
                        //MODIFICATION DES INFORMATIONS CATALOGUES
                        this.state.openEditForm ? 
                            <LayoutBuilder
                                handleCancel={this.resetStateCatalog}
                                opened={this.state.openEditForm}
                                validateButton={true}
                                handlerMutation={this.handlerMutation} 
                                icomoon={
                                    this.state.catalogData.type.identifier === 'ephemeral' ? 
                                        'ico-ajouter-cata-ephemere' 
                                    : this.state.catalogData.type.identifier === 'permanent' ? 
                                        'ico-ajouter-cata-permanent' 
                                    :   'icon-ico-ajouter-cata-thematique'
                                }
                                keepColorIcomoon={true}
                                dataLayout={catalogEditForm(this.state.catalogData, this.handleMediaPicker, this.state.allCatalogsTypes, this.props.attributes.product.attributes)} 
                                allState={this.state} 
                                stateCallback={this.handleInputChange} 
                                errorCallback={this.handleFormError}
                                drawerWidth={this.state.drawerWidthModified}
                                handleButtonGroupChange={this.handleButtonGroupChange}
                                forClose={() => this.handleToggleDrawer('openEditForm', true)} 
                                dataCard={this.state.typeOfCatalogs}
                                stepperButtonAction={[
                                    () => this.setState({
                                        styleInnerContainer : {
                                            boxShadow: 'none',
                                            backgroundInner: colors.white,
                                            backgroundActions : colors.white,
                                            padding: '0px',
                                            height: '450px'
                                        }
                                    }),
                                    () => {
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                            this.setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
        
                                        this.handleDrawerWidthChange(300)
        
                                        return true;
                                    }
                                ]}
                                backStepperButtonAction={[
                                    () => this.handleDrawerWidthChange(300),
                                    () => this.setState({
                                        backgroundDrawer : colors.grey.lighter.hue980,
                                        styleInnerContainer : {
                                            boxShadow: 'none',
                                            backgroundInner: colors.white,
                                            backgroundActions: colors.grey.lighter.hue980,
                                            padding: '0px',
                                            height: '630px'
                                        }
                                    }),
                                    () => this.handleDrawerWidthChange(-800),
                                ]}
                            />
                        : null
                    }

                    {
                        //FORMULAIRE GESTION CATEGORIES ET PRODUITS
                        this.state.type && this.state.treeData && this.state.secondTreeData && this.state.filteredCat && this.handleMediaPicker && this.state.allCatalogsTypes && this.state.allGroups ? 
                        (
                            <LayoutBuilder 
                                listProducts={this.listProducts}
                                allCategories={this.state.allCategories}
                                handleChangeCategorie={this.handleChangeCategorie}
                                resetReloadListing={() => this.setState({reloadProductFormList: false})}
                                selectedCategorie={this.state.selectedCategorie}
                                productByCategorie={this.state.productByCategorie}
                                selectedProductByCategorie={this.state.selectedProductByCategorie}
                                styleInnerContainer={this.state.styleInnerContainer}
                                backgroundColor={this.state.backgroundDrawer}
                                treeAddCat={this.addSubcategory}
                                treeEditCat={this.editCat}
                                expandNodes={this.expandNodes}
                                copyCategories={this.copyCategories}
                                handleCancel={this.resetStateCatalog}
                                opened={this.state.openForm} 
                                handlerMutation={this.handlerMutationProducts}
                                icomoon={this.state.type.identifier === 'ephemeral' 
                                    ? 'ico-modifier-cata-ephemere' 
                                    : this.state.type.identifier === 'permanent' 
                                        ? 'ico-modifier-cata-permanent' 
                                        : 'ico-modifier-cata-thematique'
                                }
                                dataLayout={catalogEditProductsCategories(this.state.type, this.state.treeData, this.state.secondTreeData, this.state.filteredCat, this.handleMediaPicker, this.state.allCatalogsTypes,this.state.allGroups)} 
                                allState={this.state} 
                                stateCallback={this.handleInputChange} 
                                errorCallback={this.handleFormError}
                                drawerWidth={this.state.drawerWidthModified}
                                forClose={() => this.handleToggleDrawer('openForm', true)} 
                                handlerSetup={this.handlerSetup} 
                                dataCard={this.state.typeOfCatalogs} 
                                validateButton={true}
                                stepperButtonAction={[
                                    () => this.setState({
                                        styleInnerContainer : {
                                            boxShadow: 'none',
                                            backgroundInner: colors.white,
                                            backgroundActions : colors.white,
                                            padding: '0px',
                                            height: '450px'
                                        }
                                    }),
                                    () => {
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                            this.setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
        
                                        this.handleDrawerWidthChange(300)
        
                                        return true;
                                    }
                                ]}
                                backStepperButtonAction={[
                                    () => this.handleDrawerWidthChange(300),
                                    () => this.setState({
                                        backgroundDrawer : colors.grey.lighter.hue980,
                                        styleInnerContainer : {
                                            boxShadow: 'none',
                                            backgroundInner: colors.white,
                                            backgroundActions: colors.grey.lighter.hue980,
                                            padding: '0px',
                                            height: '630px'
                                        }
                                    }),
                                    () => this.handleDrawerWidthChange(-800),
                                ]}
                                setSelectedProducts={this.setSelectedProducts}
                                addProducts={()=> this.handleToggleDrawer('openProductSelector')}
                                handleTreeChange={this.handleTreeChange}
                            />
                        ) : null
                    }
                    {
                        //FORMULAIRE SELECTIONS PRODUITS
                        this.state.type && this.state.treeData && this.state.secondTreeData && this.state.filteredCat && this.handleMediaPicker && this.state.allCatalogsTypes && this.state.allGroups ? 
                        (
                            <LayoutBuilder 
                                noAdd={true}
                                listProducts={this.listProducts}
                                allCategories={this.state.allCategories}
                                handleChangeCategorie={this.handleChangeCategorie}
                                resetReloadListing={() => this.setState({reloadProductFormList: false})}
                                selectedCategorie={this.state.selectedCategorie}
                                productByCategorie={this.state.productByCategorie}
                                selectedProductByCategorie={this.state.selectedProductByCategorie}
                                styleInnerContainer={this.state.styleInnerContainer}
                                treeAddCat={this.addSubcategory}
                                treeEditCat={this.editCat}
                                expandNodes={this.expandNodes}
                                copyCategories={this.copyCategories}
                                handleCancel={this.resetStateCatalog}
                                opened={this.state.openSelectionProduct} 
                                handlerMutation={this.handlerMutationProducts}
                                icomoon={this.state.type.identifier === 'ephemeral' 
                                    ? 'ico-modifier-cata-ephemere' 
                                    : this.state.type.identifier === 'permanent' 
                                        ? 'ico-modifier-cata-permanent' 
                                        : 'ico-modifier-cata-thematique'
                                }
                                dataLayout={catalogEditSelectionsProducts(this.state.type, this.state.treeData, this.state.secondTreeData, this.state.filteredCat, this.handleMediaPicker, this.state.allCatalogsTypes,this.state.allGroups)} 
                                allState={this.state} 
                                stateCallback={this.handleInputChange} 
                                errorCallback={this.handleFormError}
                                drawerWidth={this.state.drawerWidthModified}
                                forClose={() => this.handleToggleDrawer('openSelectionProduct', true)} 
                                setSelectedProducts={this.setSelectedProducts}
                            />
                        ) : null
                    }

                    {
                        //FORMULAIRE GESTION DES ATTRIBUTS
                        this.state.allCatalogsTypes && this.state.allGroups ? 
                        (
                            <LayoutBuilder 
                                styleInnerContainer={this.state.styleInnerContainer}
                                backgroundColor={this.state.backgroundDrawer}
                                handleCancel={this.resetStateCatalog}
                                opened={this.state.openFormAttributs} 
                                handlerMutation={() => {this.handlerMutation(true)}}
                                icomoon={this.state.type.identifier === 'ephemeral' 
                                    ? 'ico-modifier-cata-ephemere' 
                                    : this.state.type.identifier === 'permanent' 
                                        ? 'ico-modifier-cata-permanent' 
                                        : 'ico-modifier-cata-thematique'
                                }
                                dataLayout={catalogEditAttributs(this.state.allGroups)} 
                                allState={this.state} 
                                stateCallback={this.handleInputChange} 
                                errorCallback={this.handleFormError}
                                forClose={() => this.handleToggleDrawer('openFormAttributs', true)} 
                                handlerSetup={this.handlerSetup} 
                                validateButton={true}
                                stepperButtonAction={[
                                    () => {
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                            this.setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
                                        return true;
                                    }
                                ]}
                            />
                        ) : null
                    }

                    { 
                        //FORMULAIRE DE MODIFICATION DES CATEGORIES
                        this.state.openFormCategories ? 
                            <LayoutBuilder 
                                styleInnerContainer={this.state.styleInnerContainer}
                                opened={this.state.openFormCategories} 
                                forClose={() => this.handleToggleDrawer('openFormCategories', false)} 
                                handlerMutation={this.handleMutationCategories} 
                                dataLayout={this.state.editForm === 'edit' ? 
                                        formCategoriesEdit(this.state.cat_id, this.copyArrayOfObjects(this.state.categories), this.state.parent, this.props.attributes.category.attributes, this.state.currentLang, this.state.errors, this.state.seeErrors, this.handleMediaPicker,this.props.attributes.product.attributes, true) 
                                    :   formCategoriesAdd(this.copyArrayOfObjects(this.state.categories), this.state.parent, this.props.attributes.category.attributes, this.state.currentLang, this.state.errors, this.state.seeErrors, this.handleMediaPicker, true)} 
                                icomoon={this.state.editForm === 'edit' ? 'ico-modifier-categorie' : 'ico-ajouter-categorie'}
                                allState={this.state} 
                                stateCallback={this.handleInputChange} 
                                errorCallback={this.handleFormError}
                                deleteMutation={this.state.editForm === 'edit' ? () => {this.handleToggleDialog('openDialogCategory')} : null}
                                deleteText={this.state.editForm === 'edit' ? 'Supprimer la catégorie' : null}
                                deleteButton={this.state.editForm} 
                                validateButton={true}
                                currentLang={this.state.currentLang}
                                handleLang={this.handleLang}
                                handleButtonGroupChange={this.handleButtonGroupChange}
                            /> 
                        : null
                    }

                    {
                        // FORMULAIRE D'AJOUT DE PRODUITS PAR CATEGORIE
                        this.state.openProductSelector ? 
                            <LayoutBuilder
                                drawerWidth = {this.state.drawerWidthModified}
                                opened = {this.state.openProductSelector}
                                dataLayout = {productSelectorConfig(infoCatSelected)}
                                stateCallback = {this.addProducts}
                                allState = {this.state}
                                forClose = {()=> this.handleToggleDrawer('openProductSelector')}
                                icomoon = {'picto-produit'}
                                currentLang = {this.state.currentLang}
                                styleInnerContainer={{boxShadow: 'inherit'}}
                                expandNodes={this.expandNodes}
                                handleTreeChange={this.handleTreeChange}
                            />
                        : null
                    }

                    <DialogModal 
                        open={this.state.openDialog} 
                        title={`Êtes-vous sûr de vouloir supprimer ce catalogue ?`}
                        primaryAction={this.deleteMutation} primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
                        secondaryAction={() => {this.handleToggleDialog('openDialog')}} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                        notText={true}
                    >
                        Si vous supprimez ce catalogue celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                        {
                            this.state.listAsset.length > 0 ? ( 
                                this.state.listAsset.length === 1 ? (
                                        <div>
                                            <WarningIcon style={{color: colors.red.regular}}/>
                                            <Typography variant="h5" style={{color: colors.red.regular}}>
                                            Attention, l'asset {this.state.listAsset[0].node?.assetDatas.edges[0].node.value} est lié à ce catalogue et sera automatiquement effacé si vous décidez de supprimer ce catalogue.
                                            </Typography>
                                        </div>
                                    
                                ) : (
                                        <div style={{display: 'flex', paddingTop: '32px', justifyContent: 'center', alignItems: 'center'}}>
                                            <WarningIcon style={{color: colors.red.regular}}/>
                                            <Typography style={{color: colors.red.regular,  paddingLeft: '24px', fontSize: '14px'}}>
                                                Attention, les assets <span style={{fontWeight: 'bold'}}>
                                                {
                                                    this.state.listAsset.map((data, index) => (index === this.state.listAsset.length - 1 ?` et ${data.node?.assetDatas?.edges[0].node.value} ` : ` ${data.node?.assetDatas.edges[0].node.value},`))
                                                }
                                                </span> sont liés à ce catalogue et seront automatiquement effacés si vous décidez de supprimer ce catalogue.
                                            </Typography>
                                        </div>
                                )
                            ) : null
                        }

                    </DialogModal>

                    <DialogModal 
                        open={this.state.openDialogCategory} 
                        title={`Êtes-vous sûr de vouloir supprimer cette catégorie ?`}
                        primaryAction={this.deleteMutationCategory} primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
                        secondaryAction={() => {this.handleToggleDialog('openDialogCategory')}} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                    >
                        Si vous supprimez cette catégorie celle-ci ne sera plus accessible. Si vous ne souhaitez pas la supprimer, annulez la suppression en cliquant sur annuler.
                    </DialogModal>
                
                    <DialogModal
                        open={this.state.openDialogTreeView}
                        title={`Sélectionnez la catégorie parent :`}
                        notText={true}
                        primaryAction={this.validateNewCategorie} primarybgcolor={colors.green.regular} primarybgcolorhover={colors.green.darker} primaryText="Valider" primaryDisabled={this.state.selectCategorie?.node ? false : true}
                        secondaryAction={() => {this.handleToggleDialog('openDialogTreeView')}} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                    >
                        <SearchBar value={this.state.searchModal} placeholder="Rechercher une catégorie" onChange={(e) => this.handleInputChange('searchModal', e)} /> 
                        <TreeView 
                            typeOfTree={'selectCategorie'}
                            reducedTree={true}
                            dataTree={this.state.secondTreeData}
                            selectCategorie={this.selectCategorie}
                            onChange={treeData => this.handleTreeChange('secondTreeData', treeData)}
                            searchQuery={this.state.searchModal}
                            expand={this.expandNodes}
                            stateName="secondTreeData"
                        /> 
                    </DialogModal>
                </div>
            ) : <PageLoader />
        );
    }

    prepareFirstTree(init) {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_CATEGORIES_LIGHT,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    allCategories: this.copyArrayOfObjects(result.data.categories),
                    allCategoriesReady: true
                }, () => {
                    this.processTree('treeData', result.data.categories, e => e.parent === null && e.libelle === 'Root', init);
                    resolve();
                });
            });
        });
    }

    prepareSecondTree = async (categories, init, exe = false) => {
        this.processTree('secondTreeData', categories, e => e.parent === null, init);
        if(exe){
            let root            = this.state.secondTreeData[0];
            let allCategories   = [root.id];

            for (let child of root.children) {
                await this.processCategory(child, root.id, allCategories);
            }

            this.props.client.query({
                query: GET_CATALOGS_CATEGORIES_BY_ID,
                variables: { id: this.state.idCatalog },
                fetchPolicy: 'no-cache',
            }).then(result => {
                let findCatalog = result.data.catalog;
                
                this.setState({
                    categories: findCatalog.categories,
                    openDialogTreeView: false,
                }, () => {
                    this.prepareSecondTree(this.state.categories, true);
                    this.props.stopLoading();
                }); 
            });
        }
    }

    processTree = (stateName, categories, getRoot, init = false) => {
        let data = categories.filter(getRoot);

        for (let parent of data) {
            this.convertToNode(parent, true, init);
            this.populateChildren(categories, parent, init);
        }

        if (stateName === 'secondTreeData') {
            let productByCategorie          = this.state.productByCategorie || {};
            let selectedProductByCategorie  = this.state.selectedProductByCategorie || {};

            for (let categorie of this.state.categories) {
                // let created = selectedProductByCategorie[categorie.id] === undefined;

                productByCategorie[categorie.id]           = productByCategorie[categorie.id] || [];
                selectedProductByCategorie[categorie.id]   = selectedProductByCategorie[categorie.id] || [];
                if(categorie.productCategories.edges.length > 0){
                    for (let product of categorie.productCategories.edges) {
                        let found           = productByCategorie[categorie.id].find(e => e.node.id === product.node.product.id);
                        // let foundSelected   = selectedProductByCategorie[categorie.id].find(e => e === product.node.product.id);
    
                        if (!found)
                            productByCategorie[categorie.id].push(product);
    
                        // if (!foundSelected && created)
                        //     selectedProductByCategorie[categorie.id].push(product.node.product.id);
                    }
                }
            }

            this.setState({ 
                productByCategorie,
                selectedProductByCategorie
            });
        }
        this.setState({ 
            [stateName]: this.copyArrayOfObjects(data) 
        });
    };

    convertToNode(data, isRoot = false, init = false) {
        let attributes = [];
        // let filters = [];

        if (init) {
            for (let { node } of data.categoryDatas.edges) {
                let found = false;
                
                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;
    
                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.value,
                            media: {
                                id: node.media ? node.media.id : null,
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id // category data id
                        });
                    }
                }
    
                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        locales: [{
                            value: node.value,
                            media: {
                                id: node.media ? node.media.id : null,
                                data: node.media ? node.media.filePath : null,
                                file: null,
                                changed: false
                            },
                            id: node.locale.id,
                            code: node.locale.code,
                            categoryDataId: node.id
                        }]
                    });
                }
            }
            // if (data.categoryFilters && data.categoryFilters.edges.length > 0){
            //     for (let filter of data.categoryFilters.edges){
            //         if (filter){
            //             let getFilterData = {
            //                 ...filter.node,
            //                 attributeType: {node: filter.node.attribute}
            //             }
            //             filters.push(getFilterData)
            //         }
            //     }
            // }
        }
        
        data.attributes = init ? attributes : data.attributes;
        // data.filters = init ? filters : data.filters;
        data.filters = []; // created when opening a cat in tree

        let nameAttribute = data.attributes.find(e => e.identifier === 'category_name');

        let getTraduction = nameAttribute ? nameAttribute.locales.find(
            translation => translation.code === this.state.currentLang
        ) : null;

        data.title          = isRoot ? '/' : getTraduction?.value ?? nameAttribute?.locales[0]?.value ?? data.libelle;
        data.isDirectory    = true;
        data.isRoot         = isRoot;
        data.dragDisabled   = isRoot;
        data.expanded       = isRoot ? true : data.expanded;
    }

    populateChildren(cats, parent, init) {
        parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);
        
        for (let child of parent.children) {
            this.convertToNode(child, false, init);
            this.populateChildren(cats, child, init);
        }
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => ({...a})); 

    // goTo = route => this.props.history.push(route);
    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: { productId : id }
        });
    };
};

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineCatalogsDetails)));