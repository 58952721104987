import React from 'react';
import {Grid} from '@material-ui/core';

import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '../../../ui/button/Button';
import TabCategories from './TabCategories';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardSetup from '../../../layouts/Card/cardContent/CardSetup';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';

import { withApollo } from 'react-apollo';
import { 
    GET_CHANNEL_CATEGORIES, 
    GET_CHANNELS_BY_CATEGORY, 
    GET_CHANNEL_BY_ID, 
    ADD_CHANNEL, 
    UPDATE_CHANNEL, 
    ADD_CHANNEL_DATA, 
    GET_CHANNEL_EXTERNAL_KEYS, 
    ADD_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
    GET_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
    GET_CHANNEL_DATA,
    DELETE_CHANNEL,
    DELETE_CHANNEL_DATA,
    DELETE_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
    UPDATE_CHANNEL_DATA,
    UPDATE_CHANNEL_EXTERNAL_KEY_ATTRIBUTE
} from '../../../../queries/channels';
import { GET_ATTRIBUTES } from '../../../../queries/attributes';
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';

import { eventService } from '../../../../js/services/event.service';

import EmptyCMS from '../../../../assets/pictos/empty-picto/empty_cms.png';
import EmptyMails from '../../../../assets/pictos/empty-picto/empty_mails.png';
import EmptyMedias from '../../../../assets/pictos/empty-picto/empty_medias.png';
import EmptyConnectors from '../../../../assets/pictos/empty-picto/empty_connectors.png';
import EmptySocial from '../../../../assets/pictos/empty-picto/empty_rs.png';
import EmptyMarketplace from '../../../../assets/pictos/empty-picto/empty_marketplaces.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import cmsConfig from './config/cms.config';
import cmsAddConfig from './config/cmsAdd.config';

import request from '../../../../js/utils/fetch';
import slugify from 'slugify';
import { hasRights } from '../../../../js/utils/rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { 
    CONNECTORS,
    CONNECTORS_CMS, 
    CONNECTORS_SOCIAL_NETWORK, 
    CONNECTORS_MARKETPLACES, 
    CONNECTORS_MEDIAS, 
    CONNECTORS_EMAILS, 
    CONNECTORS_CONTENTS, 
    CONNECTORS_CONNECTORS, 
    VIEW, 
    CREATE,
    UPDATE,
    DELETE,
} from '../../../../js/constants/constant-rights';
import {setRedux} from '../../../../js/utils/functions';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

class ListChannels extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            filtered: [],
            filteredCat: [],
            valueSelect: [],
            imageForm: '',
            openForm: false,
            openFormSetupChannel: false,
            editForm: 'add',
            isEmpty : false,
            identifier: props.typeChannel,
            channelCategorie: null,
            channelByCategorie: null,
            emptyPicto: null,
            dataLayout: null,
            openDialogRemove: false,
            currentLang: props.locales[0].node.id,
            errors: {},
            seeErrors: false,
            getRights: null,
            typeTesting: {
                type: 'channel',
                testingState: ['libelle', 'attributeIdentifier'],
                testingTypingState: 'libelle',
                identifierState: 'attributeIdentifier'
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.typingTimer = null;
    }
    
    handleChange(e) {
        let currentList = [];
        let newList = [];
        let newList2 = [];
        let result = [];

        if(e){
            if (e.target.value !== "") {
                currentList = this.state.filteredCat;
                newList = currentList.filter(item => {
                    const lc = item.node.libelle.toLowerCase();
                    const filter = e.target.value.toLowerCase();
                    return lc.includes(filter);
                });
                newList2 = currentList.filter(item => {
                    const lc = item.node.description.toLowerCase();
                    const filter = e.target.value.toLowerCase();
                    return lc.includes(filter);
                });
                result = newList.concat(newList2);
                
                let j = 0;
                for(let item of result){
                    let i = 0;
                    for(let item2 of result){
                        if(item.node.id === item2.node.id  && j < i){
                            result.splice(i)
                        }
                        i++;
                    }
                    j++;
                }

            } else {
                // If the search bar is empty, set newList to original task list
                result = this.state.filteredCat;
            }
            // Set the filtered state based on what our rules added to newList
            this.setState({
                filtered: result
            });

        } else {
            this.setState({
                filtered: this.state.filteredCat
            });
        }
    }
    handleChangeTab = (event, newValue, id, libelle) => {
        let currentList = [];
        let newList = [];
        if (id) {
            currentList = this.state.channelByCategorie;
            newList = currentList.filter(item => {
                // for(let channel of item){
                    if(item.node.attributeGroup.id === id){
                        const lc = id.toLowerCase();
                        // change search term to lowercase
                        const filter = id.toLowerCase();
                        // check to see if the current list item includes the search term
                        // If it does, it will be added to newList. Using lowercase eliminates
                        // issues with capitalization in search terms and search content
                        return lc.includes(filter);
                    }else{
                        return null
                    }
                // }
            });
        } else {
            newList = this.state.channelByCategorie;
        }
        this.setState({
            filteredCat: newList
        });
    };

    doneTyping = (stateName) => {
        let typeTesting = this.state.typeTesting;
        if(stateName === typeTesting.testingTypingState){
            this.setState({
                [typeTesting.identifierState]: slugify(this.state[typeTesting.testingTypingState], {replacement :'_', lower: true, remove: /[^\w\-\s]+/g}),
            });
        }
        
        if(this.state[typeTesting.identifierState]){
            request(`${process.env.REACT_APP_API}/unique/${typeTesting.type}/${this.state[typeTesting.identifierState]}`, 'get').then(
                (data) => {
                    if(data.success){
                        eventService.fire({stateName: typeTesting.identifierState, errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.'});
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {this.doneTyping(stateName)}, 500);
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];
            if (!values) {
                values = {};
            }
            values[stateName] = value;
            this.setState({
                [this.state.currentLang]: values,
            });
        } else{
            this.setState({
                [stateName]: value,
            });
        }
        if(this.state.editForm === 'add' && this.state.typeTesting.testingState.includes(stateName))
            this.checkIdentifier(stateName)
    }

    handleInputChange = (stateName, evt, custom, translated ) => {  
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    }

    handleInitData = (typeChannel, firstTime) => {
        let getRights = null
        if(typeChannel === 'cms'){
            getRights = CONNECTORS_CMS;
            this.setState({ 
                content:{
                    title: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gérer les CMS" : "Gérer les channels de ventes",
                    subtitle: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gestion de vos CMS (création / modification / suppression)" : "Gestion de vos channels de ventes (création / modification / suppression)",
                    picto: "icon-cms",
                    txtBtn: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Connecter un nouveau CMS" : null,
                    emptyTitle: "Vous n'avez pas encore configuré de CMS",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                    emptyTxtBtn: "Ajouter un CMS",
                    emptyPicto: EmptyCMS,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_CMS,
            }, () => {
                let finalStep = 
                    {
                        // picto: EmptyCMS,
                        title: 'Et voilà !',
                        subtitle: 'Votre nouveau compte est prêt à être connecté à SpreadSuite !',
                        textButton: 'Connecter'
                    }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout
                });
            });
        }
        else if(typeChannel === 'social'){
            getRights = CONNECTORS_SOCIAL_NETWORK;
            this.setState({
                content:{
                    title: "Gérer les réseaux sociaux",
                    subtitle: "Gestion de vos réseaux sociaux (création / modification / suppression)",
                    picto: "picto-rs",
                    txtBtn: "Ajouter un compte",
                    emptyTitle: "Vous n’avez pas encore configuré de Réseaux sociaux",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                    emptyTxtBtn: "Ajouter un réseau social",
                    emptyPicto: EmptySocial,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_SOCIAL_NETWORK,
            }, () => {
                let finalStep = 
                {
                    // picto: EmptySocial,
                    title: 'Et voilà !',
                    subtitle: 'Votre nouveau compte est prêt à être connecté à SpreadSuite !',
                    textButton: 'Connecter'
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout
                });
            });
        }
        else if(typeChannel === 'marketplace'){
            getRights = CONNECTORS_MARKETPLACES;
            this.setState({
                content:{
                    title: "Gérer les Marketplaces",
                    subtitle: "Gestion de vos Marketplaces (création / modification / suppression)",
                    picto: "picto-marketplace",
                    txtBtn: "Ajouter une marketplace",
                    emptyTitle: "Vous n’avez pas encore configuré de Marketplace",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter une",
                    emptyTxtBtn: "Ajouter une marketplace",
                    emptyPicto: EmptyMarketplace,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_MARKETPLACES,
            }, () => {
                let finalStep = 
                {
                    // picto: EmptyMarketplace,
                    title: 'Et voilà !',
                    subtitle: 'Votre nouveau compte est prêt à être connecté à SpreadSuite !',
                    textButton: 'Connecter'
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout
                });
            });
        } else if(typeChannel === 'media'){
            getRights = CONNECTORS_MEDIAS;
            this.setState({ 
                content:{
                    title: "Gérer les connecteurs",
                    subtitle: "Gérez ici vos connecteurs de médias",
                    picto: "picto-connector",
                    txtBtn: "Connecter un nouveau connecteur médias",
                    emptyTitle: "Vous n'avez pas encore configuré de connecteur médias",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                    emptyTxtBtn: "Ajouter un connecteur médias",
                    emptyPicto: EmptyMedias,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_MEDIAS,
            }, () => {
                let finalStep = 
                {
                    // picto: EmptyCMS,
                    title: 'Et voilà !',
                    subtitle: 'Votre nouveau compte est prêt à être connecté à SpreadSuite !',
                    textButton: 'Connecter'
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout
                });
            });
        } else if(typeChannel === 'newsletter'){
            getRights = CONNECTORS_EMAILS;
            this.setState({ 
                content:{
                    title: "Gérer les connecteurs",
                    subtitle: "Gérez ici vos connecteurs de mailers",
                    picto: "picto-newsletter",
                    txtBtn: "Connecter un nouveau connecteur email",
                    emptyTitle: "Vous n'avez pas encore configuré de connecteur email",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                    emptyTxtBtn: "Ajouter un connecteur email",
                    emptyPicto: EmptyMails,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_EMAILS,
            }, () => {
                let finalStep = 
                {
                    // picto: EmptyCMS,
                    title: 'Et voilà !',
                    subtitle: 'Votre nouveau compte est prêt à être connecté à SpreadSuite !',
                    textButton: 'Connecter'
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout
                });
            });
        }
        else if(typeChannel === 'content'){
            getRights = CONNECTORS_EMAILS;
            this.setState({ 
                content:{
                    title: "Gérer vos connecteurs de contenus",
                    subtitle: "Gérez ici vos connecteurs pour vos contenus",
                    picto: "picto-connector-content",
                    txtBtn: "Connecter un nouveau connecteur contenu",
                    emptyTitle: "Vous n'avez pas encore configuré de connecteur",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                    emptyTxtBtn: "Ajouter un connecteur",
                    emptyPicto: EmptyCMS,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_CONTENTS,
            }, () => {
                let finalStep = 
                {
                    // picto: EmptyCMS,
                    title: 'Et voilà !',
                    subtitle: 'Votre nouveau compte est prêt à être connecté à SpreadSuite !',
                    textButton: 'Connecter'
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout
                });
            });
        }
        else if(typeChannel === 'connector'){
            getRights = CONNECTORS_CONNECTORS;
            this.setState({ 
                content:{
                    title: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gérer vos connecteurs" : "Gérer vos logisticiens",
                    subtitle: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gérez ici vos connecteurs" : "Gérer ici vos logisticiens",
                    picto: "picto-connector",
                    txtBtn: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Connecter un nouveau connecteur contenu" : null,
                    emptyTitle: "Vous n'avez pas encore configuré de connecteur",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                    emptyTxtBtn: "Ajouter un connecteur",
                    emptyPicto: EmptyConnectors,
                },
                dataLayout: cmsConfig,
                dataAddLayout: cmsAddConfig(),
                getRights: CONNECTORS_CONNECTORS,
            }, () => {
                let finalStep = 
                {
                    // picto: EmptyCMS,
                    title: 'Et voilà !',
                    subtitle: 'Votre nouveau compte est prêt à être connecté à SpreadSuite !',
                    textButton: 'Connecter'
                }
                let getLayout = this.state.dataAddLayout
                getLayout.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout: getLayout
                });
            });
        }
        if ( process.env.REACT_APP_MODE_SPREAD === "hub"){
            request(`${process.env.REACT_APP_API}/warehousePreference`, 'get', false).then(
                (data) => {
                    if(data.success){
                        this.setState({
                            preferences: data.preferences
                        });
                    }
                }
            );
        }

        const getRightsStatus = hasRights(CONNECTORS, getRights, VIEW)
        if (!getRightsStatus){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }

        if (firstTime && this.props.history.location?.state?.formOpen){
            this.handleToggleDrawer('form')
        }
    }

    handlerSetup = (id, typeId) => {
        this.props.client.query({
            query: GET_CHANNEL_BY_ID,
            variables: {id: id},
            fetchPolicy: 'no-cache',
        }).then(result =>{            
            this.setState({
                imageForm: result.data.channel.logo,
                attrGroupId: result.data.channel.attributeGroup.id,
                typeOfId: typeId
            });

            if(this.state.editForm === 'edit'){
                let children = 0;
                for(let item of this.state.dataAddLayout.formConfig.children){
                    let optionsInputs = 0;
                    for(let input of item.optionsInputs){
                        if(input.stateName === "attributeIdentifier"){
                            let getLayoutAdd = this.state.dataAddLayout
                            getLayoutAdd.formConfig.children[children].optionsInputs[optionsInputs].disabled = true
                            this.setState({
                                dataAddLayout: getLayoutAdd
                            });
                        }
                        optionsInputs++;
                    }
                    children++;
                }
                this.setState({
                    libelle: result.data.channel.libelle,
                    attributeIdentifier: result.data.channel.identifier, 
                    description: result.data.channel.description
                });
                for(let item of result.data.channel.attributeGroup.attributes.edges){
                    let identifier = item.node.identifier;
                    for(let value of result.data.channel.channelDatas.edges){
                        if(value.node.attribute.identifier === identifier){
                            this.setState({
                                [identifier]: item.node.attributeType.input === 'select' ? value.node.attributeOption.id : value.node.value,
                            });
                        }
                    }
                }
                this.props.client.query({
                    query: GET_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
                    variables: {"limit":1000},
                    fetchPolicy: 'no-cache',
                }).then(result => {
                    this.setState({externalKeyAttribute: result.data.channelExternalKeyAttributes});
                    for(let externalKey of result.data.channelExternalKeyAttributes.edges){
                        if(externalKey.node.channel.id === id){
                            this.setState({
                                [`${externalKey.node.externalKey.externalKey}_externalkey`]: externalKey.node.attribute.id,
                            });
                        }
                    }
                })
            }

            else if(this.state.editForm === "add"){
                this.setState({
                    libelle: undefined,
                    description: undefined,
                    attributeIdentifier: undefined
                });
                let children = 0;
                for(let item of this.state.dataAddLayout.formConfig.children){
                    let optionsInputs = 0;
                    for(let input of item.optionsInputs){
                        if(input.stateName === "attributeIdentifier"){
                            let getLayoutAdd = this.state.dataAddLayout
                            getLayoutAdd.formConfig.children[children].optionsInputs[optionsInputs].disabled = false
                            this.setState({
                                dataAddLayout: getLayoutAdd
                            });
                        }
                        optionsInputs++;
                    }
                    children++;
                }
            }
            let getLayoutAdd = this.state.dataAddLayout
            getLayoutAdd.titleForm = result.data.channel.libelle
            this.setState({
                dataAddLayout: getLayoutAdd
            });
            let dataLayoutSetup = {
                labelName: 'Paramétrage',
                isOptionnal: false,
                optionsInputs:[]
            };

            //CONSTRUCTION PARAMETRAGE
            for(let item of result.data.channel.attributeGroup.attributes.edges){
                let label = '';
                if(item.node.translation.translationDatas){
                    label = item.node.translation.translationDatas.edges[0].node.value;
                }
                else{
                    label = item.node.identifier
                }
                
                let helper = '';
                if(item.node.attributeHelper){
                    helper = item.node.attributeHelper.translation.translationDatas.edges[0].node.value; 
                }
                else{
                    helper = item.node.identifier
                }

                let setupInput = {
                    type: item.node.attributeType.input,
                    label: label,
                    helper: {
                        text: helper,
                        link: false,
                    },
                    required: true,
                    stateName: item.node.identifier,
                    value: null
                }
                if(item.node.attributeType.input === 'select'){
                    setupInput.value = item.node.attributeOptions.edges.map(attribute => {
                        const langSelectedValuesDefault = attribute.node.translation.translationDatas.edges[0];
        
                        const langSelectedValues = attribute.node.translation.translationDatas.edges.find(
                            lang  => lang.node.locale.code === this.state.currentLang
                        );
        
                        return ({
                            value: attribute.node.id,
                            label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node.value ?? attribute.node.identifier
                        });
                    })
                }

                if(this.state.editForm === "edit"){
                    let idAttr = "";
                    for(let attr of result.data.channel.channelDatas.edges){
                        if(attr.node.attribute.identifier === item.node.identifier){
                            idAttr = attr.node.id;
                        }
                    }
                    setupInput.id = idAttr;
                }

                dataLayoutSetup.optionsInputs.push(setupInput)
                if(this.state.editForm === "add"){
                    this.setState({[item.node.identifier]: undefined});
                }
            }
            let configFormAdd = this.state.dataAddLayout;
            configFormAdd.formConfig.children.splice(1, 1, dataLayoutSetup);
            this.setState({
                dataAddLayout: configFormAdd
            })
            
            this.handleToggleDrawer('formSetupChannel');
            this.handlerGetAttributs();
        });
    }

    handlerGetAttributs = () => {
        this.props.client.query({
            query: GET_ATTRIBUTES,
            fetchPolicy: 'no-cache',
            variables: {"limit": 1000}
        }).then(result => {
            let valueSelect = [];
            for(let item of result.data.attributes.edges){
                for(let value of item.node.translation.translationDatas.edges){
                    if(value.node.locale.id === this.state.currentLang)
                    valueSelect.push({value: item.node.id, label: value.node.value, attributeGroup: item.node.attributeGroup.edges.node ? item.node.attributeGroup.edges.node.id : ''});
                }
            }
            this.setState(
                {
                    valueSelect: valueSelect
                }, this.state.identifier !== 'connector' ? this.handlerGetExternalKeys() : null
            );
        })
    };

    handlerGetExternalKeys = () => {
        let dataLayoutSetup = {
            labelName: 'Liaisons',
            isOptionnal: false,
            optionsInputs:[],
        };
        this.props.client.query({
            query: GET_CHANNEL_EXTERNAL_KEYS,
            variables: {"limit": 1000}
        }).then(result =>{
            let datas = result.data.channelExternalKeys.edges;
            let externalKeysConfig = []
            for(let item of datas){
                if(item.node.attributeGroup.id === this.state.attrGroupId){
                    externalKeysConfig.push(`${item.node.externalKey}_externalkey`)
                    let setLink = {
                        type: 'select',
                        label: item.node.translation.translationDatas.edges[0].node.value,
                        helper: {
                            text: item.node.translation.translationDatas.edges[0].node.value,
                            link: false,
                        },
                        required: true,
                        stateName: `${item.node.externalKey}_externalkey`,
                        id: item.node.id,
                        value: this.state.valueSelect
                    };
                    dataLayoutSetup.optionsInputs.push(setLink)
                    if(this.state.editForm === "add"){
                        let label = item.node.translation.translationDatas.edges[0].node.value;
                        let value = undefined;
                        for(let attr of this.state.valueSelect){
                            if(label === attr.label){
                                value = attr.value;
                            }
                        }
                        this.setState({[`${item.node.externalKey}_externalkey`]: value});
                    }
                }
            }
            
            let configFormAdd = this.state.dataAddLayout;
            configFormAdd.formConfig.children.splice(2, 1, dataLayoutSetup);
            this.setState({
                externalKeysConfig,
                dataAddLayout: configFormAdd
            });
        });
    }

    handleGetChannelByCategorie(id) {
        this.setState({
            typeOf: []
        })
        const getChannelByCategorie = GET_CHANNELS_BY_CATEGORY;
        const variables = {id: this.state.channelCategorie}
        this.props.client.query({
            query: getChannelByCategorie,
            variables,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.setState({ 
                channelByCategorie : [],
                categories: [],
                filtered: result.data.channelCategory.channels.edges,
                filteredCat: result.data.channelCategory.channels.edges,
            }, () => {
                let notIsSystem = 0;
                for(let channel of result.data.channelCategory.channels.edges){
                    if(channel.node.isSystem){
                        channel.node.isLocaleImage = true;
                        let isDisabled = channel.node.identifier === "cloudcommerce-mailer-template" || channel.node.identifier === "cloudcommerce-template"
                        if (isDisabled){
                            channel.node.disabled = true
                            channel.node.secondaryStyle = true
                        }
                        this.state.typeOf.push(channel.node);
                    }
                    else if(!channel.node.isSystem){
                        notIsSystem++;
                        this.state.channelByCategorie.push(channel)
                    }
                }
                if(this.state.typeOf.length === 0 || notIsSystem === 0){
                    this.setState({ 
                        isEmpty: true
                    });
                }
                if(this.state.channelByCategorie.length > 0){
                    this.setState({ 
                        isEmpty: false
                    });
                }
                this.forceUpdate()
            });
        });
    }

    handleGetChannelsCategories() {
        const getChannelsCategories = GET_CHANNEL_CATEGORIES;
        this.props.client.query({
            query: getChannelsCategories,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.listChannelsCategories = result.data.channelCategories.edges;
            for(let item of this.listChannelsCategories){
                if(item.node.identifier === this.state.identifier){
                    this.setState({ 
                        channelCategorie : item.node.id,
                    }, () => {
                        this.handleGetChannelByCategorie(this.state.channelCategorie)
                    });
                }
            }
        });
    }

    handlerSaveExternalKeyMap = () => {
        for(let item of this.state.dataAddLayout.formConfig.children[2].optionsInputs){
            if(this.state.editForm === "add"){
                this.props.client.mutate({
                    mutation: ADD_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
                    variables:{
                        "externalKey": item.id,
                        "attribute": this.state[item.stateName],
                        "channel": this.state.idNewChannel
                    },
                });
                this.handleToggleDrawer('form');
            }
            else if(this.state.editForm === "edit"){
                for(let channelExternalKeyAttribute of this.state.externalKeyAttribute.edges){
                    if(item.id === channelExternalKeyAttribute.node.externalKey.id){
                        if (channelExternalKeyAttribute.node.channel.id === this.state.idNewChannel){
                            this.props.client.mutate({
                                mutation: UPDATE_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
                                variables:{
                                    "id": channelExternalKeyAttribute.node.id,
                                    "externalKey": item.id,
                                    "attribute": this.state[item.stateName],
                                    "channel": this.state.idNewChannel
                                },
                            })
                        }
                    }
                }
            }
            
        }
        this.handleToggleDrawer('formSetupChannel');
        this.handleGetChannelByCategorie(this.state.channelCategorie);
        this.props.stopLoading();
    }
    handlerSaveChannelData = () => {
        for(let channel of this.state.typeOf){
            if(channel.attributeGroup.id === this.state.typeOfId){
                for(let item of channel.attributeGroup.attributes.edges){
                    let value = this.state[item.node.identifier];
                    if(this.state.editForm === "add"){
                        this.props.client.mutate({
                            mutation: ADD_CHANNEL_DATA,
                            variables: {
                                "value": item.node.attributeType.input === 'select' ? null : value,
                                "channel": this.state.idNewChannel,
                                "attribute": item.node.id, 
                                "locale": this.state.currentLang,
                                "attributeOption": item.node.attributeType.input === 'select' ? value : null,
                            },
                        })
                    }
                    else if(this.state.editForm === "edit"){
                        let idAttr = ""
                        for(let input of this.state.dataAddLayout.formConfig.children[1].optionsInputs){
                            if(item.node.identifier === input.stateName){
                                idAttr = input.id;
                            }
                        }
                        this.props.client.mutate({
                            mutation: UPDATE_CHANNEL_DATA,
                            variables: {
                                "id": idAttr,
                                "value": item.node.attributeType.input === 'select' ? null : value,
                                "channel": this.state.idNewChannel,
                                "attribute": item.node.id, 
                                "locale": this.state.currentLang,
                                "attributeOption": item.node.attributeType.input === 'select' ? value : null,
                            },
                        })
                    }
                }
            }
        }
    }
    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }
            this.props.startLoading();
            let query = null;
            let variables = null;
            switch(this.state.editForm){
                case 'edit' :
                    query = UPDATE_CHANNEL;
                    variables = 
                    {
                        "id": this.state.editId,
                        "libelle": this.state.libelle,
                        "description": this.state.description 
                    }
                break;
                case 'add' :
                    query = ADD_CHANNEL;
                    variables = 
                    {
                        "identifier": this.state.attributeIdentifier,
                        "category": this.state.channelCategorie,
                        "attributeGroup": this.state.attrGroupId,
                        "libelle": this.state.libelle,
                        "logo": this.state.imageForm,
                        "description": this.state.description, 
                        "isSystem": false
                    }
                break;
                default:
                    return null;
            }
            this.props.client.mutate({
                mutation: query,
                variables,
            }).then(result =>{
                if(this.state.editForm === 'add'){
                    this.setState({
                        idNewChannel: result.data.createChannel.channel.id
                    },() => {
                        this.handlerSaveChannelData();
                        if (this.state.identifier !== 'connector'){
                            this.handlerSaveExternalKeyMap()
                        }else{
                            this.handleToggleDrawer('formSetupChannel');
                            this.handleGetChannelByCategorie(this.state.channelCategorie);
                            this.props.stopLoading();
                        }
                    });
                }
                else if(this.state.editForm === "edit"){
                    this.setState({
                        idNewChannel: this.state.editId
                    },() => {
                        this.handlerSaveChannelData();
                        if (this.state.identifier !== 'connector'){
                            this.handlerSaveExternalKeyMap()
                        }else{
                            this.handleToggleDrawer('formSetupChannel');
                            this.handleGetChannelByCategorie(this.state.channelCategorie);
                            this.props.stopLoading();
                        }
                    });
                }
            })
            if (this.state.editForm === 'add'){
                this.props.snack(ALERT_SUCCESS, 'Channel ajouté !');
            } else if(this.state.editForm === "edit"){
                this.props.snack(ALERT_SUCCESS, 'Channel modifié !');
            }
            setRedux(false,true,false,false)
            this.props.stopLoading();
        } catch {
            this.props.snack(ALERT_ERROR, 'Une erreur est survenue');
            this.props.stopLoading();
        }
    };

    deleteChannelExternalKeyAttributes = () => {
        return new Promise(async (resolve, reject) => {
            const result = await this.props.client.query({
                variables: {"limit": 100000},
                query: GET_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
                fetchPolicy: 'no-cache'
            })
            for(let item of result.data.channelExternalKeyAttributes.edges){
                if(item.node.channel.id === this.state.editId){
                    await this.props.client.mutate({
                        mutation: DELETE_CHANNEL_EXTERNAL_KEY_ATTRIBUTE,
                        variables: {"id": item.node.id}
                    });
                }
            }
            resolve();
        });
    }
    deleteChannelDatas = () => {
        return new Promise(async (resolve, reject) => {
            const result = await this.props.client.query({
                variables: {"limit": 1000},
                query: GET_CHANNEL_DATA,
                fetchPolicy: 'no-cache'
            })
            for(let item of result.data.channelDatas.edges){
                if(item.node.channel.id === this.state.editId){
                    await this.props.client.mutate({
                        mutation: DELETE_CHANNEL_DATA,
                        variables: {"id": item.node.id}
                    })
                }
            }
            resolve();
        });
    }

    deleteChannel = () => {
        this.props.client.mutate({
            mutation: DELETE_CHANNEL,
            variables: {"id": this.state.editId},
        }).then(result =>{
            this.handleGetChannelByCategorie(this.state.channelCategorie);
            this.handleToggleDrawer('formSetupChannel');
            this.setState({ 
                openDialogRemove: false
            });
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Channel supprimé !');
        });
    }

    deleteMutation = async () => {
        this.props.startLoading();
        await this.deleteChannelExternalKeyAttributes();
        await this.deleteChannelDatas();
        this.deleteChannel();
    }

    editChannel = (id, typeId) => {
        this.setState({
            editForm: 'edit',
            editId: id,
        },() => {this.handlerSetup(id,typeId)});
    }

    handleToggleDialog = () => {
        this.setState({ 
            openDialogRemove : !this.state.openDialogRemove
        });
    }
    handleToggleDrawer = (drawer) => {
        if(drawer === 'form'){
            if (this.state.externalKeysConfig?.length > 0){
                for (let externalKeyName of this.state.externalKeysConfig){
                    this.setState({
                        [externalKeyName]: null
                    })
                }
            }
            this.setState({ 
                openForm : !this.state.openForm,
                editForm: 'add',
                errors: {},
            });
            if(this.state.identifier === 'CMS' && !this.state.openForm){
                this.setState({
                    dataAddLayout: cmsAddConfig(),
                });
            }
        }
        else if(drawer === 'formSetupChannel'){
            this.setState({ 
                openFormSetupChannel : !this.state.openFormSetupChannel,
                errors: {},
            }, () => {
                if(this.state.identifier === 'CMS' && !this.state.openFormSetupChannel){
                    this.setState({
                        dataAddLayout: cmsAddConfig(),
                    });
                }
            });
        }
    }

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };


    componentDidMount() {
        this.handleGetChannelsCategories();
        this.handleInitData(this.state.identifier, true);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.typeChannel !== prevProps.typeChannel){
            this.setState({
                // getRights: null,
                identifier: this.props.typeChannel,
                isEmpty: false,
                channelCategorie: null,
                channelByCategorie: null,
                typeOf: [],
                filtered: [],
                filteredCat: [],
                valueSelect: [],
                imageForm: '',
                openForm: false,
                openFormSetupChannel: false,
                editForm: 'add',
                emptyPicto: null,
                dataLayout: null,
                openDialogRemove: false
            }, () => {
                this.handleInitData(this.state.identifier);
                this.handleGetChannelsCategories();
            })
        }
        if(this.state.filteredCat !== prevState.filteredCat){
            this.handleChange();
        }
    }
    
    render() {
        return (
            <div>
                {this.state.content ?
                    (
                        <TopPanel 
                            icomoon={this.state.content.picto} 
                            colorIcomoon={colors.blue.lighter.hue300} 
                            title={this.state.content.title} 
                            subtitle={this.state.content.subtitle} 
                            gradientColor1={colors.menu.regular} 
                            gradientColor2={colors.menu.darker} 
                            handlerAdd={() => {this.handleToggleDrawer('form')}} 
                            textAdd={hasRights(CONNECTORS, this.state.getRights, CREATE) ? (this.state.isEmpty || !this.state.channelByCategorie) ? null : this.state.content.txtBtn : null} 
                            searchHandler={(this.state.isEmpty || !this.state.channelByCategorie) ? null : this.handleChange} 
                            openForm={this.state.openForm}
                            buttonAvailable={this.state.channelByCategorie !== null ? true : false}
                            windowWidth={this.props.windowWidth}
                            noResult={this.state.isEmpty}
                            hasBorder={this.state.typeOf && this.state.typeOf.length > 0 && !this.state.isEmpty && process.env.REACT_APP_MODE_SPREAD !== "hub" ? false : true}
                        />
                    ) : ''
                }
                {
                    this.state.typeOf && this.state.typeOf.length > 0 && !this.state.isEmpty && process.env.REACT_APP_MODE_SPREAD !== "hub" ? 
                    (
                        <TabCategories categories={this.state.typeOf} handleChangeTab={this.handleChangeTab}></TabCategories>
                    ) : ''
                }
                <Grid container direction="column" justify="center" spacing={0} style={{width: ((this.state.openForm && this.state.isEmpty) || (this.state.filtered.length === 0 && this.state.openForm)) ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", marginTop: 16, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                    <Grid container direction="row" spacing={2}>
                        {this.state.isEmpty ? 
                            <EmptyCard title={hasRights(CONNECTORS, this.state.getRights, CREATE) ? this.state.content.emptyTitle : 'Vous n\'avez pas les droits pour ajouter ce connecteur'} subtitle={hasRights(CONNECTORS, this.state.getRights, CREATE) ? this.state.content.emptySubtitle : 'Faite une demande auprès d\'un administrateur'} textButton={hasRights(CONNECTORS, this.state.getRights, CREATE) ? this.state.content.emptyTxtBtn : null} onClick={() => {this.handleToggleDrawer('form')}} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                        :
                            <>
                                {
                                    this.state.filtered.length > 0 ? (
                                        this.state.filtered.map((type, index) =>
                                        {
                                            let setupData = type
                                            setupData.node.preferences = this.state.preferences?.[type.node.id.replace('/api/channels/', '')]
                                            return(
                                                setupData.node.isSystem ? '' : 
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListProduct${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup type={setupData} textButton={'Modifier'} handlerEdit={hasRights(CONNECTORS, this.state.getRights, UPDATE) && process.env.REACT_APP_MODE_SPREAD !== "hub" ? () => this.editChannel(type.node.id, type.node.attributeGroup.id) : null} />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        } 
                                        )
                                    ) 
                                    : 
                                    this.state.channelByCategorie !== null && (this.state.filtered.length === 0 || this.state.filteredCat.length === 0) ?  
                                        (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={hasRights(CONNECTORS, this.state.getRights, CREATE) && process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.state.content.emptyTxtBtn : null} onClick={() => {this.handleToggleDrawer('form')}} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />) 
                                    :
                                        (<PageLoader />)
                                }
                            </>
                        }
                    </Grid>
                </Grid>
                {
                    this.state.dataLayout && this.state.typeOf?.length > 0 ?
                    (
                        <LayoutBuilder opened={this.state.openForm} forClose={() => {this.handleToggleDrawer('form')}} handlerSetup={this.handlerSetup} dataLayout={this.state.dataLayout} dataCard={this.state.typeOf} drawerWidth={this.props.drawerWidth} />
                    ) : ''
                }
                {
                    this.state.dataAddLayout ?
                    (
                        <LayoutBuilder 
                            opened={this.state.openFormSetupChannel}
                            image={this.state.imageForm}
                            forClose={() => {this.handleToggleDrawer('formSetupChannel')}} 
                            dataLayout={this.state.dataAddLayout} 
                            handlerMutation={this.handlerMutation}
                            drawerWidth={this.props.drawerWidth}
                            stateCallback={this.handleInputChange}
                            backStepperButtonAction={[
                                () => {
                                    this.setState({ errors: {} });
                                }, 
                                () => {
                                    this.setState({ errors: {} });
                                },
                                () => {
                                    this.setState({ errors: {} });
                                },
                                null
                            ]}
                            stepperButtonAction={[
                                () => {
                                    if (this.hasErrors()) {
                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                        this.setState({ seeErrors: false });
                                        eventService.fire();
                                        return false;
                                    }
                                    return true;
                                }, 
                                () => {
                                    if (this.hasErrors()) {
                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                        this.setState({ seeErrors: true });
                                        eventService.fire();
                                        return false;
                                    }
                                    return true;
                                },
                                () => {
                                    if (this.hasErrors()) {
                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                        this.setState({ seeErrors: true });
                                        eventService.fire();
                                        return false;
                                    }
                                    return true;
                                }
                            ]}
                            errorCallback={this.handleFormError}
                            validateButton={true}
                            deleteMutation={hasRights(CONNECTORS, this.state.getRights, DELETE) && this.state.editForm === 'edit' ? this.handleToggleDialog : null}
                            deleteText={this.state.editForm === 'edit' ? 'Supprimer' : null}
                            allState={this.state}
                        />
                    ) : ''
                }
                <Dialog
                    open={this.state.openDialogRemove}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Êtes-vous sûr de vouloir supprimer ce channel ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si vous supprimez ce channel celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                            Annuler
                        </Button>
                        <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        typeOf: state.typeOf,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListChannels)));