import styled from 'styled-components';

export default styled.div`
    box-sizing: content-box;
    *{
        box-sizing: content-box;
    }
    &.relative{
        position: relative;
    }
    &.absolute{
        position:absolute;
    }
    &.entete{
        padding: 0 1rem;
        @media screen and (min-width: 1024px){
            padding: 0 22px;
        }
        @media screen and (min-width:1450px){
            padding : 0 60px;
        }
    }
    &.bandeau-top{
        z-index: 9;
        background: rgba(0, 14, 40, 0.1);
        p{
            margin: 2px 0 4px 0;
            color: white;
        }
    }
    &.slider-top{
        .swiper-pagination-top{
            position: absolute;
            z-index: 9;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            width: auto;
            .swiper-pagination-bullet{
                background: white;
                opacity: 0.5;
                height: 12px;
                width: 12px;
                margin: 0 2px;
                &.swiper-pagination-bullet-active{
                    opacity: 1;
                }
                &:hover{
                    box-shadow: 0px 0px 10px -5px #000E28;
                }
                @media screen and (min-width: 1024px){
                    height: 18px;
                    width: 18px;
                }
                @media screen and (min-width: 1200px){
                    margin-bottom: 30px;
                }
            }
        }
        .swiper-slide{
            div.absolute{
                width: 90%;
                max-width: 1024px;
                height: calc(100% - 100px);
                display: flex;
                justify-content: center;
                align-items: center;
            }
            @media screen and (max-width: 767px){
                max-height: calc(100vh - 150px);
                position: relative;
                img{
                    height: 100%;
                }
            }
        }
        div.absolute{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            h2{
                text-transform: uppercase;
                font-weight: bold;
                margin: 0;
                font-family: 'DINOffc-Bold', sans-serif;
            }
            h4{
                font-family: 'DinRegular', sans-serif;
                margin-bottom: 10px;
                margin-top: 0;
                font-size: 20px;
                line-height: 26px; 
                @media screen and (min-width: 767px){
                    font-size: 30px;
                    line-height: 40px;
                }
                @media screen and (min-width: 1024px){
                    font-size: 39px;
                    line-height: 47px;
                    margin-bottom: 50px;
                }
                @media screen and (min-width: 1450px){
                    margin-bottom: 100px;
                }
            }
            a{
                font-family: 'DINOffc-Bold', sans-serif;
                text-decoration: none;
                padding: 16px 24px;
                margin: 8px 0;
                @media screen and (min-width: 1024px){
                    padding: 12px 64px;
                }
            }
        }
    }
    &.slider-about{
        .swiper-container-about{
            margin-top: 20px;
            padding-bottom: 20px;
            overflow: inherit;
            @media screen and (min-width: 1024px){
                margin-top: 40px;
            }
        }
        
        .swiper-scrollbar-about{
            max-width: 50%;
            width: 400px;
            margin-left: 50%;
            transform: translateX(-50%);
            display: block;
            background: #E6E7EA;
            border-radius: 0;
            bottom: 0;
            &>div{
                background:#000E28;
                border-radius: 0;
            }
        }
        .about{
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;
            h5{
                margin-top: 18px;
                font-family: 'DINOffc-Bold', sans-serif;
                font-size: 18px;
                margin-bottom: 0;
                @media screen and (min-width: 1024px){
                    font-size: 23px;
                    line-height: 28px;
                    margin-top: 28px;
                }
            }
            p{
                margin: 8px 0;
                font-family: 'Univers', sans-serif;
                font-size: 16px;
                line-height: 22px;
            }
            div a{
                color: #808794;
                text-decoration: underline;
                font-size: 12px;
                line-height: 16px;
                margin-top: 20px;
                font-family: 'Univers', sans-serif;
                @media screen and (min-width: 1024px){
                    font-size: 20px;
                    line-height: 28px;
                }
                &:hover{
                    color: #000E28;
                    background: white;
                }
            }
        }
    }
    &.slider-instagram{
        padding: 35px 0 55px;
        .swiper-container-instagram {
            overflow: inherit;
            width: 95%;
            margin-left: 5%;
            padding-bottom: 30px;
            .swiper-scrollbar-instagram{
                max-width: 50%;
                width: 400px;
                margin-left: 50%;
                transform: translateX(-50%);
                display: block;
                background: #E6E7EA;
                border-radius: 0;
                &>div{
                    background:#000E28;
                    border-radius: 0;
                }
            }
        }
        h4{
            font-size: 32px;
            line-height: 41px;
            margin: 0;
            font-family: 'DINOffc-Bold', sans-serif;
            @media screen and (max-width: 400px){
                font-size: 38px;
                line-height: 42px;
            }
            @media screen and (min-width : 1024px){
                font-size: 61px;
                line-height: 73px;
            }
        }
        p{
            max-width: 90%;
            font-family: 'Univers', sans-serif;
            margin: 0 auto 20px;
            font-size: 16px!important;
            line-height: 22px;
            @media screen and (min-width : 1024px){
                margin-bottom: 40px;
            }
        }
        .swiper-slide{
            &>img{
                width: 74%;
            }
            &>div{
                width: calc(24% - 5px);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 10px;
                img{
                    max-width: 100%;
                }
            }
        }
    }
    &.slider-image{
        padding: 45px 0 55px;
        .swiper-container-image-fullscreen, .swiper-container-image-carre, .swiper-container-image-horizontal {
            overflow: inherit;
            width: 95%;
            margin-left: 5%;
            padding-bottom: 50px;
            padding-top: 30px;
            .swiper-scrollbar-image{
                max-width: 50%;
                width: 400px;
                margin-left: 50%;
                transform: translateX(-50%);
                display: block;
                background: #E6E7EA;
                border-radius: 0;
                &>div{
                    background:#000E28;
                    border-radius: 0;
                }
            }
        }
        h4{
            font-size: 32px;
            line-height: 41px;
            margin: 0;
            font-family: 'DINOffc-Bold', sans-serif;
            @media screen and (max-width: 400px){
                font-size: 38px;
                line-height: 42px;
            }
            @media screen and (min-width : 1024px){
                font-size: 61px;
                line-height: 73px;
            }
        }
        p{
            max-width: 90%;
            font-family: 'Univers', sans-serif;
            margin: 0 auto 20px;
            font-size: 16px!important;
            line-height: 22px;
            @media screen and (min-width : 1024px){
                margin-bottom: 40px;
            }
        }
        .swiper-slide{
            &>img{
                width: 100%;
            }
        }
        .title-box{
            width: 90%;
            margin: auto;
            a{
                margin: 0;
            }
        }
    }
    &.grid-image{
        padding: 62px;
        &>.container-grid-image{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            &>div{
                flex: 0 0 33.333333%;
                max-width: calc(100% / 3 - 16px);
                padding: 8px;
                img{
                    width: 100%;
                }
            }
        } 
        @media screen and (max-width: 1023px){
            padding: 40px 5% 30px;
        }
        @media screen and (max-width: 767px){
            &>.container-grid-image{
                &>div{
                    flex: 0 0 50%;
                    max-width: calc(100% / 2 - 16px);
                }
            }
        }
        @media screen and (max-width: 1023px){
            &>.container-grid-image{
                &>div{
                    flex: 0 0 25%;
                    max-width: calc(100% / 2 - 16px);
                }
            }
        }
    }
    &.bloc-list{
        width: 90%;
        display: block;
        margin: auto;
        padding-top: 50px;
        padding-bottom: 20px;
        @media screen and (min-width: 1024px){
            padding-top: 70px;
            padding-bottom: 40px;
        }
        &>h2{
            color: #000E28;
            font-weight: bold;
            font-family: 'DINOffc-Bold', sans-serif;
        }
        &.bloc-list-products, &.bloc-list-about{
            width: 95%;
            margin-left: 5%;
        }
    }
    &.list-categories, &.list-collections{
        margin: 20px 0 0;
        display: flex;
        justify-content:flex-start;
        align-items: center;
        flex-direction: column;
        @media screen and (min-width: 800px){
            margin: 40px 0 0;
            align-items: flex-start;
            flex-direction: row;
        }
        h2{
            font-family: 'DINOffc-Bold', sans-serif;
        }
        &>.swiper-container{
            width: 100%;
            .swiper-wrapper{
                padding-bottom: 50px;
            }
        }
        .swiper-scrollbar-image{
            max-width: 50%;
            width: 400px;
            margin-left: 50%;
            transform: translateX(-50%);
            display: block;
            background: #E6E7EA;
            border-radius: 0;
            &>div{
                background:#000E28;
                border-radius: 0;
            }
        }
        .relative{
            width: 100%;
            display: flex;
            margin: 10px 0;
            a{
                height: calc(100% - 20px);
                width: calc(100% - 20px);
                position: absolute;
                left: 10px;
                cursor: pointer;
                div{
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
                h4{
                    font-size: 46px;
                    line-height: 52px;
                    max-width: 360px;
                    font-weight: bold;
                    margin: 0;
                    font-family: 'DINOffc-Bold', sans-serif;
                    @media screen and (max-width: 1023px) and (min-width : 800px){
                        font-size: 24px;
                        line-height: 30px;
                        max-width: 260px;
                    }
                    @media screen and (max-width: 1500px) and (min-width : 1024px){
                        font-size: 30px;
                        line-height: 40px;
                        max-width: 320px;
                    }
                    @media screen and (min-width : 1500px){
                        font-size: 61px;
                        line-height: 73px;
                    }
                }
                h5{
                    font-size: 12px;
                    line-height: 16px;
                    @media screen and (min-width: 1024px){
                        font-size: 18px;
                        line-height: 20px;
                    }
                    @media screen and (min-width: 1500px){
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
                p{
                    overflow: hidden;
                    margin: 0;
                    height: 0;
                    transition: all ease 500ms;
                    text-decoration: underline;
                    font-family: 'Univers', sans-serif;
                }
                button{
                    border: 0;
                    font-size: 12px;
                    line-height: 15x;
                    padding: 12px 40px;
                    display: block;
                    margin: auto;
                    margin-top: 18px;
                    margin-bottom: 0;
                    cursor: pointer;
                    font-family: 'DINOffc-bold', sans-serif;
                    @media screen and (min-width: 800px){
                        font-size: 12px;
                        line-height: 16px;
                        padding: 8px 20px;
                        margin-top: 30px;
                    }
                    @media screen and (min-width: 1024px){
                        font-size: 14px;
                        line-height: 20px;
                        padding: 16px 30px;
                        margin-top: 28px;
                    }
                    @media screen and (min-width:1400px){
                        font-size: 20px;
                        line-height: 20px;
                        padding: 20px 100px;
                        margin-top: 40px;
                    }
                }
            }
            img{
                &+div{
                    background: transparent linear-gradient(180deg, #000E2800 0%, #000E28 100%) 0% 0% no-repeat padding-box;
                    opacity: 0.1;
                    display: block;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    transition: all ease 500ms;
                    @media screen and (min-width: 800px){
                        width: 100%;
                        left: 0;
                    }
                }
            }
            &:hover{
                img+div{
                    opacity: 0.7;
                }
                a p{
                    height: 30px;
                } 
            }
        }
        &>div.relative{
            max-width: 450px;
            max-height: 450px;
            
            @media screen and (min-width: 800px){
                padding: 10px;
            }
            @media screen and (min-width: 1024px){
                max-width: 570px;
                max-height: 570px;
                width: calc(100% / 3 - 20px);
            }
            img{
                &+div{
                    background: transparent linear-gradient(180deg, #000E2800 0%, #000E28 100%) 0% 0% no-repeat padding-box;
                    opacity: 0.1;
                    display: block;
                    height: calc(100% - 20px);
                    width: 100%;
                    left: 0;
                    top: 10px;
                    transition: all ease 500ms;
                    @media screen and (min-width: 800px){
                        width: calc(100% - 20px);
                        left: 10px;
                    }
                }
            }
        }
    }
    div.swiper-button-prev, div.swiper-button-next{
        background: white;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        border: 1px solid #eae6e7;
        outline: none;
        &:after{
            font-size: 22px;
            font-weight: bolder;
            color: #000E28;
        }
        &:hover{
            border-color: #000E28;
        }
        &:focus{
            outline: none;
        }
        &.swiper-button-prev-top{
            &:after {
                position: absolute;
                border: solid black;
                border-width: 0 4px 4px 0;
                display: inline-block;
                padding: 2px;
                transform: rotate(135deg);
                left: 13px;
                width: 7px;
                height: 7px;
                font-size: 0px;
            }
        }
        &.swiper-button-next-top{
            &:after {
                position: absolute;
                border: solid black;
                border-width: 0 4px 4px 0;
                display: inline-block;
                padding: 2px;
                transform: rotate(-45deg);
                right: 13px;
                width: 7px;
                height: 7px;
                font-size: 0px;
            }
        }
        @media screen and (min-width: 1024px){
            width: 50px;
            height: 50px;
            border-width: 2px;
            &:after{
                font-size: 30px;
            }
        }
        &.swiper-button-disabled{
            display: none;
        }
        @media screen and (min-width: 1024px){
            &.swiper-button-prev-top{
                left: 50px;
                &:focus {
                    outline: none;
                }
                &:after {
                    left: 19px;
                    width: 10px;
                    height: 10px;
                }
            }
            &.swiper-button-next-top{
                right: 50px;
                &:focus {
                    outline: none;
                }
                &:after {
                    right: 19px;
                    width: 10px;
                    height: 10px;
                }
            }
        }
        &.swiper-button-prev-products{
            transform: translateY(-50%);
            left: 4px;
        }
        &.swiper-button-next-products{
            transform: translateY(-50%);
        }
        &.swiper-button-prev-about{
            transform: translateY(-100%);
            left: 4px;
        }
        &.swiper-button-next-about{
            transform: translateY(-100%);
        }
            
        
        &.swiper-button-prev-instagram{
            left: 4px;
        }
        &.swiper-button-next-instagram{
            right: 10px;
        }
    }
    &.list-products{
        margin: 20px 0 0;
        @media screen and (min-width: 1024px){
            margin: 40px 0 0;
        }
        .swiper-container-products{
            overflow: inherit;
        }
        .product{
            p, span{
                margin: 0;
            }
            .categorie{
                font-size: 11px;
                line-height: 20px;
                font-weight: bold;
                color: #003399;
                margin-bottom: 3px;
                font-family: 'DINOffc-Bold', sans-serif;
                @media screen and (min-width: 1024px){
                    font-size: 23px;
                    line-height: 28px;
                }
            }
            .title{
                font-size: 12px;
                line-height: 14px;
                color: #000E28;
                font-weight: 600;
                margin-bottom: 2px;
                min-height: 1rem;
                font-family: 'DINOffc-Medi', sans-serif;
                @media screen and (min-width: 1024px){
                    font-size: 23px;
                    line-height: 28px;
                }
            }
            .colors{
                font-size: 10px;
                line-height: 14px;
                color: #B3B7BF;
                margin-bottom: 10px;
                width: 100%;
                font-family: 'Univers', sans-serif;
                @media screen and (min-width: 1024px){
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            .price{
                font-size: 12px;
                line-height: 16px;
                color: #000E28;
                min-height: 1rem;
                font-family: 'Univers Bold', sans-serif;
                margin-top: 5px;
                @media screen and (min-width: 1024px){
                    font-size: 20px;
                    line-height: 28px;
                }
            }
            @media  screen and (min-width:768px) {
                .categorie, .title {
                    font-size: 15px;
                }
                .colors {
                    font-size: 12px;
                }
                .price {
                    font-size: 16px;
                }
            }
            @media screen and (min-width: 1024px){
                .categorie, .title{
                    font-size: 23px;
                    line-height: 28px;
                }
                .colors{
                    font-size: 16px;
                    line-height: 22px;
                }
                .price{
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
    }
    &.storelocator-bloc-home{
        background: #FAFAFB;
        &>div{
            max-width: 90%;
            margin: auto;
            padding: 45px 0;
            display: flex;
            &>div{
                padding: 10px;
                &:last-child{
                    max-width: 890px;
                    min-width: 500px;
                    width: 100%;
                    @media screen and (max-width: 1300px){
                        display: none;
                    }
                }
            }
            h2{
                font-weight: bold;
                color: #000E28;
                font-family: 'DINOffc-Bold', sans-serif;
                font-size: 23px;
                line-height: 28px;
                margin-top: 0;
                margin-bottom: 0;
                @media screen and (min-width: 1024px){
                    font-size: 32px;
                    line-height: 40px;
                }
                @media screen and (min-width: 1450px){
                    font-size: 41px;
                    line-height: 53px;
                }
                & + h2{
                    font-family: 'DinRegular', sans-serif;
                    font-size: 26px;
                    line-height: 31px;
                    margin-bottom: 35px;
                    @media screen and (min-width: 1024px){
                        font-size: 28px;
                        line-height: 38px;
                    }
                    @media screen and (min-width: 1450px){
                        font-size: 39px;
                        line-height: 47px;
                    }
                }
            }
            p{
                color: #808794;
                margin-bottom: 45px;
                font-family: 'Univers', sans-serif;
                font-size: 16px;
                line-height: 22px;
                @media screen and (min-width: 1024px){
                    font-size: 18px;
                    line-height: 28px;
                }
                @media screen and (min-width: 1450px){
                    font-size: 23px;
                    line-height: 33px;
                }
            }
            a{
                background: white;
                color: #000E28;
                border: 1px solid #000E28;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                margin: 0;
                font-family: 'DINOffc-Bold', sans-serif;
                font-size: 12px;
                line-height: 15px;
                padding: 12px 24px;
                
                @media screen and (min-width: 1024px){
                    font-size: 16px;
                    line-height: 22px;
                    padding: 16px 24px;
                }
                @media screen and (min-width: 1450px){
                    font-size: 20px;
                    line-height: 26px;
                    padding: 23px 32px;
                }
                svg{
                    height: 16px;
                    width: 16px;
                    margin-right: 6px;
                    @media screen and (min-width: 1024px){
                        height: 28px;
                        width: 28px;
                        margin-right: 10px;
                    }
                }
                &:hover{
                    background: #000E28;
                    color: white;
                    svg path{
                        stroke: white;
                    }
                }
                @media screen and (max-width: 767px){
                    svg path{
                        stroke: white;
                    }
                    &:hover{
                        svg path{
                            stroke: #000E28;
                        }
                    }
                    
                }
            }
        }
    }
    &.title-box{
        display: flex;
        flex-direction: row; 
        justify-content: space-between;
        align-items: center;
        h2{
            font-family: 'DINOffc-Bold', sans-serif;
            font-size: 23px;
            line-height: 28px;
            @media screen and (min-width: 1024px){
                font-size: 41px;
                line-height: 53px;
            }
        }
        a{
            color: #808794;
            font-size: 12px;
            line-height: 16px;
            margin-right: 5%;
            text-decoration: none;
            font-family: 'Univers', sans-serif!important;
            @media screen and (max-width: 1023px){
                text-decoration: underline;
                &:hover{
                    color: #000E28;
                }
            }
            @media screen and (min-width: 1024px){
                font-size: 20px;
                line-height: 28px;
            }
            &:hover{
                text-decoration: underline;
            }
        }
    }
    &.swiper-container{
        overflow:hidden!important;
    }
    &.w100{
        display: flex;
        width: 100%;
        &.column{
            flex-direction: column;
        }
    }
    &.hide-tablet{
        @media screen and (max-width: 1024px) and (min-width: 768px){
            display: none;
        }
    }
    &.hide-mobile{
        @media screen and (max-width: 767px){
            display: none;
        }
    }
    &.hide-desktop{
        @media screen and (min-width: 1001px){
            display: none;
        }
    }
`; 