import React, { useEffect, useRef, useState } from "react";
import Swiper from 'swiper';
import H2 from '../shared/h2';
import H5 from '../shared/h5';
import Div from '../shared/div';
import P from '../shared/p';
import Button from '../shared/a';
import Img from '../shared/img';

const SliderAbout = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;
    
    useEffect(() => {
        const swiper = new Swiper('.swiper-container-about', {
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-next-about',
                prevEl: '.swiper-button-prev-about',
            },
            scrollbar: {
                el: '.swiper-scrollbar-about',
            },
            spaceBetween: 16,
            breakpoints: {
                300:{
                    slidesPerView: 1.2,
                },
                700:{
                    slidesPerView: 2.1,
                },
                1100:{
                    slidesPerView: 3.1,
                },
            },
        });
        console.log(data)
    }, []);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-about', {
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-next-about',
                prevEl: '.swiper-button-prev-about',
            },
            scrollbar: {
                el: '.swiper-scrollbar-about',
            },
            spaceBetween: 16,
            breakpoints: {
                300:{
                    slidesPerView: 1.2,
                },
                700:{
                    slidesPerView: 2.1,
                },
                1100:{
                    slidesPerView: 3.1,
                },
            },
        });
    }, [props]);

    return (
        <Div style={{position: 'relative'}} className="slider-about" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className="bloc-list bloc-list-about">
                <Div className="title-box">
                    <H2 className="w100 uppercase">{data.inputs.value.value}</H2>
                </Div>
                <Div className="swiper-container swiper-container-about">
                    <div className="swiper-wrapper">
                        {
                            data.blocks.slides.config.map((slide, index) => (
                                <Div className="swiper-slide w100 about">
                                    <Img 
                                        className="w100" 
                                        src={
                                            typeof slide.blocks.image.inputs.value.value === 'string' ? 
                                                slide.blocks.image.inputs.value.value 
                                            : slide.blocks.image.inputs.value.value.filePath ? 
                                                `${process.env.REACT_APP_MEDIAS}/${slide.blocks.image.inputs.value.value.filePath}` 
                                            : null
                                        }
                                        alt={data.inputs.value.value}
                                    />
                                    <H5 className="din-offc uppercase">{slide.blocks.title.inputs.value.value}</H5>
                                    <P>{slide.blocks.text.inputs.value.value}</P>
                                    <Div>
                                        <Button href={slide.blocks.link.inputs.link.value} className="link">{slide.blocks.link.inputs.value.value}</Button>
                                    </Div>
                                </Div>
                            ))
                        }
                    </div>
                    <div className="swiper-scrollbar swiper-scrollbar-about"></div>
                    <div className="swiper-button-prev swiper-button-prev-about"></div>
                    <div className="swiper-button-next swiper-button-next-about"></div>
                </Div>
            </Div>
        </Div>
    );
};

export default SliderAbout;