import {FORM} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function formShell(type) {
    var obj = {
        titleForm: type === "edit" ? "Modifier la coquille" : 'Créer une coquille',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${type === "edit" ? "modifier":  "créer"} votre coquille`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: type === "edit" ? "Modifier" : 'Créer',
        formConfig: {
            type: FORM,
            children:[
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs:[
                        {
                            type: 'text',
                            label: 'Nom',
                            helper: {
                                text: 'Indiquez le nom de la coquille',
                                link: false,
                            },
                            required: true,
                            stateName: 'shellName',
                        },
                        {
                            type : 'select',
                            label: 'Affichage par défaut',
                            stateName : 'shellView',
                            translated: false,
                            helper: {
                                text: 'Helper non disponible pour le moment',
                                link: false,
                            },
                            value: [
                                { value: 'card', label: 'Cards' },
                                { value: 'table', label: 'Tableau' },
                            ]
                        },
                        {
                            type : 'switch',
                            label:'Pagination',
                            helper: {
                                text: 'Voulez-vous activer la pagination ?',
                                link: false,
                            },
                            required: true,
                            stateName: 'shellPagination',
                        },
                        {
                            type : 'number',
                            label:'Nombre d\'élèments par pages',
                            helper: {
                                text: 'Indiquez le nombre d\'élèments à afficher par page',
                                link: false,
                            },
                            required: true,
                            stateName: 'shellPerPage',
                            conditional : {
                                conditions : [
                                    {
                                        key : 'shellPagination',
                                        value : true
                                    },

                                ]
                            }
                        }
                    ]
                },
            ]
        }
    }
    return obj;
}