import React, { useState, useEffect } from "react";
import Swiper from "react-id-swiper";
import notFound from '../../../../src/assets/images/not-found.png';
import { Box, Grid } from "@material-ui/core";
import colors from '../../../config/theme/colors';
import styled from 'styled-components';
import Button from '../../ui/button/Button';

import './SliderDashboard.scss';
import PageLoader from "../../ui/loadings/page-loader/PageLoader";
// import sliderData from "../../data/hero-sliders/hero-slider-twelve.json";
// import '../../assets/scss/style.scss';
const ContainerImage = styled.div`
    max-height: 208px;
    overflow: hidden;
    display: flex;
    justify-content: center;
`
const Title = styled.h1`
    font-size: 14px !important;
    line-height: 18px;
    color: ${colors.blue.regular} !important;
    font-weight: bold !important;
    margin: 0 !important;
`

const TextActu = styled.h5`
    font-size: 14px !important;
    line-height: 20px !important;
    color: ${colors.blue.regular} !important;
    font-weight: lighter !important;
    margin: 0 !important;
    margin-top: 12px;
    white-space: pre-line;
`

const ImageArticle = styled.img`
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
`
export default function SliderActu (props){
  const [sliderParams, setSliderParams] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let params = {
      // effect: "fade",
      slidesPerView: props.sliderData.length > 1 ? props.windowWidth > 1200 ? 2 : 1 : 1,
      loop: props.sliderData.length > 1 ? true : false,
      // speed: 250,
      // autoHeight: true,
      grabCursor: props.sliderData.length > 1 ? true : false,
      spaceBetween: 16,
      // autoplay: {
      //   delay: 7000,
      //   disableOnInteraction: true
      // },
      watchSlidesVisibility: true,
      shouldSwiperUpdate: true,
      rebuildOnUpdate: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true
      },
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev"
      // },
      // renderPrevButton: () => (
      //   <button className="swiper-button-prev ht-swiper-button-nav">
      //     <i className="pe-7s-angle-left" />
      //   </button>
      // ),
      // renderNextButton: () => (
      //   <button className="swiper-button-next ht-swiper-button-nav">
      //     <i className="pe-7s-angle-right" />
      //   </button>
      // )
    };
  
    if (props.sliderData.length <= 1){
      params.pagination = false
    }

    setSliderParams(params);
    setReady(true);
  }, [props.windowWidth]);

  return (
    ready && sliderParams ?
      <div id="swiper-dashboard" style={{paddingBottom: props.sliderData.length > 1 ? 44 : 16, paddingRight: 16, paddingLeft: 16}}>
        <div className="slider-area">
          <div className="slider-active-2 nav-style-2">
            <Swiper {...sliderParams}>
              {props.sliderData &&
                props.sliderData.map((single, key) => {
                  return (
                    <Grid
                      className={`swiper-slide`}
                      style={{ 
                        background: 'white', 
                        // padding: "0px 0px 0px 16px"
                      }}
                      key={key}
                      container
                      alignItems="stretch"
                    >
                      <Grid container style={{padding: 16, paddingLeft: props.windowWidth > 1200 ? 32 : 16, background: "#FAFAFA"}} direction={"row"} alignItems="center" justify="space-between">
                        <Grid item xs={props.windowWidth > 1200 ? 6 : 12} style={{paddingRight: 8, height: props.windowWidth > 1200 ? '100%' : 'auto', order: props.windowWidth > 1200 ? '1' : '2'}}>
                          <Grid container alignItems="center" alignContent="space-between" direction={props.windowWidth > 1200 ? "row" : "column"} style={{height: props.windowWidth > 1200 ? '100%' :  'auto', marginTop: props.windowWidth > 1200 ? '0' : 16}}>
                            <Box style={{display:'flex',flexDirection:'column', flex: props.windowWidth > 1200 ? '0 1 auto' : '1'}}>
                              <Box>
                                  <Title className="animated">{single.title}</Title>
                              </Box>
                              <Box mt={1} mb={5} >
                                  <TextActu className="animated">{single.text}</TextActu>
                              </Box>
                            </Box>
                            <Box>
                              {
                                single.buttons.map((button, index) => {
                                  return(
                                    <Button text={button.text} bgcolor={colors.blue.lighter.hue300}  onClick={button.action} shadowcolor="#53839D" arrow="right" style={{marginTop: 0}} key={`ButtonAction${index}`} />
                                  )
                                })
                              }
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={props.windowWidth > 1200 ? 6 : 12} style={{paddingLeft: 8, order: props.windowWidth > 1200 ? '2' : '1'}}>
                          <ContainerImage className="animated">
                              <ImageArticle className="animated" src={single.image ? single.image : notFound} style={{maxHeight: 208, height: '100%'}}/>
                          </ContainerImage>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Swiper>
          </div>
        </div>
      </div>
    : <PageLoader />
  );
};