import {FORM} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

const AddUser = {
    titleForm: 'Créer un nouvel utilisateur',
    subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer un utilisateur',
    drawerType: 'drawer',
    noForm: true,
    component: Button,
    textButtonValidate: 'Créer',
    formConfig: {
        type: FORM,
        children:[
            {
                optionsInputs: [
                    {
                        type: 'text',
                        label: 'Nom',
                        helper: {
                            text: 'Indiquez le nom ici',
                            link: false,
                        },
                        required: true,
                        stateName: 'name',
                    },
                    {
                        type: 'text',
                        label: 'Prénom',
                        helper: {
                            text: 'Indiquez le prénom ici',
                            link: false,
                        },
                        required: true,
                        stateName: 'firstname',
                    },
                    {
                        type: 'text',
                        email: true,
                        label: 'Adresse email',
                        helper: {
                            text: 'Indiquez l\'adresse email',
                            link: false,
                        },
                        required: true,
                        stateName: 'email',
                    },
                    {
                        type: 'switch',
                        label: 'Générer le mot de passe',
                        helper: {
                            text: 'Génération',
                            link: false,
                        },
                        required: true,
                        stateName: 'generate',
                        value: [
                            {
                                value: true,
                                label: 'Par Spreadsuite',
                            },
                            {
                                value: false,
                                label: 'Par moi',
                            },
                        ]
                    },
                    {
                        type: 'password',
                        label: 'Nouveau mot de passe',
                        helper: {
                            text: 'Le mot de passe doit contenir au moins 8 caractères, dont un chiffre, une minuscule, une majuscule et un caractère spécial parmis : !@#$%^&*',
                            link: false,
                        },
                        required: true,
                        stateName: 'plainPassword',
                    },
                    {
                        type: 'password',
                        confirm: true,
                        label: 'Confirmer le mot de passe',
                        helper: {
                            text: 'Répétez le mot de passe',
                            link: false,
                        },
                        required: true,
                        stateName: 'plainPasswordRepeat',
                    },
                ]
            },
        ]
    }
}
export default AddUser;