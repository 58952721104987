import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../../../layouts/Card/CardCustom';
import SettingsIcon from '@material-ui/icons/Settings';
import CardGiftcard from '@material-ui/icons/CardGiftcard';
import PublishIcon from '@material-ui/icons/Publish';
import Equalizer from '@material-ui/icons/Equalizer';
import CardCatalog from '../../../layouts/Card/cardContent/CardCatalog';
import CardSetup from '../../../layouts/Card/cardContent/CardSetup';
import imgNotFound from '../../../../assets/images/not-found.png';
import * as moment from 'moment';
import pictoExternalLink from '../../../../assets/pictos/out.svg';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import { EPHEMERAL_ASSET } from '../../../../js/constants/assets-types';

const BoxCustom = styled(Box)`
    width: auto;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;
const GridCatalog = styled(Grid) `
    height: 100%;
`;
const ContainerStyled = styled(Grid)`
    padding-bottom: 0.5rem;
`;
const AssetDate = styled(Typography)`
    color: ${colors.grey.regular};
    text-transform: uppercase;
    margin-left: ${props => props.marginleft};
`;
const Description = styled(Typography)`
    color: ${colors.grey.regular};
    p{
        margin: 0;
        margin-top: 8px;
    }
`;
const AssetItemImage = styled(Box)`
    background: url(${props => props.backgroundimage});
    height:110px;
    max-width: 110px;
    width: 100%;
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center;
    transition:all .5s;
`;
const AssetType = styled(Typography)`
    color: ${props => props.typeasset === 'permanent' ? colors.blue.darker.hue300 : props.typeasset === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    font-weight: bold;
    text-transform: uppercase;
    margin-right: ${props => props.marginright};
`;
const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
`;
const Link = styled.a`
    color: ${colors.grey.lighter.hue600};
    &:hover{
        color: ${colors.grey.regular};
    }
`;
const ChipAssets = styled(Box)`
    border-radius: 16px;
    background: ${props => props.typeasset === 'permanent' ? colors.blue.darker.hue300 : colors.purple.regular};
    padding: 6px 24px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    p{
        font-size: 14px;
        font-weight: bold;
    }
`;
const StatusBox = styled(Box)`
    background: ${props => props.status ? colors.green.regular : '#CF3657'};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
`;

export default class CardProduct extends React.Component{
    render() {
        const asset             = this.props.asset;
        const goTo              = this.props.routeAsset;
        const goToStats         = this.props.routeStats;

        let values = [];
        let image= '';
        if(this.props.locales){

            for (let locale of this.props.locales) { 
                values.push({
                    code: locale.node.code,
                    name: this.props.allState[locale.node.code]?.asset_store_name ?? null,
                    description: this.props.allState[locale.node.code]?.asset_store_description ?? null,
                    images: this.props.allState[locale.node.code]?.asset_store_image ?? null,
                    ean: this.props.allState[locale.node.code]?.asset_ean ?? null,
                });
            }
        }

        let getImageType = (asset) => {
            const images = require.context('../../../../assets/images', true);
            let image;
            try{
                image=images('./' + asset);
            } catch(e){
                image=imgNotFound;
            }

            return image;
        }

        let defaultName = getTraductionAttributs('asset_store_name', this.props.allState.assetData.assetDatas.edges, this.props.currentLang);
        const defaultDesc   = values.find(e => e.description !== '<p><br></p>');

        const valueName = values.find(e => e.code === this.props.currentLang);
        const valueDesc = values.find(e => e.code === this.props.currentLang && e.description !== '<p><br></p>');

        let getImagesChanged = values.find(e => e.code === this.props.currentLang)
        let defaultImage = getTraductionAttributs('asset_store_image', this.props.allState.assetData.assetDatas.edges, this.props.currentLang, 'image');
        let previewImage = getImagesChanged.images?.filePath

        image   =  previewImage 
                        ? `${process.env.REACT_APP_MEDIAS}/${previewImage}`
                        : defaultImage 
                            ?`${process.env.REACT_APP_MEDIAS}/${defaultImage.filePath}` ?? '' 
                            : getImageType(this.props.allState.assetData.assetType.logo);

        let local = moment.tz.guess();
        let toDateStart = null;
        let toDateEnd = null;
        if (asset.paramStartAt){
            toDateStart = moment.tz(asset.paramStartAt, local).format('DD/MM/YYYY HH:mm');
        }
        if (asset.paramEndAt){
            toDateEnd = moment.tz(asset.paramEndAt, local).format('DD/MM/YYYY HH:mm');
        }
        let prodUrl = asset.prodUrl;
        let locales = asset.selectedLocales;
        let assetsSelected = asset.assetsSelected;
        return (
            <Grid container direction="column" justify="center" spacing={0}>
                <BoxCustom onClick={goTo} style={{marginBottom: 8, marginLeft: 8}}>
                    <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                </BoxCustom>
                <GridCustom item lg={12} md={12} xs={12}>
                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%", borderTop: `8px solid ${EPHEMERAL_ASSET.indexOf(this.props.allState.assetData.assetType.identifier) > -1 ? colors.purple.regular : colors.blue.darker.hue300}`}} contentpadding={'16px 32px'}>
                        <Grid container justify={'space-between'} alignItems='flex-start'>
                            <AssetType variant="h5" component="div" typeasset={EPHEMERAL_ASSET.indexOf(this.props.allState.assetData.assetType.identifier) > -1 ? 'ephemeral' : 'permanent'} marginright={"1rem"}>{EPHEMERAL_ASSET.indexOf(this.props.allState.assetData.assetType.identifier) > -1 ? 'ASSET ÉPHÉMÈRE' : 'ASSET PERMANENT'}</AssetType>
                            <StatusInfo status={asset.paramStatus} width={'auto'}/>
                        </Grid>
                        <Grid container style={{marginTop: 32}}>
                            <Grid item xs={2}>
                                <AssetItemImage 
                                    backgroundimage={typeof image === "string" ? image : image?.default}
                                    mb={2}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <Box>
                                    <Box pl={4}>
                                        <ContainerStyled 
                                            container 
                                            direction="row"
                                            justify="space-between"
                                            alignItems={'flex-start'}
                                        >
                                            <Grid item xs={12}> 
                                                <Grid container alignItems="flex-start" direction="column">
                                                    <Grid container justify='space-between'>
                                                        <Grid>
                                                            <Typography variant="h4" component="div" fontweighttext={'bold'}>
                                                                { valueName?.name ?? defaultName ?? null }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container justify="flex-end">
                                                                <SettingsIcon style={{ color: this.props.ready ? colors.blue.lighter.hue300 : colors.grey.regular, marginRight: '6px'}}/>
                                                                <Typography variant="h4" component="div" fontweighttext={'bold'} colortext={this.props.ready ? colors.blue.lighter.hue300 : colors.grey.regular}>
                                                                    Réglages avancés
                                                                </Typography>
                                                            </Grid>
                                                            {
                                                                this.props.allState.assetData.assetGameType ? 
                                                                    <> 
                                                                        {
                                                                            this.props.toggleDrawerGifts ?
                                                                                <Grid container justify="flex-end" onClick={this.props.ready ? () => this.props.toggleDrawerGifts() : null} style={{ marginTop: '6px', cursor: this.props.ready ? "pointer" : 'inherit'}}>
                                                                                    <CardGiftcard style={{ color: this.props.ready ? colors.blue.lighter.hue300 : colors.grey.regular, marginRight: '6px'}}/>
                                                                                    <Typography variant="h4" component="div" fontweighttext={'bold'} colortext={this.props.ready ? colors.blue.lighter.hue300 : colors.grey.regular}>
                                                                                        Réglages cadeaux
                                                                                    </Typography>
                                                                                </Grid> 
                                                                            : null
                                                                        }  
                                                                        {
                                                                            this.props.toggleImportInstants ?
                                                                                <Grid container justify="flex-end" onClick={this.props.ready ? this.props.toggleImportInstants : null} style={{ marginTop: '6px', cursor: this.props.ready ? "pointer" : "inherit"}}>
                                                                                    <PublishIcon style={{ color: this.props.ready ? colors.blue.lighter.hue300 : colors.grey.regular, marginRight: '6px'}}/>
                                                                                    <Typography variant="h4" component="div" fontweighttext={'bold'} colortext={this.props.ready ? colors.blue.lighter.hue300 : colors.grey.regular}>
                                                                                        Importation Instants gagnants
                                                                                    </Typography>
                                                                                </Grid> 
                                                                            : null
                                                                        }
                                                                    </>
                                                                : null
                                                            }
                                                            {
                                                                this.props.allState.assetData.assetGameType || this.props.allState.assetData.assetType.identifier === 'flipbook' || this.props.allState.assetData.assetType.identifier === "flipbook_with_cart" || this.props.allState.assetData.assetType.identifier === 'image_recognizer' ?
                                                                    <Grid container justify="flex-end" onClick={this.props.ready ? goToStats : null} style={{ marginTop: '6px', cursor: this.props.ready ? "pointer" : "inherit"}}>
                                                                        <Equalizer style={{ color: this.props.ready ? colors.blue.lighter.hue300 : colors.grey.regular, marginRight: '6px'}}/>
                                                                        <Typography variant="h4" component="div" fontweighttext={'bold'} colortext={this.props.ready ? colors.blue.lighter.hue300 : colors.grey.regular}>
                                                                            Statistiques
                                                                        </Typography>
                                                                    </Grid> 
                                                                : null
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Box mt={1.5}>
                                                        <Typography variant="h5" component="div" colortext={colors.blue.regular} fontweighttext={'bold'}>
                                                            Description
                                                        </Typography>
                                                        <Description variant="body1" component="div" dangerouslySetInnerHTML={{ __html: valueDesc?.description ?? defaultDesc?.description ?? null }}/>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            {
                                                prodUrl ? 
                                                    <Grid item xs={12}> 
                                                        <Grid container alignItems="flex-start" direction="column">
                                                            <Box mt={3} style={{width: '100%'}}>
                                                            URL de production :  
                                                            <Link href={prodUrl} target="_blank" style={{marginLeft:5}}>
                                                                {prodUrl}
                                                                <img src={pictoExternalLink} alt="Voir le site" style={{display: 'inline-block', height: 13, width: 13, marginLeft: 13}} />
                                                            </Link>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>  
                                                : null
                                            }
                                            {
                                                toDateStart ? 
                                                <Grid item xs={12}> 
                                                    <Grid container alignItems="flex-start" direction="column">
                                                        <Box mt={3}>
                                                            <Typography variant="h5" component="div" colortext={colors.blue.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem'}}>
                                                                Date de début
                                                            </Typography>
                                                            <AssetDate variant="body1" color={colors.grey.regular} component="div">
                                                                {toDateStart}
                                                            </AssetDate> 
                                                        </Box>
                                                    </Grid>
                                                </Grid> 
                                                : null
                                            }  
                                            {
                                                toDateEnd ?
                                                <Grid item xs={12}> 
                                                    <Grid container alignItems="flex-start" direction="column">
                                                        <Box mt={3}>
                                                            <Typography variant="h5" component="div" colortext={colors.blue.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem'}}>
                                                                Date de Fin
                                                            </Typography>
                                                            <AssetDate variant="body1" color={colors.grey.regular} component="div">
                                                                {toDateEnd}
                                                            </AssetDate> 
                                                        </Box>
                                                    </Grid>
                                                </Grid> 
                                                : null
                                            }
                                            {
                                                assetsSelected ? 
                                                    <Grid item xs={12}> 
                                                        <Grid container alignItems="flex-start" direction="column">
                                                            {
                                                                assetsSelected?.length > 0 ?
                                                                    <Box mt={3} style={{width: '100%'}}>
                                                                        <Typography variant="h5" component="div" colortext={colors.blue.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem'}}>
                                                                            {assetsSelected?.length > 1 ? 'Widgets' : 'Widget'}
                                                                        </Typography>
                                                                        <Grid container>
                                                                            {
                                                                                assetsSelected.map((asset) => {
                                                                                    return(
                                                                                        <ChipAssets mt={1} typeasset={EPHEMERAL_ASSET.indexOf(asset.node.assetType.identifier) > -1 ? 'ephemeral' : 'permanent'}>
                                                                                            <StatusBox status={asset.node.status}/>
                                                                                            <Typography colortext={colors.white}>{asset.node.assetType.libelle} {asset.node.assetGameType ? `- ${asset.node.assetGameType.libelle}` : null}</Typography>
                                                                                        </ChipAssets>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Grid>
                                                                    </Box>
                                                                : null
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                : null
                                            }
                                            {
                                                locales?.length > 0 ? 
                                                    <Grid item xs={12}> 
                                                        <Grid container alignItems="flex-start" direction="column">
                                                            <Box mt={3} style={{width: '100%'}}>
                                                                <Typography variant="h5" component="div" colortext={colors.blue.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem'}}>
                                                                    {locales?.length > 1 ? 'Langues' : 'Langue'}
                                                                </Typography>
                                                                <Grid container>
                                                                    <Typography>
                                                                    {
                                                                        locales.map((locale,index) => {
                                                                            let getLibelle = this.props.locales.find(e => e.node.id === locale)
                                                                            if ((index + 1) === locales.length){
                                                                                getLibelle = getLibelle.node.libelle
                                                                            }else {
                                                                                getLibelle = getLibelle.node.libelle + ', '
                                                                            }
                                                                            return getLibelle
                                                                        })
                                                                    }
                                                                    </Typography>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                : null
                                            }
                                            {
                                                this.props.allState.assetData.channel ? 
                                                    <Grid item xs={12}> 
                                                        <Grid container alignItems="flex-start" direction="column">
                                                            <Box mt={3} style={{width: '100%'}}>
                                                                <Typography variant="h5" component="div" colortext={colors.blue.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem'}}>
                                                                    Channel
                                                                </Typography>
                                                                <div style={{background: colors.grey.lighter.hue980, padding: '18px'}}>
                                                                    <Grid container alignItems={'center'} justify={'space-between'}>
                                                                        <Grid item>
                                                                            <Grid container direction={'row'}>
                                                                                <img src={getImageType(this.props.allState.assetData.channel.logo)?.default || imgNotFound} width={75} height={75}/>
                                                                                <Box pl={2}>
                                                                                    <Typography variant={'h4'} fontweighttext={'bold'}>{this.props.allState.assetData.channel.libelle}</Typography>
                                                                                    <Typography variant={'body2'} dangerouslySetInnerHTML={{ __html: this.props.allState.assetData.channel.description}} />
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div> 
                                                            </Box>
                                                        </Grid>
                                                    </Grid>  
                                                : null
                                            }                                           
                                            {
                                                this.props.allState.catalogAsset ? 
                                                    <Grid item xs={12}> 
                                                        <Grid container alignItems="flex-start" direction="column">
                                                            <Box mt={3} style={{width: '100%'}}>
                                                                <Typography variant="h5" component="div" colortext={colors.blue.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem'}}>
                                                                    Catalogue
                                                                </Typography>
                                                                <Grid container justify={'center'} style={{background: colors.grey.lighter.hue980, padding: '18px'}}>
                                                                    <GridCatalog container alignItems={'flex-start'} justify={'flex-start'} direction="column">
                                                                        <Box style={{width: "100%", background: colors.white}}>
                                                                        <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0'}>
                                                                            <CardCatalog 
                                                                                data={this.props.allState.catalogAsset} 
                                                                                textButton={"Voir le catalogue"}
                                                                                windowWidth={this.props.windowWidth} 
                                                                                openForm={true}
                                                                            />
                                                                        </CardCustom>
                                                                        </Box>
                                                                    </GridCatalog>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                : <PageLoader />
                                            }
                                            {
                                                this.props.allState.retailerAsset?.node ? 
                                                    <Grid item xs={12}> 
                                                        <Grid container alignItems="flex-start" direction="column">
                                                            <Box mt={3} style={{width: '100%'}}>
                                                                <Typography variant="h5" component="div" colortext={colors.blue.regular} fontweighttext={'bold'} style={{marginBottom: '0.5rem'}}>
                                                                    Retailer
                                                                </Typography>
                                                                <Grid container justify={'center'} style={{background: colors.grey.lighter.hue980, padding: '18px'}}>
                                                                    <GridCatalog container alignItems={'flex-start'} justify={'flex-start'} direction="column">
                                                                        <Box style={{width: "100%", background: colors.white}}>
                                                                        <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0'}>
                                                                            <CardSetup 
                                                                            type={this.props.allState.retailerAsset} 
                                                                            configCard={true} 
                                                                            />
                                                                        </CardCustom>
                                                                        </Box>
                                                                    </GridCatalog>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                : null
                                            }                                         
                                        </ContainerStyled>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardCustom>
                </GridCustom>   
            </Grid>
        );
    }
}