import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Badge from '@material-ui/core/Badge';
import * as moment from 'moment';
import colors from '../../../../../config/theme/colors';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const TableCellCustom = styled(TableCell)`
    padding: 16px;
`;
const BadgeCustom = styled(Badge)`
    display: block;
    margin-right: 15px; 
    &>span{
        background-color: ${props => props.bgcolor ? props.bgcolor : colors.white};
        color: ${props => props.bgcolor ? colors.white : colors.black};
    }
`;
const BadgeOrigin = styled.span`
    padding: 4px 8px;
    border-radius: 20px;
    background-color: ${props => props.bgcolor ? props.bgcolor : colors.white};
    color: ${props => props.bgcolor ? colors.white : colors.black};
`;

function createData(id, username, createdAt, lastPlayed, assetGamePlays, origin) {
    return {
        id,
        username,
        createdAt,
        lastPlayed,
        assetGamePlays,
        origin
    };
}

function createDataRecognizer(username, city, store, assetGamePlays) {
    return {
        username,
        city,
        store,
        assetGamePlays,
    };
}

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.regular,
    },
    tooltip: {
        backgroundColor: colors.blue.regular,
        fontSize: 14,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
}

function Row(props) {
    const { row, locale, isFlipbook, isRecognizer } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    let getProductName = isFlipbook ? null : isRecognizer ? null : row.origin.product.productDatas.edges.find(e => e.node.attribute.identifier === "product_name");
    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                {/* {
                    isFlipbook ?
                        null
                    : <TableCell>
                        {
                            row.assetGamePlays?.edges.length === 0 || row.assetGamePlays ? null : 
                            (
                                <BootstrapTooltip title={row.assetGamePlays?.edges.length === 0 ? 'Aucun historique': open ? 'Masquer l\'historique de jeu' : 'Voir l\'historique de jeu'} placement="right" disableHoverListener={props.opened ? true : false} 
                                    className={clsx(classes.tooltip)}
                                >
                                    <IconButton aria-label="expand row" size="small" onClick={() => {row.assetGamePlays.edges.length === 0 ? console.log('null'): setOpen(!open)}}>
                                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </BootstrapTooltip>
                            )
                        }
                    </TableCell>
                } */}
                {
                    isFlipbook ?
                        <TableCell component="th" scope="row">{row.id}</TableCell>
                    : null
                }
                <TableCell>{row.username}</TableCell>
                <TableCell align="left">
                    {isFlipbook ?
                        <BadgeOrigin 
                            bgcolor={row.origin === 'LEGO' ? "#D11112" : row.origin === "CDISCOUNT" ? "#293847" : row.origin === "FNAC" ? "#ebb300" : "#02AAE2"}
                        >
                            {row.origin}
                        </BadgeOrigin>
                        :
                            isRecognizer ?
                                <span style={{textTransform: 'uppercase'}}>{row.city}</span>
                            :
                            `${row.origin.product.sku} - ${getProductName ? getProductName.node.value : 'N/A'}`
                    }
                </TableCell>
                {
                    isFlipbook ?
                        <TableCell>{row.createdAt}</TableCell>
                    : isRecognizer ?
                        <TableCell>{row.store}</TableCell>
                        : null
                }
                {
                    isFlipbook ?
                        <TableCell>
                            {
                                row.assetGamePlays.edges.length === 0 ? 
                                    null 
                                : 
                                    (
                                        <BootstrapTooltip title={row.assetGamePlays.edges.length === 0 ? 'Aucun historique': open ? 'Masquer l\'historique de jeu' : 'Voir l\'historique de jeu'} placement="right" disableHoverListener={props.opened ? true : false} 
                                            className={clsx(classes.tooltip)}
                                        >
                                            <IconButton aria-label="expand row" size="small" onClick={() => {row.assetGamePlays.edges.length === 0 ? console.log('null'): setOpen(!open)}}>
                                                {open ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </BootstrapTooltip>
                                    )
                            }
                        </TableCell>
                    : isRecognizer ? 
                        <TableCell>
                            {
                                row.assetGamePlays.length === 0 ? 
                                    null 
                                : 
                                    (
                                        <BootstrapTooltip title={row.assetGamePlays.length === 0 ? 'Aucun historique': open ? 'Masquer l\'historique des scans' : 'Voir l\'historique des scans'} placement="right" disableHoverListener={props.opened ? true : false} 
                                            className={clsx(classes.tooltip)}
                                        >
                                            <IconButton aria-label="expand row" size="small" onClick={() => {row.assetGamePlays.length === 0 ? console.log('null'): setOpen(!open)}}>
                                                {open ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </BootstrapTooltip>
                                    )
                            }
                        </TableCell>
                        : <TableCell>{row.lastPlayed}</TableCell>
                }
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                    <Typography variant="h6" gutterBottom component="div">
                        {isFlipbook ? 'Choix flipbook' : isRecognizer ? 'Liste des boites scannées' : 'History'}
                    </Typography>
                    {
                    isFlipbook ? 
                        <Grid container style={{paddingBottom: 16}}>
                            {row.assetGamePlays.edges.map((historyRow, index) => {
                                let getName = historyRow.node.product.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name')
                                let getImage = historyRow.node.product.productDatas.edges.find(e => e.node.attribute.identifier === 'product_image')
                                return(
                                    <Grid item key={`Lot${historyRow.node.product.sku}`} xs={3} style={{textAlign: 'center'}}>
                                        <Typography>Lot {index + 1}</Typography>
                                        <img src={`${process.env.REACT_APP_MEDIAS}/${getImage.node.media.filePath}`} />
                                        <div>
                                            {historyRow.node.product.sku} - {getName.node.value}
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    :
                        isRecognizer ?
                            <Table size="small" aria-label="lego-box">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nom</TableCell>
                                        <TableCell>Code article</TableCell>
                                        <TableCell>Catégorie</TableCell>
                                        <TableCell>Date de scan</TableCell>
                                        <TableCell align="center">Résultat</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {row.assetGamePlays?.map((historyRow, index) => {
                                    return(
                                        <TableRow key={`historyRow-${index}`}>
                                            <TableCellCustom>{historyRow.product?.name || 'N/A'}</TableCellCustom>
                                            <TableCellCustom component="th" scope="row">
                                                {historyRow.product?.sku || 'N/A'}
                                            </TableCellCustom>
                                            <TableCellCustom>
                                                {historyRow.product?.categories.map((cat) => {
                                                    return(
                                                        <span>{cat}</span>
                                                    )
                                                })}
                                            </TableCellCustom>
                                            <TableCellCustom>
                                                {moment.tz(historyRow.createdAt.date, locale).format('L H:mm')}
                                            </TableCellCustom>
                                            <TableCellCustom align="center">
                                                <BadgeOrigin bgcolor={historyRow.isMatching ? colors.green.regular : colors.red.regular}>{historyRow.result}</BadgeOrigin>
                                            </TableCellCustom>
                                        </TableRow>
                                    )
                                })}
                                </TableBody>
                            </Table>
                        : 
                        row.assetGamePlays ? 
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Id</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell align="right">Is Win ?</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {row.assetGamePlays?.edges.map((historyRow) => (
                                    <TableRow key={historyRow.date}>
                                        <TableCell>{historyRow.node.id}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {moment.tz(historyRow.node.playedAt, locale).format('L H:mm')}
                                        </TableCell>
                                        <TableCell align="right">
                                            <BadgeCustom bgcolor={historyRow.node.isWin ? colors.green.regular : colors.red.regular} badgeContent={historyRow.node.isWin ? "Gagné" : "Perdu"} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        : null
                    }
                    </Box>
                </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function CollapsibleTable(props) {
    const { players, isFlipbook, isRecognizer } = props;
    const rows = [];
    let local = moment.tz.guess();
    for(let player of players){
        if (isRecognizer){
            rows.push(
                createDataRecognizer(player.email,player.city,player.magasin,player.details)
            )
        }else{
            rows.push(
                createData(isFlipbook ? player.node.id : null, isFlipbook ? player.node.username : player.node.assetGamePlay?.assetGamePlayer.username, isFlipbook ? moment.tz(player.node.createdAt, local).format('L H:mm') : null, isFlipbook ? moment.tz(player.node.lastPlayed, local).format('L H:mm') : moment.tz(player.node.assetGamePlay?.assetGamePlayer.lastPlayed, local).format('L H:mm'), isFlipbook ? player.node.assetWishlistItems : null, isFlipbook ? player.node.origin : player.node.assetGameGift)
            );
        }
    }
    return (
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
            <TableHead>
            <TableRow>
                {
                    isFlipbook ? 
                        <TableCell><strong>id</strong></TableCell>
                    : null
                }
                {
                    isRecognizer ?
                    <TableCell><strong>Email</strong></TableCell>                    
                    : <TableCell><strong>Username</strong></TableCell>
                }
                {
                    isFlipbook ? 
                        <TableCell><strong>Origin</strong></TableCell>
                    : isRecognizer ?
                        <TableCell><strong>Ville</strong></TableCell>
                        : <TableCell><strong>Lot remporté</strong></TableCell>
                }
                {
                    isFlipbook ? 
                    <TableCell><strong>createdAt</strong></TableCell>
                    : null
                }
                <TableCell><strong>{isFlipbook ? null : isRecognizer ? 'Magasin' : 'Date de l\'instant'}</strong></TableCell>
                {
                    isRecognizer ?
                        <TableCell></TableCell>
                    : null
                }
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row, index) => (
                <Row key={`RowArray${index}`} row={row} players={players} locale={local} isFlipbook={isFlipbook} isRecognizer={isRecognizer}/>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    );
}