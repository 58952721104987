import { gql } from 'apollo-boost';

/*
{
    "id": "/api/completeness-groups/1"
}
 */
export const GET_COMPLETENESSGROUP_BY_ID = gql`
query getCompletenessGroup($id: ID!){
  completenessGroup(id:$id) {
    id
    libelle
    attributes{
      edges{
        node{
          id
          identifier
        }
      }
    }
  }
}
`;

export const GET_COMPLETENESSGROUPS = gql`
{
  completenessGroups {
    edges{
      node{
        id
        libelle
        attributes{
          edges{
            node{
              id
              identifier
            }
          }
        }
      }
    }
  }
}
`;

/*
{
  "libelle": "test group",
  "attributes": [
    "/api/attributes/1",
    "/api/attributes/2"
  ]
}
 */
export const CREATE_COMPLETENESSGROUP = gql`
mutation createCompletenessGroup($libelle: String!, $attributes: [String], $assets: [String]){
  createCompletenessGroup(input:{libelle: $libelle, attributes: $attributes, assets: $assets}) {
    completenessGroup{
      id
      libelle
      attributes{
        edges{
          node{
            id
            identifier
          }
        }
      }
    }
  }
}
`;

/*
{
  "id": "/api/completeness-groups/1",
  "libelle": "test group",
  "attributes": [
   "/api/attributes/1",
   "/api/attributes/2"
  ]
}
 */
export const UPDATE_COMPLETENESSGROUP = gql`
mutation updateCompletenessGroup($id: ID!, $libelle: String, $attributes: [String], $assets: [String]){
  updateCompletenessGroup(input:{id: $id, libelle: $libelle, attributes: $attributes, assets: $assets}) {
    completenessGroup{
      id
      libelle
      attributes{
        edges{
          node{
            id
            identifier
          }
        }
      }
    }
  }
}
`;

/*
{
  "id": "/api/completeness-groups/1"
}
 */
export const DELETE_COMPLETENESSGROUP = gql`
mutation deleteCompletenessGroup($id: ID!){
  deleteCompletenessGroup(input:{id: $id}) {
    completenessGroup{
      id
    }
  }
}
`;