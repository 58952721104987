import React, { Fragment } from "react";
import { 
    HeaderBook,
    FooterBook,
    FlipBook,
    PostCardBook,
    RecapBlockBook,
    ResultScreenBook,
    TopHomepageBook,
    BannerLinkBook
} from "../../../../shareable/components/assets/book/components";
import FlipbookSuccessEmail from "../../../../../email/flipbook/emailSuccess";
import { Toolbar } from "../../../../shareable/tools";
import { ItemTypes } from "../../../../shareable/types";
import { v4 as uuidv4 } from "uuid";

export const getComponent = (element, preview, config, apiConfig, updateConfig, inputCallback, pagesFlipbook, deleteComponent) => {

    switch (element.type) {
        case ItemTypes.HEADER_BOOK:
            return (<Fragment key={element.key}>
                <HeaderBook
                    id={element.key}
                    noSticky={preview}
                    update={updateConfig}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                </HeaderBook>
            </Fragment>)
        case ItemTypes.FOOTER_BOOK:
            return (<Fragment key={element.key}>
                <FooterBook
                    id={element.key}
                    noSticky={preview}
                    update={updateConfig}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                </FooterBook>
            </Fragment>)
        case ItemTypes.TOP_HOMEPAGE_BOOK:
            return (<Fragment key={element.key}>
                <TopHomepageBook
                    id={element.key}
                    noSticky={preview}
                    update={updateConfig}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                </TopHomepageBook>
            </Fragment>)
        case ItemTypes.POSTCARD_BOOK:
            return (<Fragment key={element.key}>
                <PostCardBook
                    id={element.key}
                    noSticky={preview}
                    update={updateConfig}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    spread={true}
                    toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                    toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                >
                </PostCardBook>
            </Fragment>)
        case ItemTypes.FLIPBOOK:
            return (<Fragment key={element.key}>
                <FlipBook
                    id={element.key}
                    noSticky={preview}
                    update={updateConfig}
                    preview={preview}
                    pagesFlipbook={pagesFlipbook}
                    data={element.data}
                    categories={apiConfig.categories}
                    products={apiConfig.products}
                    inputCallback={inputCallback}
                    params={config.params}
                    spread={true}
                    toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                    toolbarSubSection={<Toolbar name={element.data.blocks.subBlock.name} onEdit={() => inputCallback(element.key, 'subBlock')} />}
                    toolbarGiftBlock={<Toolbar name={element.data.blocks.giftBlock.name} onEdit={() => inputCallback(element.key, 'giftBlock')} />}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                </FlipBook>
            </Fragment>)
        case ItemTypes.FLIPBOOK_WITH_CART:
            return (<Fragment key={element.key}>
                <FlipBook
                    id={element.key}
                    noSticky={preview}
                    update={updateConfig}
                    preview={preview}
                    pagesFlipbook={pagesFlipbook}
                    data={element.data}
                    categories={apiConfig.categories}
                    products={apiConfig.products}
                    inputCallback={inputCallback}
                    params={config.params}
                    spread={true}
                    toolbarHeader={<Toolbar name={element.data.blocks.header.name} onEdit={() => inputCallback(element.key, 'header')} />}
                    toolbarSection={<Toolbar name={element.data.blocks.flipbook.name} onEdit={() => inputCallback(element.key)} />}
                />
            </Fragment>)
        case ItemTypes.RECAP_BLOCK_BOOK:
            return (<Fragment key={element.key}>
                <RecapBlockBook
                    id={element.key}
                    noSticky={preview}
                    update={updateConfig}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    spread={true}
                    redirect={'#'}
                    emailTemplate={FlipbookSuccessEmail()}
                    toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                    toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                    toolbarBlockRecap={<Toolbar name={element.data.blocks.recap.name} onEdit={() => inputCallback(element.key, 'recap')} />}
                >
                </RecapBlockBook>
            </Fragment>)
        case ItemTypes.RESULTS_BOOK:
            return (<Fragment key={element.key}>
                <ResultScreenBook
                    id={element.key}
                    noSticky={preview}
                    update={updateConfig}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    spread={true}
                    toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                    toolbarBlock={<Toolbar name={element.data.blocks.result.name} onEdit={() => inputCallback(element.key, 'result')} />}
                >
                </ResultScreenBook>
            </Fragment>)
        case ItemTypes.BANNER_LINK_BOOK:
            return (<Fragment key={element.key}>
                <BannerLinkBook
                    id={element.key}
                    noSticky={preview}
                    update={updateConfig}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                </BannerLinkBook>
            </Fragment>)
        case ItemTypes.EMAIL_SUCCESS_BOOK:
            return (<FlipbookSuccessEmail data={element.data} id={uuidv4()} />);
    }
}