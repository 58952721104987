import { v4 as uuidv4 } from "uuid";
import GiftFinderTemplate from "./assets/minisite/giftFinder";
import MagentoTemplate from "./assets/magento/base";
import Wordpress from "./wordpress";
import Facebook from "./facebook";
import EmailTemplate from "./email";
import MinisiteTemplate from "./assets/minisite/base";
import PresentationTemplate from "./presentation";
import BookTemplate from "./assets/book/homepage";
import GameTemplate from "./assets/game/base";
import CMSTemplate from "./assets/cms/base";
import { AssetTypes, ItemTypes, Pages } from "../shareable/types";
import { Toolbar } from "../shareable/tools";
import { getComponent } from "../core/components/assets";
import MinisiteContactEmail from '../../email/minisite/emailContact';

export const getTemplate = ( 
    assetType, assetToken, page, preview = true, noRight = false, droppable = null, // base
    config, apiConfig, // config
    updateConfig, inputCallback, deleteCallback, // methods
    taxes, onSnap, onStyle, pagesFlipbook // specific
) => {
    const mapper = (element, index) => {
        return getComponent(
            element, index, 
            assetType, assetToken, preview, droppable,
            config, apiConfig, 
            updateConfig, inputCallback, deleteCallback, 
            taxes, pagesFlipbook, onSnap, onStyle
        );
    };

    switch(assetType) {
        case AssetTypes.FLIPBOOK:
        case AssetTypes.NEWSLETTER:
            switch(page) {
                case Pages.HOMEPAGE_BOOK:
                case Pages.FLIPBOOK:
                case Pages.REGISTER_BOOK:
                case Pages.REGISTER_SUCCESS_BOOK:
                    return <BookTemplate
                        config={config[page]}
                        mapper={mapper}
                    />;
                case Pages.EMAIL_SUCCESS_BOOK:
                    let element = {
                        type: config[page].elements[0].type,
                        data: config[page].elements[0].data
                    };

                    return (
                        <div style={{ height: '100%' }}>
                            {
                                !preview  && <Toolbar 
                                    name={config[page].elements[0].data.name} 
                                    noBorder={true} 
                                    onEdit={() => inputCallback(Pages.EMAIL_SUCCESS_BOOK)} 
                                />
                            }
                            
                            { mapper(element, 0) }
                        </div>
                    );
                default: return null;
            };
        case AssetTypes.GAME:
            switch (page) {
                case Pages.HOMEPAGE_GAME:
                case Pages.LOGIN_GAME:
                case Pages.INDEX_GAME:
                case Pages.RESULTS_WIN_GAME:
                case Pages.RESULTS_LOOSE_GAME:
                case Pages.ALREADYPLAYED_GAME:
                case Pages.ENDED_GAME:
                case Pages.NOT_STARTED_GAME:
                    return <GameTemplate
                        config={config[page]}
                        params={config.params}
                        noRight={noRight}
                        mapper={mapper}
                    />;
            };
        case AssetTypes.MINISITE:
            switch (page) {
                case Pages.HOME:
                case Pages.LANDING:
                case Pages.PRODUCT_DETAILS:
                case Pages.CONTACT:
                case Pages.WISHLIST:
                    return <MinisiteTemplate
                        config={config[page]}
                        apiConfig={apiConfig}
                        params={config.params}
                        preview={preview}
                        droppable={droppable}
                        mapper={mapper}
                    />;
                case Pages.EMAIL_CONTACT:
                case Pages.EMAIL_WISHLIST:
                    return  <EmailTemplate name={config[page].data.name} preview={preview} onEdit={() => inputCallback(ItemTypes.EMAIL_CONTACT)}>
                                <MinisiteContactEmail data={config[page].data} spread={true} key={ItemTypes.EMAIL_CONTACT} id={uuidv4()} />
                            </EmailTemplate>;
            };
        case AssetTypes.FACEBOOK:
            return <Facebook
                config={config[page]}
                mapper={mapper}
            />;
        case AssetTypes.WORDPRESS:
            return <Wordpress
                config={config[page]}
                mapper={mapper}
            />;
        case AssetTypes.CMS:
            return <CMSTemplate
                config={config[page]}
                params={config.params}
                preview={preview}
                droppable={droppable}
                mapper={mapper}
            />;
        case AssetTypes.MAGENTO:
            return <CMSTemplate
                config={config[page]}
                params={config.params}
                preview={preview}
                droppable={droppable}
                mapper={mapper}
            />;
        default:
            switch (page) {
                case Pages.MAGENTO:
                    return <MagentoTemplate
                        config={config[page]}
                        mapper={mapper}
                    />;
                case Pages.GIFT_FINDER:
                    return <GiftFinderTemplate
                        config={config[page]}
                        mapper={mapper}
                    />;
                case Pages.PRESENTATION:
                    return <PresentationTemplate
                        config={config[page]}
                        droppable={droppable}
                        mapper={mapper}
                    />;
            };
    };
};