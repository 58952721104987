import React, { useEffect, Fragment } from "react";
import { 
    Header, 
    Footer, 
    SectionTitle, 
    Slider, 
    SliderMultiple, 
    SliderWithRightImages, 
    ThreeImagesGrid, 
    ThreeImagesRow, 
    ImageRow, 
    BlocText, 
    TabProduct, 
    Wishlist, 
    Landing, 
    Product,
    Contact, 
    GiftFinder,
    Image,
    Text,
    InformationBanner,
    LinkBanner,
    Category,
    TextImage,
    SliderProduct,
    TextVideo
} from "../../../../shareable/components/assets/minisite/components";

import { 
    SliderTop,
    SliderAbout,
    SliderInstagram,
    SliderImage,
    SliderPresentationMedia,
    SliderMoments,
    GridImage,
    BlocTextMedia,
    BannerMedia,
    SectionDescriptionPage,
    SectionManifestBlock,
    CategoriesHome,
    CollectionsHome,
    SliderProductsHome,
    BlocHomeStorelocator,
    Entete,
    TopImageFullscreen,
    Slogan,
    TitleText,
    SeparatorBlock,
    ListCategories,
    SliderByTab
} from "../../../../shareable/components/assets/cms/components";


import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Toolbar } from "../../../../shareable/tools";
import { ItemTypes, Pages } from "../../../../shareable/types";

export const getComponent = (element, index, preview, assetToken, Droppable, params, config, apiConfig, updateConfig, inputCallback, deleteCallback) => {

    switch (element.type) {
        case ItemTypes.SECTION_TITLE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SectionTitle
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        update={updateConfig}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SectionTitle>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.SLIDER:
            return (<Fragment key={element.key}>
                <Slider
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </Slider>
                <Droppable type={ItemTypes.SLIDER} id={element.key} index={index} />
            </Fragment>)
        case ItemTypes.SLIDER_WITH_RIGHT_IMAGES:
            return (<Fragment key={element.key}>
                <SliderWithRightImages
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </SliderWithRightImages>
                <Droppable type={ItemTypes.SLIDER_WITH_RIGHT_IMAGES} id={element.key} index={index} />
            </Fragment>)
        case ItemTypes.SLIDER_MULTIPLE:
            return (<Fragment key={element.key}>
                <SliderMultiple
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    spread={true}
                    inputCallback={inputCallback}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </SliderMultiple>
                <Droppable type={ItemTypes.SLIDER_MULTIPLE} id={element.key} index={index} />
            </Fragment>)
        case ItemTypes.THREE_IMAGES_ROW:
            return (<Fragment key={element.key}>
                <ThreeImagesRow
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    spread={true}
                    inputCallback={inputCallback}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </ThreeImagesRow>
                <Droppable type={ItemTypes.THREE_IMAGES_ROW} id={element.key} index={index} />
            </Fragment>)
        case ItemTypes.THREE_IMAGES_GRID:
            return (<Fragment key={element.key}>
                <ThreeImagesGrid
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    spread={true}
                    inputCallback={inputCallback}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </ThreeImagesGrid>
                <Droppable type={ItemTypes.THREE_IMAGES_GRID} id={element.key} index={index} />
            </Fragment>)
        case ItemTypes.BLOC_TEXT:
            return (<Fragment key={element.key}>
                <BlocText
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </BlocText>
                <Droppable type={ItemTypes.BLOC_TEXT} id={element.key} index={index} />
            </Fragment>)
        case ItemTypes.IMAGE_ROW:
            return (<Fragment key={element.key}>
                <ImageRow
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    update={updateConfig}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </ImageRow>
                <Droppable type={ItemTypes.IMAGE_ROW} id={element.key} index={index} />
            </Fragment>)
        case ItemTypes.TAB_PRODUCT:
            return (<Fragment key={element.key}>
                <TabProduct
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    update={updateConfig}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </TabProduct>

                <Droppable type={ItemTypes.TAB_PRODUCT} id={element.key} index={index} />
            </Fragment>)
        case ItemTypes.PAGE_LANDING:
            return (<Fragment key={element.key}>
                <Landing
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    params={params}
                    inputCallback={inputCallback}
                    products={apiConfig.products}
                    categories={apiConfig.categories}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                </Landing>
                <Droppable type={ItemTypes.PAGE_LANDING} id={`${element.key}-bottom`} index={index} />
            </Fragment>)
        case ItemTypes.PAGE_PRODUCT:
            return (<Fragment key={element.key}>
                <Product
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    params={config.params}
                    inputCallback={inputCallback}
                    attributes={apiConfig.attributes}
                    product={apiConfig.products[0]}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                </Product>
                <Droppable type={ItemTypes.PAGE_PRODUCT} id={`${element.key}-bottom`} index={index} />
            </Fragment>)
        case ItemTypes.PAGE_CONTACT:
            return (<Fragment key={element.key}>
                <Contact
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    params={config.params}
                    inputCallback={inputCallback}
                    emailTemplate={config[Pages.EMAIL_CONTACT]?.html}
                    assetToken={assetToken}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                </Contact>
                <Droppable type={ItemTypes.PAGE_CONTACT} id={`${element.key}-bottom`} index={index} />
            </Fragment>)
        case ItemTypes.PAGE_WISHLIST:
            return (<Fragment key={element.key}>
                <Wishlist
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    products={apiConfig.products}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                </Wishlist>
                <Droppable type={ItemTypes.PAGE_WISHLIST} id={`${element.key}-bottom`} index={index} />
            </Fragment>)
        case ItemTypes.GIFT_FINDER:
            return (<Fragment key={element.key}>
                <GiftFinder
                    id={element.key}
                    update={updateConfig}
                    inputCallback={inputCallback}
                    preview={preview}
                    data={element.data}
                    categories={apiConfig.categories}
                    products={apiConfig.products}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                </GiftFinder>
                <Droppable type={ItemTypes.GIFT_FINDER} id={`${element.key}-bottom`} index={index} />
            </Fragment>)
        case ItemTypes.INFORMATION_BANNER:
            return (<Fragment key={element.key}>
                <InformationBanner
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </InformationBanner>
                <Droppable type={ItemTypes.INFORMATION_BANNER} id={element.key} index={index} />
            </Fragment>);
        case ItemTypes.LINK_BANNER:
            return (<Fragment key={element.key}>
                <LinkBanner
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </LinkBanner>
                <Droppable type={ItemTypes.LINK_BANNER} id={element.key} index={index} />
            </Fragment>);
        case ItemTypes.CATEGORY:
            return (<Fragment key={element.key}>
                <Category
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    categories={apiConfig.categories}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </Category>
                <Droppable type={ItemTypes.CATEGORY} id={element.key} index={index} />
            </Fragment>);
        case ItemTypes.TEXT_IMAGE:
            return (<Fragment key={element.key}>
                <TextImage
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </TextImage>
                <Droppable type={ItemTypes.TEXT_IMAGE} id={element.key} index={index} />
            </Fragment>); 
        case ItemTypes.SLIDER_PRODUCT:
            return (<Fragment key={element.key}>
                <SliderProduct
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    products={apiConfig.products}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </SliderProduct>
                <Droppable type={ItemTypes.SLIDER_PRODUCT} id={element.key} index={index} />
            </Fragment>);
        case ItemTypes.TEXT_VIDEO:
            return (<Fragment key={element.key}>
                <TextVideo
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    products={apiConfig.products}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </TextVideo>
                <Droppable type={ItemTypes.TEXT_VIDEO} id={element.key} index={index} />
            </Fragment>);
        case ItemTypes.PLACEHOLDER:
            return (<Fragment key={element.key}>
                <div className={`builder-placeholder ${element.placeholderConfig.fullWidth ? 'fullwidth' : ''}`} style={{
                    height: element.placeholderConfig.height,
                    backgroundColor: element.placeholderConfig.bgColor,
                    marginBottom: element.placeholderConfig.noBottomMargin ? 0 : 20
                }}>
                    <span style={{ color: element.placeholderConfig.color }}>{element.placeholderConfig.text}</span>
                </div>
                <Droppable type={ItemTypes.ALL} id={element.key} index={index} />
            </Fragment>);
        case ItemTypes.TEXT:
        case ItemTypes.TEXT_HTML:
            return !element.hide && (<Fragment key={element.key}>
                <Text
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    onDelete={deleteCallback}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </Text>
                <Droppable type={ItemTypes.TEXT} id={`${element.key}-bottom`} index={index} />
            </Fragment>);
        case ItemTypes.IMAGE:
            return !element.hide && (<Fragment key={element.key}>
                <Image
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    update={updateConfig}
                    picker={null}
                    spread={true}
                >
                    <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                </Image>
                <Droppable type={ItemTypes.IMAGE} id={`${element.key}-bottom`} index={index} />
            </Fragment>);
        case ItemTypes.CMS_SLIDER:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderTop
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderTop>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_ABOUT:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderAbout
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderAbout>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_LIST_CATEGORIES:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <ListCategories
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </ListCategories>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_BY_TAB:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderByTab
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderByTab>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_INSTAGRAM:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderInstagram
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderInstagram>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_IMAGE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderImage
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderImage>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderPresentationMedia
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderPresentationMedia>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_MOMENTS:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderMoments
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderMoments>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_GRID_IMAGE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <GridImage
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </GridImage>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_TEXT_MEDIA:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <BlocTextMedia
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </BlocTextMedia>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_BANNER_MEDIA:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <BannerMedia
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </BannerMedia>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SECTION_DESCRIPTION_PAGE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SectionDescriptionPage
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SectionDescriptionPage>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SECTION_MANIFEST_BLOCK:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SectionManifestBlock
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SectionManifestBlock>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_CATEGORIES_HOME:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <CategoriesHome
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </CategoriesHome>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_PRODUCTS_HOME:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderProductsHome
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                        products={apiConfig?.products}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderProductsHome>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_COLLECTIONS_HOME:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <CollectionsHome
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </CollectionsHome>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_BLOC_STORELOCATOR_HOME:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <BlocHomeStorelocator
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </BlocHomeStorelocator>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
            case ItemTypes.CMS_ENTETE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <Entete
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </Entete>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_TOP_IMAGE_FULLSCREEN:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <TopImageFullscreen
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </TopImageFullscreen>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLOGAN:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <Slogan
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </Slogan>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_TITLE_TEXT:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <TitleText
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </TitleText>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SEPARATOR:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SeparatorBlock
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SeparatorBlock>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        default:
            return null;
    }
}