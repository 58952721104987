import styled from 'styled-components';

export default styled.p`
    font-size: 12px;
    color: ${props => props.color || '#000'};
    @media screen and (min-width: 1024px){
        font-size : 16px;
    }
    &.center{
        text-align: center;
    }
    &.w100{
        width: 100%;
    }
    &.white{
        color: white;
    }
`; 