import React from 'react';
import { InputLabel, Container, Grid} from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import InputBuilder from './InputBuilder';

const ContainerCustom = styled(Container)`
    background: ${props => props.backgroundcolor ? props.backgroundcolor : colors.grey.lighter.hue980};
    padding: ${props => props.padding ? props.padding : '32px'};
    @media screen and (max-width: 1280px){
        padding: 24px;
    }
    @media screen and (max-width: 960px){
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 6px;
    }
    .blockContainer{
        box-shadow: ${props => props.styleinnercontainer ? props.styleinnercontainer.boxShadow : '0px 0px 5px rgba(0, 0, 0, 0.1)'};
        padding: ${props => props.styleinnercontainer ? props.styleinnercontainer.padding : '2rem'};
        height: ${props => props.styleinnercontainer ? props.styleinnercontainer.height : '65vh'};
        .containerTree{
            background: ${props => props.styleinnercontainer ? props.styleinnercontainer.backgroundInner : colors.white};
            &>div{
                background: ${props => props.styleinnercontainer ? props.styleinnercontainer.backgroundInner : colors.white};
            }
        }
    }
`;

const InputLabelCustom = styled(InputLabel)`
    color: ${colors.blue.regular};
    line-height: 20px;
    @media screen and (min-width: 1280px){
        height: 51px;
    }
    @media screen and (max-width: 1450px){
        font-size: 14px;
        line-height: 18px;
    }
    @media screen and (max-width: 1280px){
        font-size: 13px;
        line-height: 17px;
    }
    @media screen and (max-width: 960px){
        font-size: 12px;
        line-height: 16px;
    }
    display: flex;
    align-items: center;
    word-break: break-word;
`;

class FormBuilder extends React.Component{    
    checkConditional = (conditional) =>{
        if(conditional){
            let check;

            switch(conditional.type){
                case 'and':
                default : 
                    for(let condition of conditional.conditions){
                        if(this.checkCondition(condition)){
                            check = true;
                        } else{
                            check = false;
                            break;
                        }   
                    }
                    break;                    


                case 'or':
                    for(let condition of conditional.conditions){
                        if(this.checkCondition(condition)){
                            check = true;
                            break;
                        } else{
                            check = false;
                        }  
                    }
                    break;                

            }
            console.log('checkkkkk',check)
            return check;
        } else {
            return true;
        }
    }

    checkCondition = (condition) =>{
        let conditionCheck;
        if(condition.condition){
            conditionCheck = this.checkConditional(condition.condition)
        } else {
            conditionCheck = this.props.allState[condition.key] === condition.value
        }
        return conditionCheck
    }

    render() {

        return (
            <ContainerCustom backgroundcolor={this.props.backgroundColor} styleinnercontainer={this.props.styleInnerContainer} padding={this.props.padding} style={{maxWidth: '100%'}} fixed>
                <form style={{maxWidth: 1280}}>
                    <Grid container direction="row" justify="space-between" spacing={0} style={{ marginBottom: 10 }}>
                        {this.props.optionsInputs?.length > 0 ? this.props.optionsInputs.map(this._renderItem) : null}
                    </Grid>
                </form>
            </ContainerCustom>
        );
    }

    _renderItem = (item, index) => {
        if (this.props.hideInput) {
            if (!this.props.hideInput.includes(item.stateName)) {
                return (
                    <Grid container direction="column" justify="center" spacing={0} key={`ItemForm${item.stateName}`}>
                        <Grid container direction="row" spacing={1}>
                            {item.label ? 
                                <Grid item md={3} xs={12}>
                                    <InputLabelCustom>{item.label}{item.required ? '*' : null}</InputLabelCustom>
                                </Grid> : null
                            }

                            <Grid item md={item.label ? 9 : 12} xs={12}>
                                <InputBuilder 
                                    value={
                                        item.translated  
                                            ? item.valueWithFlags 
                                                ? this.props.allState[this.props.allState.currentLang][item.stateName]?.value 
                                                : this.props.allState[this.props.allState.currentLang][item.stateName]
                                            : item.valueWithFlags  
                                                ? this.props.allState[item.stateName]?.value 
                                                : this.props.allState[item.stateName] 
                                    } 
                                    allState={this.props.allState}
                                    handleTreeChange={this.props.handleTreeChange}
                                    expandNodes={this.props.expandNodes}
                                    treeEditCat={this.props.treeEditCat}
                                    copyCategories={this.props.copyCategories}
                                    treeAddCat={this.props.treeAddCat}
                                    input={item}
                                    errorCallback={this.props.errorCallback ? (error) => this.props.errorCallback(item.stateName, error) : () => {}}
                                    stateCallback={(evt, custom) => this.props.stateCallback(item.stateName, evt, custom, item.translated)} 
                                    handleButtonGroupChange={
                                        this.props.handleButtonGroupChange 
                                        ? this.props.handleButtonGroupChange 
                                        : null
                                    }
                                    gridItems={this.props.gridItems}
                                    loadGrid={this.props.loadGrid}
                                    addProducts={this.props.addProducts}
                                    reloadListing={this.props.resetReloadListing}
                                    catalogDatas={this.props.catalogDatas}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        }
        else{
            if (!item.multipleTree && item.length !== 0){
                return (
                    this.checkConditional(item.conditional) ?
                        <Grid container direction="row" spacing={1} key={`ItemForm${item.stateName}`}>
                            {item.label ? 
                                <Grid item md={3} xs={12}>
                                    <InputLabelCustom>{item.label}{item.required ? '*' : null}</InputLabelCustom>
                                </Grid> : null
                            }
                            <Grid item md={item.label && item.type !== "mapper" ? 9 : 12 } xs={12}>
                                <InputBuilder 
                                    value={
                                        item.translated  
                                            ? item.valueWithFlags 
                                                ? this.props.allState[this.props.allState.currentLang][item.stateName]?.value 
                                                : this.props.allState[this.props.allState.currentLang][item.stateName]
                                            : item.valueWithFlags  
                                                ? this.props.allState[item.stateName]?.value 
                                                : this.props.allState[item.stateName] 
                                    } 
                                    allState={this.props.allState}
                                    input={item} 
                                    handleTreeChange={this.props.handleTreeChange}
                                    expandNodes={this.props.expandNodes}
                                    treeEditCat={this.props.treeEditCat}
                                    copyCategories={this.props.copyCategories}
                                    treeAddCat={this.props.treeAddCat}
                                    errorCallback={this.props.errorCallback ? (error) => this.props.errorCallback(item.stateName, error) : () => {}}
                                    stateCallback={(evt, custom) => this.props.stateCallback(item.stateName, evt, custom, item.translated)} 
                                    handleButtonGroupChange={this.props.handleButtonGroupChange 
                                        ? this.props.handleButtonGroupChange 
                                        : null} 
                                    gridItems={this.props.gridItems}
                                    loadGrid={this.props.loadGrid}
                                    addProducts={this.props.addProducts}
                                    resetReloadListing={this.props.resetReloadListing}
                                    catalogDatas={this.props.catalogDatas}
                                />
                            </Grid> 
                        </Grid>
                    : null
                )
            } else {
                if (!item.explorerManager && item.length !== 0){
                    return(
                        <Grid item xs={item.typeOfTree === "categorieFilter" ? 4 : 6} key={`ItemForm${item.stateName}`} style={{ flexBasis: '48%' }}>
                            {item.label ? 
                                <Grid item md={3} xs={12}>
                                    <InputLabelCustom>{item.label}{item.required ? '*' : null}</InputLabelCustom>
                                </Grid> : null
                            }
                            <Grid item md={item.label ? 9 : 12} xs={12}>
                                <InputBuilder 
                                    value={
                                        item.translated  
                                            ? item.valueWithFlags 
                                                ? this.props.allState[this.props.allState.currentLang][item.stateName]?.value 
                                                : this.props.allState[this.props.allState.currentLang][item.stateName]
                                            : item.valueWithFlags  
                                                ? this.props.allState[item.stateName]?.value 
                                                : this.props.allState[item.stateName] 
                                    }  
                                    allState={this.props.allState}
                                    input={item} 
                                    handleTreeChange={this.props.handleTreeChange}
                                    expandNodes={this.props.expandNodes}
                                    treeEditCat={this.props.treeEditCat}
                                    copyCategories={this.props.copyCategories}
                                    treeAddCat={this.props.treeAddCat}
                                    stateCallback={(evt, custom) => this.props.stateCallback(item.stateName, evt, custom, item.translated)} 
                                    handleButtonGroupChange={this.props.handleButtonGroupChange 
                                        ? this.props.handleButtonGroupChange 
                                        : null} 
                                    handleChangeCategorie={this.props.handleChangeCategorie}
                                    selectedCategorie={this.props.selectedCategorie}
                                    productByCategorie={this.props.productByCategorie}
                                    selectedProductByCategorie={this.props.selectedProductByCategorie}
                                    setSelectedProducts={this.props.setSelectedProducts}
                                    allCategories={this.props.allCategories}
                                    gridItems={this.props.gridItems}
                                    loadGrid={this.props.loadGrid}
                                    addProducts={this.props.addProducts}
                                    resetReloadListing={this.props.resetReloadListing}
                                    catalogDatas={this.props.catalogDatas}
                                />
                            </Grid> 
                        </Grid>
                    )
                }else{
                    if(item.length !== 0){
                        return(
                            <Grid item xs={item.type === "checkboxItem" ? 8: 6} key={`ItemForm${item.stateName}`}>
                                {item.label ? 
                                    <Grid item xs={3}>
                                        <InputLabelCustom>{item.label}{item.required ? '*' : null}</InputLabelCustom>
                                    </Grid> : null
                                }
                                <Grid item xs={item.label ? 9 : 12}>
                                    <InputBuilder 
                                        value={
                                            item.translated  
                                                ? item.valueWithFlags 
                                                    ? this.props.allState[this.props.allState.currentLang][item.stateName]?.value 
                                                    : this.props.allState[this.props.allState.currentLang][item.stateName]
                                                : item.valueWithFlags  
                                                    ? this.props.allState[item.stateName]?.value 
                                                    : this.props.allState[item.stateName] 
                                        } 
                                        allState={this.props.allState}
                                        input={item} 
                                        handleTreeChange={this.props.handleTreeChange}
                                        expandNodes={this.props.expandNodes}
                                        treeEditCat={this.props.treeEditCat}
                                        copyCategories={this.props.copyCategories}
                                        treeAddCat={this.props.treeAddCat}
                                        stateCallback={(evt, custom) => this.props.stateCallback(item.stateName, evt, custom, item.translated)} 
                                        handleButtonGroupChange={this.props.handleButtonGroupChange 
                                            ? this.props.handleButtonGroupChange 
                                            : null} 
                                        handleChangeCategorie={this.props.handleChangeCategorie}
                                        selectedCategorie={this.props.selectedCategorie}
                                        productByCategorie={this.props.productByCategorie}
                                        selectedProductByCategorie={this.props.selectedProductByCategorie}
                                        setSelectedProducts={this.props.setSelectedProducts}
                                        allCategories={this.props.allCategories}
                                        gridItems={this.props.gridItems}
                                        loadGrid={this.props.loadGrid}
                                        addProducts={this.props.addProducts}
                                        resetReloadListing={this.props.resetReloadListing}
                                        catalogDatas={this.props.catalogDatas}
                                    />
                                </Grid> 
                            </Grid>
                        )                        
                    }
                }
            }
        }
    }
};

export default FormBuilder;
