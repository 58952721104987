import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultProductHome, getDefaultTextCms, getDefaultLinkCms, getDefaultBackgroundCms } from '../../helpersCms';

export default {
    name: 'Slider de produits',

    blocks:{
        background: getDefaultBackgroundCms('rgba(255,255,255,0)'),
        title: getDefaultTextCms("Titre", "Produits", null),
        link: getDefaultLinkCms("Lien", "Tout afficher", "https://www.lecoqsportif.com/", null, null),
        products:{
            title: "Produit",
            name: "Produit",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_PRODUCTS_HOME,
            buttonAdd: 'un Produit',
            limit: 10,
            config: [ 
                getDefaultProductHome(),
                getDefaultProductHome(),
                getDefaultProductHome(),
                getDefaultProductHome(),
            ]
        }
    }
};