import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import TopPanel from '../../../layouts/TopPanel/TopPanel';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import formShopTheLook from './config/formShopTheLook.config';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';
import request from '../../../../js/utils/fetch';

import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { hasRights } from '../../../../js/utils/rights';
import { PRODUCTS, PRODUCTS_PRODUCTS, VIEW, CREATE, IMPORT} from '../../../../js/constants/constant-rights';

import {prepareAttributeValues,saveElement,updateElement,getElements} from '../../../../js/utils/functions';
import Listing from '../../../layouts/Listing/Listing';
import {listSettings,listMappers,perPageOptions} from './config/listShopTheLook.config';
import * as formHelper from '../../../../js/helpers/form'
import {makeUnique,searchItem} from '../../../../js/helpers/search'
import {Grid} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../ui/button/Button';
import { CREATE_LOOKBOOK_PRODUCT, DELETE_LOOKBOOK, DELETE_LOOKBOOK_PRODUCT_POSITION, UPDATE_LOOKBOOK_PRODUCT_POSITION } from '../../../../queries/look_book';
import _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { GET_ASSET_BY_ID_CATALOG } from '../../../../queries/assets';

const BoxCustom = styled(Box)`
    width: auto;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;
class ShopTheLook extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openForm:false,
            openDialog: false,
            editForm: 'add',
            assetId: `/api/assets/${props.match.params.id}`,
            currentLang: props.locales[0].node.code,
            errors: {},
            seeErrors: false,
            ready: false,
            libelle: null,
            imageLook: null,
            status: true,
            selectedProducts: [],
            productsForPosition: [],
            initialProductsList: [],
            productsToDelete: [],
        };
    }

    handleLang = (event) => {
        this.setState({ 
            currentLang: event.target.value,
        }, () => {
            eventService.fire();
        });
    };


    handleToggleDrawer = (stateDrawer,reset=false) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer]
        });
        if (reset){
            this.resetState()
        }
    };
    

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    handleNextStep = () =>{
        let result = formHelper.handleStep('next',this.state.errors);
        this.setState({
            seeErrors : !result
        })
        return result;
    }

    handleBackStep = () => {
        this.setState({ errors: {} });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    handleMediaPicker=(selected,stateName,translated)=>{
        this.handleInput(stateName,selected,null,translated);  
    }

    getProductsToDelete = (result) => {
        let deleteProducts = [];
        let listProducts = this.state.productsForPosition;
        for (let i = 0; i < this.state.initialProductsList.length; ++i ){
            let checkDelete = result.find(e => e === this.state.initialProductsList[i].value)
            if (!checkDelete){
                deleteProducts.push(this.state.initialProductsList[i].id)
            }
        }
        for (let product of listProducts){
            let checkDelete = result.find(e => e === product.value)
            if (!checkDelete){
                product.delete = true
            }
        }
        this.setState({
            productsForPosition: listProducts,
            productsToDelete: deleteProducts,
        });
    }

    handleInput = (stateName,evt,custom,translated) =>{
        if (stateName === "selectedProducts"){
            let value = formHelper.setValue(this.state,stateName, evt, custom, translated);
            this.setState({
                ...value
            }, () => {
                let reworkData = [].concat(this.state.productsForPosition)
                for (let product of this.state.selectedProducts){
                    let create = false
                    if (!this.state.initialProductsList.find(e => e.value === product) && !reworkData?.find(e => e.value === product)){
                        create = true
                        reworkData.push({
                            value: product,
                            position: null,
                            create,
                            update: false
                        })
                    }
                }
                this.setState({
                    productsForPosition: reworkData
                }, () => {
                    this.getProductsToDelete(this.state.selectedProducts)
                })
                
            })
        }
        else if(stateName === "position"){
            let getProducts = this.state.productsForPosition
            let productData = getProducts.find(e => e.value === custom)
            productData.position = evt.target.value
            let update = true
            if (!this.state.initialProductsList.find(e => e.value === custom)){
                update = false
            }
            productData.update = update
            this.setState({
                productsForPosition: getProducts
            });
        }
        else{
            let value = formHelper.setValue(this.state,stateName, evt, custom, translated);
            this.setState({...value})
        }
    }
    changePosition = (stateName, value, idProduct) => {
        let getProducts = this.state.listProducts
        let productData = getProducts.find(e => e.node.id === idProduct)
        let getData = productData.node.productCategories.edges.find(e => e.node.category.id === this.props.activeCategorie.id)
        if (stateName === "position"){
            getData.node.position = value?.target?.value ?? value
        }else{
            if (getData.node.selection){
                getData.node.selection.id = value?.target?.value ?? value
            }else{
                getData.node.selection = {id : value?.target?.value ?? value}
            }
        }
        this.setState({
            listProducts: getProducts
        });
    };

    resetState = () =>{
        this.setState({
            libelle: null,
            imageLook: null,
            status: true,
            selectedProducts: [],
            productsForPosition: [],
            initialProductsList: [],
            productsToDelete: [],
        })
    }

    saveProducts = (idLookBook)=>{
        return new Promise(async (resolve, reject) => {
            for(let product of this.state.productsForPosition){
                if (!product.delete){
                    if (product.create){
                        this.props.client.mutate({
                            mutation: CREATE_LOOKBOOK_PRODUCT,
                            variables: { 
                                lookBook: idLookBook.id,
                                product: product.value,
                                position: product.position
                            }
                        })
                    }else if (product.update){
                        this.props.client.mutate({
                            mutation: UPDATE_LOOKBOOK_PRODUCT_POSITION,
                            variables: {
                                id: product.id,
                                position: product.position
                            }
                        })
                    }
                }
            }
            for (let deleteProduct of this.state.productsToDelete){
                this.props.client.mutate({
                    mutation: DELETE_LOOKBOOK_PRODUCT_POSITION,
                    variables: { 
                        id: deleteProduct,
                    }
                })
            }
            resolve();
        });
    }

    handlerLookBookMutation= async() =>{
        this.props.startLoading();
        let variables = null;
        switch(this.state.editForm){
            case 'add':
                
                variables={
                    asset: this.state.assetId,
                    libelle : this.state.libelle,
                    status : this.state.status,
                    media: this.state.imageLook?.id || null
                }
                let newLookBook = await saveElement('lookBook',variables,{enableLoad:false})
                await this.saveProducts(newLookBook)
                this.props.snack(ALERT_SUCCESS, 'Look ajouté avec succès');
                this.resetState();
                this.handleToggleDrawer('openForm', true);
                this.reloadLookBooks();
                this.props.stopLoading();
                break;

            case 'edit':
                
                variables={
                    id : this.state.currentLookBookId,
                    asset: this.state.assetId,
                    libelle : this.state.libelle,
                    status : this.state.status,
                    media: this.state.imageLook?.id || null
                }
                let getResult = await updateElement(this.state,'lookBook',variables,null,{enableLoad:false})
                if (getResult){
                    await this.saveProducts(getResult)
                    this.props.snack(ALERT_SUCCESS, 'Look modifié avec succès');
                    this.resetState();
                    this.handleToggleDrawer('openForm', true);
                    this.props.stopLoading();
                    this.reloadLookBooks();
                }
                break;
            default:
                return null;
        }
    }

    handleToggleDialog = () => {
        this.setState({ 
            openDialog: !this.state.openDialog
        });
    };

    deleteMutation = () => {
        this.props.startLoading();
        this.props.client.mutate({
            mutation: DELETE_LOOKBOOK,
            variables: { id: this.state.currentLookBookId }
        }).then(result => {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Look supprimé avec succès');
            this.reloadLookBooks();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        }).catch(error => {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, `Impossible de supprimer le look`);
            this.reloadLookBooks();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        });
    };

    handleGetAssetInfo = () => {
        this.props.client.query({
            query: GET_ASSET_BY_ID_CATALOG,
            fetchPolicy: 'no-cache',
            variables: {"id": this.state.assetId}
        }).then(result => {
            this.setState({
                assetInfo: result.data.asset,
                isReady: true
            })
        });
    }

    initForm=()=>{
        this.setState({
            editForm : 'add'
        })
        this.handleToggleDrawer('openForm');
    }

    editHandler= async(lookBook)=>{
        let newArray = [];
        newArray = lookBook.productLookBooks.edges?.map(e => e.node.product.id)
        this.setState({
            editForm : 'edit',
            currentLookBookId: lookBook.id,
            libelle: lookBook.libelle,
            imageLook: lookBook.media,
            status: lookBook.status,
            selectedProducts: _.clone(lookBook.productLookBooks.edges?.map(e => e.node.product.id))
        })
        await this.prepareProducts(lookBook.productLookBooks.edges)
        this.handleToggleDrawer('openForm');
    }

    prepareProducts= (listProducts) => {
        return new Promise(async (resolve, reject) => {
            let arrayProducts = []
            for (let product of listProducts){
                arrayProducts.push({
                    value: product.node.product.id,
                    position: product.node.position,
                    create: false,
                    update: false,
                    id: product.node.id,
                })
            }
            this.setState({
                productsForPosition: arrayProducts,
                initialProductsList: arrayProducts
            })
            resolve()
        })
    }

    getLookBooksCallback = () =>{
        this.setState({
            reloadLookBooks:false
        })
    }
    reloadLookBooks = () =>{
        this.setState({ reloadLookBooks : true})
    }

    componentDidMount() {
        const getRights = hasRights(PRODUCTS, PRODUCTS_PRODUCTS, VIEW);
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.handleGetAssetInfo();
            if(this.props.location.state?.hasMessage){
                this.props.snack(ALERT_SUCCESS,this.props.location.state.message)
            }
        }
    };

    render() {
        return (
            <div style={{width: "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                <TopPanel 
                    icomoon="picto_cart-bag" 
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Shop the look" 
                    subtitle="Gestion de vos looks (création / modification / suppression)" 
                    handlerAdd={()=>this.initForm()} 
                    textAdd={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un look" : null}
                    // searchHandler={
                    //     this.handleChangeCompanies
                    // } 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    buttonAvailable={!this.state.openForm}
                    // currentLang={this.state.currentLang} 
                    // handleLang={this.handleLang} 
                    // locales={this.props.locales}
                    // handlerImport={() => this.handleToggleDrawer('openImportTypesForm')} 
                    // textImport={hasRights(CRM_CONTACT, CRM_CONTACT_SOCIETIES, IMPORT) ? "Importer des sociétés" : null}
                    hasBorder={true}
                />
                {/* <TraductionSelect 
                    currentLang={this.state.currentLang} 
                    handleLang={this.handleLang} 
                    locales={this.props.locales}
                /> */}
                <Grid container style={{marginTop: 16}}>
                    <BoxCustom onClick={this.props.history.goBack} style={{marginBottom: 16}}>
                        <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                    </BoxCustom>
                    <Listing
                        label = 'look'
                        settings = {listSettings}
                        cardProps = {{
                            openForm : this.state.openForm,
                            currentLang : this.state.currentLang,
                            textButton:'Modifier',
                            handlerButton: this.editHandler,
                            windowWidth : this.props.windowWidth,
                        }}
                        queryVariables={{
                            asset : this.state.assetId,
                        }}
                        perPageOptions = {perPageOptions}
                        mappers = {listMappers}
                        currentLang = {this.state.currentLang}
                        identifier = 'lookBooks'
                        viewsOptions = {{
                            current : 'card',
                            settings : ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                        reload={this.state.reloadLookBooks}
                        listingCallback = {this.getLookBooksCallback}
                    />
                </Grid>
            
                {this.state.isReady ? (
                    <div>
                            <LayoutBuilder
                                icomoon="picto_cart-bag"
                                opened={this.state.openForm} 
                                forClose={()=>this.handleToggleDrawer('openForm', true)} 
                                handlerSetup={()=>{}} 
                                dataLayout={formShopTheLook(this.state.editForm, this.handleMediaPicker, this.state)}
                                drawerWidth={this.props.drawerWidth}
                                allState={this.state}    
                                stateCallback={this.handleInput} 
                                errorCallback={this.handleFormError}    
                                stepperButtonAction={[
                                    this.handleNextStep,
                                    this.handleNextStep,
                                    this.handleNextStep,
                                ]}
                                stepperButtonDisabled={[null, () => this.state.selectedProducts.length === 0, null]}
                                validateButton={true}
                                handlerMutation={this.handlerLookBookMutation}
                                currentLang={this.state.currentLang}
                                handleLang={this.handleLang}
                                deleteMutation={this.state.editForm === "edit" ? () => {this.handleToggleDialog()} :  null}
                                deleteText={'Supprimer le look'}
                                handleButtonGroupChange={this.handleButtonGroupChange}
                            />
                        </div>
                    )
                :null}
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleToggleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Êtes-vous sûr de vouloir supprimer ce look ?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si vous supprimez ce look, celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                            Annuler
                        </Button>
                        <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => a.node ? ({...a, node: {...a.node}}) : ({...a})); 

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ShopTheLook))));