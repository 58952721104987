import { gql } from 'apollo-boost';

/*
{
  "category": "/api/categories/1"
}
 */
// TODO Dono
export const GET_PRODUCT_CATEGORY = gql`
query getProductCategory($category: String!){
  productCategories(category: $category){
    edges{
      node{
        id
        position
        product{
          id
        }
        category{
          id
        }
        selection{
          id
        }
      }
    }
  }
}
`;

/*
{
  "category": "/api/categories/1",
  "product": "/api/products/1",
  "selection": "/api/selections/1"
}
 */
export const CREATE_PRODUCT_CATEGORY = gql`
mutation createProductCategory($category: String!, $product: String!, $selection: String){
  createProductCategory(input:{category: $category, product: $product, selection: $selection}){
    productCategory{
      id
    }
  }
}
`;

/*
{
  "id": "/api/product-categories/1",
  "category": "/api/categories/1",
  "product": "/api/products/1",
  "selection": "/api/selections/1"
}
 */
export const UPDATE_PRODUCT_CATEGORY = gql`
mutation updateProductCategory($id: ID!, $category: String, $product: String, $position: Int){
  updateProductCategory(input:{id: $id, category: $category, product: $product, position: $position}){
    productCategory{
      id
    }
  }
}
`;

/*
{
  "id": "/api/product-categories/1"
}
 */
export const DELETE_PRODUCT_CATEGORY = gql`
mutation deleteProductCategory($id: ID!){
  deleteProductCategory(input:{id: $id}){
    ProductCategory{
      id
    }
  }
}
`;