import React from 'react';
import { Grid } from '@material-ui/core';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import {ROUTE_PRODUCTS_ADD, ROUTE_HOME } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import importProductsTypesConfig from './config/importProductsTypes.config';
import importProductsConfig from './config/importProducts.config';
import importProductsApiConfig from './config/importProductsAPI.config';
import exportProductsConfig from './config/exportProducts.config';
import importFichier from '../../../../assets/pictos/icon-import-fichier.svg';
import importAPI from '../../../../assets/pictos/icon-import-api.svg';
import importFlux from '../../../../assets/pictos/icon-import-flux.svg';
import request from '../../../../js/utils/fetch';
import { hasRights } from '../../../../js/utils/rights';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';
import { PRODUCTS, PRODUCTS_PRODUCTS, VIEW, CREATE, IMPORT, EXPORT } from '../../../../js/constants/constant-rights';
import '../../../navigation/DrawerLeft.scss';
import ListingProducts from './components/ListingProducts';

class ListProducts extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentLang: props.locales[0].node.code,
            openForm: false,
            openFormImport: false,
            openFormExport: false,
            catalogFormData : {},
            exportName: '',
            exportLang: props.locales[0].node.code,
            exportType: 'csv',
            importTypeFile: 'csv',
            importUrl : '',
            errors: {},
            dataImportLayout: importProductsConfig,
        }
        this.errors = {}
    }

    componentDidMount() {
        const getRights = hasRights(PRODUCTS, PRODUCTS_PRODUCTS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
    }

    handleToggleDrawer = (stateDrawer) => {
        if (stateDrawer === "openFormExport"){
            this.setState({
                [stateDrawer] : !this.state[stateDrawer],
                exportName: '',
                exportLang: this.state.currentLang,
                exportType: 'csv',
            })
        }
        this.setState({ 
            [stateDrawer] : !this.state[stateDrawer]
        });
    };

    handleFormImport = (type, title) => {

        let separator = {
            type: 'select',
            label: 'Séparateur',
            translated: false,
            helper: {
                text: `Choisir le séparateur`,
                link: false,
            },
            required: false,
            stateName: 'importSep',
            value: [
                { value: ',', label: ',' },
                { value: ';', label: ';' },
                { value: '|', label: '|' },
                { value: 'xml', label: 'SimpleXML ( temp )' },
            ]
        }
        let setupUploadFile;
        let setupUploadFileExcel;
        let setupUploadFileZip;
        let setupMapping;

        setupUploadFile = {
            type: 'uploadFile',
            label: 'Importer votre fichier CSV',
            translated: false,
            required: false,
            stateName: `upload_CSV`,
            downloadType: 'product',
        };

        setupUploadFileZip = {
            type: 'uploadFile',
            label: 'Importer votre fichier ZIP',
            translated: false,
            required: false,
            typeUpload: 'zip',
            stateName: `upload_ZIP`,
        };

        setupUploadFileExcel = {
            type: 'uploadFile',
            label: 'Importer votre fichier Excel',
            translated: false,
            required: false,
            typeUpload: 'xls',
            stateName: `upload_CSV`,
            downloadType: 'product',
        };
        
        setupMapping = {
            type: 'mapper',
            label: '',
            translated: false,
            required: false,
            mapOn: `upload_CSV`,
            stateName: `mapper`,
            staticOptions: [
                {
                    id: 'sku',
                    label: 'Code article'
                }, {
                    id: 'price',
                    label: 'Price'
                },
                {
                    id: 'stock',
                    label: 'Stock'
                }
            ]
        };

        let configFormImport = this.state.dataImportLayout;
        configFormImport.formConfig.children[0].optionsInputs = [];
        configFormImport.formConfig.children[1].optionsInputs = [];

        if (title === "excel"){
            configFormImport.formConfig.children[0].optionsInputs.push(setupUploadFileExcel);
        }else{
            configFormImport.formConfig.children[0].optionsInputs.push(separator);
            configFormImport.formConfig.children[0].optionsInputs.push(setupUploadFile);
            configFormImport.formConfig.children[0].optionsInputs.push(setupUploadFileZip);
        }
        configFormImport.formConfig.children[1].optionsInputs.push(setupMapping);

        this.setState({
            dataImportLayout: configFormImport,
            catalogFormData: {
                type,
                title
            },
            mapper: [],
            headers: title === 'api' ? '' : null,
            upload_CSV: null,
            upload_ZIP: null,
            importSep: ',',
            importValues: {},
            importLang: this.props.locales[0].node.id,
            importType: title,
            importUrl: title === 'api' ? '' : null,
        }, () => this.handleToggleDrawer('openFormImport'));
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();

            this.forceUpdate();
        });
    };

    handlerMutation = async () => {
        this.props.startLoading();

        let importConfig = {
            "url": `${process.env.REACT_APP_API_ROOT}${this.state.importType === "excel" ? `/medias/${this.state.upload_CSV?.medias?.mediaObject.filePath}` : this.state.upload_CSV?.medias?.contentUrl ?? null}`,
            "mapper": this.state.mapper,
            "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'product').node.id,
            "locale": this.state.importLang,
            "delimiter": this.state.importSep,
            "media": this.state.upload_ZIP ? `/api/media-objects/${this.state.upload_ZIP.medias.id}` : null,
            "fileType": this.state.importSep === "xml" ? "simplexml" : null,
        };

        try {
            await request(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
            this.props.snack(ALERT_SUCCESS, `Votre fichier a été importé avec succès, son intégration dans SpreadSuite sera exécutée lors du passage du CRON (Tâche automatique).`,6000);
            this.handleToggleDrawer('openForm');
            this.handleToggleDrawer('openFormImport');
            this.props.stopLoading();
        } catch(e) {
            this.props.snack(ALERT_ERROR, `L'import a échoué !`);
            this.props.stopLoading();
        }
    };

    handlerMutationExport = async () => {
        this.props.startLoading();
        if (this.hasErrors()) {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            return eventService.fire();
        }
        try {
            let data = new FormData();
            data.append('name', this.state.exportName);
            data.append('local', this.state.exportLang);
            data.append('format', this.state.exportType);

            let urlencoded = new URLSearchParams(data).toString();

            request(`${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`, 'get', null, 'application/x-www-form-urlencoded')
                .then(async (data) => {
                    if (data.success){
                        window.open(`${process.env.REACT_APP_API_ROOT}/medias/export/${data.mediaObject.filePath + '.' + (this.state.exportType === 'xls' ? this.state.exportType + 'x' : this.state.exportType)}`, '_blank');
                    }
                    this.props.stopLoading();
                    this.props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
                });
        } catch(e) {
            this.props.snack(ALERT_ERROR, `L'exportation a échoué !`);
            this.props.stopLoading();
        }
        this.handleToggleDrawer('openFormExport')
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        if(stateName === 'importSep'){
            this.setState({
                upload_CSV: null,
                upload_ZIP: null
            })
        }
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };


    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    render() {
        return (
            <div>
                <TopPanel 
                    icomoon="picto-produit"
                    colorIcomoon={colors.blue.lighter.hue300}
                    title={process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gérer les Produits" : "Liste de vos Produits"}
                    subtitle={process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Liste de vos produits (création / modification / suppression)" : "Liste de vos produits ainsi que leurs stocks"}
                    handlerAdd={() => this.goTo(ROUTE_PRODUCTS_ADD)} 
                    textAdd={process.env.REACT_APP_MODE_SPREAD !== "hub" ? hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un produit" : null : null}
                    handlerImport={() => this.handleToggleDrawer('openForm')} 
                    textImport={process.env.REACT_APP_MODE_SPREAD !== "hub" ? hasRights(PRODUCTS, PRODUCTS_PRODUCTS, IMPORT) ? "Importer des produits" : null : null}
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    currentLang={this.state.currentLang} 
                    handleLang={this.handleLang} 
                    locales={this.props.locales}
                    hasBorder={true}
                    buttonAvailable={true}
                />

                <Grid container direction="row" justify="center" style={{paddingTop: 8}}>
                    <ListingProducts 
                        productBypage = {{
                            card: [24,48,72],
                            list: [30,40,50]
                        }}
                        isCatalog = {false}
                        nbperpage = {24}
                        currentLang={this.state.currentLang} 
                        windowWidth={this.props.windowWidth}
                        openForm={this.state.openForm}
                    />
                </Grid>

                <LayoutBuilder 
                    opened={this.state.openForm} 
                    forClose={() => this.handleToggleDrawer('openForm')} 
                    dataLayout={importProductsTypesConfig} 
                    drawerWidth={this.props.drawerWidth} 
                    dataCard={[
                        {
                            'libelle': 'Importer via un',
                            'bicoloreText': 'flux',
                            'colorhover': '#6EAED1',
                            'picto': importFlux,
                            'disabled': true,
                            'textButton': 'Importer',
                            'description': 'Votre import produits facilité en renseignant simplement votre flux', 
                            'catalogDescription' : 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'flux')
                        },
                        {
                            'libelle': 'Importer via une',
                            'bicoloreText': 'API',
                            'colorhover': '#6EAED1',
                            'picto': importAPI,
                            'disabled': false,
                            'textButton': 'Importer',
                            'description': 'Votre import produits facilité en renseignant simplement votre API', 
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'api')
                        },
                        {
                            'libelle': 'Importer un',
                            'bicoloreText': 'fichier',
                            'colorhover': '#6EAED1',
                            'picto': importFichier,
                            'disabled': false,
                            'textButton': 'Importer',
                            'description': 'Votre import produits facilité en important simplement votre fichier', 
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'fichier')
                        },
                        {
                            'libelle': 'Importer un',
                            'bicoloreText': 'fichier Excel',
                            'colorhover': '#6EAED1',
                            'picto': importFichier,
                            'disabled': false,
                            'textButton': 'Importer',
                            'description': 'Votre import produits facilité en important simplement votre fichier excel', 
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'excel')
                        }
                    ]}
                />

                <LayoutBuilder
                    opened={this.state.openFormImport} 
                    icomoon={'ico-import-fichier'}
                    forClose={() => this.handleToggleDrawer('openFormImport')}  
                    dataLayout={this.state.importType === 'api' ? importProductsApiConfig : this.state.dataImportLayout}
                    drawerWidth={this.state.drawerWidth}
                    handleCancel={this.handleCancel}
                    handlerMutation={this.handlerMutation} 
                    allState={this.state} 
                    stateCallback={this.stateCallback}
                    stepperButtonDisabled={[() => (
                        this.state.importType === 'api' ? this.state.importUrl === '' : this.state.upload_CSV === null )
                        , null
                    ]}
                    stepperButtonAction={[
                        null, 
                        () => {
                            let error = 0;
                            let requiredValueMapper;
                            if(this.state.requiredValueMapper){
                                let i = 0;
                                requiredValueMapper = this.state.requiredValueMapper;
                                for(let item of this.state.requiredValueMapper){
                                    for(let value of this.state.mapper){
                                        if(value && value.length > 0){
                                            for(let val of value){
                                                if(val === item.id){
                                                    error++;
                                                    requiredValueMapper[i].checked = true;
                                                }
                                            }
                                        }
                                    }
                                    i++;
                                }
                            }
                            if(error === this.state.requiredValueMapper.length){
                                return true;
                            } else {
                                this.setState({requiredValueMapper}, () => {
                                    this.props.snack(ALERT_ERROR, this.errorMapper());
                                })
                                return false;
                            }
                        }
                    ]}
                    backStepperButtonAction={[null, null, null]}
                />      
                
                <LayoutBuilder 
                    opened={this.state.openFormExport} 
                    icomoon={'ico-import-fichier'}
                    forClose={() => this.handleToggleDrawer('openFormExport')}  
                    dataLayout={exportProductsConfig(this.props.locales, 'products')} 
                    drawerWidth={this.state.drawerWidth}
                    handleCancel={this.handleCancel}
                    handlerMutation={this.handlerMutationExport} 
                    allState={this.state} 
                    stateCallback={this.stateCallback}
                    validateButton={true}
                    errorCallback={this.handleFormError}
                    checkError={() => {}}
                />      

            </div>
        );
    }

    errorMapper = () => {
        let string = '';
        let i = 0;
        for(let value of this.state.requiredValueMapper){
            if(!value.checked){
                if(i > 0){
                    string += ', ';
                }
                else{
                    string+= ' ';
                }
                string += `"${value.label}"`;
                i++;
            }
        }
        if(i===1){
            return `Le champ ${string} est requis`;
        }
        else{
            return `Les champs${string} sont requis`;
        }
    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: { productId : id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ListProducts))));