import React, { useEffect, useState } from "react";
import Div from '../shared/div';
import H2 from '../shared/h2';
import H4 from '../shared/h4';
import H5 from '../shared/h5';
import Img from '../shared/img';
import Button from '../shared/button';
import Swiper from 'swiper';

const CollectionsHome = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;


    useEffect(() => {
        const swiper = new Swiper('.swiper-container-collections', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-collections',
            },
            navigation: {
                nextEl: '.swiper-button-next-collections',
                prevEl: '.swiper-button-prev-collections',
            },
            spaceBetween: 10,
            breakpoints: {
                300:{
                    slidesPerView: 1.1,
                },
                800:{
                    slidesPerView: 2.2,
                },
                1200:{
                    slidesPerView: 3.2,
                },
            }
        });
    }, []);
    useEffect(() => {
        const swiper = new Swiper('.swiper-container-collections', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-collections',
            },
            navigation: {
                nextEl: '.swiper-button-next-collections',
                prevEl: '.swiper-button-prev-collections',
            },
            spaceBetween: 10,
            breakpoints: {
                300:{
                    slidesPerView: 1.1,
                },
                800:{
                    slidesPerView: 2.2,
                },
                1200:{
                    slidesPerView: 3.2,
                },
            }
        });
    }, [props]);

    return (
        <Div style={{position: 'relative', background: data.inputs.backgroundColor?.value ? data.inputs.backgroundColor.value : '#FAFAFB'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className="bloc-list" style={{width: data.inputs.typeSlider?.value ? '95%' : null, marginLeft: data.inputs.typeSlider?.value ? '5%' : null}}>
                <H2 className="w100 uppercase">{data.inputs.value.value}</H2>
                <Div className="list-collections" style={{flexWrap: !data.inputs?.typeSlider?.value ? 'wrap' : null }}>
                    {
                        data.inputs.typeSlider?.value ? (
                            <Div className="swiper-container swiper-container-collections">
                                <div className="swiper-wrapper">
                                    {
                                        data.blocks.collections.config.map((collection, index) => (
                                            <Div className="relative swiper-slide">
                                                <Img className="w100" src={
                                                    typeof collection.blocks.image.inputs.value.value === 'string' ? 
                                                    collection.blocks.image.inputs.value.value 
                                                    : collection.blocks.image.inputs.value.value.filePath ? 
                                                    `${process.env.REACT_APP_MEDIAS}/${collection.blocks.image.inputs.value.value.filePath}` 
                                                    : null
                                                } alt={collection.blocks.title.inputs.value.value} />
                                                <Div className="absolute gradient-overlay" />
                                                <a href={collection.blocks.link.inputs.value.value}>
                                                    <Div className="absolute">
                                                        <H4 className="white uppercase center">{collection.blocks.title.inputs.value.value}</H4>
                                                        <H5 className="white uppercase center">{collection.blocks.subtitle.inputs.value.value}</H5>
                                                        <Button 
                                                            backgroundColor={collection.blocks.button?.inputs.backgroundColor.value}
                                                            color={collection.blocks.button?.inputs.color.value}
                                                            style={{
                                                                fontWeight: 'bold', 
                                                                textTransform: collection.blocks.button?.inputs.textTransform.value,
                                                            }}
                                                        >
                                                            {collection.blocks.button?.inputs.value.value ? collection.blocks.button?.inputs.value.value : 'Découvrir'}
                                                        </Button>
                                                    </Div>
                                                </a>
                                            </Div>
                                        ))
                                    }
                                </div>
                                <div className="swiper-scrollbar swiper-scrollbar-collections"></div>
                                <div className="swiper-button-prev swiper-button-prev-collections"></div>
                                <div className="swiper-button-next swiper-button-next-collections"></div>
                            </Div>
                        ) : (
                            data.blocks.collections.config.map((collection, index) => (
                                <Div className="relative">
                                    <Img className="w100" src={
                                        typeof collection.blocks.image.inputs.value.value === 'string' ? 
                                        collection.blocks.image.inputs.value.value 
                                        : collection.blocks.image.inputs.value.value.filePath ? 
                                        `${process.env.REACT_APP_MEDIAS}/${collection.blocks.image.inputs.value.value.filePath}` 
                                        : null
                                    } alt={collection.blocks.title.inputs.value.value} />
                                    <Div className="absolute gradient-overlay" />
                                    <a href={collection.blocks.link.inputs.value.value}>
                                        <Div className="absolute">
                                            <H4 className="white uppercase center">{collection.blocks.title.inputs.value.value}</H4>
                                            <H5 className="white uppercase center">{collection.blocks.subtitle.inputs.value.value}</H5>
                                            <Button 
                                                backgroundColor={collection.blocks.button?.inputs.backgroundColor.value}
                                                color={collection.blocks.button?.inputs.color.value}
                                                style={{
                                                    fontWeight: 'bold', 
                                                    textTransform: collection.blocks.button?.inputs.textTransform.value,
                                                }}
                                            >
                                                {collection.blocks.button?.inputs.value.value ? collection.blocks.button?.inputs.value.value : 'Découvrir'}
                                            </Button>
                                        </Div>
                                    </a>
                                </Div>
                            ))
                        )
                    }
                </Div>
            </Div>
        </Div>
    );
};

export default CollectionsHome;