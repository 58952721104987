import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlideImage, getDefaultTextareaCms, getDefaultTextCms} from '../../helpersCms';

export default {
    name: 'Section de description de la page',
    inputs: {
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
        showSlider: {
            label: 'Afficher le slider',
            type: FormInputTypes.SWITCH,
            value: true,
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "LOCAL"),
        subtitle: getDefaultTextCms("Sous-titre", "Né au coeur du bassin textile troyen, à Romilly-sur-Seine, LE COQ SPORTIF est héritier et acteur de la tradition « à façon » de la bonneterie française."),
        text: getDefaultTextareaCms("Sous-titre", "<p>Un savoir-faire de tricot et de teinture du coton qui garantit qualité, confort et durabilité aux vêtements. Depuis 2005, le savoir-faire de la marque a été relocalisé dans sa région d’origine. Tous nos produits sont ainsi aujourd’hui tricotés et teints autour de Romilly-sur-Seine, et confectionnés dans un rayon de moins de 2500 kilomètres (France, Portugal, Maroc).</p><p>Designer et fabricant de chaussures de sport depuis les années 1980s, LE COQ SPORTIF applique ces mêmes standards de qualité à ses tennis, runnings et sneakers. Si la production locale est plus difficile à mettre en place car les savoir-faire chaussures ont quasiment disparu d’Europe, nous faisons cependant progresser de x% par an nos collections intégrant ces critères de proximité. Objectif : 50% de notre production en 2025</p>", true),
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_IMAGE,
            buttonAdd: 'une slide',
            limit: 10,
            config: [ 
                getDefaultSlideImage("https://via.placeholder.com/920x470"),
                getDefaultSlideImage("https://via.placeholder.com/920x470"),
                getDefaultSlideImage("https://via.placeholder.com/920x470"),
            ]
        }
    }
};