import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlideTop } from '../../helpersCms';

export default {
    name: 'Slider home',
    blocks:{
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER,
            buttonAdd: 'une slide',
            limit: 3,
            config: [ 
                getDefaultSlideTop()
            ]
        }
    }
};