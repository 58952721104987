import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import Button from '../../../../components/ui/button/Button';
import CustomCard from './CustomCard';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import { red } from '@material-ui/core/colors';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '&>div':{
            margin: '16px 0',
            boxShadow: 'inherit',
            '&:first-child':{
                marginTop: 0,
            },
            '&:before':{
                content: 'inherit',
            }
        }
    },
    cardHeader: {
        color: '#000'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rulePanel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: colors.blue.lighter.hue900,
    },
    actionBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 10
    }
}));

const DeleteButton = withStyles(theme => ({
    root: {
        color: red[500],
        backgroundColor: 'transparent',
        minHeight: 10,
        alignSelf: 'flex-end',
        textTransform: 'inherit',
        borderRadius: 0,
        width: 'auto',
        boxShadow: 'inherit',
        '&:hover': {
            color: red[700],
            backgroundColor: 'transparent',
        }
    },
}))(Fab);

const MultipleBlockConfig = ({ datas, buildFormInput, addBlock, deleteBlock }) => {
    const AccordionSummaryCustom = styled(AccordionSummary)`
        background: ${colors.blue.lighter.hue900};
        &>div{
            justify-content: space-between;
            align-items: center;
            p{
                color: ${colors.blue.lighter.hue300};
            }
            svg{
                fill: ${colors.blue.lighter.hue300};
            }
        }
    `;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                datas.config.map((data, i) => {
                    let inputs = [];

                    // List inputs 

                    for (let inputName in data.inputs) {
                        let input = data.inputs[inputName];
                        inputs.push(buildFormInput(input, data.id));
                    }

                     // List block inputs

                    for (let blockName in data.blocks) {
                        let block       = data.blocks[blockName];
                        let cardInputs  = [];
            
                        for (let inputName in block.inputs) {
                            let input = block.inputs[inputName];
                            cardInputs.push(buildFormInput(input, block.id));
                        }

                        if (block.type === 'multiple-block') {
                            inputs.push(<MultipleBlockConfig key={block.id} datas={block} buildFormInput={buildFormInput} addBlock={addBlock} deleteBlock={deleteBlock} />)
                        } else {
                            inputs.push(<CustomCard block={block} cardInputs={cardInputs} />);
                        }
                    }

                    return (
                        <Accordion style={{borderRadius: 0}} key={i}>
                            <AccordionSummaryCustom
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{datas.title} {i + 1}</Typography>
                                
                            </AccordionSummaryCustom>
                            <AccordionDetails className={classes.rulePanel} style={{padding: '0 16px!important'}}>
                                { inputs }
                                <DeleteButton color="secondary" size="small" aria-label="delete" className={classes.button} onClick={() => {deleteBlock(datas, i)}}>
                                    <DeleteIcon style={{width: 16}} />
                                    Supprimer
                                </DeleteButton>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
            {
                (datas.limit > datas.config.length || !datas.limit) ? (
                    <div className={classes.actionBar}>
                        <Button
                            label="Ajouter"
                            style={{backgroundColor: colors.menu.regular, color: "white", boxShadow: `0px 3px 0px 0px ${colors.menu.darker}`, textTransform: 'initial', width: '100%'}}
                            onClick={() => addBlock(datas)}
                        >
                            <AddIcon style={{fontSize: 16, fill: colors.white, marginRight: 7.5}} /> <span style={{fontSize: 17}}>Ajouter {datas.buttonAdd ? ` ${datas.buttonAdd}` : null}</span>
                        </Button>
                    </div>
                ) : null
            }
        </div>
    )
};

export default MultipleBlockConfig;
