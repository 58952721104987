import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { SET_GUIDELINE } from '../../../../js/constants/action-types';
import { ROUTE_SHELLS_LIST } from '../../../../js/constants/route-names';
import { CREATE_EAV_TYPES, DELETE_EAV_TYPES, GET_EAV_TYPES, UNIQUE_EAV_CODE, UPDATE_EAV_TYPES } from "../../../../queries/attributes";
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';

import colors from '../../../../config/theme/colors';

import {Grid} from '@material-ui/core';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import EmptyCard from "../../../ui/empty-card/EmptyCard";
import Empty from '../../../../assets/pictos/empty-picto/empty_guidelines.png';

import CardCustom from '../../../layouts/Card/CardCustom';
import CardGuideline from '../../../layouts/Card/cardContent/CardGuideline';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import formShell from './config/formShell.config';
import Typography from '../../../ui/typography/Typography';
import Button from '../../../ui/button/Button';
import slugify from 'slugify';
import request from '../../../../js/utils/fetch';
import { eventService } from '../../../../js/services/event.service';
import DialogModal from '../../../ui/dialog/DialogModal';
import { setRedux } from '../../../../js/utils/functions';

class Shells extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            currentLang: this.props.locales[0].node.code,
            shells: [],
            isEmpty: false,
            loading: true,
            content:{
                emptyTitle: "Vous n’avez pas encore paramétrer vos coquilles",
                emptySubtitle: "Cliquez ci-dessous commencer la configuration",
                emptyTxtBtn: "Créer ma coquille",
                emptyPicto: Empty,
            },
            editForm: false,
            shellName: null,
            errors: {},
            seeErrors: false,
            openForm: false,
            openDialog: false,
            currentShell: null,
            shellView : 'card'
        }
        this.typingTimer = null;
    }

    resetState() {
        this.setState({
            currentShell: null,
            shellName: null,
            seeErrors: false,
        });
    }

    addShell = () => {
        this.resetState();

        // for (let locale of this.props.locales) {
        //     this.setState({
        //         [locale.node.code]: {}
        //     });
        // }

        this.setState({
            openForm : true,
            editForm: 'add',
        });
    };

    editShell = (shell) => {
        this.resetState();

        this.setState({
            openForm : true,
            editForm: 'edit',
            currentShell: shell,
            shellName: shell.node.code
        });
    };

    deleteShell = () => {
        this.props.startLoading()
        this.props.client.query({
            query: DELETE_EAV_TYPES,
            fetchPolicy: 'no-cache',
            variables: {
                "id": this.state.currentShell.node.id
            }
        }).then(async result =>{
            this.handleToggleDrawer();
            this.getShells();
            await setRedux(false,true,false,false);
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Coquille supprimé !');
        });
    }

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value);
    };

    setValue = (stateName, value) => {
        if(this.state.editForm && stateName === 'shellName'){
            if(value !== this.state.shellName)
            this.checkIdentifier(stateName);
        }
        if(stateName === 'shellPagination'){
            let perPage = value ? 12 : null;
            this.setState({shellPerPage:perPage})
        }
        this.setState({
            [stateName]: value
        });


    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {this.doneTyping(stateName)}, 500);
    };

    doneTyping = (stateName) => {
        if(stateName === 'shellName'){
            this.setState({
                attributeIdentifier: slugify(this.state.shellName, {replacement :'_', lower: true, remove: /[^\w\-\s]+/g}),
            })
        }
        
        if(this.state.shellName){
            this.props.client.query({
                query: UNIQUE_EAV_CODE,
                fetchPolicy: 'no-cache',
                variables: {
                    "code": this.state[stateName]
                }
            }).then(result =>{
                if (result.data.eavTypes.edges.length !== 0){
                    eventService.fire({stateName: 'shellName', errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.'});
                    let errors = this.state.errors;
                    errors[stateName] = true;
                    this.setState({
                        errors
                    })
                }
            });
        }
        this.forceUpdate();
    };

    handleToggleDrawer = () => {
        this.setState({ 
            openForm: !this.state.openForm
        });
    };
    
    getShells = () => {
        this.props.client.query({
            query: GET_EAV_TYPES,
            fetchPolicy: 'no-cache',
            variables: {"isSystem": false,}
        }).then(result =>{
            if(result.data.eavTypes.edges.length === 0){
                this.setState({
                    isEmpty: true,
                });
            }
            else{
                this.setState({
                    shells: result.data.eavTypes.edges
                });
            }
            this.setState({
                loading: false
            });
        });
    }

    componentDidMount = () => {
        for(let locale of this.props.locales){
            this.setState({[locale.node.code]: {}});
        }
        this.getShells();
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }
            let query       = null;
            let variables   = null;
    
            this.props.startLoading();
    
            switch(this.state.editForm) {
                case 'edit' :
                    query = UPDATE_EAV_TYPES;
                    variables = { 
                        isSystem: false, 
                        id: this.state.currentShell.node.id, 
                        code: this.state.shellName, 
                    };
                break;
                case 'add' :
                    query = CREATE_EAV_TYPES;
                    variables = { 
                        isSystem: false, 
                        code: this.state.shellName, 
                    };
                break;
                default: return this.props.stopLoading();
            }
            this.props.client.mutate({
                mutation: query,
                variables,
            }).then(result =>{
                this.handleSuccess();
            })
        } catch(e) {
            this.handleError(e);
        }
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        this.getShells();
        this.props.snack(ALERT_SUCCESS, this.state.editForm === 'edit' 
            ? 'Coquille modifiée !'
            : 'Coquille ajoutée !');

        this.handleToggleDrawer();
        this.resetState();
        await setRedux(false,true,false,false);
        this.props.stopLoading();
    };

    handleToggleDialog = () => {
        this.setState({ 
            openDialog: !this.state.openDialog
        });
    };

    render() {
        return (
            <div style={{width: "100%"}}>
                <TopPanel 
                    icomoon="icon-dashboard"
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Coquilles" 
                    subtitle="Gérer vos coquilles SpreadSuite"
                    handlerAdd={() => {this.addShell()}} 
                    buttonAvailable={true}
                    textAdd="Ajouter une coquille"
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    currentLang={this.state.currentLang} 
                    handleLang={this.handleLang} 
                    locales={this.props.locales}
                    hasBorder={true}
                />
                {
                    this.state.loading ? 
                        (
                            <PageLoader />
                        )
                    :
                    !this.state.isEmpty && this.state.shells.length > 0 ?
                        (
                            <Grid container direction="column" justify="center" spacing={0} style={{paddingBottom: 24, width: '100%', paddingTop: 24}}>
                                <Grid container direction="row" spacing={4}>
                                    {
                                        this.state.shells.map((shell, index) => (
                                            <Grid item xl={4} sm={4} xs={6} style={{display: 'flex', flexWrap: 'wrap'}} key={`card-guideline-${index}`}>
                                                <CardCustom cardContentStyle={{width: '100%'}} style={{width: '100%'}}>
                                                    <Typography>{shell.node.code}</Typography>
                                                    <Grid container justify="flex-end">
                                                        <Button onClick={() => this.editShell(shell)}>Modifier</Button>
                                                    </Grid>
                                                    {/* <CardGuideline guideline={guideline} onClick={() => {this.goTo(ROUTE_BRAND_GUIDELINE_UPDATE.replace(':id', guideline.node.id.replace('/api/shells/', '')), guideline.node.id)}} /> */}
                                                </CardCustom>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        )
                    : 
                        (
                            <EmptyCard title={this.state.content.emptyTitle} subtitle={this.state.content.emptySubtitle} textButton={this.state.content.emptyTxtBtn} onClick={() => {this.addShell()}} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                        ) 
                }
                    <LayoutBuilder 
                        opened={this.state.openForm} 
                        forClose={this.handleToggleDrawer} 
                        handlerMutation={() => (this.handlerMutation())}
                        dataLayout={formShell(this.state.editForm)} 
                        allState={this.state} 
                        icomoon={(this.state.editForm === 'edit') ? 'ico-modifier-attribut' : 'ico-ajouter-attribut'}
                        stateCallback={this.handleInputChange} 
                        handleButtonGroupChange={this.handleInputChange} 
                        errorCallback={this.handleFormError}
                        checkError={() => {}}
                        deleteMutation={
                            (this.state.editForm === 'edit') ? 
                                this.handleToggleDialog 
                            : null 
                        }
                        deleteText={this.state.editForm === 'edit' ? 'Supprimer la coquille' : null}
                        deleteButton={this.state.editForm} 
                        validateButton={true}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                    /> 
                <DialogModal 
                    open={this.state.openDialog} 
                    title={`Êtes-vous sûr de vouloir supprimer cette coquille ?`}
                    secondaryAction={this.handleToggleDialog} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                    primaryAction={() => {this.deleteShell(); this.handleToggleDialog()}}
                    primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
                >
                    Si vous supprimez cette coquille celle-ci ne sera plus accessible. Si vous ne l'utilisez plus mais que vous ne souhaitez pas la supprimer, annulez la suppression.
                </DialogModal>
            </div>
        );
    }

    goTo = (route, id = null) => {
        this.props.history.push({
            pathname : route,
            state: {id}
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        users: state.users,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setGuideline: (guideline) => dispatch({ type: SET_GUIDELINE, payload: { guideline }}),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Shells)));