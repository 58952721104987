import styled from 'styled-components';

export default styled.a`
    font-size: 16px;
    line-height: 21px;
    color: ${props => props.color || 'rgba(0, 14, 40, 1)'}; 
    background-color: ${props => props.backgroundColor || '#fff'};
    padding: 16px;
    margin: 16px 0;
    display: block;
    text-decoration: none;
    opacity: 1;
    transition: all ease 0.1s;
    &:hover{
        background-color: ${props => props.color || 'rgba(0, 14, 40, 1)'}; 
        color: ${props => props.backgroundColor || '#fff'};
    }
    &.little{
        font-size: 12px;
        line-height: 15px;
        padding: 6px 12px;
    }
    &.link{
        padding: 0;
        margin: 0;
    }
    @media screen and (max-width: 767px){
        &.reverseOnResponsive{
            background-color: ${props => props.color || 'rgba(0, 14, 40, 1)'}!important; 
            color: ${props => props.backgroundColor || '#fff'}!important;
            &:hover{
                color: ${props => props.color || 'rgba(0, 14, 40, 1)'}!important; 
                background-color: ${props => props.backgroundColor || '#fff'}!important;
            }
        }
    }
    @media screen and (min-width: 1024px){
        font-size: 20px;
        line-height: 26px;
        padding: 12px 64px;
        &.little{
            font-size: 20px;
            line-height: 26px;
            padding: 12px 24px;
        }
    }
    
`; 