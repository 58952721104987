import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import RadioCards from '../../../../ui/inputs/RadioCards';
import CheckboxCards from '../../../../ui/inputs/CheckboxCards'
import FormattingCustomFlux from '../components/FormattingCustomFlux';
import FormattingCustomURL from '../components/FormattingCustomURL';

export default function assetsAdd(allState, assetsAddData, currentLang, allLocales, currencies, getAssetData, customAttributes, getAllCatalogs, handleMediaPicker, isGame=false, getAllRetailers, assetLangSelected, getAllAssets, listGroupRates, selectGroup){
    let typeInput = '';

    let attributeSetup = attribute => {
        const defaultLang = attribute.node.translation.translationDatas.edges[0];

        const langSelected = attribute.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === currentLang
        );

        switch(attribute.node.attributeType.input) {
            case 'textarea':
                typeInput = 'textarea';
                break;
            case 'number': // create an input
            case 'decimal': // create an input
            case 'text':
                typeInput = 'text';
                break;
            case 'select':
                typeInput = 'select';
                break;
            case 'image':
                typeInput = 'mediaPicker';
                break;
            case 'file':
                typeInput = 'file';
                break;
            default: typeInput = null;
        };

        return ({
            type: typeInput,
            label: langSelected?.node.value ?? defaultLang.node.value,
            translated: true,
            helper: {
                text: 'Helper non disponible pour le moment',
                link: false,
            },
            isSystem: false,
            currentLang,
            required: attribute.node.isRequired,
            stateName: attribute.node.identifier,
            handleMediaPicker:typeInput === 'mediaPicker'?handleMediaPicker:null,

            value: attribute.node.attributeOptions.edges.map((values) => {
                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                    lang => lang.node.locale.code === currentLang
                );

                return ({
                    value: values.node.id,
                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault.node.value
                });
            })
        });
    };
    let generalInputs  = []

    generalInputs = [
        {
            type: 'text',
            label: 'Identifier',
            helper: {
                text: 'Indiquez l\'identifiant de l\'asset',
                link: false,
            },
            required: true,
            stateName: 'paramIdentifier',
            disabled: false,
        },
    ];

    if (assetsAddData.localeRequired){
        generalInputs.push(
            {
                type: 'select',
                label: 'Sélection de la langue',
                helper: {
                    text: `Indiquez la langue désiré`,
                    link: false,
                },
                translated: false,
                required: true,
                disabled: false,
                multiselect: assetsAddData.multiLocale ? true : false,
                stateName: 'assetLangSelected',
                value: allLocales.map(lang => {
                    return ({
                        value: lang.node.id,
                        label: lang.node.libelle,
                    });
                })
            },
        )   
    }

    generalInputs.push(
        {
            type: 'text',
            label: 'Url de l\'hébergeur de production',
            helper: {
                text: 'Indiquez l\'URL de l\'hébergeur de production de l\'asset',
                link: false,
            },
            required: false,
            stateName: 'prodHostUrl',
            disabled: false,
        },
        {
            type: 'text',
            label: 'Url de production',
            helper: {
                text: 'Indiquez l\'URL de production de l\'asset',
                link: false,
            },
            required: false,
            stateName: 'prodUrl',
            disabled: false,
        },
        {
            type: 'text',
            label: 'Url de l\'hébergeur de développement',
            helper: {
                text: 'Indiquez l\'URL de l\'hébergeur de développement de l\'asset',
                link: false,
            },
            required: false,
            stateName: 'stageHostUrl',
            disabled: false,
        },
        {
            type: 'text',
            label: 'Url de développement',
            helper: {
                text: 'Indiquez l\'URL de développement de l\'asset',
                link: false,
            },
            required: false,
            stateName: 'stageUrl',
            disabled: false,
        },
    )
    

    if (isGame){
        generalInputs.push(
        {
            type: 'date',
            getTime: true,
            label: 'Date de début',
            helper: {
                text: 'Date de début',
                link: false,
            },
            required: true,
            stateName: 'paramStartAt',
        },
        {
            type: 'date',
            getTime: true,
            label: 'Date de fin',
            helper: {
                text: 'Date de fin',
                link: false,
            },
            required: true,
            stateName: 'paramEndAt',
            minDate: 'paramStartAt',
        },
        )
    }

    if(selectGroup){
        generalInputs.push(selectGroup);
    }
    
    if(currencies?.length > 0 && assetsAddData.hasCatalog){
        //CONDITIONS -> Basé sur le catalogue, si pas de catalogues pas de prix
        generalInputs.push(
            {
                type: 'select',
                label: 'Devise',
                helper: {
                    label: 'Devise',
                    text: 'Indiquez la devise',
                    link: false,
                },
                required: false,
                stateName: 'currency',
                value:
                    currencies.map((currency, index) => {
                        return ({
                            value: currency.node.id,
                            label: `${currency.node.libelle} (${currency.node.code})`,
                        });
                    })
            },
        )
    }

    
    generalInputs.push(
        {
            type: 'select',
            label: "Groupe de taxe",
            helper: {
                label: "Groupe de taxe",
                text: "Indiquez le groupe pour le taux d'imposition",
                link: false,
            },
            disabled: allState.currency ? false : true,
            required: false,
            stateName: 'groupRate',
            value:
                listGroupRates.filter(e => e.node.currency.id === allState.currency).map((group, index) => {
                    return ({
                        value: group.node.id,
                        label: `${group.node.code}`,
                    });
                })
        },
    )
    generalInputs.push(
        {
            type: 'buttonGroup',
            label: 'État',
            helper: {
                label: 'État',
                text: 'Indiquez l\'état',
                link: false,
            },
            required: false,
            stateName: 'paramStatus',
            value: [
                {
                    value: true,
                    label: 'Actif',
                },
                {
                    value: false,
                    label: 'Inactif',
                },
            ]
        },
    )

    let customInputs    = customAttributes?.filter(e => e.node.attributeType.input !== 'decimal').map(attributeSetup);
    let steps = [];

    if (assetsAddData.channelRequired){
        steps.push(
            {
                labelName: 'Selection du channel',
                isOptionnal: false,
                optionsInputs:[
                    {
                        type: 'radio',
                        label: '',
                        helper:{
                            text: '',
                            link: false,
                        },
                        required: true,
                        stateName: 'channelSelected',
                        value: getAssetData.map((values) => {
                            return ({
                                value: values.node.id,
                                label: values.node.libelle,
                                description: values.node.description,
                                image: values.node.logo,
                            });
                        })
                    }
                ]
            }
        )
    }

    steps = steps.concat(
        [
            {
                labelName: 'Paramétrage',
                isOptionnal: false,
                optionsInputs:
                    customInputs.length ? customInputs.concat(generalInputs) : generalInputs
                    // generalInputs.concat(customInputs.length ? customInputs : []),
            },
        ]
    )

    if (assetsAddData.retailerRequired){
        steps = steps.concat(
            [
                {
                    labelName: 'Sélection du retailer',
                    isOptionnal: true,
                    component: RadioCards,
                    optionsInputs:
                    {
                        stateName: 'retailerSelected',
                        allInfo: true,
                        paginationType: 'retailer', 
                        required: true,
                        isEmpty: getAllRetailers.length > 0 ? false : true,
                        value: getAllRetailers.map((values) => {
                            return ({
                                values,
                                smallCard: true
                            });
                        })
                    }
                },
            ]
        )
    }

    if (assetsAddData.hasCatalog){
        steps = steps.concat(
            [   
                {
                    labelName: 'Sélection du catalogue',
                    isOptionnal: false,
                    component: RadioCards,
                    optionsInputs:
                    {
                        stateName: 'catalogSelected',
                        allInfo: true,
                        paginationType: 'catalog', 
                        required: true,
                        isEmpty: getAllCatalogs.length > 0 ? false : true,
                        value: getAllCatalogs.map((values) => {
                            return ({
                                values,
                                smallCard: true
                            });
                        })
                    }
                },
            ]
        )
    }
    if (assetsAddData.hasWidget){
        steps = steps.concat(
            [   
                {
                    labelName: 'Assets',
                    isOptionnal: true,
                    component: CheckboxCards,
                    optionsInputs:
                        {
                            stateName: 'assetsSelected',
                            allInfo: true,
                            paginationType: 'asset',
                            required: false,
                            isEmpty: getAllAssets.length > 0 ? false : true,
                            value: getAllAssets.map((values) => {
                                return ({
                                    values,
                                    smallCard: true
                                });
                            })
                        }
                },
            ]
        )
    }

    if (assetsAddData.identifier === "customflux") {
        steps = steps.concat(
            [
                {
                    labelName: 'Formatage',
                    isOptionnal: false,
                    component: FormattingCustomFlux,
                    optionsInputs:
                        []
                }
            ]
        )
    }
    steps = steps.concat(
        [
            {
                labelName: 'Format URL',
                isOptionnal: true,
                component: FormattingCustomURL,
                optionsInputs:
                    []
            }
        ]
    )
    var obj=
        {
            titleForm:`Configurer ${assetsAddData.libelle}`,
            subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre asset',
            langSelect: true,
            drawerType: 'swipeable',
            noForm: true,
            component: Button,
            formConfig: {
                type: STEPPER,
                finalStep: {
                    title: 'Et voilà !',
                    subtitle: 'Votre asset est désormais configuré',
                    textButton: 'Créer cet asset'
                },
                children: steps
            },
        }    
    return obj;
}
