import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import TopPanel from '../../../layouts/TopPanel/TopPanel';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';

import { ROUTE_BUILDER_CMS_CONTENT, ROUTE_DIFFUSION_PERMANENTS_PAGES_CHILDS_ASSET, ROUTE_HOME } from '../../../../js/constants/route-names';
import { hasRights } from '../../../../js/utils/rights';
import { BUILDER, BUILDER_ASSETS, VIEW, UPDATE, CREATE} from '../../../../js/constants/constant-rights';

import {prepareAttributeValues,saveElement,updateElement,getElements} from '../../../../js/utils/functions';
import Listing from '../../../layouts/Listing/Listing';
import {listSettings,listMappers,perPageOptions} from './config/pages/listPages.config';
import pageForm from './config/pages/formPage.config';
import contentForm from './config/pages/formContent.config';
import * as formHelper from '../../../../js/helpers/form'
import {makeUnique,searchItem} from '../../../../js/helpers/search'
import { DELETE_MAGENTO_PAGE, DELETE_MAGENTO_PAGE_CONTENT, GET_MAGENTO_PAGE_TYPES } from '../../../../queries/assetMagentoPage';
import {Grid, Typography, Box} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../ui/button/Button';
import * as moment from 'moment';
import request from '../../../../js/utils/fetch';
import slugify from 'slugify';
import styled from 'styled-components';

const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`
class DiffusionListPages extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            openForm:false,
            editForm: 'add',
            currentLang: props.locales[0].node.code,
            assetId: `/api/assets/${props.match.params.id}`,
            pageId: `/api/asset-magento-pages/${props.match.params.page}`,
            errors: {},
            seeErrors: false,
            ready: false,
            pageLibelle: null,
            pageIdentifier: null,
            pageUrl: null,
            pageStatus: true,
            pageIsExisting: false,
            pageIdMagentoPage: null,
            pageType: null,
            contentLibelle: null,
            contentStartDate: null,
            contentEndDate: null,
            contentIsActive: true,
            contentIsDefaultContent: false,
            typeTesting: {
                type: 'magento-page',
                testingState: ['pageLibelle', 'pageIdentifier'],
                testingTypingState: 'pageLibelle',
                identifierState: 'pageIdentifier'
            },
        };
        this.typingTimer = null;
    }

    handleLang = (event) => {
        this.setState({ 
            currentLang: event.target.value,
        }, () => {
            eventService.fire();
        });
    };

    handleToggleDrawer = (stateDrawer,reset=false) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer]
        });
        if (reset){
            this.resetState()
        }
    };
    

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    handleNextStep = () =>{
        let result = formHelper.handleStep('next',this.state.errors);
        this.setState({
            seeErrors : !result
        })
        return result;
    }

    handleBackStep = () => {
        this.setState({ errors: {} });
    }


    handleMediaPicker=(selected,stateName,translated)=>{
        this.handleInput(stateName,selected,null,translated);  
    }

    handleInput = (stateName,evt,custom,translated) =>{
        let value = formHelper.setValue(this.state,stateName, evt, custom, translated);
        this.setState({...value})
            if(this.state.typeTesting.testingState.includes(stateName) && this.state.editForm === "add")
            this.checkIdentifier(stateName);
    }

    resetState = () =>{
        this.setState({
            pageLibelle: null,
            pageIdentifier: null,
            pageUrl: null,
            pageStatus: true,
            pageIsExisting: false,
            pageIdMagentoPage: null,
            pageType: null,
            contentLibelle: null,
            contentStartDate: null,
            contentEndDate: null,
            contentIsActive: true,
            contentIsDefaultContent: false,
        })
    }

    handlerHeaderMutation= async()=>{
        this.props.startLoading();
        let variables = null;
        switch(this.state.editForm){
            case 'add':
                if (this.props.isContent){
                    variables={
                        magentoPage: this.state.pageId,
                        libelle: this.state.contentLibelle,
                        startDate: this.state.contentStartDate,
                        endDate: this.state.contentEndDate,
                        isDefaultContent: false,
                        isActive: this.state.contentIsActive,
                    }
                }else{
                    variables={
                        asset: this.state.assetId,
                        libelle: this.state.pageLibelle,
                        identifier: this.state.pageIdentifier,
                        url: this.state.pageUrl,
                        hasToBePush: true,
                        magentoPageId: this.state.pageIdMagentoPage,
                        isMagentoExistingPage: this.state.pageIsExisting,
                        isActive: this.state.pageStatus,
                        pageType: this.state.pageType || null,
                    }
                }
                await saveElement(this.props.isContent ? 'assetMagentoPageContent' : 'assetMagentoPage',variables,{enableLoad:false})
                this.props.snack(ALERT_SUCCESS, this.props.isContent ? 'Contenu ajouté avec succès' : 'Page ajoutée avec succès');
                this.handleToggleDrawer('openForm', true);
                this.reloadPages();
                this.props.stopLoading();
                break;

            case 'edit':
                if (this.props.isContent){
                    variables={
                        // TODO: LINK TO PAGE ID ?
                        id: this.state.currentContentId,
                        libelle: this.state.contentLibelle,
                        startDate: this.state.contentStartDate,
                        endDate: this.state.contentEndDate,
                        isActive: this.state.contentIsActive,
                    }
                }else{
                    variables={
                        id: this.state.currentPageId,
                        asset: this.state.assetId,
                        libelle: this.state.pageLibelle,
                        identifier: this.state.pageIdentifier,
                        url: this.state.pageUrl,
                        hasToBePush: true,
                        isMagentoExistingPage: this.state.pageIsExisting,
                        isActive: this.state.pageStatus,
                    }
                }
                
                if (this.state.pageIsExisting){
                    variables.magentoPageId = this.state.pageIdMagentoPage
                }else{
                    variables.magentoPageId = null
                }
                let getResult = await updateElement(this.state,this.props.isContent ? 'assetMagentoPageContent' : 'assetMagentoPage',variables,null,{enableLoad:false})
                if (getResult){
                    this.props.snack(ALERT_SUCCESS, this.props.isContent ? 'Contenu modifié avec succès' : 'Page modifiée avec succès');
                    this.handleToggleDrawer('openForm', true);
                    this.props.stopLoading();
                    this.reloadPages();
                }
               
                break;
            default:
                return null;
        }
    }

    handleToggleDialog = () => {
        this.setState({ 
            openDialog: !this.state.openDialog
        });
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    deleteMutation = () => {
        this.props.startLoading();
        this.props.client.mutate({
            mutation: this.props.isContent ? DELETE_MAGENTO_PAGE_CONTENT : DELETE_MAGENTO_PAGE,
            variables: { id: this.props.isContent ? this.state.currentContentId : this.state.currentPageId }
        }).then(result => {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, this.props.isContent ? 'Contenu supprimé avec succès' : 'Page supprimée avec succès');
            this.reloadPages();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        }).catch(error => {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, this.props.isContent ? 'Impossible de supprimer le contenu'  : `Impossible de supprimer la page`);
            this.reloadPages();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        });
    };
    
    doneTyping = (stateName) => {
        let typeTesting = this.state.typeTesting;
        if(stateName === typeTesting.testingTypingState){
            this.setState({
                [typeTesting.identifierState]: slugify(this.state[typeTesting.testingTypingState], {replacement :'_', lower: true, remove: /[^\w\-\s]+/g}),
            });
        }
        if(this.state[typeTesting.identifierState]){
            request(`${process.env.REACT_APP_API}/${typeTesting.type}/unique/${this.state[typeTesting.identifierState]}`, 'get').then(
                (data) => {
                    if(!data.isUnique){
                        eventService.fire({stateName: typeTesting.identifierState, errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.'});
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {this.doneTyping(stateName)}, 500);
    };

    initForm=()=>{
        this.setState({
            editForm : 'add'
        })
        this.handleToggleDrawer('openForm');
    }

    editHandler=(page)=>{
        // let local= moment.tz.guess();
        if (this.props.isContent){
            this.setState({
                editForm : 'edit',
                currentContentId: page.id,
                contentLibelle: page.libelle,
                contentStartDate: moment(page.startDate).format('YYYY-MM-DD'),
                contentEndDate: moment(page.endDate).format('YYYY-MM-DD'),
                contentIsActive: page.isActive,
            })
        }else{
            console.log(page)
            this.setState({
                editForm : 'edit',
                currentPageId: page.id,
                pageLibelle: page.libelle,
                pageIdentifier: page.identifier,
                pageUrl: page.url,
                pageStatus: page.isActive,
                pageIsExisting: page.isMagentoExistingPage,
                pageIdMagentoPage: page.magentoPageRealId,
                pageType: page.pageType.id
            })
        }
        this.handleToggleDrawer('openForm');
    }

    handlerRedirectContents=(page)=>{
        this.goTo(ROUTE_DIFFUSION_PERMANENTS_PAGES_CHILDS_ASSET.replace(':id', this.props.match.params.id).replace(':page', page.id.replace('/api/asset-magento-pages/', '')))
    } 

    getCallback = () =>{
        this.setState({
            reloadPages:false,
        })
    }

    reloadPages = () =>{
        this.setState({ reloadPages : true})
    }

    getPageTypes = () => {
        return new Promise((resolve,reject)=>{
            this.props.client.query({
                query : GET_MAGENTO_PAGE_TYPES,
            }).then(result=>{
                this.setState({
                    listTypesPage : result.data.assetMagentoPageTypes.edges
                },()=>resolve())
            })
        })
    }

    async componentDidMount() {
        const getRights = hasRights(BUILDER, BUILDER_ASSETS, VIEW);
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            if (!this.props.isContent){
                this.setState({
                    isReady : false,
                });
                await this.getPageTypes();
            }
            this.setState({
                isReady : true,
            });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isContent !== this.props.isContent && !this.props.isContent){
            this.reloadPages();
        }
    }

    render() {
        return (
            <div style={{width: "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                <TopPanel 
                    icomoon={this.props.isContent ? "picto-content" : "picto-connector-content"}
                    colorIcomoon={colors.blue.lighter.hue300}
                    title={this.props.isContent ? "Liste des contenus de la page" : "Liste des pages de l'asset"}
                    subtitle={`Gestion de vos ${this.props.isContent ? "contenus" : "pages"} (création / modification / suppression)`} 
                    handlerAdd={()=>this.initForm()} 
                    textAdd={hasRights(BUILDER, BUILDER_ASSETS, CREATE) ? this.props.isContent ? "Ajouter un contenu" : "Ajouter une page" : null}
                    // searchHandler={
                    //     this.handleChangeCompanies
                    // } 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    buttonAvailable={!this.state.openForm}
                    hasBorder={true}
                />
                <Grid container style={{marginTop: 16}}>
                    <Box onClick={this.props.history.goBack} style={{marginBottom: 25}}>
                        <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                    </Box>
                    <Listing
                        label = {this.props.isContent ? 'contenus': 'pages'}
                        settings = {listSettings}
                        cardProps = {{
                            openForm : this.state.openForm,
                            currentLang : this.state.currentLang,
                            textButton:'Modifier',
                            handlerButton: this.editHandler,
                            textButtonSecondary: this.props.isContent ? 'Voir le contenu' : 'Voir les contenus',
                            handlerButtonSecondary: this.props.isContent ? 
                                (page) => {
                                    window.open(`${window.location.origin}${ROUTE_BUILDER_CMS_CONTENT.replace(':assetId', page.magentoPage.asset.identifier).replace(':contentId', page.id.replace('/api/asset-magento-page-contents/', ''))}`, '_blank')
                                }
                                : this.handlerRedirectContents,
                            windowWidth : this.props.windowWidth,
                            isContent: this.props.isContent
                        }}
                        perPageOptions = {perPageOptions}
                        mappers = {listMappers}
                        currentLang = {this.state.currentLang}
                        identifier = {this.props.isContent ? 'assetMagentoPageContents' : 'assetMagentoPages'}
                        queryVariables={{
                            asset : this.state.assetId,
                            magentoPage : this.props.isContent ? `/api/asset-magento-pages/${this.props.match.params.page}` : null
                        }}
                        viewsOptions = {{
                            current : 'card',
                            settings : ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                        reload={this.state.reloadPages}
                        listingCallback = {this.getCallback}
                    />
                </Grid>
            
                {this.state.isReady ? (
                    <LayoutBuilder
                        icomoon={this.props.isContent ? "picto-content" : "picto-connector-content"}
                        opened={this.state.openForm} 
                        forClose={()=>this.handleToggleDrawer('openForm', true)} 
                        handlerSetup={()=>{}} 
                        dataLayout={this.props.isContent ? contentForm(this.state.editForm, this.state): pageForm(this.state.editForm, this.state)} 
                        drawerWidth={this.props.drawerWidth}     
                        allState={this.state}    
                        stateCallback={this.handleInput} 
                        errorCallback={this.handleFormError}    
                        stepperButtonAction={[
                            this.handleNextStep,
                            this.handleNextStep,
                            this.handleNextStep,
                        ]}
                        handleButtonGroupChange={this.handleButtonGroupChange}
                        validateButton={true}
                        handlerMutation={this.handlerHeaderMutation}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        deleteMutation={this.state.editForm === "edit" ? () => {this.handleToggleDialog()} :  null}
                        deleteText={this.props.isContent ? 'Supprimer le contenu' : 'Supprimer la page'}
                    />
                    )
                :null}
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleToggleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Êtes-vous sûr de vouloir supprimer ${this.props.isContent ? 'ce contenu' : 'cette page'} ?`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Si vous supprimez ${this.props.isContent ? 'ce contenu, celui-ci' : 'cette page, celle-ci'} ne sera plus accessible. Si vous ne souhaitez pas ${this.props.isContent ? 'le' : 'la'} supprimer, annulez la suppression en cliquant sur annuler.`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                            Annuler
                        </Button>
                        <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => a.node ? ({...a, node: {...a.node}}) : ({...a})); 

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(DiffusionListPages))));