import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';

import mapDispatchToProps from '../../../../js/helpers/mapDispatchToProps';
import mapStateToProps from '../../../../js/helpers/mapStateToProps';

import {Grid, Tooltip} from '@material-ui/core';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import Empty from '../../../../assets/pictos/empty-picto/empty_products.png';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import CardCustom from '../../../layouts/Card/CardCustom';
import Typography from '../../../ui/typography/Typography';
import Button from '../../../ui/button/Button';
import DialogModal from '../../../ui/dialog/DialogModal';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import colors from '../../../../config/theme/colors';

import { eventService } from '../../../../js/services/event.service';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { hasRights } from '../../../../js/utils/rights';
import { SETTINGS, SETTINGS_USERS, VIEW, CREATE, IMPORT} from '../../../../js/constants/constant-rights';

import { GET_ATTR_GROUPE_WITH_ATTRIBUTES } from '../../../../queries/attributes';
import { GET_COMPLETENESSGROUPS, CREATE_COMPLETENESSGROUP, UPDATE_COMPLETENESSGROUP, DELETE_COMPLETENESSGROUP } from '../../../../queries/completeness_group';

import formAddCompletenessGroup from './config/formAddCompletenessGroup.config';
import styled from 'styled-components';

const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
    font-weight: bold;
    display: block;
    width: 100px;
`;

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.regular,
    },
    tooltip: {
        backgroundColor: colors.blue.regular,
        fontSize: 14,
        padding: 10,
        textAlign: 'left',
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class ProductsCompletude extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            openForm: false,
            openDialog: false,
            errors: {},
            editForm: 'add',
            libelle: null,
            attributesSelected: [],
            ready: false,
            listCompletenessGroup: null
        };
    }

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setState({
            [stateName]: value,
        });
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    handleToggleDrawer = () => {
        this.setState({ 
            openForm: !this.state.openForm
        }, () => {
            if(!this.state.openForm){
                this.handleReinitState();
            }
        });
    };

    handleToggleDialog = () => {
        this.setState({ 
            openDialog: !this.state.openDialog
        });
    };

    handleReinitState = () => {
        this.setState({libelle: null, attributesSelected: [], editForm: 'add', selectedGroup: null});
    }

    handlerMutation = () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            return eventService.fire();
        }
        this.props.startLoading();
        let query = this.state.editForm === 'add' ? CREATE_COMPLETENESSGROUP : UPDATE_COMPLETENESSGROUP;
        let variables = {libelle: this.state.libelle, attributes: this.state.attributesSelected};
        if(this.state.editForm === 'edit'){
            variables.id = this.state.selectedGroup;
        }
        this.props.client.query({
            query,
            variables,
            fetchPolicy: "no-cache",
        }).then(result => {
            this.handleGetCompletnessGroup();
            this.handleToggleDrawer();
            this.handleReinitState();
            this.props.stopLoading();
        });
    };

    
    deleteMutation = () => {
        this.props.startLoading();
        this.props.client.mutate({
            mutation: DELETE_COMPLETENESSGROUP,
            variables: { id: this.state.selectedGroup }
        }).then(result => {
            this.handleGetCompletnessGroup();
            this.handleToggleDrawer();
            this.handleToggleDialog();
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Groupe de complétude supprimé avec succès');
        }).catch(error => {
            this.handleGetCompletnessGroup();
            this.handleToggleDrawer();
            this.handleToggleDialog();
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, `Impossible de supprimer le groupe`);
        });
    };

    handleGetCompletnessGroup = () => {
        this.props.client.query({
            query: GET_COMPLETENESSGROUPS,
            fetchPolicy: "no-cache",
        }).then(result => {
            this.setState({listCompletenessGroup: result.data.completenessGroups});
        });
    }

    componentDidMount = async () => {
        this.handleGetCompletnessGroup();
        this.initAttributs();
    }

    initAttributs() {
        this.props.client.query({
            query: GET_ATTR_GROUPE_WITH_ATTRIBUTES,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({ allGroups: result.data.attributeGroups.edges.filter(e => (!e.node.isSystem && !e.node.isForContent) || e.node.identifier === 'default'), ready: true });
        });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    editGroup = (completenessGroup) => {
        let attributesSelected = [];
        for(let attr of completenessGroup.node.attributes.edges){
            attributesSelected.push(attr.node.id);
        }
        this.setState({
            selectedGroup: completenessGroup.node.id, 
            libelle: completenessGroup.node.libelle,
            attributesSelected,
            editForm: 'edit',
        }, () => {
            this.handleToggleDrawer();

        });
    };

    render() {
        return (
            <div style={{width: this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', paddingBottom: 32}}>
                <TopPanel 
                    icomoon="picto-produit" 
                    colorIcomoon={colors.blue.lighter.hue300} 
                    title="Complétude" 
                    subtitle="Complétude (création / modification / suppression)" 
                    handlerAdd={this.handleToggleDrawer} 
                    textAdd={hasRights(SETTINGS, SETTINGS_USERS, CREATE) ? "Ajouter un groupe de complétude" : null}
                    searchHandler={this.handleChange}  
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    buttonAvailable={this.state.openForm || !this.state.ready ? false : true}
                    openForm={this.state.openForm}
                    hasBorder={true}
                />
                {
                    this.state.ready && this.state.listCompletenessGroup.edges?.length === 0 ? (
                        <EmptyCard title={'Aucun groupe de complétude existant'} subtitle={'Vous pouvez créer dès maintenant un groupe'} textButton={'Créer un groupe de complétude'} onClick={() => {this.handleToggleDrawer()}} picto={Empty} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                    ) : !this.state.ready ? (
                        <PageLoader />
                    ) : (
                        <Grid container direction="column" justify="center" spacing={0} style={{paddingBottom: 24, width: '100%', paddingTop: 24}}>
                            <Grid container direction="row" spacing={4}>
                                {
                                    this.state.listCompletenessGroup.edges.map((completenessGroup, index) => (
                                        <Grid item xl={4} sm={4} xs={6} style={{display: 'flex', flexWrap: 'wrap'}} key={`card-completeness-${index}`}>
                                            <CardCustom cardContentStyle={{width: '100%'}} style={{width: '100%'}} contentpadding={"16px 16px 8px"}>
                                                <Typography variant="h2">{completenessGroup.node.libelle}</Typography>
                                                <BootstrapTooltip 
                                                        title={completenessGroup.node.attributes.edges.map((attr, index) =>{
                                                            return(<span key={`CatalogName${index}`} style={{display: 'block'}}>{index + 1} - {attr.node.identifier}</span>)
                                                        })} 
                                                        arrow
                                                >
                                                    <SpanColor variant="body1">{`${completenessGroup.node.attributes.edges.length} attribut${completenessGroup.node.attributes.edges.length > 1 ? 's' : ''} `}</SpanColor>
                                                </BootstrapTooltip>
                                                <Grid container justify="flex-end">
                                                    <Button onClick={() => this.editGroup(completenessGroup)}>Modifier ce groupe</Button>
                                                </Grid>
                                            </CardCustom>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    )
                }
                { this.state.openForm ? 
                    <LayoutBuilder 
                        opened={this.state.openForm} 
                        forClose={this.handleToggleDrawer} 
                        handlerMutation={() => this.handlerMutation()}
                        allState={this.state}
                        dataLayout={formAddCompletenessGroup(this.state.allGroups, this.state.editForm, this.state.currentLang)}
                        icomoon={'picto-produit'}
                        stateCallback={this.handleInputChange} 
                        errorCallback={this.handleFormError}
                        checkError={() => {}}
                        deleteMutation={this.state.editForm === 'edit' ? this.handleToggleDialog : null}
                        deleteText={this.state.editForm === 'edit' ? 'Supprimer le groupe de complétude' : null}
                        deleteButton={this.state.editForm === 'edit' ? true : false} 
                        validateButton={true}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        stepperButtonAction={[
                            () => {
                                if (this.hasErrors()) {
                                    this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                    this.setState({ seeErrors: true });
                                    eventService.fire();
                                    return false;
                                }
                                return true;
                            }
                        ]}
                    /> 
                    : null
                }
                <DialogModal 
                    open={this.state.openDialog} 
                    title={`Êtes-vous sûr de vouloir supprimer ce groupe de complétude ?`}
                    primaryAction={this.deleteMutation} primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
                    secondaryAction={() => {this.handleToggleDialog()}} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                >
                    Si vous supprimez cette catégorie celle-ci ne sera plus accessible. Si vous ne souhaitez pas la supprimer, annulez la suppression en cliquant sur annuler.
                </DialogModal>
            </div>
        );
    }
    goTo = route => {
        this.props.history.push(route);
    };
}

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsCompletude)));
