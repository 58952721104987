import React from "react";
import { BuilderContext } from "../builder";
import { Alert, AlertTitle } from "@material-ui/lab";
import { AppBar, Tabs, Tab, Typography } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { ControlPoint, Style, Tune, FlipToFront } from '@material-ui/icons';
import colors from "../../config/theme/colors";
import { AssetTypes } from "../shareable/types";
import { getDraggables } from "../core/config";

export const BuilderRight = () => {
    const { 
        assetType,
        currentTab,
        currentComponent, 
        buildForm, 
        buildGeneralInputs,
        page,
        onlyLeft,
        noRight,
        getPageName,
        height,
        presentation,
        changeTab,
    } = React.useContext(BuilderContext);

    const classes = useStyles();

    return (
        <div 
            className="right" 
            style={{ 
                height
            }}
        >
            <AppBar 
                position="static" 
                className={classes.appBar}
            >
                <Tabs 
                    value={currentTab} 
                    onChange={changeTab} 
                    centered 
                    variant="fullWidth"
                >
                    <Tab 
                        className={classes.tabStyle} 
                        id={`simple-tab-${0}`} 
                        aria-controls={`simple-tabpanel-${0}`} 
                        icon={(
                            <BootstrapTooltip title="Composants" className={classes.tooltip}>
                                <ControlPoint />
                            </BootstrapTooltip>
                        )} 
                    />
                    <Tab 
                        className={classes.tabStyle} 
                        id={`simple-tab-${1}`} 
                        aria-controls={`simple-tabpanel-${1}`} 
                        icon={(
                            <BootstrapTooltip title="Apparence" className={classes.tooltip}>
                                <Style />
                            </BootstrapTooltip>
                        )} 
                    />
                    
                    {
                        (assetType === AssetTypes.MINISITE 
                        || assetType === AssetTypes.NEWSLETTER 
                        || assetType === AssetTypes.FLIPBOOK 
                        || assetType === AssetTypes.GAME) && (
                            <Tab 
                                className={classes.tabStyle} 
                                id={`simple-tab-${2}`} 
                                aria-controls={`simple-tabpanel-${2}`} 
                                icon={(
                                    <BootstrapTooltip title="Paramètres" className={classes.tooltip}>
                                        <Tune />
                                    </BootstrapTooltip>
                                )} 
                            />
                        )
                    }
                </Tabs>
            </AppBar>

            <div className="right-content">
                <div
                    id={`simple-tabpanel-${0}`}
                    value={currentTab}
                    index={0}
                    style={{ 
                        display: currentTab === 0 
                            ? 'block' 
                            : 'none', 
                        background: colors.white, 
                        padding: 20 
                    }}
                >
                    <h2 className={classes.title}>
                        Composants - { getPageName(page) }
                    </h2>

                    {
                        currentTab === 0 && (
                            <div 
                                className="first-panel" 
                                style={{ backgroundColor: colors.blue.lighter.hue900 }}
                            >
                                <div style={{ width: '100%', display: 'flex' }}>
                                    <FlipToFront style={{ fill: colors.blue.lighter.hue300 }} />
                                    <Typography className={classes.heading} style={{ color: colors.blue.lighter.hue300, paddingBottom: 12, paddingLeft: 10 }}>Liste des composants</Typography>
                                </div>

                                { getDraggables(assetType, presentation) }
                            </div>
                        )
                    }
                </div>

                <div 
                    id={`simple-tabpanel-${1}`} 
                    value={currentTab} 
                    index={1}
                    style={{
                        background: 'white',
                        display: currentTab === 1 ? 'block' : 'none'
                    }} 
                >
                    {
                        currentTab === 1 && (
                            <div className="second-panel">
                                { 
                                    currentComponent
                                        ? buildForm()
                                        : (
                                            <Alert severity="warning" style={{ width: "calc(100% - 40px)", marginTop: 10, marginBottom: 30 }}>
                                                <AlertTitle>Attention</AlertTitle>
                                                Aucun <strong>composant</strong> sélectionné
                                            </Alert>
                                        )
                                }
                            </div>
                        )
                    }
                </div>

                <div 
                    id={`simple-tabpanel-${2}`} 
                    value={currentTab} 
                    index={2} 
                    style={{ 
                        display: currentTab === 2 
                            ? 'block' 
                            : 'none', 
                        background: 'white' 
                    }}
                >
                    {
                        currentTab === 2 && (
                            <div className="third-panel">
                                <h2 style={{ color: colors.blue.regular, width: '100%', padding: '10px 0', marginBottom: 6, fontSize: 16 }}>
                                    Paramètres
                                </h2>

                                { buildGeneralInputs() }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    tabStyle: {
        maxWidth: 100,
        minWidth: 100
    },
    appBar: {
        zIndex: 0, 
        background: '#F7F7F7', 
        boxShadow: 'inherit' 
    },
    tooltip: {
        fontSize: 20
    },
    title: { 
        color: colors.blue.regular, 
        width: '100%', 
        padding: '10px 0', 
        marginBottom: 6, 
        fontSize: 16 
    }
}));

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: colors.blue.regular,
    },
    tooltip: {
        backgroundColor: colors.blue.regular,
    },
}));

const BootstrapTooltip = (props) => {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
};