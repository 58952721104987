import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardContentDetails from '../../../layouts/Card/cardContent/CardContentDetails';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import formContentDetail from './config/formContentDetail.config';
import Button from '../../../ui/button/Button';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import { eventService } from '../../../../js/services/event.service';
import colors from '../../../../config/theme/colors';
import { ROUTE_CONTENU_LIST, ROUTE_HOME } from '../../../../js/constants/route-names';

import { 
    GET_CONTENT_DATA, 
    DELETE_CONTENT, 
    GET_CONTENTS, 
    UPDATE_CONTENT, 
    UPDATE_CONTENT_DATA, 
    ADD_CONTENT_DATA, 
    DELETE_CONTENT_DATA 
} from '../../../../queries/contents';
import { GET_ATTRIBUTES_BY_GROUPE, GET_ATTR_GROUPE_WITH_ATTRIBUTES } from '../../../../queries/attributes';
import { GET_CONTENT_CATEGORIES } from '../../../../queries/content_categories';
import { hasRights } from '../../../../js/utils/rights';
import { CONTENTS, CONTENTS_CONTENTS, UPDATE, DELETE} from '../../../../js/constants/constant-rights';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';


class ContentDetail extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            groupAttribut: '',
            customAttributes: [],
            metaAttributes: [],
            imageAttributes: [],
            imagesSelected: [],
            attributes : [],
            maxImageNumber : 0,
            imageSrc: [],
            contentId: `/api/contents/${props.match.params.id}`,
            sku: null,
            title: '',
            categories: [],
            categoriesData: [],
            description:'',
            status:'',
            metaTitle: '',
            metaDesc: '',
            openForm: false,
            openDialog: false,
            allGroups: [],
            errors: {},
            secondErrors: {},
            seeErrors: false,
        }
    }

    componentDidMount() {
        const getRights = hasRights(CONTENTS, CONTENTS_CONTENTS, UPDATE)
        
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.initContent();
    }
    
    handleToggleDrawer = (drawer) => {
        if(drawer === 'form'){
            this.setState({ 
                openForm : !this.state.openForm,
            });
        }
        this.setState({ 
            seeErrors: false
        });
    };

    handleToggleDialog = () => {
        this.setState({ 
            openDialog : !this.state.openDialog
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else{
            this.setState({
                [stateName]: value,
            });
        }
    };

    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    resetState() {
        this.setState({
            imageSrc: [],
            categories: [],
            errors: {}
        });
    }

    handleCancel = () => {
        this.resetState();
        this.initContent();
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    deleteMutation = () => {
        let query = null;
        let variables = null;

        query = DELETE_CONTENT;
        variables = { id: this.state.contentId };

        this.props.client.mutate({
            mutation: query,
            variables,
            refetchQueries: [{
                query: GET_CONTENTS,
            }]
        }).then(result =>{
            this.goTo(ROUTE_CONTENU_LIST);
            this.handleToggleDrawer('form');
            this.handleToggleDialog();
        })
    };

    copy(array) {
        let newArray = [];
        
        for (let elem of array)
            newArray.push(Object.assign({}, elem));

        return newArray;
    }

    copyArrayOfObjects = array => array.map(a => ({...a}));

    getAttributeTranslatedValue = (id, lang) => {
        if (!this.state.attributes)
            return null;

        let attribute = this.state.attributes.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };


    saveAttributes = content => {
        return new Promise(async (resolve, reject) => {
            let attributes = this.state.isSystemAttributes.concat(this.state.customAttributes);

            for (let attribute of attributes) {
                for (let locale of this.props.locales) { 
                    let formValue           = this.state[locale.node.code][attribute.node.identifier];
                    let currentTranslation  = this.getAttributeTranslatedValue(attribute.node.id, locale.node.id);
                    let isMedia             = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';
    
                    if (formValue && isMedia) {

                        /*if (!formValue.changed)
                            continue;*/
                        isMedia     = true;
                        /*formValue   = formValue.file;*/
                    }
    
                    if (formValue) { 
                        // let resultMedia = null;

                       /* if (isMedia) { 
                            let formData = new FormData();
    
                            formData.append('file', formValue);
                            formData.append('type', formValue.type);
        
                            resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                        }*/
    
                        if (currentTranslation) {
                            // UPDATE STEP

                            let variables = {
                                "id": currentTranslation.contentDataId, 
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "content": content, 
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.id;
                            }

                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select'){
                                    variables.value = formValue;
                                }
    
                            await this.props.client.mutate({
                                mutation: UPDATE_CONTENT_DATA,
                                variables
                            });
                        } else {
                            // CREATE STEP
                            let ADD_CONTENT_DATA_RESULT = ''
                            let variables = {
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "content": content, 
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.id;
                            }

                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select'){
                                    variables.value = formValue;
                                }
                            ADD_CONTENT_DATA_RESULT = await this.props.client.mutate({
                                mutation: ADD_CONTENT_DATA,
                                variables
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP
    
                        await this.props.client.mutate({
                            mutation: DELETE_CONTENT_DATA,
                            variables: { "id": currentTranslation.contentDataId }
                        });
                    }
                }
            }

            resolve();
        });
    };

    handleMediaPicker=(selected,stateName)=>{

        this.handleInputChange(stateName,selected,null,this.state.currentLang);
    }

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        await this.initContent();

        this.props.snack(ALERT_SUCCESS, 'Contenu modifié !');

        this.handleToggleDrawer('form');
        this.resetState();
        this.props.stopLoading();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    handleSecondFromError = (stateName, error) => {
        let secondErrors = this.state.secondErrors;

        secondErrors[stateName] = error;

        this.setState({ secondErrors })
    }

    hasErrors = (formError) => {
        if (formError === 'firstForm'){
            if (this.state.errors) {
                for (let error in this.state.errors) {
                    if (this.state.errors[error])
                        return true;
                }
            }
        }else{
            if (this.state.secondErrors) {
                for (let error in this.state.secondErrors) {
                    if (this.state.secondErrors[error])
                        return true;
                }
            }
        }
        

        return false;
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors('firstForm')) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }

            this.props.startLoading();

            await this.props.client.mutate({
                mutation: UPDATE_CONTENT,
                variables: { 
                    'id': this.state.contentId,
                    'attributeGroup': this.state.groupAttribut,
                    'contentCategories': this.state.categories.map(e => e.id),
                    'status': this.state.status
                }
            });

            await this.saveAttributes(this.state.contentId);

            this.handleSuccess();
        } catch(e) {
            this.handleError(e);
        }
    };

    render() {
        const {sku, status} = this.state;
        return (
            <>
                <div style={{width: this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                    <Grid container justify={'flex-end'}>
                        <TraductionSelect 
                            currentLang={this.state.currentLang} 
                            handleLang={this.handleLang} 
                            locales={this.props.locales}
                        />
                    </Grid>
                </div>
                {
                    this.state.ready ?
                    <CardCustom style={{width: `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))`, marginTop: 8, marginLeft: 16, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                        <CardContentDetails 
                            sku={sku}
                            content={{status}} 
                            routeContent={() => this.goTo(ROUTE_CONTENU_LIST)} 
                            categories={this.state.categories} 
                            toggleDrawer={hasRights(CONTENTS, CONTENTS_CONTENTS, UPDATE) ? () => this.handleToggleDrawer('form') : null} 
                            openForm={this.state.openForm}
                            imageAttributes={this.state.imageAttributes}
                            customAttributes={this.state.customAttributes}
                            currentLang={this.state.currentLang}
                            allState={this.state}
                            locales={this.props.locales}
                        />
                        
                        <LayoutBuilder 
                            validateButton={true} 
                            handleCancel={this.handleCancel}
                            opened={this.state.openForm} 
                            forClose={() => this.handleToggleDrawer('form')} 
                            handlerMutation={this.handlerMutation} 
                            icomoon={'ico-modifier-produit'}
                            dataLayout={formContentDetail(
                                this.state.currentLang, 
                                this.state.categoriesData, 
                                this.state.customAttributes, 
                                this.state.metaAttributes, 
                                this.state.imageAttributes,
                                this.state.categories, 
                                this.state.allGroups,
                                this.state.errors,
                                this.state.seeErrors,
                                this.handleMediaPicker,
                                this.state.imagesSelected,
                            )} 
                            handleButtonGroupChange={this.handleButtonGroupChange}
                            allState={this.state} 
                            stateCallback={this.handleInputChange} 
                            errorCallback={this.handleFormError}
                            currentLang={this.state.currentLang}
                            handleLang={this.handleLang}
                            drawerWidth={this.props.drawerWidth}
                            deleteMutation={hasRights(CONTENTS, CONTENTS_CONTENTS, DELETE) ? this.handleToggleDialog : null}
                            deleteText={'Supprimer le contenu'}
                            deleteButton={this.state.editForm} 
                        />
                        <Dialog
                            open={this.state.openDialog}
                            onClose={this.handleToggleDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Êtes-vous sûr de vouloir supprimer ce contenu ?</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Si vous supprimez ce contenu celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                                    Annuler
                                </Button>
                                <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                                    Supprimer
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </CardCustom>
                    : <PageLoader />
                }
            </>
        );
    }
    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);
    }

    prepareAttributes() {
        return new Promise(async (resolve, reject) => {
            const GET_ATTRIBUTES_BY_GROUP_RESULT = await this.props.client.query({
                query: GET_ATTRIBUTES_BY_GROUPE,
                variables: {id : this.state.groupAttribut},
                fetchPolicy: "no-cache"
            });
            let isSystemAttributes  = this.props.attributes.content.attributes.edges.filter(e => e.node.isSystem);
            let metaAttributes      = this.props.attributes.content.attributes.edges.filter(e => e.node.isSystem && e.node.identifier.indexOf('meta') > -1);
            let customAttributes    = GET_ATTRIBUTES_BY_GROUP_RESULT.data.attributeGroup.attributes.edges.filter(e => !e.node.isSystem);
            let imageAttributes     = isSystemAttributes.filter(e => e.node.attributeType.input === 'image')
                                        .concat(customAttributes.filter(e => e.node.attributeType.input === 'image'));
            this.setState({
                isSystemAttributes,
                metaAttributes,
                customAttributes,
                imageAttributes,
                maxImageNumber: imageAttributes.length,
            }); 

            resolve();
        });
    }

    prepareAttributesLocales(result) {
        return new Promise((resolve, reject) => {
            let attributes = [];

            for (let { node } of result.data.content.contentDatas.edges) {
                let found = false;
                
                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;
    
                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            contentDataId: node.id,
                            type: node.attribute.attributeType.input
                        });
                    }
                }
    
                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        isSystem: node.attribute.isSystem,
                        attributeOptions: node.attributeOptions,
                        translationLabel: node.attribute.translation.translationDatas.edges,
                        locales: [{
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            contentDataId: node.id,
                            type: node.attribute.attributeType.input
                        }]
                    });
                }
            }
    
            this.setState({ attributes }, resolve);
        });
    }

    prepareAttributeValues() {
        for (let locale of this.props.locales) {
            let values = {};

            for (let attribute of this.state.attributes) {
                let attributeLocale = attribute.locales.find(e => e.code === locale.node.code);
                let defaultLocale   = attribute.locales.find(e => e.code === this.state.currentLang);

                if (!attributeLocale) {
                    if (attribute.attributeType.input === 'select') {
                        values[attribute.identifier] = defaultLocale?.value ?? attribute.locales[0] ;  
                    }
                } else {
                    switch(attribute.attributeType.input) {
                        case 'file':
                        case 'image':
                            if (attributeLocale.media){
                                values[attribute.identifier] = {
                                    data: attributeLocale.media ? attributeLocale.media.filePath : null,
                                    file: null,
                                    changed: false
                                };
                            }
                            break;
                        default:
                            values[attribute.identifier] = attributeLocale.value;  
                    }
                }
            }

            this.setState({
                [locale.node.code]: values
            });
        }
    }

    initContent() {
        this.props.client.query({
            query: GET_CONTENT_DATA,
            fetchPolicy: "no-cache",
            variables : { id: this.state.contentId }
        }).then(GET_CONTENT_DATA_RESULT => {
            this.props.client.query({
                query: GET_ATTR_GROUPE_WITH_ATTRIBUTES,
                fetchPolicy: "no-cache",
                variables : { id: this.state.contentId }
            }).then(GET_ATTR_GROUPE_WITH_ATTRIBUTES_RESULT => {
                let filterCategoriesCatalog = GET_CONTENT_DATA_RESULT.data.content.contentCategories.filter(e => e.catalog === null)
                let getImageContent = GET_CONTENT_DATA_RESULT.data.content.contentDatas.edges.find(e => e.node.attribute.identifier === "content_image")

                this.setState({
                    sku: GET_CONTENT_DATA_RESULT.data.content.sku,
                    groupAttribut : GET_CONTENT_DATA_RESULT.data.content.attributeGroup.id,
                    categories: filterCategoriesCatalog, // content categories
                    allGroups: GET_ATTR_GROUPE_WITH_ATTRIBUTES_RESULT.data.attributeGroups.edges.filter(e => (!e.node.isSystem && e.node.isForContent) || e.node.identifier === 'content'),
                    attributes : [],
                    status: GET_CONTENT_DATA_RESULT.data.content.status,
                    defaultImageContent: getImageContent?.node.media

                }, async () => {
                    this.prepareAttributes();
                    await this.prepareAttributesLocales(GET_CONTENT_DATA_RESULT);
                    this.prepareAttributeValues();
    

                    const GET_CONTENT_CATEGORIES_RESULT = await this.props.client.query({
                        query: GET_CONTENT_CATEGORIES,
                        fetchPolicy: "no-cache"
                    });
                    this.setState({ categoriesData: GET_CONTENT_CATEGORIES_RESULT.data.contentCategories, ready: true }); // all categories
                });
            });
        })
        
    }

    goTo = route => this.props.history.push(route);
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes, 
    locales: state.locales,
  };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ContentDetail)));
