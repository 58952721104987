import React from 'react';
import { Grid, Box, Typography, InputLabel, DialogContentText } from '@material-ui/core';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../../js/constants/alert-types';
import { eventService } from '../../../../../js/services/event.service';

import _ from 'lodash';
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import emptyCatalog from '../../../../../assets/images/emptyCatalog.jpg';

import { ROUTE_RESTAURANTS } from '../../../../../js/constants/route-names';
import { hasRights } from '../../../../../js/utils/rights';
import { PRODUCTS, PRODUCTS_PRODUCTS, VIEW, UPDATE, DELETE } from '../../../../../js/constants/constant-rights';
import { GET_ATTR_GROUPE_LIGHT, GET_ATTRIBUTES_BY_GROUPE } from '../../../../../queries/attributes';
import { DELETE_RESTAURANT, GETBY_ID_RESTAURANT } from '../../../../../queries/restaurants';
import { CREATE_RESTAURANTDATA, UPDATE_RESTAURANTDATA, DELETE_RESTAURANTDATA } from '../../../../../queries/restaurantdatas';
import { GET_USERRESTAURANTS_EMAILS, CREATE_USERRESTAURANT, UPDATE_USERRESTAURANT, DELETE_USERRESTAURANT } from '../../../../../queries/userrestaurants';
import { ALLOWED } from '../../../../../js/constants/medias-types';

import restaurantEdit from './config/restaurantEdit.config';
import addUserRestaurant from './config/addUserRestaurant.config';
import editUserForm from './config/editUserRestaurant.config';

import CardCustom from '../../../../layouts/Card/CardCustom';
import CardUsers from '../../../../layouts/Card/cardContent/CardUsers';
import LayoutBuilder from '../../../../ui/form/LayoutFormBuilder';
import DialogModal from '../../../../ui/dialog/DialogModal';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import Button from '../../../../ui/button/Button';
import InputBuilder from '../../../../ui/form/InputBuilder';

import * as generator from 'generate-password';

const BoxCustom = styled(Box)`
    width: auto;
`;
const GridCustom = styled(Grid)`
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;
const BoxCustomAttributs = styled(Box)`
    background: ${colors.grey.lighter.hue980};
    margin-bottom: 32px;
`;
const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.imagechanged ? props => props.backgroundimage : props.backgroundimage});
    background-size: cover;
    background-position: center;
`;
const LineColor = styled(Box)`
    background: ${props => props.typecatalog === 'permanent' ? colors.blue.darker.hue300 : props.typecatalog === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    height: 20px;
    padding-left: 16px;
    display: flex;
    align-items: center;
`;
const CatalogNumbers = styled(Typography)`
    color: ${props => props.typecatalog === 'permanent' ? colors.blue.darker.hue300 : props.typecatalog === 'ephemeral' ? colors.purple.regular : colors.pink.regular};
    font-weight: bold;
`;
const CatalogNbType = styled(Typography)`
    margin-top: -0.5rem;
    font-weight: bold;
    color: ${colors.blue.regular};
`;
const Subtitle = styled(Typography)`
    color: ${colors.blue.regular};
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 10px;
`;
const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
    font-weight: bold;
`;
const InputLabelCustom = styled(InputLabel)`
    color: ${colors.blue.regular};
    margin-top: ${props => props.margintop || 0};
    margin-bottom: 24px;
`;
const GridFlexCenter = styled(Grid)`
    display: flex;
    align-items: center;
`;

const emailPopup = {
    type: 'text',
    label: 'Email',
    helper: 'Langue',
    disabled: 'disabled',
    stateName: 'email',
    value: ''
};
const passwordPopup = {
    type: 'password',
    label: 'Mot de passe',
    helper: 'Langue',
    disabled: 'disabled',
    stateName: 'password',
    value: ''
};


class RestaurantDetail extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            restaurantId: this.props.history.location.state?.restaurantId || `/api/restaurants/${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}` || null,
            categories: [],
            categoriesData: [],
            ready: false,
            description:'',
            status:'',
            openForm: false,
            openFormUser: false,
            openDialog: false,
            openDialogPassword: false,
            allGroups: [],
            errors: {},
            secondErrors: {},
            seeErrors: false,
            restaurantEdit: restaurantEdit,
            openDialogPassword: false,
            email: '', 
            plainPassword: '',
            plainPasswordRepeat: '',
            lastname: '', 
            firstname: '', 
            showPassword: false,
            inputHidden: [],
            editPassword: true,
            listEmail: [],
            currentEmail: '',
            userDefault: false,
        }
    }

    handleToggleDrawer = (drawer) => {
        this.setState({ 
            [drawer] : !this.state[drawer],
        }, () => {
            if(this.state.userRestaurant.edges.length > 0 && drawer === 'openFormUser' && this.state[drawer]){
                this.setState({editPassword: false})
            }
            if(!this.state[drawer]){
                this.handleGetRestaurantById();
            }
        });
    };

    handleToggleDialog = (name) => {
        this.setState({ 
            [name] : !this.state[name]
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } 
        else {
            this.setState({
                [stateName]: value,
            });
        }
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });

        if (stateName === 'generate') {
            this.handleFormError('plainPassword', false);
            this.handleFormError('plainPasswordRepeat', false);
        }
    };

    handleInputChange = (stateName, evt, custom, translated ) => {
        let value = null
        if(custom && !evt){
            value = custom;
        }
        else{
            value = evt?.target?.value ?? evt;
        }
        this.setValue(stateName, value, translated);
    };

    deleteMutation = () => {
        let variables = { id: this.state.restaurantId };

        this.props.client.mutate({
            mutation: DELETE_RESTAURANT,
            variables,
        }).then(result =>{
            this.goTo(ROUTE_RESTAURANTS);
        })
    };

    deleteUserMutation = () => {
        this.props.startLoading();
        let variables = { id: this.state.userRestaurant.edges[0].node.id };
        this.props.client.mutate({
            mutation: DELETE_USERRESTAURANT,
            variables,
        }).then(result =>{
            this.handleToggleDialog('openDialogDeleteUser');
            this.props.snack(ALERT_SUCCESS, `L'utilisateur a été supprimé`);
            this.handleToggleDrawer('openFormUser');
        })
    };

    getAttributes = () => {
        this.props.client.query({
            query: GET_ATTR_GROUPE_LIGHT,
            fetchPolicy: 'no-cache'
        }).then( async result =>{
            let idGroup = result.data.attributeGroups.edges.find(item => item.node.identifier === "restaurant")?.node.id || null;
            if(idGroup){
                this.setState({
                    idGroup
                }, () =>{
                    this.props.client.query({
                        query: GET_ATTRIBUTES_BY_GROUPE,
                        fetchPolicy: 'no-cache',
                        variables: {id: this.state.idGroup}
                    }).then( async result =>{
                        let restaurantEdit = this.state.restaurantEdit;
                        restaurantEdit.formConfig.children[0].optionsInputs = [];

                        let switchUserInput = {
                            type: 'switch',
                            label: 'Utilisateurs par défaut',
                            helper: {
                                label: 'Utilisateurs par défaut',
                                text: 'Choississez d\'utiliser un utilisateur par défaut',
                                link: false,
                            },
                            required: true,
                            stateName: 'userDefault',
                            value: [
                                {
                                    value: true,
                                    label: 'Oui',
                                },
                                {
                                    value: false,
                                    label: 'Non',
                                },
                            ]
                        };



                        this.setState({
                            listAttributes: result.data.attributeGroup.attributes.edges
                        }, () => {
                            let restaurantEdit = this.state.restaurantEdit;
                            restaurantEdit.formConfig.children[0].optionsInputs.push(switchUserInput);
                            this.setState({restaurantEdit}, () => {
                                for(let attribute of this.state.listAttributes){
                                    if(attribute.node.status && attribute.node.identifier !== 'restaurant_identifier'){
                                        let isEmail = false;
                                        const defaultLang = attribute.node.translation.translationDatas.edges[0];
                                
                                        const langSelected = attribute.node.translation.translationDatas.edges.find(
                                            lang => lang.node.locale.code === this.state.currentLang
                                        );
                                        let typeInput = '';
                                
                                        switch(attribute.node.attributeType.input) {
                                            case 'textarea':
                                                typeInput = 'textarea';
                                                break;
                                            case 'number': 
                                            case 'decimal': 
                                                typeInput = 'decimal';
                                                break;
                                            case 'text':
                                                typeInput = 'text';
                                                break;
                                            case 'mail':
                                                typeInput = 'text';
                                                isEmail = true;
                                                break;
                                            case 'link':
                                            case 'input':
                                                typeInput = 'text';
                                                break;
                                            case 'select':
                                                typeInput = 'select';
                                                break;
                                            case 'image':
                                                typeInput = 'mediaPicker';
                                                break;
                                            case 'file':
                                                typeInput = 'file';
                                                break;
                                            case 'date':
                                                typeInput = 'date';
                                                break;
                                            default: typeInput = null;
                                        };
                                        let input = {
                                            type: typeInput,
                                            label: langSelected?.node.value && defaultLang.node.value ? defaultLang.node.value : attribute.node.identifier,
                                            handleMediaPicker:typeInput === 'mediaPicker' || typeInput === 'file' ? this.handleMediaPicker : null,
                                            allowedTypes: typeInput === 'file' ? ALLOWED  : null,
                                            translated: false,
                                            helper: {
                                                text: 'Helper non disponible pour le moment',
                                                link: false,
                                            },
                                            isSystem: false,
                                            currentLang: this.state.currentLang,
                                            required: attribute.node.isRequired,
                                            stateName: attribute.node.identifier,
                                            email: isEmail,
                                            value: attribute.node.attributeOptions.edges.map((values) => {
                                                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];
                                
                                                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                                                    lang => lang.node.locale.code === this.state.currentLang
                                                );
                                
                                                return ({
                                                    value: values.node.id,
                                                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node?.value
                                                });
                                            })
                                        };
                                        let restaurantEdit = this.state.restaurantEdit;
                                        restaurantEdit.formConfig.children[0].optionsInputs.push(input);
                                        this.setState({restaurantEdit});
                                    }
                                };    
                            });
                        })
                    })
                })
            }
        });
        
    };

    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,null,selected,null);  
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = (formError) => {
        if (formError === 'firstForm'){
            if (this.state.errors) {
                for (let error in this.state.errors) {
                    if (this.state.errors[error])
                        return true;
                }
            }
        }else{
            if (this.state.secondErrors) {
                for (let error in this.state.secondErrors) {
                    if (this.state.secondErrors[error])
                        return true;
                }
            }
        }
        

        return false;
    };

    handlerMutation = async () => {
        this.props.startLoading();
        if (this.hasErrors('firstForm')) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            this.props.stopLoading();
            return eventService.fire();
        }
        

        let i = 0;
        for(let attribute of this.state.listAttributes){
            i++;
            if(attribute.node.status){
                let typeInput = '';
                let isEmail = false;
                switch(attribute.node.attributeType.input) {
                    case 'textarea':
                        typeInput = 'textarea';
                        break;
                    case 'number': 
                    case 'decimal': 
                        typeInput = 'decimal';
                        break;
                    case 'text':
                        typeInput = 'text';
                        break;
                    case 'mail':
                        typeInput = 'text';
                        isEmail = true;
                        break;
                    case 'link':
                        typeInput = 'text';
                        break;
                    case 'select':
                        typeInput = 'select';
                        break;
                    case 'image':
                        typeInput = 'mediaPicker';
                        break;
                    case 'file':
                        typeInput = 'file';
                        break;
                    case 'date':
                        typeInput = 'date';
                        break;
                    default: typeInput = null;
                };
                let check = false;
                let restaurantIdData = null;
                for(let data of this.state.restaurant.restaurantData.edges){
                    if(data.node.attribute.id === attribute.node.id){
                        check = true;
                        restaurantIdData = data.node.id;
                    }
                }
                if(check){
                    let variables = {
                        id: restaurantIdData,
                        restaurant: this.state.restaurant.id,
                        attribute: attribute.node.id,
                    }
                    if(typeInput === 'mediaPicker'){
                        variables.media = this.state[attribute.node.identifier]?.id || null;
                    }
                    else if(typeInput === 'select'){
                        variables.attributeOption = this.state[attribute.node.identifier];
                    }
                    else{
                        variables.value = this.state[attribute.node.identifier];
                    }
                    if(variables.value || variables.attributeOption || variables.media){
                        this.props.client.mutate({
                            mutation: UPDATE_RESTAURANTDATA,
                            variables
                        }).then(result => {
                            if(i === this.state.listAttributes.length){
                                this.setState({openForm: false});
                                this.handleGetRestaurantById();
                            }
                        })
                    }
                    else{
                        this.props.client.mutate({
                            mutation: DELETE_RESTAURANTDATA,
                            variables: {"id": variables.id}
                        }).then(result => {
                            this.setState({[attribute.node.identifier]: null})
                        })
                    }
                    if(i === this.state.listAttributes.length){
                        this.setState({openForm: false});
                        this.handleGetRestaurantById();
                    }
                }else{
                    let variables = {
                        restaurant: this.state.restaurant.id,
                        attribute: attribute.node.id,
                    }
                    if(typeInput === 'mediaPicker'){
                        variables.media = this.state[attribute.node.identifier]?.id || null;
                    }
                    else if(typeInput === 'select'){
                        variables.attributeOption = this.state[attribute.node.identifier];
                    }
                    else{
                        variables.value = this.state[attribute.node.identifier];
                    }
                    if(variables.value || variables.attributeOption || variables.media){
                        this.props.client.mutate({
                            mutation: CREATE_RESTAURANTDATA,
                            variables
                        }).then(result => {
                            if(i === this.state.listAttributes.length){
                                this.setState({openForm: false});
                                this.handleGetRestaurantById();
                            }
                        })
                    }
                    else if(i === this.state.listAttributes.length){
                        this.setState({openForm: false});
                        this.handleGetRestaurantById();
                    }
                }
            }
        }
    };

    handlerMutationUser = () => {
        this.props.startLoading();
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.props.stopLoading();
            return eventService.fire();
        }
        for(let user of this.state.listEmail){
            if(this.state.email !== this.state.currentEmail){
                if(user.node.email === this.state.email){
                    this.props.stopLoading();
                    this.props.snack(ALERT_ERROR, 'Cet email est déjà utilisé est donc pas valide.');
                    return eventService.fire({stateName: 'email', errorMessage: "Cet email est déjà utilisé est donc pas valide."});
                }
            }
        }
        if (this.state.plainPassword !== this.state.plainPasswordRepeat) {
            this.props.stopLoading();
            return this.props.snack(ALERT_ERROR, 'Les mots de passe ne sont pas identiques');
        }
        if(this.state.userRestaurant.edges.length > 0){
            let variables = {
                id: this.state.userRestaurant.edges[0].node.id,
                email: this.state.email,
                name: this.state.name,
                firstname: this.state.firstname,
            };
            if(this.state.editPassword){
                variables.password = this.state.plainPassword;
            }
            this.props.client.mutate({
                mutation: UPDATE_USERRESTAURANT,
                variables
            }).then(result => {
                this.handleToggleDrawer('openFormUser');
                if(this.state.editPassword){
                    this.handleToggleDialog('openDialogPassword');
                }
            })
        }else{
            let variables = {
                email: this.state.email,
                password: this.state.plainPassword,
                restaurant: this.state.restaurantId,
                name: this.state.name,
                firstname: this.state.firstname,
                state: "available",
                roles: [],
            };

            this.props.client.mutate({
                mutation: CREATE_USERRESTAURANT,
                variables
            }).then(result => {
                this.handleToggleDrawer('openFormUser');
                this.handleToggleDialog('openDialogPassword');
            })
        }
    }

    getNumberTable = (rooms) => {
        let number = 0;
        for(let room of rooms){
            number += room.node.roomTables.edges.length;
        }
        return number;
    }
    
    handleGetRestaurantById = () => {
        return new Promise((resolve, reject) => {
            let variables = {id: this.state.restaurantId}
            this.props.client.query({
                query: GETBY_ID_RESTAURANT,
                fetchPolicy: 'no-cache',
                variables,
            }).then( result =>{
                this.setState({
                    userRestaurant: result.data.restaurant.userRestaurants,
                    restaurant: result.data.restaurant,
                    numberTable: this.getNumberTable(result.data.restaurant.rooms.edges),
                },()=>{
                    let user = this.state.userRestaurant.edges[0]?.node
                    if(user){
                        this.setState({name: user.name, firstname: user.firstname, email: user.email, currentEmail: user.email})
                    }
                    for(let data of this.state.restaurant.restaurantData.edges){
                        let typeInput = null;
                        let isEmail = false;
                        switch(data.node.attribute.attributeType.input) {
                            case 'textarea':
                                typeInput = 'textarea';
                                break;
                            case 'number': 
                            case 'decimal': 
                                typeInput = 'decimal';
                                break;
                            case 'text':
                                typeInput = 'text';
                                break;
                            case 'mail':
                                typeInput = 'text';
                                isEmail = true;
                                break;
                            case 'link':
                                typeInput = 'text';
                                break;
                            case 'select':
                                typeInput = 'select';
                                break;
                            case 'image':
                                typeInput = 'mediaPicker';
                                break;
                            case 'file':
                                typeInput = 'file';
                                break;
                            case 'date':
                                typeInput = 'date';
                                break;
                            default: typeInput = null;
                        };
                        let value = null; 
                        if(typeInput === 'select'){
                            value = data.node.attributeOption?.id;
                        }
                        else if(typeInput === 'mediaPicker'){
                            value = data.node.media;
                        }
                        else{
                            value = data.node.value;
                        }
                        this.setState({
                            [data.node.attribute.identifier]: value
                        });
                    }
                    this.props.stopLoading();
                    resolve();
                });
            });
        });
    }

    handleGetAllUsersRestaurants = () => {
        this.props.client.query({
            query: GET_USERRESTAURANTS_EMAILS,
            fetchPolicy: 'no-cache',
        }).then( result =>{
            this.setState({listEmail: result.data.userRestaurants.edges});
        });
    }

    async getAll() {
        await this.handleGetRestaurantById();
        await this.handleGetAllUsersRestaurants();
        this.setState({ ready: true });
    }
    
    componentDidMount() {
        this.getAll();
        this.getAttributes();
    }

    componentDidUpdate(prevProps, prevState){
        if(this.state.generate !== prevState.generate){
            if(this.state.generate){
                var password = generator.generate({
                    length: 12,
                    numbers: true,
                    symbols: true,
                    lowercase: true,
                    uppercase: true,
                    excludeSimilarCharacters: true,
                    exclude: '"\'{}[]()=:;.,_+-?/<>~',
                    strict: true
                });
                this.setState({
                    plainPassword: password,
                    plainPasswordRepeat: password,
                })
            }
            else{
                this.setState({
                    plainPassword: '',
                    plainPasswordRepeat: ''
                })
            }
            if(this.state.generate){
                this.state.inputHidden.push('plainPassword', 'plainPasswordRepeat')
            }else{
                for( var i = 0; i < this.state.inputHidden.length; i++){ 
                    if ( this.state.inputHidden[i] === 'plainPassword') { 
                        this.state.inputHidden.splice(i, 1);
                    }
                    if (this.state.inputHidden[i] === 'plainPasswordRepeat'){
                        this.state.inputHidden.splice(i, 1);
                    }
                }
            }
        }
        if(this.state.editPassword !== prevState.editPassword){
            if(this.state.editPassword){
                this.setState({
                    inputHidden: []
                })
            }
            else{
                this.setState({
                    plainPassword: '',
                    plainPasswordRepeat: '',
                    inputHidden: ['plainPassword', 'plainPasswordRepeat', 'generate']
                });
            }
        }
        if(this.state.email !== prevState.email){
            for(let user of this.state.listEmail){
                if(this.state.email !== this.state.currentEmail){
                    if(user.node.email === this.state.email){
                        setTimeout(() => {
                            this.handleFormError('email', true);
                            eventService.fire({stateName: 'email', errorMessage: "Cet email est déjà utilisé est donc pas valide."});
                        }, 100);
                    }
                }
            }
        }     
    };

    render() {
        let getImageBackground = this.state.restaurant?.restaurantData.edges.find(item => item.node.attribute.identifier === 'restaurant_logo')?.node.media
        return (
            <>
                <Grid container direction="column" justify="center" style={{marginBottom: 32}}>
                    <GridCustom item lg={12} md={12} xs={12} >
                        <BoxCustom onClick={() => this.goTo(ROUTE_RESTAURANTS)} style={{marginBottom: 16}}>
                            <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                        </BoxCustom>

                        <CardCustom paddingbottom={0} paddingtop={0} style={{width: (this.state.openForm || this.state.openFormUser) && this.props.windowWidth > 1500 ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "50%",  transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', overflow: 'inherit'}} contentpadding={"0 0 0 0"}>
                            <Box>
                                <ContainerImage 
                                    height={245}
                                    backgroundimage={this.state.restaurant_logo?.filePath ? `${process.env.REACT_APP_MEDIAS}/${this.state.restaurant_logo.filePath}` : emptyCatalog} 
                                />
                                <LineColor height={16} ></LineColor>

                                <Box padding={'1rem 1.5rem 1rem 1.5rem'} style={{borderBottom: '1px solid rgb(212, 217, 220)'}}>
                                    <Typography variant="h4" component="div">
                                        <Box fontWeight="bold">
                                            {this.state.restaurant?.restaurantData.edges.find(item => item.node.attribute.identifier === 'restaurant_name')?.node.value}
                                        </Box>
                                    </Typography>

                                    <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: this.state.restaurant?.restaurantData.edges.find(item => item.node.attribute.identifier === 'restaurant_phone')?.node.value }}/>
                                    
                                    <Grid
                                        container 
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        style={{paddingBottom: 24}}
                                    >
                                        <Grid item xs={6}>
                                            <Grid container>
                                                <Grid item>
                                                    <CatalogNumbers variant="h1" >
                                                        {this.state.restaurant?.rooms.edges.length}
                                                    </CatalogNumbers>
                                                    <CatalogNbType variant="h4">
                                                        {this.state.restaurant?.rooms.edges.length <= 1 ? 'salle' : 'salles'}
                                                    </CatalogNbType>
                                                </Grid>
                                                <Grid item style={{marginLeft: 32}}>
                                                    <CatalogNumbers variant="h1">
                                                        {this.state.numberTable}
                                                    </CatalogNumbers>
                                                    <CatalogNbType variant="h4">
                                                        {this.state.numberTable <= 1 ? 'table' : 'tables'}
                                                    </CatalogNbType>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>
                                    {
                                        this.state.ready ? (
                                            <>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={6} style={{display: 'flex', alignItems: 'flex-end'}}>
                                                            <Subtitle>Attributs</Subtitle>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Grid container justify={'flex-end'}>
                                                                <Box>
                                                                    <Button text="Modifier le restaurant" disabled={this.state.ready ? (this.state.openForm ? this.state.openForm : false) : true} bgColor={colors.green.regular} onClick={() => this.handleToggleDrawer('openForm')} arrow={'right'} shadowcolor={colors.green.darker}/>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <BoxCustomAttributs container direction="row">
                                                        {
                                                            this.state.listAttributes ? (
                                                                this.state.listAttributes.map((attribute) => (
                                                                    attribute.node.status ? (
                                                                        <div style={{display: 'flex', flexDirection: 'row', padding: '4px 16px', borderBottom: '1px solid white'}}>
                                                                            {
                                                                                attribute.node.attributeType.input === 'image' ? (
                                                                                    <>
                                                                                        <p>{attribute.node.translation.translationDatas.edges[0].node.value} - {!this.state[attribute.node.identifier]?.filePath ? (<strong style={{maxWidth: '50%', paddingLeft: 8}}>Aucune image</strong>) : null}</p>
                                                                                        {this.state[attribute.node.identifier]?.filePath ? (<img style={{maxWidth: '50%', paddingLeft: 8}} src={`${process.env.REACT_APP_MEDIAS}/${this.state[attribute.node.identifier]?.filePath}`} />) : null}
                                                                                    </>
                                                                                ) : attribute.node.attributeType.input === 'select' ? (
                                                                                    <p>{attribute.node.translation.translationDatas.edges[0].node.value} - <strong>{attribute.node.attributeOptions.edges.find(item => item.node.id === this.state[attribute.node.identifier])?.node.translation.translationDatas.edges[0].node.value ? attribute.node.attributeOptions.edges.find(item => item.node.id === this.state[attribute.node.identifier])?.node.translation.translationDatas.edges[0].node.value : 'Aucune valeur' }</strong></p>
                                                                                ) : attribute.node.attributeType.input === 'textarea' ? 
                                                                                    <p>{attribute.node.translation.translationDatas.edges[0].node.value} - <strong><Typography variant="p" dangerouslySetInnerHTML={{ __html: attribute.node.translation.translationDatas.edges[0].node.value}} /></strong></p>
                                                                                    : (<p>{attribute.node.translation.translationDatas.edges[0].node.value} - <strong>{this.state[attribute.node.identifier] ? this.state[attribute.node.identifier] : 'Aucune valeur'}</strong></p>)
                                                                            }
                                                                        </div>
                                                                    ) : null
                                                                ))
                                                            ) : null
                                                        }
                                                    </BoxCustomAttributs>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={6} style={{display: 'flex', alignItems: 'flex-end'}}>
                                                            <Subtitle>Utilisateur</Subtitle>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Grid container justify={'flex-end'}>
                                                                <Box>
                                                                    <Button text={this.state.userRestaurant?.edges.length > 0 ? "Modifier l'utilisateur" : "Ajouter un utilisateur"} disabled={this.state.ready ? (this.state.openFormUser ? this.state.openFormUser : false) : true} bgColor={colors.green.regular} onClick={() => this.handleToggleDrawer('openFormUser')} arrow={'right'} shadowcolor={colors.green.darker}/>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {
                                                        this.state.userRestaurant?.edges.length > 0 ? (
                                                            <CardCustom paddingbottom="0px" style={{width: '100%'}}>
                                                                <CardUsers userProperty={this.state.userRestaurant?.edges[0].node} noAction={true} />
                                                            </CardCustom>
                                                        ) : (<p>Aucun utilisateur associé à ce restaurant pour le moment.</p>)
                                                    }
                                                </Grid>
                                            </> 
                                        ) : <PageLoader />
                                    }
                                </Box>
                            </Box>
                        </CardCustom>
                    </GridCustom>   
                </Grid>
                <LayoutBuilder 
                    validateButton={true} 
                    opened={this.state.openForm} 
                    forClose={() => this.handleToggleDrawer('openForm')}
                    handlerMutation={this.handlerMutation} 
                    icomoon={'picto-retailer'}
                    dataLayout={this.state.restaurantEdit}
                    allState={this.state} 
                    stateCallback={this.handleInputChange} 
                    errorCallback={this.handleFormError}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                    drawerWidth={this.props.drawerWidth}
                    handleButtonGroupChange={this.handleButtonGroupChange}
                    deleteMutation={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, DELETE) ? () => this.handleToggleDialog('openDialog') : null}
                    deleteText={'Supprimer le restaurant'}
                    deleteButton={this.state.editForm} 
                />
                {

                }
                <LayoutBuilder 
                    validateButton={true} 
                    opened={this.state.openFormUser} 
                    forClose={() => this.handleToggleDrawer('openFormUser')}
                    handlerMutation={this.handlerMutationUser} 
                    icomoon={'picto-retailer'}
                    dataLayout={this.state.userRestaurant?.edges.length > 0 ? editUserForm : addUserRestaurant}
                    allState={this.state} 
                    stateCallback={this.handleInputChange} 
                    errorCallback={this.handleFormError}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                    drawerWidth={this.props.drawerWidth}
                    deleteMutation={this.state.userRestaurant?.edges.length > 0 ? () =>  this.handleToggleDialog('openDialogDeleteUser') : null}
                    deleteText={this.state.userRestaurant?.edges.length > 0 ? `Supprimer l'utilisateur` : null}
                    deleteButton={this.state.userRestaurant?.edges.length > 0 ? true : false}
                    handleButtonGroupChange={this.handleButtonGroupChange}
                    hideInput={this.state.inputHidden}
                />

                <DialogModal 
                    open={this.state.openDialog} 
                    title={`Êtes-vous sûr de vouloir supprimer ce restaurant ?`}
                    secondaryAction={() => this.handleToggleDialog("openDialog")} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                    primaryAction={this.deleteMutation} primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
                >
                    Si vous supprimez ce restaurant celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                </DialogModal>
                
                <DialogModal 
                    open={this.state.openDialogDeleteUser} 
                    title={`Êtes-vous sûr de vouloir supprimer l'utilisateur ?`}
                    secondaryAction={() => this.handleToggleDialog('openDialogDeleteUser')} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                    primaryAction={this.deleteUserMutation} primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
                >
                    Si vous supprimez cet utilisateur celui-ci n'aura plus accès au restaurant. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                </DialogModal>

                <DialogModal
                    open={this.state.openDialogPassword}
                    title={this.state.editForm === 'add' ? ('Nouveau compte ajouté !') : ('Compte modifié !')}
                    primaryAction={() => this.handleToggleDialog('openDialogPassword')} primarybgcolor={colors.green.regular} primarybgcolorhover={colors.green.darker} primaryText="Ok"
                >
                    <DialogContentText id="alert-dialog-description">
                            <SpanColor>{this.state.firstname} {this.state.name}</SpanColor> {this.state.editForm === 'add' ? ('peut désormais accéder au restaurant.') : ('a désormais des nouveaux identifiants restaurant.')}
                    </DialogContentText>
                    <DialogContentText style={{paddingTop: 10}}>
                        <Grid container>
                            <GridFlexCenter item xs={4}>
                                <InputLabelCustom>Email</InputLabelCustom>
                            </GridFlexCenter>
                            <InputBuilder input={emailPopup} xs={8} value={this.state.email} />
                        </Grid>
                    </DialogContentText>
                    <DialogContentText style={{marginBottom: 0}}>
                        <Grid container>
                            <GridFlexCenter item xs={4}>
                                <InputLabelCustom>Mot de passe</InputLabelCustom>
                            </GridFlexCenter>
                            <InputBuilder input={passwordPopup} xs={8} value={this.state.plainPassword} />
                        </Grid>
                    </DialogContentText>
                </DialogModal>
            </>
        );
    }

    goTo = route => this.props.history.push(route);
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(RestaurantDetail)));
