import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import {useState} from 'react'
const CellHead = styled(TableCell)`
    cursor : ${props => props.filter ? 'pointer': 'normal'};
    width: ${props => props.width ? props.width : 'auto'};
    
`

const LabelContainer = styled.div`
    display:flex;
    align-items:center;
`


function HeadingCell(props){
    let currentSort = props.sortBy.find(e=>e.filter == props.filter);
    const [orderBy,setOrderBy] = useState(currentSort ? currentSort.orderBy : null);

    const handleSort = (orderBy,filter) =>{
        let newOrderBy = orderBy == null ? 'asc' : orderBy == 'asc' ? 'desc' : null;
        setOrderBy(newOrderBy);
        props.sortCallback({filter : filter, orderBy : newOrderBy})
    }
    return(
        <CellHead {...props} onClick = {props.filter ? ()=>handleSort(orderBy,props.filter) : ()=>{}}>
            <LabelContainer>
                <span>{props.label}</span>

                {props.filter ? 
                    orderBy == null ?
                        <UnfoldMoreIcon style={{marginLeft:5}}/>
                    :   
                        orderBy == 'asc' ?
                            <ArrowDropDownIcon style={{marginLeft:5}}/>
                        : <ArrowDropUpIcon style={{marginLeft:5}}/>
                    
                :null}                
            </LabelContainer>

        </CellHead>
    )
}
export default HeadingCell;