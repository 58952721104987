import React from 'react';
import {Typography, Grid, Box} from '@material-ui/core';
import Button from '../../ui/button/Button';
import colors from '../../../config/theme/colors';
import SearchBar from '../../ui/search/SearchBar';
import Stats from '../Stats/Stats';
import styled from 'styled-components';
import iconSet from "../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import imgNotFound from '../../../assets/images/not-found.png';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TraductionSelect from '../../layouts/TopPanel/TraductionSelect';

const BoxTitlePicto = styled(Box)`
    background: ${colors.white};
    border-radius: 5px;
    border: 1px solid ${props => props.noresult ? colors.grey.lighter.hue600 : colors.blue.lighter.hue300};
    width: max-content;
`;
const BoxCustom = styled(Box)`
    background: ${colors.white};
`;
const CustomSelect = styled(Select)`
    .MuiSelect-root{
        padding-top: 14px;
        padding-bottom: 13px;
        width: 80px;
    }
`;
const GridCustom = styled(Grid)`
    @media screen and (max-width: 1920px){
        justify-content: ${props => props.needreturn ? 'flex-start !important' : 'flex-end'};
    }
`;
const GridStatus = styled(Grid)`
    @media screen and (max-width: 1200px){
        margin-left: 0 !important;
        width: 100%;
        div{
            width: auto !important;
        }
        .MuiInputBase-root{
            width: 130px !important;
        }
    }
`;

let getImageType = (asset) => {
    const images = require.context('../../../assets/images', true);
    let image;
    try{
        image=images('./' + asset);
    } catch(e){
        image=imgNotFound;
    }
    return image;
}

export default function TopPanel(props) {
    let img = getImageType(props.image);
    const selectStatus = {
        type: 'select',
        label: 'Status',
        helper: 'status',
        required: false,
        stateName: 'searchStatus',
        value: [
            {
                value: 'all',
                label: 'Tout'
            },
            {
                value: 'active',
                label: 'Actif'
            },
            {
                value: 'inactive',
                label: 'Inactif'
            },
        ]
    };
    
    return(
        <BoxCustom pt={props.windowWidth < 1800 ? 3 : 4} pb={props.noResult ? 3 : props.windowWidth < 1600 ? 1 : props.windowWidth < 1800 ? 2 : 3} style={{position: 'relative', borderBottom: props.hasBorder ? `1px solid ${colors.grey.lighter.hue800}` : 'none'}} form={props.form}>
            <Grid container direction="row" justify="center" spacing={0}>
                <Grid container direction="row" spacing={0}>
                    <Grid container direction="row" spacing={0} justify="space-between">
                        {
                            props.stats ? 
                            (
                                <>
                                    <Grid container justify="space-between">
                                        <Grid item lg={5} xs={12}>
                                            <Grid container direction="row">
                                                <Grid item>
                                                    {
                                                        props.icomoon ? (
                                                            <BoxTitlePicto p={1} mr={props.windowWidth < 1400 ? 1 : 2} noresult={props.noResult}>
                                                                <IcomoonReact iconSet={iconSet} color={props.noResult ? colors.grey.lighter.hue600  : props.colorIcomoon ? (props.colorIcomoon === 'none' ? '' : props.colorIcomoon) : null} size={props.windowWidth < 1400 ? 20 : 33} icon={props.icomoon} />
                                                            </BoxTitlePicto>
                                                        ) : ''
                                                    }
                                                    {
                                                        props.picto ? (
                                                            <Box pr={props.windowWidth < 1400 ? 0 : 2}>
                                                                <img src={props.picto}/>
                                                            </Box>
                                                        ) : ''
                                                    }
                                                    {
                                                        props.image ? (
                                                            <Box pr={props.windowWidth < 1400 ? 0 : 2}>
                                                                <img style={{maxWidth: 50}} src={typeof img === "string" ? img : img?.default}/>
                                                            </Box>
                                                        ) : ''
                                                    }
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h3" style={{color: props.noResult ? colors.grey.lighter.hue600  : '', fontWeight: props.strongText ? 'normal' : 'bold', fontSize: props.windowWidth <= 1000 ? 14 : props.windowWidth < 1400 && props.windowWidth > 1000 ? 16 : 18}}>{props.title} {props.strongText ? <strong>{props.strongText}</strong> : null}</Typography>
                                                    <Typography variant="body2" component="div">
                                                        <Box pt={props.windowWidth < 1400 ? 0 : 1}  style={{fontSize: props.windowWidth <= 1000 ? 12 : props.windowWidth < 1400 && props.windowWidth > 1000 ? 13 : 14}}>
                                                            {props.subtitle}
                                                        </Box>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={7} xs={12} style={{display: 'flex', paddingLeft: '8px', alignItems:'center', marginTop: props.windowWidth < 1400 ? 12 : 0, marginBottom: props.windowWidth < 1400 ? 12 : 0}}>
                                            <Grid container direction="row" justify="flex-end">
                                                {props.stats.map((stat, index) =>
                                                    (
                                                        <Box ml={index > 0 ? 4 : 0} style={{display: "inline-block"}} key={`Stat${index}`}>
                                                            <Stats windowWidth={props.windowWidth} stat={stat} />
                                                        </Box>
                                                    )
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <Grid container>
                                    <Grid container direction="row">
                                        <Grid item style={{width: 70}}>
                                            {
                                                props.icomoon ? (
                                                    <BoxTitlePicto p={1} mr={props.windowWidth < 1400 ? 1 : 2} >
                                                        <IcomoonReact iconSet={iconSet} color={props.noResult ? colors.grey.lighter.hue600  : props.colorIcomoon ? (props.colorIcomoon === 'none' ? '' : props.colorIcomoon) : null} size={props.windowWidth < 1400 ? 20 : 33} icon={props.icomoon} />
                                                    </BoxTitlePicto>
                                                ) : ''
                                            }
                                            {
                                                props.picto ? (
                                                    <Box pr={2} >
                                                        <img style={{maxWidth: 50}} src={props.picto}/>
                                                    </Box>
                                                ) : ''
                                            }
                                            {
                                                props.image ? (
                                                    <Box pr={props.windowWidth < 1400 ? 0 : 2}>
                                                        <img style={{maxWidth: 50}} src={typeof img === "string" ? img : img?.default}/>
                                                    </Box>
                                                ) : ''
                                            }
                                        </Grid>
                                        <Grid item style={{width: 'calc(100% - 70px)', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                            <Typography variant="h3" style={{color: props.noResult ? colors.grey.lighter.hue600  : '', fontWeight: props.strongText ? 'normal' : 'bold', fontSize: props.windowWidth <= 1000 ? 14 : props.windowWidth < 1400 && props.windowWidth > 1000 ? 16 : 18}}>{props.title} {props.strongText ? <strong>{props.strongText}</strong> : null}</Typography>
                                            <Typography variant="body2" component="div">
                                                <Box pt={props.windowWidth < 1400 ? 0 : 1} style={{fontSize: props.windowWidth <= 1000 ? 12 : props.windowWidth < 1400 && props.windowWidth > 1000 ? 13 : 14}}>
                                                    {props.subtitle}
                                                </Box>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                    <Grid container justify={(props.textAdd || props.textImport || props.children ) ? 'space-between' : 'flex-end'}>
                    {
                        (props.textAdd || props.textImport || props.children || props.searchHandler || props.searchStatusHandler) ? 
                            (
                                <Grid container style={{marginTop: 12}}>
                                    <Grid container>
                                        {
                                            props.children || props.textAdd || props.textImport || props.searchStatusHandler || props.textThirdButton || props.textFourthButton ?
                                                <Grid item xl={props.secondSearchHandler && props.searchStatusHandler ? 3 : props.searchStatusHandler ? 6 : 9} lg={props.secondSearchHandler && props.searchStatusHandler ? 12 : props.searchStatusHandler ? 6 : 9} md={props.secondSearchHandler && props.searchStatusHandler ? 12 : props.searchStatusHandler ? 6 : 9} sm={props.secondSearchHandler && props.searchStatusHandler ? 12 : props.searchStatusHandler ? 6 : props.secondSearchHandler ? 9 : 6} xs={props.secondSearchHandler && props.searchStatusHandler ? 12 : props.searchStatusHandler ? 6 : props.secondSearchHandler ? 9 : 6}>
                                                    {
                                                        props.textAdd ? 
                                                            ( <Button text={props.textAdd} bgcolor={colors.green.regular}  onClick={props.handlerAdd} shadowcolor={colors.green.darker} disabled={props.buttonAvailable === true ? props.openForm : true}/> ) 
                                                            : ''
                                                    }
                                                    {
                                                        props.textImport ? 
                                                            ( <Button style={{marginLeft: props.textAdd ? '16px' : '0'}} arrow={props.colorImport ? props.colorImport.arrow : null} text={props.textImport} bgcolor={props.colorImport ? props.colorImport.background : colors.green.regular} onClick={props.handlerImport} shadowcolor={props.colorImport ? props.colorImport.shadow : colors.green.darker} disabled={props.buttonAvailable === true ? props.openForm : true}/> ) 
                                                            : ''
                                                    }
                                                    {
                                                        props.textThirdButton ? 
                                                            ( <Button style={{marginLeft: props.textAdd || props.textImport ? '16px' : '0'}} arrow={props.colorThirdButton ? props.colorThirdButton.arrow : null} text={props.textThirdButton} bgcolor={props.colorThirdButton ? props.colorThirdButton.background : colors.green.regular} onClick={props.handlerThirdButton} shadowcolor={props.colorThirdButton ? props.colorThirdButton.shadow : colors.green.regular} disabled={props.buttonAvailable === true ? props.openForm : true}/> ) : ''
                                                    }
                                                    {
                                                        props.children ? props.children : ''
                                                    }
                                                </Grid>
                                            : null
                                        }
                                        <GridCustom item xl={props.secondSearchHandler && props.searchStatusHandler ? 9 : props.searchStatusHandler ? 6 : 3} md={props.secondSearchHandler && props.searchStatusHandler ? 12 : props.searchStatusHandler ? 6 : 3} xs={props.secondSearchHandler && props.searchStatusHandler ? 12 : props.searchStatusHandler ? 6 : props.secondSearchHandler ? 3 : 6} style={{display: 'flex', alignItems:'center', flexWrap: 'wrap', justifyContent: 'flex-end'}} needreturn={props.secondSearchHandler && props.searchStatusHandler ? 1 : 0}>
                                            { 
                                                props.searchHandler ? 
                                                    (
                                                        <Grid item xs={12} style={{marginTop: '10px', marginBottom: '10px', maxWidth: 250}}>
                                                            <SearchBar style={props.searchStyle ? props.searchStyle : null} onChange={props.searchHandler} placeholder={props.searchPlaceholder}/>
                                                        </Grid>
                                                    ) : ''
                                            }
                                            { 
                                                props.secondSearchHandler ? 
                                                    (
                                                        <Grid item xs={12} style={{marginLeft: props.secondSearchHandler ? 16 : 0, marginTop: '10px', marginBottom: '10px', maxWidth: 350}}>
                                                            <SearchBar style={props.searchStyle ? props.searchStyle : null} onChange={props.secondSearchHandler} placeholder={props.secondSearchPlaceholder} id={"second-searchBar"}/>
                                                        </Grid>
                                                    ) : ''
                                            }
                                            {
                                                props.searchStatusHandler ?
                                                    <GridStatus item style={{marginLeft: 16, display: 'flex', alignItems:'center'}}>
                                                        <div style={{marginRight: 16, width: '100%'}}>Recherche par status: </div>
                                                        <CustomSelect
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={props.currentStatus}
                                                            onChange={props.searchStatusHandler}
                                                            variant="outlined"
                                                        >
                                                            {
                                                                selectStatus.value.map((e, index) => {
                                                                    return(
                                                                        <MenuItem value={e.value} key={`SearchStatus${index}`}>{e.label}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </CustomSelect>
                                                    </GridStatus>
                                                : null
                                            }
                                            {
                                                props.handleLang ?
                                                <Grid item xs={12}>
                                                    <TraductionSelect 
                                                        currentLang={props.currentLang} 
                                                        handleLang={props.handleLang} 
                                                        locales={props.locales}
                                                    />
                                                </Grid>
                                                : null
                                            }
                                        </GridCustom>
                                    </Grid>
                                </Grid>
                        ) : ''
                    }
                    </Grid>
                </Grid>
            </Grid>
        </BoxCustom>
    );
}