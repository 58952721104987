import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Grid,Box, Typography} from '@material-ui/core';
import { withApollo } from 'react-apollo';
import Pagination from '@material-ui/lab/Pagination';

import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import emptyPicto from '../../../../assets/pictos/empty-picto/empty_stats.png';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import Stats from '../../../layouts/Stats/Stats'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import FaceIcon from '@material-ui/icons/Face';
import LineCharts from '../../../layouts/Stats/LineCharts';
import CardCustom from '../../../layouts/Card/CardCustom';

import { GET_ASSET_BY_ID, GET_ASSET_WISHLISTS, GET_ASSET_WISHLISTS_CURSORS } from '../../../../queries/assets';
import { GET_PRODUCTS_PAGINATION } from '../../../../queries/products';
import { GET_GAME_PLAYS, GET_WIN_INSTANT_BY_ASSET_CURSOR, GET_WIN_INSTANT_BY_ASSET } from '../../../../queries/asset_games';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import * as moment from 'moment';
import 'moment-timezone';
import { eventService } from '../../../../js/services/event.service';
import styled from 'styled-components';
import ArrayStats from './components/ArrayStats';
import request from '../../../../js/utils/fetch';

import { hasRights } from '../../../../js/utils/rights';
import { BUILDER, BUILDER_ASSETS, VIEW } from '../../../../js/constants/constant-rights';
import { v4 as uuidv4 } from "uuid";

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

const PanelItem = styled.div `
    padding-top:40px
`

const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;


class EngineAssetsDetailsPageStats extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            styleInnerContainer : {
                boxShadow: 'none',
                backgroundInner: colors.white,
                backgroundActions: colors.grey.lighter.hue980,
                padding: '0px',
                height: '630px'
            },
            allPlayers: [],
            allInstants: [],
            openFormEdit : false,
            openFormEditGifts : false,
            openFormFeedAdd:false,
            openDialog : false,
            idAsset: `/api/assets/${this.props.match.params.id}`,
            assetData : null,
            imageAsset : null,
            errors: {},
            seeErrors: false,
            ready: false,
            drawerWidthModified : props.drawerWidth,
            currentLang: props.locales[0].node.code,
            disabledLink    : false,
            toDelete        : null,
            openMediaPicker: false,
            postType: true,
            currentLocale: props.locales[0].node.code,
            tabValue:0,
            stats:[
                {
                    icon: 'picto-profil',
                    dataColor: colors.blue.regular,
                    subtitle: 'Participations',
                    gradientColor1: colors.blue.regular,
                    gradientColor2: colors.blue.lighter,
                    dataSize:'h3',
                    statType : 'gamePlays'
                },
                {
                    icon: 'account', 
                    dataColor: colors.blue.regular,
                    subtitle: 'Participations Uniques',
                    gradientColor1: colors.blue.regular,
                    gradientColor2: colors.blue.lighter,
                    dataSize:'h3',
                    statType : 'uniquePlays'
    
                },
                {
                    icon: 'icon-win',
                    statType : 'gameWins',
                    dataColor: colors.blue.regular,
                    subtitle: 'Lots remportés',
                    gradientColor1: colors.blue.regular,
                    gradientColor2: colors.blue.lighter,
                    dataSize:'h3',

                },
                {
                    icon: 'icon-gift',
                    dataColor: colors.blue.regular,
                    subtitle: 'Lots restants',
                    gradientColor1: colors.blue.regular,
                    gradientColor2: colors.blue.lighter,
                    dataSize:'h3',
                    statType : 'remaingGifts'
                },
                {
                    icon: 'icon-calendrier',
                    dataColor: colors.blue.regular,
                    subtitle: 'Prochain Lot',
                    gradientColor1: colors.blue.regular,
                    gradientColor2: colors.blue.lighter,
                    dataSize:'h3',
                    statType : 'nextGift'
                },
    
            ],
            chartDatas : [],
            dates : [],
            allDatas:[
                    {
                        data: [],
                        color: '#147FAB',
                        title: 'Participant.e.s',
                    },
                    // {
                    //     data: [],
                    //     color: '#E51C52',
                    //     title: 'Participant.e.s uniques',
                    // }
            ],
            playersCount:'',
            nbperpage: 10,
            countPage: 0,
            cursor: false,
            page: 1,
        };

    }

    
    componentDidMount() {
        const getRights = hasRights(BUILDER, BUILDER_ASSETS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.setUpStats();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){

        if (prevState.startDateNotConverted !== this.state.startDateNotConverted) {
            let local = moment.tz.guess();
            let toDateStart = moment.tz(this.state.startDateNotConverted, local).format('L');
            this.setState({
                startDate : toDateStart,
            });
        }

        if(prevState.paramImage !== this.state.paramImage){
            if(this.state.paramImage != null){
                this.setState({
                    disabledLink : true,
                })
            }
            else{
                this.setState({
                    disabledLink : false,
                })
            }
        }
        if(prevState.paramLink !== this.state.paramLink){
            if(this.state.paramLink.length>0){
                this.setState({
                    disabledImage : true,
                })
            }
            else{
                this.setState({
                    disabledImage : false,
                })
            }
        }

        if(prevState.paramTwitterFirstMedia !== this.state.paramTwitterFirstMedia){
            console.log('image twitter',this.state.paramTwitterFirstMedia)
        }
    }

    handleGetAssetDatas = () =>{

        return new Promise(async (resolve, reject) => {
            this.props.client.query({
                query:GET_ASSET_BY_ID,
                variables:{id: this.state.idAsset},
                fetchPolicy:'no-cache'
            }).then(result => {
                this.setState({
                    assetData : result.data.asset
                })
                resolve();
            });
            
        });
    }

    handleGetCursorsInstant = () => {
        let variables = 
            {
                "asset" : this.state.idAsset,
                "isWin": true,
            };
        this.props.client.query({
            query: GET_WIN_INSTANT_BY_ASSET_CURSOR,
            variables,
            fetchPolicy: 'no-cache'
        }).then(result => {
            console.log('handleGetCursorsPlayers', result)
            this.setState({
                listCursors: result.data.assetGameInstants.edges,
            });
        });
    }

    handleGetInstantsPagination = () => {

        return new Promise(async (resolve, reject) => {
            let variables = 
                {
                    "nbperpage": this.state.nbperpage, 
                    "asset" : this.state.idAsset,
                    "isWin": true,
                };
                if (this.state.cursor && this.state.listCursors && this.state.cursor !== this.state.listCursors[0].cursor) {
                    variables.cursor = this.state.cursor;
                }
            this.props.client.query({
                query: GET_WIN_INSTANT_BY_ASSET,
                variables
            }).then(result =>{
                console.log('handleGetInstantsPagination', result)
                this.handleGetCursorsInstant();
                this.setState({
                    ready : true, 
                    countPage: Math.ceil(result.data.assetGameInstants.totalCount / this.state.nbperpage),
                    allInstants: result.data.assetGameInstants.edges,
                    // gamePlayersCount : result.data.assetGameInstants.totalCount
                });
                this.props.stopLoading();
            });
            resolve();
        });
    }

    handleGetPlayersApi = () => {
        return new Promise(async (resolve, reject) => {
            request(`${process.env.REACT_APP_API}/assets/games/stats/${this.state.assetData.identifier}`, 'get').then(
                (data) => {
                    console.log('handleGetPlayersApi', data)
                    if (data.success){
                        this.setState({
                            ready : true, 
                            gamePlaysCount: data.datas.participations,
                            gamePlayersCount : data.datas.singleParticipations,
                            gameInstantsWins : data.datas.giftsWon,
                            gameRemaingInstants : data.datas.remainingGifts,
                            gameNextInstant : data.datas.nextGift,
                            chartDatas: data.datas.chart
                        });
                    }
                    let stats = this.state.stats.map((stat,index)=>({
                            ...stat,
                            data : this.handleGetStats(stat.statType)
                        
                    }))
                    this.handleSetupCharts(data.datas.chart);
                    this.setState({
                        stats : stats
                    })
                    this.props.stopLoading();
                    resolve();
                }
            );
        });
    }

    changePage = (event, page, type) => {
        this.props.startLoading();
        let index = 0;
        if(page > 1){
            index = ( page * this.state.nbperpage ) - this.state.nbperpage - 1
        }
        this.setState({
            cursor: this.state.listCursors[index].cursor,
            page: page
        },()=>{
            if (this.state.assetData.assetType.identifier === 'flipbook' || this.state.assetData.assetType.identifier === "flipbook_with_cart"){
                this.handleGetWishlist(true);
            }else{
                this.handleGetInstantsPagination();
            }
        });
    };

    handleGetCursorsWishlist = () => {
        this.props.client.query({
            query: GET_ASSET_WISHLISTS_CURSORS,
            fetchPolicy: 'no-cache'
        }).then(result => {
            console.log('handleGetCursorsWishlist', result)
            this.setState({
                listCursors: result.data.assetWishlists.edges,
            });
        });
    }

    handleGetWishlist = (changePage) => {

        return new Promise(async (resolve, reject) => {
            let variables = 
                {
                    "nbperpage": this.state.nbperpage, 
                    "asset" : this.state.idAsset,
                };
            if (this.state.cursor && this.state.listCursors && this.state.cursor !== this.state.listCursors[0].cursor) {
                variables.cursor = this.state.cursor;
            }
            this.props.client.query({
                query: GET_ASSET_WISHLISTS,
                variables
            }).then(GET_ASSET_WISHLISTS_RESULT =>{
                this.handleGetCursorsWishlist();
                this.setState({
                    countPage: Math.ceil(GET_ASSET_WISHLISTS_RESULT.data.assetWishlists.totalCount / this.state.nbperpage),
                    gamePlays: GET_ASSET_WISHLISTS_RESULT.data.assetWishlists.edges,
                    gamePlaysCount: GET_ASSET_WISHLISTS_RESULT.data.assetWishlists.totalCount,
                });
                this.setState({
                    gamePlays: GET_ASSET_WISHLISTS_RESULT.data.assetWishlists.edges,  
                    gamePlaysCount : GET_ASSET_WISHLISTS_RESULT.data.assetWishlists.totalCount,
                })
                if (!changePage){
                    request(`${process.env.REACT_APP_API}/assets/wishlist/catalogue_de_noel/top_five`, 'get').then(
                        (data) => {
                            console.log('data request', data)
                            let arraySku = [];
                            let rankingProducts = []
                            // data.ranking.forEach(function(item){
                            //     Object.keys(item).forEach(function(key){
                            //         console.log("key:" + key + "value:" + item[key]);
                            //     })
                            // })
                            for (const [key, value] of Object.entries(data.ranking)) {
                                // console.log(`${key}: ${value}`);
                                arraySku.push(key)
                                rankingProducts.push({
                                    id: key,
                                    product: null,
                                    value
                                })
                                // console.log('rankingProducts', rankingProducts)
                            }
                            rankingProducts.sort(function (a, b) {
                                // console.log('a', a)
                                // console.log('b', b)
                                // console.log('a.value - b.value', a.value - b.value)
                                return b.value - a.value;
                            });
                            this.setState({
                                rankingProducts 
                            })
                            console.log('rankingProducts', rankingProducts)
                            if(data.success){
                                this.props.client.query({
                                    query: GET_PRODUCTS_PAGINATION,
                                    fetchPolicy: 'no-cache',
                                    variables:{
                                        "idList": arraySku
                                    }
                                }).then(GET_PRODUCTS_PAGINATION_RESULT => {
                                    console.log('GET_PRODUCTS_PAGINATION_RESULT', GET_PRODUCTS_PAGINATION_RESULT)
                                    GET_PRODUCTS_PAGINATION_RESULT.data.products.edges.map((product, index) => {
                                        if (this.state.rankingProducts.length > 0){
                                            this.state.rankingProducts[index].product = product.node
                                            return null
                                        }else{
                                            return null;
                                        }
                                    })
                                    console.log('this.state.rankingProducts', this.state.rankingProducts)
                                    let statsFlipbook = [{
                                        icon: 'picto-profil',
                                        dataColor: colors.blue.regular,
                                        subtitle: 'Wishlists',
                                        gradientColor1: colors.blue.regular,
                                        gradientColor2: colors.blue.lighter,
                                        dataSize:'h3',
                                        statType : 'gamePlays',
                                        data: GET_ASSET_WISHLISTS_RESULT.data.assetWishlists.totalCount,
                                    }]
                                    let statsRankingProducts = []
                                    if (GET_PRODUCTS_PAGINATION_RESULT.data.products.edges.length > 0){
                                        console.log('rankingProducts', rankingProducts)
                                        this.state.rankingProducts.map((rank, index) => {
                                            statsRankingProducts.push({
                                                icon: 'picto-profil',
                                                dataColor: colors.blue.regular,
                                                subtitle: `Top Produit #${index + 1}`,
                                                gradientColor1: colors.blue.regular,
                                                gradientColor2: colors.blue.lighter,
                                                dataSize:'h3',
                                                statType : 'gamePlays',
                                                data: rank.value,
                                                productInfo: rank.product
                                            })
                                            return null
                                        })
                                        statsFlipbook.push()
                                    }
                                    this.setState({
                                        ready : true, 
                                        statsFlipbook,
                                        statsRankingProducts,
                                    })
                                    console.log('statsFlipbook', this.state.statsFlipbook)
                                    resolve();
                                })
                                // eventService.fire({stateName: 'attributeIdentifier', errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.'});
                            }
                        }
                    );
                }
                this.props.stopLoading();
            });
            
        });
    }
    

    handleGetPlays=()=>{
        return new Promise(async (resolve, reject) => {
            this.props.client.query({
                query: GET_GAME_PLAYS,
                variables:{
                    "asset" : this.state.idAsset
                }
            }).then(result =>{
                console.log('GET_GAME_PLAYS',result)
                let gamesValidated = result.data.assetGamePlays.edges.filter(e => e.node.isPlayed)
                this.setState({
                    gamePlays : gamesValidated,
                    gamePlaysCount : gamesValidated.length
                })
                resolve();
            })
            
        });
    }

    handleGetStatsRecognizer = () => {
        return new Promise(async (resolve, reject) => {
            let formData = new FormData();
                formData.append('uuid', uuidv4())
            request(`${process.env.REACT_APP_STATS_API}`, 'post', formData, 'multipart/form-data').then(
                (data) => {
                    this.setState({
                        gamePlays: data.statistics
                    })
                    let statsRecognizer = [{
                        icon: 'picto-profil',
                        dataColor: colors.blue.regular,
                        subtitle: 'Participations',
                        gradientColor1: colors.blue.regular,
                        gradientColor2: colors.blue.lighter,
                        dataSize:'h3',
                        statType : 'gamePlays',
                        data: data.statistics.length,
                    }]
                    let counterMatch = 0;
                    let counterTotal = 0;
                    for (let players of data.statistics){
                        for (let play of players.details){
                            if (play.isMatching){
                                counterMatch++
                            }
                            counterTotal++
                        }
                    }
                    
                    let percentMatch = counterMatch / counterTotal;
                    percentMatch = percentMatch * 100;
                    percentMatch = Math.round(percentMatch);
                    statsRecognizer.push(
                        {
                            icon: 'icon-win',
                            dataColor: colors.blue.regular,
                            subtitle: 'Taux de succès scan',
                            gradientColor1: colors.blue.regular,
                            gradientColor2: colors.blue.lighter,
                            dataSize:'h3',
                            statType : 'gamePlays',
                            data: `${percentMatch} %`,
                        }
                    )
                    let arraySku = [];
                    let rankingProducts = [];
                    for (const [key, value] of Object.entries(data.top)) {
                        // console.log(`${key}: ${value}`);
                        arraySku.push(key)
                        rankingProducts.push({
                            id: key,
                            product: value.product,
                            value: value.cpt
                        })
                    }
                    rankingProducts.sort(function (a, b) {
                        return b.value - a.value;
                    });
                    let statsRankingProducts = []
                    rankingProducts.map((rank, index) => {
                        statsRankingProducts.push({
                            icon: 'picto-profil',
                            dataColor: colors.blue.regular,
                            subtitle: `Top boite LEGO #${index + 1}`,
                            gradientColor1: colors.blue.regular,
                            gradientColor2: colors.blue.lighter,
                            dataSize:'h3',
                            data: rank.value,
                            productInfo: rank.product
                        })
                        return null
                    })
                    this.setState({
                        ready : true,
                        stats: statsRecognizer,
                        statsRankingProducts,
                    });
                    this.props.stopLoading();
                    resolve();
                }
            );
        });
    }
    // handleGetInstants=()=>{
    //     return new Promise(async (resolve, reject) => {
    //         this.props.client.query({
    //             query: GET_GAME_INSTANTS,
    //             variables:{
    //                 "asset" : this.state.idAsset
    //             }
    //         }).then(result =>{
    //             console.log('GET_GAME_INSTANTS',result)
    //             let gameInstants = result.data.assetGameInstants.edges;
    //             let gameInstantsWins = gameInstants.filter(game => game.node.isWin).length;
    //             let gameRemaingInstants = gameInstants.filter(game => !game.node.isWin)
    //             let gameNextInstant = gameRemaingInstants.sort((a,b)=>this.sortInstantsByDate(a,b))[0];
    //             this.setState({
    //                 gameInstantsWins : gameInstantsWins,
    //                 gameRemaingInstants : gameRemaingInstants.length,
    //                 gameNextInstant : gameNextInstant
    //             })

    //             resolve();
    //         })
            
    //     });
    // }
    sortInstantsByDate = (game,gameNext)=>{
        let gameDate = new Date(game.node.winningAt);
        let gameNextDate = new Date(gameNext.node.winningAt);
        return gameDate - gameNextDate;
        
    }
    resetState = () => {
        this.setState({
            paramIdentifier : null,
            paramStartAt    : null,
            paramStatus     : true,
            paramUrl        : null,
        });
        this.handleGetAssetDatas();
        this.prepareAttributeValues();
    }

    handleToggleDrawer = (stateDrawer, changeWidth) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer],
            errors: {},
        });

        if (changeWidth) {
            this.handleDrawerWidthChange(300);
        } 
        
    };

    handleChangeTab=(event,value)=>{
        this.setState({
            tabValue : value
        })
    }


    handleDrawerWidthChange = (width) => {
        this.setState({ 
            drawerWidthModified: width
        });
    };
    handleSelectPie = (event) => {
        this.setState({ selectedDataPie: event.target.value }, () => {
            eventService.fire();
        });
    };
    handleGetStats = (type) =>{
        console.log('handleGetStats', type)
        switch(type){
            case 'gamePlays':
                return this.state.gamePlaysCount;
            case 'uniquePlays':
                return this.state.gamePlayersCount;
            case 'gameWins':
                return this.state.gameInstantsWins;
            case 'remaingGifts' : 
                return this.state.gameRemaingInstants;
            case 'nextGift' : 
                let next = this.state.gameNextInstant;
                let date = next ? moment.utc(next).format('L à HH:mm') : 'Aucun';
                return date;
            default:
                return null;
        }
    }

    handleSetupCharts = (chart) =>{
        let allDates = this.state.dates;
        let allDatas = this.state.allDatas;
        console.log('chart', chart)
        // chart.forEach(function(item) {
        //     Object.keys(item).forEach(function(key) {
        //         console.log("key:" + key + "value:" + item[key]);
        //     });
        // });
        Object.keys(chart)
        .forEach(function eachKey(key) { 
            console.log('key:',key, 'value: ', chart[key]); // alerts key 
            allDates.push(moment(key).format('D/M/YYYY'));
            allDatas[0]['data'].push(chart[key]);
        });

        this.setState({
            dates : allDates,
            allDatas : allDatas
        })
    }

    setUpStats= async ()=>{
        await this.handleGetAssetDatas();
        if (this.state.assetData.assetType.identifier === 'flipbook' || this.state.assetData.assetType.identifier === "flipbook_with_cart"){
            await this.handleGetWishlist();
        }else if (this.state.assetData.assetType.identifier === 'image_recognizer'){
            await this.handleGetStatsRecognizer();
        }else{
            // await this.handleGetPlays();
            await this.handleGetPlayersApi();
            await this.handleGetInstantsPagination();
            // await this.handleGetInstants();
        }
    }

    handlerExportWinners = async (assetIdentifier) => {
        try {
            this.props.startLoading();
            request(`${process.env.REACT_APP_API}/assets/games/winners/${assetIdentifier}`, 'get', false).then(
                (data) => {
                    if(data.success){
                        window.open(`${process.env.REACT_APP_API_ROOT}/medias/export/${data.file.filePath}.${data.file.type}`, '_blank');
                        this.props.stopLoading();
                        this.props.snack(ALERT_SUCCESS, `Téléchargement réussi !`, 10000);
                    }else{
                        this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
                        this.props.stopLoading();
                    }
                }
            );
        } catch(e) {
            this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
            this.props.stopLoading();
        }
    };

    render() {
        const assetData = this.state.assetData;
        const TabPanel=(props)=>{
            return (
                props.index === this.state.tabValue 
                    ? 
                    <PanelItem>{props.children}</PanelItem>
                :null 
                
            );
        }
        return (
            <div style={{width: this.state.openFormEdit ? assetData.assetType.hasFeed === true ? "100%" : `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                <TopPanel 
                    icomoon         = "icon-asset" 
                    colorIcomoon    = {colors.blue.lighter.hue300}
                    title           = "Gérer mon Asset" 
                    subtitle        = "Visualisation des statistiques"
                    gradientColor1  = {colors.menu.regular} 
                    gradientColor2  = {colors.menu.darker} 
                    openForm        = {this.state.openFormEdit}
                    handlerAdd      ={() => this.handlerExportWinners(assetData.identifier)} 
                    textAdd         ={this.state.assetData?.assetType.identifier === 'flipbook' || this.state.assetData?.assetType.identifier === 'image_recognizer' ? null : "Exporter les gagnants"}
                    buttonAvailable={this.state.ready}
                    hasBorder={true}
                />
                <Box onClick={this.props.history.goBack} style={{marginBottom: 16, marginTop: 16}}>
                    <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                </Box>
                {
                    this.state.assetData ?
                        this.state.assetData.assetType.identifier !== 'flipbook' ?
                            <>
                                {
                                    this.state.assetData.assetType.identifier !== 'image_recognizer' ?
                                        <Tabs
                                            value={this.state.tabValue}
                                            onChange={this.handleChangeTab}
                                            scrollButtons="on"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            aria-label="scrollable force tabs example"
                                            variant="fullWidth"
                                            >
                                                <Tab label="Vue d'ensemble" icon={<TrendingUpIcon/>}/>
                                                <Tab label={this.state.assetData.assetType.identifier === 'flipbook' || this.state.assetData.assetType.identifier === "flipbook_with_cart" || this.state.assetData.assetType.identifier === 'image_recognizer' ? "Détail des utilisateurs" : "Instants gagnants"} icon={<FaceIcon/>} />

                                        </Tabs>
                                        : null
                                }
                                <TabPanel index={0}>
                                    <Grid container direction="row" spacing={6} justify="center" style={{width: "100%"}}>
                                        
                                        {
                                            this.state.ready ?
                                                this.state.stats.map((stat, index) =>
                                                    (
                                                        <Grid item key={`statsGrid${index}`}> 
                                                            <Stats stat={stat} windowWidth={this.props.windowWidth}/>
                                                        </Grid>
                                                    )
                                                )
                                            : null
                                        }
                                    
                                    </Grid>
                                    {
                                        this.state.assetData.assetType.identifier === 'image_recognizer' ?
                                            <Grid container justify="center" style={{marginTop: 24}}>
                                                {
                                                    this.state.statsRankingProducts ?
                                                        this.state.statsRankingProducts.map((stat, index) =>
                                                            (
                                                                <Grid item key={`statsGrid${index}`} style={{marginRight: 16, width: "calc((100%/5) - (5 * 16px))"}}> 
                                                                    <Stats stat={stat} windowWidth={this.props.windowWidth} isRecognizer={true}/>
                                                                </Grid>
                                                            )
                                                        )
                                                    : null
                                                }
                                                {
                                                    this.state.gamePlays?.length > 0 ? (
                                                        <GridCustom item xs={12} style={{marginTop: 24}}>
                                                            <ArrayStats players={this.state.gamePlays} locale={this.state.currentLocale} isRecognizer={true}/>
                                                            {
                                                                this.state.countPage > 1 ? 
                                                                    (<PaginationCustom onChange={(event, page) => {this.changePage(event, page, null)}} page={this.state.page} count={this.state.countPage} color="primary" />)
                                                                :
                                                                    null
                                                            }  
                                                        </GridCustom>
                                                    ) :  
                                                        this.state.ready ?
                                                        (<EmptyCard title={"Aucune statistique pour le moment"} subtitle={"Revenez quand des personnes auront participées à ce jeu"} onClick={() => {this.handleToggleDrawer('form')}} picto={emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />) 
                                                        :
                                                        (<PageLoader />)
                                                }
                                            </Grid>
                                            :
                                            <Grid container direction="row">
                                                <CardCustom paddingtop={'48px'} contentpadding={'0 32px'} width="100%" margintop="40px">                                   
                                                    <LineCharts xAxisData={this.state.dates} yAxisData={this.state.allDatas} handleSelectPie={this.handleSelectPie}/>     
                                                </CardCustom>
                                            </Grid>
                                    }
                                </TabPanel>
                                <TabPanel index={1}>
                                    {
                                        this.state.allInstants.length > 0 ? (
                                            <GridCustom item xs={12}>
                                                <ArrayStats players={this.state.allInstants} locale={this.state.currentLocale} />
                                                {
                                                    this.state.countPage > 1 ? 
                                                        (<PaginationCustom onChange={(event, page) => {this.changePage(event, page, null)}} page={this.state.page} count={this.state.countPage} color="primary" />)
                                                    :
                                                        null
                                                } 
                                            </GridCustom>
                                        ) 
                                        :  
                                            this.state.ready ?
                                            (<EmptyCard title={"Aucune statistique pour le moment"} subtitle={"Revenez quand des personnes auront participées à ce jeu"} onClick={() => {this.handleToggleDrawer('form')}} picto={emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />) 
                                            :
                                            (<PageLoader />)
                                    }
                                </TabPanel>
                            </>
                        : <Grid container>
                            {
                                this.state.statsFlipbook ?
                                    this.state.statsFlipbook.map((stat, index) =>
                                        (
                                            <Grid item key={`statsGrid${index}`} style={{marginRight: 16}}> 
                                                <Stats stat={stat} windowWidth={this.props.windowWidth}/>
                                            </Grid>
                                        )
                                    )
                                : null
                            }
                            <Grid container justify="center" style={{marginTop: 16}}>
                                {
                                    this.state.statsRankingProducts ?
                                        this.state.statsRankingProducts.map((stat, index) =>
                                            (
                                                <Grid item key={`statsGrid${index}`}> 
                                                    <Stats stat={stat} windowWidth={this.props.windowWidth}/>
                                                </Grid>
                                            )
                                        )
                                    : null
                                }
                            </Grid>
                            {
                                this.state.gamePlays?.length > 0 ? (
                                    <GridCustom item xs={12} style={{marginTop: 16}}>
                                        <ArrayStats players={this.state.gamePlays} locale={this.state.currentLocale} isFlipbook={true}/>
                                        {
                                            this.state.countPage > 1 ? 
                                                (<PaginationCustom onChange={(event, page) => {this.changePage(event, page, null)}} page={this.state.page} count={this.state.countPage} color="primary" />)
                                            :
                                                null
                                        }  
                                    </GridCustom>
                                ) :  
                                    this.state.ready ?
                                    (<EmptyCard title={"Aucune statistique pour le moment"} subtitle={"Revenez quand des personnes auront participées à ce jeu"} onClick={() => {this.handleToggleDrawer('form')}} picto={emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />) 
                                    :
                                    (<PageLoader />)
                            }
                        </Grid>
                    : (<PageLoader />)
                }
            </div>
        );
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => ({...a})); 
    goTo = route => this.props.history.push(route);
};


const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
}

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsPageStats)));

