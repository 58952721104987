import React from 'react';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { ROUTE_CRM_CONTACTS_ADD, ROUTE_CRM_CONTACTS_CLIENTS, ROUTE_CRM_CONTACTS_LEADS, ROUTE_CRM_CONTACTS_LIST, ROUTE_HOME } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import importCustomersTypesConfig from './config/importCustomersTypes.config';
import importCustomersConfig from './config/importCustomers.config';
import importFichier from '../../../../assets/pictos/icon-import-fichier.svg';
import importAPI from '../../../../assets/pictos/icon-import-api.svg';
import importFlux from '../../../../assets/pictos/icon-import-flux.svg';
import request from '../../../../js/utils/fetch';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import emptyProduct from '../../../../assets/pictos/empty-picto/product.svg';

import { Grid, Tooltip, TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import { hasRights } from '../../../../js/utils/rights';
import { CRM_CONTACT, CRM_CONTACT_CLIENTS, CRM_CONTACT_CONTACTS, CRM_CONTACT_LEADS, CREATE, IMPORT, VIEW} from '../../../../js/constants/constant-rights';
import * as customersListingConfig from './config/contactListing.config'
import Listing from '../../../layouts/Listing/Listing'
import {searchItem} from '../../../../js/helpers/search'


const styles = theme => ({
});

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.darker.hue300,
    },
    tooltip: {
        backgroundColor: colors.blue.darker.hue300,
        fontSize: 14,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class ListCustomers extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentLang: props.locales[0].node.code,
            openForm: false,
            openFormImport: false,
            catalogFormData : {},
            page: 1,
            nbperpageCustomers: 6,
            countPage: 0,
            cursor: null,
            skulist: [],
            searchValue: '',
            arrayCatFilter: [],
            currentStatus : props.typeContact,
            isEmpty: false,
            viewMode: 'card',
        }
        this.typingSearchTimer = null;
    }

    handleInitData = (typeContact) => {
        let content;
        let showTab = false;
        switch(typeContact){
            case 'client':
                content = {
                    title: "Clients",
                    subtitle: "Gestion de vos clients (création / modification / suppression)",
                    picto: "icon-clients",
                    textAdd: "Ajouter un client",
                    textImport: "Importer des clients",
                    route: ROUTE_CRM_CONTACTS_CLIENTS
                };
                importCustomersConfig.titleForm = 'Importer des clients';
                importCustomersConfig.subTitleForm = 'Veuillez compléter les champs ci-dessous pour importer vos clients';
            break;
            case 'lead':
                content = {
                    title: "Leads",
                    subtitle: "Gestion de vos leads (création / modification / suppression)",
                    picto: "icon-leads",
                    textAdd: "Ajouter un lead",
                    textImport: "Importer des leads",
                    route: ROUTE_CRM_CONTACTS_LEADS
                };
                importCustomersConfig.titleForm = 'Importer des leads';
                importCustomersConfig.subTitleForm = 'Veuillez compléter les champs ci-dessous pour importer vos leads';
            break;

            case 'contact':
                content = {
                    title: "Contacts",
                    subtitle: "Gestion de vos contacts (création / modification / suppression)",
                    picto: "icon-contacts",
                    textAdd: "Ajouter un contact",
                    textImport: "Importer des contacts",
                    route :ROUTE_CRM_CONTACTS_LIST
                };
                importCustomersConfig.titleForm = 'Importer des contacts';
                importCustomersConfig.subTitleForm = 'Veuillez compléter les champs ci-dessous pour importer vos contacts';
                break;
            default :
                return null;
        }
        this.setState({
            showTab: showTab,
            content: content,
        })
    }

    handleSearch = async(e) => {
        let idList= await searchItem(e,'customer','/api/customers/',{attributes:null},{delay:100})
        this.setState({
            activeSearch : idList
        })

    }

    getCustomersCallback = (customers) => {
        this.setState({
            customerIsReady : true,
            reload:false
        })
    }
    reloadCustomers = () =>{
        this.setState({reload:true})
    }

    handleCategory = (category)=>{
        this.setState({
            customerIsReady: false,
            currentStatus: category,
            currentPage: 1,
            cursor : null
        })
    };

    checkAccess() {
        let type = null;
        switch(this.state.currentStatus){
            case 'contact' :
                type = CRM_CONTACT_CONTACTS;
            break;
            case 'client' :
                type = CRM_CONTACT_CLIENTS;
            break;
            case 'lead' :
                type = CRM_CONTACT_LEADS;
            break;
            default :
                return null;
        }
        const getRights = hasRights(CRM_CONTACT, type, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.setState({
                accessType: type,
            })
        }
    }

    componentDidMount() {   
        this.checkAccess();
        this.getAll();
        if(this.props.location.state?.hasMessage){
            this.props.snack(ALERT_SUCCESS,this.props.location.state.message)
        }   
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.typeContact !== prevProps.typeContact){
            this.setState({
                currentStatus: this.props.typeContact,
                openFormImport: false,
                openForm: false,

            }, 
                ()=>{
                    this.checkAccess();
                    this.reloadCustomers();
                    this.handleInitData(this.props.typeContact);
            })
        }
    }

    async getAll() {
        await this.handleInitData(this.state.currentStatus);
    }

    handleToggleDrawer = (stateDrawer) => {
        this.setState({ 
            [stateDrawer] : !this.state[stateDrawer]
        });
    };

    handleFormImport = (type, title) => {
        this.setState({
            catalogFormData: {
                type,
                title
            },
            mapper: [],
            media: null,
            headers: null,
            importFile: null,
            importSep: ';',
            importValues: {},
            importLang: this.props.locales[0].node.id
        });

        this.handleToggleDrawer('openFormImport');
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();

            this.forceUpdate();
        });
    };

    handlerMutation = async () => {
        this.props.startLoading();

        let importConfig = {
            "url": `${process.env.REACT_APP_API_ROOT}${this.state.media?.contentUrl ?? null}`,
            "mapper": this.state.mapper,
            "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'customer').node.id,
            "locale": this.state.importLang,
            "delimiter":this.state.importSep
        };

        try {
            await request(`${process.env.REACT_APP_API_ROOT}/api/file-import-crms`, 'post', importConfig, undefined, true);
            this.props.snack(ALERT_SUCCESS, `Votre fichier a été importé avec succès, son intégration dans SpreadSuite sera exécutée lors du passage du CRON (Tâche automatique).`,6000);
            this.getAll();
            this.handleToggleDrawer('openForm');
            this.handleToggleDrawer('openFormImport');
            this.props.stopLoading();
        } catch(e) {
            this.props.snack(ALERT_ERROR, `L'import a échoué !`);
            this.props.stopLoading();
        }
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    noResult = () =>{
        return(
            <EmptyCard title={"Aucun résultat trouvé"} subtitle={hasRights(CRM_CONTACT, this.state.accessType, CREATE) ? "Ajouter un contact en cliquant ci-dessous" : "Faite une demande auprès d'un administrateur"} textButton={hasRights(CRM_CONTACT, this.state.accessType, CREATE) ? "Ajouter un contact" : null} onClick={() => this.goTo(ROUTE_CRM_CONTACTS_ADD)} picto={emptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
        )
    }

    render() {

        let productBypage = [];
        this.state.viewMode === 'list' ? productBypage = [10,20,50] : productBypage = [6,18,30];

        return (
            <div>
                {this.state.content ? (
                    <TopPanel 
                        icomoon={this.state.content.picto}
                        colorIcomoon={colors.blue.lighter.hue300}
                        title={this.state.content.title}
                        subtitle={this.state.content.subtitle}
                        handlerAdd={() => this.goTo(ROUTE_CRM_CONTACTS_ADD,this.state.currentStatus,'typeContact')} 
                        textAdd={hasRights(CRM_CONTACT, this.state.accessType, CREATE) ? this.state.content.textAdd : null}
                        handlerImport={() => this.handleToggleDrawer('openForm')} 
                        textImport={hasRights(CRM_CONTACT, this.state.accessType, IMPORT) ? this.state.content.textImport : null}
                        searchHandler={this.handleSearch} 
                        gradientColor1={colors.menu.regular} 
                        gradientColor2={colors.menu.darker}
                        windowWidth={this.props.windowWidth}
                        openForm={this.state.openForm}
                        buttonAvailable={this.state.customerIsReady}
                        // currentLang={this.state.currentLang} 
                        // handleLang={this.handleLang} 
                        // locales={this.props.locales}
                        hasBorder={true}
                    />
                    ) : '' 
                }
                <TraductionSelect 
                    currentLang={this.state.currentLang} 
                    handleLang={this.handleLang} 
                    locales={this.props.locales}
                />
                


                
                    <Listing
                        identifier = 'customers'
                        perPageOptions = {customersListingConfig.perPageOptions}
                        viewsOptions = {customersListingConfig.viewsOptions}
                        label = 'contacts'
                        settings = {customersListingConfig.listSettings}
                        mappers = {customersListingConfig.listMappers}
                        currentLang = {this.state.currentLang}
                        cardProps = {{
                            style : {height:'100%'},
                            isCard: true       
                        }}
                        cardContainerProps = {{
                            hovercard:true ,
                            style:{height:'100%'},
                            contentpadding :  '16px' ,
                        }}
                        listingCallback={this.getCustomersCallback}
                        queryVariables = {{
                            id_list : this.state.activeSearch,
                            status : this.state.currentStatus
                        }}
                        noResultComponent = {this.noResult}

                    />

                
                
                <LayoutBuilder 
                    opened={this.state.openForm} 
                    forClose={() => this.handleToggleDrawer('openForm')} 
                    dataLayout={importCustomersTypesConfig} 
                    drawerWidth={this.props.drawerWidth} 
                    dataCard={[
                        {
                            'libelle': 'Importer via un',
                            'bicoloreText': 'flux',
                            'colorhover': '#6EAED1',
                            'picto': importFlux,
                            'disabled': true,
                            'textButton': 'Importer',
                            'description': 'Votre import contacts facilité en renseignant simplement votre flux', 
                            'catalogDescription' : 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'flux')
                        },
                        {
                            'libelle': 'Importer via une',
                            'bicoloreText': 'API',
                            'colorhover': '#6EAED1',
                            'picto': importAPI,
                            'disabled': true,
                            'textButton': 'Importer',
                            'description': 'Votre import contacts facilité en renseignant simplement votre API', 
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'api')
                        },
                        {
                            'libelle': 'Importer un',
                            'bicoloreText': 'fichier',
                            'colorhover': '#6EAED1',
                            'picto': importFichier,
                            'disabled': false,
                            'textButton': 'Importer',
                            'description': 'Votre import contacts facilité en important simplement votre fichier', 
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'fichier')
                        }
                    ]}
                />

                <LayoutBuilder 
                    opened={this.state.openFormImport} 
                    icomoon={'ico-import-fichier'}
                    forClose={() => this.handleToggleDrawer('openFormImport')}  
                    dataLayout={importCustomersConfig} 
                    drawerWidth={this.state.drawerWidth}
                    handleCancel={this.handleCancel}
                    handlerMutation={this.handlerMutation} 
                    allState={this.state} 
                    stateCallback={this.stateCallback}
                    stepperButtonDisabled={[() => this.state.headers === null, null]}
                    stepperButtonAction={[null, null]}
                    backStepperButtonAction={[null, null, null]}
                />      

            </div>
        );
    }

    goTo = (route, id,target) => {
        let variables = {};
        variables[target] = id ;
        this.props.history.push({
            pathname : route,
            state: variables
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ListCustomers))));
