import { v4 as uuidv4 } from "uuid";
import { ItemTypes, EditableTypes, FormInputTypes, Pages } from "../shareable/types";
import { CopyWithJSON } from "../../js/utils/copy";

import { 
    SectionTitle, 
    ProductGrid, 
    Footer, 
    Slider, 
    ImageRow, 
    GiftFinder, 
    SliderWithRightImages, 
    SliderMultiple, 
    ThreeImagesRow, 
    ThreeImagesGrid, 
    BlocText,
    Contact, 
    Wishlist,
    Landing,
    Product,
    EmailContact,
    EmailWishlist,
    InformationBanner,
    LinkBanner,
    Category,
    TextImage,
    SliderProduct,
    TextVideo,
} from './config/minisite';

import { 
    preHeaderGameDefault, 
    headerGameDefault, 
    footerGameDefault, 
    homeGameDefault, 
    homeCompleteFormGameDefault, 
    loginGameDefault,
    indexGameDefault, 
    resultsWinGameDefault, 
    resultsLooseGameDefault, 
    alreadyPlayedGameDefault, 
    endedGameDefault, 
    notStartedGameDefault, 
    redirectOnGameDefault, 
    redirectOnGame2Default, 
    redirectOnGame3Default, 
    redirectOnGame4Default, 
    explanationsGameDefault, 
    explanationsGameACVDefault, 
    explanationsGameBFDefault, 
    rulesGameDefault, 
    explanationsMoreDetailsGameDefault,
    basicBlocGameDefault, 
    basicBlocGame2Default,
    amazingGameDefault,
    titleAmazingGameDefault,
    paragraphAmazingGameDefault,
    rulesAmazingGameDefault,
    rulesInlineFleAmazingGameDefault,
    dotationsOneImageAmazingGameDefault,
    buttonAmazingGameDefault
} from './config/game';

import { 
    flipbook, 
    flipbookWithCart, 
    headerFlipbook, 
    headerCartFlipbook, 
    footerFlipbook, 
    homeFlipbook, 
    postCardFlipbook, 
    recapBlockFlipbook, 
    resultFlipbook, 
    bannerLinkFlipbook, 
    emailSuccessFlipbook 
} from './config/book';

import { 
    OfferSectionTitle, 
    OfferFrontPage, 
    OfferAboutSlide, 
    OfferTripleBlockList, 
    OfferTripleBlock, 
    OfferTripleBlockText, 
    OfferTextWithImage, 
    OfferTextWithImagesSimple,
    OfferDevis
} from './config/offer';

import {
    SliderTop,
    SliderInstagram,
    SliderImage,
    SliderPresentationMedia,
    SliderMoments,
    GridImage,
    BlocTextMedia,
    BannerMedia,
    SectionDescriptionPage,
    SectionManifestBlock,
    SliderAbout,
    CategoriesHome,
    CollectionsHome,
    SliderProductsHome,
    BlocHomeStorelocator,
    Entete,
    TopImageFullscreen,
    Slogan,
    TitleText,
    Separator,
    ListCategories,
    SliderByTab
} from './config/cms';

import { getDefaultText } from './helpers';

//CONFIG PAR TYPE 
export const getDefaultData = (type, value) => {
    switch(type) {
        case ItemTypes.SECTION_TITLE: return CopyWithJSON(SectionTitle);
        case ItemTypes.SLIDER: return CopyWithJSON(Slider);
        case ItemTypes.SLIDER_WITH_RIGHT_IMAGES: return CopyWithJSON(SliderWithRightImages);
        case ItemTypes.SLIDER_MULTIPLE: return CopyWithJSON(SliderMultiple);
        case ItemTypes.THREE_IMAGES_ROW: return CopyWithJSON(ThreeImagesRow);
        case ItemTypes.THREE_IMAGES_GRID: return CopyWithJSON(ThreeImagesGrid);
        case ItemTypes.BLOC_TEXT: return CopyWithJSON(BlocText);
        case ItemTypes.IMAGE_ROW: return CopyWithJSON(ImageRow);
        case ItemTypes.TAB_PRODUCT: return CopyWithJSON(ProductGrid);   
        case ItemTypes.FOOTER: return CopyWithJSON(Footer);
        case ItemTypes.GIFT_FINDER: return CopyWithJSON(GiftFinder);
        case ItemTypes.TEXT: return CopyWithJSON(textDefault(value));
        case ItemTypes.TEXT_HTML: return CopyWithJSON(textDefault(value, true));
        case ItemTypes.IMAGE: return CopyWithJSON(imageDefault(value));
        case ItemTypes.INFORMATION_BANNER: return CopyWithJSON(InformationBanner);
        case ItemTypes.LINK_BANNER: return CopyWithJSON(LinkBanner);
        case ItemTypes.CATEGORY: return CopyWithJSON(Category);
        case ItemTypes.TEXT_IMAGE: return CopyWithJSON(TextImage);
        case ItemTypes.SLIDER_PRODUCT: return CopyWithJSON(SliderProduct);
        case ItemTypes.TEXT_VIDEO: return CopyWithJSON(TextVideo);
        case ItemTypes.PAGE_CONTACT: return CopyWithJSON(Contact);
        case ItemTypes.PAGE_WISHLIST: return CopyWithJSON(Wishlist);
        case ItemTypes.PAGE_LANDING: return CopyWithJSON(Landing);
        case ItemTypes.PAGE_PRODUCT: return CopyWithJSON(Product);
        case ItemTypes.EMAIL_CONTACT: return CopyWithJSON(EmailContact);
        case ItemTypes.EMAIL_WISHLIST: return CopyWithJSON(EmailWishlist);
        case ItemTypes.HEADER_GAME: return CopyWithJSON(headerGameDefault);
        case ItemTypes.PRE_HEADER_GAME: return CopyWithJSON(preHeaderGameDefault);
        case ItemTypes.FOOTER_GAME: return CopyWithJSON(footerGameDefault);
        case ItemTypes.HOME_GAME: return CopyWithJSON(homeGameDefault);
        case ItemTypes.HOME_COMPLETE_FORM_GAME: return CopyWithJSON(homeCompleteFormGameDefault);
        case ItemTypes.LOGIN_GAME: return CopyWithJSON(loginGameDefault);
        case ItemTypes.INDEX_GAME: return CopyWithJSON(indexGameDefault);
        case ItemTypes.RESULTS_WIN_GAME: return CopyWithJSON(resultsWinGameDefault);
        case ItemTypes.RESULTS_LOOSE_GAME: return CopyWithJSON(resultsLooseGameDefault);
        case ItemTypes.ALREADYPLAYED_GAME: return CopyWithJSON(alreadyPlayedGameDefault);
        case ItemTypes.ENDED_GAME: return CopyWithJSON(endedGameDefault);
        case ItemTypes.NOT_STARTED_GAME: return CopyWithJSON(notStartedGameDefault);
        case ItemTypes.REDIRECT_ON_GAME: return CopyWithJSON(redirectOnGameDefault);
        case ItemTypes.REDIRECT_ON_GAME2: return CopyWithJSON(redirectOnGame2Default);
        case ItemTypes.REDIRECT_ON_GAME3: return CopyWithJSON(redirectOnGame3Default);
        case ItemTypes.REDIRECT_ON_GAME4: return CopyWithJSON(redirectOnGame4Default);
        case ItemTypes.EXPLANATIONS_GAME: return CopyWithJSON(explanationsGameDefault);
        case ItemTypes.EXPLANATIONS_ACV_GAME: return CopyWithJSON(explanationsGameACVDefault);
        case ItemTypes.EXPLANATIONS_BF_GAME: return CopyWithJSON(explanationsGameBFDefault);
        case ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME: return CopyWithJSON(explanationsMoreDetailsGameDefault);
        case ItemTypes.AMAZING_GAME: return CopyWithJSON(amazingGameDefault);
        case ItemTypes.TITLE_GAME: return CopyWithJSON(titleAmazingGameDefault);
        case ItemTypes.PARAGRAPH_GAME: return CopyWithJSON(paragraphAmazingGameDefault);
        case ItemTypes.RULES_AMAZING_GAME: return CopyWithJSON(rulesAmazingGameDefault);
        case ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME: return CopyWithJSON(rulesInlineFleAmazingGameDefault);
        case ItemTypes.DOTATIONS_ONE_IMAGE: return CopyWithJSON(dotationsOneImageAmazingGameDefault);
        case ItemTypes.BUTTON_GAME: return CopyWithJSON(buttonAmazingGameDefault);
        case ItemTypes.RULES_GAME: return CopyWithJSON(rulesGameDefault);
        case ItemTypes.BASIC_BLOC:  return CopyWithJSON(basicBlocGameDefault);
        case ItemTypes.BASIC_BLOC2:  return CopyWithJSON(basicBlocGame2Default);
        case ItemTypes.FLIPBOOK: return CopyWithJSON(flipbook);
        case ItemTypes.FLIPBOOK_WITH_CART: return CopyWithJSON(flipbookWithCart);
        case ItemTypes.HEADER_BOOK: return CopyWithJSON(headerFlipbook);
        case ItemTypes.HEADER_CART_BOOK: return CopyWithJSON(headerCartFlipbook);
        case ItemTypes.FOOTER_BOOK: return CopyWithJSON(footerFlipbook);
        case ItemTypes.TOP_HOMEPAGE_BOOK: return CopyWithJSON(homeFlipbook);
        case ItemTypes.POSTCARD_BOOK: return CopyWithJSON(postCardFlipbook);
        case ItemTypes.RECAP_BLOCK_BOOK: return CopyWithJSON(recapBlockFlipbook);
        case ItemTypes.RESULTS_BOOK: return CopyWithJSON(resultFlipbook);
        case ItemTypes.EMAIL_SUCCESS_BOOK: return CopyWithJSON(emailSuccessFlipbook);
        case ItemTypes.BANNER_LINK_BOOK: return CopyWithJSON(bannerLinkFlipbook);
        case ItemTypes.OFFER_SECTION_TITLE: return CopyWithJSON(OfferSectionTitle);
        case ItemTypes.OFFER_FRONT_PAGE: return CopyWithJSON(OfferFrontPage);
        case ItemTypes.OFFER_ABOUT_SLIDE: return CopyWithJSON(OfferAboutSlide);
        case ItemTypes.OFFER_TRIPLE_BLOCK_LIST: return CopyWithJSON(OfferTripleBlockList);
        case ItemTypes.OFFER_TRIPLE_BLOCK: return CopyWithJSON(OfferTripleBlock);
        case ItemTypes.OFFER_TRIPLE_BLOCK_TEXT: return CopyWithJSON(OfferTripleBlockText);
        case ItemTypes.OFFER_TEXT_WITH_IMAGE: return CopyWithJSON(OfferTextWithImage);
        case ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE: return CopyWithJSON(OfferTextWithImagesSimple);
        case ItemTypes.OFFER_DEVIS: return CopyWithJSON(OfferDevis);
        case ItemTypes.CMS_SLIDER: return CopyWithJSON(SliderTop);
        case ItemTypes.CMS_SLIDER_INSTAGRAM: return CopyWithJSON(SliderInstagram);
        case ItemTypes.CMS_SLIDER_IMAGE: return CopyWithJSON(SliderImage);
        case ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA: return CopyWithJSON(SliderPresentationMedia);
        case ItemTypes.CMS_SLIDER_MOMENTS: return CopyWithJSON(SliderMoments);
        case ItemTypes.CMS_GRID_IMAGE: return CopyWithJSON(GridImage);
        case ItemTypes.CMS_SLIDER_ABOUT: return CopyWithJSON(SliderAbout);
        case ItemTypes.CMS_LIST_CATEGORIES: return CopyWithJSON(ListCategories);
        case ItemTypes.CMS_SLIDER_BY_TAB: return CopyWithJSON(SliderByTab);
        case ItemTypes.CMS_TEXT_MEDIA: return CopyWithJSON(BlocTextMedia);
        case ItemTypes.CMS_BANNER_MEDIA: return CopyWithJSON(BannerMedia);
        case ItemTypes.CMS_SECTION_DESCRIPTION_PAGE: return CopyWithJSON(SectionDescriptionPage);
        case ItemTypes.CMS_SECTION_MANIFEST_BLOCK: return CopyWithJSON(SectionManifestBlock);
        case ItemTypes.CMS_CATEGORIES_HOME: return CopyWithJSON(CategoriesHome);
        case ItemTypes.CMS_COLLECTIONS_HOME: return CopyWithJSON(CollectionsHome);
        case ItemTypes.CMS_SLIDER_PRODUCTS_HOME: return CopyWithJSON(SliderProductsHome);
        case ItemTypes.CMS_BLOC_STORELOCATOR_HOME: return CopyWithJSON(BlocHomeStorelocator);
        case ItemTypes.CMS_ENTETE: return CopyWithJSON(Entete);
        case ItemTypes.CMS_TOP_IMAGE_FULLSCREEN: return CopyWithJSON(TopImageFullscreen);
        case ItemTypes.CMS_SLOGAN: return CopyWithJSON(Slogan);
        case ItemTypes.CMS_TITLE_TEXT: return CopyWithJSON(TitleText);
        case ItemTypes.CMS_SEPARATOR: return CopyWithJSON(Separator);
        default: return null;
    }
};

export const getDefaultConfig = (type) => {
    switch(type) {
        case Pages.WORDPRESS:
            return {
                elements: [
                    { 
                        type: ItemTypes.TEXT, 
                        key: 'title', 
                        data: getDefaultData(ItemTypes.TEXT, 'Votre titre ici')
                    }, { 
                        type: ItemTypes.TEXT_HTML, 
                        key:  'content', 
                        data: getDefaultData(ItemTypes.TEXT_HTML, "Vous retrouvez ici le contenu de l'article. Vous avez à votre droite un formulaire pour le modifier.")
                    }
                ]
            };
        case Pages.FACEBOOK:
            return {
                elements: [
                    { 
                        type: ItemTypes.IMAGE, 
                        key: 'image', 
                        hide: false,
                        data: getDefaultData(ItemTypes.IMAGE)
                    }, { 
                        type: ItemTypes.TEXT, 
                        key: 'link',
                        hide: true,
                        data: getDefaultData(ItemTypes.TEXT, '')
                    }, { 
                        type: ItemTypes.TEXT, 
                        key:  'content', 
                        data: getDefaultData(ItemTypes.TEXT, "Vous retrouvez ici le contenu du post. Vous avez à votre droite un formulaire pour le modifier.")
                    }
                ]
            };
        default: 
            return null;
    }
};

export const getDefaultTextarea = (name, value, html, color = null, backgroundColor = null) => ({
    type: EditableTypes.LONG_TEXT,
    name,
    id: uuidv4(),
    inputs: {
        value: {
            type: html ? FormInputTypes.WYSIWYG : FormInputTypes.TEXTAREA,
            value
        },
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
        backgroundColor: {
            type: FormInputTypes.COLOR,
            value: backgroundColor,
            label: "Couleur de fond"
        },
    }
});

export const getDefaultSlide = (img = "https://via.placeholder.com/1920x720/F0F7FA/C5DEEC", subtitle = "Lorem ipsum", title = "LOREM IPSUM", text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, lorem nec faucibus luctus.") => ({
    subtitle: "Subtitle",
    text: "Text",
    id: 0,
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        title: getDefaultText("Titre", title, 18, "#fff"),
        subtitle: getDefaultText("Sous-titre", subtitle, 72, "#fff"),
        text: getDefaultText("Texte", text, 22, "#fff")
    }
});

export const getDefaultButton = (name, value, size, color, backgroundColor = null, borderWidth = null, borderColor = null, borderRadius = null) => ({
    type: EditableTypes.SHORT_TEXT,
    name,
    id: uuidv4(),
    inputs: {
        value: {
            type: FormInputTypes.TEXT,
            value
        },
        size: {
            type: FormInputTypes.SLIDER,
            value: size,
            params: {max: 100, min: 8, step: 2},
        },
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
        backgroundColor: {
            type: FormInputTypes.COLOR,
            value: backgroundColor,
            label: "Couleur de fond"
        },
        border: {
            type: FormInputTypes.GROUP,
            label: "Border",
            inputs: {
                borderColor: {
                    type: FormInputTypes.COLOR,
                    value: borderColor,
                    label: "Couleur de la bordure"
                },
                borderWidth: {
                    type: FormInputTypes.SLIDER,
                    value: borderWidth,
                    label: "Largeur de la bordure :",
                    params: {max: 5, min: 0, step: 1}
                },
                borderRadius: {
                    type: FormInputTypes.SLIDER,
                    value: borderRadius,
                    label: "Arrondi des angles :",
                    params: {max: 50, min: 0, step: 1}
                }
            }
        },
    },
});

export const getDefaultLink = (name, value, link, size, color, backgroundColor) => ({
    type: EditableTypes.SHORT_TEXT,
    name,
    id: uuidv4(),
    inputs: {
        value: {
            type: FormInputTypes.TEXT,
            value
        },
        link:{
            type: FormInputTypes.LINK,
            link
        },
        size: {
            type: FormInputTypes.SLIDER,
            value: size,
            params: {max: 100, min: 8, step: 2},
        },
        color: {
            type: FormInputTypes.COLOR,
            value: color
        },
        backgroundColor: {
            type: FormInputTypes.COLOR,
            value: backgroundColor
        }
    },
});

// GET DEFAULT INPUT
export const getInputs = (name, inputs) => ({
    name,
    id: uuidv4(),
    inputs
});

const textDefault = (value = "Lorem ipsum dolor sit amet, consectetur adipisicing elit.", html) => ({
    paddingTop: 95,
    paddingBottom: 90,
    blocks: {
        content: getDefaultTextarea("Content", value, html),
    }
});

const imageDefault = (value = "Lorem ipsum dolor sit amet, consectetur adipisicing elit.") => ({
    paddingTop: 95,
    paddingBottom: 90,
    name: "Image",
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: null
                }
            }
        }
    }
});