import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultCategorieHome } from '../../helpersCms';

export default {
    name: 'Catégories mises en avant',
    inputs: {
        value: {
            type: FormInputTypes.TEXT,
            label: "Titre de la section",
            value: "Catégories"
        },
    },
    blocks:{
        categories:{
            title: "Catégorie",
            name: "Catégorie",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_CATEGORIES_HOME,
            buttonAdd: 'une catégorie',
            limit: 3,
            config: [ 
                getDefaultCategorieHome()
            ]
        }
    }
};