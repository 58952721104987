import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';

import CardCustom from '../../../layouts/Card/CardCustom';
import CardContentDetails from '../../../layouts/Card/cardContent/CardContentDetails';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import formContentAdd from './config/formContentAdd.config';
import * as moment from "moment";

import { Grid } from '@material-ui/core';

import InputBuilder from '../../../ui/form/InputBuilder';

import { ROUTE_CONTENU_LIST, ROUTE_HOME } from '../../../../js/constants/route-names';
import { eventService } from '../../../../js/services/event.service';
import { 
    ADD_CONTENT,
    ADD_CONTENT_DATA,
} from '../../../../queries/contents';
import { GET_ATTR_GROUPE_WITH_ATTRIBUTES } from '../../../../queries/attributes';
import { GET_CONTENT_CATEGORIES } from '../../../../queries/content_categories';
import request from '../../../../js/utils/fetch';
import { hasRights } from '../../../../js/utils/rights';
import { CONTENTS, CONTENTS_CONTENTS, CREATE} from '../../../../js/constants/constant-rights';

class ContentAdd extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            groupAttribut: '',
            customAttributes: [],
            attributesSelected: [],
            metaAttributes: [],
            imageAttributes: [],
            attributes : [],
            maxImageNumber : 0,
            imageSrc: [],
            contentId: this.props.history.location?.state?.productId,
            sku: null,
            title: '',
            categories: [],
            categoriesData: [],
            nbCatalog: 0,
            description:'',
            status:'',
            metaTitle: '',
            metaDesc: '',
            openForm: false,
            allGroups: [],
            ready: false,
            errors: {},
            secondErrors: {},
            seeErrors: false
        }
        this.typingTimer = null;
        this.typeTesting = 'product';
    }

    componentDidMount() {
        const getRights = hasRights(CONTENTS, CONTENTS_CONTENTS, CREATE)
        
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.initProduct();
    }
    
    handleToggleDrawer = (drawer) => {
        if(drawer === 'form'){
            this.setState({ 
                openForm : !this.state.openForm,
            });
        }
        this.setState({ 
            seeErrors: false
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    
    doneTyping = (stateName) => {
        if(this.state.sku){
            request(`${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.sku}`, 'get').then(
                (data) => {
                    if(data.success){
                        eventService.fire({stateName: 'sku', errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.'});
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        if(stateName === 'sku'){
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {this.doneTyping(stateName)}, 500);
        }
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else{
            this.setState({
                [stateName]: value,
            }, async () => {
                if (stateName === 'groupAttribut') {
                    await this.prepareAttributes();
                    this.prepareAttributeValues();
                }
            });
        }
        if(stateName === "sku")
            this.checkIdentifier(stateName);
    };

    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    resetState() {
        this.setState({
            imageSrc: [],
            categories: [],
            errors: {}
        });
    }


    handleCancel = () => {
        this.resetState();
        this.initProduct();
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    copy(array) {
        let newArray = [];
        
        for (let elem of array)
            newArray.push(Object.assign({}, elem));

        return newArray;
    }

    getAttributeTranslatedValue = (id, lang) => {
        if (!this.state.attributes)
            return null;

        let attribute = this.state.attributes.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };

    saveAttributes = (content) => {
        return new Promise(async (resolve, reject) => {
            let attributes = this.state.isSystemAttributes.concat(this.state.customAttributes);
            let getContentData = [];
            for (let attribute of attributes) {
                for (let locale of this.props.locales) { 
                    let formValue   = this.state[locale.node.code][attribute.node.identifier];
                    let isMedia     = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';

                    if (formValue && isMedia) {
                        isMedia     = true;
                    }
                    if (formValue) {
                        let variables = {
                            "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                            "content": content, 
                            "attribute": attribute.node.id,
                            "locale": locale.node.id
                        };

                        if (isMedia){
                            variables.media = formValue.id;
                        }
                            
                        
                        if (!isMedia)
                            if (attribute.node.attributeType.input !== 'select'){
                                variables.value = formValue;
                            }
                        let resultMutation = await this.props.client.mutate({
                            mutation: ADD_CONTENT_DATA,
                            variables
                        })
                        getContentData.push(resultMutation.data.createContentData.contentData)
                    }
                }
            }

            resolve(getContentData);
        });
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        await this.initProduct();

        this.props.snack(ALERT_SUCCESS, 'Contenu ajouté !');

        this.handleToggleDrawer('form');
        this.resetState();
        
        this.props.stopLoading();

        this.goTo(ROUTE_CONTENU_LIST);
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    handleSecondFormError = (stateName, error) => {
        let secondErrors = this.state.secondErrors;

        secondErrors[stateName] = error;

        this.setState({ secondErrors })
    }

    hasErrors = (formError) => {
        if (formError === 'firstForm'){
            if (this.state.errors) {
                for (let error in this.state.errors) {
                    if (this.state.errors[error])
                        return true;
                }
            }
        }else{
            if (this.state.secondErrors) {
                for (let error in this.state.secondErrors) {
                    if (this.state.secondErrors[error])
                        return true;
                }
            }
        }
        

        return false;
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors('firstForm')) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }

            
            this.props.startLoading();
            const ADD_CONTENT_RESULT = await this.props.client.mutate({
                mutation: ADD_CONTENT,
                variables: {
                    'sku': this.state.sku,
                    'attributeGroup': this.state.groupAttribut,
                    'contentCategories': this.state.categories.map(e => e.id),
                    'createdAt': moment().format('YYYY-MM-DD'),
                    'updatedAt': moment().format('YYYY-MM-DD'),
                    'status': this.state.status,
                    // 'superAttribute': this.state.isVariant ? this.state.variantsValidated.attributes.map(e => e.node.id) : [],
                }
            });

            await this.saveAttributes(ADD_CONTENT_RESULT.data.createContent.content.id);
            this.handleSuccess();
        } catch(e) {
            this.handleError(e);
        }
    };

    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);  
    }

    render() {
        const { sku, status } = this.state;

        const selectLang = {
            type: 'select',
            label: 'Langue',
            helper: 'Langue',
            required: false,
            stateName: 'currentLang',
            value: this.props.locales.map((locale) => {
                return(
                    {
                        value: locale.node.code,
                        label: locale.node.libelle
                    }
                )
            })
        };

        return (
            <>
                <div style={{width: `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))`, marginTop: 16, marginLeft:16, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                    <Grid container justify={'flex-end'}>
                        <Grid item xs={5}>
                            <Grid container justify={'flex-end'}>
                                <InputBuilder value={this.state.currentLang} input={selectLang} stateCallback={this.handleLang} /> 
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <CardCustom style={{width: `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))`, marginTop: 8, marginLeft:16, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                    {
                        <CardContentDetails 
                            sku={sku}
                            content={{ status }} 
                            routeContent={() => this.goTo(ROUTE_CONTENU_LIST)} 
                            categories={this.state.categories} 
                            toggleDrawer={this.handleToggleDrawer} 
                            openForm={this.state.openForm}
                            imageAttributes={this.state.imageAttributes}
                            customAttributes={this.state.customAttributes}
                            currentLang={this.state.currentLang}
                            allState={this.state}
                            locales={this.props.locales}
                            creation={true}
                        />
                    }
                    
                    <LayoutBuilder 
                        validateButton={true} 
                        //handleCancel={this.handleCancel}
                        opened={this.state.openForm} 
                        forClose={() => {this.goTo(ROUTE_CONTENU_LIST)}} 
                        handlerMutation={this.handlerMutation} 
                        icomoon={'ico-ajouter-produit'}
                        //noCancel={true}
                        dataLayout={formContentAdd(
                            this.state.currentLang, 
                            this.state.categoriesData, 
                            this.state.customAttributes, 
                            this.state.metaAttributes, 
                            this.state.imageAttributes,
                            this.state.categories, 
                            this.state.allGroups,
                            this.state.errors,
                            this.state.seeErrors,
                            this.handleMediaPicker,
                        )} 
                        handleButtonGroupChange={this.handleButtonGroupChange}
                        allState={this.state} 
                        stateCallback={this.handleInputChange} 
                        errorCallback={this.handleFormError}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        drawerWidth={this.props.drawerWidth}
                        deleteButton={false} 
                    />
                </CardCustom>
            </>
        );
    }

    prepareAttributes() {
        return new Promise(async (resolve, reject) => {
            let group = this.state.allGroups.find(e => e.node.id === this.state.groupAttribut);

            let isSystemAttributes  = this.props.attributes.content.attributes.edges.filter(e => e.node.isSystem);
            let metaAttributes      = this.props.attributes.content.attributes.edges.filter(e => e.node.isSystem && e.node.identifier.indexOf('meta') > -1);
            let customAttributes    = group.node.attributes.edges.filter(e => !e.node.isSystem);
            let imageAttributes     = isSystemAttributes.filter(e => e.node.attributeType.input === 'image')
                                        .concat(customAttributes.filter(e => e.node.attributeType.input === 'image'));

            this.setState({
                isSystemAttributes,
                metaAttributes,
                customAttributes,
                imageAttributes,
                maxImageNumber: imageAttributes.length,
            }); 

            resolve();
        });
    }

    prepareAttributeValues() {
        for (let locale of this.props.locales) {
            let values = {};

            for (let attribute of this.state.customAttributes) {
                if (attribute.node.attributeType.input === 'select') {
                    if (attribute.node.attributeOptions.edges.length){
                        values[attribute.node.identifier] = attribute.node.attributeOptions.edges[0].node.id
                    }
                }
            }

            this.setState({
                [locale.node.code]: values
            });
        }
    }

    initProduct() {
        this.props.client.query({
            query: GET_ATTR_GROUPE_WITH_ATTRIBUTES,
            fetchPolicy: "no-cache",
            variables : { id: this.state.contentId }
        }).then(GET_ATTR_GROUPE_WITH_ATTRIBUTES_RESULT => {
            this.setState({
                sku: null,
                groupAttribut : GET_ATTR_GROUPE_WITH_ATTRIBUTES_RESULT.data.attributeGroups.edges.find(e => e.node.identifier === 'content').node.id,
                categories: [], // product categories
                allGroups: GET_ATTR_GROUPE_WITH_ATTRIBUTES_RESULT.data.attributeGroups.edges.filter(e => (!e.node.isSystem && e.node.isForContent) || e.node.identifier === 'content'),
                attributes : [],
                status: true
            }, async () => {
                await this.prepareAttributes();
                this.prepareAttributeValues();
                const GET_CONTENT_CATEGORIES_RESULT = await this.props.client.query({
                    query: GET_CONTENT_CATEGORIES,
                    fetchPolicy: "no-cache"
                });
                this.handleToggleDrawer('form');
                this.setState({ categoriesData: GET_CONTENT_CATEGORIES_RESULT.data.contentCategories, ready: true }); // all categories
            });
        });
    }

    goTo = route => this.props.history.push(route);
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ContentAdd)));
