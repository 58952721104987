import React, { useEffect, useRef, useState } from "react";
import Div from '../shared/div';
import styled from 'styled-components';


const DivTitleText = styled(Div)`
    max-width: 1500px;
    width: 90%;
    margin: auto;
    padding: 0;
    padding-top: 15px;
    h3{
        font-size: 23px;
        line-height: 1.2;
        font-family: 'DINBold', sans-serif;
        font-weight: bold;
        margin: 20px 0;
    }
    p{
        font-size: 16px;
        line-height: 1.2;
        margin: 20px 0;
        font-family: 'DinRegular', sans-serif;
    }
    @media screen and (min-width: 768px){
        padding-top: 40px;
        padding-bottom: 30px;
        text-align: center;
        h3{
            font-size: 30px;
        }
        p{
            font-size: 22px;
        }
    }
    @media screen and (min-width: 1024px){
        padding-top: 80px;
        padding-bottom: 58px;
        h3{
            font-size: 41px;
        }
        p{
            font-size: 25px;
        }
    }
`
const TitleText = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    return (
        <Div style={{position: 'relative', background: data.inputs.backgroundColor.value}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <DivTitleText style={{textAlign: data.inputs.centerText.value ? 'center' : 'inherit'}}>
                <h3 style={{color: data.blocks.title.inputs.color.value ? data.blocks.title.inputs.color.value : '#000' }}>{data.blocks.title.inputs.value.value}</h3>
                <p style={{color: data.blocks.title.inputs.color.value ? data.blocks.title.inputs.color.value : '#000' }}>{data.blocks.text.inputs.value.value}</p>
            </DivTitleText>
        </Div>
    );
};

export default TitleText;