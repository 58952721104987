import React from "react";

export default ({ data, preview, component, block, inputCallback, className, type = null, style = null, hover, text = null, onClick = null, link = null }) => {
    let border = null;

    if (data.blocks[block]?.inputs.border)
        border = data.blocks[block].inputs.border;

    let image = data.blocks[block]?.inputs['backgroundImage']?.value;

    return (
        <button 
            href={data.blocks[block].inputs.link?.value}
            className={className} 
            onClick={() => {
                if (inputCallback)
                    inputCallback(component, block);

                if (onClick)
                    onClick();
            }} 
            style={{
                fontSize: data.blocks[block].inputs.size.value,
                color: data.blocks[block].inputs.color.value,
                backgroundColor: data.blocks[block].inputs.backgroundColor.value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                lineHeight: '2',
                borderStyle: 'solid',
                borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                padding: `${data.blocks[block]?.inputs?.paddingVertical?.value ?? 5}px ${data.blocks[block]?.inputs?.paddingHorizontal?.value ?? 10}px`,
                textTransform: data.blocks[block]?.inputs?.textTransform?.value,
                fontWeight: 'normal'
            }}
            type={type}
        >
            { text ? text : data.blocks[block].inputs.value.value }
        </button> 
    );
};