import React from 'react';
import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import * as moment from 'moment';

import "../../../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';

import CardCustom from '../CardCustom';
import notFound from '../../../../assets/images/not-found.png';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import StoreIcon from '@material-ui/icons/Store';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import UpdateIcon from '@material-ui/icons/Update';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ROUTE_PRODUCTS_LIST_DETAIL } from '../../../../js/constants/route-names';
import iconSet from "../../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import StyleIcon from '@material-ui/icons/Style';
import CardProduct from '../../../layouts/Card/cardContent/CardProduct';

const Sku = styled(Typography)``
const BoxCustom = styled(Box)`
    width: auto;
`;
const BoxCustomAttributs = styled(Box)`
    background: ${colors.grey.lighter.hue980};
    width: 100%;
`;
const TitleAttributs = styled(Typography)`
    font-weight: bold;
    color: ${colors.blue.regular};
`;
const TypographyAttributs = styled(Typography)`
    color: ${colors.grey.regular};
    p{
        margin: 0;
    }
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;
const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
    font-weight: bold;
`;
const Title = styled(Typography)`
    font-weight: bold;
`
const Subtitle = styled(Typography)`
    color: ${colors.blue.regular};
    font-weight: bold;
`;
const AttributContainer = styled(Grid)`
    margin-bottom: 16px;
`;
const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`;
const BoxCustomGallery = styled(Box)`
    .image-gallery{
        display: flex;
        justify-content: center;
        align-items: center;
        &.fullscreen-modal{
            .image-gallery-content{
                top: 40%;
                transform: translateY(-50%);
                left: 10%;
            }
        }
    }
    .image-gallery-thumbnails{
        overflow: auto;
    }
    .image-gallery-content {
        width: 100%;
    }
    // .image-gallery-thumbnails .image-gallery-thumbnails-container{
    //     transform: none !important;
    // }
    .image-gallery-slides{
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .image-gallery-slide-wrapper{
        // max-height: 350px;
        min-height: 350px;
        height: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        .image-gallery-swipe{
            height: 100%;
            max-height: 350px;
            .image-gallery-slide{
                display:flex;
                align-items: center;
                justify-content: center;
                max-height: 350px;
                height: 100%;
                overflow: hidden;
                // width: auto;
            }
        }
    }
    .image-gallery-right-nav, .image-gallery-left-nav {
        padding: 0;
    }
    .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
        height: 30px !important;
        width: 30px;
        padding: 0;
        stroke-width: 2;
    }
    .image-gallery-icon{
        opacity: 0;
        color: ${colors.black};
        filter: none;
        height: 40px !important;
        width: 40px;
        background: ${colors.grey.lighter.hue900};
        padding: 0px;
        border-radius: 50%;
    }
    .image-gallery-swipe{
        height: 100%
        .image-gallery-swipe{
            height: 100%
            .image-gallery-slide{
                height: 100%   
            }
        }
    }
    .image-gallery-slide img{
        width: 100%;
        height: auto;
        max-height: 350px !important;
        overflow: hidden;
    }
    .fullscreen{
        .image-gallery-swipe{
            max-height: none !important;
        }
        .image-gallery-slide {
            max-height: none !important;
            img {
                max-height: 60vh !important;
            }
        }
        .image-gallery-fullscreen-button{
            right: -70px;
            opacity: 1 !important;
            svg{
                top: 2px;
                position: relative;
            }
        }
    }
    .image-gallery-slide-wrapper:hover .image-gallery-icon{
        opacity: 0.4;
    }
    .image-gallery-icon:hover{
        opacity: 0.8 !important;
    }
    .image-gallery-fullscreen-button svg{
        height: 20px !important;
    }
    .image-gallery-thumbnail{
        transition: unset;
    }
    .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus{
        border: 2px solid #6eaed18f;
    }
`;

const AccordionCustom = styled(Accordion)`
    box-shadow: none;
    margin-top: 16px;
    width: 100%;
`;
const AccordionSummaryCustom = styled(AccordionSummary)`
    padding: 0;
    border-bottom: 1px solid ${colors.grey.lighter.hue900};
    min-height: 40px !important;
    .MuiAccordionSummary-content{
        margin: 8px 0 !important;
        align-items: center;
    }
`;
const AccordionDetailsCustom = styled(AccordionDetails)`
    padding: 0;
    margin-top: 8px;
`;

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.regular,
    },
    tooltip: {
        backgroundColor: colors.blue.regular,
        fontSize: 14,
        padding: 10,
        textAlign: 'left',
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class CardProductDetails extends React.Component{

    render() {
        const product   = this.props.product;
        const totalImages = this.props.imageAttributes.length;
        let imageLoaded = null;
        let values = [];

        let stock = 0;
        let cart = 0;
        let reappro = 0;
        let realTime = 0;
        if(this.props.datasStocks){
            cart = this.props.datasStocks.cart.value;
            for(let item of this.props.datasStocks.stock){
                stock += item.value;
            }
            for(let item of this.props.datasStocks.reappro){
                reappro += item.value;
            }
            realTime = stock - cart;
        }

        if(this.props.locales){
            for (let locale of this.props.locales) { 
                values.push({
                    code: locale.node.code,
                    name: this.props.allState[locale.node.code]?.product_name?.value ?? null,
                    description: this.props.allState[locale.node.code]?.product_description?.value ?? null,
                    price: this.props.allState[locale.node.code]?.product_price?.value ?? null,
                    ean: this.props.allState[locale.node.code]?.product_ean?.value ?? null,
                });
            }
        }

        const defaultName   = values.find(e => e.name);
        const defaultDesc   = values.find(e => e.description !== '<p><br></p>');
        const defaultPrice  = values.find(e => e.price);
        const defaultEAN    = values.find(e => e.ean);

        const valueName = values.find(e => e.code === this.props.currentLang);
        const valueDesc = values.find(e => e.code === this.props.currentLang && e.description !== '<p><br></p>');

        let map = (attribute, index) => {
            const defaultLabel = attribute.node.translation.translationDatas.edges[0];

            const label = attribute.node.translation.translationDatas.edges.find(
                lang  => lang.node.locale.code === this.props.currentLang
            );

            let values = [];
            
            for (let locale of this.props.locales) { 
                values.push({
                    code: locale.node.code,
                    value: this.props.allState[locale.node.code]?.[attribute.node.identifier]?.value ?? null
                });
            }
            const defaultValue  = values.find(e => attribute.node.attributeType.input === 'file' ? e.value?.data ? e.value?.data ?? null : e.value : e.value);
            const value         = values.find(e => e.code === this.props.currentLang);
            let local = moment.tz.guess();
            const finalAttributeValue = attribute.node.attributeType.input === 'file' 
                ? (value?.value?.file ?? null)
                    ? value.value.file.name
                    : (value?.value?.data ?? null)
                        ? value.value.data
                        : (defaultValue?.value?.file ?? null)
                            ? defaultValue.value.file.name
                            : (defaultValue?.value?.data ?? null)
                                ? defaultValue?.value?.data
                                : defaultValue?.value?.filePath
                : attribute.node.attributeType.input === 'date' 
                    ? value?.value 
                        ? moment.tz(value?.value, local).format('L') 
                        : defaultValue ? moment.tz(defaultValue?.value, local).format('L') ?? null : null
                    : value?.value ?? (defaultValue?.value ?? null);

            let finalAttributeOptionValue   = null;

            if (attribute.node.attributeType.input === 'select') {
                if (finalAttributeValue) {
                    let option = attribute.node.attributeOptions.edges.find(e => e.node.id === finalAttributeValue);
    
                    if (option) {
                        const optionDefaultLabel = option.node.translation.translationDatas.edges[0];
    
                        const optionLabel = option.node.translation.translationDatas.edges.find(
                            lang  => lang.node.locale.code === this.props.currentLang
                        );
    
                        finalAttributeOptionValue = optionLabel?.node.value ?? optionDefaultLabel?.node.value ?? option.node.identifier;
                    }
                }
            }
            return (
                (finalAttributeValue || finalAttributeOptionValue) !== null ?
                    <Grid item lg={6} md={6} sm={6} xs={12} key={`attribute-container-${index}`}>
                        <AttributContainer container direction="row" key={attribute.node.identifier}>
                            <Grid item xs={8} style={{paddingRight: 8}}>
                                <TitleAttributs variant="body2">
                                    { 
                                        label?.node.value 
                                            ?? defaultLabel?.node.value 
                                            ?? attribute.node.identifier 
                                    }
                                </TitleAttributs>
                            </Grid>
                            <Grid item xs={4} style={{paddingRight: 8}}>
                                {
                                    attribute.node.attributeType.input === 'textarea' ?
                                        <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: finalAttributeValue}} />
                                    : 
                                        attribute.node.attributeType.input === 'file' ? 
                                            <a target="__blank" href={`${process.env.REACT_APP_MEDIAS}/${defaultValue.value.file?.name || finalAttributeValue}`}>
                                                <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }}>
                                                    { defaultValue.value.name }
                                                </TypographyAttributs>
                                            </a> 
                                        : 
                                            <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }}>
                                                { attribute.node.attributeType.input === 'select' ? finalAttributeOptionValue : finalAttributeValue }
                                            </TypographyAttributs>
                                }
                            </Grid>
                        </AttributContainer>
                    </Grid>
                : null
            );
        };

        let catalogList = []
        if(this.props.allState?.categoriesCatalog?.length > 0){
            for (let catalog of this.props.allState.categoriesCatalog){
                if (catalog.catalog){
                    if (catalogList.indexOf(catalog.catalog.libelle) === -1){
                        catalogList.push(catalog.catalog.libelle)
                    }
                }
            }
        }
        return (
            <Box pb={1} px={2}>
                <Grid container direction="row" justify="center" spacing={0}>
                    {
                        process.env.REACT_APP_MODE_SPREAD === "hub" ?
                            (
                                <Grid item xs={12} style={{marginBottom: 32}}>
                                    <BoxCustom onClick={this.props.history.goBack} style={{marginBottom: 16}}>
                                        <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                                    </BoxCustom>
                                    <Grid container direction="row" justify="center" spacing={4}>
                                        <Grid item xs={11}>
                                            <Grid container direction="row" justify="center" spacing={0}>
                                                <Grid item xs={3}>
                                                    <CardCustom style={{background: colors.blue.regular, borderRadius: '5px 0 0 5px', borderRight: '1px solid white' }} cardContentStyle={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px'}}>
                                                        <Typography variant="h3" style={{color: 'white', fontSize: 14, lineHeight: '20px'}}>Disponible Real-time</Typography>
                                                        <Typography variant="body1" style={{color: 'white', fontSize: 36, lineHeight: '36px'}}>
                                                            <UpdateIcon /> {realTime}
                                                        </Typography>
                                                    </CardCustom>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <CardCustom style={{background: colors.blue.regular, borderRadius: '0', borderRight: '1px solid white' }} cardContentStyle={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px'}}>
                                                        <Typography variant="h3" style={{color: 'white', fontSize: 14, lineHeight: '20px'}}>Stock</Typography>
                                                        <Typography variant="body1" style={{color: 'white', fontSize: 36, lineHeight: '36px'}}>
                                                            <EqualizerIcon />{stock}
                                                        </Typography>
                                                    </CardCustom>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <CardCustom style={{background: colors.blue.regular, borderRadius: '0', borderRight: '1px solid white' }} cardContentStyle={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px'}}>
                                                        <Typography variant="h3" style={{color: 'white', fontSize: 14, lineHeight: '20px'}}>Réservé</Typography>
                                                        <Typography variant="body1" style={{color: 'white', fontSize: 36, lineHeight: '36px'}}>
                                                            <AddShoppingCartIcon /> {cart}
                                                        </Typography>
                                                    </CardCustom>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <CardCustom style={{background: colors.blue.regular, borderRadius: '0 5px 5px 0'}} cardContentStyle={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px'}}>
                                                        <Typography variant="h3" style={{color: 'white', fontSize: 14, lineHeight: '20px'}}>Réassort</Typography>
                                                        <Typography variant="body1" style={{color: 'white', fontSize: 36, lineHeight: '36px'}}>
                                                            <PlayForWorkIcon />{reappro}
                                                        </Typography>
                                                    </CardCustom>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CardCustom style={{height: '100%'}} cardContentStyle={{padding: 12,  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            {stock === 0 ? 
                                                (<CancelIcon style={{fill: colors.red.regular, width: '1.3em', height: '1.3em'}} />)
                                                : 
                                                (<CheckCircleIcon style={{fill: colors.green.regular, width: '1.3em', height: '1.3em'}} />) 
                                            }
                                            </CardCustom>   
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : null
                    }
                    <Grid item xs={this.props.windowWidth > 1200 ? 6 : 3}>
                        {this.props.type === 'dashboard' ? '' :
                            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                (
                                    <BoxCustom onClick={this.props.history.goBack} style={{marginBottom: 16}}>
                                        <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                                    </BoxCustom>
                                ) : null
                        }
                        <BoxCustomGallery pr={4}>
                            {
                                (() => {
                                    let images = this.props.imageAttributes.map((attribute, index) => {
                                        let values = [];
                                        
                                        for (let locale of this.props.locales) { 
                                            values.push({
                                                code: locale.node.code,
                                                value: this.props.allState[locale.node.code]?.[attribute.node.identifier]?.value ?? null
                                            });
                                        }

                                        const defaultValue  = values.find(e => e.value);
                                        const value         = values.find(e => e.code === this.props.currentLang);

                                        const finalValue = value?.value ?? defaultValue?.value ?? null;
                                        return {
                                            original: 
                                                finalValue ? 
                                                    finalValue.changed ? 
                                                        finalValue.data ? 
                                                            finalValue.data 
                                                        : notFound
                                                    : finalValue.data || finalValue.filePath ? `${process.env.REACT_APP_MEDIAS}/${finalValue.data ? finalValue.data : finalValue.filePath}` : notFound
                                                : index > 0 ? 
                                                    null 
                                                : notFound,

                                            thumbnail: 
                                                finalValue ? 
                                                    finalValue.changed ? 
                                                        finalValue.data ? 
                                                            finalValue.data 
                                                        : notFound
                                                        : finalValue.data || finalValue.filePath ? `${process.env.REACT_APP_MEDIAS}/${finalValue.data ? finalValue.data : finalValue.filePath}` : notFound
                                                : index > 0 ? 
                                                    null 
                                                : notFound,
                                        };
                                    });
                                    imageLoaded = 0;
                                    for(var i = images.length - 1; i >= 0; i--) {
                                        if(images[i].original === null) {
                                            images.splice(i, 1);
                                        }
                                        else if(images[i].original !== notFound){
                                            imageLoaded++;
                                        }
                                    }
                                    
                                    this.props.allState.imagesSelected = images

                                    return <ImageGallery items={images ? images : notFound} infinite={true} showPlayButton={false} />
                                })()
                            }
                        </BoxCustomGallery>

                    </Grid>
                    <Grid item xs={this.props.windowWidth > 1200 ? 6 : 9}>
                        {this.props.type === 'dashboard' ? '' :
                            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            (
                                <Grid container justify="flex-end">
                                    <Box>
                                        <StatusInfo status={product.status}/>
                                    </Box>
                                </Grid>
                            ) : null
                        }
                        <Grid container direction="column" justify="flex-start">
                            <Grid item xs={12}>
                                <Sku variant="body2"><b>Code article :</b> {this.props.sku}</Sku>
                                <Box>
                                    <Typography variant="body1"><b>EAN13 :</b> { valueName?.ean ?? defaultEAN?.ean ?? null }</Typography>
                                </Box>
                                <Box>
                                    <Title variant="h4">{ valueName?.name ?? defaultName?.name ?? null }</Title>
                                </Box>
                                <Box>
                                    <Title variant="h4">{ (valueName?.price ?? defaultPrice?.price ?? null) 
                                        ? (valueName?.price ?? defaultPrice?.price ?? null) + ' €' 
                                        : null 
                                    }</Title>
                                </Box>
                                <Box pt={1} width="auto">
                                    { this.props.creation ? null : 
                                        product.nbCatalog === undefined ? null : product.nbCatalog > 0 ?  
                                        (
                                            <BootstrapTooltip title={catalogList.map((catalogName, index) =>{
                                                return(
                                                    <span key={`CatalogName${index}`} style={{display: 'block'}}>{index + 1} - {catalogName}</span>
                                                )
                                            })} arrow>
                                                <Typography variant="body1" style={{width: "auto", display: "inline-block"}}>Présent dans <SpanColor>{product.nbCatalog} {process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'catalogue' : 'entrepôt'}{product.nbCatalog > 1 ? 's' : ''}</SpanColor></Typography>
                                            </BootstrapTooltip>
                                        ) : (
                                            <Typography variant="body1">Ce produit n'est présent dans<SpanColor> aucun catalogue</SpanColor></Typography>
                                        )
                                    }
                                </Box>
                                {
                                    process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                        <Box display="flex" style={{alignItems: "center"}} pt={1}>
                                            <Box position="relative" display="inline-flex" mr={2}>
                                                <CircularProgressCustom 
                                                variant="determinate" 
                                                thickness={4} 
                                                value={product.statsProduct === undefined 
                                                    ? null 
                                                    : product.statsProduct[this.props.currentLang] 
                                                        ? product.statsProduct[this.props.currentLang] 
                                                        : product.statsProduct[0]
                                                }
                                                size={50}
                                                />
                                                <Box
                                                    top={0}
                                                    left={0}
                                                    bottom={"2px"}
                                                    right={0}
                                                    position="absolute"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Typography variant="body2" component="div" color="textSecondary">
                                                        {`${Math.round(
                                                            product.statsProduct === undefined 
                                                            ? 0 
                                                            : product.statsProduct[this.props.currentLang] !== null && product.statsProduct[this.props.currentLang] !== undefined 
                                                                ? product.statsProduct[this.props.currentLang] 
                                                                : product.statsProduct[0],
                                                            )}%`
                                                        }
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box>
                                                { totalImages ? 
                                                    (
                                                        <Grid container alignItems="center">
                                                            <PhotoLibraryOutlinedIcon fontSize="large" style={{color: colors.grey.regular}}/>
                                                            <Typography variant="body1" style={{marginLeft: '8px'}}><SpanColor>{imageLoaded}</SpanColor>/{totalImages}</Typography>
                                                        </Grid>
                                                    ) : null
                                                }
                                            </Box>
                                        </Box>
                                    : null
                                }

                                {
                                    this.props.allState?.initialVariant?.length > 0 ? 
                                        <Grid container alignItems={"center"} style={{marginTop: 16}}>
                                            <StyleIcon  style={{fill: colors.grey.regular}}/>
                                            <Typography variant="body1" style={{marginLeft: 8}}> 
                                                <SpanColor> {this.props.allState.initialVariant?.length} {`variante${this.props.allState.initialVariant?.length > 1 ? 's' : ''}`}</SpanColor>
                                            </Typography>
                                        </Grid>
                                    : null
                                }
                                
                                <Box pt={3} pb={1}>
                                    <Subtitle variant="body2">Catégories</Subtitle>
                                </Box>
                                <Typography variant="body1">{this.props.categories.map((categorie, index) => {
                                    let allNames = categorie.categoryDatas.edges.filter(e => e.node.attribute.identifier === 'category_name');

                                    const defaultValue  = allNames[0];
                                    const value         = allNames.find(e => e.node.locale.code === this.props.currentLang);

                                    let finalValue = value?.node.value ?? defaultValue?.node.value ?? categorie.libelle;

                                    return `${finalValue}${this.props.categories?.length === index + 1 ? '' : ' / '}`;
                                })}</Typography>
                                <Box pt={4} pb={1}>
                                    <Subtitle variant="body2">Description</Subtitle>
                                </Box>

                                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: valueDesc?.description ?? defaultDesc?.description ?? null }} />

                                {this.props.type === 'dashboard' ?
                                    (
                                        <Grid container>
                                            <Box pt={4}>
                                                <StatusInfo status={product.status}/>
                                            </Box>
                                        </Grid>
                                    ) : ''
                                }

                                {
                                    this.props.selectedProducts?.length > 0 ? (
                                        <Box pt={4}>
                                            <Subtitle variant="body2">Produit{this.props.selectedProducts.length > 1 ? 's' : null} associé{this.props.selectedProducts.length > 1 ? 's' : null} : {this.props.selectedProducts.length}</Subtitle>
                                        </Box>
                                    ) : null
                                }
                            </Grid>
                        </Grid>
                        {
                            this.props.creation
                                ? null 
                                : this.props.canModify && process.env.REACT_APP_MODE_SPREAD !== "hub" ?   
                                    <Grid container direction="row" justify="flex-end">
                                        {this.props.type === 'dashboard' ?
                                            (
                                                <Box>
                                                    <Button text="Voir la fiche produit >" bgColor={colors.green.regular}  onClick={this.props.routeProduct} />
                                                </Box>
                                            ) : 
                                            (
                                                <Box>
                                                    <Button text="Modifier" disabled={this.props.ready ? (this.props.openForm ? this.props.openForm : false) : true} bgColor={colors.green.regular}  onClick={this.props.toggleDrawer} arrow={'right'} shadowcolor={colors.green.darker}/>
                                                </Box>
                                            )
                                        }
                                    </Grid>
                                    : null
                            }
                    </Grid>
                </Grid>
                {
                    this.props.customAttributes && this.props.customAttributes?.length && process.env.REACT_APP_MODE_SPREAD !== "hub"
                        ? (
                            <AccordionCustom defaultExpanded={true} style={{marginTop: 16}}>
                                <AccordionSummaryCustom
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <IcomoonReact iconSet={iconSet} color={colors.blue.lighter.hue300} size={20} icon={'picto-attribut'} />
                                    <Subtitle variant="body1" component="div" colortext={colors.blue.lighter.hue300} fontweighttext={'bold'} style={{marginLeft: '8px'}}>
                                        Attribut{this.props.customAttributes?.length > 1 ? 's' :  ''}
                                    </Subtitle>
                                </AccordionSummaryCustom>
                                <AccordionDetailsCustom>
                                <BoxCustomAttributs mt={2} py={2} px={1}>
                                        <Grid container>
                                            {
                                                this.props.customAttributes 
                                                    ? this.props.customAttributes.filter(e => e.node.attributeType.input !== 'image' && e.node.status).map(
                                                        map) 
                                                    : null
                                            }
                                        </Grid>
                                    </BoxCustomAttributs>
                                </AccordionDetailsCustom>
                            </AccordionCustom>
                        ) : null
                }
                {
                    this.props.allState?.initialVariant?.length > 0 && !this.props.creation ?
                        <Grid container>
                            <AccordionCustom defaultExpanded={false}>
                                <AccordionSummaryCustom
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <IcomoonReact iconSet={iconSet} color={colors.blue.lighter.hue300} size={20} icon={'icon-asset'} />
                                    <Subtitle variant="body1" component="div" colortext={colors.blue.lighter.hue300} fontweighttext={'bold'} style={{marginLeft: '8px'}}>
                                        Variante{this.props.allState.initialVariant.length > 1 ? 's' :  ''} 
                                    </Subtitle>
                                </AccordionSummaryCustom>
                                <AccordionDetailsCustom>
                                    <Grid container>
                                        {
                                            this.props.allState.initialVariant.map((variant) => {
                                                return(
                                                    <Grid item lg={this.props.openForm && this.props.windowWidth > 1500 ? 12 : 4} md={4} sm={6} xs={6} style={{padding: 8}} key={variant.sku}>
                                                        <CardCustom>
                                                            <Grid container>
                                                                <Grid item xs={5}>
                                                                    <Box pr={4}>
                                                                        <Grid container alignItems="center" justify="center" style={{minHeight: 100}}>
                                                                            <img src={variant.image.value ? `${process.env.REACT_APP_MEDIAS}/${variant.image.value.filePath}` : this.props.allState.defaultImageProduct ? `${process.env.REACT_APP_MEDIAS}/${this.props.allState.defaultImageProduct.filePath}` : notFound} style={{maxHeight: 180, maxWidth: '100%'}} alt={'Variant image'} />
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={7} style={{display: 'flex', flexDirection: 'column',}}>
                                                                    <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                        <Typography variant="body2">{variant.sku}</Typography>
                                                                    </Box>
                                                                    {
                                                                        variant.price.value ?
                                                                            <Title variant="body1" style={{marginTop: 8}}>{variant.price.value} €</Title>
                                                                        : null
                                                                    }
                                                                    
                                                                    <Title variant="body2" style={{marginTop: 16}}>Attributs</Title>
                                                                    {
                                                                        variant.allAttr.length > 0 ?
                                                                            variant.allAttr.map((attr, index) => {
                                                                                let getValue = attr.values
                                                                                if (attr.valuesForSelect){
                                                                                    getValue = attr.valuesForSelect.find(e => e.node.id === attr.values)
                                                                                    if (getValue.node.translation.translationDatas.edges.find(e => e.node.locale.code === this.props.currentLang)){
                                                                                        getValue = getValue.node.translation.translationDatas.edges.find(e => e.node.locale.code === this.props.currentLang).node.value
                                                                                    }else{
                                                                                        getValue = getValue.node.identifier
                                                                                    }
                                                                                }
                                                                                return(
                                                                                    <AttributContainer container direction="row" key={`container-attribute-${index}`}>
                                                                                        <Grid item xs={8} style={{paddingRight: 8}}>
                                                                                            <TitleAttributs variant="body2">
                                                                                                {attr.label ?? attr.identifier}
                                                                                            </TitleAttributs>
                                                                                        </Grid>
                                                                                        <Grid item xs={4} style={{paddingRight: 8}}>
                                                                                            {getValue}
                                                                                        </Grid>
                                                                                    </AttributContainer>
                                                                                )
                                                                            })
                                                                        : null
                                                                    }
                                                                    {
                                                                        this.props.goTo ? 
                                                                            <Grid container justify="flex-end">
                                                                                <Button text={'Voir le produit'} arrow={'right'} onClick={() => this.props.goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', variant.idVariant.replace('/api/products/', '')))} />
                                                                            </Grid>
                                                                        : null
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </CardCustom>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </AccordionDetailsCustom>
                            </AccordionCustom>
                        </Grid>
                    : null
                }
                {
                    this.props.allState?.suggestionsProduct?.length > 0 && !this.props.creation ?
                        <Grid container>
                            <AccordionCustom defaultExpanded={false}>
                                <AccordionSummaryCustom
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <IcomoonReact iconSet={iconSet} color={colors.blue.lighter.hue300} size={20} icon={'picto-produit'} />
                                    <Subtitle variant="body1" component="div" colortext={colors.blue.lighter.hue300} fontweighttext={'bold'} style={{marginLeft: '8px'}}>
                                        Suggestion{this.props.allState.suggestionsProduct.length > 1 ? 's' :  ''} 
                                    </Subtitle>
                                </AccordionSummaryCustom>
                                <AccordionDetailsCustom>
                                    <Grid container>
                                        {
                                            this.props.allState.suggestionsProduct.map((suggestion, index) => {
                                                return(
                                                    <Grid item lg={this.props.openForm && this.props.windowWidth > 1500 ? 12 : 4} md={4} sm={6} xs={6} style={{padding: 8}} key={`card-product-suggestion-${index}`}>
                                                        <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} hovercard={true}>
                                                            <CardProduct 
                                                                currentLang={this.props.currentLang} 
                                                                product={suggestion.node} 
                                                                index={index} 
                                                                routeProduct={() => this.props.goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', suggestion.node.id.replace('/api/products/', '')))} 
                                                                windowWidth={this.props.windowWidth}
                                                                minimalSetup={true}
                                                            />
                                                        </CardCustom>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </AccordionDetailsCustom>
                            </AccordionCustom>
                        </Grid>
                    : null
                }
                {
                    process.env.REACT_APP_MODE_SPREAD !== "hub" && product.retailersInfos?.length > 0 ?
                        <Grid container>
                            <AccordionCustom defaultExpanded={false}>
                                <AccordionSummaryCustom
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <IcomoonReact iconSet={iconSet} color={colors.blue.lighter.hue300} size={20} icon={'picto-retailer'} />
                                    <Subtitle variant="body1" component="div" colortext={colors.blue.lighter.hue300} fontweighttext={'bold'} style={{marginLeft: '8px'}}>
                                        Fiche{this.props.allState.suggestionsProduct.length > 1 ? 's' :  ''} produit{this.props.allState.suggestionsProduct.length > 1 ? 's' :  ''} retailers
                                    </Subtitle>
                                </AccordionSummaryCustom>
                                <AccordionDetailsCustom>
                                        {/* <Typography variant="h3" style={{textDecoration: 'underline', marginBottom: 8}}>Fiches produits retailers: </Typography> */}
                                    <Grid container direction="row" style={{marginTop: 8}}>
                                        {
                                            product.retailersInfos.map((retailer) => {
                                                return(
                                                    <Grid item xs={this.props.openForm ? 6 : 3} onClick={() => {window.open(retailer.url, retailer.retailer, `width=960, height=800, top=100, right=100, left=${window.screen.width - 1060}`)}} style={{display: "flex", alignItem:"center", cursor:'pointer', color: colors.grey.regular, padding: '0 8px'}}>
                                                        <CardCustom style={{width: '100%'}} cardContentStyle={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                                            <Grid container direction="row" justify="space-between" alignItems="center">
                                                                <img src={`${process.env.REACT_APP_API_ROOT}/${retailer.retailer_logo}`} style={{maxHeight: 50}}/>
                                                                <Typography variant="h4">{retailer.retailer}</Typography>
                                                            </Grid>
                                                            <Grid container direction="row" justify="space-between" alignItems="center" style={{marginTop: 16}}>
                                                                <OpenInNewIcon />
                                                                <Typography variant="body1">Voir la page</Typography>
                                                            </Grid>
                                                        </CardCustom>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </AccordionDetailsCustom>
                            </AccordionCustom>
                        </Grid>
                    : null
                }
                {
                    process.env.REACT_APP_MODE_SPREAD === "hub" && product.stocksProduct.length > 0 ?
                        <>
                            <Grid container style={{marginTop: 16}}>
                                <Grid item xs={4}>
                                    <Typography variant="h3" style={{textDecoration: 'underline', marginBottom: 8}}>Stock</Typography>
                                    {
                                        product.stocksProduct.map((stock) => {
                                            if (stock.value > 0){
                                                return(
                                                    <Grid container alignItems="center">
                                                        {stock.type === 'store' ?
                                                            <StoreIcon style={{color: colors.grey.regular}}/>
                                                            : 
                                                            <EqualizerIcon style={{color: colors.grey.regular}}/>
                                                        }
                                                        <Typography variant="body1" style={{marginLeft: 4}}>{stock.libelle}: <b><SpanColor>{stock.value}</SpanColor></b></Typography>
                                                    </Grid>
                                                )
                                            }else{
                                                return null
                                            }
                                        })
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h3" style={{textDecoration: 'underline', marginBottom: 8}}>Réapprovisionnement</Typography>
                                    {
                                        this.props.datasStocks.reappro.map((value) => {
                                            let date = moment(value.date);
                                            let diff = date.diff(moment(), 'days') + 1;

                                            return(<Typography variant="body1" style={{marginTop: 4}}>
                                                <SpanColor>{value.value}</SpanColor> unité{value.value > 1 ? 's' : null} le {date.format('DD MMMM YYYY')} { diff > 0 ? `(J+${diff})` : null }
                                            </Typography>)
                                        })
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h3" style={{textDecoration: 'underline', marginBottom: 8}}>Commandes</Typography>
                                    <Typography variant="body1" style={{marginTop: 4}}>Produit dans <SpanColor>{product.commandsProduct.total > 0 ? product.commandsProduct.total : 'aucune'}</SpanColor> {product.commandsProduct.total > 1 ? 'commandes.' : 'commande.'}</Typography>
                                    <Typography variant="body1" style={{marginTop: 4}}><SpanColor>{product.commandsProduct.pending > 0 ? product.commandsProduct.pending : "Aucun"}</SpanColor> {product.commandsProduct.pending > 1 ? 'produits en attente de traitement' : 'produit en attente de préparation'} en préparation.</Typography>
                                    <Typography variant="body1" style={{marginTop: 4}}><SpanColor>{product.commandsProduct.preparation > 0 ? product.commandsProduct.preparation : "Aucun"}</SpanColor> {product.commandsProduct.preparation > 1 ? 'produits en cours de préparation.' : 'produit en cours de préparation.'}</Typography>
                                    <Typography variant="body1" style={{marginTop: 4}}><SpanColor>{product.commandsProduct.shipped > 0 ? product.commandsProduct.shipped : "Aucun"}</SpanColor> {product.commandsProduct.shipped > 1 ? 'produits expédiés.' : 'produit expédié.'}</Typography>
                                </Grid>
                            </Grid>
                        </>
                    : null
                }
            </Box>
        );
    }
}
export default withRouter(CardProductDetails);