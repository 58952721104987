import { gql } from 'apollo-boost';

//----- GAME GIFT
//----- Récupération des cadeaux
/*
 Exemple de variables
  {
    "asset":"/api/assets/1"
  }
*/
export const GET_GAME_GIFTS = gql`
query assetGameGifts($asset: String){
  assetGameGifts(asset: $asset, first: 100){
    edges{
      node{
        id
        product{
          id
          sku
          productDatas{
            edges{
              node{
                id
                attribute{
                  id
                  identifier
                }
                value
              }
            }
          }
        }
        image{
          id
          filePath
        }
        asset{
          id
        }
      }
    }
  }
}
`;
//----- Ajouter un cadeau 
/*
 Exemple de variables
  {
    "product": "api/products/26",
    "image": "api/media-objects/82"
  }
*/
export const ADD_GAME_GIFT = gql`
mutation AddAssetGameGift($product: String!, $image: String!, $asset: String!){
	createAssetGameGift(input: {product:$product, image:$image, asset:$asset}){
  	assetGameGift{
      id
      product{
        id
        sku
      }
      image{
        id
      }
    }
  }
}`;
//----- Modifier un cadeau  
/*
 Exemple de variables
 {
    "id": "/api/asset-game-gifts/1",
    "product": "api/products/26",
    "image": "api/media-objects/82"
  }
*/
export const UPDATE_GAME_GIFT = gql`
mutation UpdateAssetGameGift($id: ID!, $product: String, $image: String, $asset: String!){
	updateAssetGameGift(input: {id:$id, product:$product, image:$image, asset:$asset}){
  	assetGameGift{
      id
      product{
        id
        sku
      }
      image{
        id
      }
    }
  }
}`;
//----- Supprimer un cadeau 
/*
 Exemple de variables
 {
  "id": "/api/asset-game-gifts/1"
  }
*/
export const DELETE_GAME_GIFT = gql`
mutation DeleteAssetGameGift($id: ID!){
	deleteAssetGameGift(input: {id:$id}){
  	assetGameGift{
      id
    }
  }
}`;
//----- END OF GAME GIFT

//----- GAME INSTANT
//----- Récupération des instants
/*
 Exemple de variables
  {
    "asset":"/api/assets/1"
  }
*/
export const GET_GAME_INSTANTS = gql`
query assetGameInstants($asset: String){
  assetGameInstants(asset: $asset, first: 150) {
    edges{
      node{
        id
        assetGameGift {
          id
          product{
            id
            sku
          }
          image{
            id
          }
        }
        winningAt
        isWin
        assetGamePlay {
          id
        }
        asset {
          id
        }
      }
    }
  }
}
`;
//----- Ajouter un instant 
/*
 Exemple de variables
  {
    "assetGameGift": "/api/asset-game-gifts/2",
    "asset": "api/assets/5",
    "winningAt": "2020-08-01 12:34:35"
  }
*/
export const ADD_GAME_INSTANT = gql`
mutation AddAssetGameInstant($assetGameGift: String!, $winningAt: String!, $asset:String!, $isWin:Boolean!){
	createAssetGameInstant(input: {assetGameGift:$assetGameGift, winningAt:$winningAt, asset:$asset isWin:$isWin}){
  	assetGameInstant{
      id
    }
  }
}`;
//----- Modifier un instant 
/*
 Exemple de variables
  {
    "id" : "/api/asset-game-instants/1",
    "assetGameGift": "/api/asset-game-gifts/2",
    "asset": "api/assets/5",
    "winningAt": "2020-08-01 12:34:35"
  }
*/
export const UPDATE_GAME_INSTANT = gql`
mutation UpdateAssetGameInstant($id: ID!, $assetGameGift: String, $winningAt: String, $asset:String, $isWin:Boolean!){
	updateAssetGameInstant(input: {id:$id, assetGameGift:$assetGameGift, winningAt:$winningAt, asset:$asset, isWin:$isWin}){
  	assetGameInstant{
      id
    }
  }
}`;
//----- Supprimer un instant 
/*
 Exemple de variables
  {
    "id" : "/api/asset-game-instants/1"
  }
*/
export const DELETE_GAME_INSTANT = gql`
mutation DeleteAssetGameInstant($id: ID!){
	deleteAssetGameInstant(input: {id:$id}){
  	assetGameInstant{
      id
    }
  }
}`;
//----- END OF GAME INSTANT

//----- GAME PAGE
//----- Récupération des pages
/*
 Exemple de variables
  {
    "id":"/api/assets/1"
  }
*/
export const GET_GAME_PAGES = gql`
query assetGamePages($asset: String){
  assetGamePages(asset:$asset){
    edges{
      node{
        id
        identifier
        asset {
          id
        }
        assetGamePageType{
          id
          identifier
          image
        }
        libelle
        status
        content
        createdAt
        updatedAt
      }
    }
  }
}
`;
//----- Ajouter une page 
/*
 Exemple de variables
  {
    "identifier": "test",
    "asset": "api/assets/5",
    "assetGamePageType": "api/asset-game-page-types/7",
    "libelle": "T.E.S.T",
    "status":"1",
    "content":"demo",
    "createdAt":"2020-07-21"
  }
*/
export const ADD_GAME_PAGE = gql`
mutation AddAssetGamePage($identifier: String!, $asset: String!, $assetGamePageType:String!, $libelle:String!, $status:String!, $content:String, $createdAt:String!, $updatedAt:String){
	createAssetGamePage(input: {identifier:$identifier, asset:$asset, assetGamePageType:$assetGamePageType, libelle:$libelle, status:$status, content:$content, createdAt:$createdAt, updatedAt:$updatedAt}){
    assetGamePage{
      id
      identifier
      asset {
        id
      }
      assetGamePageType{
        id
        identifier
      }
      libelle
      status
      content
      createdAt
      updatedAt
    }
  }
}`;
//----- Modifier une page 
/*
 Exemple de variables
  {
    "id":"/api/asset-game-pages/1",
    "identifier": "test",
    "asset": "api/assets/5",
    "assetGamePageType": "api/asset-game-page-types/7",
    "libelle": "T.E.S.T edited",
    "status":"0",
    "content":"demo",
    "createdAt":"2020-07-21",
    "updatedAt":"2020-07-21"
  }
*/
export const UPDATE_GAME_PAGE = gql`
mutation UpdateAssetGamePage($id: ID!, $identifier: String, $asset: String, $assetGamePageType:String, $libelle:String, $status:String, $content:String, $htmlContent: String, $createdAt:String, $updatedAt:String){
	updateAssetGamePage(input: {id:$id, identifier:$identifier, asset:$asset, assetGamePageType:$assetGamePageType, libelle:$libelle, status:$status, content:$content, htmlContent:$htmlContent, createdAt:$createdAt, updatedAt:$updatedAt}){
    assetGamePage{
      id
      identifier
      asset {
        id
      }
      assetGamePageType{
        id
        identifier
      }
      libelle
      status
      content
      htmlContent
      createdAt
      updatedAt
    }
  }
}`;
//----- Supprimer une page 
/*
 Exemple de variables
  {
    "id":"/api/asset-game-pages/1"
  }
*/
export const DELETE_GAME_PAGE = gql`
mutation DeleteAssetGamePage($id: ID!){
	deleteAssetGamePage(input: {id:$id}){
  	assetGamePage{
        id
    }
  }
}`;
//----- END OF GAME PAGE

//----- GAME PLAYER

//----- Récupération des cursors des joueurs
/*
Exemple de variables
  {
    "id":"/api/assets/3"
  }
*/
export const GET_GAME_PLAYERS_CURSORS = gql`
query assetGamePlayers($asset: String, $isValidated: Boolean, $boolAsLastPlayed: Boolean){
  assetGamePlayers(first: 100000000,asset:$asset, isValidated: $isValidated, exists:{lastPlayed:$boolAsLastPlayed}){
    totalCount
    edges{
      cursor
    }
  }
}
`;

//----- Récupération des joueurs
/*
 Exemple de variables
  {
    "id":"/api/assets/1"
  }
*/
export const GET_GAME_PLAYERS = gql`
query assetGamePlayers($nbperpage: Int, $cursor: String, $asset: String, $isValidated: Boolean, $boolAsLastPlayed: Boolean){
  assetGamePlayers(first: $nbperpage, after: $cursor, asset:$asset, isValidated: $isValidated, exists:{lastPlayed:$boolAsLastPlayed}){
    totalCount
    edges{
      node{
        id
        username
        uuid
        consent
        createdAt
        asset {
          id
        }
        assetGamePlays{
          edges {
            node {
              id
              isWin
              playedAt
            }
          }
        }
      }
    }
  }
}
`;
//----- END OF GAME PLAYER

//----- GAME TYPE
//----- Récupération des types de jeux
export const GET_GAME_TYPE = gql`
{
  assetGameTypes{
    edges{
      node{
        id
        libelle
        identifier
        logo
        description
        assets {
          edges {
            node {
              id
              assetType{
                localeRequired
                multiLocale
                hasCatalog
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_GAME_PLAYS = gql`
  query assetGamePlays($asset: String){
    assetGamePlays(asset:$asset, first: 100000){
      totalCount
      edges{
        node{
          playedAt
          isPlayed
          assetGamePlayer{
            id
          }
        }
      }
    }
  }
`

//----- Récupération des instants gagnants + joueurs => C U R S O R
/*
Exemple de variables
  {
    "asset":"/api/assets/3",
    "isWin": true
  }
*/
export const GET_WIN_INSTANT_BY_ASSET_CURSOR = gql`
query getCursorIsWinInstant($asset: String, $isWin:Boolean){
  assetGameInstants(first: 10000, asset:$asset, isWin:$isWin){
    totalCount
    edges{
      cursor
    }
  }
}
`

//----- Récupération des instants gagnants + joueurs
/*
Exemple de variables
  {
    "asset":"/api/assets/3",
    "isWin": true
  }
*/
export const GET_WIN_INSTANT_BY_ASSET = gql`
query getIsWinInstant($nbperpage: Int, $cursor: String, $asset: String, $isWin:Boolean){
  assetGameInstants(first: $nbperpage, after: $cursor,asset:$asset, isWin:$isWin){
    totalCount
    edges{
      node{
        isWin 
        assetGameGift{
          image{
            filePath
          }
          product{
            id
            sku
            productDatas{
              edges{
                node{
                  id
                  attribute{
                    id
                    identifier
                  }
                  value
                }
              }
            }
          }
        }
        assetGamePlay{
          assetGamePlayer{
          	username
            lastPlayed
        	}
        }
      }
    }
  }
}
`
