import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';

const ImageContainer = styled.div`
    height : ${props=> props.height};
    background-size : 100%;
    background-repeat : no-repeat;
    background-position : center;
    width:100%;
    background-image:url(${props => props.imageUrl})
`
function CellImage(props){
    return(
        <TableCell 
            style={{
                width : props.width ? props.width :'auto',
                padding : '4px 16px'
            }}
        >
            {props.imageHeight ? 
                <ImageContainer height={props.imageHeight} imageUrl={props.value ? `${process.env.REACT_APP_MEDIAS}/${props.value}` : props.noImage} /> 
            :
                <img src={props.value ? `${process.env.REACT_APP_MEDIAS}/${props.value}` : props.noImage} style={{width:'100%'}}/>
            }
        </TableCell>
    )
}
export default CellImage;