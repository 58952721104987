import React, { useEffect, useState } from "react";
import Div from '../shared/div';
import H2 from '../shared/h2';
import H4 from '../shared/h4';
import P from '../shared/p';
import Button from '../shared/a';
import Img from '../shared/img';
import styled from 'styled-components';
import { VIDEOS } from '../../../../../../../js/constants/medias-types';

const DivBlocTextMedia = styled(Div)`
    padding: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    &>div{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
        &>div{
            padding: 24px;
            dipslay: flex;
            justify-content: center;
            flex-direction: row;
            width: 100%;
            max-width: 50%;
        }
    }
    .container-button{
        display: flex;
        justify-content: center;
        flex-direction: row;
        a{
            margin: 20px;
        }
    }
    h2{
        max-width: 100%;
        width: 600px;
        font-family: 'DINOffc-bold', sans-serif;
        margin-bottom: 16px;
    }
    h4{
        max-width: 100%;
        width: 600px;
        font-size: 31px;
        line-height: 37px;
        text-transform: uppercase;
        font-family: 'DinRegular', sans-serif;
        margin-bottom: 25px;
    }
    div{
        font-family: 'Univers',sans-serif;
        a{
            font-family: 'DINOffc-bold', sans-serif;
        }
        &.center{
            max-width: 100%;
            width: 600px;
            p{
                text-align: center;
                width: 100%;
            }
        }
    }
    p{
        max-width: 100%;
        width: 600px;
        font-size: 16px;
        line-height: 22px;
        font-family: 'Univers', sans-serif;
    }
    @media screen and (min-width: 1250px){
        h2{
            font-size: 61px;
            line-height: 73px;
        }
    }
    @media screen and (max-width: 1250px){
        h2{
            font-size: 50px;
            line-height: 60px;
            margin-bottom: 8px;
        }
        h4{
            font-size: 25px;
            line-height: 32px;
            margin-bottom: 12px;
        }
        p{
            font-size: 14px;
            line-height: 20px;
        }
        .container-button{
            a{
                margin: 10px;
                font-size: 16px;
                line-height: 20px;
                padding: 8px 16px;
            }
        }
    }
    @media screen and (max-width: 1023px){
        padding: 20px 5%;
    }
    @media screen and (max-width: 1000px){
        &>div{
            display: flex;
            flex-direction: column;
            &>div{
                max-width: 100%;
            }
        }
    }
    @media screen and (max-width: 800px){
        h2{
            font-size: 36px;
            line-height: 42px;
        }
        h4{
            font-size: 20px;
            line-height: 28px;
        }
    }

    @media screen and (max-width: 400px){
        h2{
            font-size: 28px;
            line-height: 36px;
        }
        h4{
            font-size: 18px;
            line-height: 24px;
        }
    }
`;

const BlocTextMedia = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    let image = data.inputs['backgroundImage'].value;

    let isVideo = VIDEOS.includes(data.blocks.media.inputs.value.value.type) ? true : false;
    let border = null;
    console.log(data.blocks.link?.inputs?.border)
    return (
        <Div 
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: 'relative',
            }} 
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)}
        >
            { hover && !preview && props.children }
            <DivBlocTextMedia>
                <Div style={{flexDirection: data.inputs['positionMedia'].value === 'top' || data.inputs['positionMedia'].value === 'bottom' ? 'column' : null, maxWidth: data.inputs['positionMedia'].value === 'top' || data.inputs['positionMedia'].value === 'bottom' ? 1040 : null}}>
                    <Div style={{order: data.inputs['positionMedia'].value === 'right' || data.inputs['positionMedia'].value === 'bottom' ? '0' : '2', maxWidth: data.inputs['positionMedia'].value === 'top' || data.inputs['positionMedia'].value === 'bottom' ? '100%' : null}}>
                        {
                            data.blocks.title.inputs.value.value ? (
                                <H2 
                                    color={data.blocks.title.inputs.color.value} 
                                    className="uppercase"
                                    style={{textAlign: data.inputs['centerText'].value ? 'center' : null, marginLeft: data.inputs['centerText'].value ? 'auto' : null, marginRight: data.inputs['centerText'].value ? 'auto' : null}}
                                >
                                    {data.blocks.title.inputs.value.value}
                                </H2>
                            ) : null
                        }
                        {data.blocks.subtitle.inputs.value.value ? (
                            <H4 
                                color={data.blocks.subtitle.inputs.color.value}
                                style={{textAlign: data.inputs['centerText'].value ? 'center' : null, marginLeft: data.inputs['centerText'].value ? 'auto' : null, marginRight: data.inputs['centerText'].value ? 'auto' : null}}
                            >
                                {data.blocks.subtitle.inputs.value.value}
                            </H4>
                        ) : null}
                        {
                            data.blocks.text.inputs.value.value ? (
                                <Div
                                    className={data.inputs['centerText'].value ? 'center' : null}
                                    color={data.blocks.text.inputs.color.value}
                                    style={{textAlign: data.inputs['centerText'].value ? 'center' : null, marginLeft: data.inputs['centerText'].value ? 'auto' : null, marginRight: data.inputs['centerText'].value ? 'auto' : null}}
                                    dangerouslySetInnerHTML={{__html: data.blocks.text.inputs.value.value}}
                                />
                            ) : null
                        }
                        <Div className="container-button" style={{justifyContent: data.inputs['centerButton'].value ? 'center' : 'flex-start'}}>
                            {
                                data.blocks.link.inputs.link.value && data.blocks.link.inputs.value.value ? (
                                    <Button 
                                        href={data.blocks.link.inputs.link.value} 
                                        backgroundColor={data.blocks.link.inputs.backgroundColor.value} 
                                        color={data.blocks.link.inputs.color.value} 
                                        className="little"
                                        style={{
                                            marginLeft: !data.inputs['centerButton'].value ? 0 : null,
                                            borderStyle: 'solid',
                                            borderWidth: data.blocks.link?.inputs?.border?.inputs.borderWidth?.value ? data.blocks.link.inputs.border.inputs.borderWidth.value : '0px',
                                            borderColor: data.blocks.link?.inputs?.border?.inputs.borderColor?.value ? data.blocks.link.inputs.border.inputs.borderColor.value : null
                                        }}
                                    >
                                        {data.blocks.link.inputs.value.value}
                                    </Button>
                                ) : null
                            }
                            {
                                data.blocks.link2.inputs.link.value && data.blocks.link2.inputs.value.value ? (
                                    <Button 
                                        href={data.blocks.link2.inputs.link.value} 
                                        backgroundColor={data.blocks.link2.inputs.backgroundColor.value} 
                                        color={data.blocks.link2.inputs.color.value} 
                                        className="little"
                                        style={{
                                            borderStyle: 'solid',
                                            borderWidth: data.blocks.link2?.inputs?.border?.inputs.borderWidth?.value ? data.blocks.link2.inputs.border.inputs.borderWidth.value : '0px',
                                            borderColor: data.blocks.link2?.inputs?.border?.inputs.borderColor?.value ? data.blocks.link2.inputs.border.inputs.borderColor.value : null
                                        }}
                                    >
                                        {data.blocks.link2.inputs.value.value}
                                    </Button>
                                ) : null
                            }
                        </Div>
                    </Div>
                    {
                        data.inputs['showMedia'].value ? (
                            <Div style={{order: '1', maxWidth: data.inputs['positionMedia'].value === 'top' || data.inputs['positionMedia'].value === 'bottom' ? '100%' : null}}>
                                {
                                    isVideo ? (
                                        <video controls autoplay={true} muted={true} loop={true}>
                                            <source src={`${process.env.REACT_APP_MEDIAS}/${data.blocks.media.inputs.value.value.filePath}`} type="video/mp4"/>
                                        </video>
                                    ) : (
                                        <Img 
                                            className="w100" 
                                            src={
                                                typeof data.blocks.media.inputs.value.value === 'string' ? 
                                                    data.blocks.media.inputs.value.value 
                                                : data.blocks.media.inputs.value.value.filePath ? 
                                                    `${process.env.REACT_APP_MEDIAS}/${data.blocks.media.inputs.value.value.filePath}` 
                                                : null
                                            } 
                                            alt={data.blocks.title.inputs.value.value}
                                        />
                                    )
                                }
                            </Div>
                        ) : null
                    }
                </Div>
            </DivBlocTextMedia>
        </Div>
    );
};

export default BlocTextMedia;