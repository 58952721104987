import React from 'react';
import {Grid,TextField} from '@material-ui/core';
import request from '../../../../../js/utils/fetch';

import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import {connect} from 'react-redux';
import {GET_PRODUCTS_PAGINATION, SEARCH_PRODUCTS_SKU_BY_CAT, SEARCH_PRODUCTS_SKU} from '../../../../../queries/products';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';

import _ from 'lodash';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import { getTraductionAttributs } from '../../../../../js/utils/functions';
import NoImage from '../../../../../assets/images/not-found.png';


const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 13px;
        padding: 10px;
    }
    & > p{
        margin: 0;
    }
`;

class ProductSelector extends React.Component { 
    constructor(props){
        super(props)
        this.state = {
            currentLang : this.props.allState.currentLang,
            selectedProducts: this.props.allState.selectedProducts || [],
            selectAll: [],
            nbProductsTotal: this.props.allState.selectedProducts?.length || 0,
            productByCategorie: {},
            selectedProductByCategorie: {},
            formatedDatas: {},
            load: true,
            skulist: [],
            searchStatus: 'all',
            products: [],
        }
    }


    
    handleChange = (e, value) => {
        clearTimeout(this.typingSearchTimer);
        if(e?.target?.value){
            this.setState({searchValue: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch();
            }, 500);
        } 
        else {
            this.setState({
                skulist: [],
                noResult: false,
                searchValue: '',
            }, () => {
                this.handleGetProducts();
            });
        }
    }


    handleGetProducts = async () => {
        this.setState({load: true})
        let variables = {};
        if(this.state.selectedProducts && this.state.selectedProducts.length > 0){
            variables.idList = this.state.selectedProducts.map(e => parseInt(e.replace('/api/products/', '')));
            this.props.client.query({
                query: GET_PRODUCTS_PAGINATION,
                fetchPolicy: 'no-cache',
                variables
            }).then(async result =>{
                this.setState({load: false, products: result.data.products.edges});
            });
        }
    };

    componentDidMount = () => {
        this.handleGetProducts();
    };

    render(){
        return(
            <Grid container spacing={0} style={{background: colors.grey.lighter.hue980, marginTop: 0}}>
                <Grid item xs={12}>
                    {
                        !this.state.load ?
                            this.state.products?.length > 0 ?
                                this.state.products.map((product, index) => {
                                    let getPosition= this.props.allState.productsForPosition.find(e => e.value === product.node.id)
                                    let getName = getTraductionAttributs('product_name', product.node.productDatas.edges, this.props.allState.currentLang)
                                    let getImage = getTraductionAttributs('product_image', product.node.productDatas.edges, this.props.allState.currentLang, 'image')
                                    return(
                                        <Grid container style={{padding: 8}} key={`product-position-${index}`}>
                                            <Grid item xs={10}>
                                                <Grid container alignItems="center" style={{height: "100%"}}>
                                                    <Grid item xs={3} style={{textAlign: "center"}}>
                                                        <img src={getImage ? `${process.env.REACT_APP_MEDIAS}/${getImage?.filePath}` : NoImage} style={{width: "auto", maxHeight: 50}} alt={`image-product-${getName}`}/>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {product.node.sku}
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        {getName}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Grid container alignItems="center" style={{height: "100%"}}>
                                                    <TextFieldCustom
                                                        id={`Position-${index}`}
                                                        variant="outlined"
                                                        value={getPosition?.position || ''}
                                                        fullWidth= {true}
                                                        onChange={evt => {
                                                            this.props.stateCallback('position', evt, product.node.id);
                                                        }}
                                                        name={`Position-${index}`}
                                                        type={'text'}
                                                        placeholder={'Pos'}
                                                        style={{width: '100%'}}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                                : "Pas de produits sélectionnés"
                        : <PageLoader/>
                    }
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo((connect(mapStateToProps,mapDispatchToProps)(ProductSelector)));