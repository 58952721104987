import React, { useEffect, useState } from "react";
import Div from '../shared/div';
import H2 from '../shared/h2';
import H4 from '../shared/h4';
import P from '../shared/p';
import Img from '../shared/img';

const CategoriesHome = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;
    
    return (
        <Div style={{position: 'relative'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className="bloc-list">
                <H2 className="w100 uppercase">{data.inputs.value.value}</H2>
                <Div className="list-categories">
                    {
                        data.blocks.categories.config.map((categorie, index) => (
                            <Div className="relative" key={index}>
                                <Img className="w100" src={
                                    typeof categorie.blocks.image.inputs.value.value === 'string' ? 
                                    categorie.blocks.image.inputs.value.value 
                                    : categorie.blocks.image.inputs.value.value.filePath ? 
                                    `${process.env.REACT_APP_MEDIAS}/${categorie.blocks.image.inputs.value.value.filePath}` 
                                    : null
                                } alt={categorie.blocks.title.inputs.value.value} />
                                <Div className="absolute gradient-overlay" />
                                <a href={categorie.blocks.link.inputs.value.value}>
                                    <Div className="absolute">
                                        <H4 className="white uppercase">{categorie.blocks.title.inputs.value.value}</H4>
                                        <P className="center white">Découvrir</P>
                                    </Div>
                                </a>
                            </Div>
                        ))
                    }
                </Div>
            </Div>
        </Div>
    );
};

export default CategoriesHome;