import React from 'react';
import {Grid, Box} from '@material-ui/core';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardContact from '../../../layouts/Card/cardContent/CardContact';
import CardCompany from '../../../layouts/Card/cardContent/CardCompany';
import CardActions from '../../../layouts/Card/cardContent/CardActions';
import Stats from '../../../layouts/Stats/Stats';
import emptyProduct from '../../../../assets/pictos/empty-picto/product.svg';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { ROUTE_CRM_CONTACTS_DETAILS, ROUTE_CRM_CONTACTS_ADD, ROUTE_CRM_CONTACTS_LIST, ROUTE_CRM_CONTACTS_CLIENTS, ROUTE_CRM_CONTACTS_LEADS, ROUTE_HOME } from '../../../../js/constants/route-names';
import { withApollo } from 'react-apollo';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import { GET_CUSTOMERS } from '../../../../queries/customers';
import { GET_COMPANIES } from '../../../../queries/companies';
import { hasRights } from '../../../../js/utils/rights';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { SNACK } from '../../../../js/constants/action-types';
import { CRM_CONTACT, CRM_CONTACT_DASHBOARD, VIEW} from '../../../../js/constants/constant-rights';


class DashboardCustomers extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            filteredContact: [],
            filteredCompanies: [],
            currentLang: props.locales[0].node.code,
        };
        this.stats = 
        [
            {
                'icon': 'icon-business',
                'data': '24/06/20 à 09:00',
                'dataColor': colors.pink.regular,
                'subtitle': 'Mise à jour des Sociétés',
                'gradientColor1': colors.pink.regular,
                'gradientColor2': colors.pink.lighter,
            },
            {
                'icon': 'icon-clients',
                'data': '24/06/20 à 09:00',
                'dataColor': colors.pink.regular,
                'subtitle': 'Mise à jour des Clients',
                'gradientColor1': colors.pink.regular,
                'gradientColor2': colors.pink.lighter,
            },
            {
                'icon': 'icon-leads',
                'data': '24/06/20 à 09:00',
                'dataColor': colors.pink.regular,
                'subtitle': 'Mise à jour des Leads',
                'gradientColor1': colors.pink.regular,
                'gradientColor2': colors.pink.lighter,
            },
            {
                'icon': 'icon-contacts',
                'data': '24/06/20 à 09:00',
                'dataColor': colors.pink.regular,
                'subtitle': 'Mise à jour des Contacts',
                'gradientColor1': colors.pink.regular,
                'gradientColor2': colors.pink.lighter,
            },
        ];
        this.actionsCard = 
        [
            {
                'icon': 'icon-contacts',
                'title': 'Gérer mes contacts',
                'subtitle': 'Gérez l’ensemble de vos contacts (création / modification / suppression)',
                'marginTop': 0,
                'gradientColor1': colors.cyan.regular,
                'gradientColor2': colors.cyan.lighter,
                'route': ROUTE_CRM_CONTACTS_LIST
            },
            {
                'icon': 'icon-clients',
                'title': 'Gérer mes clients',
                'subtitle': 'Gérez l’ensemble de vos clients (création / modification / suppression)',
                'marginTop': 16,
                'gradientColor1': colors.pink.regular,
                'gradientColor2': colors.pink.lighter,
                'route': ROUTE_CRM_CONTACTS_CLIENTS
            },
            {
                'icon': 'icon-leads',
                'title': 'Gérer mes leads',
                'subtitle': 'Gérez l’ensemble de vos leads (création / modification / suppression)', 
                'marginTop': 16,
                'gradientColor1': colors.menu.regular,
                'gradientColor2': colors.menu.darker,
                'route': ROUTE_CRM_CONTACTS_LEADS
            },
        ];
    }

    handleGetCustomers(){
        this.props.client.query({
            query: GET_CUSTOMERS,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.listContact = result.data.customers.edges;
            this.setState({
                filteredContact: this.listContact,
            });
        });
    }

    handleGetCompanies(){
        this.props.client.query({
            query: GET_COMPANIES,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.listCompanies = result.data.companies.edges;
            this.setState({
                filteredCompanies: this.listCompanies,
            })
        })
    }

    componentDidMount(){
        const getRights = hasRights(CRM_CONTACT, CRM_CONTACT_DASHBOARD, VIEW);
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.handleGetCustomers();
            this.handleGetCompanies();
        }
    }

    render() {
        return (
            <div>
                <Typography variant="h1">
                        Dashboard contacts
                </Typography>
                <Typography variant="body1" component="div">
                    <Box pt={3} pb={2}>
                        Dernières mises à jour
                    </Box>
                </Typography>
                <Grid container direction="row">
                    <Grid item lg={12}>
                        <Grid container lg={10}>
                            {this.stats.map((stat, index) =>
                                (
                                    <Grid item lg={2} key={`statsGrid${index}`} style={{marginRight: this.props.windowWidth < 1400 ? '16px' : '0px'}}>
                                        <Stats stat={stat} windowWidth={this.props.windowWidth}/>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={0}>
                    <Grid container direction="row" spacing={4}>
                        <Grid item lg={3} md={12}>
                            <Typography variant="body1" component="div">
                                <Box pt={3} pb={2}>
                                    Que voulez vous faire ?
                                </Box>
                            </Typography>
                            <Grid container>
                                {this.actionsCard.map((action, index) =>
                                    (
                                        <Grid key={`CardActions${index}`} style={{marginRight: this.props.windowWidth < 1400 ? '8px' : '0px', width: '100%'}}>
                                            <CardCustom style={{marginTop: this.props.windowWidth < 1400 ? '0px' : action.marginTop, background: `transparent linear-gradient(118deg, ${action.gradientColor1} 0%, ${action.gradientColor2} 100%) 0% 0% no-repeat padding-box`}}>
                                                <CardActions action={action} goTo={this.goToAction} />
                                            </CardCustom>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Grid>
                        {
                            this.state.filteredContact.length > 0 ? (
                                <>
                                    <Grid item lg={6}>
                                        <Typography variant="body1" component="div">
                                            <Box pt={3} pb={2}>
                                                Derniers contacts ajoutés
                                            </Box>
                                        </Typography>
                                        <Grid container direction="row" spacing={2} lg={12}>
                                            {this.state.filteredContact.slice(-4).reverse().map((contact, index) =>{
                                                
                                                return(
                                                    <Grid item xs={6} style={{wordWrap:'break-word'}}>
                                                            <CardContact 
                                                                data={contact}
                                                                currentLang={this.state.currentLang}
                                                            />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={3} md={12} xs={12}>
                                        <Typography variant="body1" component="div">
                                            <Box pt={3} pb={2}>
                                                Dernières sociétés ajoutées
                                            </Box>
                                        </Typography>
                                        <Grid>
                                            {this.state.filteredCompanies.slice(-2).reverse().map((companyDashboard, index) =>{
                                                return(
                                                    <CardCustom key={`firstProduct${index}`} hovercard={true} style={{marginBottom:16}}>
                                                        <CardCompany
                                                            textButton={'Voir la société'} 
                                                            currentLang={this.state.currentLang} 
                                                            data={companyDashboard}
                                                            routeProduct={() => this.goTo(ROUTE_CRM_CONTACTS_DETAILS.replace(':id',), companyDashboard.data.node.id)} 
                                                            isList={true}
                                                        />
                                                    </CardCustom>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </>
                            ) 
                            : !this.listContacts ? (
                                <Grid item lg={6} md={6} style={{height: '100%'}}>
                                    <PageLoader />
                                </Grid>
                            )
                            : (this.state.listContacts && this.state.listContacts.length > 0) ?
                            (
                                <Grid item lg={6} md={6}>
                                    <EmptyCard title={"Aucun résultat pour cette recherche"} textButton={"Ajouter un contact"} onClick={() => this.goTo(ROUTE_CRM_CONTACTS_ADD)} picto={emptyProduct} openForm={this.state.openForm} xsImg={2} />
                                </Grid>
                            )
                            : 
                            (
                                <Grid item lg={6} md={6}>
                                    <EmptyCard title={"Vous n'avez pas encore configuré de contact"} subtitle={"Cliquez sur le bouton ci-dessous pour en ajouter un"} textButton={"Ajouter un contact"} onClick={() => this.goTo(ROUTE_CRM_CONTACTS_ADD)} picto={emptyProduct} openForm={this.state.openForm} xsImg={2} />
                                </Grid>
                            ) 
                        }
                        
                    </Grid>
                </Grid>
            </div>
        );
    }
    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: { productId : id }
        });
    };

    goToAction = (route) => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardCustomers)));
