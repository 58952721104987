import {Grid} from '@material-ui/core';
import {useEffect,useState} from 'react';
import CardCustom from '../../../Card/CardCustom';

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

export default function GridView(props){
    let {settings,items,currentLang} = props;
    let columns = settings.columns;
    return(
        <Grid 
            container 
            spacing={4}
        >
            {items?.length >0 ?
                items.map((item,index)=>(
                    <Grid 
                        item 
                        key={`list${capitalize(props.label)}-${index}`}
                        xs={12/(columns.xs ? columns.xs : 1)}
                        sm={12/(columns.sm ? columns.sm : 2)}
                        lg={12/(columns.laptop ? columns.laptop : 3)}
                    >
                        <CardCustom 
                            style={{width: "100%", padding: 0,...props.cardContainerProps?.style}} 
                            cardContentStyle={{padding: 0}} 
                            contentpadding={props.cardContainerProps?.contentpadding}
                        >
                            <settings.components data={item} {...props.cardProps} currentLang={currentLang}/>
                        </CardCustom>
                    </Grid> 
                ))
            :null}

        </Grid>
    )
}


