/*import { gapi } from 'gapi-script';*/

export function loadGoogleDrive(channel,importCallback){


    var developerKey = channel.channelDatas?.edges.find(channelData => channelData.node.attribute.identifier === 'gd_api_key')?.node.value;
    var clientId = channel?.channelDatas?.edges.find(channelData => channelData.node.attribute.identifier === 'dg_customer_id')?.node.value;
    var appId = channel?.channelDatas?.edges.find(channelData => channelData.node.attribute.identifier === 'gd_project_id')?.node.value;

    var authToken;

    var strWindowFeatures = "`scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,left=-1000,top=-1000`";
    var getPickerData = window.open('/googledrivecallback.html?developerKey='+developerKey+'&clientId='+clientId+'&appId='+appId, "test", strWindowFeatures);

    var timer = setInterval(function() {
        if(getPickerData.closed) {
            clearInterval(timer);

            var data = {};

            if (getPickerData.document.getElementById('myData')) {
                data = getPickerData.document.getElementById('myData').innerHTML;
                data = JSON.parse(data);
            }

            if (getPickerData.document.getElementById('authToken')) {
                authToken = getPickerData.document.getElementById('authToken').innerHTML;
            }

            if (data.length > 0) {
                pickerCallback(data, authToken)
            }

        }
    }, 1000);

    // A simple callback implementation.
    function pickerCallback(data, oauthToken) {
        if(data){
            importCallback(data,'googledrive',{'oauthToken' : oauthToken});
        }

    }


}
export function loadOneDrive(channel,importCallback, onError){
        var odAppId = channel.channelDatas?.edges.find(channelData => channelData.node.attribute.identifier === 'od_app_id')?.node.value;
        const script = document.createElement("script");
        script.src = "https://js.live.net/v7.2/OneDrive.js";
        /*script.async = true;*/
        document.head.appendChild(script);
        // debugger;
        script.onload = () => {
            var odOptions = {
                clientId: odAppId,
                action: "download",
                multiSelect: true,
                advanced: {
                    filter: ".png,.txt", // Restriction des extensions
                    endpointHint : "api.onedrive.com",
                    redirectUri: `${process.env.REACT_APP_PUBLIC}/onedrivecallback.html`
                },
                success: function(files) {
                    // Traitement des fichiers
                    importCallback(files,'oneDrive',{'oauthToken' : files.accessToken});
                },
                cancel: function() { /* cancel handler */ },
                error: function(error) {
                    onError()
                }
            };

            window.OneDrive.open(odOptions);
        };

}