import {TABS} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function formContentAdd(currentLang, categories, customAttributes, metaAttributes, imageAttributes, categoriesSelected, allGroups, errors, seeErrors,handleMediaPicker) {
    let typeInput = '';
    let attributeSetup = attribute => {
        const defaultLang = attribute.node.translation.translationDatas.edges[0];

        const langSelected = attribute.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === currentLang
        );

        switch(attribute.node.attributeType.input) {
            case 'textarea':
                typeInput = 'textarea';
                break;
            case 'number': // create an input
            case 'decimal': // create an input
            case 'text':
                typeInput = 'text';
                break;
            case 'select':
                typeInput = 'select';
                break;
            case 'image':
                typeInput = 'mediaPicker';
                break;
            case 'file':
                typeInput = 'file';
                break;
            default: typeInput = null;
        };

        return ({
            type: typeInput,
            label: langSelected?.node.value ?? defaultLang?.node.value ?? attribute.node.identifier,
            handleMediaPicker:handleMediaPicker,
            translated: true,
            helper: {
                text: 'Helper non disponible pour le moment',
                link: false,
            },
            isSystem: false,
            currentLang,
            required: attribute.node.isRequired,
            stateName: attribute.node.identifier,
            value: attribute.node.attributeOptions.edges.map((values) => {
                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                    lang => lang.node.locale.code === currentLang
                );

                return ({
                    value: values.node.id,
                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault.node.value
                });
            })
        });
    };

    let generalInputs = [
        {
            type: 'select',
            label: `Modèle`,
            helper: {
                text: `Indiquez le modèle`,
                link: false,
            },
            translated: false,
            required: true,
            disabled: false,
            stateName: 'groupAttribut',
            value: allGroups.map(group => {
                const langSelectedValuesDefault = group.node.translation.translationDatas.edges[0];

                const langSelectedValues = group.node.translation.translationDatas.edges.find(
                    lang  => lang.node.locale.code === currentLang
                );

                return ({
                    value: group.node.id,
                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node.value ?? group.node.identifier
                });
            })
        },
        {
            type: 'text',
            label: 'Code article',
            helper: {
                text: 'Code article du contenu',
                link: false,
            },
            
            required: true,
            stateName: 'sku',
            disabled: false,
        },
        {
            type: 'text',
            label: 'Titre du contenu',
            helper: {
                text: 'Indiquez le nom du contenu ici',
                link: false,
            },
            translated: true,
            required: true,
            stateName: 'content_title',
        },
        {
            type: 'selectTree',
            label: 'Catégories',
            translated: false,
            helper: {
                text: 'Modifier les catégories du contenu',
                link: false,
            },
            required: true,
            stateName: 'categories',
            multiselect: true,
            data: (() => {
                let data = categories.filter(e => e.parent === null && e.libelle === 'Root');

                let populateChildren = (cats, parent) => {
                    let catSelected = categoriesSelected.find(e => e.id === parent.id);
                    
                    let allNames    = parent.contentCategoryDatas.edges.filter(e => e.node.attribute.identifier === 'category_name');

                    const defaultValue  = allNames.find(e => e.node.locale.code === currentLang);
                    const value         = allNames.find(e => e.node.locale.code === currentLang);

                    let finalValue = value?.node.value ?? defaultValue?.node.value ?? parent.libelle;


                    parent.value    = parent.id;
                    parent.label    = finalValue;
                    parent.checked  = catSelected ? true : false;

                    parent.expanded = true;
                    parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);

                    for (let child of parent.children)
                        populateChildren(cats, child);
                };

                for (let parent of data)
                    populateChildren(categories, parent);

                return data;
            })()
        },
        {
            type: 'textarea',
            label: 'Description',
            helper: {
                text: 'Décrivez votre contenu',
                link: false,
            },
            translated: true,
            required: false,
            stateName: 'content_description',
            isContent: true,
        },
        {
            type: 'buttonGroup',
            label: 'État',
            helper: {
                label: 'État',
                text: 'Indiquez l\'état',
                link: false,
            },
            required: false,
            stateName: 'status',
            value: [
                {
                    value: true,
                    label: 'Actif',
                },
                {
                    value: false,
                    label: 'Inactif',
                },
            ]
        }
    ];
    let imageInputs     = imageAttributes.map(attributeSetup);
    let metaInputs      = metaAttributes.map(attributeSetup);
    let customInputs    = customAttributes.filter(e => e.node.attributeType.input !== 'image').map(attributeSetup);

    let hasErrors = (inputs) => {
        for (let input of inputs) {
            if (errors[input.stateName])
                return true;
        }

        return false;
    };

    let children = [
        {
            labelName: 'Général',
            error: hasErrors(generalInputs),
            optionsInputs: generalInputs
        },
        {
            labelName: 'Médias',
            isOptionnal: false,
            error: hasErrors(imageInputs),
            optionsInputs: imageInputs
        }
    ];

    if(metaInputs.length){
        children.push({
            labelName: 'Métas',
            isOptionnal: false,
            error: hasErrors(metaInputs),
            optionsInputs: metaInputs
        });
    }
    if(customInputs.length){
        children.push({
            labelName: 'Variables de modèle',
            isOptionnal: false,
            error: hasErrors(customInputs),
            optionsInputs: customInputs
        })
    }

    var obj = {
        titleForm: 'Ajouter un contenu',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre contenu',
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: TABS,
            seeErrors,
            children
        }
    };

    return obj;
}