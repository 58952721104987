import React, { useEffect, useRef, useState } from "react";
import Swiper from 'swiper';
import H2 from '../shared/h2';
import H3 from '../shared/h3';
import H4 from '../shared/h4';
import Button from '../shared/a';
import Div from '../shared/div';
import P from '../shared/p';
import Img from '../shared/img';

const SliderImage = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;
    
    return (
        <Div  style={{ position: 'relative', background: data.blocks.background.inputs.backgroundColor.value }} className="grid-image" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className="container-grid-image">
                {
                    data.blocks.slides.config.map((slide, index) => (
                        <Div>
                            <Img 
                                src={
                                    typeof slide.blocks.imagePrincipale?.inputs.value.value === 'string' ? 
                                        slide.blocks.imagePrincipale?.inputs.value.value 
                                    : slide.blocks.imagePrincipale?.inputs.value.value.filePath ? 
                                        `${process.env.REACT_APP_MEDIAS}/${slide.blocks.imagePrincipale?.inputs.value.value.filePath}` 
                                    : null
                                }
                                alt={'illustration'}
                            />
                        </Div>
                    ))
                }
            </Div>
        </Div>
    );
};

export default SliderImage;