import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';

import * as moment from "moment";
import _ from 'lodash';
import request from '../../../../js/utils/fetch';
import colors from '../../../../config/theme/colors';

import { ROUTE_PRODUCTS_LIST, ROUTE_HOME } from '../../../../js/constants/route-names';
import { hasRights } from '../../../../js/utils/rights';
import { PRODUCTS, PRODUCTS_PRODUCTS, VIEW, UPDATE, DELETE } from '../../../../js/constants/constant-rights';
import { ADD_PRODUCT, GET_PRODUCT_DATA, DELETE_PRODUCT, GET_PRODUCTS, UPDATE_PRODUCT, UPDATE_PRODUCT_DATA, ADD_PRODUCT_DATA, DELETE_PRODUCT_DATA, GET_PRODUCTS_PAGINATION } from '../../../../queries/products';
import { GET_ATTRIBUTES_BY_GROUPE } from '../../../../queries/attributes';
import { GET_CATEGORIES_LIGHT } from '../../../../queries/categories';
import { ALLOWED } from '../../../../js/constants/medias-types';

import formProductDetail from './config/formProductDetail.config';
import formVariantProductAdd from './config/formVariantProductAdd.config';

import CardCustom from '../../../layouts/Card/CardCustom';
import CardProductDetails from '../../../layouts/Card/cardContent/CardProductDetails';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import DialogModal from '../../../ui/dialog/DialogModal';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { GET_SELECTIONS } from '../../../../queries/selections';
import styled from 'styled-components';

const SelectCustom = styled(Select)`
    border-radius: 0; 
    width: 100%; 
    max-width: 170px; 
    margin-right: 16;
    fieldset{
        border: none;
    }
    &:before, &:after{
        display: none !important;
    }
    input, .MuiSelect-select{
        font-size: 14px;
        padding: 16px;
        padding-left: 8px;
        margin-right: 16px;
        color: ${colors.blue.lighter.hue300};
        &:focus{
            background: none;
        }
        @media screen and (max-width: 1450px){
            font-size: 14px;
            line-height: 18px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            line-height: 17px;
        }
        @media screen and (max-width: 960px){
            font-size: 12px;
            line-height: 16px;
        }
    }
    svg{
        fill: ${colors.blue.lighter.hue300};
    }
    & > p{
        margin: 0;
    }
`;

class ProductDetail extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            groupAttribut: '',
            customAttributes: [],
            metaAttributes: [],
            imageAttributes: [],
            imagesSelected: [],
            attributes : [],
            maxImageNumber : 0,
            imageSrc: [],
            productId: `/api/products/${props.match.params.id}`,
            retailersInfos: [],
            sku: null,
            title: '',
            categories: [],
            categoriesData: [],
            readyForCat: false,
            ready: false,
            categorieReady: false,
            description:'',
            status:'',
            metaTitle: '',
            metaDesc: '',
            openForm: false,
            openDialog: false,
            allGroups: [],
            errors: {},
            secondErrors: {},
            seeErrors: false,
            variantToDelete: [],
            suggestionsProduct: [],
            currentCollection: null,
            listCollections: [],
            hasPrice: false,
        }
    }

    async componentDidMount() {
        const getRights = hasRights(PRODUCTS, PRODUCTS_PRODUCTS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        await this.initCollections();
        this.initProduct();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id){
            const getRights = hasRights(PRODUCTS, PRODUCTS_PRODUCTS, VIEW)
            if (!getRights){
                this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
                this.goTo(ROUTE_HOME);
            }
            this.setState({
                productId: `/api/products/${this.props.match.params.id}`,
                ready: false
            }, this.initProduct);
        }
        
    }

    handleToggleDrawer = (drawer) => {
        if(drawer === 'form'){
            this.setState({ 
                openForm : !this.state.openForm,
            }, () => {
                this.props.client.query({
                    query: GET_CATEGORIES_LIGHT,
                    fetchPolicy: "no-cache"
                }).then((result) => {
                    this.setState({ 
                        categoriesData: result.data.categories,
                        categorieReady: true,
                    });
                })
            });
        }
        if(drawer === 'addVariant'){
            this.setState({ 
                openFormVariant : !this.state.openFormVariant,
                // variantsProduct : this.state.variantsValidated,
            });
            if (this.state.variantsValidated?.values.length > 0){
                let copyVariantsValidated = _.cloneDeep(this.state.variantsValidated.values)
                this.setState({
                    variantsProduct : copyVariantsValidated
                })
            } else{
                this.setState({
                    variantsProduct : []
                })
            }
        }
        this.setState({ 
            seeErrors: false
        });
    };

    handleToggleDialog = () => {
        this.setState({ 
            openDialog : !this.state.openDialog
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    handleChangeCollection = (event) => {
        this.setState({ 
            currentCollection: event.target.value,
            ready: false
        }, () => {
            this.initProduct();
        });
    };

    setValue = (stateName, value, translated) => {
        if(stateName === 'product_price' && value.includes(',')){
            value = value.replace(',', '.');
        }
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }
            if (values[stateName]){
                if (!values[stateName]?.new){
                    values[stateName].value = value;
                    values[stateName].updated = true;
                }else{
                    values[stateName].value = value;
                }
            }else{
                values[stateName] = {
                    value: value,
                    new: true,
                    updated: true,
                }
            }
            

            this.setState({
                [this.state.currentLang]: values,
            });
        } else{
            this.setState({
                [stateName]: value,
            });
        }
    };

    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    resetState() {
        this.setState({
            imageSrc: [],
            categories: [],
            errors: {},
        }, () => {
            this.resetVariant()
        });
    }
    
    resetVariant = () => {
        this.setState({
            // attributesSelected: [],
            variantToDelete: [],
            variantsProduct: [],
        });
    }

    handleCancel = () => {
        this.resetState();
        this.initProduct();
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    deleteMutation = () => {
        let query = null;
        let variables = null;

        query = DELETE_PRODUCT;
        variables = { id: this.state.productId };

        this.props.client.mutate({
            mutation: query,
            variables,
            refetchQueries: [{
                query: GET_PRODUCTS,
            }]
        }).then(result =>{
            this.goTo(ROUTE_PRODUCTS_LIST);
            this.handleToggleDrawer('form');
            this.handleToggleDialog();
        })
    };

    copy(array) {
        let newArray = [];
        
        for (let elem of array)
            newArray.push(Object.assign({}, elem));

        return newArray;
    }

    copyArrayOfObjects = array => array.map(a => ({...a}));

    getAttributeTranslatedValue = (id, lang) => {
        if (!this.state.attributes)
            return null;

        let attribute = this.state.attributes.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };
    
    saveVariant = (drawer) => {
        this.handleToggleDrawer(drawer)
        let copyVariants = this.copyArrayOfObjects(this.state.variantsProduct);
        this.setState({
            variantsProduct: [],
            variantsValidated: {
                attributes : this.state.attributesSelected,
                values : copyVariants,
            },
            // changedVariants: copyVariants.map(e => e.idVariant)
        });
        this.getVariantToDelete(copyVariants)
    }

    getVariantToDelete = (result) => {
        let deleteVariants = []
        for (let i = 0; i < this.state.initialVariantId.length; ++i ){
            let checkDelete = result.find(e => e.idVariant === this.state.initialVariantId[i])
            if (!checkDelete){
                deleteVariants.push(this.state.initialVariantId[i])
            }
        }
        this.setState({
            variantToDelete: deleteVariants,
        });
    }

    getVariantForChildren = () => {
        return new Promise(async (resolve, reject) => {
            let values = this.state.variantsValidated.values
            let getChildren = []
            for (let i = 0; i < values.length; ++i ){
                let skuVariant = this.state.sku + '-variant'+ (i+1);
                if (values[i]?.new === true){
                    const ADD_PRODUCT_VARIANT_RESULT = await this.props.client.mutate({
                        mutation: ADD_PRODUCT,
                        variables: {
                            'sku': skuVariant,
                            'attributeGroup': this.state.groupAttribut,
                            'categories': this.state.categories.map(e => parseInt(e.id.replace('/api/categories/', ''))),
                            'createdAt': moment().format('YYYY-MM-DD'),
                            'updatedAt': moment().format('YYYY-MM-DD'),
                            'status': this.state.status,
                        }
                    });
                    await this.saveAttributesVariants(ADD_PRODUCT_VARIANT_RESULT.data.createProduct.product.id, values[i]);
                    let variantCreate = ADD_PRODUCT_VARIANT_RESULT.data.createProduct.product.id
                    getChildren.push(variantCreate)
                } else{
                    await this.saveAttributesVariants(values[i].idVariant, values[i], true)
                    getChildren.push(values[i].idVariant)
                }
            }
            resolve(getChildren);
        });
    };

    createVariant = () => {
        return new Promise(async (resolve, reject) => {
            let getChildren = await this.getVariantForChildren();
            resolve(getChildren);
        });
    };

    saveAttributesVariants = (product, variants, update) => {
        return new Promise(async (resolve, reject) => {
            let attributes = this.state.isSystemAttributes.concat(this.state.customAttributes);
            let getProductData = [];
            for (let attribute of attributes) {
                // for (let locale of this.props.locales) { 
                    let locale  = this.props.locales.find(e => e.node.code === this.state.currentLang)
                    let formValue   = this.state[locale.node.code][attribute.node.identifier];
                    let isMedia     = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';
                    let isForVariant  = attribute.node.identifier === 'product_price' || attribute.node.identifier === 'product_image';
                    // let currentTranslation  = this.getAttributeTranslatedValue(attribute.node.id, locale.node.id);
                    if (formValue && isMedia) {
                        /*if (!formValue.changed)
                            continue;*/
                        isMedia     = true;
                        /*formValue   = formValue.file;*/
                    }
                    if (variants.allAttr.find(e => e.id === attribute.node.id) || formValue || isForVariant) {
                        if (variants && variants.new){
                            let variables = {
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "product": product, 
                                "attribute": attribute.node.id,
                                "locale": locale.node.id,
                                "updatedAt": moment().format('YYYY-MM-DD'),
                            };

                            if (isMedia){
                                if (variants){
                                    variables.media = variants.new ? variants.image.value.id : variants.image.value.data.id
                                }else{
                                    variables.media = formValue.id;
                                }
                            }
                                
                            
                            if (!isMedia)
                                if (variants){
                                    for (let newAttr of variants.allAttr){
                                        if (attribute.node.id === newAttr.id){
                                            if (newAttr.type === "select"){
                                                variables.attributeOption = newAttr.values
                                            }else{
                                                variables.value = newAttr.values;
                                            }
                                        }
                                    }
                                    if (attribute.node.identifier === "product_price"){
                                        variables.value = variants.price.value
                                    }
                                } else{
                                    variables.value = formValue;
                                }
                            await this.props.client.mutate({
                                mutation: ADD_PRODUCT_DATA,
                                variables
                            })
                        }else if (variants.updated){
                            for (let newAttr of variants.allAttr){
                                if (attribute.node.id === newAttr.id){
                                    let variables = {
                                        "id": newAttr.productDataId, 
                                        "attributeOption": attribute.node.attributeType.input === 'select' ? newAttr.values : null,
                                        "product": product, 
                                        "attribute": attribute.node.id,
                                        "locale": locale.node.id,
                                        "updatedAt": moment().format('YYYY-MM-DD'),
                                    };
    
                                    if (isMedia)
                                        variables.media = formValue.id;
                                    
                                    if (!isMedia)
                                        variables.value = newAttr.values;
            
                                    await this.props.client.mutate({
                                        mutation: UPDATE_PRODUCT_DATA,
                                        variables
                                    });
                                }
                            }
                            if (attribute.node.identifier === "product_price" && variants.price.id){
                                let variables = {
                                    "id": variants.price.id, 
                                    "attributeOption": null,
                                    "product": product, 
                                    "attribute": attribute.node.id,
                                    "locale": locale.node.id,
                                    "updatedAt": moment().format('YYYY-MM-DD'),
                                };
                                variables.value = variants.price.value
                                await this.props.client.mutate({
                                    mutation: UPDATE_PRODUCT_DATA,
                                    variables
                                });
                            }
                            if (attribute.node.identifier === "product_image" && variants.image.id){
                                let variables = {
                                    "id": variants.image.id, 
                                    "attributeOption": null,
                                    "product": product, 
                                    "attribute": attribute.node.id,
                                    "locale": locale.node.id,
                                    "updatedAt": moment().format('YYYY-MM-DD'),
                                };
                                variables.media = variants.image.value.id
                                await this.props.client.mutate({
                                    mutation: UPDATE_PRODUCT_DATA,
                                    variables
                                });
                            }
                        }
                    }
                // }
            }

            resolve(getProductData);
        });
    };

    saveAttributes = product => {
        return new Promise(async (resolve, reject) => {
            let attributes = this.state.isSystemAttributes.concat(this.state.customAttributes);
            for (let locale of this.props.locales){
                for (let attribute of attributes) {
                    let formValue           = this.state[locale.node.code][attribute.node.identifier];
                    let currentTranslation  = this.getAttributeTranslatedValue(attribute.node.id, locale.node.id);
                    let isMedia             = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';
                    if (formValue && isMedia) {
                        isMedia     = true;
                    }
                    if (formValue && formValue.value) { 
    
                        let toCreate = formValue.new && formValue.updated;
                        let toUpdate = !formValue.new && formValue.updated && currentTranslation;
    
                        if (toUpdate) {
                            // UPDATE STEP
    
                            let variables = {
                                "id": currentTranslation.productDataId, 
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue.value : null,
                                "product": product, 
                                "attribute": attribute.node.id,
                                "selection": this.state.currentCollection !== "-1" ? this.state.currentCollection : null,
                                "locale": locale.node.id
                            };
    
                            if (isMedia) {
                                variables.media = formValue.value.id;
                            }
    
                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select'){
                                    variables.value = formValue.value;
                                }
    
                            await this.props.client.mutate({
                                mutation: UPDATE_PRODUCT_DATA,
                                variables
                            });
                        } else if (toCreate) {
                            // CREATE STEP
    
                            let ADD_PRODUCT_DATA_RESULT = ''
                            let variables = {
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue.value : null,
                                "product": product, 
                                "attribute": attribute.node.id,
                                "locale": locale.node.id,
                                "selection": this.state.currentCollection !== "-1" ? this.state.currentCollection : null,
                                "updatedAt": moment().format('YYYY-MM-DD'),
                            };
    
                            if (isMedia) {
                                variables.media = formValue.value.id;
                            }
    
                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select'){
                                    variables.value = formValue.value;
                                }
                            ADD_PRODUCT_DATA_RESULT = await this.props.client.mutate({
                                mutation: ADD_PRODUCT_DATA,
                                variables
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP
    
                        await this.props.client.mutate({
                            mutation: DELETE_PRODUCT_DATA,
                            variables: { "id": currentTranslation.productDataId }
                        });
                    }
                }
            }

            resolve();
        });
    };

    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);
    }

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        await this.initProduct();

        this.props.snack(ALERT_SUCCESS, 'Produit modifié !');

        this.handleToggleDrawer('form');
        this.resetState();
        this.props.stopLoading();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    handleSecondFromError = (stateName, error) => {
        let secondErrors = this.state.secondErrors;

        secondErrors[stateName] = error;

        this.setState({ secondErrors })
    }

    hasErrors = (formError) => {
        if (formError === 'firstForm'){
            if (this.state.errors) {
                for (let error in this.state.errors) {
                    if (this.state.errors[error])
                        return true;
                }
            }
        }else{
            if (this.state.secondErrors) {
                for (let error in this.state.secondErrors) {
                    if (this.state.secondErrors[error])
                        return true;
                }
            }
        }
        

        return false;
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors('firstForm')) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }

            this.props.startLoading();

            let catalogCat = this.state.categoriesCatalog?.map(e => e.id)
            let allCategories = this.state.categories?.map(e => e.id).concat(catalogCat)

            if (this.state.isVariant){
                const newVariants = await this.createVariant();
                if (this.state.variantToDelete.length > 0 ){
                    for (let variantId of this.state.variantToDelete){
                        await this.props.client.mutate({
                            mutation: DELETE_PRODUCT,
                            variables: {
                                'id': variantId
                            },
                        })
                    }
                }
                let variables = {
                    'id': this.state.productId,
                    'attributeGroup': this.state.groupAttribut,
                    'categories': allCategories.map(e => e.replace('/api/categories/', '')),
                    'status': this.state.status,
                    'children': newVariants,
                };
                if(this.state.selectedProducts){
                    variables.suggestions = this.state.selectedProducts;
                }
                await this.props.client.mutate({
                    mutation: UPDATE_PRODUCT,
                    variables,
                })
            } else{
                let variables = { 
                    'id': this.state.productId,
                    'attributeGroup': this.state.groupAttribut,
                    'categories': allCategories.map(e => e.replace('/api/categories/', '')),
                    'status': this.state.status
                };
                if(this.state.selectedProducts){
                    variables.suggestions = this.state.selectedProducts;
                }
                await this.props.client.mutate({
                    mutation: UPDATE_PRODUCT,
                    variables,
                });
            }

            await this.saveAttributes(this.state.productId);

            this.handleSuccess();
        } catch(e) {
            this.handleError(e);
        }
    };

    render() {
        const { sku, nbCatalog, status, statsProduct, stocksProduct, commandsProduct, retailersInfos} = this.state;
        return (
            <>
                {
                    process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        (
                            <div style={{width: this.state.openForm && this.props.windowWidth > 1500 ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "calc(100% - 32px)", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                                <Grid container direction={"row"} justify={'flex-end'} alignItems={"center"}>
                                    <Typography style={{fontSize: 13}}>Choix de la collection: </Typography>
                                    <SelectCustom
                                        id="collection-select"
                                        onChange={this.handleChangeCollection}
                                        disabled={false}
                                        value={this.state.currentCollection || "-1"}
                                    >
                                        <MenuItem value={'-1'}>Aucune collection</MenuItem>
                                        { this.state.listCollections.map((option, i) => (
                                            <MenuItem key={i} value={option.node.id}>{ option.node.identifier }</MenuItem>
                                        ))}
                                    </SelectCustom>
                                    <Grid item>
                                        <TraductionSelect
                                            currentLang={this.state.currentLang}
                                            handleLang={this.handleLang}
                                            locales={this.props.locales}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    : null
                }
                <CardCustom style={{width: this.state.openForm && this.props.windowWidth > 1500 ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "calc(100% - 32px)", marginLeft: 16, marginTop: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 8 : 24, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                    {
                        this.state.ready ?
                            <CardProductDetails 
                                sku={sku}
                                product={{ nbCatalog, status, statsProduct, stocksProduct, commandsProduct, retailersInfos}}
                                routeProduct={() => this.goTo(ROUTE_PRODUCTS_LIST)} 
                                categories={this.state.categories} 
                                toggleDrawer={() => this.handleToggleDrawer('form')} 
                                openForm={this.state.openForm}
                                imageAttributes={this.state.imageAttributes}
                                customAttributes={this.state.customAttributes}
                                currentLang={this.state.currentLang}
                                allState={this.state}
                                locales={this.props.locales}
                                ready={this.state.readyForCat}
                                canModify={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, UPDATE)}
                                datasStocks={this.state.datasStocks ? this.state.datasStocks : null}
                                windowWidth={this.props.windowWidth}
                                selectedProducts={this.state.selectedProducts}
                                goTo={this.goTo}
                            />
                        : <PageLoader />
                    }
                    
                    <LayoutBuilder 
                        validateButton={true} 
                        handleCancel={this.handleCancel}
                        opened={this.state.openForm} 
                        forClose={() => this.handleToggleDrawer('form')} 
                        handlerMutation={this.handlerMutation} 
                        icomoon={'ico-modifier-produit'}
                        dataLayout={formProductDetail(
                            this.state.currentLang, 
                            this.state.categoriesData, 
                            this.state.customAttributes, 
                            this.state.metaAttributes, 
                            this.state.imageAttributes,
                            this.state.categories, 
                            this.state.allGroups,
                            this.state.errors,
                            this.state.seeErrors,
                            this.handleMediaPicker,
                            this.state.imagesSelected,
                            this.state.isVariant,
                            this.handleToggleDrawer,
                            this.state.variantsValidated,
                            ALLOWED,
                            this.state.hasPrice
                        )} 
                        handleButtonGroupChange={this.handleButtonGroupChange}
                        allState={this.state} 
                        stateCallback={this.handleInputChange} 
                        errorCallback={this.handleFormError}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        currentCollection={this.state.currentCollection}
                        handleChangeCollection={this.handleChangeCollection}
                        listCollections={this.state.listCollections}
                        drawerWidth={this.props.drawerWidth}
                        deleteMutation={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, DELETE) ?  this.handleToggleDialog : null}
                        deleteText={'Supprimer le produit'}
                        deleteButton={this.state.editForm} 
                    />
                    <LayoutBuilder 
                        validateButton={true} 
                        handleCancel={this.resetVariant}
                        opened={this.state.openFormVariant} 
                        forClose={() => this.handleToggleDrawer('addVariant')} 
                        handlerMutation={() => this.saveVariant('addVariant')} 
                        icomoon={'ico-modifier-produit'}
                        dataLayout={formVariantProductAdd(
                            this.state.currentLang, 
                            this.state.customAttributes, 
                            this.state.attributesSelected, 
                            this.state[this.state.currentLang]?.product_price,
                            this.state.variantsValidated,
                            this.state.defaultImageProduct,
                            true,
                            this.handleMediaPicker,
                            this.state.hasPrice,
                        )} 
                        backStepperButtonAction={[
                            () => {
                                this.setState({ errors: {} });
                            }, 
                            () => {
                                this.setState({ errors: {} });
                            },
                            () => {
                                this.setState({ errors: {} });
                            },
                            null
                        ]}
                        stepperButtonAction={[
                            () => {
                                if (this.hasErrors()) {
                                    this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                    this.setState({ seeErrors: true });
                                    eventService.fire();
                                    return false;
                                }
                                return true;
                            },
                            null,
                        ]}
                        handleButtonGroupChange={this.handleButtonGroupChange}
                        allState={this.state} 
                        stateCallback={this.handleInputChange} 
                        errorCallback={this.handleFormError}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        drawerWidth={this.props.drawerWidth}
                        deleteButton={false} 
                    />
                    <DialogModal 
                        open={this.state.openDialog} 
                        title={`Êtes-vous sûr de vouloir supprimer ce produit ?`}
                        secondaryAction={this.handleToggleDialog} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                        primaryAction={this.deleteMutation} primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
                    >
                        Si vous supprimez ce produit celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                    </DialogModal>
                </CardCustom>
            </>
        );
    }
    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);
    }

    prepareAttributes() {
        return new Promise(async (resolve, reject) => {
            const GET_ATTRIBUTES_BY_GROUP_RESULT = await this.props.client.query({
                query: GET_ATTRIBUTES_BY_GROUPE,
                variables: {id : this.state.groupAttribut},
                fetchPolicy: "no-cache"
            });
            let isSystemAttributes  = this.props.attributes.product.attributes.edges.filter(e => e.node.isSystem);
            let metaAttributes      = this.props.attributes.product.attributes.edges.filter(e => e.node.isSystem && e.node.identifier.indexOf('meta') > -1);
            let customAttributes    = GET_ATTRIBUTES_BY_GROUP_RESULT.data.attributeGroup.attributes.edges.filter(e => !e.node.isSystem);
            let hasPrice            = GET_ATTRIBUTES_BY_GROUP_RESULT.data.attributeGroup.attributes.edges.find(e => e.node.identifier === "product_price") ? true : false;
            let imageAttributes     = isSystemAttributes.filter(e => e.node.attributeType.input === 'image').concat(customAttributes.filter(e => e.node.attributeType.input === 'image'));
            this.setState({
                isSystemAttributes,
                metaAttributes,
                customAttributes,
                imageAttributes,
                hasPrice,
                maxImageNumber: imageAttributes.length,
            }); 

            resolve();
        });
    }

    prepareVariants(result) {
        return new Promise((resolve, reject) => {
            let superAttributes = [];
            let variantValues = []
            for (let attr of result.data.product.superAttribute.edges){
                let getLabel = attr.node.translation.translationDatas.edges.find(e => e.node.locale.code === this.state.currentLang)
                superAttributes.push({
                    label: getLabel ? getLabel.node.value : attr.node.translation.translationDatas.edges[0].node.value,
                    node: attr.node
                })
            }
            for (let child of result.data.product.children.edges){
                let getName = child.node.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name')
                let getPrice = child.node.productDatas.edges.find(e => e.node.attribute.identifier === 'product_price')

                let getImage = child.node.productDatas.edges.find(e => e.node.attribute.identifier === 'product_image')
                let getAttrVariant = []
                for(let superAttr of superAttributes){
                    let getAttr = child.node.productDatas.edges.find(e => e.node.attribute.id === superAttr.node.id);
                    if (getAttr){
                        let getLabel = getAttr.node.attribute.translation.translationDatas.edges.find(e => e.node?.locale.code === this.state.currentLang);
                        let getVariant = {
                            id: getAttr.node.attribute.id,
                            identifier: getAttr.node.attribute.identifier,
                            label: getLabel ? getLabel.node.value : getAttr?.node.attribute.translation.translationDatas.edges[0].node.value,
                            productDataId : getAttr.node.id,
                            type : getAttr.node.attribute.attributeType.input,
                            values: getAttr.node.attribute.attributeType.input === "select" ? getAttr.node.attributeOption.id : getAttr.node.value,
                            valuesForSelect: superAttr.node.attributeOptions.edges,
                        }
                        getAttrVariant.push(getVariant)
                    }
                }
                variantValues.push({
                    idVariant: child.node.id,
                    sku: child.node.sku,
                    name: getName?.node.value,
                    price: {
                        id: getPrice?.node.id,
                        value: getPrice?.node.value,
                    },
                    image: {
                        id: getImage?.node.id,
                        value: getImage?.node.media
                    },
                    new: false,
                    updated: false,
                    allAttr: getAttrVariant,
                })
            }
            let copyVariantValues = this.copyArrayOfObjects(variantValues);
            this.setState({
                attributesSelected: superAttributes,
                variantsValidated: {
                    attributes: result.data.product.superAttribute.edges,
                    values: variantValues
                },
                // variantsProduct: variantValues ? copyVariantValues : [],
                initialVariant: copyVariantValues,
                initialVariantId: variantValues.map(e => e.idVariant)
            });
        });
    }

    prepareAttributesLocales(result) {
        return new Promise((resolve, reject) => {
            let attributes = [];

            for (let { node } of result.data.product.productDatas.edges) {
                let found = false;
                
                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;
    
                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            productDataId: node.id,
                            type: node.attribute.attributeType.input
                        });
                    }
                }
    
                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        isSystem: node.attribute.isSystem,
                        attributeOptions: node.attributeOptions,
                        translationLabel: node.attribute.translation.translationDatas.edges,
                        locales: [{
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption?.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            productDataId: node.id,
                            type: node.attribute.attributeType.input
                        }]
                    });
                }
            }
    
            this.setState({ attributes }, resolve);
        });
    }

    prepareAttributeValues() {
        return new Promise((resolve, reject) => {
            for (let locale of this.props.locales) {
                let values = {};

                for (let attribute of this.state.attributes) {
                    let attributeLocale = attribute.locales.find(e => e.code === locale.node.code);
                    // let defaultLocale   = attribute.locales.find(e => e.code === this.state.currentLang);
                    let defaultLocale   = attribute.locales.find(e => e.code === 'fr_FR');
                    // console.log('defaultLocale', defaultLocale)
                    if (!attributeLocale && defaultLocale) {
                        if (attribute.attributeType.input === 'select') {
                            values[attribute.identifier] = {
                                value: defaultLocale?.value ?? attribute.locales[0],
                                new: false,
                                updated: false
                            }
                        }
                        switch(attribute.attributeType.input) {
                            case 'file':
                            case 'image':
                                values[attribute.identifier] = {
                                    value : {
                                        id: defaultLocale?.media ? defaultLocale.media.id : null,
                                        data: defaultLocale?.media ? defaultLocale.media.filePath : null,
                                        file: null,
                                        changed: false,
                                        type: defaultLocale.media?.type,
                                        name: defaultLocale.media?.name,
                                    },
                                    new: true,
                                    updated: false
                                };
                                break;
                            default:
                                values[attribute.identifier] = {
                                    value : defaultLocale.value,
                                    new: true,
                                    updated: false, 
                                }
                        }
                    } else if (attributeLocale) {
                        switch(attribute.attributeType.input) {
                            case 'file':
                            case 'image':
                                values[attribute.identifier] = {
                                    value : {
                                        id: attributeLocale.media?.id,
                                        data: attributeLocale.media ? attributeLocale.media.filePath : null,
                                        file: null,
                                        changed: false,
                                        type: attributeLocale.media?.type,
                                        name: attributeLocale.media?.name,
                                    },
                                    new: false,
                                    updated: false
                                };
                                break;
                            default:
                                values[attribute.identifier] = {
                                    value : attributeLocale.value,
                                    new: false,
                                    updated: false,
                                } 
                        }
                    }
                }

                this.setState({
                    [locale.node.code]: values,
                });
            }
            resolve()
        })
    }

    initCollections() {
        this.props.client.query({
            query: GET_SELECTIONS,
            fetchPolicy: "no-cache",
        }).then(result => {
            this.setState({
                listCollections: result.data.selections.edges
            })
        })
    }

    initProduct() {
        this.props.client.query({
            query: GET_PRODUCT_DATA,
            fetchPolicy: "no-cache",
            variables : { 
                id: this.state.productId,
                selection: this.state.currentCollection !== "-1" ? this.state.currentCollection : null,
            }
        }).then(GET_PRODUCT_DATA_RESULT => {
                let filterCategoriesCatalog = GET_PRODUCT_DATA_RESULT.data.product.productCategories.edges.filter(e => e.node.category.catalog === null).map(e => e.node.category)
                let getCategoriesCatalog = GET_PRODUCT_DATA_RESULT.data.product.productCategories.edges.filter(e => e.node.category.catalog !== null).map(e => e.node.category)
                let getimageProduct = GET_PRODUCT_DATA_RESULT.data.product.productDatas.edges.find(e => e.node.attribute.identifier === "product_image")
                let array = [];
                let selectedProducts = [];
                
                for(let suggestion of GET_PRODUCT_DATA_RESULT.data.product.suggestions.edges){
                    selectedProducts.push(suggestion.node.id);
                }

                array.push({node: GET_PRODUCT_DATA_RESULT.data.product.attributeGroup});

                this.setState({
                    sku: GET_PRODUCT_DATA_RESULT.data.product.sku,
                    groupAttribut : GET_PRODUCT_DATA_RESULT.data.product.attributeGroup.id,
                    categories: filterCategoriesCatalog, // product categories
                    attributes : [],
                    suggestionsProduct : [],
                    allGroups: array,
                    status: GET_PRODUCT_DATA_RESULT.data.product.status,
                    isVariant: GET_PRODUCT_DATA_RESULT.data.product.children.edges.length || GET_PRODUCT_DATA_RESULT.data.product.superAttribute.edges.length > 0 ? true : false,
                    defaultImageProduct: getimageProduct?.node.media,
                    categoriesCatalog: getCategoriesCatalog,
                    selectedProducts,
                }, async () => {
                    this.prepareAttributes();
                    await this.prepareAttributesLocales(GET_PRODUCT_DATA_RESULT);
                    this.prepareVariants(GET_PRODUCT_DATA_RESULT);
                    await this.prepareAttributeValues();
                    
                    await request(`${process.env.REACT_APP_API}/products-count/catalog/${this.state.sku}`, 'get').then(
                        (data) => {
                            if(data.success){
                                this.setState({
                                    nbCatalog: data.products[this.state.sku]
                                });
                            }
                            else{
                                this.setState({
                                    nbCatalog: 0
                                });
                            }
                        }
                    );
                    await request(`${process.env.REACT_APP_API}/completude/${this.state.sku}`, 'get').then(
                        (data) => {
                            if(data.success){
                                this.setState({
                                    statsProduct: data.products[this.state.sku].percentByLangs
                                })
                            }
                        }
                    );
                    request(`${process.env.REACT_APP_API}/getRetailerUrlByProduct/${this.state.sku}`, 'get').then(
                        (data) => {
                            if(data.success && data.products[this.state.sku]?.length > 0){
                                this.setState({
                                    retailersInfos: data.products[this.state.sku]
                                })
                            }
                        }
                    );
                    if ( process.env.REACT_APP_MODE_SPREAD === "hub"){
                        await request(`${process.env.REACT_APP_API}/stockCompletudeBySku/${this.state.sku}`, 'post').then(
                            (data) => {
                                if(data.success){
                                    this.setState({
                                        stocksProduct: data.products[this.state.sku].stock,
                                        commandsProduct: data.products[this.state.sku].orders,
                                        datasStocks: data.products[this.state.sku],
                                    })
                                }
                            }
                        );
                    }
                    if (this.state.selectedProducts.length > 0){
                        this.props.client.query({
                            query: GET_PRODUCTS_PAGINATION,
                            variables: {'idList': this.state.selectedProducts.map(e => e.replace('/api/products/', ''))}
                        }).then((result) => {
                            this.setState({
                                suggestionsProduct : result.data.products.edges
                            })
                        })
                    }
                    this.setState({
                        readyForCat: true,
                        ready: true,
                    });
                });
            });
    }

    goTo = route => this.props.history.push(route);
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ProductDetail)));