import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import ProductsSelector from '../components/ProductsSelector';
import ProductsPositions from '../components/ProductsPositions';

export default function formShopTheLook(action='add', handleMediaPicker, allState) {
    let generalInputs   = [
        {
            required : true,
            type : 'text',
            label : 'Libelle',
            stateName : 'libelle',
            helper: {
                text: 'Nom de votre look',
                link: false,
            }
        },
        {
            type: 'mediaPicker',
            label: 'Image',
            helper: {
                text: 'Image de votre look',
                link: false,
            },
            handleMediaPicker : handleMediaPicker,
            required: false,
            stateName: 'imageLook',
        },
        {
            type: 'buttonGroup',
            label: 'État',
            helper: {
                label: 'État',
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'status',
            value: [
                {
                    value: true,
                    label: 'Actif',
                },
                {
                    value: false,
                    label: 'Inactif',
                },
            ]
        },
    ]
    var form = {
        titleForm: action === 'add'? 'Créer un look':'Modifier un look',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer':'modifier'} votre look`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Valider la création de ce look' : 'Valider la modification de ce look',
                textButton: action === 'add' ? 'Créer' : 'Modifier'
            },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
                {
                    labelName: 'Sélection des produits',
                    isOptionnal: false,
                    component: ProductsSelector,
                    optionsInputs: {
                        catalogId : allState.assetInfo?.catalog.id,
                        noCategories: true,
                    }
                },
                {
                    labelName: 'Position des produits',
                    isOptionnal: false,
                    component: ProductsPositions,
                },
            ]
        }
    }
    return form;
}