import {TABS} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CheckboxAttributs from '../../../../ui/inputs/CheckboxAttributs';

export default function formAddCompletenessGroup(allGroups, edit, currentLang){
    return ({
        titleForm: edit === 'edit' ?  'Modifier le groupe de complétude' : 'Créer un nouveau groupe de complétude',
        subTitleForm: edit === 'edit' ? 'Veuillez compléter les champs ci-dessous pour modifier un groupe de complétude' : 'Veuillez compléter les champs ci-dessous pour créer un groupe de complétude',
        drawerType: 'swipeable',
        noForm: true,
        component: Button,
        textButtonValidate: edit === 'edit' ? 'Modifier' : 'Créer',
        formConfig: {
            type: TABS,
            children:[
                {
                    labelName: 'Information du groupe',
                    isOptionnal: false,
                    optionsInputs: [
                        {
                            type: 'text',
                            label: 'Libellé du groupe',
                            helper: {
                                text: 'Indiquez le libellé ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'libelle',
                        },
                    ]
                },
                {
                    labelName: 'Sélections des attributs',
                    isOptionnal: false,
                    component: CheckboxAttributs,
                    optionsInputs:{
                        stateName: 'attributesSelected',
                        required: true,
                        value: allGroups
                    }
                }
            ]
        }
    })
}