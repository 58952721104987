import React, { useEffect, useRef, useState } from "react";
import Swiper from 'swiper';
import A from '../shared/a';
import H1 from '../shared/h1';
import H2 from '../shared/h2';
import P from '../shared/p';
import Div from '../shared/div';
import Button from '../shared/a';
import styled from 'styled-components';


const SectionSubtitle = styled(Div)`
    width: calc(100% + 2rem);
    margin-left: -1rem;
    position: relative;
    overflow: hidden;
    height: 512px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    justify-content: flex-end;
    img{
        max-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        min-width: 100%;
        min-height: 100%;
        position: absolute;
    }
    p {
        position: relative;
        text-align: center;
        display: block;
        color: white;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 16px;
        font-family: 'DINOffc-Medi', sans-serif;
        margin: 0;
        &.top-text{
            position: absolute;
            top: 18px;
            @media screen and (min-width: 1024px){
                top: 30px;
                font-size: 25px;
                line-height: 30px;
            };
        }
        &.bottom-text{
            font-family: 'DINOffc-Black', sans-serif;
            margin-top: 0;
            font-size: 17px;
            line-height: 46px;
        }
    }
    h2 {
        position: relative;
        text-align: center;
        display: block;
        color: white;
        font-size: 67px;
        line-height: 71px;
        font-family: 'Univers Bold Condensed', sans-serif;
    }
    @media screen and (min-width: 768px){
        p {
            font-size: 22px;
            line-height: 28px;
        }
        img {
            max-height: none !important;
        }
    }
    @media screen and (min-width: 1024px){
        width: calc(100% + 44px);
        margin-left: -22px;
        height: 800px;
        p {
            font-size: 25px;
            line-height: 30px;
        }
        p.bottom-text{
            font-size: 42px !important;
            line-height: 106px !important;
        }
        h2 {
            font-size: 163px !important;
            line-height: 170px !important;
        }
    }
    @media screen and (min-width:1450px){
        width: calc(100% + 120px);
        margin-left : -60px;
    }
`

const ContainerImage = styled(Div)`
    width: 100%;
    &:after{
        content:'';
        position:absolute;
        left:0px;
        top:0px;
        width:100%;
        height:100%;
        background: rgba(0, 0, 0, 0.2);
    }
`
const ContainerTitle = styled(Div)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: auto;
    display: block;
    margin: auto;
    width: calc(100% + 44px);
    padding-bottom: 20px;
    h2 {
        letter-spacing: -4px;
    }
`

const TopImageFullscreen = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;
    console.log('data', data)

    return (
        <Div style={{position: 'relative'}} className="sectionSubtitle" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <SectionSubtitle>
                <ContainerImage>
                    <img src={
                        typeof data.blocks.image.inputs.value.value === 'string' ? 
                            data.blocks.image.inputs.value.value 
                        : data.blocks.image.inputs.value.value.filePath ? 
                            `${process.env.REACT_APP_MEDIAS}/${data.blocks.image.inputs.value.value.filePath}` 
                        : null} 
                        alt={data.blocks.title.inputs.value.value}
                    />
                </ContainerImage>
                {
                    data.blocks.subtitleTop.inputs.value.value ? 
                        <P className="top-text">{data.blocks.subtitleTop.inputs.value.value}</P>
                    : null
                }
                <ContainerTitle>
                    <H2>{data.blocks.title.inputs.value.value}</H2>
                    {
                        data.blocks.subtitle.inputs.value.value ? 
                            <P className="bottom-text">{data.blocks.subtitle.inputs.value.value}</P>
                        : null
                    }
                </ContainerTitle>
                {/* <Div onClick={() => {
                    var offset = document.querySelector("header").clientHeight; // sticky nav height
                    var el = document.getElementById('scrollTo'); // element scrolling to
                    window.scroll({ top: (el.offsetTop - offset), left: 0, behavior: 'smooth' });
                }}>
                    <CustomIcon className={classes.chevron} icon="icono-chevron-right" size={30} />
                </Div> */}
            </SectionSubtitle>
        </Div>
    );
};

export default TopImageFullscreen;