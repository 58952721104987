import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { SET_GUIDELINE } from '../../../../js/constants/action-types';
import { ROUTE_SHELLS_CUSTOM_DETAIL } from '../../../../js/constants/route-names';

import colors from '../../../../config/theme/colors';

import {Grid} from '@material-ui/core';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import EmptyCard from "../../../ui/empty-card/EmptyCard";
import Empty from '../../../../assets/pictos/empty-picto/empty_guidelines.png';

import CardCustom from '../../../layouts/Card/CardCustom';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import Typography from '../../../ui/typography/Typography';
import Button from '../../../ui/button/Button';
import { setRedux } from '../../../../js/utils/functions';
import * as moment from "moment";
import slugify from 'slugify';
import DialogModal from '../../../ui/dialog/DialogModal';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import formShellCustom from './config/formShellCustom.config';
import CardShell from '../../../layouts/Card/cardContent/CardShell';


import { eventService } from '../../../../js/services/event.service';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { GET_ATTRIBUTES_BY_TYPE, UNIQUE_EAV_CODE } from '../../../../queries/attributes';
import { GET_SHELL_BY_EAVTYPE, CREATE_SHELL, UPDATE_SHELL, DELETE_SHELL, CREATE_SHELL_DATA, DELETE_SHELL_DATA, GET_DATAS_OF_A_SHELL, UPDATE_SHELL_DATA } from '../../../../queries/shells';


class ListShellsCustom extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            currentLang: this.props.locales[0].node.code,
            shells: [],
            isEmpty: false,
            loading: true,
            content:{
                emptyTitle: `Vous n’avez pas encore de ${this.props.match.params.alias}`,
                emptySubtitle: "Cliquez ci-dessous commencer la configuration",
                emptyTxtBtn: "Créer",
                emptyPicto: Empty,
            },
            editForm: false,
            errors: {},
            seeErrors: false,
            openForm: false,
            openDialog: false,
            customAttributes: [],
            sku: null,
            currentShell: null,
        }
        this.typingTimer = null;
    }
    
    getShells = () => {
        this.props.client.query({
            query: GET_SHELL_BY_EAVTYPE,
            fetchPolicy: 'no-cache',
            variables: {"eavType": `/api/eav-types/${this.props.match.params.id}`,page:1,itemsPerPage:50}
        }).then(result =>{
            console.log(result)
            if(result.data.shells.collection.length === 0){
                this.setState({
                    isEmpty: true,
                });
            }
            else{
                this.setState({
                    shells: result.data.shells.collection
                });
            }
            this.setState({
                loading: false
            });
        });
    }

    // CUSTOM SHELLS

    deleteShell = () => {
        this.props.startLoading()
        this.props.client.query({
            query: DELETE_SHELL,
            fetchPolicy: 'no-cache',
            variables: {
                "id": this.state.currentShell
            }
        }).then(result =>{
            this.props.stopLoading();
            if (result.data.deleteShell){
                this.handleToggleDrawer()
                this.props.snack(ALERT_SUCCESS, 'Coquille supprimé !');
                this.getShells()
            }
        });
    }

    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    setValue = (stateName, value, translated) => {
        // if(stateName === 'product_price' && value.includes(',')){
        //     value = value.replace(',', '.');
        // }
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else{
            this.setState({
                [stateName]: value,
            });
        }
        if(stateName === "sku")
            this.checkIdentifier(stateName);
    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {this.doneTyping(stateName)}, 500);
    };

    doneTyping = (stateName) => {
        if(stateName === 'shellName'){
            this.setState({
                attributeIdentifier: slugify(this.state.shellName, {replacement :'_', lower: true, remove: /[^\w\-\s]+/g}),
            })
        }
        
        if(this.state.shellName){
            this.props.client.query({
                query: UNIQUE_EAV_CODE,
                fetchPolicy: 'no-cache',
                variables: {
                    "code": this.state[stateName]
                }
            }).then(result =>{
                if (result.data.eavTypes.edges.length !== 0){
                    eventService.fire({stateName: 'shellName', errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.'});
                    let errors = this.state.errors;
                    errors[stateName] = true;
                    this.setState({
                        errors
                    })
                }
            });
        }
        this.forceUpdate();
    };

    prepareAttributes() {
        return new Promise(async (resolve, reject) => {
            let group = this.state.allGroups;
            let customAttributes    = group.filter(e => !e.node.isSystem);
            let imageAttributes     = customAttributes.filter(e => e.node.attributeType.input === 'image');

            this.setState({
                customAttributes,
                imageAttributes,
            }); 

            resolve();
        });
    }

    prepareAttributeValues() {
        return new Promise((resolve, reject) => {
            for (let locale of this.props.locales) {
                let values = {};

                for (let attribute of this.state.attributes) {
                    let attributeLocale = attribute.locales.find(e => e.code === locale.node.code);
                    let defaultLocale   = attribute.locales.find(e => e.code === this.state.currentLang);

                    if (!attributeLocale) {
                        if (attribute.attributeType.input === 'select') {
                            values[attribute.identifier] = defaultLocale?.value ?? attribute.locales[0] ;  
                        }
                    } else {
                        switch(attribute.attributeType.input) {
                            case 'file':
                            case 'image':
                                values[attribute.identifier] = {
                                    // data: attributeLocale.media ? attributeLocale.media.filePath : null,
                                    filePath: attributeLocale.media ? attributeLocale.media.filePath : null,
                                    file: null,
                                    changed: false,
                                    type: attributeLocale.media?.type,
                                    name: attributeLocale.media?.name,
                                };
                                break;
                            default:
                                values[attribute.identifier] = attributeLocale.value;  
                        }
                    }
                }

                this.setState({
                    [locale.node.code]: values,
                });
            }
            resolve()
        })
    }

    prepareAttributesLocales(result) {
        return new Promise((resolve, reject) => {
            let attributes = [];

            for (let { node } of result.data.shell.shellDatas.edges) {
                let found = false;
                
                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;
    
                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            productDataId: node.id,
                            type: node.attribute.attributeType.input
                        });
                    }
                }
    
                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        isSystem: node.attribute.isSystem,
                        attributeOptions: node.attributeOptions,
                        translationLabel: node.attribute.translation.translationDatas.edges,
                        locales: [{
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            productDataId: node.id,
                            type: node.attribute.attributeType.input
                        }]
                    });
                }
            }
    
            this.setState({ attributes }, resolve);
        });
    }

    addShell = async() => {
        this.setState({
            editForm: 'add',
        })
        await this.prepareAttributeValues();
        this.handleToggleDrawer();
    }

    editShell = async(id) => {
        this.props.client.query({
            query: GET_DATAS_OF_A_SHELL,
            fetchPolicy: 'no-cache',
            variables: {"id": id}
        }).then(async result =>{
            if(result.data){
                this.prepareAttributesLocales(result);
                await this.prepareAttributeValues();
                this.setState({
                    editForm: 'edit',
                    sku: result.data.shell.sku,
                    currentShell: id,
                })
                this.handleToggleDrawer();
            }
        })
    }

    initShell = () => {
        return new Promise(async (resolve, reject) => {
            this.props.client.query({
                query: GET_ATTRIBUTES_BY_TYPE,
                variables: {"id": `/api/eav-types/${this.props.match.params.id}`},
                fetchPolicy: "no-cache",
            }).then(GET_ATTRIBUTES_BY_TYPE_RESULT => {
                this.setState({
                    sku: null,
                    allGroups: GET_ATTRIBUTES_BY_TYPE_RESULT.data.eavType.attributes.edges.filter(e => (!e.node.isSystem && !e.node.isForContent) || e.node.identifier === 'default'),
                    attributes : [],
                    currentShell: null,
                    // status: true
                }, async () => {
                    await this.prepareAttributes();
                });
            });
            resolve();
        })
    }

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    getAttributeTranslatedValue = (id, lang) => {
        if (!this.state.attributes)
            return null;

        let attribute = this.state.attributes.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };

    saveAttributes = shell => {
        return new Promise(async (resolve, reject) => {
            let attributes = this.state.customAttributes;

            for (let attribute of attributes) {
                for (let locale of this.props.locales) { 
                    let formValue           = this.state[locale.node.code][attribute.node.identifier];
                    let currentTranslation  = this.getAttributeTranslatedValue(attribute.node.id, locale.node.id);
                    let isMedia             = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';
                    if (formValue && isMedia) {

                        /*if (!formValue.changed)
                            continue;*/
                        isMedia     = true;
                        /*formValue   = formValue.file;*/
                    }
    
                    if (formValue) { 
                        // let resultMedia = null;

                       /* if (isMedia) { 
                            let formData = new FormData();
    
                            formData.append('file', formValue);
                            formData.append('type', formValue.type);
        
                            resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                        }*/

                        if (currentTranslation) {
                            // UPDATE STEP

                            let variables = {
                                "id": currentTranslation.productDataId, 
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "shell": shell, 
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.id;
                            }

                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select'){
                                    variables.value = formValue;
                                }
    
                            await this.props.client.mutate({
                                mutation: UPDATE_SHELL_DATA,
                                variables
                            });
                        } else {
                            // CREATE STEP
                            let ADD_SHELL_DATA_RESULT = ''
                            let variables = {
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "shell": shell, 
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };

                            if (isMedia) {
                                variables.media = formValue.id;
                            }

                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select'){
                                    variables.value = formValue;
                                }
                            ADD_SHELL_DATA_RESULT = await this.props.client.mutate({
                                mutation: CREATE_SHELL_DATA,
                                variables
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP
    
                        await this.props.client.mutate({
                            mutation: DELETE_SHELL_DATA,
                            variables: { "id": currentTranslation.productDataId }
                        });
                    }
                }
            }

            resolve();
        });
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }
            this.props.startLoading();
            let variables = {
                'id': this.state.currentShell,
                'sku': this.state.sku,
                'eavType': `/api/eav-types/${this.props.match.params.id}`, 
                'createdAt': moment().format('YYYY-MM-DD'),
                'updatedAt': moment().format('YYYY-MM-DD'),
                'status': true,
            };
            if (this.state.editForm === "add"){
                const SHELL_RESULT_CREATE_RESULT = await this.props.client.mutate({
                    mutation: CREATE_SHELL,
                    variables
                });
                await this.saveAttributes(SHELL_RESULT_CREATE_RESULT.data.createShell.shell.id);
            }else{
                await this.props.client.mutate({
                    mutation: UPDATE_SHELL,
                    variables
                });
                await this.saveAttributes(this.state.currentShell);
            }
            
            this.handleSuccess();
        } catch(e) {
            this.handleError(e);
        }
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        if (this.state.editForm === "add"){
            this.props.stopLoading();
            this.handleToggleDrawer();
            this.props.snack(ALERT_SUCCESS, 'Coquille ajoutée !');
            this.getShells()
        }
        else{
            this.props.stopLoading();
            this.handleToggleDrawer();
            this.props.snack(ALERT_SUCCESS, 'Coquille modifiée !');
            // this.initShell();
            this.getShells()
        }
        // await setRedux(false,true,false,false);
    };

    handleToggleDialog = () => {
        this.setState({ 
            openDialog: !this.state.openDialog
        });
    };

    handleToggleDrawer = () => {
        this.setState({ 
            openForm: !this.state.openForm
        });
    };

    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);  
    };

    handleClose = async() => {
        this.setState({
            errors: {},
            seeErrors: false,
        })
        await this.initShell();
        this.handleToggleDrawer()
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    //COMPONENT CYCLE

    componentDidMount = () => {
        this.initShell();
        this.getShells();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.match.params.id !== this.props.match.params.id){
            this.setState({
                loading: true,
                isEmpty: false,
            }, this.getShells);
        }
    }

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        // this.forceUpdate();
    };

    render() {
        return (
            <div style={{width: "100%"}}>
                <TopPanel 
                    icomoon="icon-dashboard"
                    colorIcomoon={colors.blue.lighter.hue300}
                    title={`Gérer les ${this.props.match.params.alias}`}
                    subtitle={`Gérer vos coquilles ${this.props.match.params.alias}`}
                    handlerAdd={() => this.addShell()} 
                    buttonAvailable={true}
                    textAdd="Ajouter"
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    currentLang={this.state.currentLang} 
                    handleLang={this.handleLang} 
                    locales={this.props.locales}
                    hasBorder={true}
                />
                {
                    this.state.loading ? 
                        (
                            <PageLoader />
                        )
                    :
                    !this.state.isEmpty && this.state.shells.length > 0 ?
                        (
                            <Grid container direction="column" justify="center" spacing={0} style={{paddingBottom: 24, width: '100%', paddingTop: 24}}>
                                <Grid container direction="row" spacing={4}>
                                    {
                                        this.state.shells.map((shell, index) => {
                                            return(
                                                <Grid item xl={6} sm={6} xs={6} style={{display: 'flex', flexWrap: 'wrap'}} key={`card-guideline-${index}`}>
                                                    <CardCustom cardContentStyle={{width: '100%'}} style={{width: '100%'}}>
                                                        <CardShell allState={this.state} shell={shell} customAttributes={this.state.customAttributes} locales={this.props.locales} currentLang={this.state.currentLang} goTo={() => this.editShell(shell.id)} />
                                                        {/* <Typography variant="h4">{shell.node.sku}</Typography>
                                                        <Grid container justify="flex-end">
                                                            <Button onClick={() => this.editShell(shell.node.id)}>Modifier</Button>
                                                        </Grid> */}
                                                    </CardCustom>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        )
                    : 
                        (
                            <EmptyCard title={this.state.content.emptyTitle} subtitle={this.state.content.emptySubtitle} textButton={this.state.content.emptyTxtBtn} onClick={() => this.addShell()} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                        ) 
                }
                <LayoutBuilder 
                        opened={this.state.openForm} 
                        forClose={this.handleClose} 
                        handlerMutation={() => (this.handlerMutation())}
                        dataLayout={formShellCustom(this.state.editForm, this.state.currentLang, this.state.customAttributes, this.state.imageAttributes, this.state.errors, this.state.seeErrors, this.handleMediaPicker)} 
                        allState={this.state} 
                        icomoon={(this.state.editForm === 'edit') ? 'ico-modifier-attribut' : 'ico-ajouter-attribut'}
                        stateCallback={this.handleInputChange} 
                        errorCallback={this.handleFormError}
                        checkError={() => {}}
                        deleteMutation={
                            (this.state.editForm === 'edit') ? 
                                this.handleToggleDialog 
                            : null 
                        }
                        deleteText={this.state.editForm === 'edit' ? 'Supprimer la coquille' : null}
                        deleteButton={this.state.editForm} 
                        validateButton={true}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                    /> 
                <DialogModal 
                    open={this.state.openDialog} 
                    title={`Êtes-vous sûr de vouloir supprimer cette coquille ?`}
                    secondaryAction={this.handleToggleDialog} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                    primaryAction={() => {this.deleteShell(); this.handleToggleDialog()}}
                    primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
                >
                    Si vous supprimez cette coquille celle-ci ne sera plus accessible. Si vous ne l'utilisez plus mais que vous ne souhaitez pas la supprimer, annulez la suppression.
                </DialogModal>
            </div>
        );
    }

    goTo = (route, id = null) => {
        this.props.history.push({
            pathname : route,
            state: {id}
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        users: state.users,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setGuideline: (guideline) => dispatch({ type: SET_GUIDELINE, payload: { guideline }}),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ListShellsCustom)));