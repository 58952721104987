import styled from 'styled-components';

export default styled.h2`
    margin: 0;
    color: ${props => props.color || '#000'};
    font-size: 23px;
    line-height: 28px;
    @media screen and (min-width: 1024px){
        font-size: 41px;
        line-height: 53px; 
    }
    &.din-offc{
        font-size: 26px;
        line-height: 31px;
        @media screen and (min-width: 1024px){
            font-size: 39px;
            line-height: 47px; 
        }
    }
    &.like-h1{
        font-size: 46px;
        line-height: 52px;
        font-family: 'DINOffc-Bold', sans-serif;
        @media screen and (min-width: 768px){
            font-size: 80px;
            line-height: 100px;
        }
        @media screen and (min-width: 1024px){
            font-size: 100px;
            line-height: 120px;
        }
        @media screen and (min-width: 1450px){
            font-size: 119px;
            line-height: 142px;
        }
    }
    &.center{
        text-align: center;
    }
    &.uppercase{
        text-transform: uppercase;
    }
    &.white{
        color: white;
    }
`; 