import React from 'react';
import {CursorPagination} from '../../../js/utils/pagination'
import styled from 'styled-components';

import PageLoader from '../../ui/loadings/page-loader/PageLoader'
import { Grid} from '@material-ui/core';
import GridView from './components/GridView/GridView';
import TableView from './components/TableView/TableView'
import ChangeView from './components/ChangeView';
import {itemMapper} from '../../../js/mappers/mapper';
import * as listingHelper from '../../../js/utils/listing';
import {getElements} from '../../../js/utils/functions';
import NoResult from './components/NoResult';
const ListingContainer = styled.div`
    width:100%;
`

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

class Listing extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            mappingReady : false,
            items : null,
            pagination : this.props.pagination != false ? 
                {
                    page : 0,
                    perPage : this.props.perPageOptions[this.props.viewsOptions.current][0]
                }
                : null,
            views: this.props.viewsOptions,
            sortBy:[],
            noResult: false,
        }
    }


    mapItems = async(items) =>{
        this.setState({mappingReady : false})
        let result = await itemMapper(this.props.mappers,items,this.props.currentLang);
        this.setState({
            items : result
        })
    }



    handleGetItems() {
        return new Promise(async(resolve, reject) => {
            this.setState({ready:false})

            let variables = {
                ...this.props.queryVariables
            }
            variables = await listingHelper.initQuery(this.state,variables,'queryData');
            let queryStates = await listingHelper.initQuery(this.state,variables,'states');

            this.setState({...queryStates});
            let result = await getElements(this.props.identifier,variables);
            let handleResults = await listingHelper.handleResult(result.data[this.props.identifier],'listItems',this.state);
            await this.mapItems(handleResults.listItems);
            
            this.setState({
                ...handleResults,
            })
            if(this.props.listingCallback)
                this.props.listingCallback(handleResults.listItems)
            resolve();
        
        });
    }


    changeViews = async(mode) =>{
        let views = await listingHelper.changeViews(this.state,mode);
        this.setState({
            views
        })
        if(this.props.perPageOptions[mode][0]!=this.state.pagination.perPage){
            this.changePerPage(this.props.perPageOptions[mode][0])
        }
    }

    changePage = async(newPage) =>{
        let pagination = await listingHelper.pagination.changePage(this.state.pagination,newPage);
        this.setState({
            pagination
        },()=>this.handleGetItems())
    }
    
    changePerPage = async(perPage) => {
        let reload = this.state.pagination.perPage == perPage;
        if(reload){
            let pagination = await listingHelper.pagination.updatePerPage(this.state.pagination,perPage);
            this.setState({
                pagination
            },()=>{this.handleGetItems()})            
        }

    }

    handleSort = async(sortBy) => {
        let newSortBy = await listingHelper.sortBy.handleSort(this.state.sortBy,sortBy);
        let pagination = await listingHelper.pagination.resetPagination(this.state.pagination.perPage);
        this.setState({
            newSortBy,
            pagination
        },()=>this.handleGetItems())
    }

    resetPagination = async() => {
        let pagination = await listingHelper.pagination.resetPagination(this.state.pagination.perPage);
        this.setState({pagination, noResult: false},() => this.handleGetItems())
        // this.handleGetItems();
    }
    componentDidMount(){
        this.handleGetItems();
    }
    componentDidUpdate(prevProps,prevState){
        if(JSON.stringify(this.props.queryVariables) != JSON.stringify(prevProps.queryVariables)){
            this.resetPagination();
        }
        if(this.props.reload != prevProps.reload && this.props.reload == true){
            this.resetPagination();
        }
        if(this.props.currentLang != prevProps.currentLang){
            this.mapItems(this.state.listItems)
        }
    }

    render(){
        let {settings,label,perPageOptions} = this.props; 
        let pagination = this.state.pagination;
        let currentLang = this.props.currentLang;
        let noResult = this.state.noResult;
        let views = this.state.views;
        return(
            <ListingContainer>

                {views.settings?.length > 1 ? 
                    <Grid container justify="flex-end" alignItems="center">
                        <ChangeView views={views} handleViews={this.changeViews} />
                    </Grid>
                : null}
                {
                    !noResult ?
                        <>
                            {
                                views.current == 'card' ?
                                        this.state.ready ?
                                            <GridView settings={settings.grid} items={this.state.items[views.current]} cardProps={this.props.cardProps} label={label} currentLang={currentLang} cardContainerProps = {this.props.cardContainerProps}/>
                                        : <PageLoader/>
                                    :  
                                        this.state.ready ?
                                            <TableView settings={settings.table} items={this.state.items[views.current]} label={label} ready={this.state.ready} sortCallback={this.handleSort} sortBy={this.state.sortBy}/>
                                        : <PageLoader/>
                            }    
                                        
                            {
                                this.state.ready && pagination  ? 
                                    <CursorPagination
                                        rowLabel = {`${capitalize(label)} par page`}
                                        pagination = {pagination}
                                        type = "table"
                                        changePageCallback = {this.changePage}
                                        changePerPageCallback = {this.changePerPage}
                                        showPerPage = {perPageOptions[views.current]?.length > 0}
                                        perPageOptions = {perPageOptions[views.current]}
                                    />
                                : null
                                
                            }                    
                        </>

                    : <NoResult component={this.props.noResultComponent}/>
                }


            </ListingContainer>
        )        
    }


}

export default Listing;