import {STEPPER} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

export default function formPage(action='add', allState) {
    let generalInputs   = [
        {
            type : 'text',
            label : 'Libelle',
            stateName : 'pageLibelle',
            required: action === 'add' ? true : false,
            helper: {
                text: 'Libelle de la page',
                link: false,
            },
        },
        {
            type : 'text',
            label : 'Identifiant',
            stateName : 'pageIdentifier',
            required: action === 'add' ? true : false,
            disabled: action === 'edit' ? true : false,
            helper: {
                text: 'Identifiant de la page',
                link: false,
            },
        },
        {
            type : 'text',
            label : 'URL Key',
            stateName : 'pageUrl',
            required: action === 'add' ? true : false,
            helper: {
                text: 'URL key de la page',
                link: false,
            },
        },
        {
            type: 'select',
            disabled: action === 'edit' ? true : false,
            label: 'Type de page',
            helper: {
                text: 'Choississez un type de page',
                link: false,
            },
            required: true,
            stateName: 'pageType',
            value: allState.listTypesPage.map(type => {
                return ({
                    value: type.node.id,
                    label: type.node.libelle || type.node.identifier
                });
            })
        },
        {
            type: 'buttonGroup',
            label: 'État',
            helper: {
                label: 'État',
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'pageStatus',
            value: [
                {
                    value: true,
                    label: 'Actif',
                },
                {
                    value: false,
                    label: 'Inactif',
                },
            ]
        },
        {
            type: 'switch',
            label: 'Existant dans magento',
            helper: {
                // label: 'Existatn',
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'pageIsExisting',
            value: [
                {
                    value: true,
                    label: 'Actif',
                },
                {
                    value: false,
                    label: 'Inactif',
                },
            ]
        },
    ]
    if (allState.pageIsExisting){
        generalInputs.push({
            required : true,
            type : 'text',
            label : 'ID page magento',
            stateName : 'pageIdMagentoPage',
            required: true,
            helper: {
                text: 'Id de la page magento',
                link: false,
            },
        })
    }
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add'? 'Créer une page':'Modifier une page',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer':'modifier'} votre page`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Valider la création de cette page' : 'Valider la modification de cette page',
                textButton: action === 'add' ? 'Créer' : 'Modifier'
            },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}