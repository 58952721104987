import React from 'react';
import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import * as moment from 'moment';
import NoImage from '../../../../assets/images/not-found.png';
import IcomoonReact from 'icomoon-react';
import iconSet from "../../../../assets/selection.json";
import clsx from 'clsx';

const ContainerCard = styled(Box)`
    display: block;
    height: 100%;
`
const Color = styled.div`
    display: inline-block;
    width: 25px;
    height: 18px;
    margin-right: 6px;
    border: 1px solid black;
    background-color: ${props => props.color};
`;
const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    border-radius: 4px 4px 0px 0px;
    width: 100%;
`

const LineColor = styled(Box)`
    background: ${colors.blue.darker.hue300};
    width: 100%;
`

const ButtonCustom = styled(Button)`
    &:hover{
        background-color: ${props => props.bgcolor};
        opacity: 0.7;
        border-color: ${props => props.border ? props.bgcolor : ''};
    }
`

export default function CardInfoManagement(props){

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.blue.regular,
        },
        tooltip: {
            backgroundColor: colors.blue.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }
    const classes = useStylesBootstrap();

    return(
        <ContainerCard>
            <Grid container style={{position: 'relative', height: '100%'}}>
                <Box style={{width: "100%"}}>
                    <ContainerImage height={160} backgroundimage={props.data.node.media ? `${process.env.REACT_APP_MEDIAS}/${props.data.node.media?.filePath}` : NoImage} />
                    {/* <Grid item xs={4}>
                        <img src={props.data.node.media ? `${process.env.REACT_APP_MEDIAS}/${props.data.node.media?.filePath}` : NoImage} style={{width: "100%"}} alt={`image-ShopTheLook-${props.data.node.libelle}`}/>
                    </Grid> */}
                    <LineColor height={8}/>
                    <Grid item xs={12} style={{padding: 16}}>
                        <Grid container direction="row" style={{marginBottom: 8}} justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h3" style={{marginBottom: 8}}>{props.data.node.libelle}</Typography>
                            </Grid>
                            <Grid item>
                                <StatusInfo status={props.data.node.status} />
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center' style={{marginLeft: 8, marginBottom: 4}}>
                            <Typography variant="body2" style={{marginRight: 8}}>- Couleur libelle: </Typography>
                            <BootstrapTooltip title={props.data.node.libelleColor} className={clsx(classes.tooltip)}>
                                <Color color={props.data.node.libelleColor} />
                            </BootstrapTooltip>
                        </Grid>
                        {
                            props.data.node.linkButton ? 
                                <>
                                    <Typography variant="h4">Bouton: </Typography>
                                    <Grid container direction="column">
                                        <Grid container alignItems='center' style={{marginLeft: 8, marginBottom: 4}}>
                                            <Typography variant="body2" style={{marginRight: 8}}>- Texte: </Typography>
                                            <Typography variant="body2">{props.data.node.textButton}</Typography>
                                        </Grid>
                                        <Grid container alignItems='center' style={{marginLeft: 8, marginBottom: 4}}>
                                            <Typography variant="body2" style={{marginRight: 8}}>- Couleur texte: </Typography>
                                            <BootstrapTooltip title={props.data.node.textColorButton} className={clsx(classes.tooltip)}>
                                                <Color color={props.data.node.textColorButton} />
                                            </BootstrapTooltip>
                                        </Grid>
                                        <Grid container alignItems='center' style={{marginLeft: 8, marginBottom: 4}}>
                                            <Typography variant="body2" style={{marginRight: 8}}>- Couleur bouton: </Typography>
                                            <BootstrapTooltip title={props.data.node.colorButton} className={clsx(classes.tooltip)}>
                                                <Color color={props.data.node.colorButton} />
                                            </BootstrapTooltip>
                                        </Grid>
                                        <Grid container alignItems='center' style={{marginLeft: 8, marginBottom: 4}}>
                                            <Typography variant="body2" style={{marginRight: 8}}>- Lien: </Typography>
                                            <a href={props.data.node.linkButton} target="__blank">{props.data.node.linkButton}</a>
                                        </Grid>
                                        <Grid container alignItems='center' style={{marginLeft: 8, marginBottom: 4}}>
                                            <a href={props.data.node.linkButton} target="__blank" style={{textDecoration: 'none'}}><ButtonCustom bgcolor={props.data.node.colorButton} color={props.data.node.textColorButton} text={props.data.node.textButton} onClick={() => {}}/></a>
                                        </Grid>
                                    </Grid>
                                </>
                            : null
                        }
                        {
                            props.data.node.product.edges.length > 0 ?
                                <Grid container alignItems="center" style={{marginTop: 8, marginBottom: 4}}>
                                    <Grid item style={{marginRight: 8}}>
                                        <Typography variant="body1">Nombre de produits : </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems="center">
                                            <IcomoonReact iconSet={iconSet} color={colors.blue.lighter.hue300} size={25} icon={"picto-produit"} />
                                            <Typography variant="body1" style={{marginLeft: 8, color:colors.blue.lighter.hue300, fontWeight: "bold"}}>{props.data.node.product.edges.length}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            : 
                                <Grid container alignItems="center">
                                    <Grid item style={{marginRight: 8}}>
                                        <Typography variant="body1" style={{marginTop: 8, marginBottom: 4}}>Catégories : </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems="center">
                                            <IcomoonReact iconSet={iconSet} color={colors.blue.lighter.hue300} size={25} icon={"picto-categorie"} />
                                            <Typography variant="body1" style={{marginLeft: 8, color:colors.blue.lighter.hue300, fontWeight: "bold"}}>{props.data.node.category.length}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        }
                        <Grid container justify="flex-end">
                            <Button onClick={() => props.handlerButton(props.data.node)} text={props.textButton}></Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </ContainerCard>
    );
}