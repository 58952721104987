import React, { useEffect }  from 'react';

import { Box, Grid } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import { withRouter } from 'react-router';
import { ROUTE_DIFFUSION_CAMPAIGNS_DETAIL } from '../../../../js/constants/route-names';
import styled from 'styled-components';
// import * as moment from 'moment';
import 'moment-timezone';

import emptyCatalog from '../../../../assets/images/emptyCatalog.jpg';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import iconSet from "../../../../assets/selection.json";
import IcomoonReact from "icomoon-react";


const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    border-radius: 4px 4px 0px 0px;
`
const ContainerStyled = styled(Grid)`
    padding-bottom: 0.5rem;
`
const LineColor = styled(Box)`
    background: ${colors.blue.lighter.hue300};
`

const CatalogDescription = styled(Typography)`
    max-height: 40px;
    min-height: 40px;
    overflow: hidden;
    p{
        margin: 0;
    }
`

const AccordionCustom = styled(Accordion)`
    box-shadow: none;
`;
const AccordionSummaryCustom = styled(AccordionSummary)`
    padding: 0;
    border-bottom: 1px solid ${colors.grey.lighter.hue900};
    min-height: 40px !important;
    .MuiAccordionSummary-content{
        margin: 8px 0 !important;
        align-items: center;
    }
`;
const AccordionDetailsCustom = styled(AccordionDetails)`
    padding: 0;
    margin-top: 8px;
`;
const GridCampaign = styled(Grid) `
    height: 100%;
`;

const Subtitle = styled(Typography)`
    line-height: 19px;
    font-size: 14px;
`;

function CardCampaign(props){
    const [campaignData, setCampaignData] = React.useState(null);
    // const [startDate, setStartDate] = React.useState(null);
    // const [endDate, setEndDate] = React.useState(null);


    const goTo = (route, id) => {
        props.history.push({
            pathname : route,
            state: {campaignId : id}
        });
    };
    useEffect(() => {
        if (campaignData !== props.campaign.node){
            // let toDateStart = moment(props.campaign.node.startAt).format('L');
            // let toDateEnd = moment(props.campaign.node.endAt).format('L');
            setCampaignData(props.campaign.node)
            // setStartDate(toDateStart)
            // setEndDate(toDateEnd)
        }
    });

    // const getCatalogTypeName = (identifier) => {
    //     switch(identifier) {
    //         case 'permanent':
    //             return 'Catalogue Permanent';
    //         case 'ephemeral':
    //             return 'Catalogue Éphémère';
    //         case 'thematic':
    //             return 'Catalogue Thématique';
    //         default: return 'n/a';
    //     }
    // };
    return(
        campaignData ?
        (
            <Box width={'100%'} style={{cursor: props.inputCard ? 'inherit' : 'pointer', height:'100%', display: 'flex', flexDirection: 'column'}} onClick={props.inputCard ? null : () => goTo(ROUTE_DIFFUSION_CAMPAIGNS_DETAIL.replace(':id', campaignData.id))}>
                <ContainerImage height={160} backgroundimage={campaignData.media ? `${process.env.REACT_APP_API_ROOT}/medias/${campaignData.media.filePath}` : emptyCatalog} />
                <LineColor height={8}/>
                <Box padding={'1rem 1.5rem 0.5rem 1.5rem'} style={{display: 'flex', flexDirection: 'column', flex: '1'}}>
                    <ContainerStyled 
                        container 
                        direction="row"
                        justify="space-between"
                        alignItems={props.openForm ? "flex-start" : "center"}
                    >
                        <Grid item xs={props.openForm ? 8 : 10}>
                            <Typography variant="h4" colortext={colors.blue.regular} component="div">
                                <Box fontWeight="bold">
                                    {campaignData.libelle}
                                </Box>
                            </Typography> 
                            {/* <Grid container alignItems="center">
                                <CatalogType variant="h5" component="div" typecatalog={campaignData.type.identifier} marginright={"1rem"}>
                                    {getCatalogTypeName(campaignData.type.identifier)}
                                </CatalogType>
                                {
                                    campaignData.type.identifier !== 'permanent' ? 
                                        <CatalogDate variant="h5" colortext={colors.grey.regular} component="div">
                                            {startDate} - {endDate}
                                        </CatalogDate> 
                                    : <div style={{minHeight: 15, width: '100%'}} />
                                }
                            </Grid> */}
                        </Grid>
                        <StatusInfo status={campaignData.status} width={'auto'}/>
                    </ContainerStyled>
                    
                    <Box pb={3} pt={1}>
                        
                        <CatalogDescription variant="body1" component="div">
                            <HTMLEllipsis
                                unsafeHTML={campaignData.description ? campaignData.description :  null }
                                maxLine='2'
                                ellipsis='...'
                                basedOn='letters'
                            />
                        </CatalogDescription>
                    </Box>
                    <Grid container>
                        <Box width={'100%'}>
                            <AccordionCustom defaultExpanded={true}>
                                <AccordionSummaryCustom
                                    // expandIcon={<ExpandMoreIcon style={{padding: "0 12px"}}/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <IcomoonReact iconSet={iconSet} color={colors.blue.lighter.hue300} size={20} icon={'icon-asset'} />
                                    <Subtitle variant="body1" component="div" colortext={colors.blue.lighter.hue300} fontweighttext={'bold'} style={{marginLeft: '8px'}}>
                                        Assets
                                    </Subtitle>
                                </AccordionSummaryCustom>
                                <AccordionDetailsCustom>
                                    <Grid container>
                                        {
                                            campaignData.assets.edges.length > 0 ? 
                                                campaignData.assets.edges.map((asset, index) => {
                                                    let getNameAsset = asset.node.assetDatas.edges.find(e => e.node.attribute.identifier === 'asset_store_name');
                                                    let getTypeAsset = asset.node.assetType.libelle;
                                                    let getGameTypeAsset = asset.node.assetGameType?.libelle;
                                                    return(
                                                        <Grid item xs={12} style={{marginTop: 8}} key={`CampaignCardAsset${index}`}> 
                                                            <Grid container alignItems="flex-start" direction="column">
                                                                <Box style={{width: '100%'}}>
                                                                    <Grid container justify={'center'} style={{padding: '0'}}>
                                                                        <GridCampaign container alignItems={'flex-start'} justify={'flex-start'} direction="column">
                                                                            <Typography variant={'body2'} colortext={colors.blue.regular}> {getNameAsset.node.value} <span style={{color: colors.blue.lighter.hue300}}>({getTypeAsset}{getGameTypeAsset ? ` - ${getGameTypeAsset}` : null})</span></Typography>
                                                                        </GridCampaign>
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            : <Typography>Aucun asset</Typography>
                                        }    
                                    </Grid>
                                </AccordionDetailsCustom>
                            </AccordionCustom>
                        </Box>
                        <Box mt={3} pb={4} width={'100%'}>
                            <AccordionCustom defaultExpanded={true}>
                                <AccordionSummaryCustom
                                    // expandIcon={<ExpandMoreIcon style={{padding: "0 12px"}}/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <IcomoonReact iconSet={iconSet} color={colors.blue.lighter.hue300} size={20} icon={'picto-retailer'} />
                                    <Subtitle variant="body1" component="div" colortext={colors.blue.lighter.hue300} fontweighttext={'bold'} style={{marginLeft: '8px'}}>
                                        Retailers
                                    </Subtitle>
                                </AccordionSummaryCustom>
                                <AccordionDetailsCustom>
                                    <Grid container>
                                        {
                                            campaignData.retailers.edges.length > 0 ? 
                                                campaignData.retailers.edges.map((retailer, index) => {
                                                    let getNameAsset = retailer.node.libelle;
                                                    return(
                                                        <Grid item xs={12} style={{marginTop: 8}} key={`CampaignCardRetailer${index}`}> 
                                                            <Grid container alignItems="flex-start" direction="column">
                                                                <Box style={{width: '100%'}}>
                                                                    <Grid container justify={'center'} style={{padding: '0'}}>
                                                                        <GridCampaign container alignItems={'flex-start'} justify={'flex-start'} direction="column">
                                                                            <Typography variant={'body2'} colortext={colors.blue.regular}>{getNameAsset}</Typography>
                                                                        </GridCampaign>
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            : <Typography>Aucun retailers</Typography>
                                        }    
                                    </Grid>
                                </AccordionDetailsCustom>
                            </AccordionCustom>
                        </Box>
                    </Grid>
                    <Grid
                        container 
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                        style={{flex: '1'}}
                    >
                        {
                            !props.configCard ? 
                                <Grid item xs={12}>
                                    <Grid container justify="flex-end">
                                        <Box mt={props.openForm ? 1 : 0}>
                                            <Button 
                                            text={props.textButton} 
                                            color={colors.blue.lighter.hue300} 
                                            border={`1px solid ${colors.blue.lighter.hue300}`}
                                            colorhover={colors.white}
                                            bgcolor={colors.white} 
                                            bgcolorHover={colors.blue.lighter.hue300}
                                            disableElevation={true} 
                                            arrow="right" 
                                            onClick={() => goTo(ROUTE_DIFFUSION_CAMPAIGNS_DETAIL.replace(':id', campaignData.id.replace('/api/campaigns/', '')))}/>
                                        </Box>
                                    </Grid>
                                </Grid> 
                            : null
                        }
                    </Grid>
                </Box>
            </Box>
        )
        : null
    )
}

export default withRouter(CardCampaign);
