import React, { useState, useEffect } from 'react';

import { Box, Grid } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import imgNotFound from '../../../../assets/images/not-found.png';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import request from '../../../../js/utils/fetch';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Brightness1OutlinedIcon from '@material-ui/icons/Brightness1Outlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import * as moment from "moment";
import FavoriteIcon from '@material-ui/icons/Favorite';
import SecurityIcon from '@material-ui/icons/Security';

const CardDescription = styled(Box)`
    p{
        margin: 0;
    }
`;
const ContainerImage = styled(Box)`
    overflow: hidden;
    background-color: ${props => props.bgcolor};
    background-image: url(${props => props.backgroundimage});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

const LineColor = styled(Box)`
    background: ${colors.blue.lighter.hue300};
`;

function CardSetup(props){
    const [displayCron,setDisplayCron] = useState(null);
    const type = props.type;
    
    // CRON SETUP
    let cronData = null;
    useEffect(() => {
        if(props.isRetailer){
            let idRetailer = '';
            if(props.isDashboard){
                idRetailer = type.node.id;
            }
            else{
                idRetailer = type.node?.id?.replace("/api/retailers/", '');
            }
            request(`${process.env.REACT_APP_API}/scheduler/retailer/${idRetailer}`, 'get', false).then(
                (data) => {
                    if(data.success){
                        cronData = data.crons
                        let displayCron = {};
                        if (cronData.length > 0){
                            switch(cronData[0].status){
                                case "success": 
                                    displayCron.color = colors.green.regular;
                                    displayCron.status = cronData[0].status;
                                    displayCron.statusText = "Succès";
                                    displayCron.type = cronData[0].type;
                                    displayCron.endDate = moment(cronData[0].end).format('DD/MM/YYYY à HH:MM');
                                    displayCron.picto = <Brightness1OutlinedIcon style={{fill: displayCron.color, fontSize: '1.2rem'}} />;
                                break;
                                case "pending": 
                                    displayCron.color = colors.orange.regular;
                                    displayCron.status = cronData[0].status;
                                    displayCron.statusText = "En attente";
                                    displayCron.type = cronData[0].type;
                                    displayCron.startDate = moment(cronData[0].start).format('DD/MM/YYYY à HH:MM');
                                    displayCron.picto = <ReportProblemOutlinedIcon style={{fill: displayCron.color, fontSize: '1.2rem'}}/>

                                break;
                                case "running": 
                                    displayCron.color = colors.blue.lighter.hue300;
                                    displayCron.status = cronData[0].status;
                                    displayCron.statusText = "En cours";
                                    displayCron.type = cronData[0].type;
                                    displayCron.startDate = moment(cronData[0].start).format('DD/MM/YYYY à HH:MM');
                                    displayCron.picto = <Brightness1OutlinedIcon style={{fill: displayCron.color, fontSize: '1.2rem'}} />;
                                break;
                                case "skipped": 
                                    displayCron.color = colors.grey.regular;
                                    displayCron.status = cronData[0].status;
                                    displayCron.statusText = "Ignoré";
                                    displayCron.type = cronData[0].type;
                                    displayCron.picto = <ErrorOutlineIcon style={{fill: displayCron.color, fontSize: '1.2rem'}}/>
                                break;
                                case "error": 
                                    displayCron.color = colors.red.regular;
                                    displayCron.status = cronData[0].status;
                                    displayCron.statusText = "Erreur";
                                    displayCron.type = cronData[0].type;
                                    displayCron.startDate = moment(cronData[0].start).format('DD/MM/YYYY à HH:MM');
                                    displayCron.picto = <ReportProblemOutlinedIcon style={{fill: displayCron.color, fontSize: '1.2rem'}}/>
                                break;
                                default:
                                    return null;
                            }
                            setDisplayCron(displayCron)
                        }
                    }
                }
            );
        }
    }, []);

    if(type.node){
        // IMAGE SETUP
        let image = "";
        if(type.node.logo){
            image = type.node.logo
            const imagesFolder = require.context('../../../../assets/images', true);
            try{
                image = imagesFolder('./' + type.node.logo);
            }catch(e){
                image = imgNotFound;
            }
        }
        if(type.node.attributeGroup.identifier === "retailer" || type.node.identifier === 'retailer' || type.node.attributeGroup.identifier === "brand" || type.node.identifier === 'brand' || props.isRetailer){
            if(type.node.logo){
                image = `${process.env.REACT_APP_API_ROOT}/${type.node.logo}`;
            }else{
                image = imgNotFound;
            }
        }

        let bgColor = '#FFFFFF';
        
        switch(type.node.logo) {
            case "medias/magento-logo.png": 
                bgColor = '#f36324' 
            break;
            case "medias/cdiscount-logo.png": 
                bgColor = '#233244' 
            break;
            case "medias/bestbuy-logo.png": 
                bgColor = '#0046c2' 
            break;
            case "medias/amazon-logo.png": 
                bgColor = '#0046c2' 
            break;
            case "medias/twitter-logo.png": 
                bgColor = '#00aced' 
            break;
            case "medias/facebook-logo.png": 
                bgColor = '#4267b2' 
            break;
            case "medias/cloudcommerce-logo.png": 
                bgColor = '#00a1df' 
            break;
            case "medias/shopify-logo.png": 
                bgColor = '#7ab55c' 
            break;
            case "medias/wordpress-logo.png": 
                bgColor = '#1e8cbe' 
            break;
            case "medias/mailchimp-logo.png": 
                bgColor = '#ffdf1b' 
            break;
            default : bgColor = '#FFFFFF';
        }

        if(image === imgNotFound){
            bgColor = '#f8fafb';
        }
        return(
            <Box style={{height: "100%", display: "flex", flexDirection: "column", position:"relative"}}>
                <ContainerImage height={160} backgroundimage={image ? typeof image === "string" ? image : image?.default : imgNotFound} bgcolor={bgColor} />
                <LineColor height={8}/>
                <Box padding={'1rem 1.5rem 0.5rem 1.5rem'} style={{flex: 1,display: 'flex',flexDirection: "column"}}>
                    <Grid container style={{paddingBottom: 8}} alignItems={'center'}>
                        <Grid item xs={9}>
                            <Typography variant="h4" colortext={colors.blue.regular} component="div">
                                <Box fontWeight="bold">
                                    {type.node.libelle}
                                </Box>
                            </Typography>
                        </Grid>
                        {
                            process.env.REACT_APP_MODE_SPREAD !== "hub" ? 
                                <Grid item xs={3}>
                                    <StatusInfo status={props.isDashboard ? type.node.assets?.length > 0 ? true : false : type.node.assets?.edges.length > 0 ? true : false} width={'auto'} justify={'flex-end'}/>
                                </Grid>
                            : null
                        }
                    </Grid>
                    
                    <Typography variant="body1" component="div">
                        <CardDescription pb={2} style={{height: props.inputCard ? '60px' : '80px'}}>
                            <HTMLEllipsis
                                unsafeHTML={type.node.description}
                                maxLine={props.inputCard ? '3' : '4'}
                                ellipsis='...'
                                basedOn='letters'
                            />
                        </CardDescription>
                    </Typography>
                    {
                        type.node.preferences ?
                            <Grid container direction="column" style={{marginBottom: 16}} justify="center">
                                <Grid container alignItems="center">
                                    <FavoriteIcon style={{color: colors.grey.regular}}/>
                                    <Typography variant="body1" component="div" style={{marginLeft: 8}}>
                                        Entrepôt prioritaire : {type.node.preferences?.[0].warehouse}
                                    </Typography>
                                </Grid>
                                <Grid container alignItems="center" style={{marginTop: 8}}>
                                    <SecurityIcon style={{color: colors.grey.regular}}/>
                                    <Typography variant="body1" component="div" style={{marginLeft: 8}}>
                                        Stock de sécurité : {type.node.preferences?.[0].security_stock}
                                    </Typography>
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        type.node.channelDatas ? 
                        (
                            <div style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ArrowDropDownIcon style={{fill: colors.purple.regular, border: `1px solid ${colors.purple.regular}`, borderRadius: '50%', fontSize: '1.2rem'}}/>
                                    <Typography variant="body1" component="div" style={{color: colors.purple.regular, paddingLeft: 8}}>
                                        Flux sortant
                                    </Typography>
                                </div>
                                {
                                    props.showDetails ? (
                                        <Button onClick={props.showDetails}>Voir le détails</Button>
                                    ) : null
                                }
                            </div>
                        ) 
                        :   
                        type.node.retailerData ? 
                        (
                            <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ArrowDropUpIcon style={{fill: colors.purple.regular, border: `1px solid ${colors.purple.regular}`, borderRadius: '50%', fontSize: '1.2rem'}}/>
                                    <Typography variant="body1" component="div" style={{color: colors.purple.regular, paddingLeft: 8, fontSize: 12, lineHeight: '15px'}}>
                                        Flux entrant
                                    </Typography>
                                </div>
                                {
                                    props.showDetails ? (
                                        <Button onClick={props.showDetails}>Voir le détails</Button>
                                    ) : null
                                }
                            </div>
                        ) 
                        : 
                        null
                    }
                    {
                        displayCron ?
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 16, cursor: 'pointer'}} onClick={() => {props.cronListOpening(props.isDashboard ? type.node.id : type.node.id.replace('/api/retailers/', ''), type.node)}}>
                                {displayCron.picto}
                                <Grid container direction="column">
                                    {
                                        displayCron.endDate || displayCron.startDate ?
                                            <Typography variant="body1" style={{color: displayCron.color, paddingLeft: 8, fontSize: 12, lineHeight: '15px'}}>
                                                {
                                                    displayCron.endDate ?
                                                        `Terminé le ${displayCron.endDate}`
                                                    : `Programmé le ${displayCron.startDate}`
                                                }
                                            </Typography>
                                        : null
                                    }
                                    <Typography variant="body1" component="div" style={{color: displayCron.color, paddingLeft: 8, fontSize: 12, lineHeight: '15px'}}>
                                        {displayCron.type}: {displayCron.statusText}
                                    </Typography>
                                </Grid>
                            </div>
                        : null
                    }
                    {props.handlerEdit ? 
                        <Grid container justify="flex-end" style={{position: "absolute", top: 8, right:16}}>
                            <Button 
                                // text={props.textButton} 
                                bgcolor={colors.white} 
                                color={colors.blue.lighter.hue300} 
                                colorhover="white" 
                                bgColor="transparent" 
                                disableElevation={true} 
                                border={`1px solid ${colors.blue.lighter.hue300}`} 
                                // arrow="right" 
                                onClick={() => {props.handlerEdit()}}
                                style={{padding: "4px 8px", minWidth: 40}}
                            >
                                <SettingsIcon style={{fontSize: '1.2rem'}} />
                            </Button>
                        </Grid>
                    : null}
                </Box>
                
            </Box>
        );
    }
}

export default withRouter(CardSetup);
