import React from 'react';
import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import * as moment from 'moment';

const ContainerCard = styled(Box)`
    display: block;
    height: 100%;
    padding: 16px;
`

export default function CardPages(props){

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.blue.regular,
        },
        tooltip: {
            backgroundColor: colors.blue.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }
    return(
        <ContainerCard>
            <Grid container style={{position: 'relative', height: '100%'}}>
                <Grid container direction="column">
                    <Grid container justify="flex-end">
                        <StatusInfo status={props.data.node.isActive} width={'auto'}/>
                    </Grid>
                    <Typography variant="h4">{props.data.node.libelle}</Typography>
                    {
                        props.isContent ? 
                            <>
                                <Typography variant="body1">Date de début: {!props.data.node.isDefaultContent ? moment(props.data.node.startDate).format('L') : 'N/A'}</Typography>
                                <Typography variant="body1">Date de fin: {!props.data.node.isDefaultContent ? moment(props.data.node.endDate).format('L') : 'N/A'}</Typography>
                            </>
                        :
                        <>
                            <Typography variant="body1">URL key: {props.data.node.url}</Typography>
                            {
                                props.data.node.magentoPageRealId ? 
                                    <Typography variant="body1">Magento page id: {props.data.node.magentoPageRealId}</Typography>
                                : null
                            }
                        </>
                    }
                    
                    {/* <Typography variant="body1">Date de début: {moment(props.data.node.startDate).format('L')}</Typography>
                    <Typography variant="body1">Date de fin: {moment(props.data.node.endDate).format('L')}</Typography> */}
                </Grid>
                <Grid container justify="flex-end">
                    {
                        !props.data.node.isDefaultContent ? 
                            <Button onClick={() => props.handlerButton(props.data.node)} text={props.textButton} />
                        : null
                    }
                    <Button onClick={() => props.handlerButtonSecondary(props.data.node)} arrow={props.isContent ? true : false} text={props.textButtonSecondary} style={{marginLeft: 8}} />
                </Grid>
            </Grid>
        </ContainerCard>
    );
}